/**
* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import {
    ConfigurationService,
    ContextService,
    EventsService,
    FavoritesService,
    FocusService,
    HelpService,
    UiLocalStorageService,
    PromptService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    SessionService,
    ValidationService
} from "../../services";
import * as Models from "../../models";

declare var $: any;

@Component({
    selector: 'createsharedlist',
    template: require('./create-shared-list.component.html')
})
export class CreateSharedListComponent {
    sharedListDialog: string = "#shared-list-dialog";
    sharedListNo: number = 0;
    sharedListSubHeader: string = "";
    sharedListDialogSuccess: string = "#shared-list-dialog-success";
    sharedListSuccessOk: string = "#btn_ok_favorite";
    recentlySharedListsDialog: string = "#recently-shared-lists-dialog";
    sharedListDialogOpen: string = "#btn_recently_shared_list_Ok";

    constructor(private helpService: HelpService,
        private configurationService: ConfigurationService,
        private sessionService: SessionService,
        private favoritesService: FavoritesService,
        private rootScopeService: RootScopeService,
        private uiLocalStorageService: UiLocalStorageService,
        private notificationsService: PromptService,
        private pubSubService: EventsService,
        private focusService: FocusService) {

    }

    /**
     * Help for Create Shared List
     * @param helpId
     */
    help(helpId: string) {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses[helpId]);
    }

    closeCreateSharedList() {
        $(this.sharedListDialog).modal('hide');
        this.favoritesService.selectedItemValues.length = 0;
        this.favoritesService.selectedPeople.length = 0;
        setTimeout(() => {
            $('#personFavoritesOption a').trigger("focus");
        }, 100);
    }

    /**
     * Shared List Items Listbox label for screen reader
     * @param itemsArray
     * @returns {string}
     */
    sharedListItemsAriaLabel(itemsArray: any) {
        let labelText: string = "";
        if (itemsArray && itemsArray != null && itemsArray != undefined) {
            labelText += "Shared List Items: ";
            if (itemsArray.length > 0) {
                for (let d = 0; d < itemsArray.length; d++) {
                    labelText += itemsArray[d].ItemLabel;
                    labelText += d != itemsArray.entries.length - 1 ? ", " : ". ";
                }
            }
        }

        return labelText;
    }

    /**
     * Create shred list on Enter key
     * @param event
     */
    sharedListEnter(event: any) {
        if (event.keyCode === Models.KeyCodes.enter) {
            this.createSharedList();
        }
    }

    /**
     * Local Storage used to store 10 recently shared list
     * @param event
     * @param linkEvent
     */
    showRecentlyShared(event, linkEvent) {
        if (this.favoritesService.sharedListOptions && this.favoritesService.sharedListOptions.length > 0) {
            $(this.recentlySharedListsDialog).modal('show');
            setTimeout(() => {
                document.getElementById("btn_recently_shared_list_Ok").focus();
            }, 500);
        } else if (linkEvent) {
            let message: any = [Models.SharedListConstants.recentlyCreatedbody];
            this.favoritesService.duplicateEntriesAlertMsg(Models.SharedListConstants.recentlyCreatedTitle, message, 'person');
        } else {
            event.stopPropagation(); //avoid closing of bootstrap dropdown
        }
    }

    createSharedList() {
        let prefix = this.sessionService.getOperatorId() + "_";
        let pattern = /^[\w\.]*$/; // regex for special symbols
        this.favoritesService.listName = this.favoritesService.listName.trim();
        if ((this.favoritesService.listName.length == 0) || this.favoritesService.listName == prefix) {
            let msg: any = [
                "Please complete the Shared List Name.",
                "",
                "The Shared List Name can be neither the provided prefix nor empty or all spaces."
            ];
            this.favoritesService.duplicateEntriesAlertMsg("Create Shared List Error", msg, 'person');
            this.rootScopeService.sharedListName = true;
        } else if (this.favoritesService.listName.match(pattern) === null) {
            let msg: any = ["The Shared List Name may only contain letters, numbers, underscores, and periods."];
            this.favoritesService.duplicateEntriesAlertMsg("Create Shared List Error", msg, 'person');
        } else {
            this.sharedListNo = this.favoritesService.selectedPeople.length;
            this.sharedListSubHeader = "The following " + this.sharedListNo + " records will be stored in a SavedList that can be" + " used by you and others for various Colleague processes. ";
            this.favoritesService.createSharedList(this.favoritesService.selectedPeople, this.favoritesService.listName, false, this.sharedListConfirmationDialog, this.sharedListConfirmOverwrite, this);
        }
    }

    /**
     * Tab on Cancel should focus on Help
     * @param event
     */
    btnCreateSharedListCancelKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            this.focusService.focusOn("btnCreateSharedListHelp", true);
        }
    }

    /**
     * Shift tab on help should focus on Cancel
     * @param event
     */
    btnCreateSharedListHelpKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            this.focusService.focusOn("btnCreateSharedListCancel", true);
        }
    }

    /**
     * success callback for createSharedList
     * @param createSharedListComp
     * @param listObject
     */
    private sharedListConfirmationDialog(createSharedListComp: CreateSharedListComponent, listObject: any) {
        // updates recently shared list modal
        let self: CreateSharedListComponent = <CreateSharedListComponent>createSharedListComp;
        let sharedLists: any = self.uiLocalStorageService.get(Models.LocalStorageConstants.favoritesSharedList) || [];
        sharedLists.unshift(listObject);
        if (sharedLists.length == 11) { // if 10 shared lists were already stored in Local Storage
            let temp = sharedLists.slice(1, 11); // contains 9 most recently added shared lists
            self.uiLocalStorageService.set(Models.LocalStorageConstants.favoritesSharedList, temp);
        } else {
            self.uiLocalStorageService.set(Models.LocalStorageConstants.favoritesSharedList, sharedLists);
        }
        $(self.sharedListDialogSuccess).modal('show');
        setTimeout(() => {
            document.getElementById("btn_ok_favorite").focus();
        }, 500);
    }

    /**
     * failure callback for createShareLsit
     * @param createSharedListComp
     * @param error
     */
    private sharedListConfirmOverwrite(createSharedListComp: CreateSharedListComponent, error: any) {
        let self: CreateSharedListComponent = <CreateSharedListComponent>createSharedListComp;
        if (error.ErrorCode == "DMI.SHFRS.0004") { // if shared list already exists
            let msg: any = {};
            msg.text = [
                "The Shared List you are trying to create already exists.",
                "",
                "Would you like to overwite the existing Shared List with this Shared List?"
            ];
            msg.buttons = [{
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    // User confirms wanting to overwrite existing Shared List
                    self.notificationsService.popupMessageDismiss();
                    self.removeDuplicateSharedlist();
                    self.favoritesService.createSharedList(self.favoritesService.selectedPeople, self.favoritesService.listName, true, self.sharedListConfirmationDialog, self.sharedListConfirmOverwrite, self);
                    self.pubSubService.broadcast(Models.EventConstants.closeAlert);
                },
                title: "Confirm shared list overwrite",
                SearchFocus: false
            },
            {
                label: Models.PromptMessageConstants.cancelPromptButton,
                title: "Cancel shared list overwrite",
                callback: () => {
                    self.notificationsService.popupMessageDismiss(() => {
                        self.pubSubService.broadcast(Models.EventConstants.closeAlert);
                        setTimeout(() => {
                            let addToFavoriteBtn = document.querySelector(self.sharedListDialog + " #btn_add-favorite-sharedList") as HTMLElement;
                            addToFavoriteBtn.focus();
                        }, 200);
                    }, false);
                },
                SearchFocus: false
            }
            ];
            msg.defaultCallbackNumber = 0;
            self.notificationsService.popupMessageShow(msg);
        } else {
            self.notificationsService.popupMessage("Shared List Overwrite Error", error.ErrorMessageText, null);
        }
    }

    /**
     * remove duplicate sharedlist name from local storage.
     */
    private removeDuplicateSharedlist() {
        let sharedLists = this.uiLocalStorageService.get(Models.LocalStorageConstants.favoritesSharedList);
        if (sharedLists != null) {
            for (let i = 0; i < sharedLists.length; i++) {
                if (sharedLists[i].listname === this.favoritesService.listName) {
                    sharedLists.splice(i, 1);
                }
            }
            this.uiLocalStorageService.set(Models.LocalStorageConstants.favoritesSharedList, sharedLists);
        }
    }
}