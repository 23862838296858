var showErrorMessage = true;

function lazyload() {
    var sRule1 =
        "@font-face {" +
        "  font-family: 'open_sansregular';" +
        "  font-style: normal;" +
        "  font-weight: normal;" +
        "  src: url('../fonts/OpenSans-Regular-webfont.woff') " +
        "  format('woff');" +
        "}";

    var sRule2 =
        "@font-face {" +
        "  font-family: 'open_sanslight';" +
        "  font-style: normal;" +
        "  font-weight: normal;" +
        "  src: url('../fonts/OpenSans-Light-webfont.woff') " +
        "  format('woff');" +
        "}";

    var sRule3 =
        "@font-face {" +
        "  font-family: 'open_sanssemibold';" +
        "  font-style: normal;" +
        "  font-weight: normal;" +
        "  src: url('../fonts/OpenSans-Semibold-webfont.woff') " +
        "  format('woff');" +
        "}";

    var style1 = document.styleSheets[0];
    if ("function" === typeof (style1.insertRule)) {
        // Firefox, Safari, Chrome
        style1.insertRule(sRule1, 0);
        style1.insertRule(sRule2, 0);
        style1.insertRule(sRule3, 0);
    } else if ("string" === typeof (style1.cssText)) {
        // IE
        style1.cssText = sRule1;
        style1.cssText = sRule2;
        style1.cssText = sRule3;
    }

    //    Google Analytics
    if (disableGA == 'false') {
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
        ga('create', 'UA-108717436-1', 'auto');
    }
}

$(document).ready(function () {
    $('.progress-button').progressInitialize();
    $('[data-toggle="tooltip"]').tooltip();


    $(".search-input").on("focus", function (e) {
        $(".search-icon").addClass("search-icon-focus");
    });
    $(".search-input").on("blur", function (e) {
        $(".search-icon").removeClass("search-icon-focus");
    });

    setTimeout(function () {
        lazyload();
    }, 100);
});

function beep() {
    var sound = document.getElementById("audio");
    sound.play()
}

//Alert user when closing browser tab.
//TODO: Custom Message only works in ie. Research
window.addEventListener("beforeunload", function (e) {
    //check if userid is visible or not. Show warning only if logged in.
    if (!$('#logoutMessage').is(":visible") && !$('#UserID').is(":visible") && window.allowReload != true && showErrorMessage) {
        var confirmationMessage = "Please save any unsaved data.";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome
    }
    showErrorMessage = true;
});


//IE Doesn't support trimLeft and trimRight out of the box, so adding this as fall back function.
if (typeof String.prototype.trimLeft !== 'function') {
    String.prototype.trimLeft = function () {
        return this.replace(/^\s+/, "");
    }
}

if (typeof String.prototype.trimRight !== 'function') {
    String.prototype.trimRight = function () {
        return this.replace(/\s+$/, "");
    }
}

if (typeof String.prototype.startsWith !== 'function') {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    }
}

if (typeof Array.prototype.move !== 'function') {
    Array.prototype.move = function (old_index, new_index) {
        if (new_index >= this.length) {
            var k = new_index - this.length;
            while ((k--) + 1) {
                this.push(undefined);
            }
        }
        this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    };
}

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            // 1. Let O be ? ToObject(this value).
            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                // c. Increase k by 1.
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function () {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}

$(document).on("wheel", function (e) {
    if (e.target && $(e.target).hasClass("contentGrid")) {
        return false;
    }
});

(function ($) {
    $.fn.drags = function (opt) {
        opt = $.extend({ handle: "", cursor: "move" }, opt);

        if (opt.handle === "") {
            var $el = this;
        } else {
            var $el = this.find(opt.handle);
        }

        return $el.css('cursor', opt.cursor).on("mousedown", function (e) {
            if (opt.handle === "") {
                var $drag = $(this).addClass('draggable');
            } else {
                var $drag = $(this).addClass('active-handle').parent().addClass('draggable');
            }
            var z_idx = $drag.css('z-index'),
                drg_h = $drag.outerHeight(),
                drg_w = $drag.outerWidth(),
                pos_y = $drag.offset().top + drg_h - e.pageY,
                pos_x = $drag.offset().left + drg_w - e.pageX;
            $drag.css('z-index', 1000).parents().on("mousemove", function (e) {
                if ($('.draggable').length) {
                    $('.draggable').offset({
                        top: e.pageY + pos_y - drg_h,
                        left: e.pageX + pos_x - drg_w
                    }).on("mouseup", function () {
                        $(this).removeClass('draggable').css('z-index', z_idx);
                    });
                }
            });
            e.preventDefault(); // disable selection
        }).on("mouseup", function () {
            if (opt.handle === "") {
                $(this).removeClass('draggable');
            } else {
                $(this).removeClass('active-handle').parent().removeClass('draggable');
            }
        });

    }
})(jQuery);