/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    ChangePasswordService,
    ConfigurationService,
    EventsService,
    FavoritesService,
    LoginService,
    PreferencesService,
    RootScopeService,
    SessionService, UiLocalStorageService
} from "../../../services";
import * as Models from "../../../models";

declare var universityName: any;
declare var $: any;
declare var defaultThreshold: any;

@Component({
    selector: 'login',
    template: require('./login.component.html')
})
export class LoginComponent implements OnInit {
    contextDefinitionsLoadedListenerId: string = "";
    favoritesLoadedListenerId: string = "";
    preferencesLoadedListenerId: string = "";
    TriggerDBLoginListenerId: string = "";
    configurationLoadedListenerId: string = "";
    interfaceReady: boolean = false;
    dbPasswordModal = "#databasePassword .modal";
    userId = '';
    userPassword = '';
    dbPassword = '';
    inputTypePassword: boolean = true;
    dbInputTypePassword: boolean = true;
    configurationLoaded = false;
    universityName: string = universityName;

    constructor(private favoritesService: FavoritesService, private loginService: LoginService,
        private configurationService: ConfigurationService, private rootScopeService: RootScopeService,
        private changePasswordService: ChangePasswordService, private eventsService: EventsService,
        private sessionService: SessionService, private preferencesService: PreferencesService, private uiLocalStorageService: UiLocalStorageService) {
        this.contextDefinitionsLoadedListenerId = this.eventsService.on("contextDefinitionsLoaded", [this.contextDefinitionsLoadedListener, this]);
        this.favoritesLoadedListenerId = this.eventsService.on("favoritesLoaded", [this.favoritesLoadedListener, this]);
        this.preferencesLoadedListenerId = this.eventsService.on("preferencesLoaded", [this.preferencesLoadedListener, this]);
        this.TriggerDBLoginListenerId = this.eventsService.on("TriggerDBLogin", [this.TriggerDBLoginListener, this]);
        this.configurationLoadedListenerId = this.eventsService.on("configurationLoaded", [this.configurationLoadedListener, this]);
    }

    ngOnInit() {
        $('.progress-button').progressInitialize();
    }

    ngOnDestroy() {
        this.eventsService.destroy("contextDefinitionsLoaded", this.contextDefinitionsLoadedListenerId);
        this.eventsService.destroy("favoritesLoaded", this.favoritesLoadedListenerId);
        this.eventsService.destroy("preferencesLoaded", this.preferencesLoadedListenerId);
        this.eventsService.destroy("TriggerDBLogin", this.TriggerDBLoginListenerId);
        this.eventsService.destroy("configurationLoaded", this.configurationLoadedListenerId);
    }

    hideShowPassword(event: any = null) {
        // Disable password eyeball functionality if the configurations are getting loaded or the user is not authenticated or login is in progress or the user just logged out
        if (!(!this.configurationLoaded || this.rootScopeService.isAuthenticated || this.loginService.loginUnderProgress == true || this.rootScopeService.loggedOut == true)) {
            if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
                this.inputTypePassword = !this.inputTypePassword;
            }
        }
    };


    contextDefinitionsLoadedListener(comp: any) {
        let self = <LoginComponent>comp;
        self.loginService.setProgress(20, "Loading Preferences...");

        // CUI-5000 - Naman Kumar
        // Whenever a search results in fetch context from server, we do not need to reload preferences again.
        // Preferences should only be loaded once, upon successful login.
        if (self.rootScopeService.arePreferencesLoadedFlag === false)
            self.preferencesService.init();
    }

    favoritesLoadedListener(comp: any) {
        let self = <LoginComponent>comp;
        self.loginService.setProgress(20, "Loaded Favorites...");
    }


    preferencesLoadedListener(comp: any) {
        let self = <LoginComponent>comp;
        self.loginService.setProgress(20, "Loading Favorites...");
        self.favoritesService.init();
        self.inputTypePassword = true;
    }


    login(dbLogin: boolean = false) {
        this.rootScopeService.systemError = '';

        let request: any = {};
        request.UserID = this.userId;
        request.UserPassword = this.userPassword;
        request.DatabasePassword = dbLogin ? this.dbPassword : '';
        request.DasConnectString = '';
        request.WebServiceUrl = this.configurationService.configuration.serviceUrl;

        if (!dbLogin) this.loginService.resetDBLoginFailCount();
        this.loginService.login(request);
    };

    TriggerDBLoginListener(comp: any, dbPassword: any) {
        let self = <LoginComponent>comp;
        self.dbPassword = dbPassword;
        self.login(true);
    }


    configurationLoadedListener(comp: any, dbPassword: any) {
        let self = <LoginComponent>comp;
        self.configurationLoaded = true;

        // reset performance counter from local storage
        let userThreshold = self.uiLocalStorageService.get(Models.LocalStorageConstants.refreshBrowserSessionThreshold);
        if (userThreshold != null) {
            self.rootScopeService.threshold = userThreshold;
        }
        else {
            self.rootScopeService.threshold = defaultThreshold;
        }

        if (self.rootScopeService.threshold < defaultThreshold) self.rootScopeService.threshold = defaultThreshold;

        if ($.cookie('refresh_session') != null) {
            self.sessionService.restoreSession();
            self.rootScopeService.isAuthenticated = true;
            self.loginService.postLoginPromise();
            return;
        }

        //auto login for SSO
        if (self.sessionService.ssoEnabled === true) {
            var ssoToken1 = $.cookie('ssoToken1');
            if (ssoToken1 != null && ssoToken1 != '') {
                self.rootScopeService.systemError = '';

                let request: any = {};
                request.UserID = ssoToken1;
                request.UserPassword = '';
                request.DatabasePassword = '';
                request.DasConnectString = '';
                request.WebServiceUrl = self.configurationService.configuration.serviceUrl;
                request.IsSSO = true;
                // request = JSON.stringify(request);
                self.loginService.login(request);
                // maintain session token in session service to be used upon logout.
                self.sessionService.sessionToken = $.cookie('ssoToken2')

                //clear tokens from the cookie.
                $.removeCookie('ssoToken1', { path: '/' });
                $.removeCookie('ssoToken2', { path: '/' });
            }
            else {
                window.location.href = "index.html";
            }
        }


        //create debug session
        var debugToken = self.getParameterByName('debugToken');
        var debugUserId = self.getParameterByName('userId');
        if (debugToken != null && debugUserId != null) {
            var request: any = {};
            request.SessionToken = debugToken;
            request.WebServiceURL = self.configurationService.configuration.serviceUrl;
            self.loginService.debugSession(request, debugUserId);
        }
        else {
            setTimeout(() => {
                let userIdElement = document.getElementById("UserID") as HTMLInputElement;
                userIdElement.focus();
                userIdElement.select();
            }, 250);

        }
    }


    //Get Query String Value
    getParameterByName(name: string, url: string = null) {

        if (!url) url = window.location.href;
        url = decodeURIComponent(url);
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        url = null;
        if (!results) return null;
        if (!results[2]) return '';
        return encodeURIComponent(results[2]);
        //.replace(/\+/g, " ")
    }

    resetButton(reset: any) {
        var msg = reset;
        this.rootScopeService.systemError = '';
        this.loginService.loginUnderProgress = false;
    };

    showChangePassword(event: any = null) {
        // Event will be null for click actions. If it is a keyboard event, event will be passed on by the component html.
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            // In case of click, event will be null. Therefore, prevent null reference errors by adding this check.
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.changePasswordService.openChangePasswordDialog();
            this.eventsService.broadcast("PasswordChangeDialogOpened");
            return;
        }
    }
}