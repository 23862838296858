/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component} from "@angular/core";
import {FileService, FocusService, ServerCommandService, SubMenuDialogService} from "../../../services";
import * as Models from "../../../models";

declare var $: any;

@Component({
    selector: 'filedownload',
    template: require('./file-download.component.html')
})
export class FileDownloadComponent {
    constructor(private fileService: FileService, private focusService: FocusService,
                private serverCommandService: ServerCommandService) {

    }

    /**
     * Close the Download file dialog
     */
    closeDownloadFileDialog() {
        this.fileService.isDownloadOpen = false;
        $('#open-file .modal').modal('hide');
        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.processUpdate);
    }

    /**
     * Tab on close button should focus on Download button
     * @param event
     */
    fileCloseBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("fileDownloadBtn", true);
        }
    }
}