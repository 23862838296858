/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Inject, OnInit } from "@angular/core";
import {
    EventsService,
    HelpService,
    NavigateService,
    SearchService,
    ConfigurationService,
    PreferencesService,
    FocusService
} from "../../services";
import * as Models from "../../models";

declare var $: any;

@Component({
    selector: 'navigatemodal',
    template: require('./navigate-modal.component.html'),
})
export class NavigateModalComponent implements OnInit {
    currentApplication: any;
    currentIndex: number = 0;
    applications: any = {};
    treeTemplate: any = {
        Menu: {
            CurrentItems: [],
            Folders: [],
        }
    };
    colleagueApps: any;
    hideSpinner: boolean = true;

    constructor(private preferencesService: PreferencesService,
        private configurationService: ConfigurationService,
        private searchService: SearchService,
        private eventsService: EventsService,
        private navigateService: NavigateService,
        private helpService: HelpService,
        private focusService: FocusService) {
    }

    ngOnInit() {
        this.currentApplication = this.navigateService.selectedApplication;
        this.applications = this.navigateService.fullApplications;
        this.colleagueApps = this.navigateService.applications;
    }

    /**
     * Displays help for Navigate dialog
     */
    help(event: any) {
        if (event == null || (event.keyCode == Models.KeyCodes.enter || event.keyCode == Models.KeyCodes.space)) {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.Navigation)
        }
    }

    /**
     * Load menu items for a selected application
     * @param apps
     */
    loadTreeMenu(apps: any) {
        if (apps != this.currentApplication) {
            this.currentApplication = this.navigateService.currentApplication(apps);
            if (!this.isLocalStorage()) {
                this.navigateService.getMenu(this.currentApplication, this.currentApplication, this.currentApplication, this.getMenuCallBack, this);
            } else {
                for (let i = 0; i < this.navigateService.applicationsMenu.length; i++) {
                    if (this.navigateService.applicationsMenu[i].Application == this.currentApplication) {
                        this.applications[this.currentApplication].Menu = this.navigateService.applicationsMenu[i].menu;
                        //If folders are open close all folders in initial load
                        for (let j = 0; j < this.applications[this.currentApplication].Menu.Folders.length; j++) {
                            let tmp: any = this.applications[this.currentApplication].Menu.Folders[i];
                            this.navigateService.collapseChildFolders(tmp);
                        }
                        break;
                    }
                }
            }
        }
    }

    /**
     * Close the modal
     * @param event
     */
    close(event: any) {
        if (event == null || (event.keyCode == Models.KeyCodes.enter || event.keyCode == Models.KeyCodes.space)) {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
        }
    }

    /**
     * Shift Tab on the first application button should focus on the last record in the Navigation Menu
     * @param event
     * @param ind
     */
    btnApplicationKeydown(event: any, ind: number) {
        // Only process if the shift+tab action was on the first button
        if (ind == 0) {
            let tab: boolean = event.keyCode == Models.KeyCodes.tab;
            let shift: boolean = event.shiftKey;

            if (shift && tab) {
                let navigationItems = $(".navgation-body").find(".navigation-menu-font.item, .text-black.item");
                if (navigationItems != null && navigationItems.length > 0) {
                    let lastItem = navigationItems[navigationItems.length - 1];
                    event.preventDefault();
                    event.stopPropagation();
                    this.focusService.focusOn(lastItem.id, true);
                }
            }
        }
    }

    /**
     * Navigate keyboard shortcut for Help
     * @param event
     */
    navigateModalKeyDown(event: any) {
        let keyCtrl = event.ctrlKey;
        let keyAlt = event.altKey;
        let keyCode = event.keyCode;
        let keyCharCode = event.charCode;
        let keyChar = String.fromCharCode(keyCode || keyCharCode);

        if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
            event.stopPropagation();
            event.preventDefault();
            this.help(null);
        }
        else {
            return;
        }
    }

    /**
     * Handle the response when server sends menu items back for a given application
     * @param menuResponse
     * @param navigateModalComponent
     */
    private getMenuCallBack(menuResponse: any, navigateModalComponent: any) {
        let self: NavigateModalComponent = <NavigateModalComponent>navigateModalComponent;
        let resp: any = self.navigateService.addParent(menuResponse);
        self.applications[self.currentApplication].Menu = resp.Menu;
        self.navigateService.applicationsMenu.push({
            Application: self.currentApplication,
            menu: resp.Menu
        });   
        //store it in service to use frequent selection
        self.navigateService.disabled = false;

        
        setTimeout(() => {
            let selectedElement = document.querySelector("#" + self.navigateService.selectedApplication + ">.btn-application-menu") as HTMLElement;
            selectedElement.focus();
        }, 250);
    }

    /**
     * Check if navigate service already contains the items for a given application
     * @returns {boolean}
     */
    private isLocalStorage() {
        let isHavingdata: boolean = false;
        let menu: any = this.navigateService.applicationsMenu;
        for (let i = 0; i < menu.length; i++) {
            if (menu[i].Application == this.currentApplication) {
                isHavingdata = true;
                break;
            }
        }
        return isHavingdata;
    }
}