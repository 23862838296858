/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Models from "../models";
import { ConfigurationService, EventsService, FocusService, HelpService, RootScopeService, SearchService, ServerCommandService, SessionService } from "./";

declare var $: any;
declare var window: any;

@Injectable()
export class AboutService {
    userName: string = "";
    buildVersion: "N/A";
    buildDate: "N/A";
    envisionVersion: string = "";
    colleagueVersion: string = "";
    sessionValues: string = "";
    sslEnabled: boolean = false;
    isOpen: boolean = false;

    constructor(private eventsService: EventsService, private http: HttpClient, private rootScopeService: RootScopeService,
        private searchService: SearchService, private helpService: HelpService,
        private configurationService: ConfigurationService, private sessionService: SessionService,
        private serverCommandService: ServerCommandService, private focusService: FocusService) {
        this.eventsService.on(Models.CommandConstants.preloadData, [this.preLoadData, this]);
        this.eventsService.on("LaunchHyperlinkMessage", [this.launchHyperlinkMessageListener, this]);
        this.eventsService.on("SessionInfoMessage", [this.sessionInfoMessageListener, this]);
    }

    preLoadData(service: any) {
        let self = <AboutService>service;
        self.userName = self.sessionService.getUserId();
        self.getAboutUs();
    }

    display() {
        this.isOpen = true;
        this.serverCommandService.sendProcessCommandMessage("SessionInfo");
        $("#about .modal").modal({
            show: true,
            keyboard: true
        }).draggable();
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    }

    processHelp() {
        this.serverCommandService.sendProcessCommandMessage("ProcessHelp");
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    }

    fieldHelp() {
        this.serverCommandService.sendProcessCommandMessage("FieldHelp");
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    }

    listKeyboardShortcutsPC() {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses.KeyboardShortcutsPC);
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    };


    closeLeftSidebar() {
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    };


    // Fetch about us data from the server.
    getAboutUs() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'version'].join('/');
        this.http.get(url, httpOptions).subscribe((response: any) => {
            this.buildDate = response.BuildDate;
            this.buildVersion = response.Version;
            this.rootScopeService.colleagueUiBuildVersion = response.Version;
        });
    }

    launchHyperlinkMessageListener(service: any, msg: any) {
        let helpWindow = window.open(msg.TargetURL, "UI_HELP");
        if (helpWindow != null)
            helpWindow.focus();
    }

    sessionInfoMessageListener(service: any, message: any) {
        let self = <AboutService>service;

        self.sslEnabled = self.configurationService.getConfiguration().serviceUrl.toLowerCase().indexOf("https:") == 0;

        if (message.IsEnvironmentLimitingEnabled) {
            //is there a user maximum?
            if (message.UserMaximumSessions > -1) {
                self.sessionValues = message.UserCurrentSessions + " of " + message.UserMaximumSessions + " used ";
            } else {
                //no user maximum - is there an env maximum?
                if (message.EnvironmentMaximumSessions > -1) {
                    self.sessionValues = message.UserCurrentSessions + " of " + message.EnvironmentMaximumSessions +
                        " spots shared by all users (" + (message.EnvironmentMaximumSessions - message.EnvironmentCurrentSessions) + " remaining)";
                } else {
                    self.sessionValues = message.UserCurrentSessions + " (Unlimited)";
                }
            }
        } else {
            if (message.UserMaximumSessions > -1) {
                self.sessionValues = message.UserCurrentSessions + " of " + message.UserMaximumSessions + " used ";
            } else {
                self.sessionValues = message.UserCurrentSessions + " (Unlimited)";
            }
        }

        self.colleagueVersion = message.ColleagueVersion;
        self.envisionVersion = message.EnvisionVersion;
    }

    close() {
        this.isOpen = false;

        setTimeout(() => {
            this.focusService.selectPreviousField();
        }, 100);
    }
}