/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import "core-js/es6";
import "reflect-metadata";
import "zone.js/dist/zone";

import "@angular/platform-browser";
import "@angular/platform-browser-dynamic";
import "@angular/core";
import "@angular/common";
import "@angular/forms";
import "@angular/router";
import "rxjs";