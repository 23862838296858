/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import * as Models from "../models";
import {
    ConfigurationService,
    ContextService,
    EventsService,
    FocusService,
    FormService,
    PreferencesService,
    ProcessingService,
    PromptService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    ValidationService
} from "./";

import * as _ from 'lodash';

@Injectable()
export class SearchResultsService {
    // Variables declared to be used in call backs
    elementToFocus: string = "";
    messageToRead: string = "";
    gridColumnOrders: any = {};
    selectAll: boolean = false;
    selectAllPreviouslySelected: boolean = false;
    alreadyFocused: boolean = false;

    constructor(private searchService: SearchService,
        private preferencesService: PreferencesService,
        private validationService: ValidationService,
        private notificationsService: PromptService,
        private contextService: ContextService,
        private processingService: ProcessingService,
        private serverCommandService: ServerCommandService,
        private eventsService: EventsService,
        private configurationService: ConfigurationService,
        private rootScopeService: RootScopeService, private formService: FormService, private focusService: FocusService) {
    }

    nextPage(data: any, rowIndex: number, cellIndex: number): void {
        if (this.isNextAllowed(data)) {
            // Page size
            let cursorSize: number = data.control.cursorSize;
            // First record's index on the page
            let startRow: number = data.results.currentRow + cursorSize;
            if (startRow > data.results.totalRows)
                startRow = data.results.totalRows;

            // Check if there is a valid row index
            if (rowIndex != null && rowIndex >= 0) {
                // Message to read if verbose access is set to true
                this.messageToRead = "Navigating to next page. Hit OK to continue.";
                // Element to focus ID -- for grid
                let renderType: string = data.layout.renderType.toLowerCase();

                if (renderType.toLowerCase() == "card") {
                    this.elementToFocus = "tr[class$=sr_" + renderType + "_" + rowIndex;
                }
                // If previous page request came from a cell, since we don't know the actual index of the row, we have to rely on the selector which startsWith the pattern 'sr_grid_rowNumber_cellIndex'
                else if (renderType.toLowerCase() == "grid") {
                    this.elementToFocus = cellIndex != null && cellIndex >= 0 ? "td[id^=sr_grid_" + rowIndex + "_" + cellIndex : "#sr_" + renderType + "_" + rowIndex;
                }

                // Call new page search
                this.newPageSearch(startRow, data, this.searchSuccessCallBack, null, this);
            }
            // If rowIndex is -1, it means the request came from search results Input
            else if (rowIndex == -1) {
                this.messageToRead = "Navigating to next page. Hit OK to continue.";
                this.elementToFocus = this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch ? "#form-sr-input" : "#non-form-sr-input";
                this.newPageSearch(startRow, data, this.searchSuccessCallBack, null, this);
            }
        }
    }

    previousPage(data: any, rowIndex: number, cellIndex: number) {
        if (this.isPreviousAllowed(data)) {
            // Page size
            let cursorSize: number = data.control.cursorSize;
            // First record's index on the page
            let startRow: number = data.results.currentRow - cursorSize;


            // Check if there is a valid row index
            if (rowIndex != null && rowIndex >= 0) {
                // Message to read if verbose access is set to true
                this.messageToRead = "Navigating to previous page. Hit OK to continue.";
                // Element to focus ID -- for grid
                let renderType: string = data.layout.renderType.toLowerCase();

                if (renderType.toLowerCase() == "card") {
                    this.elementToFocus = "tr[class$=sr_" + renderType + "_" + rowIndex;
                }
                // If previous page request came from a cell, since we don't know the actual index of the row, we have to rely on the selector which startsWith the pattern 'sr_grid_rowNumber_cellIndex'
                else if (renderType.toLowerCase() == "grid") {
                    this.elementToFocus = cellIndex != null && cellIndex >= 0 ? "td[id^=sr_grid_" + rowIndex + "_" + cellIndex : "#sr_" + renderType + "_" + rowIndex;
                }

                // Call new page search
                this.newPageSearch(startRow, data, this.searchSuccessCallBack, null, this);
            }
            // If rowIndex is -1, it means the request came from search results Input
            else if (rowIndex == -1) {
                // Message to read if verbose access is set to true
                this.messageToRead = "Navigating to previous page. Hit OK to continue.";
                this.elementToFocus = this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch ? "#form-sr-input" : "#non-form-sr-input";
                this.newPageSearch(startRow, data, this.searchSuccessCallBack, null, this);
            }
        }
    }

    firstPage(data: any, focusOnInput: boolean = false): void {
        if (this.isPreviousAllowed(data)) {
            // Message to read if verbose access is set to true
            this.messageToRead = "Navigating to first page. Hit OK to continue.";
            if (focusOnInput == false) {
                // Element to focus ID -- for grid
                if (data.layout.renderType.toLowerCase() == "card")
                    this.elementToFocus = "#sr_card_0_0";
                else if (data.layout.renderType.toLowerCase() == "grid")
                    this.elementToFocus = "#sr_grid_0"
            }
            else if (focusOnInput == true) {
                this.elementToFocus = this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch ? "#form-sr-input" : "#non-form-sr-input";
            }
            // Call new page search
            this.newPageSearch(1, data, this.searchSuccessCallBack, null, this);
        }
    }

    lastPage(data: any, focusOnInput: boolean = false): void {
        if (this.isNextAllowed(data)) {
            let startRow: number;
            let cursorSize: number = data.control.cursorSize;
            let remainder: number = (data.results.totalRows % cursorSize);
            if (remainder === 0) {
                startRow = data.results.totalRows - cursorSize + 1;
            } else {
                startRow = data.results.totalRows - remainder + 1;
            }

            // Message to read if verbose access is set to true
            this.messageToRead = "Navigating to last page. Hit OK to continue.";

            if (focusOnInput == false) {
                // Element to focus ID -- for grid
                this.elementToFocus = "tr[class$=sr_" + data.layout.renderType.toLowerCase() + "_" + (data.results.totalRows - 1);
            }
            else if (focusOnInput == true) {
                this.elementToFocus = this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch ? "#form-sr-input" : "#non-form-sr-input";
            }
            // Call new page search
            this.newPageSearch(startRow, data, this.searchSuccessCallBack, null, this);
        }
    }

    /**
     * Opens the records selected in either grid or card view. If selectAllRecordsFlag is true,
     * @param resultData
     * @param selectAllRecordsFlag
     */
    openSelected(resultData: any, selectAllRecordsFlag: boolean = false, context: string = null) {
        let recordsToAdd: any = [];
        let idsToAdd: any = [];

        // If select all checkbox is checked, open all records in the context area. Otherwise, open only the records that are in selectedRowNumbers
        if (selectAllRecordsFlag == true) {
            // Reset selectedRowNumbers
            this.rootScopeService.selectedRowNumbers.length = 0;
            this.rootScopeService.searchResultRecordsSelected = 0;
            _.each(resultData.searchResults.data, (r: any) => {
                recordsToAdd.push(r);
                idsToAdd.push(r["@ID"]);
                this.rootScopeService.selectedRowNumbers.push(r["@ID"]);
            });
            // Clear unselectedRowNumbers
            this.rootScopeService.unselectedRowNumbers.length = 0;
        }
        // If select all checkbox is unchecked, open records that exist in selectedRowNumbers array.
        else {
            for (let i = 0; i < this.rootScopeService.selectedRowNumbers.length; i++) {
                let record = _.find(resultData.results.data, (s: any) => {
                    return s["@ID"] == this.rootScopeService.selectedRowNumbers[i];
                });
                recordsToAdd.push(record);
                idsToAdd.push(record["@ID"]);
            }
        }

        // If all records were fetched from the server before opening, context will be in resultData.searchResults otherwise context will be in resultData.layout
        let contextToMatch: string = this.validationService.isNullOrEmpty(context) ? (selectAllRecordsFlag == true ? resultData.searchResults.context : resultData.layout.context) : context;

        // If current search is not a Generic search and the context is person, broadcast a loadPersonContextData request.
        if (!this.searchService.state.isGenericSearch) {
            if (["PERSON", "ORG.PERSON", "STAFF", "CORP"].indexOf(contextToMatch) >= 0) {
                this.eventsService.broadcast(Models.CommandConstants.loadPersonContextData, recordsToAdd);
            }
        }
        else {
            // If context form is open and context is person and autoLoad property is set to true and there is only one form open, broadcast a loadPersonContextData request.
            if (this.contextService.openContextForm && (["PERSON", "ORG.PERSON", "STAFF", "CORP"].indexOf(contextToMatch) >= 0) && resultData.control.autoLoad == true && this.formService.forms.length == 1) {
                this.eventsService.broadcast(Models.CommandConstants.loadPersonContextData, recordsToAdd);
                this.searchService.resetState();
            }
            else {
                let listOfpersonId = idsToAdd.join('|');
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", listOfpersonId);
                this.searchService.resetState();
            }
        }

        // Close all popovers once items have been opened in context area.

        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover, this.rootScopeService.isFormOpen == false);
        this.searchService.state.isGenericSearch = false;
        this.searchService.toggleVisible();
        if (this.rootScopeService.isFormOpen) {
            this.focusService.focusPreviousField();
        }
    }

    newPageSearch(startRow: number, data: any, resultsCallBack: any, failureCallBack: any = null, resultsCallBackParam: any = null, queryParams: any = null): void {
        let successCallback: any = function (hideSearchResults: boolean, searchResultsParams: any) {

            //TODO: Implement when selection toggle is implemented.
            // autoSelectAll();

            let self: SearchResultsService = <SearchResultsService>searchResultsParams[2];
            self.setPrivacyFlag(searchResultsParams[1]);
            self.selectRecords(searchResultsParams[1]);

            self.processingService.closeProcessing();

            if (resultsCallBack) {
                resultsCallBack(searchResultsParams[0]);
                self.eventsService.broadcast("RefreshResultPage");
            }
        };

        this.processingService.showProcessing("New Page Search");
        this.searchService.search(this.getCurrentSearchContext(data), data.results.cursorHandle, startRow, data.control.cursorSize, queryParams, "", "", "", successCallback, failureCallBack, null, [resultsCallBackParam, data, this]);
    }

    /**
     * If Select All was clicked, when view is changed, automatically select all records in this view. If select all was not clicked, highlight records based on selectedRowNumbers
     * This is needed because when we switch between the two views, a new search is performed and data is reset.
     */
    selectRecords(data: any): void {
        if (this.selectAll == true) {
            // Reset selected row numbers
            this.rootScopeService.selectedRowNumbers.length = 0;
            this.rootScopeService.searchResultRecordsSelected = 0;
            // Loop through all records, and push the records in selectedRowNumbers and highlight each record by setting recordSelected to true
            for (let d: number = 0; d <= data.results.data.length - 1; d++) {
                this.rootScopeService.selectedRowNumbers.push(data.results.data[d]["@ID"]);
                data.results.data[d].recordSelected = true;
            }
            // Clear unselectedRowNumbers
            this.rootScopeService.unselectedRowNumbers.length = 0;
            this.rootScopeService.searchResultRecordsSelected = this.selectAllPreviouslySelected === true
                ? data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                : this.rootScopeService.selectedRowNumbers.length;
        }
        else if (this.selectAllPreviouslySelected == true) {
            // If select all checkbox was checked and then some items were individually unchecked, and then the user opened another page,
            _.each(data.results.data, (d: any) => {
                if (this.rootScopeService.unselectedRowNumbers.indexOf(d["@ID"]) < 0) {
                    d.recordSelected = true;
                }
            });
        }
        else {
            _.each(data.results.data, (d: any) => {
                if (this.rootScopeService.selectedRowNumbers.indexOf(d["@ID"]) >= 0) {
                    d.recordSelected = true;
                }
                else {
                    d.recordSelected = false;
                }
            });
        }
    }

    setPrivacyFlag(srData: any): void {
        let record: any;
        for (let i = 0; i < srData.results.data.length; i++) {
            record = srData.results.data[i];

            //Continue to next record if there is no privacy code associated with this search record.
            if (record == null || record['PRIVACY.FLAG'] == null || (record['PRIVACY.FLAG'] != null && record['PRIVACY.FLAG'].length == 0)) {
                record.searchResultPrivacyMessage = null;
                continue;
            }

            if (record['PRIVACY.FLAG'].constructor != Array)
                record['PRIVACY.FLAG'] = [record['PRIVACY.FLAG']];

            if (["PERSON", "ORG.PERSON", "STAFF", "CORP"].indexOf(srData.results['context']) >= 0) {
                record.searchResultPrivacyMessage = this.configurationService.getPersonRecordDenialMessage();
            }

            _.each(this.preferencesService.savedPreferences.PrivacyCodeAccess, (prefPrivacyCode: any) => {
                _.each(record['PRIVACY.FLAG'], (pFlag: any) => {
                    if (pFlag === prefPrivacyCode) {
                        record.searchResultPrivacyMessage = null;
                    }
                });
            });
        }
    }


    getCurrentSearchContext(data: any) {
        if (this.searchService.state.isGenericSearch === true) {
            return data.results.context;
        } else {
            return this.searchService.state.isFormSearch ? "FORM" : "PERSON";
        }
    }

    /**
     * Displays error prompt
     * @param textToRead string or string[]
     * @param title string
     * @param elementToFocus id of element to focus
     */
    displayErrorPrompt(textToRead: any, title: string, elementToFocus: string = null): void {
        this.alreadyFocused = true;
        //pop up to display the message
        let popupMessage: any = {};
        if (!this.validationService.isNullOrEmpty(title))
            popupMessage.title = title;

        // if message is string
        if (textToRead instanceof String || typeof textToRead === "string") {
            popupMessage.text = [
                textToRead
            ];
        }
        else { // if message is array of string.
            popupMessage.text = textToRead;
        }

        popupMessage.buttons = [
            {
                label: Models.LabelConstants.ok,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        setTimeout(() => {
                            if (!this.validationService.isNullOrEmpty(elementToFocus)) {
                                $(elementToFocus).trigger("focus");
                            } else {
                                this.searchService.focusOnMainInput();
                            }
                        }, 250);
                        this.processingService.closeProcessing();
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = "1";
        this.notificationsService.popupMessageShow(popupMessage);
    }

    /**
     * Opens a selected form in Form search results
     * @param form
     */
    openForm(form: any): void {
        if (form != null) {
            this.rootScopeService.analyticsLastFormOpen = form['@ID'];
            let fullMnemonic: string = form['PROCESS.APPLICATION'] + "-" + form['@ID'];
            this.searchService.selectedForm.form = {};
            this.searchService.selectedForm.isSelection = null;
            this.searchService.searchInput.value = "";
            this.rootScopeService.analyticsLastFormOpen = form['@ID'];
            this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", fullMnemonic, "", "", "");
            this.searchService.resetState();

            let historyList: any = this.searchService.criteria.formHistory;
            for (let i = 0; i < historyList.length; i++) {
                if (historyList[i] != null) {
                    // Split form search history record on '$$$'. Part 0 provides the key in format FormMnemonic: FullFormName. Further split it at ':' and get the first part.
                    // Match this with the second part of search array item. If they match, the user is searching for a form that is already in the history. Therefore, send LaunchForm command.
                    let splitHistoryFormName = historyList[i].split('$$$')[0].split(':')[0];
                    if (splitHistoryFormName.toUpperCase() == form['@ID'].toUpperCase()) {
                        // Existing form search history record found, remove it and later, add the newly selected item
                        historyList.splice(i, 1);
                        break;
                    }
                }
            }
            this.searchService.addToHistory(form['@ID'] + ': ' + form['PROCESS.DESCRIPTION'], true, fullMnemonic, true);
        } else {
            //pop up to form
            this.searchService.searchInput.value = "";
            this.displayErrorPrompt(Models.SearchResultConstants.formPromptMessageText, "Error");
        }
    }

    screenReaderPromptMessage(textToRead: string, title: string, elementToFocusId: string) {
        //pop up to display the message
        let popupMessage: any = {};
        if (!this.validationService.isNullOrEmpty(title))
            popupMessage.title = title;
        popupMessage.text = [
            textToRead
        ];
        popupMessage.buttons = [
            {
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        if (!this.validationService.isNullOrEmpty(elementToFocusId)) {
                            this.focusService.focusOn(elementToFocusId, true);
                        }
                        this.eventsService.broadcast(Models.EventConstants.closeAlert);
                    }, false);
                }
            }
        ];
        popupMessage.defaultCallbackNumber = "1";
        this.notificationsService.popupMessageShow(popupMessage);
    }

    private isNextAllowed(data: any): boolean {
        // next not allowed if it is a form search
        if (this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch) {
            return false;
        }

        if (data.results != null) {
            return data.results.currentRow + data.control.cursorSize <= data.results.totalRows;
        }
        return false;
    }

    private isPreviousAllowed(data: any): boolean {
        // previous not allowed if it is a form search
        if (this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch) {
            return false;
        }
        if (data.results != null) {
            return data.results.currentRow > 1;

        }
        return false;
    }

    private searchSuccessCallBack(searchResultsService: any): void {
        let self: SearchResultsService = <SearchResultsService>searchResultsService;
        self.searchService.setSearchTooltip();
        if (!self.validationService.isNullOrEmpty(self.elementToFocus)) {
            if (self.preferencesService.dialogPreferences.VerboseAccess == true && !self.validationService.isNullOrEmpty(self.messageToRead)) {
                self.displayErrorPrompt(self.messageToRead, "", self.elementToFocus);
            }
            else {
                self.alreadyFocused = true;
                setTimeout(() => {
                    $(self.elementToFocus).trigger("focus");
                }, 200);
            }
        }
    }
}