/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import * as Models from '../models';
import {
    EventsService,
    RootScopeService,
    PromptService,
    HelperService,
    ConfigurationService,
    ValidationService,
    ContextService,
    ImageService,
    SessionService,
    UiLocalStorageService,
    ServerCommandService,
    SessionStorage,
    PreferencesService,
    FocusService
} from './';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import * as _ from 'lodash';

@Injectable()
export class SearchService {
    inProgress: boolean = false;
    searchTypeKey = "ST";
    generalFormHistoryKey = "SGFH";
    generalPersonHistoryKey = "SGPH";
    invalidSearchChars = ["(", ")", "[", "]", "{", "}", ":"];
    state: any = {};
    criteria: any = {};
    cache: any = {};
    selectedForm: any = { form: { highlight: false } };
    searchInput: any = {};
    args: any = {};
    cursorHandles: any = [];
    responseInterval: any = null;
    responseUnderProcess: boolean = false;
    pendingSearchRequest: any;

    constructor(private uiLocalStorageService: UiLocalStorageService, private contextService: ContextService,
        private focusService: FocusService,
        private validationService: ValidationService, private preferencesService: PreferencesService,
        private configurationService: ConfigurationService, private sessionService: SessionService,
        private http: HttpClient, private serverCommandService: ServerCommandService, private sessionStorage: SessionStorage,
        private imageService: ImageService,
        private eventsService: EventsService, private promptService: PromptService, private rootScopeService: RootScopeService, private helperService: HelperService) {
        this.eventsService.on(Models.EventConstants.postLogin, [this.loginCompleteEventListener, this]);
        this.eventsService.on(Models.ToggleSearchCategory.closeleftSidebar, [this.closeLeftSidebarEventListener, this]);
        this.eventsService.on(Models.ToggleSearchCategory.closePopover, [this.closeSearchPopoverEventListener, this]);
        this.eventsService.on(Models.CommandConstants.updatePersonMatchItems, [this.autoCompletePersonMatchItems, this]);
        this.eventsService.on(Models.CommandConstants.updateFormMatchItems, [this.autoCompleteFormMatchItems, this]);
        this.eventsService.on(Models.CommandConstants.addToHistory, [this.addToHistoryListener, this]);
        this.eventsService.on("preferencesLoaded", [this.preferencesLoadedListener, this]);
        this.eventsService.on("cancelPersonSearch", [this.cancelSearch, this]);
    }

    autoCompletePersonMatchItems(service: any, input: string) {
        let self = <SearchService>service;

        var searchList = self.criteria.personHistory;
        self.state.isAutoCompleteform = false;
        self.state.isAutoCompleteperson = true;
        while (self.rootScopeService.criteria.autoCompleteMatch.length > 0) {
            self.rootScopeService.criteria.autoCompleteMatch.pop();
        }
        let firstArray = [];
        let secondArray = [];
        if (input.indexOf("\\") > -1) return;
        let match = input.toUpperCase();
        for (var i = 0; i < searchList.length; i++) {
            if (searchList[i] != null) {
                try {
                    if (searchList[i].toUpperCase().substring(0, 1) === match) {
                        firstArray.push(searchList[i]);
                    } else if (searchList[i].toUpperCase().match(match)) {
                        secondArray.push(searchList[i]);
                    }
                    self.rootScopeService.criteria.autoCompleteMatch = firstArray.concat(secondArray);
                }
                catch (err) {

                }
            }
        }
    }

    autoCompleteFormMatchItems(service: any, input: string) {
        let self = <SearchService>service;

        self.state.isAutoCompleteform = true;
        self.state.isAutoCompleteperson = false;
        var searchList = self.criteria.formHistory;

        while (self.rootScopeService.criteria.autoCompleteMatch.length > 0) {
            self.rootScopeService.criteria.autoCompleteMatch.pop();
        }
        let firstArray = [];
        let secondArray = [];
        if (input.indexOf("\\") > -1) return;
        let match = input.toUpperCase();
        for (var i = 0; i < searchList.length; i++) {
            try {
                if (searchList[i].toUpperCase().substring(0, 1) === match) {
                    firstArray.push(searchList[i]);
                } else if (searchList[i].toUpperCase().match(match)) {
                    secondArray.push(searchList[i]);
                }
                self.rootScopeService.criteria.autoCompleteMatch = firstArray.concat(secondArray);
            } catch (err) {

            }
        }

    }

    toggleSearchOptions(category: string, focus: boolean = true) {
        switch (category) {
            case Models.ToggleSearchCategory.closePopover:
                if (this.state.PersonSearchResults) {
                    this.toggleVisible(false);
                }
                this.resetState();
                if (focus) this.focusOnSearchInput();
                break;
            case Models.ToggleSearchCategory.toggleFormPopover:
                this.resetState();
                this.state.searchformAutocomplete = true;
                break;
            case Models.ToggleSearchCategory.search:
                this.resetState();
                if (this.state.isFormSearch && !this.state.isGenericSearch) {
                    this.state.FormSearchResults = true;
                } else {
                    this.state.PersonSearchResults = true;
                }
                this.setSearchTooltip();
                break;
            case Models.ToggleSearchCategory.leftPersonSidebar:
                this.state.showPersonSidebar = !this.state.showPersonSidebar;
                break;
            case Models.ToggleSearchCategory.leftHelpSidebar:
                this.state.showHelpSidebar = !this.state.showHelpSidebar;
                break;
            case Models.ToggleSearchCategory.closeleftSidebar:
                this.state.showHelpSidebar = false;
                this.state.showPersonSidebar = false;
                break;
            case Models.ToggleSearchCategory.toggleNavigationPopover:
                this.resetState();
                this.eventsService.broadcast("openNavigate");
                this.state.ShowNavigationMenu = true;
                break;
            case Models.ToggleSearchCategory.togglePersonAdvancedSearchPopover:
                this.resetState();
                this.state.PersonAdvancedSearch = true;
                break;
            default:
                console.error(category);
                break;
        }
    }

    loginCompleteEventListener(searchService: any) {
        let self = <SearchService>searchService;

        self.resetState();

        self.state.showPersonSidebar = false;
        self.state.showHelpSidebar = false;
        self.state.isGenericSearch = false;
        self.state.srData = {};
    }

    preferencesLoadedListener(searchService: any) {
        let self = <SearchService>searchService;

        // Retrieve form search history
        self.criteria.formHistory = self.uiLocalStorageService.get(Models.LocalStorageConstants.formSearchHistory);
        if ((self.criteria.formHistory === undefined) || (self.criteria.formHistory.length == 0)) {
            self.criteria.formHistory = [];
        }

        // Retrieve person search history
        self.criteria.personHistory = self.uiLocalStorageService.get(Models.LocalStorageConstants.personSearchHistory);
        if ((self.criteria.personHistory === undefined) || (self.criteria.personHistory.length == 0)) {
            self.criteria.personHistory = [];
        }

        // Set the search state after the preferences have been loaded and the Search Type is available in local storage
        let savedIsFormSearch = self.uiLocalStorageService.get(Models.LocalStorageConstants.navBarSearchType);
        if (savedIsFormSearch === undefined) {
            self.state.isFormSearch = true;
        } else {
            self.state.isFormSearch = (savedIsFormSearch === "Y" || self.rootScopeService.preferences.PersonSearchAllowed == false) ? true : false;
        }
        // Set initial formless context, form or person
        if (self.state.isFormSearch) {
            self.state.currentContextIndex = "FORM";
        } else {
            self.state.currentContextIndex = "PERSON";
        }
        setTimeout(() => {
            self.focusOnSearchInput();
        }, 250);
    }

    closeSearchPopoverEventListener(searchService: any) {
        let self = <SearchService>searchService;
        self.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
    }

    closeLeftSidebarEventListener(searchService: any) {
        let self = <SearchService>searchService;
        self.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    }

    resetState() {
        if (this.state.srData == null) {
            this.state.srData = {};
        }
        this.state.searchformAutocomplete = false;
        this.state.searchpersonAutocomplete = false;

        this.state.PersonAdvancedSearch = false;
        this.state.PersonSearchResults = false;

        this.state.FormSearchResults = false;
        this.state.ShowNavigationMenu = false;
    }

    buildQueryColumns(contextId: string) {
        let union = _.union(
            this.contextService.getContext(contextId).Header.demandFields,
            _.map(this.contextService.getContext(contextId).Header.displayFields, 'fieldName'), [
                this.contextService.getContext(contextId).Header.leftTitleField,
                this.contextService.getContext(contextId).Header.rightTitleField
            ],
            this.contextService.getContext(contextId).ResultPage.demandFields,
            _.map(this.contextService.getContext(contextId).ResultPage.headerFields, 'fieldName'),
            this.contextService.getContext(contextId).ResultPage.favoriteFields
        );

        return _.compact(union);
    }

    scrubWildcards(searchCriteria: string) {
        // searchCriteria contains the entire search string.  Remove any wildcards.
        // Use regular expressions to filter the string. Allow ellipses for Person Search.  
        // Replace any ellipses (...) with null for Form Search.  Then replace any asterisk (*) with null.
        if (this.state.isFormSearch) {
            searchCriteria = searchCriteria.replace(/\.\.\./g, "").replace(/\*/g, "");
        } else {
            searchCriteria = searchCriteria.replace(/\*/g, "");
        }
        return searchCriteria;
    };

    formatSearchCriteria(searchCriteria: string) {
        // searchCriteria contains the entire search string.  Make sure operators have spaces on both sides
        // Use regular expressions to filter the string
        // First, replace any greater than (>) with greater than and a space (> ).
        // Then replace any less than (<) with less than and a space (< ).
        // Then replace any equal to (=) with equal to and a space (= ).
        searchCriteria = searchCriteria.replace(/>/g, "> ").replace(/</g, "< ").replace(/=/g, "= ");
        // The previous substitutions will have replaced ">=" and "<=" with "> =" and "< =", repsectively.
        // Get rid of the space in between the operators.  Then replace of multiple spaces with a single space.
        searchCriteria = searchCriteria.replace(/>\s=/g, ">=").replace(/<\s=/g, "<=").replace(/\s\s/g, " ");
        return searchCriteria.trim();
    };

    clearCache() {
        this.cache.cursorHandle = "";
        this.cache.cursorSize = 0;
        this.cache.data = {};
        this.cache.startRows = [];
    }

    setCurrentSRData(srData: any) {
        if (srData.searchResults === undefined) {
            srData.searchResults = {};
        }
        if (srData.views === undefined) {
            srData.views = {};
        }
        if (srData.layout === undefined) {
            srData.layout = {};
        }
        if (srData.control === undefined) {
            srData.control = {};
        }
        if (srData.control.error === undefined) {
            srData.control.error = "";
        }
        if (srData.control.sortedFieldName === undefined) {
            srData.control.sortedFieldName = "";
        }
        if (srData.control.sortedAscending === undefined) {
            srData.control.sortedAscending = true;
        }
        this.state.currentSRData = srData;
    }

    parseResults(data: any) {
        let newData = {};
        let numColumns = data.QueryColumns.length;
        let results: any = [];

        _.each(data.Results, function (outerResult: any, outerIndex: any) {
            let result = {};
            _.each(outerResult, function (resultColumnData: any, resultColumnDataIndex: any) {
                // Join multivalue fields into a single value
                let fieldValue = resultColumnData.join(", ");
                let fieldName = data.QueryColumns[resultColumnDataIndex];
                result[fieldName] = fieldValue;
            });

            results.push(result);
        });

        return results;
    }

    searchCache(contextId: any, cursorHandle: any, startRow: any, cursorSize: any, queryColumns: any, searchCriteria: any, sortColumns: any, sortAscending: any, successCallback: any, failureCallback: any, resultsCallback: any, successCallbackParam: any) {
        if (resultsCallback == null) {
            if (this.cache.cursorHandle !== cursorHandle) {
                this.clearCache();
            } else {
                if (this.cache.cursorSize === cursorSize && this.cache.startRows.includes(startRow)) {
                    let cachedData: any = this.cache.data;
                    if (cachedData !== undefined) {
                        let srData = this.getCurrentSRData();
                        srData.searchResults.currentRow = startRow;
                        srData.searchResults.data = cachedData;
                        if (successCallback !== undefined) {
                            successCallback(false, successCallbackParam);
                        }
                        return true;
                    }
                }
            }
        }
        return false;
    }

    getCurrentSRData() {
        let srData = this.state.srData[this.state.currentContextIndex];
        if (srData === undefined) {
            this.state.srData[this.state.currentContextIndex] = {}
            srData = this.state.srData[this.state.currentContextIndex];
            srData.control = {};
            srData.layout = {};
        }
        return srData;
    }

    clearResults() {
        let srData = this.getCurrentSRData();
        srData.searchResults = {};
        srData.views = {};
        srData.control = {};
        this.setCurrentSRData(srData);
        this.state.newSearch = true;
    };

    //Stop searching if cancel button on wait dialog is pressed
    cancelSearch(searchService: any) {
        let self = <SearchService>searchService;
        self.pendingSearchRequest.unsubscribe();
        self.cursorHandles = [];
    };

    focusOnMainInput() {
        setTimeout(() => {
            if (this.state.isFormSearch && !this.state.isGenericSearch) {
                this.focusService.focusOn("#form-sr-input");
            } else {
                this.focusService.focusOn("#non-form-sr-input");
            }
        }, 300);
    };

    //TODO: Check if required
    toggleRunning(force: any, msgText: any = null, noCancel: any = null, searchtext: any = null) {
        // if (force === undefined) {
        //     _state.isRunning = !_state.isRunning;
        // } else {
        //     _state.isRunning = force;
        // }

        // if (_state.isRunning === true) {
        //     let alertTitle = "Please Wait ...";
        //     if (msgText !== undefined) {
        //         // If message passed in, use it instead
        //     } else if (_state.isGenericSearch) {
        //         _state.currentContext = searchtext;
        //         msgText = 'Performing search for ' + _state.currentContext;
        //     } else if (_state.isAdvSearch) {
        //         msgText = Constants.promptMessages.advanceSearchmsg;
        //         alertTitle = "Search"
        //     } else if (_state.isFormSearch) {
        //         noCancel = true;
        //         msgText = 'Processing ...';
        //     } else {
        //         msgText = "Searching for " + '"' + (_state.isFormSearch === true ? _criteria.form : _criteria.person) + '"';
        //         alertTitle = "Search"
        //     }
        //     let alertArgs = {
        //         id: Constants.promptMessages.alertId,
        //         message: msgText,
        //         alertTitle: alertTitle
        //     }
        //     if (noCancel === undefined || noCancel === false) {
        //         alertArgs.cancelCallback = _cancelCallback;
        //     }
        //     PubSubService.broadcast(Constants.events.openAlert, alertArgs);

        // } else {
        //     PubSubService.broadcast(Constants.events.closeAlert, Constants.promptMessages.alertId);
        // }
    }

    toggleFormSearch(override: boolean = null) {
        let tempFormSearch = this.state.isFormSearch;
        this.resetState();
        this.state.isFormSearch = tempFormSearch;

        if (_.isBoolean(override)) {
            this.state.isFormSearch = override;
        } else {
            this.state.isFormSearch = !this.state.isFormSearch;
        }
        // Save current form vs person search state in local storage
        this.uiLocalStorageService.set(Models.LocalStorageConstants.navBarSearchType, (this.state.isFormSearch) ? "Y" : "N");
        if (this.state.isFormSearch) {
            this.state.currentContextIndex = "FORM";
            this.setCurrentSRData(this.getCurrentSRData());
            if (this.state.isVisible) {
                this.focusService.focusOn("#form-sr-input");
            }

        } else {
            this.state.currentContextIndex = "PERSON";
            this.setCurrentSRData(this.getCurrentSRData());
            if (this.state.isVisible) {
                this.focusService.focusOn("#non-form-sr-input");
            }
        }

        // toggle search history after switching the search context
        if (this.state.searchformAutocomplete || this.state.searchpersonAutocomplete) {
            this.toggleSearchOptions(Models.ToggleSearchCategory.autocomplete);
        } else {
            this.resetState();
        }
    }

    toggleSingleForm(result: any) {
        if (!_.has(result, "highlight")) {
            result.highlight = false;
        }
        this.selectedForm.form.highlight = false;
        if (this.selectedForm.form['@ID'] == result['@ID']) {
            if (result.clicked === undefined) {
                result.highlight = false;
                result.clicked = true;
                this.selectedForm.isSelection = false;
            } else if (result.clicked === true) {
                result.highlight = true;
                result.clicked = false;
                this.selectedForm.isSelection = true;
            } else {
                result.highlight = false;
                result.clicked = true;
                this.selectedForm.isSelection = false;
            }
        } else {
            result.highlight = !result.highlight;
        }
        if (result.highlight == true) {
            this.selectedForm.form = result;
            this.selectedForm.isSelection = true;
        }
    }

    addToHistory(historyID: string, isForm: boolean, mnemonic: string = null, override: boolean = false) {
        let historyList = isForm ? this.criteria.formHistory : this.criteria.personHistory;
        let found = false;
        for (let i = 0; i < historyList.length; i++) {
            let history = '';
            if (historyList[i] != null) {
                if (historyList[i].indexOf(' $$$') > 0) {
                    history = historyList[i].substring(0, historyList[i].indexOf(' $$$'));
                } else {
                    history = historyList[i];
                }
                if (history === historyID) {
                    //TODO: Why were we overwriting the history with the new mnemonic (Search APLS, ST-APLS, save on first form flips to UT-APLS. Why did ST-APLS become UT-APLS?)
                    if (override) {
                        historyList[i] = historyID + " $$$ " + mnemonic;
                    }
                    found = true;
                    break;
                }
            }
        }
        if (!found) {
            if (mnemonic) historyList.push(historyID + " $$$ " + mnemonic);
            else historyList.push(historyID);
        }
        let sortedHistoryList = _.sortBy(historyList);
        while (historyList.length > 0) {
            historyList.pop();
        }
        for (let i = 0; i < sortedHistoryList.length; i++) {
            historyList.push(sortedHistoryList[i]);
        }
        this.uiLocalStorageService.set(isForm ? Models.LocalStorageConstants.formSearchHistory : Models.LocalStorageConstants.personSearchHistory, historyList);
    }

    focusOnSearchInput() {
        if (this.state.isFormSearch) {
            this.focusService.focusOn("#form-search");
        } else {
            this.focusService.focusOn("#person-search");
        }
    }

    searchInputValidation(validationMsg: string) {
        let popupMessage: any = {};
        popupMessage.title = '';
        popupMessage.text = [validationMsg];
        popupMessage.buttons = [{
            label: "OK",
            callback: () => {
                this.promptService.popupMessageDismiss(() => {
                    if (this.state.PersonAdvancedSearch) {
                        this.focusService.focusOn('#personName');
                    } else {
                        setTimeout(() => {
                            this.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
                            this.focusOnSearchInput();
                        }, 150);
                    }
                    setTimeout(() => {
                        this.dismissDialogMessageAlways();
                    }, 100);
                }, false);
            },

        }];
        popupMessage.defaultCallbackNumber = 0;
        this.promptService.popupMessageShow(popupMessage);
    }

    dismissDialogMessageAlways() {
        this.eventsService.broadcast(Models.EventConstants.closeAlert);
    };

    searchError(error: string) {
        let popupMessage: any = {};
        popupMessage.title = '';
        popupMessage.text = [error];
        popupMessage.buttons = [{
            label: "OK",
            callback: () => {
                this.promptService.popupMessageDismiss(() => {
                    setTimeout(() => {
                        this.dismissDialogMessageAlways();
                    }, 100);

                    if (this.state.PersonAdvancedSearch) {
                        this.focusService.focusOn('#personName');
                    } else {
                        this.focusOnMainInput();
                    }
                }, false);
            },

        }];
        popupMessage.defaultCallbackNumber = 0;
        this.promptService.popupMessageShow(popupMessage);
    }

    setSearchTooltip() {
        let tooltip = "<div style='text-align: left !important'>";
        let indent = "</br>&nbsp;&nbsp;&nbsp;&nbsp;";
        let tooltipTextOnly = '';
        let isAccessible = this.preferencesService.dialogPreferences.VerboseAccess;
        let srData = this.getCurrentSRData();
        if (!this.state.isGenericSearch && this.state.isFormSearch) {
            // Text only for field announcement
            tooltipTextOnly += srData.searchResults && !this.validationService.isNullOrEmpty(srData.searchResults.criteria) && !this.validationService.isNullOrEmpty(srData.searchResults.totalRows) ?
                "Form view search results for " + this.helperService.formatMnemonic(srData.searchResults.criteria) + ", " + srData.searchResults.totalRows + " results. " :
                "Form view search results, ";

            if (isAccessible) {
                tooltip += "Enter a single form result to run, e.g. <b>3</b>";
                tooltip += "</br></br>Or enter a command:";
                tooltip += indent + "<b>J <i>x</i></b> - jump to and focus on result number <i>x</i>";
                tooltip += indent + "<b>R <i>x</i></b> - read result number <i>x</i>";
                tooltip += indent + "<b>T <i>x</i></b> - toggle selection for result number <i>x</i>";

                tooltipTextOnly += "Select Search Results by Number or enter a command: ";
                tooltipTextOnly += "J space x to jump to and focus on result number x, ";
                tooltipTextOnly += "R space x to read a specific result number x, ";
                tooltipTextOnly += "T space x to toggle a specific result number x";
            } else {
                tooltip += 'Enter a single form result to run, e.g. <b>3</b>';

                tooltipTextOnly += "Enter a single form result to run, for example 3";
            }
        } else {
            // Text only for field announcement
            if (srData.searchResults && !this.validationService.isNullOrEmpty(srData.searchResults.criteria) &&
                srData.contextData && srData.contextData.ResultPage && !this.validationService.isNullOrEmpty(srData.contextData.ResultPage.context)) {
                tooltipTextOnly += " view search results for " + srData.searchResults.criteria + " (" + srData.contextData.ResultPage.context + "). ";
            } else if (srData.contextData && srData.contextData.ResultPage && !this.validationService.isNullOrEmpty(srData.contextData.ResultPage.context)) {
                tooltipTextOnly += " view search results (" + srData.contextData.ResultPage.context + "). ";
            } else {
                tooltipTextOnly += " view search results. ";
            }

            if (srData.searchResults && !this.validationService.isNullOrEmpty(srData.searchResults.currentRow) && !this.validationService.isNullOrEmpty(srData.searchResults.cursorSize) && !this.validationService.isNullOrEmpty(srData.searchResults.totalRows))
                tooltipTextOnly += srData.searchResults.currentRow + " - " +
                    Math.min(srData.searchResults.cursorSize + srData.searchResults.currentRow - 1, srData.searchResults.totalRows) +
                    " of " + srData.searchResults.totalRows + ". ";

            if (isAccessible) {
                tooltip += "Enter records to select in the following format: <b>1,2-4,7</b>";
                tooltip += "</br></br>Or enter a command:";
                tooltip += indent + "<b>FA</b>- select all records";
                tooltip += indent + "<b>A</b> - add a new record (if available on a form)";
                tooltip += indent + "<b>C</b> - switch to card view";
                tooltip += indent + "<b>G</b> - switch to grid view";
                tooltip += indent + "<b>J <i>x</i></b> - jump to and focus on result number <i>x</i>";
                tooltip += indent + "<b>R <i>x</i></b> - read key for result number <i>x</i>";
                tooltip += indent + "<b>RD <i>x</i></b> - read details for result number <i>x</i>";
                tooltip += indent + "<b>T <i>x</i></b> - toggle selection for result number <i>x</i>";
                tooltip += indent + "<b>S</b> - enter Sort Select criteria, if applicable";
                tooltip += indent + "<b>E</b> - export results to Excel";
                tooltip += indent + "<b>?</b> - reshow these search input options";

                tooltipTextOnly += "Select Search Results by Number or if not performing a form search enter a command: ";
                tooltipTextOnly += "FA to select all records, ";
                tooltipTextOnly += "A to add a new record (if available on a form), ";
                tooltipTextOnly += "C to switch to card view, ";
                tooltipTextOnly += "G to switch to grid view, ";
                tooltipTextOnly += "J space x to jump to and focus on result number x, ";
                tooltipTextOnly += "R space x to read key for result number x, ";
                tooltipTextOnly += "RD space x to read details for result number x, ";
                tooltipTextOnly += "T space x to toggle selection for result number x, ";
                tooltipTextOnly += "S to enter Sort Select criteria, if applicable, ";
                tooltipTextOnly += "E to export results to Excel, ";
                tooltipTextOnly += "? to reshow these search input options";
            } else {
                tooltip += "Enter records to select in the following format: <b>1,2-4,7</b>";
                tooltip += "</br></br>Or enter a command:";
                tooltip += indent + "<b>FA</b> - select all records";
                tooltip += indent + "<b>A</b> - add a new record (if available on a form)";
                tooltip += indent + "<b>J <i>x</i></b> - jump to and focus on result number <i>x</i>";
                tooltip += indent + "<b>T <i>x</i></b> - toggle selection for result number <i>x</i>";
                tooltip += indent + "<b>S</b> - enter Sort/Select criteria (if applicable)";
                tooltip += indent + "<b>E</b> - export results to Excel";
                tooltip += indent + "<b>?</b> - re-show these search input options";

                tooltipTextOnly += "Select Search Results by Number or if not performing a form search enter a command: ";
                tooltipTextOnly += "FA to select all records, ";
                tooltipTextOnly += "A to add a new record (if available on a form), ";
                tooltipTextOnly += "J space x to jump to and focus on result number x, ";
                tooltipTextOnly += "T space x to toggle selection for result number x, ";
                tooltipTextOnly += "S to enter Sort Select criteria, if applicable, ";
                tooltipTextOnly += "E to export results to Excel, ";
                tooltipTextOnly += "? to reshow these search input options";
            }
        }
        tooltip += '</div>';
        this.searchInput.tooltip = tooltip;
        this.searchInput.tooltipTextOnly = tooltipTextOnly;
    }

    setError(error: string) {
        this.clearResults();
        this.getCurrentSRData().control.error = error;
    };

    colleagueSearchRequest() {

        let req: any = {};
        req.EntityName = "";
        req.Context = "";
        req.Group = "";
        req.ProcessID = "";
        req.StartRow = "";
        req.CursorSize = "";
        req.QueryColumns = "";
        req.SortColumns = null;
        req.LimitingCriteria = "";
        req.SortAscending = null;

        return req;
    }

    search(contextId: any, cursorHandle: any, startRow: any, cursorSize: any, queryColumns: any, searchCriteria: any, sortColumns: any, sortAscending: any,
        successCallback: any = null, failureCallback: any = null, resultsCallback: any = null, successCallbackParam: any = null, failureCallbackParam: any = null) {
        // Reset flags on search
        this.rootScopeService.performanceCounter += 5;

        if (!this.validationService.isNullOrEmpty(searchCriteria)) {
            this.rootScopeService.searchCriteria = searchCriteria;
        }

        // If cache hit, simply return it
        if (this.searchCache(contextId, cursorHandle, startRow, cursorSize, queryColumns, searchCriteria, sortColumns, sortAscending, successCallback, failureCallback, resultsCallback, successCallbackParam)) {
            this.toggleSearchOptions(Models.ToggleSearchCategory.search);
            return;
        }
        this.getCurrentSRData().control.error = "";
        let errMsg = "";
        let checkChar = "";


        this.criteria.personSearch = this.criteria.person;
        $("#context-warning").show();
        if (this.state.isFormSearch) { // CUI-5728 -- Check for special characters only for form search. Person search should let these characters pass through.
            for (let idx = 0; idx < this.invalidSearchChars.length; idx++) {
                checkChar = this.invalidSearchChars[idx];
                if (searchCriteria.indexOf(checkChar) >= 0) {
                    errMsg = "Invalid search character '" + checkChar + "'";
                    break;
                }
            }
        }

        if (errMsg.length > 0) {
            if (_.isFunction(failureCallback)) {
                failureCallback(false, errMsg, failureCallbackParam);
            } else {
                this.setError(errMsg);
            }
        } else {
            if (contextId.toLowerCase() == 'form' && this.validationService.isNullOrEmpty(cursorHandle)) {
                this.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
            }
            let searchRequest = this.colleagueSearchRequest();
            if (Array.isArray(cursorHandle) && cursorHandle.length > 0) {
                searchRequest.EntityName = cursorHandle[0];
            } else {
                searchRequest.EntityName = cursorHandle;
            }
            searchRequest.Context = contextId;
            //searchRequest.Group = "";
            //searchRequest.ProcessID = "";
            searchRequest.StartRow = startRow;
            searchRequest.CursorSize = cursorSize;
            searchRequest.QueryColumns = queryColumns;
            searchRequest.SortColumns = sortColumns;
            searchRequest.SortAscending = sortAscending;
            searchRequest.LimitingCriteria = searchCriteria;

            let opts: any = {
                httpOptions: {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json'
                    })
                },
                body: searchRequest,
                url: [this.configurationService.getConfiguration().serviceUrl, 'search', this.sessionService.getToken(), this.sessionService.getControlId()].join('/'),
            };

            // Duplicate check before adding a cursor handle
            let handle: any = { 'cursorHandle': cursorHandle, 'context': contextId, 'criteria': searchCriteria };
            let cursorHandleAlreadyAdded: boolean = false;
            for (let i = 0; i < this.cursorHandles.length; i++) {
                if (this.cursorHandles[i].context === handle.context &&
                    this.cursorHandles[i].criteria === handle.criteria &&
                    this.cursorHandles[i].cursorHandle === handle.cursorHandle) {
                    cursorHandleAlreadyAdded = true;
                    break;
                }
            }
            if (cursorHandleAlreadyAdded != true) {
                this.cursorHandles.push(handle);
            }
            else {
                return; // return to avoid duplicate searches.
            }

            if (this.cursorHandles.length > 1) {
                let responseInterval = setInterval(() => {
                    if (this.cursorHandles.length == 1 || (this.cursorHandles.length > 1 && this.searchCount == 0)) {
                        clearInterval(responseInterval);
                        this.args = {
                            contextId: contextId,
                            cursorHandle: cursorHandle,
                            startRow: startRow,
                            cursorSize: cursorSize,
                            queryColumns: queryColumns,
                            searchCriteria: searchCriteria,
                            sortColumns: sortColumns,
                            sortAscending: sortAscending,
                            successCallback: successCallback,
                            failureCallback: failureCallback,
                            resultsCallback: resultsCallback,
                            successCallbackParam: successCallbackParam,
                            failureCallbackParam: failureCallbackParam
                        };
                        this.sendRequest(opts);
                    }

                }, 500);
            }
            else if (this.cursorHandles.length == 1) {
                this.args = {
                    contextId: contextId,
                    cursorHandle: cursorHandle,
                    startRow: startRow,
                    cursorSize: cursorSize,
                    queryColumns: queryColumns,
                    searchCriteria: searchCriteria,
                    sortColumns: sortColumns,
                    sortAscending: sortAscending,
                    successCallback: successCallback,
                    failureCallback: failureCallback,
                    resultsCallback: resultsCallback,
                    successCallbackParam: successCallbackParam,
                    failureCallbackParam: failureCallbackParam
                };
                this.sendRequest(opts);
            }
        }

    }

    toggleVisible(override: boolean = null) {
        if (_.isBoolean(override)) {
            this.state.isVisible = override;
        } else {
            this.state.isVisible = !this.state.isVisible;
        }
        // If dimissing generic search, this is effectively a cancel of the lookup
        if (!this.state.isVisible && (this.state.isGenericSearch)) {
            this.state.isGenericSearch = false;
            //click outside the pop over and send message to server to get back to normal state
            this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.screenCancel);
        }
        if (this.state.isVisible) {
            this.setSearchTooltip();
            this.focusOnMainInput();
        } else {
            if (this.state.isFormSearch && !this.state.isGenericSearch) {
                this.selectedForm.form.highlight = false;
            }
            this.toggleFormSearch(this.state.isFormSearch);
        }
        this.rootScopeService.isSearchOpen = this.state.isVisible;
    }

    addToHistoryListener(searchService: SearchService, msg: any) {
        let self: SearchService = <SearchService>searchService;
        self.addToHistory(msg.historyID, msg.isForm, msg.mnemonic);
    }

    searchCount: number = 0;

    private sendRequest(opts: any) {
        this.searchCount++;
        this.pendingSearchRequest = this.http.post(opts.url, opts.body, opts.httpOptions).subscribe(
            (response: any) => {
                this.pendingSearchRequest = null;
                // let data: any = JSON.parse(response._body);
                this.searchResponse(response);
            }, (error: any) => {
                this.pendingSearchRequest = null;
                this.cursorHandles = [];
                this.handleError(error);
            });
    }

    private searchResponse(data: any) {
        this.searchCount--;
        this.eventsService.broadcast(Models.EventConstants.closeAlert);


        let searchResults = data.SearchResults;
        let hideSearchResults = false;
        // Stop processing if cancelled
        if (this.state.cancelled === true) {
            this.state.cancelled = false;
            return;
        }

        if (data.Errors.length > 0) {
            this.cursorHandles = [];
            let d = data.Errors[0];
            if (d.ErrorCategory.toUpperCase() == "WEBSERVICE" && d.ErrorCode.toLowerCase() == "application server configuration invalid") {
                d.ErrorMessageText = "The Colleague Application Server is currently unavailable. Please try again later...";
            }

            if (_.isFunction(this.args.failureCallback)) {
                this.args.failureCallback(false, d.ErrorMessageText, this.args.failureCallbackParam);
            } else {

                this.dismissDialogMessageAlways();
                if (d.ErrorMessageText != null && d.ErrorMessageText != '')
                    this.searchError(d.ErrorMessageText);

                _.each(data.Errors, (it: any) => {
                    this.setError(it.ErrorMessageText);
                });
            }

        } else {
            //_dismissDialogMessageAlways();
            if (!this.args.queryColumns) {
                searchResults.QueryColumns = this.buildQueryColumns(this.args.contextId);
            }
            let removed: boolean = false;
            if (_.isFunction(this.args.resultsCallback)) {
                if (this.cursorHandles.length >= 1) {
                    removed = true;
                    this.cursorHandles.shift();
                }
                this.args.resultsCallback(searchResults, this.args.contextId, this.args.searchCriteria);
            } else {
                if (searchResults.TotalRows > 0) {
                    if (searchResults.TotalRows === 1 && !this.state.isGenericSearch && this.state.isFormSearch && this.validationService.isNullOrEmpty(searchResults.CursorHandle)) {
                        this.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
                        // Launch single form search result
                        let formResult = this.parseResults(searchResults)[0];
                        let formId = formResult['PROCESS.APPLICATION'] + "-" + formResult['@ID'];
                        hideSearchResults = true;
                        this.rootScopeService.analyticsLastFormOpen = formResult['@ID'];
                        this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", formId, "", "", "");
                        //this.toggleRunning(true);
                        // Add form to search history
                        let historyID = formResult['@ID'] + ": " + formResult['PROCESS.DESCRIPTION'];
                        this.criteria.form = historyID;
                        this.eventsService.broadcast("UpdateSearchInput", historyID);
                        this.rootScopeService.lastFormSearchHistoryId = historyID + " $$$ " + formId;
                        // If a valid form is searched, form specs message listener adds the form to form history.Therefore, search response does not need to add it here.Commenting code. -- Naman
                        // Form search history should be added from form search results and not the form specs message. Reverting old code. -- Naman -- 02/11/2019
                        this.addToHistory(historyID, true, formId, true);
                    } else {
                        if (!this.state.isGenericSearch && this.state.isFormSearch) this.criteria.formSearch = this.criteria.form;

                        let resultsData = this.parseResults(searchResults);

                        // Two or more rows selected, display the search results
                        let results: any = {
                            totalRows: searchResults.TotalRows,
                            cursorHandle: searchResults.CursorHandle,
                            context: this.args.contextId,
                            currentRow: this.args.startRow,
                            cursorSize: resultsData.length > this.args.cursorSize ? resultsData.length : this.args.cursorSize
                        };


                        for (let i = 0; i < resultsData.length; i++) {
                            resultsData[i].rowNumber = results.currentRow + i - 1;
                            resultsData[i].recordNumber = results.currentRow + i;
                        }


                        let srData = this.getCurrentSRData();
                        if ((this.args.searchCriteria !== undefined) && (this.args.searchCriteria.length > 0)) {
                            results.criteria = this.args.searchCriteria;
                        } else if (srData.searchResults != undefined && srData.searchResults.criteria != undefined && srData.searchResults.criteria.length > 0) {
                            results.criteria = srData.searchResults.criteria;
                        }

                        if (srData.searchResults && srData.searchResults.cursorHandle) {
                            if (srData.searchResults.cursorHandle != results.cursorHandle) {
                                srData.searchResults = null;
                                srData.searchResults = results;
                                srData.searchResults.data = [];
                                this.rootScopeService.selectedRowNumbers = [];
                                this.rootScopeService.searchResultRecordsSelected = 0;
                                //for (let i = 0; i < results.totalRows; i++) {
                                //    srData.searchResults.data.push(null);
                                //}
                            } else if (srData.searchResults.totalRows != results.totalRows) {
                                srData.searchResults.totalRows = results.totalRows;
                                srData.searchResults.cursorHandle = results.cursorHandle;
                                srData.searchResults.startRow = results.startRow;
                                srData.searchResults.context = results.context;
                                srData.searchResults.cursorSize = results.cursorSize;
                                while (srData.searchResults.data.length > 0) {
                                    srData.searchResults.data[srData.searchResults.data.length - 1] = null;
                                    srData.searchResults.data.pop();
                                }
                            } else if (srData.searchResults.totalRows == results.totalRows) {
                                srData.searchResults.currentRow = results.currentRow;
                                srData.searchResults.cursorSize = results.cursorSize;
                                srData.searchResults.startRow = results.startRow;

                                let existingNode = _.find(srData.searchResults.data, (rec: any) => {
                                    return rec.rowNumber == resultsData[0].rowNumber && rec['@ID'] != resultsData['@ID'];
                                });

                                if (existingNode) {
                                    while (srData.searchResults.data.length > 0) {
                                        srData.searchResults.data[srData.searchResults.data.length - 1] = null;
                                        srData.searchResults.data.pop();
                                    }
                                }
                            } else {
                                srData.searchResults.currentRow = results.currentRow;
                                srData.searchResults.cursorSize = results.cursorSize;
                            }
                        } else {
                            srData.searchResults = null;
                            srData.searchResults = results;
                            srData.searchResults.data = [];
                            this.rootScopeService.selectedRowNumbers = [];
                            this.rootScopeService.searchResultRecordsSelected = 0;
                            //for (let i = 0; i < results.cursorSize; i++) {
                            //    srData.searchResults.data.push(null);
                            //}
                        }

                        for (let i = 0; i < resultsData.length; i++) {

                            let existingNode = _.find(srData.searchResults.data, (rec: any) => {
                                return rec.rowNumber == resultsData[i].rowNumber;
                            });
                            if (existingNode == null) {
                                srData.searchResults.data.push(resultsData[i]);
                            }
                        }

                        srData.contextData = this.contextService.getContext(this.args.contextId);

                        // Set view and selection arguments for formless PERSON search
                        if (!this.state.isGenericSearch) {

                            if (this.state.isFormSearch) {
                                srData.control.addMode = false;
                                srData.control.autoLoad = false;
                                srData.control.multiSelection = false;
                                srData.control.sortSelectAllowed = false;

                            } else {
                                srData.views = {
                                    viewNames: ["Form-less Person Search"],
                                    viewHandles: [searchResults.CursorHandle],
                                    selectedView: 0
                                }
                                srData.control.addMode = false;
                                srData.control.autoLoad = true;
                                srData.control.multiSelection = true;
                                srData.control.sortSelectAllowed = true;
                            }
                        }
                        srData.control.cursorSize = results.cursorSize;

                        //#region CUI-3645
                        // Set the prefix for SessionStorage
                        this.sessionStorage.setPrefix("DTO");
                        // Get the current context name from currentSRData. This will form the key with which we will query session storage
                        let key = srData.contextData.Header.context;
                        // Check SessionStorage to see if it contains the column order under DTO key
                        let cachedOrder = this.sessionStorage.get(key);
                        // If session storage has a cached order, set that to srData.layout.headerFields
                        if (cachedOrder) {
                            let newHeaderFields: any = [];
                            // parse through cachedOrder array and re-order items in srData.layout.headerFields
                            $(cachedOrder).each((orderIndex: any, orderValue: any) => {
                                srData.layout = srData.contextData.ResultPage;
                                let originalHeaderFields = srData.layout.headerFields;
                                // parse through each original headerField record, if label matches the new order's value, push the complete object to newHeaderFields
                                // @ts-ignore
                                $(originalHeaderFields).each((headerIndex: number, headerValue: any) => {
                                    if (headerValue.label.toLowerCase() === orderValue.toLowerCase()) {
                                        newHeaderFields.push(headerValue);
                                        return true;
                                    }
                                });
                            });

                            // Assign header fields in new order to layout
                            if (newHeaderFields && newHeaderFields.length > 0) {
                                srData.layout.headerFields = newHeaderFields;
                            }
                        }
                        //#endregion

                        this.setCurrentSRData(srData);

                        // Add page of data to cache
                        this.cache.cursorHandle = srData.searchResults.cursorHandle;
                        this.cache.cursorSize = results.cursorSize;
                        this.cache.data = srData.searchResults.data;
                        if (!this.cache.startRows.includes(this.args.startRow))
                            this.cache.startRows.push(this.args.startRow);
                        let recordsToAdd = '';

                        let tempCursorHandle = _.find(this.cursorHandles, (handle: any) => {
                            return handle.cursorHandle == srData.searchResults.cursorHandle;
                        });

                        if (tempCursorHandle == null) {
                            tempCursorHandle = _.find(this.cursorHandles, (handle: any) => {
                                return srData.searchResults.cursorHandle.toUpperCase().indexOf(handle.cursorHandle.toUpperCase()) >= 0;
                            });
                        }
                        let tempContextId: string = "";
                        let searchCriteria: any;
                        if (tempCursorHandle != null) {
                            tempContextId = tempCursorHandle.context;
                            searchCriteria = tempCursorHandle.criteria
                            this.cursorHandles = _.without(this.cursorHandles, tempCursorHandle);
                        }

                        if (tempContextId === "PERSON" && !isNaN(searchCriteria) && searchResults.TotalRows <= 1 && searchCriteria !== '') {
                            recordsToAdd = srData.searchResults.data;
                            this.eventsService.broadcast("loadPersonContextData", recordsToAdd);
                            // this.state.PersonSearchResults = false;
                            this.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
                        } else {
                            this.toggleSearchOptions(Models.ToggleSearchCategory.search);
                        }
                        // getting values for person search

                        if (tempContextId !== "FORM" && srData.contextData.Header.imageEnabled && this.configurationService.getConfiguration().ClientParameters.PersonImagesEnabled == true) {
                            let srData = this.getCurrentSRData();
                            _.each(srData.searchResults.data, (it: any) => {
                                try {
                                    this.imageService.getImage(it);
                                } catch (ex) {
                                    // swallow exception like a good developer
                                }
                            });
                        }
                    }
                } else {
                    if (!this.state.isGenericSearch && this.state.isFormSearch)
                        this.criteria.formSearch = this.criteria.form;
                    this.toggleSearchOptions(Models.ToggleSearchCategory.search);
                    this.clearResults();
                }

            }
            if (_.isFunction(this.args.successCallback)) {
                if (this.cursorHandles.length >= 1 && removed == false) {
                    this.cursorHandles.shift();
                }
                this.args.successCallback(hideSearchResults, this.args.successCallbackParam);
            }
        }

    }

    private handleError(error: Response): any {
        if (_.isFunction(this.args.failureCallback)) {
            this.args.failureCallback(false, error.statusText, this.args.failureCallbackParam);
        } else {
            this.setError("Person search failed. Please try again later.");
        }
    }
}