/**
* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";

declare var ga: any;
declare var disableGA: any;
// 08/24/2021 Disable Google Analytics (CSSP-10364)
disableGA = 'true';
@Injectable()
export class AnalyticsService {
    enabled: boolean = true;

    constructor() {
        if (disableGA == 'true') {
            this.enabled = false;
        }
    }

    trackEvent(category: any, action: any, label: any, value: any) {
        if (this.enabled == true && ga != null) {
                ga("send", "event", {
                    "eventCategory": category,
                    "eventAction": action,
                    "eventLabel": label,
                    "eventValue": value
                });
        }
    }

    trackPage(name: any, title: any, version: any) {
        if (this.enabled == true && ga != null) {
            ga("send", "pageview", {
                page: "/form/" + name,
                title: title,
                location: version
            });
        }
    }
}