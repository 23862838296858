/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit, Inject, Input } from '@angular/core';

import {
    ConfigurationService,
    RootScopeService,
    EventsService,
    ContextHeaderService,
    ContextService,
    UiLocalStorageService,
    ValidationService,
    PromptService,
    FormService
} from "../../../services";
import * as Models from "../../../models";
import * as _ from 'lodash';

declare var $: any;
declare var showErrorMessage: any;
declare var adminSelectedEmailClient: any;
declare var overwriteEmailClientPreference: any;

@Component({
    selector: 'contextheader',
    template: require('./contextHeader.component.html')
})
export class ContextHeaderComponent implements OnInit {
    loadPersonContextDataId: string = "";
    MoveContextForwardId: string = "";
    MoveContextBackId: string = "";
    ViewAllContextId: string = "";

    showCloseContext: boolean = false;
    showFavoriteContext: boolean = false;
    data: any = null;
    personcontext: any = null;
    headerdefinition: any = null;
    autoCloseContext: boolean = false;
    newContext: any = null;

    constructor(private configurationService: ConfigurationService, private contextService: ContextService, private rootScopeService: RootScopeService,
        private eventsService: EventsService, private contextHeaderService: ContextHeaderService, private uiLocalStorageService: UiLocalStorageService,
        private validationService: ValidationService, private promptService: PromptService, private formService: FormService) {
        this.loadPersonContextDataId = this.eventsService.on("loadPersonContextData", [this.loadPersonContextData, this]);
        this.MoveContextForwardId = this.eventsService.on("MoveContextForward", [this.moveContextForwardListener, this]);
        this.MoveContextBackId = this.eventsService.on("MoveContextBack", [this.moveContextBackListener, this]);
        this.ViewAllContextId = this.eventsService.on("ViewAllContext", [this.viewAllContextListener, this]);
    }

    moveContextForwardListener(comp: any) {
        let self = <ContextHeaderComponent>comp;
        self.contextHeaderService.nextRecord(self.contextService.context.PERSON.position);
    }

    moveContextBackListener(comp: any) {
        let self = <ContextHeaderComponent>comp;
        self.contextHeaderService.previousRecord(self.contextService.context.PERSON.position);
    }

    personImagesEnabled() {
        var configuration = this.configurationService.getConfiguration();
        if (configuration != null && configuration.ClientParameters != null && configuration.ClientParameters.PersonImagesEnabled != null)
            return configuration.ClientParameters.PersonImagesEnabled;
        else return false;
    }

    calcColumnWidth(width: any) {
        var columnPrefixes = ["col-lg-", "col-md-", "col-sm-", "col-xs-"];

        columnPrefixes = _.map(columnPrefixes, (prefix: any) => {
            return prefix + (12 / width);
        });

        return columnPrefixes.join(' ');
    }

    loadPersonContextData(component: any, data: any) {
        let self = <ContextHeaderComponent>component;

        var currentContextData = self.contextService.getContext().data;

        //Remove Duplicate data selection
        for (var i = 0; i < data.length; i++) {
            for (var j = i + 1; j < data.length; j++) {
                if (data[j]["@ID"] == data[i]["@ID"]) {
                    data.splice(i, 1);
                    i--;
                    break;
                }
            }
        }

        self.contextService.listRefreshRecord("PERSON", data);

        self.rootScopeService.doNotRemoveDuplicate = false;
    }


    dismissWarning() {
        $("#context-warning").hide();
    };

    showWarning() {
        $("#context-warning").show();
    }

    closeRightActionPopover() {
        this.showCloseContext = false;
        this.showFavoriteContext = false;
    }

    closeContext(length: number) {
        if (length == 1) {
            this.contextHeaderService.closeRecord(0);
        }
        else {
            this.showCloseContext = true;
        }
    }

    favoriteContext(length: number) {
        if (length == 1) {
            this.contextService.addRecordToFavorites(0);
        }
        else {
            this.showFavoriteContext = true;
        }
    }

    closeContext_popout(position: number) {
        if (this.formService.forms.length >= 1 && position !== -1)
            this.rootScopeService.closeContextRecordPopout = true;

        this.contextHeaderService.closeRecord(position);
        this.closeRightActionPopover();
    }

    favoriteContext_popout(position: number) {
        this.contextService.addRecordToFavorites(position);
        this.closeRightActionPopover();
    }

    viewAllContextListener(component: any, data: any) {
        let self = <ContextHeaderComponent>component;
        self.contextHeaderService.openPopoverContext();
    }

    /**
     * Opens the Admin/User preferred Email Client
     * @param email - Email address of PERSON record from context header
     */
    openEmail(email: any) {
        showErrorMessage = false;

        /* Always Ask: 0, Desktop: 1, Web Based: 2
        Start with the default email client option of Desktop.
        If Admin has not allowed users to select their own preferred client, proceed with admin selected email client option
        If Admin has allowed users to select their own preferred client, proceed with user preferred email client option.
         */
        let emailClientOption: number = 1;

        // Check if overwriteEmailClientPreference variable is defined
        if (!this.validationService.isNullOrEmpty(overwriteEmailClientPreference)) {
            // If Admins have not allowed users to opt for their preferred email client, use Admin's preferred email client option
            if (overwriteEmailClientPreference.toLocaleLowerCase() === "false") {
                // Check if adminSelectedEmailClient is a valid number
                if (!this.validationService.isNullOrEmpty(adminSelectedEmailClient) && !isNaN(adminSelectedEmailClient)) {
                    emailClientOption = parseInt(adminSelectedEmailClient); // Parse the preferred email client value, set by Admin, to a number (either 1 or 2)
                }
            }
            // If Admins have allowed users to choose their preferred email client, use the preferredEmailClient saved in local storage
            else if (overwriteEmailClientPreference.toLowerCase() === "true") {
                let localStorageEmailClient = this.uiLocalStorageService.get(Models.LocalStorageConstants.userPreferredEmailClient);
                // Check if localStorage value of email client is defined and is a valid number
                if (!this.validationService.isNullOrEmpty(localStorageEmailClient) && !isNaN(localStorageEmailClient)) {
                    emailClientOption = parseInt(localStorageEmailClient); // Parse the local storage email client value to a number (either 0 or 1 or 2)
                }
            }
        }

        switch (emailClientOption) {
            case 0: // Always ask (Display dialog message prompting the user to select their preference)
                let popupMessage: any = {};
                let message: any = Models.DialogContants.PreferredEmailClient;
                popupMessage.title = "Email Client";
                popupMessage.text = [message];
                popupMessage.buttons = [
                    {
                        label: Models.PromptMessageConstants.DesktopEmailClient,
                        callback: () => {
                            this.promptService.popupMessageDismiss(() => {
                                window.location.href = "mailto:" + email;
                            }, false);
                        },
                        SearchFocus: false
                    },
                    {
                        label: Models.PromptMessageConstants.WebBasedEmailClient,
                        callback: () => {
                            this.promptService.popupMessageDismiss(() => {
                                window.open("mailto:" + email);
                            }, false);
                        },
                        SearchFocus: false
                    },
                    {
                        label: "Cancel",
                        callback: () => {
                            this.promptService.popupMessageDismiss(() => {
                                this.eventsService.broadcast(Models.EventConstants.closeAlert, true);
                            }, false);
                        },
                        SearchFocus: false
                    }
                ];
                popupMessage.defaultCallbackNumber = "0";
                this.promptService.popupMessageShow(popupMessage);
                break;
            case 1: // Desktop email client (open link in same window)
                window.location.href = "mailto:" + email;
                break;
            case 2: // Web based client (open link in a new tab/window)
                window.open("mailto:" + email);
                break;
            default:
                // Default option is always Desktop email client (open link in same window)
                window.location.href = "mailto:" + email;
                break;
        }
    }

    ngOnInit() {
        this.headerdefinition = this.contextService.context;
        this.data = this.contextService.context.PERSON.data;
        this.personcontext = this.contextService.context.PERSON;
    }

    ngOnDestroy() {
        this.eventsService.destroy("loadPersonContextData", this.loadPersonContextDataId);
        this.eventsService.destroy("MoveContextForward", this.MoveContextForwardId);
        this.eventsService.destroy("MoveContextBack", this.MoveContextBackId);
        this.eventsService.destroy("ViewAllContext", this.ViewAllContextId);
    }
}