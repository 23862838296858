/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component, Inject, OnInit} from "@angular/core";
import {
    ConfigurationService,
    EventsService,
    FocusService,
    FormService,
    HelpService,
    ParserService,
    PromptService,
    RootScopeService,
    ValidationService,
    SessionService
} from "../../services/";
import * as Models from "../../models";

declare let $: any;

@Component({
    selector: 'customfieldsequence',
    template: require('./custom-field-sequence.component.html')
})
export class CustomFieldSequenceComponent implements OnInit {
    formFields: any;
    currentOperator: string = "";
    customElements: any = [];
    currentForm: any;
    displayCopyToOtherUsersDropdown: boolean = false;
    clearAllFieldsClicked: boolean = false;
    selectedCurrentField: any;
    nextFieldDisabled: boolean = false;
    selectedCustomizeForUser: any;
    nextFieldList: any;
    selectedNextField: string = "";
    firstFieldChecked: boolean = false;
    firstFieldDisabled: boolean = false;
    lastFieldChecked: boolean = false;
    lastFieldDisabled: boolean = false;
    requiredChecked: boolean = false;
    inquiryChecked: boolean = false;
    noAccessChecked: boolean = false;
    requiredDisabled: boolean = false;
    inquiryDisabled: boolean = false;
    noAccessDisabled: boolean = false;
    selectedUsersList: any;
    copyToOtherUsersList: any;
    currentFieldList: any;
    customFirstFieldId: any;
    customizeForUserList: any;
    displayNumberedBoxesLegendModal: boolean = false;

    changeCurrentFieldListenerId: string = "";
    customizeForUserDataReadyListenerId: string = "";
    updateCopyToOtherUsersCustomizedListenerId: string = "";

    constructor(private formService: FormService,
                private validationService: ValidationService,
                private sessionService: SessionService,
                private rootScopeService: RootScopeService,
                private helpService: HelpService,
                private configurationService: ConfigurationService,
                private parserService: ParserService,
                private promptService: PromptService,
                private eventsService: EventsService,
                private focusService: FocusService) {
        this.changeCurrentFieldListenerId = this.eventsService.on(Models.CustomFieldSequenceConstants.changeCurrentFieldListener, [this.changeCurrentFieldListener, this]);
        this.customizeForUserDataReadyListenerId = this.eventsService.on(Models.CustomFieldSequenceConstants.customizeForUserDataReadyListener, [this.customizeForUserDataReadyListener, this]);
        this.updateCopyToOtherUsersCustomizedListenerId = this.eventsService.on(Models.CustomFieldSequenceConstants.updateCopyToOtherUsersCustomizedListener, [this.updateCopyToOtherUsersCustomizedListener, this]);
    }

    ngOnInit() {
        this.init();

        if (this.rootScopeService.callCustomTabRetrieval == true) {
            this.selectedUsersList = this.getSelectedUsersList();
        }

        // Current Field Dropdown List
        this.currentFieldList = this.getCurrentFieldList();
        // Default selected field of the Current Field Dropdown List
        this.selectedCurrentField = this.currentFieldList[0];

        // Next field dropdown list
        this.nextFieldList = this.getNextFieldList();
        // Flag to check whether the next field dropdown list should be enabled or disabled
        this.nextFieldDisabled = this.nextFieldDisabledCheck();
        // Default selected field of the Next Field Dropdown List
        this.selectedNextField = this.getSelectedNextField();

        // If a first field has been selected, get the ID and store in scope. This will be compared to the ID of current selected field to enable/disable first field checkbox.
        this.customFirstFieldId = this.getFirstField();
        // Truthy function to check the First field checkbox
        this.firstFieldChecked = this.checkCustomFirstField();
        // Flag to check whether the first field checkbox should be enabled or disabled
        this.firstFieldDisabled = this.firstFieldDisabledCheck();

        // Truthy function to check the Last field checkbox
        this.lastFieldChecked = this.checkCustomLastField();
        // Flag to check whether the last field checkbox should be disabled or enabled
        this.lastFieldDisabled = this.lastFieldDisabledCheck();

        // Checks whether the Required checkbox should be checked or not.
        this.requiredChecked = this.fieldRequired();
        // Controls whether the Required checkbox should be disabled
        this.requiredDisabled = this.requiredFieldDisabledCheck();

        // Checks whether the Inquiry checkbox should be checked or not
        this.inquiryChecked = this.fieldInquiry();
        // Controls whether the Inquiry checkbox should be disabled
        this.inquiryDisabled = this.inquiryFieldDisabledCheck();

        // Checks whether the No Access checkbox should be checked or not
        this.noAccessChecked = this.fieldNoAccess();
        // Controls whether the No Access checkbox should be disabled
        this.noAccessDisabled = this.noAccessDisabledCheck();

        if (this.rootScopeService.callCustomTabRetrieval != true) {
            // Customize for dropdown list
            this.customizeForUserList = this.getCustomizeForUserList();
            // Default selected record in the Customize for dropdown list
            this.selectedCustomizeForUser = this.customizeForUserList[0];
        }

        // Flag to display the dropdown for copy to other users
        this.displayCopyToOtherUsersDropdown = false;

        // Flag to display the numbered boxes legend modal
        this.displayNumberedBoxesLegendModal = false;

        setTimeout(() => {
            this.focusService.focusOn("ddlCurrentField", true);
        }, 50);
    }

    //region Private

    //region Initialize

    /**
     * Resets all the custom sequence orders
     */
    private setCustomSequenceOrders() {
        // Set all the custom sequence orders to sequence order
        for (let f in this.formFields) {
            if (this.formFields[f].hasOwnProperty("sequenceOrder")
                && !this.validationService.isNullOrEmpty(this.formFields[f].sequenceOrder)
                && !isNaN(this.formFields[f].sequenceOrder)) {
                this.formFields[f].customSequenceOrder = this.formFields[f].sequenceOrder;
            }
            else if (this.formFields[f].hasOwnProperty("customSequenceOrder")) {
                delete this.formFields[f].customSequenceOrder;
            }
        }
    }

    /**
     * Get a sorted array which contains each formElement's ID and it's corresponding elementOrder in an ascending order
     */
    private getCustomElements() {
        // Create an array which contains each formElement's ID and it's corresponding elementOrder
        this.customElements = [];

        for (let field in this.formFields) {
            if (this.formFields[field].hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(this.formFields[field].elementOrder)) {
                this.customElements.push({
                    "elementID": this.formFields[field].elementID,
                    "elementOrder": this.formFields[field].elementOrder,
                    "customRequired": this.formFields[field].customFieldRequired,
                    "customFieldInquiry": this.formFields[field].customFieldInquiry,
                    "customFieldNoAccess": this.formFields[field].customFieldNoAccess,
                    "customFirstField": this.formFields[field].customFirstField,
                    "customLastField": this.formFields[field].customLastField,
                    "customSequenceOrder": this.formFields[field].sequenceOrder,
                    "customFieldForward": this.formFields[field].customFieldForward
                })
            }
        }

        // Sort the array based on elementOrder
        this.customElements.sort((a: any, b: any) => {
            return a["elementOrder"] - b["elementOrder"]
        });
    }

    /**
     * Sets the field customized flag for a given field in formFields collection.
     * If elementId is not provided, it will iterate through all the custom elements and set the values
     * @param elementId
     */
    private setFieldCustomizedFlag(elementId: any = null) {
        if (!this.validationService.isNullOrEmpty(elementId)) {
            let result: any = this.getCurrentSelectedElement();
            if (!this.validationService.isNullOrEmpty(result)) {
                this.formFields[elementId].fieldCustomized = result.customRequired == true || result.customFieldInquiry == true || result.customFieldNoAccess == true || result.customFirstField == true
                    || result.customLastField == true || !this.validationService.isNullOrEmpty(result.customFieldForward);
            }
        }
        else {
            for (let c = 0; c < this.customElements.length; c++) {
                let curr: any = this.customElements[c];
                this.formFields[curr.elementID].fieldCustomized = curr.customRequired == true || curr.customFieldInquiry == true || curr.customFieldNoAccess == true || curr.customFirstField == true
                    || curr.customLastField == true || !this.validationService.isNullOrEmpty(curr.customFieldForward);
            }
        }
    }

    /**
     * Initialization code to save the customElements and their properties to the cache. If a user clears the customizations, simply reset using this cached data.
     */
    private init() {
        this.formFields = this.formService.getLatestForm().fields;
        this.setCustomSequenceOrders();
        this.getCustomElements();
        this.currentForm = this.formService.activeForm;
        this.currentOperator = this.sessionService.getOperatorId();
        this.displayCopyToOtherUsersDropdown = false;
        this.setFieldCustomizedFlag();
        this.clearAllFieldsClicked = false;
    }

    //endregion Initialize

    //region Current Field

    /**
     * Gets the currently selected element based on the current selected field dropdown list's current selection
     * @returns current selected element
     */
    private getCurrentSelectedElement() {
        let resultElement = $.grep(this.customElements, (e: any) => {
            return e.elementOrder == this.selectedCurrentField.key;
        });

        return resultElement != null && resultElement.length > 0 ? resultElement[0] : null;
    }

    /**
     * Gets the list of items to be bound to the Current field dropdown list in ascending order of element Order
     * @returns {Array}
     */
    private getCurrentFieldList() {
        let currentFieldListArr = [];

        for (let i = 0; i < this.customElements.length; i++) {
            // Get the elementID
            let elem = this.customElements[i].elementID;
            let elemID = "";

            // If the element already exists, continue to next element in the loop
            let found = false;
            for (let c = 0; c < currentFieldListArr.length; c++) {
                if (currentFieldListArr[c].key == this.formFields[elem].elementOrder) {
                    found = true;
                    break;
                }
            }
            if (found == true)
                continue;

            // If the formElement is not header or phantom or form label and has a valid elementOrder
            if (!this.validationService.isNullOrEmpty(this.formFields[elem].elementOrder)
                && !this.formFields[elem].isPhantomField && !this.formFields[elem].isFormLabel) {

                // If the current field is a header field, check if it is not a part of the hide header fields. If it is, do not add it to the current field list
                if (this.formFields[elem].isHeaderField && this.formService.hideHeaderFields.length > 0) {
                    let addHeaderField = true;
                    for (let h = 0; h < this.formService.hideHeaderFields.length; h++) {
                        let headerField = this.formService.hideHeaderFields[h];
                        if (headerField.fieldName.toLocaleUpperCase() == elem.toLocaleUpperCase()
                            || (elem.indexOf('_') > 0 && headerField.fieldName.toLocaleUpperCase() == elem.split('_')[0].toLocaleUpperCase())) {
                            if (headerField.formID.toLocaleUpperCase() == this.formService.activeForm.formId.toLocaleUpperCase()
                                || (this.formService.activeForm.formId.indexOf('_') > 0 && headerField.formID.toLocaleUpperCase() == this.formService.activeForm.formId.split('_')[0].toLocaleUpperCase())) {
                                addHeaderField = false;
                                break;
                            }
                        }
                    }

                    if (addHeaderField == true) {
                        elemID = this.formFields[elem].elementID;

                        // If element has a window controller, ID will have an underscore (_). Split by _ and get first item of the array. This will be the ID that gets added to the dropdown list.
                        if (!this.validationService.isNullOrEmpty(this.formFields[elem].elementWindowController)) {
                            if (elemID.indexOf('_') > 0) {
                                elemID = elemID.split('_')[0];
                            }
                        }

                        // Add the item to dropdown list array
                        currentFieldListArr.push({
                            key: this.formFields[elem].elementOrder,
                            value: this.formFields[elem].elementOrder + " - " + elemID
                        });
                    }
                }
                else {
                    elemID = this.formFields[elem].elementID;

                    // If element has a window controller, ID will have an underscore (_). Split by _ and get first item of the array. This will be the ID that gets added to the dropdown list.
                    if (!this.validationService.isNullOrEmpty(this.formFields[elem].elementWindowController)) {
                        if (elemID.indexOf('_') > 0) {
                            elemID = elemID.split('_')[0];
                        }
                    }

                    // Add the item to dropdown list array
                    currentFieldListArr.push({
                        key: this.formFields[elem].elementOrder,
                        value: this.formFields[elem].elementOrder + " - " + elemID
                    });
                }
            }
        }

        // Sort the array
        currentFieldListArr.sort((a: any, b: any) => {
            return a.key - b.key;
        });

        return currentFieldListArr;
    }

    /**
     * Gets an array containing fields that had a field forward customized for them
     * @returns {Array}
     */
    private getCurrentFields() {
        let resultElements = $.grep(this.customElements, (c: any) => {
            return c.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(c.customFieldForward);
        });

        // If there are any result elements, return them.
        return resultElements != null && resultElements.length > 0 ? resultElements : [];
    }

    //endregion Current Field

    //region Next Field

    /**
     * Gets the list of items to be bound to the Next field dropdown list in ascending order of element Order
     * @returns {Array}
     */
    private getNextFieldList() {
        let nextFieldListArr: any = [];
        let keysAdded: any = [];

        for (let i = 0; i < this.customElements.length; i++) {

            // Get the elementID
            let elem: any = this.customElements[i].elementID;
            let elemID: string = "";

            if (keysAdded.indexOf(this.formFields[elem].elementOrder) >= 0)
                continue;

            // If the formElement is not header or phantom or form label and has a valid elementOrder
            // Last clause of the condition -- If element is inquiry, then it can have a sequenceOrder only if it is detailable. Items that don't satisfy this condition should not be added to dropdown.
            if (!this.validationService.isNullOrEmpty(this.formFields[elem].elementOrder)
                && !this.formFields[elem].isHeaderField
                && !this.formFields[elem].isPhantomField && !this.formFields[elem].isFormLabel
                && (((this.formFields[elem].isInquiryField) && !this.validationService.isNullOrEmpty(this.formFields[elem].elementDetailable) && this.formFields[elem].elementDetailable == true)
                || (!this.formFields[elem].isInquiryField))) {
                elemID = this.formFields[elem].elementID;

                // If element has a window controller, ID will have an underscore (_). Split by _ and get first item of the array. This will be the ID that gets added to the dropdown list.
                if (!this.validationService.isNullOrEmpty(this.formFields[elem].elementWindowController)) {
                    if (elemID.indexOf('_') > 0) {
                        elemID = elemID.split('_')[0];
                    }
                }

                // Add the item to dropdown list array
                nextFieldListArr.push({
                    key: this.formFields[elem].elementOrder,
                    value: this.formFields[elem].elementOrder + " - " + elemID
                });
                keysAdded.push(this.formFields[elem].elementOrder);
            }
        }

        // Sort the array
        nextFieldListArr.sort((a: any, b: any) => {
            return a.key - b.key;
        });

        // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
        let selectedKey: any = this.selectedCurrentField;
        // If dropdown didn't have any selected value, remove the first item from the next field dropdown list array.
        if (this.validationService.isNullOrEmpty(selectedKey)) {
            selectedKey = nextFieldListArr[0].key;
        }

        this.findAndRemove(nextFieldListArr, "key", selectedKey.key);

        return nextFieldListArr;
    }

    /**
     * Check whether the next field dropdown should be enabled or disabled
     * @param selectedCustomizeUser
     * @returns {boolean}
     */
    private nextFieldDisabledCheck(selectedCustomizeUser: string = null): boolean {
        // Check the global override settings for first field
        if (this.rootScopeService.AllTabOverrideAllowed != null && this.rootScopeService.AllTabOverrideAllowed == false) {
            let isCurrentUser: boolean = !this.validationService.isNullOrEmpty(selectedCustomizeUser) ? selectedCustomizeUser.toLocaleLowerCase() == this.currentOperator.toLocaleLowerCase() : true;

            // Check user specific override settings for first field. If the overrides are allowed for the current user, proceed with checking other conditions
            if (this.rootScopeService.UserTabOverrideAllowed != null && this.rootScopeService.UserTabOverrideAllowed == true && isCurrentUser == true) {
                // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
                let resultElement: any = this.getCurrentSelectedElement();

                // If the currentElement is a header element, disable next field dropdown.
                if (!this.validationService.isNullOrEmpty(resultElement) && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)
                    && this.formFields[resultElement.elementID].isHeaderField) {
                    // If current selected element is a header field, return true to disable first field.
                    return true;
                }

                // If the currentElement is inquiry and not detailable, disable next field dropdown
                let isInquiry: boolean = this.fieldInquiry(resultElement, false);
                if (isInquiry == true) {
                    // If the form field has elementDetailable property and it is not set to true, disable next field
                    if (this.formFields[resultElement.elementID].hasOwnProperty("elementDetailable") && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID].elementDetailable) && this.formFields[resultElement.elementID].elementDetailable != true) {
                        return true;
                    }
                }

                // If the currentElement is the last field, disable next field dropdown. If a field is last field in UI4, it doesn't allow you to select next field anyways.
                if (resultElement.hasOwnProperty("customLastField") && !this.validationService.isNullOrEmpty(resultElement.customLastField) && resultElement.customLastField == true) {
                    return true;
                }

                return false;
            }
            // If the user selected is not the current operator, disable the overrides for all other users.
            else {
                return true;
            }
        }
        // If global overrides are allowed, it doesn't matter what the current user's override setting is, the overrides will be allowed. Proceed with checking other conditions.
        else {
            // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
            let resultElement: any = this.getCurrentSelectedElement();

            // If the currentElement is a header element, disable next field dropdown.
            if (!this.validationService.isNullOrEmpty(resultElement) && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)
                && this.formFields[resultElement.elementID].isHeaderField) {
                // If current selected element is a header field, return true to disable first field.
                return true;
            }

            // If the currentElement is inquiry and not detailable, disable next field dropdown
            let isInquiry: boolean = this.fieldInquiry(resultElement, false);
            if (isInquiry == true) {
                // If the form field has elementDetailable property and it is not set to true, disable next field
                if (this.formFields[resultElement.elementID].hasOwnProperty("elementDetailable") && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID].elementDetailable) && this.formFields[resultElement.elementID].elementDetailable != true) {
                    return true;
                }
            }

            // If the currentElement is the last field, disable next field dropdown. If a field is last field in UI4, it doesn't allow you to select next field anyways.
            if (resultElement.hasOwnProperty("customLastField") && !this.validationService.isNullOrEmpty(resultElement.customLastField) && resultElement.customLastField == true) {
                return true;
            }

            return false;
        }
    }

    /**
     * Gets an array containing the elements that were set as field forward for other fields
     * @returns {Array}
     */
    private getNextFields() {
        let result: any = [];
        let resultElements: any = $.grep(this.customElements, (c: any) => {
            return c.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(c.customFieldForward);
        });

        // If there are any elements, process the field forward items
        if (resultElements && resultElements.length > 0) {
            // Iterate through all the result elements that have a customFieldForward
            for (let r = 0; r < resultElements.length; r++) {
                // Take the customFieldForward and try to find that element in customElements
                let elem: any = $.grep(this.customElements, (c: any) => {
                    return c.elementID.toUpperCase() == resultElements[r].customFieldForward.toUpperCase();
                });
                // If no result was found, it might be a window controller. Try to add "_1" at the end and try again.
                if (this.validationService.isNullOrEmpty(elem) || elem.length == 0) {
                    elem = $.grep(this.customElements, (c: any) => {
                        return c.elementID.toUpperCase() == resultElements[r].customFieldForward.toUpperCase() + "_1";
                    });
                }

                if (elem && elem.length > 0) {
                    result.push(elem[0]);
                }
            }
        }

        return result;
    }

    /**
     * On load of CFS overlay, selects the next field if any.
     * @returns {string}
     */
    private getSelectedNextField(): string {
        let selectNextField: string = "";

        // Load the forward fields of this current selected field, if any.
        let currentElement: any = this.getCurrentSelectedElement();
        if (currentElement && currentElement.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(currentElement.customFieldForward)) {
            // Get elementID of field forward and based on this ID, get the field element
            let fieldForwardId: string = currentElement.customFieldForward;
            let fieldForwardElement: any = $.grep(this.customElements, (c: any) => {
                return c.elementID.toUpperCase() == fieldForwardId.toUpperCase();
            });

            // If the customFieldForward had an ID that did not match any of the customElements, try adding "_1" at the end.
            // Why was this done? Customizations in 4.x, when saving custom sequence, do not pass the _1 at the end if the next field is a window controller.
            // Therefore, we have to manually check for that in this condition.
            if (this.validationService.isNullOrEmpty(fieldForwardElement) || fieldForwardElement.length == 0) {
                fieldForwardId = currentElement.customFieldForward + "_1";
                fieldForwardElement = $.grep(this.customElements, (c: any) => {
                    return c.elementID.toUpperCase() == fieldForwardId.toUpperCase();
                });
            }

            // Get the key for this fieldForward from the nextFieldList and select that element.
            if (fieldForwardElement && fieldForwardElement.length > 0) {
                if (fieldForwardElement[0].hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(fieldForwardElement[0].elementOrder)
                    && !isNaN(fieldForwardElement[0].elementOrder)) {
                    for (let n = 0; n < this.nextFieldList.length; n++) {
                        let nextField: any = this.nextFieldList[n];
                        if (nextField.hasOwnProperty("key") && nextField.key == fieldForwardElement[0].elementOrder) {
                            let elementPos: any = $.map(this.nextFieldList, (obj: any, index: any) => {
                                if (obj.key == nextField.key) {
                                    return index;
                                }
                            })[0];
                            if (elementPos && !isNaN(elementPos)) {
                                selectNextField = this.nextFieldList[elementPos];
                            }
                            break;
                        }
                    }
                }
            }
        }

        return selectNextField;
    }

    //endregion Next Field

    //region First Field

    /**
     * Checks if the current selected field has been customized to be the first field or not
     * @returns {boolean}
     */
    private checkCustomFirstField(): boolean {
        // Get matching element
        let resultElement: any = this.getCurrentSelectedElement();

        return !this.validationService.isNullOrEmpty(resultElement) ? resultElement.hasOwnProperty("customFirstField") && !this.validationService.isNullOrEmpty(resultElement.customFirstField) && resultElement.customFirstField === true : false;
    }

    /**
     * If a first field is selected in custom field sequence, return the field otherwise return null.
     * @returns firstField
     */
    private getFirstField() {
        let firstField: any = null;

        // Get the array of elements with customFirstField property set to true.
        let firstFieldElement = $.grep(this.customElements, (e: any) => {
            return e.customFirstField == true;
        });

        // There should only be one element with customFirstField property set to true. If the length of the array is greater than one, it means the customFirstField property is set to true.
        if (firstFieldElement && firstFieldElement.length > 0)
            firstField = firstFieldElement[0];

        return firstField;
    }

    /**
     * Check whether the first field checkbox should be enabled or disabled
     * @param selectedCustomizeUser
     * @returns {boolean}
     */
    private firstFieldDisabledCheck(selectedCustomizeUser: string = null): boolean {
        // Check the global override settings for first field
        if (this.rootScopeService.AllTabOverrideAllowed != null && this.rootScopeService.AllTabOverrideAllowed == false) {
            let isCurrentUser: boolean = !this.validationService.isNullOrEmpty(selectedCustomizeUser) ? selectedCustomizeUser.toLocaleLowerCase() == this.currentOperator.toLocaleLowerCase() : true;

            // Check user specific override settings for first field. If the overrides are allowed for the current user, proceed with checking other conditions
            if (this.rootScopeService.UserTabOverrideAllowed != null && this.rootScopeService.UserTabOverrideAllowed == true && isCurrentUser == true) {
                // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
                let resultElement: any = this.getCurrentSelectedElement();

                // If the currentElement is a header element, disable first field checkbox.
                if (!this.validationService.isNullOrEmpty(resultElement) && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                    // If current selected element is a header field, return true to disable first field.
                    if (this.formFields[resultElement.elementID].isHeaderField) {
                        return true;
                    }
                }

                // Get first field.
                let firstField: any = this.getFirstField();
                if (!this.validationService.isNullOrEmpty(firstField)) {
                    // If the current element matches the first field, enable checkbox otherwise disable it.
                    return firstField.elementID.toUpperCase() != resultElement.elementID.toUpperCase();
                }
            }
            // If the user selected is not the current operator, disable the overrides for all other users.
            else {
                return true;
            }
        }
        // If global overrides are allowed, it doesn't matter what the current user's override setting is, the overrides will be allowed. Proceed with checking other conditions.
        else {
            // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
            let resultElement: any = this.getCurrentSelectedElement();

            // If the currentElement is a header element, disable first field checkbox.
            if (!this.validationService.isNullOrEmpty(resultElement) && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                // If current selected element is a header field, return true to disable first field.
                if (this.formFields[resultElement.elementID].isHeaderField) {
                    return true;
                }
            }

            // Get first field.
            let firstField: any = this.getFirstField();
            if (!this.validationService.isNullOrEmpty(firstField)) {
                // If the current element matches the first field, enable checkbox otherwise disable it.
                return firstField.elementID.toUpperCase() != resultElement.elementID.toUpperCase();
            }
        }
    }

    //endregion First Field

    //region Last field

    /**
     * Checks if the current selected field has been customized to be the last field or not
     * @returns {boolean}
     */
    private checkCustomLastField(): boolean {
        // Get matching element
        let resultElement: any = this.getCurrentSelectedElement();
        return !this.validationService.isNullOrEmpty(resultElement) ? resultElement.hasOwnProperty("customLastField") && !this.validationService.isNullOrEmpty(resultElement.customLastField) && resultElement.customLastField === true : false;
    }

    /**
     * If a last field is selected in custom field sequence, return the field otherwise return null.
     * @returns {any}
     */
    private getLastField() {
        let lastField: any = null;

        // Get the array of elements with customFirstField property set to true.
        let lastFieldElement: any = $.grep(this.customElements, (e: any) => {
            return e.customLastField == true;
        });

        // There should only be one element with customFirstField property set to true. If the length of the array is greater than one, it means the customFirstField property is set to true.
        if (lastFieldElement && lastFieldElement.length > 0)
            lastField = lastFieldElement[0];

        return lastField;
    }

    /**
     * Check whether the last field checkbox should be enabled or disabled
     * @param selectedCustomizeUser
     * @returns {boolean}
     */
    private lastFieldDisabledCheck(selectedCustomizeUser: string = null): boolean {
        // Check the global override settings for last field
        if (this.rootScopeService.AllTabOverrideAllowed != null && this.rootScopeService.AllTabOverrideAllowed == false) {
            let isCurrentUser: boolean = !this.validationService.isNullOrEmpty(selectedCustomizeUser) ? selectedCustomizeUser.toLocaleLowerCase() == this.currentOperator.toLocaleLowerCase() : true;

            // Check user specific override settings for last field. If the overrides are allowed for the current user, proceed with checking other conditions
            if (this.rootScopeService.UserTabOverrideAllowed != null && this.rootScopeService.UserTabOverrideAllowed == true && isCurrentUser == true) {
                // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
                let resultElement: any = this.getCurrentSelectedElement();

                // If the currentElement is a header element, disable last field checkbox.
                if (!this.validationService.isNullOrEmpty(resultElement) && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)
                    && this.formFields[resultElement.elementID].isHeaderField) {
                    // If current selected element is a header field, return true to disable first field.
                    return true;
                }

                // If the currentElement has a customFieldForward, last field should be disabled.
                if (resultElement.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(resultElement.customFieldForward)) {
                    return true;
                }

                // Get last field.
                let lastField: any = this.getLastField();

                // If there is no last field, check to see if there is a first field and if the current element's order is less than the first field.
                // If the above condition is true, check if the current field has a custom sequence order. If it does, do not disable last field since the user might be customizing field sequence in reverse order.
                // If it is, do not enable last field checkbox for these fields.
                if (this.validationService.isNullOrEmpty(lastField)) {
                    // Get the first element
                    let firstField: any = this.getFirstField();

                    // If there is no first field, enable the last field checkbox
                    if (this.validationService.isNullOrEmpty(firstField))
                        return false;

                    let currentElemOrder: string = "";
                    let firstElemOrder: string = "";

                    // Current field element order
                    if (resultElement.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(resultElement.elementOrder) && !isNaN(resultElement.elementOrder)) {
                        currentElemOrder = resultElement.elementOrder;
                    }
                    // Last field element order
                    if (firstField.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(firstField.elementOrder) && !isNaN(firstField.elementOrder)) {
                        firstElemOrder = firstField.elementOrder;
                    }

                    // Compare element orders and enable/disable first field checkbox
                    if (!this.validationService.isNullOrEmpty(currentElemOrder) && !this.validationService.isNullOrEmpty(firstElemOrder)) {
                        if (currentElemOrder < firstElemOrder) {
                            // Check if resultElement has a customSequenceOrder and if it does, do not disable last field otherwise disable last field
                            return !(resultElement.hasOwnProperty("customSequenceOrder") && !this.validationService.isNullOrEmpty(resultElement.customSequenceOrder) && !isNaN(resultElement.customSequenceOrder));
                        }
                    }
                    else {
                        return false;
                    }
                }

                // If nothing, always keep last field checkbox enabled.
                return false;
            }
            // If the user selected is not the current operator, disable the overrides for all other users.
            else {
                return true;
            }
        }
        else {
            // Get the current selected item from Current Field Dropdown and remove it from the Next field dropdown
            let resultElement: any = this.getCurrentSelectedElement();

            // If the currentElement is a header element, disable last field checkbox.
            if (!this.validationService.isNullOrEmpty(resultElement) && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)
                && this.formFields[resultElement.elementID].isHeaderField) {
                // If current selected element is a header field, return true to disable first field.
                return true;
            }

            // If the currentElement has a customFieldForward, last field should be disabled.
            if (resultElement.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(resultElement.customFieldForward)) {
                return true;
            }

            // Get last field.
            let lastField: any = this.getLastField();

            // If there is no last field, check to see if there is a first field and if the current element's order is less than the first field.
            // If the above condition is true, check if the current field has a custom sequence order. If it does, do not disable last field since the user might be customizing field sequence in reverse order.
            // If it is, do not enable last field checkbox for these fields.
            if (this.validationService.isNullOrEmpty(lastField)) {
                // Get the first element
                let firstField: any = this.getFirstField();

                // If there is no first field, enable the last field checkbox
                if (this.validationService.isNullOrEmpty(firstField))
                    return false;

                let currentElemOrder: string = "";
                let firstElemOrder: string = "";

                // Current field element order
                if (resultElement.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(resultElement.elementOrder) && !isNaN(resultElement.elementOrder)) {
                    currentElemOrder = resultElement.elementOrder;
                }
                // Last field element order
                if (firstField.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(firstField.elementOrder) && !isNaN(firstField.elementOrder)) {
                    firstElemOrder = firstField.elementOrder;
                }

                // Compare element orders and enable/disable first field checkbox
                if (!this.validationService.isNullOrEmpty(currentElemOrder) && !this.validationService.isNullOrEmpty(firstElemOrder)) {
                    if (currentElemOrder < firstElemOrder) {
                        // Check if resultElement has a customSequenceOrder and if it does, do not disable last field otherwise disable last field
                        return !(resultElement.hasOwnProperty("customSequenceOrder") && !this.validationService.isNullOrEmpty(resultElement.customSequenceOrder) && !isNaN(resultElement.customSequenceOrder));
                    }
                }
                else {
                    return false;
                }
            }

            // If nothing, always keep last field checkbox enabled.
            return false;
        }
    }

    //endregion Last field

    //region Required

    /**
     * Checks if the current field was set to be required and enables/disables the required checkbox
     * @returns {boolean}
     */
    private fieldRequired(): boolean {
        let fieldRequiredFlag = false;

        // Get the current selected item from Current Field Dropdown (sortedElements)
        let resultElement = this.getCurrentSelectedElement();

        if (!this.validationService.isNullOrEmpty(resultElement)) {
            // If the currentElement is a header element, disable required checkbox.
            if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                // If current selected element is a header field, return true to disable required field.
                if (this.formFields[resultElement.elementID].isHeaderField) {
                    fieldRequiredFlag = false;
                    this.requiredDisabled = true;
                    this.inquiryDisabled = true;
                    this.noAccessDisabled = false;
                    return fieldRequiredFlag;
                }
            }

            // Check if the element's customRequired property is true
            if (resultElement.hasOwnProperty("customRequired") && !this.validationService.isNullOrEmpty(resultElement.customRequired) && resultElement.customRequired === true) {
                fieldRequiredFlag = true;
                this.requiredDisabled = false;
                this.inquiryDisabled = true;
                this.noAccessDisabled = true;
                return fieldRequiredFlag;
            }

            // Check if this element had the elementRequired property set to Y in the original form specs
            if (fieldRequiredFlag == false && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                let formElement = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                if (!this.validationService.isNullOrEmpty(formElement) && formElement.hasOwnProperty("elementRequired")
                    && !this.validationService.isNullOrEmpty(formElement.elementRequired) && formElement.elementRequired.toUpperCase() == "Y") {
                    fieldRequiredFlag = true;
                    this.requiredDisabled = true;
                    this.inquiryDisabled = true;
                    this.noAccessDisabled = true;
                    return fieldRequiredFlag;
                }
            }
        }

        return fieldRequiredFlag;
    }

    /**
     * Check whether the required checkbox should be enabled or disabled
     * @param selectedCustomizeUser
     * @returns {boolean}
     */
    private requiredFieldDisabledCheck(selectedCustomizeUser: string = null): boolean {
        // Check the global override settings for required field
        if (this.rootScopeService.AllFieldAccessOverrideAllowed != null && this.rootScopeService.AllFieldAccessOverrideAllowed == false) {
            let isCurrentUser: boolean = !this.validationService.isNullOrEmpty(selectedCustomizeUser) ? selectedCustomizeUser.toLocaleLowerCase() == this.currentOperator.toLocaleLowerCase() : true;

            // Check user specific override settings for required field. If the overrides are allowed for the current user, proceed with checking other conditions
            if (this.rootScopeService.UserFieldAccessOverrideAllowed != null && this.rootScopeService.UserFieldAccessOverrideAllowed == true && isCurrentUser == true) {
                // Get the current selected item from Current Field Dropdown (sortedElements)
                let resultElement: any = this.getCurrentSelectedElement();

                if (!this.validationService.isNullOrEmpty(resultElement)) {
                    // If the currentElement is a header element, disable required checkbox.
                    if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                        // If current selected element is a header field, return true to disable first field.
                        if (this.formFields[resultElement.elementID].isHeaderField) {
                            return true;
                        }
                    }

                    // Check if the element's customRequired property is true
                    if (resultElement.hasOwnProperty("customRequired") && !this.validationService.isNullOrEmpty(resultElement.customRequired) && resultElement.customRequired === true) {
                        return false;
                    }

                    // Check if this element had the elementRequired property set to Y in the original form specs
                    if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                        let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                        if (!this.validationService.isNullOrEmpty(formElement) && formElement.hasOwnProperty("elementRequired")
                            && !this.validationService.isNullOrEmpty(formElement.elementRequired) && formElement.elementRequired.toUpperCase() == "Y") {
                            return true;
                        }
                    }

                    // Check if this element has been customized to be inquiry only or no access. If yes, disable required
                    if (this.inquiryChecked == true || this.noAccessChecked == true) {
                        return true;
                    }
                }

                return false;
            }
            // If the user selected is not the current operator, disable the overrides for all other users.
            else {
                return true;
            }
        }
        else {
            // Get the current selected item from Current Field Dropdown (sortedElements)
            let resultElement: any = this.getCurrentSelectedElement();

            if (!this.validationService.isNullOrEmpty(resultElement)) {
                // If the currentElement is a header element, disable required checkbox.
                if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                    // If current selected element is a header field, return true to disable first field.
                    if (this.formFields[resultElement.elementID].isHeaderField) {
                        return true;
                    }
                }

                // Check if the element's customRequired property is true
                if (resultElement.hasOwnProperty("customRequired") && !this.validationService.isNullOrEmpty(resultElement.customRequired) && resultElement.customRequired === true) {
                    return false;
                }

                // Check if this element had the elementRequired property set to Y in the original form specs
                if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                    let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                    if (!this.validationService.isNullOrEmpty(formElement) && formElement.hasOwnProperty("elementRequired")
                        && !this.validationService.isNullOrEmpty(formElement.elementRequired) && formElement.elementRequired.toUpperCase() == "Y") {
                        return true;
                    }
                }

                // Check if this element has been customized to be inquiry only or no access. If yes, disable required
                if (this.inquiryChecked == true || this.noAccessChecked == true) {
                    return true;
                }
            }

            return false;
        }
    }

    /**
     * Checks if any required fields come before the customized first field
     * @returns {boolean}
     */
    private isRequiredFieldBeforeFirstField(): boolean {
        let firstField: any = this.getFirstField();
        let firstFieldSequenceOrder: number = firstField.customSequenceOrder;

        for (let c = 0; c < this.customElements; c++) {
            let resultElement: any = this.customElements[c];

            if (!this.validationService.isNullOrEmpty(resultElement)) {
                // Check if the element's customRequired property is true
                if (resultElement.hasOwnProperty("customRequired") && !this.validationService.isNullOrEmpty(resultElement.customRequired) && resultElement.customRequired === true) {
                    if (firstFieldSequenceOrder > resultElement.customSequenceOrder) {
                        return true;
                    }
                }

                // Check if this element had the elementRequired property set to Y in the original form specs
                if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                    let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                    if (!this.validationService.isNullOrEmpty(formElement) && formElement.hasOwnProperty("elementRequired")
                        && !this.validationService.isNullOrEmpty(formElement.elementRequired) && formElement.elementRequired.toUpperCase() == "Y") {
                        if (firstFieldSequenceOrder > formElement.customSequenceOrder) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    /**
     * Gets all the customized required fields in an array.
     * @returns {Array}
     */
    private getRequiredFields() {
        // Get all fields that have customRequired property set to true
        let resultElements: any = $.grep(this.customElements, (r: any) => {
            return r.customRequired == true;
        });

        // If there are any result elements, return them.
        return resultElements && resultElements.length > 0 ? resultElements : [];
    }

    /**
     * Gets all the default required fields in an array
     * @returns {Array}
     */
    private getDefaultRequiredFields() {
        let defaultRequiredFieldsArr: any = [];
        for (let element = 0; element < this.customElements.length; element++) {
            let resultElement: any = this.customElements[element];
            if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                if (!this.validationService.isNullOrEmpty(formElement) && formElement.hasOwnProperty("elementRequired")
                    && !this.validationService.isNullOrEmpty(formElement.elementRequired) && formElement.elementRequired.toUpperCase() == "Y") {
                    if (!this.validationService.isNullOrEmpty(formElement.elementOrder) && !formElement.isHeaderField
                        && !formElement.isPhantomField && !formElement.isFormLabel) {
                        defaultRequiredFieldsArr.push(resultElement);
                    }
                }
            }
        }
        return defaultRequiredFieldsArr;
    }

    //endregion Required

    //region Inquiry

    /**
     * Checks if the current field was set to be inquiry
     * @param elementToCheck
     * @param setCheckBoxesFlag
     * @returns {boolean}
     */
    private fieldInquiry(elementToCheck: any = null, setCheckBoxesFlag: any = null): boolean {
        let fieldInquiryFlag: boolean = false;

        if (this.validationService.isNullOrEmpty(setCheckBoxesFlag))
            setCheckBoxesFlag = true;

        let resultElement: any;
        // Get the current selected item from Current Field Dropdown
        if (this.validationService.isNullOrEmpty(elementToCheck))
            resultElement = this.getCurrentSelectedElement();
        else if (!this.validationService.isNullOrEmpty(elementToCheck))
            resultElement = elementToCheck;

        if (!this.validationService.isNullOrEmpty(resultElement)) {
            // If the currentElement is a header element, disable inquiry checkbox.
            if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                // If current selected element is a header field, return true to disable first field.
                if (this.formFields[resultElement.elementID].isHeaderField) {
                    fieldInquiryFlag = false;
                    if (setCheckBoxesFlag != false) {
                        this.requiredDisabled = true;
                        this.inquiryDisabled = true;
                        this.noAccessDisabled = false;
                    }
                    return fieldInquiryFlag;
                }
            }

            // If customFieldInquiry property is set to true, it means the element was customized to be inquiry
            if (resultElement.hasOwnProperty("customFieldInquiry") && !this.validationService.isNullOrEmpty(resultElement.customFieldInquiry) && resultElement.customFieldInquiry == true) {
                fieldInquiryFlag = true;
                if (setCheckBoxesFlag != false) {
                    this.inquiryDisabled = false;
                    this.requiredDisabled = true;
                    this.noAccessDisabled = true;
                }
                return fieldInquiryFlag;
            }

            // If field was not set to inquiry through customization, check if it was set through a FieldSecurityMessage or through original form specs
            if (fieldInquiryFlag == false && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                if (!this.validationService.isNullOrEmpty(formElement)) {
                    // FieldSecurityMessage inquiry setting
                    if (this.clearAllFieldsClicked != true) {
                        if ((formElement.hasOwnProperty("runTimeInquiryField") && !this.validationService.isNullOrEmpty(formElement.runTimeInquiryField) && formElement.runTimeInquiryField == true)
                            || (formElement.hasOwnProperty("runTimeRTInquiryField") && !this.validationService.isNullOrEmpty(formElement.runTimeRTInquiryField) && formElement.runTimeRTInquiryField == true)) {
                            fieldInquiryFlag = true;
                            if (setCheckBoxesFlag != false) {
                                this.inquiryDisabled = false;
                                this.requiredDisabled = false;
                                this.noAccessDisabled = false;
                            }
                            return fieldInquiryFlag;
                        }
                    }

                    // Original Form Specs inquiry setting
                    if (fieldInquiryFlag == false && formElement.hasOwnProperty("elementType")
                        && !this.validationService.isNullOrEmpty(formElement.elementType) && formElement.elementType.toLowerCase() == "inquiry") {
                        fieldInquiryFlag = true;
                        if (setCheckBoxesFlag != false) {
                            this.inquiryDisabled = true;
                            this.requiredDisabled = true;
                            this.noAccessDisabled = false;
                            this.firstFieldDisabled = true;
                            this.lastFieldDisabled = true;
                        }
                        return fieldInquiryFlag;
                    }
                }
            }
        }

        return fieldInquiryFlag;
    }

    /**
     * Check whether the inquiry only checkbox should be enabled or disabled
     * @param selectedCustomizeUser
     * @returns {boolean}
     */
    private inquiryFieldDisabledCheck(selectedCustomizeUser: string = null): boolean {
        // Check the global override settings for inquiry field
        if (this.rootScopeService.AllFieldAccessOverrideAllowed != null && this.rootScopeService.AllFieldAccessOverrideAllowed == false) {
            let isCurrentUser: boolean = !this.validationService.isNullOrEmpty(selectedCustomizeUser) ? selectedCustomizeUser.toLocaleLowerCase() == this.currentOperator.toLocaleLowerCase() : true;

            // Check user specific override settings for inquiry field. If the overrides are allowed for the current user, proceed with checking other conditions
            if (this.rootScopeService.UserFieldAccessOverrideAllowed != null && this.rootScopeService.UserFieldAccessOverrideAllowed == true && isCurrentUser == true) {
                let resultElement: any = this.getCurrentSelectedElement();

                if (!this.validationService.isNullOrEmpty(resultElement)) {
                    // If the currentElement is a header element, disable inquiry checkbox.
                    if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)
                        && this.formFields[resultElement.elementID].isHeaderField) {
                        // If current selected element is a header field, return true to disable first field.
                        return true;
                    }

                    // If customFieldInquiry property is set to true, it means the element was customized to be inquiry
                    if (resultElement.hasOwnProperty("customFieldInquiry") && !this.validationService.isNullOrEmpty(resultElement.customFieldInquiry)
                        && resultElement.customFieldInquiry == true) {
                        return false;
                    }

                    // If field was not set to inquiry through customization, check if it was set through a FieldSecurityMessage or through original form specs
                    if (this.clearAllFieldsClicked != true) {
                        if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                            let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                            if (!this.validationService.isNullOrEmpty(formElement)) {
                                // FieldSecurityMessage inquiry setting
                                if ((formElement.hasOwnProperty("runTimeInquiryField") && !this.validationService.isNullOrEmpty(formElement.runTimeInquiryField) && formElement.runTimeInquiryField == true)
                                    || (formElement.hasOwnProperty("runTimeRTInquiryField") && !this.validationService.isNullOrEmpty(formElement.runTimeRTInquiryField) && formElement.runTimeRTInquiryField == true)) {
                                    return false;
                                }

                                // Original Form Specs inquiry setting
                                if (formElement.hasOwnProperty("elementType") && !this.validationService.isNullOrEmpty(formElement.elementType)
                                    && formElement.elementType.toLowerCase() == "inquiry") {
                                    return true;
                                }
                            }
                        }
                    }

                    // Check if this element has been customized to be required or no access. If yes, disable inquiry only
                    if (this.requiredChecked == true || this.noAccessChecked == true) {
                        return true;
                    }
                }

                return false;
            }
            // If the user selected is not the current operator, disable the overrides for all other users.
            else {
                return true;
            }
        }
        else {
            let resultElement: any = this.getCurrentSelectedElement();

            if (!this.validationService.isNullOrEmpty(resultElement)) {
                // If the currentElement is a header element, disable inquiry checkbox.
                if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)
                    && this.formFields[resultElement.elementID].isHeaderField) {
                    // If current selected element is a header field, return true to disable first field.
                    return true;
                }

                // If customFieldInquiry property is set to true, it means the element was customized to be inquiry
                if (resultElement.hasOwnProperty("customFieldInquiry") && !this.validationService.isNullOrEmpty(resultElement.customFieldInquiry)
                    && resultElement.customFieldInquiry == true) {
                    return false;
                }

                // If field was not set to inquiry through customization, check if it was set through a FieldSecurityMessage or through original form specs
                if (this.clearAllFieldsClicked != true) {
                    if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                        let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                        if (!this.validationService.isNullOrEmpty(formElement)) {
                            // FieldSecurityMessage inquiry setting
                            if ((formElement.hasOwnProperty("runTimeInquiryField") && !this.validationService.isNullOrEmpty(formElement.runTimeInquiryField) && formElement.runTimeInquiryField == true)
                                || (formElement.hasOwnProperty("runTimeRTInquiryField") && !this.validationService.isNullOrEmpty(formElement.runTimeRTInquiryField) && formElement.runTimeRTInquiryField == true)) {
                                return false;
                            }

                            // Original Form Specs inquiry setting
                            if (formElement.hasOwnProperty("elementType") && !this.validationService.isNullOrEmpty(formElement.elementType)
                                && formElement.elementType.toLowerCase() == "inquiry") {
                                return true;
                            }
                        }
                    }
                }

                // Check if this element has been customized to be required or no access. If yes, disable inquiry only
                if (this.requiredChecked == true || this.noAccessChecked == true) {
                    return true;
                }
            }

            return false;
        }
    }

    /**
     * Gets all the customized inquiry fields in an array.
     * @returns {Array}
     */
    private getInquiryFields() {
        // Get all fields that have customFieldInquiry property set to true
        let resultElements: any = $.grep(this.customElements, (i: any) => {
            return i.customFieldInquiry == true;
        });

        // If there are any result elements, return them.
        return resultElements && resultElements.length > 0 ? resultElements : [];
    }

    //endregion Inquiry

    //region No Access

    /**
     * Checks if the current field was set to be No Access
     * @param elementToCheck
     * @param setCheckBoxesFlag
     * @returns {boolean}
     */
    private fieldNoAccess(elementToCheck: any = null, setCheckBoxesFlag: boolean = null): boolean {
        let fieldNoAccessFlag: boolean = false;

        if (this.validationService.isNullOrEmpty(setCheckBoxesFlag))
            setCheckBoxesFlag = true;

        let resultElement: any;
        // Get the current selected item from Current Field Dropdown
        if (this.validationService.isNullOrEmpty(elementToCheck))
            resultElement = this.getCurrentSelectedElement();
        else if (!this.validationService.isNullOrEmpty(elementToCheck))
            resultElement = elementToCheck;

        if (!this.validationService.isNullOrEmpty(resultElement)) {
            // If the currentElement is a header element, disable inquiry checkbox.
            if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                // If current selected element is a header field, return true to disable first field.
                if (this.formFields[resultElement.elementID].isHeaderField) {
                    if (resultElement.hasOwnProperty("customFieldNoAccess") && !this.validationService.isNullOrEmpty(resultElement.customFieldNoAccess)) {
                        fieldNoAccessFlag = resultElement.customFieldNoAccess == true;
                    }
                    if (setCheckBoxesFlag != false) {
                        this.requiredDisabled = true;
                        this.inquiryDisabled = true;
                        this.noAccessDisabled = false;
                    }
                    return fieldNoAccessFlag;
                }
            }

            // If customFieldNoAccess property is set to true, it means the field was customized and didn't come from field security
            if (resultElement.hasOwnProperty("customFieldNoAccess") && !this.validationService.isNullOrEmpty(resultElement.customFieldNoAccess) && resultElement.customFieldNoAccess == true) {
                fieldNoAccessFlag = true;
                if (setCheckBoxesFlag != false) {
                    this.noAccessDisabled = false;
                    this.requiredDisabled = true;
                    this.inquiryDisabled = true;
                }
                return fieldNoAccessFlag;
            }

            // If runTimeNoAccess property is set to true in the fieldElement, it came from field security message. Hence, no customization should be allowed.
            if (this.clearAllFieldsClicked != true) {
                if (fieldNoAccessFlag == false && resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                    let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                    if (!this.validationService.isNullOrEmpty(formElement)) {
                        // FieldSecurityMessage no access setting
                        if (formElement.hasOwnProperty("runTimeNoAccess") && !this.validationService.isNullOrEmpty(formElement.runTimeNoAccess) && formElement.runTimeNoAccess == true) {
                            fieldNoAccessFlag = true;
                            if (setCheckBoxesFlag != false) {
                                this.noAccessDisabled = true;
                                this.requiredDisabled = true;
                                this.inquiryDisabled = true;
                                this.firstFieldDisabled = true;
                                this.lastFieldDisabled = true;
                            }
                            return fieldNoAccessFlag;
                        }
                    }
                }
            }
        }

        return fieldNoAccessFlag;
    }

    /**
     * Check whether the no access checkbox should be enabled or disabled
     * @param selectedCustomizeUser
     * @returns {boolean}
     */
    private noAccessDisabledCheck(selectedCustomizeUser: string = null): boolean {
        // Check the global override settings for no access field
        if (this.rootScopeService.AllFieldAccessOverrideAllowed != null && this.rootScopeService.AllFieldAccessOverrideAllowed == false) {
            let isCurrentUser: boolean = !this.validationService.isNullOrEmpty(selectedCustomizeUser) ? selectedCustomizeUser.toLocaleLowerCase() == this.currentOperator.toLocaleLowerCase() : true;

            // Check user specific override settings for no access field. If the overrides are allowed for the current user, proceed with checking other conditions
            if (this.rootScopeService.UserFieldAccessOverrideAllowed != null && this.rootScopeService.UserFieldAccessOverrideAllowed == true && isCurrentUser == true) {
                // Get the current selected item from Current Field Dropdown
                let resultElement: any = this.getCurrentSelectedElement();

                if (!this.validationService.isNullOrEmpty(resultElement)) {
                    // If the currentElement is a header element, disable inquiry checkbox.
                    if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                        // If current selected element is a header field, return true to disable first field.
                        if (this.formFields[resultElement.elementID].isHeaderField) {
                            return false;
                        }
                    }

                    // If customFieldNoAccess property is set to true, it means the field was customized and didn't come from field security
                    if (resultElement.hasOwnProperty("customFieldNoAccess") && !this.validationService.isNullOrEmpty(resultElement.customFieldNoAccess) && resultElement.customFieldNoAccess == true) {
                        return false;
                    }

                    // If runTimeNoAccess property is set to true in the fieldElement, it came from field security message. Hence, no customization should be allowed.
                    if (this.clearAllFieldsClicked != true) {
                        if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                            let formElement: any = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                            if (!this.validationService.isNullOrEmpty(formElement)) {
                                // FieldSecurityMessage no access setting
                                if (formElement.hasOwnProperty("runTimeNoAccess") && !this.validationService.isNullOrEmpty(formElement.runTimeNoAccess) && formElement.runTimeNoAccess == true) {
                                    return true;
                                }
                            }
                        }
                    }

                    // Check if this element has been customized to be required or was required by default. If yes, disable no access
                    if (this.requiredChecked == true) {
                        return true;
                    }

                    // Check if this element has been customized to be inquiry only, if it has then disable no access
                    if (resultElement.customFieldInquiry == true) {
                        return true;
                    }
                }

                return false;
            }
            // If the user selected is not the current operator, disable the overrides for all other users.
            else {
                return true;
            }
        }
        else {
            // Get the current selected item from Current Field Dropdown
            let resultElement: any = this.getCurrentSelectedElement();

            if (!this.validationService.isNullOrEmpty(resultElement)) {
                // If the currentElement is a header element, disable inquiry checkbox.
                if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                    // If current selected element is a header field, return true to disable first field.
                    if (this.formFields[resultElement.elementID].isHeaderField) {
                        return false;
                    }
                }

                // If customFieldNoAccess property is set to true, it means the field was customized and didn't come from field security
                if (resultElement.hasOwnProperty("customFieldNoAccess") && !this.validationService.isNullOrEmpty(resultElement.customFieldNoAccess) && resultElement.customFieldNoAccess == true) {
                    return false;
                }

                // If runTimeNoAccess property is set to true in the fieldElement, it came from field security message. Hence, no customization should be allowed.
                if (this.clearAllFieldsClicked != true) {
                    if (resultElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(resultElement.elementID)) {
                        let formElement = this.formFields.hasOwnProperty(resultElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[resultElement.elementID]) ? this.formFields[resultElement.elementID] : null;

                        if (!this.validationService.isNullOrEmpty(formElement)) {
                            // FieldSecurityMessage no access setting
                            if (formElement.hasOwnProperty("runTimeNoAccess") && !this.validationService.isNullOrEmpty(formElement.runTimeNoAccess) && formElement.runTimeNoAccess == true) {
                                return true;
                            }
                        }
                    }
                }

                // Check if this element has been customized to be required or was required by default. If yes, disable no access
                if (this.requiredChecked == true) {
                    return true;
                }

                // Check if this element has been customized to be inquiry only, if it has then disable no access
                if (resultElement.customFieldInquiry == true) {
                    return true;
                }
            }

            return false;
        }
    }

    /**
     * Gets all the customized no access fields in an array.
     * @returns {Array}
     */
    private getNoAccessFields() {
        // Get all fields that have customFieldNoAccess property set to true
        let resultElements: any = $.grep(this.customElements, (n: any) => {
            return n.customFieldNoAccess == true;
        });

        // If there are any result elements, return them.
        return resultElements && resultElements.length > 0 ? resultElements : [];
    }

    //endregion No Access

    //region Customize for users

    /**
     * Gets the list of users to be bound to the Customize for: dropdown list
     * @param userList
     * @returns {Array}
     */
    private getCustomizeForUserList(userList: any = null) {
        let customizeForUserList: any = [];

        // Always add current logged in user to the list
        customizeForUserList.push({
            key: this.currentOperator,
            value: this.currentOperator + " (Me)"
        });

        // If userList is provided, create the key value array from this list. This will be the case when OperatorInfoMessage passes a list of users.
        if (!this.validationService.isNullOrEmpty(userList) && userList.length > 0) {
            // Push a record for All Users since there are records in the userList from OperatorInfoMessage
            customizeForUserList.push({
                key: "*all*",
                value: "All Users"
            });

            // Add remaining users excluding current operator since the current operator has already been added.
            for (let user = 0; user < userList.length; user++) {
                let u: any = userList[user];
                if (u.hasOwnProperty("UserID") && !this.validationService.isNullOrEmpty(u.UserID)
                    && u.hasOwnProperty("UserName") && !this.validationService.isNullOrEmpty(u.UserName)) {
                    if (u.UserID.toLowerCase() != this.currentOperator.toLowerCase()) {
                        customizeForUserList.push({
                            key: u.UserID,
                            value: u.UserID + " (" + u.UserName + ")"
                        });
                    }
                }
            }
        }

        return customizeForUserList;
    }

    //endregion Customize for users

    //region Copy to other users

    /**
     * Gets the list of users to be bound to the Copy to other users dropdown list
     * @param userList
     * @returns {Array}
     */
    private getCopyToOtherUsersList(userList: any) {
        let copyToOtherUsersList: any = [];

        // If userList is provided, create the key value array from this list. This will be the case when OperatorInfoMessage passes a list of users.
        if (!this.validationService.isNullOrEmpty(userList) && userList.length > 0) {
            // Push a record for All Users since there are records in the userList from OperatorInfoMessage
            copyToOtherUsersList.push({
                name: "All users",
                selected: false,
                id: "*all*",
                checkboxTitle: "All users"
            });

            // Add remaining users excluding current operator since the current operator has already been added.
            for (let user = 0; user < userList.length; user++) {
                let u: any = userList[user];
                if (u.hasOwnProperty("UserID") && !this.validationService.isNullOrEmpty(u.UserID)
                    && u.hasOwnProperty("UserName") && !this.validationService.isNullOrEmpty(u.UserName)
                    && u.UserID.toLowerCase() != this.currentOperator.toLowerCase()) {
                    copyToOtherUsersList.push({
                        name: u.UserID + " (" + u.UserName + ")",
                        selected: false,
                        id: u.UserID,
                        customized: false,
                        checkboxTitle: u.UserID + " (" + u.UserName + ")"
                    });
                }
            }
        }

        return copyToOtherUsersList;
    }

    //endregion Copy to other users

    //region Miscellaneous

    /**
     * Resets the customizations to the current user on Save or Cancel
     */
    private resetCustomizationsToCurrentUser() {
        if (this.formService.getLatestForm().hasOwnProperty("customizations") && !this.validationService.isNullOrEmpty(this.formService.getLatestForm().customizations)) {
            let customFormSpecsMsg: any = this.formService.getLatestForm().customizations[this.currentOperator];
            if (customFormSpecsMsg.hasOwnProperty("ElementList") && !this.validationService.isNullOrEmpty(customFormSpecsMsg.ElementList) && customFormSpecsMsg.ElementList.length > 0) {
                let customizedElementList: any = this.formService.getLatestForm().customizations[this.currentOperator].ElementList;
                if (!this.validationService.isNullOrEmpty(customizedElementList) && customizedElementList.length > 0) {
                    // If the selected user had customizations, broadcast the customformspecsmessage again, initialize scope controller and reset the sequence orders
                    let message: any = new Models.ClientInputMessage();
                    message["_i:type"] = "ClientInputMessage";
                    message.Command = Models.CommandConstants.customFormSpecs;
                    message.FieldData = customizedElementList;
                    this.eventsService.broadcast(Models.EventConstants.sendServerMessage, message);
                }
                else {
                    this.parserService.createSequenceOrderForElements(this.formFields, null);
                }
            }
            else {
                this.parserService.createSequenceOrderForElements(this.formFields, null);
            }
        }
        else {
            this.parserService.createSequenceOrderForElements(this.formFields, null);
        }
    }

    /**
     * Removes the specified value from the array
     * @param arr
     * @param property
     * @param value
     */
    private findAndRemove(arr: any, property: string, value: any) {
        arr.forEach((result: any, index: number) => {
            if (result[property] == value) {
                //Remove from array
                arr.splice(index, 1);
            }
        });
    }

    /**
     * Displays warning messages to the user so that the user can confirm their actions before proceeding.
     * @param okCallBack
     */
    private displayClearAllFieldsWarning(okCallBack: any) {
        let popupMessage: any = {};
        let message: any = Models.CustomFieldSequenceConstants.clearAllFieldsPopupMessage + " " + this.selectedCustomizeForUser.key.toLowerCase() + "?";
        popupMessage.title = Models.CustomFieldSequenceConstants.clearAllFieldsPopupTitle;
        popupMessage.text = [message];
        popupMessage.buttons = [
            {
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    okCallBack();
                    this.promptService.popupMessageDismiss(() => {
                        this.eventsService.broadcast(Models.EventConstants.closeAlert)
                    }, false);
                },
                SearchFocus: false
            },
            {
                label: Models.PromptMessageConstants.cancelPromptButton,
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        this.eventsService.broadcast(Models.EventConstants.closeAlert);
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = "1";
        this.promptService.popupMessageShow(popupMessage);
    }

    /**
     * Enables all the checkboxes and unchecks them
     */
    private resetCheckboxes() {
        this.noAccessDisabled = false;
        this.requiredDisabled = false;
        this.inquiryDisabled = false;
    }

    /**
     * Updates the sequenceOrder displayed on UI when some customizations are made.
     */
    private updateCustomSequenceOrder() {
        // Get first field and last field
        let firstElement: any = this.getFirstField();
        let lastElement: any = this.getLastField();

        // If first field is found, start the sequence order from here
        if (!this.validationService.isNullOrEmpty(firstElement)) {
            let seqOrder: number = 1;
            let firstFound: boolean = false;
            let lastFound: boolean = false;

            // Clear customSequenceOrders before proceeding
            for (let field in this.formFields) {
                if (this.formFields[field].hasOwnProperty("customSequenceOrder")) {
                    delete this.formFields[field].customSequenceOrder;
                }
            }

            // Array that will keep track of indexes that have been processed. If an index comes in loop that is already in the array, loop will break out of iterations.
            let processed: any = [];

            for (let c = 0; c < this.customElements.length; c++) {
                // If we loop back to an element that has already been processed, it means that an element's fieldforward was set to something that had a lower elementOrder.
                if (processed.indexOf(c) >= 0) {
                    break;
                }

                let elemField: any = this.customElements[c];
                let field: any = elemField.elementID;

                // If the current element is in a window, check to see if the rowIndex is 1. If not, do not add custom sequence order
                if (this.formFields[field].hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(this.formFields[field].elementWindowController)
                    && this.formFields[field].hasOwnProperty("rowIndex") && !this.validationService.isNullOrEmpty(this.formFields[field].rowIndex) && !isNaN(this.formFields[field].rowIndex) && this.formFields[field].rowIndex != 1) {
                    continue;
                }

                // If the current field does not match firstField, remove it's customSequenceOrder and continue
                if (firstFound == false && lastFound == false && this.formFields[field].elementID.toUpperCase() != firstElement.elementID.toUpperCase()) {
                    delete this.formFields[field].customSequenceOrder;
                    delete elemField.customSequenceOrder;
                }
                // If the current field matches firstField, start adding customSequenceOrders from here
                else if (firstFound == false && lastFound == false && this.formFields[field].elementID.toUpperCase() == firstElement.elementID.toUpperCase()) {
                    // First element is found now
                    firstFound = true;

                    // Add customSequenceOrder
                    this.formFields[field].customSequenceOrder = seqOrder;
                    elemField.customSequenceOrder = seqOrder;

                    // Push this field's index to processed
                    processed.push(c);

                    // If the current field has a field forward that has been customized, skip to that next field
                    if (elemField.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(elemField.customFieldForward)) {
                        let nextFieldElem = $.grep(this.customElements, (e: any) => {
                            return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase();
                        });

                        // If the customFieldForward had an ID that did not match any of the customElements, try adding "_1" at the end.
                        // Why was this done? Customizations in 4.x, when saving custom sequence, do not pass the _1 at the end if the next field is a window controller.
                        // Therefore, we have to manually check for that in this condition.
                        if (this.validationService.isNullOrEmpty(nextFieldElem) || nextFieldElem.length == 0) {
                            nextFieldElem = $.grep(this.customElements, (e: any) => {
                                return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase() + "_1";
                            });
                        }

                        if (nextFieldElem && nextFieldElem.length > 0) {
                            // Set c to the index of next field forward
                            if (nextFieldElem[0].hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(nextFieldElem[0].elementID)) {
                                let elementPos: any = $.map(this.customElements, (obj: any, index: any) => {
                                    if (obj.elementID.toUpperCase() == nextFieldElem[0].elementID.toUpperCase()) {
                                        return index;
                                    }
                                })[0];

                                if (elementPos && !isNaN(elementPos)) {
                                    c = elementPos - 1;
                                }
                            }
                        }
                    }
                    seqOrder++;
                }
                // If the first field has already been found, simply add the new customSequenceOrder for all the elements that have the customSequenceOrder then check for last element
                else if (firstFound == true && lastFound == false && !this.validationService.isNullOrEmpty(lastElement)) {
                    // Check for inquiry or no access elements here
                    let isInquiry: boolean = this.fieldInquiry(elemField, false);
                    let isNoAccess: boolean = this.fieldNoAccess(elemField, false);

                    // If a field is inquiry, check if it is detailable. If the field is detailable, we need to add sequence order to it.
                    if (isInquiry == true && this.formFields[field].hasOwnProperty("elementDetailable") && !this.validationService.isNullOrEmpty(this.formFields[field].elementDetailable) && this.formFields[field].elementDetailable == true) {
                        isInquiry = false;
                    }
                    // If a field is inquiry, check if the elemField's customFieldInquiry property is true, which means it was customized. Therefore, add customSequenceOrder for this.
                    if (isInquiry == true && elemField.customFieldInquiry == true) {
                        isInquiry = false;
                    }
                    // If a field is no access, check if the elemField's customFieldNoAccess property is true, which means it was customized. Therefore, add customSequenceOrder for this.
                    if (isNoAccess = true && elemField.customFieldNoAccess == true) {
                        isNoAccess = false;
                    }

                    // If the element is neither inquiry nor no access, add customSequenceOrder, else continue;
                    if (isInquiry == false && isNoAccess == false) {
                        this.formFields[field].customSequenceOrder = seqOrder;
                        elemField.customSequenceOrder = seqOrder;
                        // Push this field's elementOrder to processed
                        processed.push(c);
                        seqOrder++;
                    }

                    // If this field matches the last element, break after adding the last sequence order.
                    if (!this.validationService.isNullOrEmpty(lastElement) && lastElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(lastElement.elementID)
                        && this.formFields[field].elementID.toUpperCase() == lastElement.elementID.toUpperCase()) {
                        lastFound = true;
                    }
                    // If the current field has a field forward that has been customized, skip to that next field
                    else if (elemField.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(elemField.customFieldForward)) {
                        let nextFieldElem: any = $.grep(this.customElements, (e: any) => {
                            return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase();
                        });

                        // If the customFieldForward had an ID that did not match any of the customElements, try adding "_1" at the end.
                        // Why was this done? Customizations in 4.x, when saving custom sequence, do not pass the _1 at the end if the next field is a window controller.
                        // Therefore, we have to manually check for that in this condition.
                        if (this.validationService.isNullOrEmpty(nextFieldElem) || nextFieldElem.length == 0) {
                            nextFieldElem = $.grep(this.customElements, (e: any) => {
                                return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase() + "_1";
                            });
                        }

                        if (nextFieldElem && nextFieldElem.length > 0) {
                            // Set c to the index of next field forward
                            if (nextFieldElem[0].hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(nextFieldElem[0].elementID)) {
                                let elementPos: any = $.map(this.customElements, (obj: any, index: any) => {
                                    if (obj.elementID.toUpperCase() == nextFieldElem[0].elementID.toUpperCase()) {
                                        return index;
                                    }
                                })[0];

                                if (elementPos && !isNaN(elementPos)) {
                                    c = elementPos - 1;
                                }
                            }
                        }
                    }
                }
                // First element is found but there is no last element. Therefore, the sequenceOrdering will go till the end.
                else if (firstFound == true && lastFound == false && this.validationService.isNullOrEmpty(lastElement)) {
                    // Check for inquiry or no access elements here
                    let isInquiry: boolean = this.fieldInquiry(elemField, false);
                    let isNoAccess: boolean = this.fieldNoAccess(elemField, false);

                    // If a field is inquiry, check if it is detailable. If the field is detailable, we need to add sequence order to it.
                    if (isInquiry == true && this.formFields[field].hasOwnProperty("elementDetailable") && !this.validationService.isNullOrEmpty(this.formFields[field].elementDetailable) && this.formFields[field].elementDetailable == true) {
                        isInquiry = false;
                    }
                    // If a field is inquiry, check if the elemField's customFieldInquiry property is true, which means it was customized. Therefore, add customSequenceOrder for this.
                    if (isInquiry == true && elemField.customFieldInquiry == true) {
                        isInquiry = false;
                    }
                    // If a field is no access, check if the elemField's customFieldNoAccess property is true, which means it was customized. Therefore, add customSequenceOrder for this.
                    if (isNoAccess = true && elemField.customFieldNoAccess == true) {
                        isNoAccess = false;
                    }

                    // If the element is neither inquiry nor no access, add customSequenceOrder, else continue;
                    if (isInquiry == false && isNoAccess == false) {
                        this.formFields[field].customSequenceOrder = seqOrder;
                        elemField.customSequenceOrder = seqOrder;
                        // Push this field's elementOrder to processed
                        processed.push(c);
                        seqOrder++;
                    }

                    // If the current field has a field forward that has been customized, skip to that next field
                    if (elemField.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(elemField.customFieldForward)) {
                        let nextFieldElem: any = $.grep(this.customElements, (e: any) => {
                            return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase();
                        });

                        // If the customFieldForward had an ID that did not match any of the customElements, try adding "_1" at the end.
                        // Why was this done? Customizations in 4.x, when saving custom sequence, do not pass the _1 at the end if the next field is a window controller.
                        // Therefore, we have to manually check for that in this condition.
                        if (this.validationService.isNullOrEmpty(nextFieldElem) || nextFieldElem.length == 0) {
                            nextFieldElem = $.grep(this.customElements, (e: any) => {
                                return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase() + "_1";
                            });
                        }

                        if (nextFieldElem && nextFieldElem.length > 0) {
                            // Set c to the index of next field forward
                            if (nextFieldElem[0].hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(nextFieldElem[0].elementID)) {
                                let elementPos: any = $.map(this.customElements, (obj: any, index: any) => {
                                    if (obj.elementID.toUpperCase() == nextFieldElem[0].elementID.toUpperCase()) {
                                        return index;
                                    }
                                })[0];

                                if (elementPos && !isNaN(elementPos)) {
                                    c = elementPos - 1;
                                }
                            }
                        }
                    }
                }
                // If both first and last elements have been found, delete the remaining customSequenceOrders
                else if (firstFound == true && lastFound == true) {
                    if (this.formFields[field].hasOwnProperty("sequenceOrder")) {
                        delete this.formFields[field].customSequenceOrder;
                        delete elemField.customSequenceOrder;
                    }
                }
            }
        }
        // There is no first field, which means that the first field was unchecked.
        else {
            let seqOrder: number = 1;
            let lastFound: boolean = false;

            // Clear customSequenceOrders before proceeding
            for (let f in this.formFields) {
                if (this.formFields[f].hasOwnProperty("customSequenceOrder")) {
                    delete this.formFields[f].customSequenceOrder;
                }
            }

            // Array that will keep track of elemntOrder that has been processed. If an elementOrder comes in loop that is already in the array, loop will break out of iterations.
            let processed: any = [];

            for (let c = 0; c < this.customElements.length; c++) {
                // If we loop back to an element that has already been processed, it means that an element's fieldforward was set to something that had a lower elementOrder.
                if (processed.indexOf(c) >= 0) {
                    break;
                }

                let elemField: any = this.customElements[c];
                let field: any = elemField.elementID;

                // If the current element is in a window, check to see if the rowIndex is 1. If not, do not add custom sequence order
                if (this.formFields[field].hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(this.formFields[field].elementWindowController)
                    && this.formFields[field].hasOwnProperty("rowIndex") && !this.validationService.isNullOrEmpty(this.formFields[field].rowIndex) && !isNaN(this.formFields[field].rowIndex) && this.formFields[field].rowIndex != 1) {
                    continue;
                }

                let isInquiry: boolean = this.fieldInquiry(elemField, false);
                let isNoAccess: boolean = this.fieldNoAccess(elemField, false);

                // If a field is inquiry, check if it is detailable. If the field is detailable, we need to add sequence order to it.
                if (isInquiry == true && this.formFields[field].hasOwnProperty("elementDetailable") && !this.validationService.isNullOrEmpty(this.formFields[field].elementDetailable) && this.formFields[field].elementDetailable == true) {
                    isInquiry = false;
                }
                // If a field is inquiry, check if the elemField's customFieldInquiry property is true, which means it was customized. Therefore, add customSequenceOrder for this.
                if (isInquiry == true && elemField.customFieldInquiry == true) {
                    isInquiry = false;
                }
                // If a field is no access, check if the elemField's customFieldNoAccess property is true, which means it was customized. Therefore, add customSequenceOrder for this.
                if (isNoAccess = true && elemField.customFieldNoAccess == true) {
                    isNoAccess = false;
                }

                // If last element has not been found yet, current form field has a sequence order and the current form field is not the last element
                if (lastFound == false && !isInquiry && !isNoAccess && !this.formFields[field].isHeaderField
                    && !this.formFields[field].isPhantomField && !this.formFields[field].isFormLabel
                    && (this.validationService.isNullOrEmpty(lastElement) || this.formFields[field].elementID.toUpperCase() != lastElement.elementID.toUpperCase())) {

                    // Add sequence Order
                    this.formFields[field].customSequenceOrder = seqOrder;
                    elemField.customSequenceOrder = seqOrder;

                    // Push this field's elementOrder to processed
                    processed.push(c);

                    // If the current field has a field forward that has been customized, skip to that next field
                    if (elemField.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(elemField.customFieldForward)) {
                        let nextFieldElem: any = $.grep(this.customElements, (e: any) => {
                            return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase();
                        });

                        // If the customFieldForward had an ID that did not match any of the customElements, try adding "_1" at the end.
                        // Why was this done? Customizations in 4.x, when saving custom sequence, do not pass the _1 at the end if the next field is a window controller.
                        // Therefore, we have to manually check for that in this condition.
                        if (this.validationService.isNullOrEmpty(nextFieldElem) || nextFieldElem.length == 0) {
                            nextFieldElem = $.grep(this.customElements, (e: any) => {
                                return e.elementID.toUpperCase() == elemField.customFieldForward.toUpperCase() + "_1";
                            });
                        }

                        if (nextFieldElem && nextFieldElem.length > 0) {
                            // Set c to the index of next field forward
                            if (nextFieldElem[0].hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(nextFieldElem[0].elementID)) {
                                let elementPos: any = $.map(this.customElements, (obj: any, index: any) => {
                                    if (obj.elementID.toUpperCase() == nextFieldElem[0].elementID.toUpperCase()) {
                                        return index;
                                    }
                                })[0];

                                if (elementPos && !isNaN(elementPos)) {
                                    c = elementPos - 1;
                                }
                            }
                        }
                    }

                    seqOrder++;
                }
                // If this field matches the last element, break after adding the last sequence order.
                else if (lastFound == false && !this.validationService.isNullOrEmpty(lastElement) && lastElement.hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(lastElement.elementID)
                    && this.formFields[field].elementID.toUpperCase() == lastElement.elementID.toUpperCase()) {
                    // this.formFields[field].customSequenceOrder = this.formFields[field].sequenceOrder;
                    // elemField.customSequenceOrder = this.formFields[field].sequenceOrder;

                    this.formFields[field].customSequenceOrder = seqOrder;
                    elemField.customSequenceOrder = seqOrder;

                    seqOrder++;

                    lastFound = true;
                }
                // Delete remaining custom sequence orders
                else if (lastFound == true) {
                    if (this.formFields[field].hasOwnProperty("sequenceOrder")) {
                        delete this.formFields[field].customSequenceOrder;
                        delete elemField.customSequenceOrder;
                    }
                }
            }
        }
    }

    /**
     * Creates a JSON object that will store the customizations for the current form.
     * @returns {{}}
     */
    private getCustomizedParameters() {
        let params: any = {};
        // Add User ID
        params.userID = this.currentOperator;
        // Add first field, if any
        params.firstField = this.getFirstField();
        // Add all the fields which have a field forward. Also add the ID of the fields to which the tabbing will forward to.
        params.currentFields = this.getCurrentFields();
        params.nextFields = this.getNextFields();
        // Add last field
        params.lastField = this.getLastField();
        // Add no access fields
        params.noAccessFields = this.getNoAccessFields();
        // Add inquiry fields
        params.inquiryFields = this.getInquiryFields();
        // Add required fields
        params.requiredFields = this.getRequiredFields();
        // Add default required fields that came from form specs
        params.defaultRequiredFields = this.getDefaultRequiredFields();
        return params;
    }

    /**
     * Displays a warning message to the user if they try to save a sequence customization where first field to be after required fields or last field to be before required fields
     * @param okCallBack
     */
    private displayTabbingLoopWarning(okCallBack: any) {
        let popupMessage: any = {};
        let message = Models.CustomFieldSequenceConstants.customTabbingLoopPopupMessage;
        popupMessage.title = "Warning";
        popupMessage.text = [message];
        popupMessage.buttons = [
            {
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    okCallBack();
                    this.promptService.popupMessageDismiss(() => {
                        this.eventsService.broadcast(Models.EventConstants.closeAlert)
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = "1";
        this.promptService.popupMessageShow(popupMessage);
    }

    /**
     * Resets any customizations when any user selection changes in Customize for dropdown list.
     */
    private resetFormFieldCustomizations() {
        for (let field in this.formFields) {
            let targetField = this.formFields[field];

            if (targetField.hasOwnProperty("customFieldRequired"))
                delete targetField.required;
            delete targetField.customFieldRequired;

            if (targetField.hasOwnProperty("customFieldInquiry"))
                delete targetField.inquiry;
            delete targetField.customFieldInquiry;

            if (targetField.hasOwnProperty("customFieldNoAccess"))
                delete targetField.runTimeNoAccess;
            delete targetField.customFieldNoAccess;

            if (targetField.hasOwnProperty("customFirstField"))
                delete targetField.customFirstField;

            if (targetField.hasOwnProperty("customLastField"))
                delete targetField.customLastField;
        }
    }

    /**
     * Display message to the user about Copy Customizations and to click on Save to commit the changes
     */
    private displayCopyToOtherUsersMessage() {
        this.selectedUsersList = this.getSelectedUsersList();
        let count: number = this.selectedUsersList.length;
        if (count > 0) {
            let popupMessage: any = {};
            let message = "Customizations were successfully copied to " + count + " users. You must Save these changes to commit them to Colleague.";
            popupMessage.title = "Copy Customizations";
            popupMessage.text = [message];
            popupMessage.buttons = [
                {
                    label: Models.PromptMessageConstants.okPromptButton,
                    callback: () => {
                        this.promptService.popupMessageDismiss(() => {
                            this.eventsService.broadcast(Models.EventConstants.closeAlert)
                        }, false);
                    },
                    SearchFocus: false
                }
            ];
            popupMessage.defaultCallbackNumber = "1";
            this.promptService.popupMessageShow(popupMessage);
        }
    }

    /**
     * Helper method to get selected users
     * @returns {any}
     */
    private getSelectedUsersList() {
        let selectedUsers: any = [];
        if (!this.validationService.isNullOrEmpty(this.copyToOtherUsersList)) {
            selectedUsers = $.grep(this.copyToOtherUsersList, (c: any) => {
                return c.selected == true;
            });
        }
        return selectedUsers;
    }

    //endregion Miscellaneous

    //endregion Private

    //region Current Field

    /**
     * Triggered when current field dropdown selection is changed. Results in updating the next field dropdown list.
     */
    currentFieldChanged() {
        // New Next Field dropdown list items
        this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);
        if (this.nextFieldDisabled != true) {
            this.nextFieldList = this.getNextFieldList();
            // Next field dropdown selected item should always be the empty item on top
            this.selectedNextField = "";
        }

        // First field
        this.firstFieldChecked = this.checkCustomFirstField();
        this.firstFieldDisabled = this.firstFieldDisabledCheck(this.selectedCustomizeForUser.key);

        // Last field
        this.lastFieldChecked = this.checkCustomLastField();
        this.lastFieldDisabled = this.lastFieldDisabledCheck(this.selectedCustomizeForUser.key);

        // Update checkboxes for Required, Inquiry Only and No Access
        this.resetCheckboxes();
        this.requiredChecked = this.fieldRequired();
        this.inquiryChecked = this.fieldInquiry();
        this.noAccessChecked = this.fieldNoAccess();
        this.requiredDisabled = this.requiredFieldDisabledCheck(this.selectedCustomizeForUser.key);
        this.inquiryDisabled = this.inquiryFieldDisabledCheck(this.selectedCustomizeForUser.key);
        this.noAccessDisabled = this.noAccessDisabledCheck(this.selectedCustomizeForUser.key);

        // Load the forward fields of this current selected field, if any.
        let currentElement: any = this.getCurrentSelectedElement();
        if (currentElement && currentElement.hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(currentElement.customFieldForward)) {
            // Get elementID of field forward and based on this ID, get the field element
            let fieldForwardId: string = currentElement.customFieldForward;
            let fieldForwardElement: any = $.grep(this.customElements, (c: any) => {
                return c.elementID.toUpperCase() == fieldForwardId.toUpperCase();
            });

            // If the customFieldForward had an ID that did not match any of the customElements, try adding "_1" at the end.
            // Why was this done? Customizations in 4.x, when saving custom sequence, do not pass the _1 at the end if the next field is a window controller.
            // Therefore, we have to manually check for that in this condition.
            if (this.validationService.isNullOrEmpty(fieldForwardElement) || fieldForwardElement.length == 0) {
                fieldForwardId = currentElement.customFieldForward + "_1";
                fieldForwardElement = $.grep(this.customElements, (c: any) => {
                    return c.elementID.toUpperCase() == fieldForwardId.toUpperCase();
                });
            }

            // Get the key for this fieldForward from the nextFieldList and select that element.
            if (fieldForwardElement && fieldForwardElement.length > 0) {
                if (fieldForwardElement[0].hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(fieldForwardElement[0].elementOrder)
                    && !isNaN(fieldForwardElement[0].elementOrder)) {
                    for (let n = 0; n < this.nextFieldList.length; n++) {
                        let nextField: any = this.nextFieldList[n];
                        if (nextField.hasOwnProperty("key") && nextField.key == fieldForwardElement[0].elementOrder) {
                            let elementPos: any = $.map(this.nextFieldList, (obj: any, index: any) => {
                                if (obj.key == nextField.key) {
                                    return index;
                                }
                            })[0];
                            if (elementPos && !isNaN(elementPos)) {
                                this.selectedNextField = this.nextFieldList[elementPos];
                            }
                            break;
                        }
                    }
                }
            }
        }

        // Broadcast elementOrderSelectedListener to change the style of selected elements
        this.eventsService.broadcast(Models.CustomFieldSequenceConstants.elementOrderSelectedListener, currentElement.elementOrder);
    }

    //endregion Current Field

    //region Next Field

    /**
     * Triggered when next field dropdown selection is changed. Results in updating the sequence order of elements
     * @param selectedNextField
     */
    nextFieldChanged(selectedNextField: any) {
        // Get current element
        let currentElement: any = this.getCurrentSelectedElement();

        // If selectedNextField has a value, it means that the user selected a next field and not the empty option on top.
        if (!isNaN(selectedNextField)) {
            // Get next element that is selected in the dropdown
            let nextFieldElement: any = $.grep(this.customElements, (c: any) => {
                return c.elementOrder == selectedNextField;
            });

            // Check value in nextFieldElement
            if (nextFieldElement && nextFieldElement.length > 0) {
                // If nextFieldElement has elementID, set it to the customFieldForward of the currentElement
                if (nextFieldElement[0].hasOwnProperty("elementID") && !this.validationService.isNullOrEmpty(nextFieldElement[0].elementID)) {
                    currentElement.customFieldForward = nextFieldElement[0].elementID;
                    this.lastFieldDisabled = true;
                }
            }

            // Check to see if there is a lastElement. If there is, check if the elementOrder of this lastElement is lower than the nextFieldElement.
            // If elementOrder of lastElement is lower than nextFieldElement, clear the last field.
            let lastElement: any = this.getLastField();
            if (!this.validationService.isNullOrEmpty(lastElement)) {
                if (lastElement.elementOrder < nextFieldElement[0].elementOrder) {
                    lastElement.customLastField = false;
                }
            }
        }
        // User selected empty option from the dropdown. If there was a customFieldForward for the current element, reset it.
        else {
            currentElement.customFieldForward = null;
            this.lastFieldDisabled = false;
        }

        // Update Sequence Order
        this.updateCustomSequenceOrder();

        this.setFieldCustomizedFlag(currentElement.elementID);
    }

    //endregion Next Field

    //region First Field

    /**
     * When the first field is toggled, reflect it in the customElements
     * NOTE: Since First Field checkbox will be disabled for all other fields except for the one that is currently the first field, we do not need to clear it for other elements.
     */
    firstFieldToggle() {
        if (this.firstFieldDisabled != true) {
            // Get current element
            let currentElement: any = this.getCurrentSelectedElement();

            // Get list of elements that are required
            if (!this.validationService.isNullOrEmpty(currentElement)) {
                if (this.formFields[currentElement.elementID].isHeaderField)
                    return;

                // If customFirstField
                if (currentElement.hasOwnProperty("customFirstField")) {
                    // Current element has not been customized to be the first field.
                    if (this.validationService.isNullOrEmpty(currentElement.customFirstField)) {
                        currentElement.customFirstField = true;
                        // First field
                        this.firstFieldChecked = this.checkCustomFirstField();
                        this.firstFieldDisabled = this.firstFieldDisabledCheck(this.selectedCustomizeForUser.key);
                    }
                    else if (!this.validationService.isNullOrEmpty(currentElement.customFirstField)) {
                        // Current element has been customized to be the first field.
                        if (currentElement.customFirstField == true) {
                            currentElement.customFirstField = false;
                            // First field
                            this.firstFieldChecked = this.checkCustomFirstField();
                            this.firstFieldDisabled = this.firstFieldDisabledCheck(this.selectedCustomizeForUser.key);
                        }
                        // Current element has not been customized to be the first field.
                        else if (currentElement.customFirstField == false) {
                            currentElement.customFirstField = true;
                            // First field
                            this.firstFieldChecked = this.checkCustomFirstField();
                            this.firstFieldDisabled = this.firstFieldDisabledCheck(this.selectedCustomizeForUser.key);
                        }
                    }
                }
                else {
                    currentElement.customFirstField = true;
                    // First field
                    this.firstFieldChecked = this.checkCustomFirstField();
                    this.firstFieldDisabled = this.firstFieldDisabledCheck(this.selectedCustomizeForUser.key);
                }

                // Update Sequence Order
                this.updateCustomSequenceOrder();

                this.setFieldCustomizedFlag(currentElement.elementID);
            }
        }
    }

    //endregion First Field

    //region Last Field

    /**
     * When the last field is toggled, reflect the changes in customElements
     */
    lastFieldToggle() {
        if (this.lastFieldDisabled == false) {// Get current element
            let currentElement = this.getCurrentSelectedElement();

            if (this.formFields[currentElement.elementID].isHeaderField)
                return;

            // Set all customLastField to false
            for (let element = 0; element < this.customElements.length; element++) {
                if (this.customElements[element].elementID == currentElement.elementID)
                    continue;

                this.customElements[element].customLastField = false;
            }

            if (!this.validationService.isNullOrEmpty(currentElement)) {
                // If customLastField
                if (currentElement.hasOwnProperty("customLastField")) {
                    // Current element has not been customized to be the last field.
                    if (this.validationService.isNullOrEmpty(currentElement.customLastField)) {
                        // Set currentElement as the last field
                        currentElement.customLastField = true;
                        this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                        // Last field
                        this.lastFieldChecked = this.checkCustomLastField();
                        this.lastFieldDisabled = this.lastFieldDisabledCheck(this.selectedCustomizeForUser.key);
                    }
                    else if (!this.validationService.isNullOrEmpty(currentElement.customLastField)) {
                        // Current element has not been customized to be the last field.
                        if (currentElement.customLastField == false) {
                            // Set currentElement as the last field
                            currentElement.customLastField = true;
                            this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                            // Last field
                            this.lastFieldChecked = this.checkCustomLastField();
                            this.lastFieldDisabled = this.lastFieldDisabledCheck(this.selectedCustomizeForUser.key);
                        }
                        // Current element has been customized to be the last field.
                        else if (currentElement.customLastField == true) {
                            currentElement.customLastField = false;
                            this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                            // Last field
                            this.lastFieldChecked = this.checkCustomLastField();
                            this.lastFieldDisabled = this.lastFieldDisabledCheck(this.selectedCustomizeForUser.key);
                        }
                    }
                }
                else {
                    // Set currentElement as the last field
                    currentElement.customLastField = true;
                    this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                    // Last field
                    this.lastFieldChecked = this.checkCustomLastField();
                    this.lastFieldDisabled = this.lastFieldDisabledCheck(this.selectedCustomizeForUser.key);
                }

                // Update Sequence Order
                this.updateCustomSequenceOrder();

                this.setFieldCustomizedFlag(currentElement.elementID);
            }
        }
    }

    //endregion Last Field

    //region Required

    /**
     * When the required field is toggled, reflect the changes in customElements
     */
    requiredFieldToggle() {
        if (this.requiredDisabled != true) {
            // Get current element
            let currentElement: any = this.getCurrentSelectedElement();

            if (!this.validationService.isNullOrEmpty(currentElement)) {
                if (this.formFields[currentElement.elementID].isHeaderField)
                    return;

                // If customRequired
                if (currentElement.hasOwnProperty("customRequired")) {
                    // Current element has not been customized to be required.
                    if (this.validationService.isNullOrEmpty(currentElement.customRequired)) {
                        currentElement.customRequired = true;
                        // Update checkboxes
                        this.requiredChecked = true;
                        this.requiredDisabled = false;
                        this.inquiryChecked = false;
                        this.inquiryDisabled = true;
                        this.noAccessChecked = false;
                        this.noAccessDisabled = true;
                    }
                    else if (!this.validationService.isNullOrEmpty(currentElement.customRequired)) {
                        // Current element has been customized to be required field.
                        if (currentElement.customRequired == true) {
                            currentElement.customRequired = false;
                            // Update checkboxes
                            this.requiredChecked = false;
                            this.requiredDisabled = false;
                            this.inquiryChecked = false;
                            this.inquiryDisabled = false;
                            this.noAccessChecked = false;
                            this.noAccessDisabled = false;
                        }
                        // Current element has not been customized to be required.
                        else if (currentElement.customRequired == false) {
                            currentElement.customRequired = true;
                            // Update checkboxes
                            this.requiredChecked = true;
                            this.requiredDisabled = false;
                            this.inquiryChecked = false;
                            this.inquiryDisabled = true;
                            this.noAccessChecked = false;
                            this.noAccessDisabled = true;
                        }
                    }
                }
                // Current element has not been customized to be required.
                else {
                    currentElement.customRequired = true;
                    // Update checkboxes
                    this.requiredChecked = true;
                    this.requiredDisabled = false;
                    this.inquiryChecked = false;
                    this.inquiryDisabled = true;
                    this.noAccessChecked = false;
                    this.noAccessDisabled = true;
                }

                this.setFieldCustomizedFlag(currentElement.elementID);
            }
        }
    }

    //endregion Required

    //region Inquiry

    /**
     * When the inquiry only field is toggled, reflect the changes in customElements
     */
    inquiryFieldToggle() {
        if (this.inquiryDisabled != true) {
            // Get current element
            let currentElement: any = this.getCurrentSelectedElement();

            if (!this.validationService.isNullOrEmpty(currentElement)) {
                if (this.formFields[currentElement.elementID].isHeaderField)
                    return;

                // If customFieldInquiry
                if (currentElement.hasOwnProperty("customFieldInquiry")) {
                    // Current element has not been customized to be inquiry only.
                    if (this.validationService.isNullOrEmpty(currentElement.customFieldInquiry)) {
                        currentElement.customFieldInquiry = true;
                        this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                        // Update checkboxes
                        this.requiredChecked = false;
                        this.requiredDisabled = true;
                        this.inquiryChecked = true;
                        this.inquiryDisabled = false;
                        this.noAccessChecked = false;
                        this.noAccessDisabled = true;
                    }
                    else if (!this.validationService.isNullOrEmpty(currentElement.customFieldInquiry)) {
                        // Current element has been customized to be inquiry only.
                        if (currentElement.customFieldInquiry == true) {
                            currentElement.customFieldInquiry = false;
                            this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                            // Update checkboxes
                            this.requiredChecked = false;
                            this.requiredDisabled = false;
                            this.inquiryChecked = false;
                            this.inquiryDisabled = false;
                            this.noAccessChecked = false;
                            this.noAccessDisabled = false;
                        }
                        // Current element has not been customized to be inquiry.
                        else if (currentElement.customFieldInquiry == false) {
                            currentElement.customFieldInquiry = true;
                            this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                            // Update checkboxes
                            this.requiredChecked = false;
                            this.requiredDisabled = true;
                            this.inquiryChecked = true;
                            this.inquiryDisabled = false;
                            this.noAccessChecked = false;
                            this.noAccessDisabled = true;
                        }
                    }
                }
                // Current element has not been customized to be inquiry.
                else {
                    currentElement.customFieldInquiry = true;
                    this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                    // Update checkboxes
                    this.requiredChecked = false;
                    this.requiredDisabled = true;
                    this.inquiryChecked = true;
                    this.inquiryDisabled = false;
                    this.noAccessChecked = false;
                    this.noAccessDisabled = true;
                }

                this.setFieldCustomizedFlag(currentElement.elementID);
            }
        }
    }

    //endregion Inquiry

    //region No Access

    /**
     * When the no access field is toggled, reflect the changes in customElements
     */
    noAccessFieldToggle() {
        if (this.noAccessDisabled != true) {
            // Get current element
            let currentElement: any = this.getCurrentSelectedElement();

            // Get the formElement to check if the element was inquiry only by default
            let defaultInquiryFlag: boolean = false;
            let formElement: any = this.formFields.hasOwnProperty(currentElement.elementID) && !this.validationService.isNullOrEmpty(this.formFields[currentElement.elementID]) ? this.formFields[currentElement.elementID] : null;
            if (!this.validationService.isNullOrEmpty(formElement) && formElement.hasOwnProperty("elementType")
                && !this.validationService.isNullOrEmpty(formElement.elementType) && formElement.elementType.toLowerCase() == "inquiry") {
                defaultInquiryFlag = true;
            }

            if (!this.validationService.isNullOrEmpty(currentElement)) {
                // If customFieldNoAccess
                if (currentElement.hasOwnProperty("customFieldNoAccess")) {
                    // Current element has not been customized to be no access.
                    if (this.validationService.isNullOrEmpty(currentElement.customFieldNoAccess)) {
                        currentElement.customFieldNoAccess = true;
                        this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                        // Update checkboxes
                        this.requiredChecked = false;
                        this.requiredDisabled = true;
                        // If field is inquiry by default form specs, do not uncheck it.
                        this.inquiryChecked = defaultInquiryFlag == true;
                        this.inquiryDisabled = true;
                        this.noAccessChecked = true;
                        this.noAccessDisabled = false;
                    }
                    else if (!this.validationService.isNullOrEmpty(currentElement.customFieldNoAccess)) {
                        // Current element has been customized to be no access.
                        if (currentElement.customFieldNoAccess == true) {
                            currentElement.customFieldNoAccess = false;
                            this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                            // If current element is a header field and contains an "_", there could be multiple such fields in scope's customElements. Therefore, we need to reset the flag for all such fields
                            if (this.formFields[currentElement.elementID].isHeaderField
                                && currentElement.elementID.indexOf('_') > 0) {
                                let otherResults = $.grep(this.customElements, (cust: any) => {
                                    return cust.elementID.indexOf('_') > 0 && cust.elementID.split('_')[0].toLocaleUpperCase() == currentElement.elementID.split('_')[0].toLocaleUpperCase();
                                });

                                if (otherResults && otherResults.length > 0) {
                                    for (let o = 0; o < otherResults.length; o++) {
                                        otherResults[o].customFieldNoAccess = false;
                                    }
                                }
                            }

                            // Update checkboxes
                            if (defaultInquiryFlag == true) {
                                this.requiredChecked = false;
                                this.requiredDisabled = true;
                                this.inquiryChecked = true;
                                this.inquiryDisabled = true;
                            }
                            else {
                                this.requiredChecked = false;
                                this.inquiryChecked = false;
                                // If the current element is a header field, Required and Inquiry Only checkboxes should be disabled
                                if (this.formFields[currentElement.elementID].isHeaderField) {
                                    this.requiredDisabled = true;
                                    this.inquiryDisabled = true;
                                }
                                else {
                                    this.requiredDisabled = false;
                                    this.inquiryDisabled = false;
                                }
                            }
                            this.noAccessChecked = false;
                            this.noAccessDisabled = false;
                        }
                        // Current element has not been customized to be no access.
                        else if (currentElement.customFieldNoAccess == false) {
                            currentElement.customFieldNoAccess = true;
                            this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                            // Update checkboxes
                            this.requiredChecked = false;
                            this.requiredDisabled = true;
                            // If field is inquiry by default form specs, do not uncheck it.
                            this.inquiryChecked = defaultInquiryFlag == true;
                            this.inquiryDisabled = true;
                            this.noAccessChecked = true;
                            this.noAccessDisabled = false;
                        }
                    }
                }
                // Current element has not been customized to be no access.
                else {
                    currentElement.customFieldNoAccess = true;
                    this.nextFieldDisabled = this.nextFieldDisabledCheck(this.selectedCustomizeForUser.key);

                    // Update checkboxes
                    this.requiredChecked = false;
                    this.requiredDisabled = true;
                    // If field is inquiry by default form specs, do not uncheck it.
                    this.inquiryChecked = defaultInquiryFlag == true;
                    this.inquiryDisabled = true;
                    this.noAccessChecked = true;
                    this.noAccessDisabled = false;
                }

                this.setFieldCustomizedFlag(currentElement.elementID);
            }
        }
    }

    //endregion No Access

    //region Clear fields

    /**
     * Clears all the customizations the user has made for the currently selected field.
     */
    clearThisFieldClick() {
        // From scope formElements, get the element that matches the current selected field element and clear it's properties
        for (let element = 0; element < this.customElements.length; element++) {
            if (this.customElements[element].hasOwnProperty("elementOrder") && this.customElements[element].elementOrder == this.selectedCurrentField.key) {
                if (this.customElements[element].hasOwnProperty("customRequired"))
                    this.customElements[element].customRequired = false;
                if (this.customElements[element].hasOwnProperty("customFieldInquiry"))
                    this.customElements[element].customFieldInquiry = false;
                if (this.customElements[element].hasOwnProperty("customFieldNoAccess"))
                    this.customElements[element].customFieldNoAccess = false;
                if (this.customElements[element].hasOwnProperty("customFirstField"))
                    this.customElements[element].customFirstField = false;
                if (this.customElements[element].hasOwnProperty("customLastField"))
                    this.customElements[element].customLastField = false;
                if (this.customElements[element].hasOwnProperty("customFieldForward"))
                    this.customElements[element].customFieldForward = null;

                this.setFieldCustomizedFlag(this.customElements[element].elementID);
                break;
            }
        }

        // Update the sequence Order
        this.updateCustomSequenceOrder();
        this.currentFieldChanged();
    }

    /**
     * Clears all the customizations the user has made in the current session.
     */
    clearAllFieldsClick() {
        let okCallBack = () => {
            this.clearAllFieldsClicked = true;
            for (let element = 0; element < this.customElements.length; element++) {
                if (this.customElements[element].hasOwnProperty("customRequired"))
                    this.customElements[element].customRequired = false;
                if (this.customElements[element].hasOwnProperty("customFieldInquiry"))
                    this.customElements[element].customFieldInquiry = false;
                if (this.customElements[element].hasOwnProperty("customFieldNoAccess"))
                    this.customElements[element].customFieldNoAccess = false;
                if (this.customElements[element].hasOwnProperty("customFirstField"))
                    this.customElements[element].customFirstField = false;
                if (this.customElements[element].hasOwnProperty("customLastField"))
                    this.customElements[element].customLastField = false;
                if (this.customElements[element].hasOwnProperty("customFieldForward"))
                    this.customElements[element].customFieldForward = null;
            }

            // Update the sequence Order
            this.updateCustomSequenceOrder();
            this.setFieldCustomizedFlag();
            // Reset the current element's checkboxes and other options
            this.currentFieldChanged();
        };

        let message: string = Models.CustomFieldSequenceConstants.clearAllFieldsPopupMessage + " " + this.sessionService.getUserId().toLowerCase() + "?";
        this.displayClearAllFieldsWarning(okCallBack);
    }

    //endregion Clear fields

    //region Customize for users

    /**
     * When the user selects a different user from Customize for dropdown, this method is called.
     * @param selectedUser
     */
    customizeForChanged(selectedUser: any) {
        this.selectedCustomizeForUser = selectedUser;
        // Get the customized elements for this user from the form's customizations property
        let customFormSpecsMsg: any = this.formService.getLatestForm().customizations[selectedUser.key];
        if (!this.validationService.isNullOrEmpty(customFormSpecsMsg) && customFormSpecsMsg.hasOwnProperty("ElementList")
            && !this.validationService.isNullOrEmpty(customFormSpecsMsg.ElementList) && customFormSpecsMsg.ElementList.length > 0) {
            let customizedElementList: any = customFormSpecsMsg.ElementList;
            if (!this.validationService.isNullOrEmpty(customizedElementList) && customizedElementList.length > 0) {

                // If the selected user had customizations, broadcast the customformspecsmessage again, initialize scope controller and reset the sequence orders
                customFormSpecsMsg.UpdateTargetFields = selectedUser.key.toLocaleUpperCase() != this.currentOperator.toLocaleUpperCase();
                this.resetFormFieldCustomizations();
                this.eventsService.broadcast(Models.CommandConstants.customFormSpecs, customFormSpecsMsg);

                this.init();
                this.selectedCurrentField = this.currentFieldList[0];
                this.currentFieldChanged();
            }
            else {
                // If there were no customizations, simply re-create the sequence order and reset the sequence orders on customize properties window
                this.resetFormFieldCustomizations();
                this.parserService.createSequenceOrderForElements(this.formFields, null);
                this.init();
                this.selectedCurrentField = this.currentFieldList[0];
                this.currentFieldChanged();
            }
        }
        else {
            this.resetFormFieldCustomizations();
            // If there were no customizations, simply re-create the sequence order and reset the sequence orders on customize properties window
            this.parserService.createSequenceOrderForElements(this.formFields, null);
            this.init();
            this.selectedCurrentField = this.currentFieldList[0];
            this.currentFieldChanged();
        }
    }

    //endregion Customize for users

    //region Copy to other users

    /**
     * Toggles the visibility of Copy to other users dropdown list
     */
    toggleCopyToOtherUsersDropdown() {
        if (this.rootScopeService.callCustomTabRetrieval == true) {
            this.displayCopyToOtherUsersDropdown = !this.displayCopyToOtherUsersDropdown;

            // If copy dropdown is displayed, check to see if there were any users selected and check those items
            if (this.displayCopyToOtherUsersDropdown == true) {
                setTimeout(() => {
                    this.focusService.focusOn("btnCloseCopyToOtherUsers", true);
                }, 100);
                let selectedUsers: any = this.getSelectedUsersList();
                if (selectedUsers.count > 0) {
                    for (let user = 0; user < selectedUsers.length; user++) {
                        let copyToOtherUserElem: any = $.grep(this.copyToOtherUsersList, (c: any) => {
                            return c.id.toLocaleUpperCase() == user["id"].toLocaleUpperCase();
                        });
                        if (copyToOtherUserElem && copyToOtherUserElem.length > 0) {
                            copyToOtherUserElem[0].selected = true;
                        }
                    }
                }
            }
        }
    }

    /**
     * Toggles the checkbox from copy to other users checkbox list
     * @param userId
     */
    toggleUserSelection(userId: string) {
        let list: any = this.getSelectedUsersList();
        let elem: any = $.grep(this.copyToOtherUsersList, (c: any) => {
            return c.id.toLocaleUpperCase() == userId.toLocaleUpperCase();
        });

        if (elem && elem.length > 0) {
            if (elem[0].selected == false) {
                elem[0].selected = true;
            }
            else if (elem[0].selected == true) {
                elem[0].selected = false;
            }
        }
    }

    /**
     * Copy to other users click
     */
    copyToOtherUsersClick() {
        if (this.getSelectedUsersList().length > 0) {
            this.displayCopyToOtherUsersMessage();
            this.displayCopyToOtherUsersDropdown = false;
        }
    }

    /**
     * Cancel copying to other users operation and clear the checkboxes
     */
    cancelCopyToOtherUsersClick() {
        for (let item = 0; item < this.copyToOtherUsersList.length; item++) {
            if (this.copyToOtherUsersList[item].selected == true)
                this.copyToOtherUsersList[item].selected = false;
        }
        this.displayCopyToOtherUsersDropdown = false;

        setTimeout(() => {
            this.focusService.focusOn("btnCopyToOtherUsers", true);
        }, 100);
    }

    /**
     * Tab on Cancel button moves focus to Close button
     * @param event
     */
    cancelCopyToOtherUsersKeydown(event: any) {
        let tab: any = event.keyCode == Models.KeyCodes.tab;
        let shift: any = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("btnCloseCopyToOtherUsers", true);
        }
    }

    /**
     * Shift tab on Close button in header should focus on Cancel in footer
     * @param event
     */
    btnCloseCopyToOtherUsersKeydown(event: any) {
        let tab: any = event.keyCode == Models.KeyCodes.tab;
        let shift: any = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("cancel", true);
        }
    }

    /**
     * Escape key on Copy to other users dialog should close the dialog
     * @param event
     */
    divCopyToOtherUsersKeydown(event: any) {
        let esc: any = event.keyCode == Models.KeyCodes.escape;

        if (esc) {
            event.preventDefault();
            event.stopPropagation();
            this.cancelCopyToOtherUsersClick();
        }
    }

    //endregion Copy to other users

    //region Numbered Boxes Legend

    /**
     * Toggles the visibility of numbered boxes legend modal
     */
    toggleNumberedBoxesLegendModal() {
        this.displayNumberedBoxesLegendModal = !this.displayNumberedBoxesLegendModal;
        setTimeout(() => {
            this.focusService.focusOn("btnCloseLegendDialog", true);
        }, 100)
    }

    /**
     * Escape key on legend box should close the legend dialog
     * @param event
     */
    divBoxesLegendKeydown(event: any) {
        let esc: any = event.keyCode == Models.KeyCodes.escape;

        if (esc) {
            event.preventDefault();
            event.stopPropagation();
            this.closeNumberedBoxesLegendModal();
        }
    }

    /**
     * Close numbered boxes legend modal
     */
    closeNumberedBoxesLegendModal() {
        this.displayNumberedBoxesLegendModal = false;
        setTimeout(() => {
            this.focusService.focusOn("numberedBoxesLegendLinks", true);
        }, 100);
    }

    /**
     * Prevent tabbing out of the Legend boxes
     * @param event
     */
    btnCloseLegendDialogKeydown(event: any) {
        let tab: any = event.keyCode == Models.KeyCodes.tab;

        if (tab) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    //endregion Numbered Boxes Legend

    //region Save

    /**
     * Save the field sequence customizations
     */
    saveCustomization() {
        let args: any = this.getCustomizedParameters();
        let allFieldData: string = "";
        let fieldData: string = "";
        let operatorId: string = this.selectedCustomizeForUser.key;

        // If CustomTabRetrieval server command was sent, the current user can customize for other users.
        // Check for selected users for copying the customizations to.
        if (this.rootScopeService.callCustomTabRetrieval == true) {
            let selectedUsers: any = this.getSelectedUsersList();
            if (selectedUsers.length > 0) {
                // Generate the string for ALL Users customization
                let checkForAll: any = $.grep(selectedUsers, (s: any) => {
                    return s.id.toLocaleUpperCase() == "*ALL*";
                });

                if (checkForAll && checkForAll.length > 0) {
                    allFieldData += "ALL||";
                }

                for (let user = 0; user < selectedUsers.length; user++) {
                    // Add each user that has been selected in the copy list
                    if (selectedUsers[user].id.toLocaleUpperCase() != "*ALL*") {
                        fieldData += selectedUsers[user].id;
                        // If more than one users have been selected, add the ~ delimiter
                        if (user != selectedUsers.length - 1)
                            fieldData += "~";
                    }
                }
            }

            if (operatorId.toLocaleLowerCase() != "*all*") {
                // Add the currently selected user in the customize for dropdown
                fieldData += fieldData == "" ? operatorId : "~" + operatorId;
                fieldData = "OTHER|" + fieldData;
                fieldData += "|";
            }
            else if (operatorId.toLocaleLowerCase() == "*all*" && allFieldData == "") {
                allFieldData += "ALL||";
            }
            else if (fieldData != "") {
                fieldData = "OTHER|" + fieldData;
                fieldData += "|";
            }
        }
        else {
            // If user can't override all tabbing, simply add the current user's information
            fieldData += "OTHER|" + operatorId + "|";
        }

        if (!this.validationService.isNullOrEmpty(args)) {
            // Tracks if a first field is after any of the required fields
            let firstAfterRequired: boolean = false;
            // Tracks if a last field is before any of the required fields
            let lastBeforeRequired: boolean = false;

            // Add first field
            if (args.hasOwnProperty("firstField") && !this.validationService.isNullOrEmpty(args.firstField) && args.firstField.hasOwnProperty("elementOrder")
                && !this.validationService.isNullOrEmpty(args.firstField.elementOrder) && !isNaN(args.firstField.elementOrder)) {
                if (fieldData != "") {
                    fieldData += args.firstField.elementOrder + "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += args.firstField.elementOrder + "|";
                }
                // Check  if the first field is after any of the default required fields
                if (args.hasOwnProperty("defaultRequiredFields") && !this.validationService.isNullOrEmpty(args.defaultRequiredFields) && args.defaultRequiredFields.length > 0) {
                    // Check if any of the default required field's elementOrder is less than first field's element order.
                    let fieldAfter: any = $.grep(args.defaultRequiredFields, (d: any) => {
                        return d.elementOrder < args.firstField.elementOrder;
                    });

                    // If there is any matching record, set firstAfterRequired to true
                    if (fieldAfter && fieldAfter.length > 0) {
                        firstAfterRequired = true;
                    }
                }
            }
            else {
                if (fieldData != "") {
                    fieldData += "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += "|";
                }
            }

            // Add current fields
            if (args.hasOwnProperty("currentFields") && !this.validationService.isNullOrEmpty(args.currentFields) && args.currentFields.length > 0) {
                for (let c = 0; c < args.currentFields.length; c++) {
                    let cObj: any = args.currentFields[c];
                    // Check if the current field in iteration has a valid elementOrder
                    if (!this.validationService.isNullOrEmpty(cObj) && cObj.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(cObj.elementOrder) && !isNaN(cObj.elementOrder)) {
                        if (fieldData != "") {
                            fieldData += cObj.elementOrder;
                        }
                        // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                        if (allFieldData != "") {
                            allFieldData += cObj.elementOrder;
                        }
                    }
                    // If there are more than one fields, and the current field in the iteration is not the last field, add the delimiter "~" otherwise add the separator "|"
                    if (fieldData != "") {
                        fieldData += args.currentFields.length > 1 && c != (args.currentFields.length - 1) ? "~" : "|";
                    }
                    // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                    if (allFieldData != "") {
                        allFieldData += args.currentFields.length > 1 && c != (args.currentFields.length - 1) ? "~" : "|";
                    }
                }
            }
            else {
                if (fieldData != "") {
                    fieldData += "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += "|";
                }
            }

            // Add next fields
            if (args.hasOwnProperty("nextFields") && !this.validationService.isNullOrEmpty(args.nextFields) && args.nextFields.length > 0) {
                for (let cn = 0; cn < args.nextFields.length; cn++) {
                    let cnObj: any = args.nextFields[cn];
                    // Check if the current field in iteration has a valid elementOrder
                    if (!this.validationService.isNullOrEmpty(cnObj) && cnObj.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(cnObj.elementOrder) && !isNaN(cnObj.elementOrder)) {
                        if (fieldData != "") {
                            fieldData += cnObj.elementOrder;
                        }
                        // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                        if (allFieldData != "") {
                            allFieldData += cnObj.elementOrder;
                        }
                    }
                    // If there are more than one fields, and the current field in the iteration is not the last field, add the delimiter "~" otherwise add the separator "|"
                    if (fieldData != "") {
                        fieldData += args.nextFields.length > 1 && cn != (args.nextFields.length - 1) ? "~" : "|";
                    }
                    // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                    if (allFieldData != "") {
                        allFieldData += args.nextFields.length > 1 && cn != (args.nextFields.length - 1) ? "~" : "|";
                    }
                }
            }
            else {
                if (fieldData != "") {
                    fieldData += "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += "|";
                }
            }

            // Add last field
            if (args.hasOwnProperty("lastField") && !this.validationService.isNullOrEmpty(args.lastField) && args.lastField.hasOwnProperty("elementOrder")
                && !this.validationService.isNullOrEmpty(args.lastField.elementOrder) && !isNaN(args.lastField.elementOrder)) {
                if (fieldData != "") {
                    fieldData += args.lastField.elementOrder + "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += args.lastField.elementOrder + "|";
                }
                // Check  if the last field is before any of the default required fields
                if (args.hasOwnProperty("defaultRequiredFields") && !this.validationService.isNullOrEmpty(args.defaultRequiredFields) && args.defaultRequiredFields.length > 0) {
                    // Check if any of the default required field's elementOrder is greater than last field's element order.
                    let fieldBefore = $.grep(args.defaultRequiredFields, (d: any) => {
                        return d.elementOrder > args.lastField.elementOrder;
                    });

                    // If there is any matching record, set lastBeforeRequired to true
                    if (fieldBefore && fieldBefore.length > 0) {
                        lastBeforeRequired = true;
                    }
                }
            }
            else {
                if (fieldData != "") {
                    fieldData += "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += "|";
                }
            }

            // Add no access fields
            if (args.hasOwnProperty("noAccessFields") && !this.validationService.isNullOrEmpty(args.noAccessFields) && args.noAccessFields.length > 0) {
                for (let n = 0; n < args.noAccessFields.length; n++) {
                    let nObj: any = args.noAccessFields[n];
                    // Check if the current field in iteration has a valid elementOrder
                    if (!this.validationService.isNullOrEmpty(nObj) && nObj.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(nObj.elementOrder) && !isNaN(nObj.elementOrder)) {
                        if (fieldData != "") {
                            fieldData += nObj.elementOrder;
                        }
                        // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                        if (allFieldData != "") {
                            allFieldData += nObj.elementOrder;
                        }
                    }
                    // If there are more than one no access fields, and the current field in the iteration is not the last no access field, add the delimiter "~" otherwise add the separator "|"
                    if (fieldData != "") {
                        fieldData += args.noAccessFields.length > 1 && n != (args.noAccessFields.length - 1) ? "~" : "|";
                    }
                    // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                    if (allFieldData != "") {
                        allFieldData += args.noAccessFields.length > 1 && n != (args.noAccessFields.length - 1) ? "~" : "|";
                    }
                }
            }
            else {
                if (fieldData != "") {
                    fieldData += "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += "|";
                }
            }

            // Add inquiry only fields
            if (args.hasOwnProperty("inquiryFields") && !this.validationService.isNullOrEmpty(args.inquiryFields) && args.inquiryFields.length > 0) {
                for (let i = 0; i < args.inquiryFields.length; i++) {
                    let iObj = args.inquiryFields[i];
                    // Check if the current field in iteration has a valid elementOrder
                    if (!this.validationService.isNullOrEmpty(iObj) && iObj.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(iObj.elementOrder) && !isNaN(iObj.elementOrder)) {
                        if (fieldData != "") {
                            fieldData += iObj.elementOrder;
                        }
                        // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                        if (allFieldData != "") {
                            allFieldData += iObj.elementOrder;
                        }
                    }
                    // If there are more than one inquiry fields, and the current field in the iteration is not the last inquiry field, add the delimiter "~" otherwise add the separator "|"
                    if (fieldData != "") {
                        fieldData += args.inquiryFields.length > 1 && i != (args.inquiryFields.length - 1) ? "~" : "|";
                    }
                    // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                    if (allFieldData != "") {
                        allFieldData += args.inquiryFields.length > 1 && i != (args.inquiryFields.length - 1) ? "~" : "|";
                    }
                }
            }
            else {
                if (fieldData != "") {
                    fieldData += "|";
                }
                // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                if (allFieldData != "") {
                    allFieldData += "|";
                }
            }

            // Add required fields
            if (args.hasOwnProperty("requiredFields") && !this.validationService.isNullOrEmpty(args.requiredFields) && args.requiredFields.length > 0) {
                for (let r = 0; r < args.requiredFields.length; r++) {
                    let rObj: any = args.requiredFields[r];
                    // Check if the current field in iteration has a valid elementOrder
                    if (!this.validationService.isNullOrEmpty(rObj) && rObj.hasOwnProperty("elementOrder") && !this.validationService.isNullOrEmpty(rObj.elementOrder) && !isNaN(rObj.elementOrder)) {
                        if (fieldData != "") {
                            fieldData += rObj.elementOrder;
                            // If there are more than one required fields, and the current field in the iteration is not the last required field, add the delimiter "~" otherwise add the separator "|"
                            fieldData += args.requiredFields.length > 1 && r != (args.requiredFields.length - 1) ? "~" : "";
                        }
                        // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                        if (allFieldData != "") {
                            allFieldData += rObj.elementOrder;
                            // If all field data was not empty, it means that the user has chosen to copy the customization to all users
                            allFieldData += args.requiredFields.length > 1 && r != (args.requiredFields.length - 1) ? "~" : "";
                        }

                        // If the current iterated element's elementOrder is less than the first field or greater than last field, set the appropriate flags
                        if (args.hasOwnProperty("firstField") && !this.validationService.isNullOrEmpty(args.firstField) && args.firstField.hasOwnProperty("elementOrder")
                            && !this.validationService.isNullOrEmpty(args.firstField.elementOrder) && !isNaN(args.firstField.elementOrder) && args.firstField.elementOrder > rObj.elementOrder) {
                            firstAfterRequired = true;
                        }
                        if (args.hasOwnProperty("lastField") && !this.validationService.isNullOrEmpty(args.lastField) && args.lastField.hasOwnProperty("elementOrder")
                            && !this.validationService.isNullOrEmpty(args.lastField.elementOrder) && !isNaN(args.lastField.elementOrder) && args.lastField.elementOrder < rObj.elementOrder) {
                            lastBeforeRequired = true;
                        }
                    }
                }
            }

            // If there is a first field before required or last field after required fields, display a warning to the user.
            if (firstAfterRequired || lastBeforeRequired) {
                let okCallBack = () => {
                    // Close customization window along with hiding the element order and sequence order spans
                    this.eventsService.broadcast(Models.EventConstants.cancelCustomizeFieldSequence);

                    // Send server message
                    let message: any = new Models.ClientInputMessage();
                    message["_i:type"] = "ClientInputMessage";
                    message.Command = Models.CommandConstants.customTabUpdate;
                    message.FieldData = allFieldData != "" ? allFieldData + (fieldData != "" ? "^" + fieldData : "") : (fieldData != "" ? fieldData : "");
                    this.eventsService.broadcast(Models.EventConstants.sendServerMessage, message);
                };

                this.displayTabbingLoopWarning(okCallBack);

                // If the customizations were saved for a user other than the current logged in user, reset the customizations for the current logged in user
                // in case there were any.
                if (operatorId.toLocaleUpperCase() != this.currentOperator.toLocaleUpperCase())
                    this.resetCustomizationsToCurrentUser();
            }
            else {
                // Close customization window along with hiding the element order and sequence order spans
                this.eventsService.broadcast(Models.EventConstants.cancelCustomizeFieldSequence);

                // Send server message
                let message: any = new Models.ClientInputMessage();
                message["_i:type"] = "ClientInputMessage";
                message.Command = Models.CommandConstants.customTabUpdate;
                message.FieldData = allFieldData != "" ? allFieldData + (fieldData != "" ? "^" + fieldData : "") : (fieldData != "" ? fieldData : "");
                this.eventsService.broadcast(Models.EventConstants.sendServerMessage, message);

                // If the customizations were saved for a user other than the current logged in user, reset the customizations for the current logged in user
                // in case there were any.
                if (operatorId.toLocaleUpperCase() != this.currentOperator.toLocaleUpperCase())
                    this.resetCustomizationsToCurrentUser();
            }
        }
    }

    //endregion Save

    //region Help

    /**
     * Opens help for custom field sequence
     */
    openCustomFieldSequenceHelp() {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses["CustomTabbing"]);
    }

    customFieldSequenceContainerKeydown(event: any){
        let keyCtrl = event.ctrlKey;
        let keyAlt = event.altKey;
        let keyCode = event.keyCode;
        let keyCharCode = event.charCode;
        let keyChar = String.fromCharCode(keyCode || keyCharCode);

        if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
            event.stopPropagation();
            event.preventDefault();
            this.openCustomFieldSequenceHelp();
        }
        else {
            return;
        }
    }

    /**
     * Shift+Tab on help should focus on Cancel button
     * @param event
     */
    btnHelpKeydown(event: any) {
        let tab: any = event.keyCode == Models.KeyCodes.tab;
        let shift: any = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("btnCancel", true);
        }
    }

    //endregion Help

    //region Cancel

    /**
     * Cancels the Customizations of field sequence, broadcasts an event for the interface controller to hide the CustomizeFieldSequence overlay along with element order and sequence order.
     */
    cancelCustomization() {
        this.resetCustomizationsToCurrentUser();
        this.eventsService.broadcast(Models.EventConstants.cancelCustomizeFieldSequence);
    }

    /**
     * Tab on Cancel should focus on help for Custom Field Sequence panel
     * @param event
     */
    btnCancelKeydown(event: any) {
        let tab: any = event.keyCode == Models.KeyCodes.tab;
        let shift: any = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("btnHelp", true);
        }
    }

    /**
     * Tab on Close button should focus on Current field dropdown
     * @param event
     */
    customFieldSequenceCloseKeydown(event: any) {
        let tab: any = event.keyCode == Models.KeyCodes.tab;
        let shift: any = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("ddlCurrentField", true);
        }
    }

    //endregion Cancel

    //region Listeners

    /**
     * Listener to change the current field dropdown selected item when elementOrder on the field is clicked.
     * @param customFieldSequenceComponent
     * @param elementToSelect
     */
    changeCurrentFieldListener(customFieldSequenceComponent: any, elementToSelect: any) {
        let self: CustomFieldSequenceComponent = <CustomFieldSequenceComponent>customFieldSequenceComponent;
        if (!self.validationService.isNullOrEmpty(elementToSelect) && !isNaN(elementToSelect)) {
            // Check if currentFieldList contains the element
            let elementPos: any = $.map(self.currentFieldList, (obj: any, index: any) => {
                if (obj.key == elementToSelect) {
                    return index;
                }
            })[0];

            if (elementPos >= 0) {
                self.selectedCurrentField = self.currentFieldList[elementPos];
                self.currentFieldChanged();
            }
        }
    }

    /**
     * Listener to get the CustomizeUserList from OperatorInfoMessage.
     * @param customFieldSequenceComponent
     * @param userList
     */
    customizeForUserDataReadyListener(customFieldSequenceComponent: any, userList: any) {
        let self: CustomFieldSequenceComponent = <CustomFieldSequenceComponent>customFieldSequenceComponent;
        self.customizeForUserList = self.getCustomizeForUserList(userList);
        self.copyToOtherUsersList = self.getCopyToOtherUsersList(userList);
        self.selectedCustomizeForUser = self.customizeForUserList[0];
    }

    /**
     * Listener to update the customized property in copy dropdown list for all the users that have customizations
     * @param customFieldSequenceComponent
     * @param userId
     */
    updateCopyToOtherUsersCustomizedListener(customFieldSequenceComponent: any, userId: any) {
        let self: CustomFieldSequenceComponent = <CustomFieldSequenceComponent>customFieldSequenceComponent;

        // Check if there are any customizations for this user.
        let selectElem = $.grep(self.copyToOtherUsersList, (c: any) => {
            return c.id.toLocaleUpperCase() == userId.toLocaleUpperCase();
        });

        if (selectElem && selectElem.length > 0) {
            selectElem[0].customized = true;
            selectElem[0].checkboxTitle = selectElem[0].name + ". This user has existing customizations.";
        }
    }

    //endregion Listeners

    ngOnDestroy() {
        this.eventsService.destroy(Models.CustomFieldSequenceConstants.changeCurrentFieldListener, this.changeCurrentFieldListenerId);
        this.eventsService.destroy(Models.CustomFieldSequenceConstants.customizeForUserDataReadyListener, this.customizeForUserDataReadyListenerId);
        this.eventsService.destroy(Models.CustomFieldSequenceConstants.updateCopyToOtherUsersCustomizedListener, this.updateCopyToOtherUsersCustomizedListenerId);
    }
}