/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import { FavoritesService } from "../../services";

@Component({
    selector: 'recentlysharedlists',
    template: require('./recently-shared-lists.component.html')
})
export class RecentlySharedListsComponent {
    private sharedListDialog = "#shared-list-dialog";
    private recentlyCreatedSharedListLink = "#recentlyCreatedSharedListLink";

    constructor(private favoritesService: FavoritesService) {

    }

    recentlySharedClosed() {
        setTimeout(() => {
            if ($(this.sharedListDialog).is(":visible")) {
                document.getElementById("recentlyCreatedSharedListLink").focus();
            }
            else {
                let personFavoritesOption = document.querySelector("#personFavoritesOption a") as HTMLElement;
                personFavoritesOption.focus();
            }
        }, 100);
    }
}