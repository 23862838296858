/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable } from "@angular/core";
import * as Models from "../models";
import { ConfigurationService, EventsService, FocusService, PromptService, SessionService } from "./";

import * as _ from 'lodash';

@Injectable()
export class HelpService {

    constructor(private sessionService: SessionService,
        private configurationService: ConfigurationService,
        private eventsService: EventsService,
        private promptService: PromptService,
        private focusService: FocusService) {

    }

    display(helpId: string, type: string = null, fieldId: string = null): void {
        // Create arguments for help servlet
        let ssoToken: string = this.sessionService.getTokenIndex() + "*" + this.sessionService.getToken() + "*" + this.sessionService.getControlId();

        let helpParameters: any = {
            type: Models.HelperText.process,
            pid: helpId,
            ssotoken: encodeURIComponent(window.btoa(ssoToken))
        };

        // Slightly different behavior for generic help vs form process/field help
        if (type === Models.HelperText.form) {
            helpParameters.client = "ui";
        } else {
            helpParameters.web = "Y";
            helpParameters.pageid = "help";
        }

        if (fieldId != null) {
            helpParameters.FIELD = fieldId;
        }

        // Construct URL link to get to help servlet
        let helpServletUrl: string = this.configurationService.getConfiguration().ClientParameters.HelpServletUrl;

        // If no help servlet url is available, display message to the user.
        if (helpServletUrl == "") {
            this.displayHelpServletUrlWarning();
            this.eventsService.broadcast(Models.EventConstants.closeLeftSidebar);
            return;
        }

        let sep: string = "?";
        _.each(helpParameters, (value: any, key: any) => {
            helpServletUrl += sep + key + "=" + value;
            sep = "&";
        });

        this.eventsService.broadcast(Models.EventConstants.closeLeftSidebar);

        // Open the link to the help servlet
        let helpWindow: any = (<any>window).open(helpServletUrl, "UI_HELP");
        if (helpWindow != null)
            helpWindow.focus();
    }

    private displayHelpServletUrlWarning() {
        let popupMessage: any = {};
        popupMessage.title = "";
        popupMessage.text = ["The help system is unavailable or has not been configured."];
        popupMessage.buttons = [
            {
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        this.eventsService.broadcast(Models.EventConstants.closeAlert);
                        this.focusService.focusPreviousField();
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = "1";
        this.promptService.popupMessageShow(popupMessage);
    }
}