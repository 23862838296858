/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { EventsService } from "./";
import * as Models from "../models";
import * as _ from 'lodash';

@Injectable()
export class UiLocalStorageService {
    prefix: string = "";

    constructor(private eventsService: EventsService) {
        if (typeof localStorage == "undefined" || localStorage == null) {
            console.warn("This browser does not support local storage");
        }
    }

    get(key: string) {
        let localKey: string = this.prefix + key;
        let rawObj: any = localStorage[localKey];

        return (rawObj && (rawObj.indexOf('{') == 0 || rawObj.indexOf('[') == 0)) ? JSON.parse(rawObj) : rawObj;
    }

    set(key: string, value: any, backupDataFlag: boolean = true) {
        let preservedValue = value;
        let localKey = this.prefix + key;
        if (value) {
            if (_.isObject(value) || _.isArray(value)) {
                preservedValue = JSON.stringify(preservedValue);
            }

            localStorage[localKey] = preservedValue;
            // Do not backup data if calling method doesn't want to. This will be true when we restore data to local storage from preferencesService after login.
            if (backupDataFlag)
                this.eventsService.broadcast("backupLocalStorage");
        }
    }

    remove(key: string) {
        let localKey = this.prefix + key;
        let value = localStorage[localKey];

        if (value != null) {
            delete localStorage[localKey];
            this.eventsService.broadcast("backupLocalStorage");
        }
    }

    setPrefix(prefix: string) {
        if (_.isString(prefix)) {
            this.prefix = prefix;
        }
    }

    /**
     * Returns all the localStorage data of Colleague UI application in a JSON object
     */
    getAll() {
        // Get all the keys stored in local storage for this application
        let localStorageKeys = Object.keys(localStorage);
        let localStorageJson = {};

        for (let key in localStorageKeys) {
            // Compare the local storage keys with the whitelist in LocalStorageConstants
            for (var constantKey in Models.LocalStorageConstants) {
                if (Models.LocalStorageConstants.hasOwnProperty(constantKey) && Models.LocalStorageConstants[constantKey] == localStorageKeys[key]) {
                    let value: string = this.get(localStorageKeys[key]);
                    localStorageJson[localStorageKeys[key]] = value;
                    break;
                }
            }
        }

        return localStorageJson;
    }
}