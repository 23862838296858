/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import { ConfigurationService, SessionService } from "./";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ImageService {

    constructor(private http: HttpClient, private configurationService: ConfigurationService, private sessionService: SessionService) {

    }

    getImage(result: any): any {
        if (result == null || this.configurationService.getConfiguration().ClientParameters.PersonImagesEnabled == false)
            return null;

        result.image = "";
        result.imageMissing = true;
        let imageId = result['ID'];
        let imageRequest: any = {
            "ImageID": imageId,
            "UserID": this.sessionService.getUserId()
        };
        // imageRequest = JSON.stringify(imageRequest);

        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'image', this.sessionService.getToken(), this.sessionService.getControlId()].join('/');
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };

        this.http.post(url, imageRequest, httpOptions).subscribe(
            (response: any) => {
                if (response && response.status != 204) {
                    result.image = "data:image/jpeg;base64," + response;
                    result.imageMissing = false;
                }
            });
    }
}