/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Component, OnInit, Inject } from '@angular/core';
import {
    CommentDialogService,
    FocusService,
    HelpService,
    ConfigurationService,
    ValidationService,
    ServerCommandService
} from '../../../services'
import * as Models from "../../../models"
import { Subject } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';

declare var isChrome: any;
declare var isFirefox: any;
declare var isIE: any;
declare var saveAs: any;
declare var document: any;
declare var $: any;

@Component({
    selector: 'commentdialog',
    template: require('./commentdialog.component.html')
})
export class CommentDialogComponent implements OnInit {

    totalMatch: number;
    width: any;
    searchString: string = "";
    previousHash: boolean = false;
    isFirefox: boolean;
    isIE: boolean;
    isChrome: boolean;
    // Send a Keep Alive every minute there is user input
    keyDownNotifier: Subject<string> = new Subject();
    /**
     * Open Comment Dialog Help
     */
    openHelp = function () {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses.CommentsControl);
    };
    /**
     * Methods for selecting a value within the text input for given range
     */
    private setSelectionRange = function (input: any, selectionStart: number, selectionEnd: number) {
        if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        } else if (input.createTextRange) {
            let range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
    };

    constructor(private commentDialogService: CommentDialogService, private helpService: HelpService,
        private configurationService: ConfigurationService,
        private validationService: ValidationService,
        private serverCommandService: ServerCommandService,
        private focusService: FocusService) {
    }

    ngOnInit() {
        this.isFirefox = isFirefox;
        this.isIE = isIE;
        this.isChrome = isChrome;
        let chromeWidth = 683;
        let ffWidth = 723; //743px
        let ffMultiplier = 7;
        let chromeMultiplier = 6.6;

        // this.keyDownNotifier.pipe(debounceTime(5000))
        this.keyDownNotifier.pipe(throttleTime(5000))
			.subscribe(data => this.serverCommandService.sendProcessCommandMessage("KeepAlive"));
        let dppx = window.devicePixelRatio ||
            (window.matchMedia && window.matchMedia("(min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches ? 2 : 1) ||
            1;
        if (dppx > 1) {
            ffWidth += 21;
            ffMultiplier = 7.05;
            chromeMultiplier = 6.65;
        }
        if (this.commentDialogService.maxLength > 0) {
            this.width = (isChrome == true && isIE == false)
                ? (chromeWidth - (100 - this.commentDialogService.maxLength) * (6.6)) + 'px'
                : isFirefox == true ? (ffWidth - (100 - this.commentDialogService.maxLength) * (7.05)) + 'px' : "auto";
        }
        else {
            this.width = "100%";
        }

        // focus comment dialog
        setTimeout(() => {
            this.setSelectionRange($("#multiLine")[0], 0, 0);
            $("#multiLine").scrollTop(0);
        }, 250);
    }

    /**
     * Download current content as text file
     */
    downloadFile(): void {
        var text = $("#multiLine").val();
        var textToWrite = text.replace(/\n/g, "\r\n");
        var blob = new Blob([textToWrite], {
            type: "text/plain;charset=utf-8"
        });
        saveAs(blob, "fileName.txt");
        text = null;
    }

    // find next match for the search string
    findNext(event: any = null): void {
        if ((event != null && event.keyCode != Models.KeyCodes.enter) || this.validationService.isNullOrEmpty(this.searchString)) {
            this.totalMatch = -1;
            return;
        }
        this.totalMatch = -1;
        let txt = $("#multiLine").val().toLowerCase();
        let strSearchTerm = this.searchString.toLowerCase();
        let content = txt;
        let matchString = strSearchTerm;
        let reg = new RegExp(matchString, "g");

        // find next index of search term, starting from current cursor position
        let cursorPos = this.getCursorPosEnd($("#multiLine")[0]);
        let termPos = txt.indexOf(strSearchTerm, cursorPos);
        // if found, select it
        if (strSearchTerm !== "") {
            if (termPos != -1) {
                if (content.match(reg).length > 0) {
                    this.totalMatch = content.match(reg).length;
                    this.setCaretPosition("multiLine", termPos);
                    this.setSelectionRange($("#multiLine")[0], termPos, termPos + strSearchTerm.length);
                }
            } else {
                // not found from cursor pos, so start from beginning
                termPos = txt.indexOf(strSearchTerm);
                if (termPos != -1) {
                    if (content.match(reg).length > 0) {
                        this.setCaretPosition("multiLine", termPos);
                        this.totalMatch = content.match(reg).length;
                        this.setSelectionRange($("#multiLine")[0], termPos, termPos + strSearchTerm.length);
                    }
                }
            }
        }
        txt = null;
    }

    /**
     * Append Time stamp datetime and name at current position
     */
    appendTimeStamp(): number {
        let d = new Date();
        let minutes = d.getMinutes().toString().length == 1 ? '0' + d.getMinutes() : d.getMinutes();
        let hours = (d.getHours() > 12 ? d.getHours() - 12 : d.getHours());// Convert hours to 12 hour format instead of the 24 hour format
        let ampm = d.getHours() >= 12 ? 'PM' : 'AM';
        let contentToBeAppended = this.commentDialogService.timeStampDate + ' ' + hours + ":" + minutes + ' ' + ampm + ' ' + this.commentDialogService.timeStampName + "\n";
        this.insertAtCursor($("#multiLine")[0], contentToBeAppended);
        return contentToBeAppended.length;
    }

    /**
     *
     * @param event
     */
    keyDown(event: any) {
        let actionFound: boolean = false;
        let keyChar = String.fromCharCode(event.keyCode || event.charCode);
        this.keyDownNotifier.next(keyChar);
        //close dialog
        if (event.keyCode == Models.KeyCodes.f8 || event.keyCode == Models.KeyCodes.escape) {
            this.commentDialogService.closeCommentDialog();
            actionFound = true;
        }
        //save comments
        else if (event.keyCode == Models.KeyCodes.f9) {
            this.commentDialogService.save();
            actionFound = true;
        }
        //append time stamp
        else if (event.key == "#") {
            this.previousHash = true;
        }
        else if (this.previousHash && event.keyCode == Models.KeyCodes.enter) {
            this.previousHash = false;
            // get current cursor position
            let cursorPos = this.getCursorPosEnd($("#multiLine")[0]);
            let text = $("#multiLine").val();
            // remove the # key from the content
            text = text.substr(0, cursorPos - 1) + text.substr(cursorPos, text.length - cursorPos);
            $("#multiLine").val(text);
            //set the cursor where # sign was
            this.setSelectionRange($("#multiLine")[0], cursorPos - 1, cursorPos - 1);
            // insert time stamp
            let timeStampLength: number = this.appendTimeStamp();
            // set the cursor at the end for timestamp
            this.setSelectionRange($("#multiLine")[0], cursorPos + timeStampLength - 2, cursorPos + timeStampLength - 2);
            actionFound = true;
            text = null;
        }
        else if (event.ctrlKey && keyChar == "A") { //CTRL + A
            this.previousHash = false;
            let pre = $('#multiLine')[0];
            if (pre.select) {
                pre.select();
            }
            else if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(pre);
                range.select();
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(pre);
                window.getSelection().addRange(range);
            }
            actionFound = true;
            pre = null;
        }
        else {
            this.previousHash = false;
        }

        if (actionFound) { // prevent default and stop propagation to avoid event bubbling
            event.stopPropagation();
            event.preventDefault();
        }
    }

    /**
     * Tab on text area should focus on help
     * @param event
     */
    commentDialogTextAreaKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("btnCommentDialogHelp", true);
        }
    }

    /**
     * Shift tab on help should focus on text area
     * @param event
     */
    btnCommentDialogHelpKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("multiLine", true);
        }
    }

    private setCaretPosition(elemId: string, caretPos: number) {
        var elem = document.getElementById(elemId);

        if (elem != null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            }
            else {
                if (elem.selectionStart) {
                    elem.setSelectionRange(caretPos, caretPos);
                    elem.focus();

                    var e = jQuery.Event("keypress");
                    e.which = 32;
                    $("#" + elemId).trigger(e);

                    var e = jQuery.Event("keypress");
                    e.which = 8;
                    $("#" + elemId).trigger(e);
                }
                else
                    elem.focus();
            }
        }
    }

    /**
     * Inserts a value at current focus/cursor position
     * @param field Dom text object
     * @param value value to be appended
     */
    private insertAtCursor(field: any, value: any) {
        // TODO: Which IE version is supported and do we need this code anymore?
        //IE support
        if (document.selection) {
            field.focus();
            let sel = document.selection.createRange();
            sel.text = value;
        }
        //MOZILLA and others
        else if (field.selectionStart || field.selectionStart == '0') {
            let startPos: number = field.selectionStart;
            let endPos: number = field.selectionEnd;
            field.value = field.value.substring(0, startPos)
                + value
                + field.value.substring(endPos, field.value.length);
            this.setCaretPosition(field.id, startPos + value.length);
        } else {
            field.value += value;
            this.setCaretPosition(field.id, value.length);
        }
    }

    /**
     * Gets the current cursor position
     * @param input Dom text input
     */
    private getCursorPosEnd(input: any): number {
        var pos = 0;
        // IE Support
        if (document.selection) {
            input.focus();
            var sel = document.selection.createRange();
            pos = sel.text.length;
        }
        // Firefox/chrome support
        else if (input.selectionStart || input.selectionStart == '0')
            pos = input.selectionEnd;
        return pos;
    };


}