/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Injectable} from "@angular/core";
import * as Models from "../models";
import {RootScopeService, ValidationService, ConfigurationService} from "./";
import * as _ from 'lodash';

@Injectable()
export class ParserService {
    nullValue: string = "_i:nil";

    constructor(private rootScopeService: RootScopeService, private validationService: ValidationService, private configurationService: ConfigurationService) {

    }

    /**
     * Main function
     * @param rawXml
     * @param formId
     * @returns {any}
     */
    parseFormSpecs(rawXml: any, formId: string) {
        let originalMessage: any = rawXml;
        let baseFormId: string = originalMessage.form.formID;
        // replace any missing nodes in the original message with nulls
        // originalMessage = this.recursiveNullReplacement(originalMessage);

        let windowList: any = this.createWindowList(originalMessage);
        let formElements = this.createFormElementsList(originalMessage, windowList);
        let sortedRowObject = this.createRowGroups(formElements, windowList);
        let sortedRowArray = sortedRowObject.rowArray;
        let lastRow = sortedRowObject.lastRow;
        let lastColumn = sortedRowObject.lastColumn;
        let firstFocusElement = sortedRowObject.firstFocusElement;
        let bottomHeaderRow = sortedRowObject.bottomHeaderRow;

        let form: any = {
            formId: formId,
            baseFormId: baseFormId,
            fields: formElements,
            formTitle: originalMessage.form.formTitle,
            formMnemonic: originalMessage.form.formMnemonic,
            original: originalMessage,
            rowGroups: sortedRowArray,
            windowList: windowList,
            lastRow: lastRow,
            lastColumn: lastColumn,
            firstFocusElement: firstFocusElement,
            bottomHeaderRow: bottomHeaderRow,
            externalLinks: originalMessage.form.formExternalLinks,
            formGraphicOmitGraphics: originalMessage.form.formGraphicOmitGraphics
        };

        form = this.addCustomFieldSequenceElementOrders(form);

        let headerRowNumber: any = 0;
        for (let i = form.rowGroups.length; i >= 0; i--) {
            if (form.rowGroups[i] == null) {
                continue;
            }
            else {
                form.rowGroups[i] = {
                    headerOnlyRow: true,
                    rowGroups: form.rowGroups[i]
                };

                for (let j = 0; j < form.rowGroups[i].rowGroups.length; j++) {
                    if (form.rowGroups[i].rowGroups[j].indexOf(Models.HelperText.promptText) != 0) {
                        form.rowGroups[i].headerOnlyRow = false;
                        break;
                    }
                }

                if (form.rowGroups[i].headerOnlyRow == true) {
                    headerRowNumber++;
                    for (let j = 0; j < form.rowGroups[i].rowGroups.length; j++) {
                        form.fields[form.rowGroups[i].rowGroups[j]].headerRowNumber = headerRowNumber
                    }
                } else {
                    headerRowNumber = 0;
                }
            }
        }

        _.each(form.fields, (fieldElement: any) => {
            fieldElement.isValcodeField = fieldElement.elementType == Models.ElementType.valCode;
            fieldElement.isPhantomField = fieldElement.elementType === Models.ElementType.phantom;
            fieldElement.isInquiryField = fieldElement.elementType === Models.ElementType.inquiry;
            
            fieldElement.isInputField = (_.includes([Models.ElementType.maint, Models.ElementType.boolean, Models.ElementType.inquiry], fieldElement.elementType));
            fieldElement.isFormLabel = ((fieldElement.elementType === Models.ElementType.label) || (fieldElement.elementType === Models.ElementType.varPrompt));
            fieldElement.isVariableLabel = fieldElement.elementType === Models.ElementType.varPrompt;
            fieldElement.isHeaderField = fieldElement.elementInHeader === Models.HelperText.y;
            fieldElement.isGraphicField = fieldElement.elementType === Models.ElementType.graphic;
            fieldElement.realElementDisplayWidth = fieldElement.elementDisplayWidth;
            if (fieldElement.elementDate) {
                fieldElement.dateFormat = (fieldElement.elementConversion === "SN.DATE4" ? this.configurationService.getConfiguration().ClientParameters.Date4Format :
                    (fieldElement.elementConversion === "SN.DATE2" ? this.configurationService.getConfiguration().ClientParameters.Date2Format : fieldElement.elementConversion));
                fieldElement.delimiter = "/";
                if (fieldElement.dateFormat.indexOf("-") != -1) {
                    fieldElement.delimiter = "-";
                }
                fieldElement.dateFormat = fieldElement.dateFormat.replace('D2/', '').replace('D2-', '').replace('D4/', '').replace('D4-', '');
                fieldElement.yearIndex = fieldElement.dateFormat.toUpperCase().indexOf('Y');
                fieldElement.monthIndex = fieldElement.dateFormat.toUpperCase().indexOf('M');
                fieldElement.dayIndex = fieldElement.dateFormat.toUpperCase().indexOf('D');

            }
            // Check if field is not part of window, means these are not window fields
            if (fieldElement.isInputField || fieldElement.isValcodeField) {

                // Calculate next field to be used when tabbing on non window field or 
                if (this.validationService.isNullOrEmpty(fieldElement.elementElementForward)) {

                    if (form.fields[fieldElement.elementFieldForward] != null) {
                        fieldElement.nextField = form.fields[fieldElement.elementFieldForward].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementFieldForward + "_1"] != null) {
                        fieldElement.nextField = form.fields[fieldElement.elementFieldForward + "_1"].sanitizedID;
                    }
                }
                else {
                    if (form.fields[fieldElement.elementElementForward + "_" + fieldElement.rowIndex] != null) {
                        fieldElement.nextField = form.fields[fieldElement.elementElementForward + "_" + +fieldElement.rowIndex].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementElementForward] != null) {
                        fieldElement.nextField = form.fields[fieldElement.elementElementForward].sanitizedID;
                    }
                }

                // Calculate next element to be used when tabbing on empty window row element
                if (this.validationService.isNullOrEmpty(fieldElement.elementWindowController) == false &&
                    fieldElement.elementWindowController == fieldElement.originalElementID) {
                    if (form.fields[fieldElement.elementFieldForward] != null) {
                        fieldElement.nextElement = form.fields[fieldElement.elementFieldForward].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementFieldForward + "_1"] != null) {
                        fieldElement.nextElement = form.fields[fieldElement.elementFieldForward + "_1"].sanitizedID;
                    }
                }

                // Used for Shift + Tab
                // Calculate previous field to be used when tabbing on non window field
                if (this.validationService.isNullOrEmpty(fieldElement.elementElementBack)) {

                    if (form.fields[fieldElement.elementFieldBack] != null) {
                        fieldElement.previousField = form.fields[fieldElement.elementFieldBack].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementFieldBack + "_" + fieldElement.rowIndex] != null) {
                        fieldElement.previousField = form.fields[fieldElement.elementFieldBack + "_" + fieldElement.rowIndex].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementFieldBack + "_1"] != null) {
                        fieldElement.previousField = form.fields[fieldElement.elementFieldBack + "_1"].sanitizedID;
                    }
                }
                else {

                    if (form.fields[fieldElement.elementElementBack] != null) {
                        fieldElement.previousField = form.fields[fieldElement.elementElementBack].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementElementBack + "_" + fieldElement.rowIndex] != null) {
                        fieldElement.previousField = form.fields[fieldElement.elementElementBack + "_" + fieldElement.rowIndex].sanitizedID;
                    }
                    else if (form.fields[fieldElement.elementElementBack + "_1"] != null) {
                        fieldElement.previousField = form.fields[fieldElement.elementElementBack + "_1"].sanitizedID;
                    }
                }
            }
            fieldElement.originalText = "";
            fieldElement.text = "";
        });

        form.fieldsArray = _.map(form.fields, (value: any, key: any) => {
            return value;
        });

        return form;
    }

    /**
     * Build the helper text or hover text for the given element.
     */
    buildHelperText(fieldElement: any, loadedData: string, hover: boolean, windowList?: any, formFieldElements?: any): string {
        let helperText: string = "";
        let elementLabel: string = "";
        let compoundLabel: string = "";

        if (this.validationService.isNullOrEmpty(fieldElement.elementWindowController)) {
            if (!this.validationService.isNullOrEmpty(windowList)) {
                let windowElement: any = windowList[fieldElement.elementWindowController];

                if (!this.validationService.isNullOrEmpty(windowElement) && !this.validationService.isNullOrEmpty(formFieldElements)) {
                    if (windowElement[0]) {
                        if (this.validationService.isNullOrEmpty(windowElement[0].windowLabel)) {
                            for (let elKey in formFieldElements) {
                                if (fieldElement.elementWindowController == formFieldElements[elKey].elementID) {
                                    helperText += formFieldElements[elKey].elementHelpLabel + " Window ";
                                }
                            }
                        }
                        else {
                            helperText += windowElement[0].windowLabel + " Window ";
                        }
                    }
                }
            }
        }
        /*
         For elements with variable prompts, allow the value of the variable prompt to be passed in as the element label.
         Otherwise, set the label based on attributes of the field element.
         */
        if (loadedData == undefined) {
            loadedData = "";
        }
        if (hover == undefined) {
            hover = false;
        }
        if (loadedData.length > 0) {
            elementLabel = loadedData;
        } else {
            elementLabel = this.getFieldLabel(fieldElement);
        }
        if (fieldElement.inquiry || fieldElement.runTimeInquiryField || fieldElement.runTimeRTInquiryField) {
            helperText += Models.HelperText.inquiry;
        } else if (fieldElement.runTimeNoAccess) {
            helperText += Models.HelperText.noAccess;
        } else {
            helperText += Models.HelperText.maintainable;
        }

        helperText += Models.HelperText.field + elementLabel;

        if (!fieldElement.runTimeNoAccess) {
            if (fieldElement.elementDetailable) {
                helperText += Models.HelperText.elementDetailable;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.elementType == 'valCode') {
                helperText += Models.HelperText.valCodeField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.lookUpField) {
                helperText += Models.HelperText.lookUpField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.elementDate) {
                helperText += Models.HelperText.dateField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.elementCalc) {
                helperText += Models.HelperText.calcField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
        }

        /*
         JAWS will announce "required" when the input element in html has a required attribute.  Add "required" for hover text
         */
        if (fieldElement.required) {
            helperText += compoundLabel + Models.HelperText.is;
            if (hover) {
                helperText += Models.HelperText.required;
            }
        }
        return helperText;
    }

    /**
     * CUI-3515 - Add the sequence Order of each element inside a form to formElements collection
     * @param formElements
     * @param customFormElements - Comes from CustomFormSpecsMessage
     * @private
     */
    createSequenceOrderForElements(formElements: any, customFormElements: any) {
        // Create an array which contains each formElement's ID and it's corresponding elementOrder
        let sortable: any = this.getSortedElements(formElements);
        let customFirstField: string = "";
        let customLastField: string = "";
        let customInquiryFields: any;
        let customNoAccessFields: any;

        // Get all the customized fields - First field, last field, inquiry fields, no access fields
        if (this.rootScopeService.disableCustomFieldSequence != true && !this.validationService.isNullOrEmpty(customFormElements) && customFormElements.length > 0) {
            // Check if any element has been customized to be the first field
            customFirstField = this.getCustomFirstField(customFormElements);

            // Check if any element has been customized to be the last field
            customLastField = this.getCustomLastField(customFormElements);
        }

        if (!this.validationService.isNullOrEmpty(customFormElements) && customFormElements.length > 0) {
            // Check if any elements have been customized to be inquiry only fields
            customInquiryFields = this.getCustomInquiryFields(customFormElements);

            // Check if any elements have been customized to be no access fields
            customNoAccessFields = this.getCustomNoAccessFields(customFormElements);
        }

        // Start the sequenceOrder from 1 and increment for each valid record in the loop
        let sequenceOrder: number = 1;
        let firstElementAdded: boolean = false;

        // Variables to keep track of window controller element's sequence order
        let windowControllerElementsAdded: any = [];
        let windowElement: string;
        let windowElementNumber: number;
        let currentElementWindowFlag: boolean = false;

        let startLoopNumber: number = 0;

        if (!this.validationService.isNullOrEmpty(customFirstField)) {
            let startPos = sortable.map((x: any) => {
                return x[0];
            }).indexOf(customFirstField);
            // If startPos is not found, try to find the position by adding '_1' to the end of customFirstField since it might be a window controller field.
            if (startPos == -1) {
                startPos = sortable.map((x: any) => {
                    return x[0];
                }).indexOf(customFirstField + "_1");
            }
            // Set i to this startPos and proceed.
            if (!this.validationService.isNullOrEmpty(startPos) && !isNaN(startPos) && startPos >= 0) {
                startLoopNumber = startPos;
            }
        }

        for (let i = startLoopNumber; i < sortable.length; i++) {
            // Get the elementID
            let elem = sortable[i][0];

            // Reset tracking variables
            windowElement = "";
            windowElementNumber = 0;
            currentElementWindowFlag = false;

            // If there is an underscore in ID, it could be a window controller element.
            if (elem.indexOf('_') > 0) {
                windowElement = elem.split('_')[0];
                windowElementNumber = elem.split('_')[1];
                // If the windowElementNumber is not 1, set it to 1 so that sequenceOrder gets added to the first row
                if (!this.validationService.isNullOrEmpty(windowElementNumber) && !isNaN(windowElementNumber) && windowElementNumber > 0 && windowElementNumber != 1) {
                    windowElementNumber = 1;
                    elem = windowElement + "_" + windowElementNumber;
                }
                // Keep track of window controller elements that have already been added to the sequence order
                if (formElements[elem] != null && formElements[elem].hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(formElements[elem].elementWindowController)) {
                    currentElementWindowFlag = true;

                    // If the collection of already added windowControllerElements contains a match for current element, continue the iteration.
                    if (windowControllerElementsAdded.length > 0 && windowControllerElementsAdded.indexOf(windowElement) != -1) {
                        continue;
                    }
                }
            }

            // If a form element already has a sequence order and it comes back in the loop, it means the sequence ordering should end here. Do not proceed.
            if (formElements[elem] != null && formElements[elem].hasOwnProperty("sequenceOrder") && !isNaN(formElements[elem].sequenceOrder)) {
                break;
            }

            if (!this.validationService.isNullOrEmpty(customFormElements) && customFormElements.length > 0) {
                // Check if this elementID has any CustomFormSpecs. In case we are looking at a field in elementWindowController, split on '_' and then compare the ID's.
                let customizedElem = $.grep(customFormElements, (c: any) => {
                    if (c.ElementID.toUpperCase() == elem.toUpperCase() || elem.indexOf('_') > 0 && c.ElementID.toUpperCase() == elem.split('_')[0].toUpperCase())
                        return true;
                });

                // Match found for a customized element
                if (this.rootScopeService.disableCustomFieldSequence != true && customizedElem && customizedElem.length == 1) {
                    // Check if ElementFieldForward is set in the current customFormElement. If it is, find that Field in sortable and set i to the index of that field minus 1
                    // (for loop will increment i so always set value to less than 1 of the index). Doing so will ensure that the sequence stays in order and none of the elements are missing.
                    if (customizedElem[0].hasOwnProperty("ElementFieldForward") && !this.validationService.isNullOrEmpty(customizedElem[0].ElementFieldForward)) {
                        let elemFieldForwardID: string = customizedElem[0].ElementFieldForward;
                        // Add a property customFieldForward to the formElement. This will help in updating sequence order when server sends a FieldSecurityMessage
                        formElements[elem].customFieldForward = elemFieldForwardID;
                        let elementPos = sortable.map((x: any) => {
                            return x[0];
                        }).indexOf(elemFieldForwardID);
                        // If elementPos is not found, try to find the position by adding '_1' to the end of elemFieldForwardID since it might be a window controller field.
                        if (elementPos == -1) {
                            elementPos = sortable.map((x: any) => {
                                return x[0];
                            }).indexOf(elemFieldForwardID + "_1");
                        }
                        // Set i to this elementPos - 1(minus 1 because for loop will increment by 1) and proceed.
                        if (!this.validationService.isNullOrEmpty(elementPos) && !isNaN(elementPos) && elementPos >= 0) {
                            i = elementPos - 1;
                        }
                    }
                }
            }

            // If the formElement is not header or phantom or form label and has a valid elementOrder, add a sequenceOrder corresponding to this formElement.
            // Also check for the inquiry conditions in the last clause of if condition using isElementInquiry method
            if (formElements[elem] != null && !this.validationService.isNullOrEmpty(formElements[elem].elementOrder) && !formElements[elem].isHeaderField && !formElements[elem].isPhantomField && !formElements[elem].isFormLabel &&
                (this.isElementInquiry(formElements[elem]))) {
                let currentElementProcessed = false;

                if (this.rootScopeService.disableCustomFieldSequence != true) {
                    //region Handle case for firstField
                    if (firstElementAdded != true && !this.validationService.isNullOrEmpty(customFirstField)) {
                        if (elem.toUpperCase() === customFirstField.toUpperCase() ||
                            (currentElementWindowFlag === true && windowElement.toUpperCase() === customFirstField.toUpperCase())) {
                            let addFirstFieldSequenceOrder = true;

                            // Check to see if the first field is an inquiry field and if it is, do not add sequence order
                            if (!this.validationService.isNullOrEmpty(customInquiryFields) && customInquiryFields.length > 0) {
                                for (let f = 0; f < customInquiryFields.length; f++) {
                                    if (elem.toUpperCase() === customInquiryFields[f].toUpperCase() && !formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable != true) {
                                        addFirstFieldSequenceOrder = false;
                                        break;
                                    }
                                }
                            }

                            // First field is not an inquiry field, proceed with adding it's sequence order
                            if (addFirstFieldSequenceOrder == true) {
                                if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                                    windowControllerElementsAdded.push(windowElement);
                                    formElements[elem].sequenceOrder = sequenceOrder;
                                    formElements[elem].elementSkipTab = sequenceOrder;
                                    sequenceOrder++;
                                } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                                    formElements[elem].sequenceOrder = sequenceOrder;
                                    formElements[elem].elementSkipTab = sequenceOrder;
                                    sequenceOrder++;
                                }
                            }

                            // Reset this flag so that we can proceed with adding other element's sequence order
                            firstElementAdded = true;
                            currentElementProcessed = true;
                            continue;
                        } else {
                            continue;
                        }
                    }
                    //endregion

                    //region Handle case for last field
                    if (currentElementProcessed == false && !this.validationService.isNullOrEmpty(customLastField)) {
                        if (elem.toUpperCase() === customLastField.toUpperCase() ||
                            (currentElementWindowFlag === true && windowElement.toUpperCase() === customLastField.toUpperCase())) {
                            let addLastFieldSequenceOrder = true;

                            // Check to see if the last field is an inquiry field or not. Also, if it is inquiry, check to see if it is detailable or not.
                            if (!this.validationService.isNullOrEmpty(customInquiryFields) && customInquiryFields.length > 0) {
                                for (let l = 0; l < customInquiryFields.length; l++) {
                                    if (elem.toUpperCase() === customInquiryFields[l].toUpperCase() && !formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable != true) {
                                        addLastFieldSequenceOrder = false;
                                        break;
                                    }
                                }
                            }

                            // Last field is not an inquiry field, proceed with adding it's sequence order
                            if (addLastFieldSequenceOrder == true) {
                                if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                                    windowControllerElementsAdded.push(windowElement);
                                    formElements[elem].sequenceOrder = sequenceOrder;
                                    formElements[elem].elementSkipTab = sequenceOrder;
                                    sequenceOrder++;
                                } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                                    formElements[elem].sequenceOrder = sequenceOrder;
                                    formElements[elem].elementSkipTab = sequenceOrder;
                                    sequenceOrder++;
                                }
                            }

                            // Last element has been processed. Abort further sequencing.
                            break;
                        }
                    }
                    //endregion

                    //region Handle case for inquiry only fields
                    if (currentElementProcessed == false && !this.validationService.isNullOrEmpty(customInquiryFields) && customInquiryFields.length > 0) {
                        let addCurrentFieldInquiryCheck;
                        for (let n = 0; n < customInquiryFields.length; n++) {
                            if (elem.toUpperCase() === customInquiryFields[n].toUpperCase() && currentElementWindowFlag != true) {
                                addCurrentFieldInquiryCheck = false;
                                currentElementProcessed = true;
                            }
                            // Window controller element check
                            else if (currentElementWindowFlag === true && windowElement.toUpperCase() === customInquiryFields[n].toUpperCase()) {
                                // If this inquiry field is the first field of window controller, proceed. Else, continue;
                                if (formElements[elem].elementWindowController.toUpperCase() == windowElement.toUpperCase() ||
                                    (formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable == true)) {
                                    addCurrentFieldInquiryCheck = true;
                                    currentElementProcessed = true;
                                    break;
                                } else {
                                    addCurrentFieldInquiryCheck = false;
                                    currentElementProcessed = true;
                                    break;
                                }
                            }
                        }

                        if (addCurrentFieldInquiryCheck == true) {
                            if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                                windowControllerElementsAdded.push(windowElement);
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            }
                            currentElementProcessed = true;
                            continue;
                        }
                    }
                    //endregion

                    //region Handle case for no access fields
                    if (currentElementProcessed == false && !this.validationService.isNullOrEmpty(customNoAccessFields) && customNoAccessFields.length > 0) {
                        let addCurrentFieldNoAccessCheck = true;
                        for (let a = 0; a < customNoAccessFields.length; a++) {
                            if ((elem.toUpperCase() === customNoAccessFields[a].toUpperCase() && currentElementWindowFlag != true && !formElements[elem].hasOwnProperty("elementDetailable")) || (elem.toUpperCase() === customNoAccessFields[a].toUpperCase() && currentElementWindowFlag != true &&
                                formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable != true)) {
                                addCurrentFieldNoAccessCheck = false;
                                currentElementProcessed = true;
                            } else if (currentElementWindowFlag === true && windowElement.toUpperCase() === customNoAccessFields[a].toUpperCase()) {
                                // If this no access field is the first field of window controller, proceed. Else, continue;
                                if (formElements[elem].elementWindowController.toUpperCase() == windowElement.toUpperCase() ||
                                    (formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable == true)) {
                                    addCurrentFieldNoAccessCheck = true;
                                    currentElementProcessed = true;
                                    break;
                                } else {
                                    addCurrentFieldNoAccessCheck = false;
                                    currentElementProcessed = true;
                                    break;
                                }
                            }
                        }

                        if (addCurrentFieldNoAccessCheck == true) {
                            if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                                windowControllerElementsAdded.push(windowElement);
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            }
                            currentElementProcessed = true;
                            continue;
                        }
                    }
                    //endregion

                    if (currentElementProcessed == false) {
                        if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                            windowControllerElementsAdded.push(windowElement);
                            formElements[elem].sequenceOrder = sequenceOrder;
                            formElements[elem].elementSkipTab = sequenceOrder;
                            sequenceOrder++;
                        } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                            formElements[elem].sequenceOrder = sequenceOrder;
                            formElements[elem].elementSkipTab = sequenceOrder;
                            sequenceOrder++;
                        }
                    }
                }
                // If custom field sequence is disabled, still check for the no access and inquiry elements.
                else if (this.rootScopeService.disableCustomFieldSequence == true) {
                    //region Handle case for inquiry only fields
                    if (currentElementProcessed == false && !this.validationService.isNullOrEmpty(customInquiryFields) && customInquiryFields.length > 0) {
                        let addCurrentFieldInquiryCheck;
                        for (let n = 0; n < customInquiryFields.length; n++) {
                            if (elem.toUpperCase() === customInquiryFields[n].toUpperCase() && currentElementWindowFlag != true) {
                                addCurrentFieldInquiryCheck = false;
                                currentElementProcessed = true;
                            }
                            // Window controller element check
                            else if (currentElementWindowFlag === true && windowElement.toUpperCase() === customInquiryFields[n].toUpperCase()) {
                                // If this inquiry field is the first field of window controller, proceed. Else, continue;
                                if (formElements[elem].elementWindowController.toUpperCase() == windowElement.toUpperCase() ||
                                    (formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable == true)) {
                                    addCurrentFieldInquiryCheck = true;
                                    currentElementProcessed = true;
                                    break;
                                } else {
                                    addCurrentFieldInquiryCheck = false;
                                    currentElementProcessed = true;
                                    break;
                                }
                            } else {
                                addCurrentFieldInquiryCheck = false;
                            }
                        }

                        if (addCurrentFieldInquiryCheck == true) {
                            if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                                windowControllerElementsAdded.push(windowElement);
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            }
                            currentElementProcessed = true;
                            continue;
                        }
                    }
                    //endregion

                    //region Handle case for no access fields
                    if (currentElementProcessed == false && !this.validationService.isNullOrEmpty(customNoAccessFields) && customNoAccessFields.length > 0) {
                        let addCurrentFieldNoAccessCheck = true;
                        for (let a = 0; a < customNoAccessFields.length; a++) {
                            if ((elem.toUpperCase() === customNoAccessFields[a].toUpperCase() && currentElementWindowFlag != true && !formElements[elem].hasOwnProperty("elementDetailable")) || (elem.toUpperCase() === customNoAccessFields[a].toUpperCase() && currentElementWindowFlag != true &&
                                formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable != true)) {
                                addCurrentFieldNoAccessCheck = false;
                                currentElementProcessed = true;
                            } else if (currentElementWindowFlag === true && windowElement.toUpperCase() === customNoAccessFields[a].toUpperCase()) {
                                // If this no access field is the first field of window controller, proceed. Else, continue;
                                if (formElements[elem].elementWindowController.toUpperCase() == windowElement.toUpperCase() ||
                                    (formElements[elem].hasOwnProperty("elementDetailable") && formElements[elem].elementDetailable == true)) {
                                    addCurrentFieldNoAccessCheck = true;
                                    currentElementProcessed = true;
                                    break;
                                } else {
                                    addCurrentFieldNoAccessCheck = false;
                                    currentElementProcessed = true;
                                    break;
                                }
                            } else {
                                addCurrentFieldNoAccessCheck = false;
                            }
                        }

                        if (addCurrentFieldNoAccessCheck == true) {
                            if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                                windowControllerElementsAdded.push(windowElement);
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                                formElements[elem].sequenceOrder = sequenceOrder;
                                formElements[elem].elementSkipTab = sequenceOrder;
                                sequenceOrder++;
                            }
                            currentElementProcessed = true;
                            continue;
                        }
                    }
                    //endregion

                    if (currentElementProcessed == false) {
                        if (currentElementWindowFlag === true) { //Indicates this is a window controller element.
                            windowControllerElementsAdded.push(windowElement);
                            formElements[elem].sequenceOrder = sequenceOrder;
                            formElements[elem].elementSkipTab = sequenceOrder;
                            sequenceOrder++;
                        } else if (currentElementWindowFlag === false) { //Indicates this is not a window controller element.
                            formElements[elem].sequenceOrder = sequenceOrder;
                            formElements[elem].elementSkipTab = sequenceOrder;
                            sequenceOrder++;
                        }
                    }
                }
            }
        }
    }

    /**
     * Based on the FieldSecurityMessage, an element's security setting may change. Therefore, change the sequence order of this element as well.
     * @param formElements - Fields in the form
     * @param targetField - Field for which the sequence order needs to be updated.
     * @param securitySetting - (Constants.fieldsecurity) SecuritySetting that determines whether the element needs to be added or removed from the sequence order.
     */
    updateSequenceOrderForElement(formElements: any, targetField: any, securitySetting: any) {
        let firstFormElement: any = this.getFormElementsFirstField(formElements);
        let lastFormElement: any = this.getFormElementsLastField(formElements);

        // Remove from sequence order if the security setting is No Access, Inquiry or RT_Inquiry
        if (securitySetting == Models.FieldSecurityConstants.noaccess || securitySetting == Models.FieldSecurityConstants.inquiry || securitySetting == Models.FieldSecurityConstants.rtinquiry) {
            let findElement: any = formElements[targetField.elementID];

            // If the element is the first element in a window, we should not delete the sequence order.
            if (findElement.elementID.indexOf('_') > 0) {
                let windowElementNumber: any = findElement.elementID.split('_')[1];
                // If the current element is window controller's first element and is an inquiry field, do not remove sequenceOrder
                if (!this.validationService.isNullOrEmpty(windowElementNumber) && !isNaN(windowElementNumber) && windowElementNumber == 1) {
                    return;
                }
            }
            //If the element is detailable, we should not delete the sequence order.
            if (!this.validationService.isNullOrEmpty(findElement.elementDetailable) && findElement.elementDetailable == true) {
                return;
            }

            // If there is a match to the targetField in currentForm's fields and the field has a sequenceOrder, remove the sequenceOrder
            if (!this.validationService.isNullOrEmpty(findElement) && findElement.hasOwnProperty("sequenceOrder") && !isNaN(findElement.sequenceOrder)) {
                delete findElement.sequenceOrder;
            }

            // Get sorted elements
            let sortElements = this.getSortedElements(formElements, false);

            // Iterate through form elements and find the position where the field with current security setting was found. Once found, proceed to update the sequence orders of the following items in the list of
            // form elements
            let matchAlreadyFound: boolean = false;
            let sequenceOrder: number = 0;
            let breakIteration: boolean = false;

            for (let i = 0; i < sortElements.length; i++) {
                // Get the elementID
                let elem: any = sortElements[i][0];

                // If this element is the first field, proceed.
                if (!this.validationService.isNullOrEmpty(firstFormElement) && elem != firstFormElement.elementID)
                    continue;

                // If this element is the last field, stop sequencing.
                if (!this.validationService.isNullOrEmpty(lastFormElement) && elem == lastFormElement.elementID)
                    breakIteration = true;

                // If match was already found, the items after the matching element need to have their sequenceOrder updated. However, only update those elements which already had a sequenceOrder associated with them.
                // Do not update fields that are not a part of the form fields on the UI.
                if (matchAlreadyFound == true && formElements[elem].hasOwnProperty("sequenceOrder") && !isNaN(formElements[elem].sequenceOrder)) {
                    formElements[elem].sequenceOrder = sequenceOrder;
                    formElements[elem].elementSkipTab = sequenceOrder;
                } else if (matchAlreadyFound == false && elem == findElement.elementID) {
                    matchAlreadyFound = true;
                    sequenceOrder++;
                    continue;
                }

                if (formElements[elem].hasOwnProperty("sequenceOrder") && !isNaN(formElements[elem].sequenceOrder))
                    sequenceOrder++;

                if (breakIteration == true)
                    break;
            }
        } else if (securitySetting == Models.FieldSecurityConstants.full || securitySetting == Models.FieldSecurityConstants.addonly || securitySetting == Models.FieldSecurityConstants.noDelete) {
            // If targetField doesn't have a sequenceOrder, add a sequenceOrder
            if (!(targetField.hasOwnProperty("sequenceOrder") && !isNaN(targetField.sequenceOrder))) {
                let processFurther: boolean = true;
                // Check if the targetField is a window controller and if it is, check if the first of those targetFields already has a sequenceOrder
                if (targetField.hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(targetField.elementWindowController) && targetField.elementID.indexOf('_') > 0) {
                    if (targetField != 1) {
                        let targetFieldFirst: string = targetField.elementID.split('_')[0];
                        let firstOfTargetFieldID: string = targetFieldFirst + "_1";

                        // Check if firstOfTargetField has sequenceOrder. If it does, do not proceed further.
                        let firstOfTargetField: any = formElements[firstOfTargetFieldID];
                        if (firstOfTargetField.hasOwnProperty("sequenceOrder") && !isNaN(firstOfTargetField.sequenceOrder)) {
                            processFurther = false;
                        } else {
                            // The first of window controller elements does not have any sequence order. Reset the target field and proceed with adding the sequenceOrder.
                            processFurther = true;
                            targetField = firstOfTargetField;
                        }
                    }
                }

                if (processFurther == true) {
                    let findElement: any = formElements[targetField.elementID];

                    // Get sorted elements
                    let sortElements: any = this.getSortedElements(formElements, false);

                    // Iterate through the form elements, find the position of the matching elements and add a sequenceOrder. Push the remaining sequenceOrder by 1.
                    let matchAlreadyFound: boolean = false;
                    let sequenceOrder: number = 0;
                    let breakIteration: boolean = false;

                    // Array that will keep track of indexes that have been processed. If an index comes in loop that is already in the array, loop will break out of iterations.
                    let processed: any = [];

                    for (let i = 0; i < sortElements.length; i++) {
                        // If we loop back to an element that has already been processed, it means that an element's fieldforward was set to something that had a lower elementOrder. This step avoids infinite loop.
                        if (processed.indexOf(i) >= 0) {
                            break;
                        }
                        // If this field index has not already been processed, add it to the processed records
                        else {
                            // Push this field's index to processed
                            processed.push(i);
                        }

                        // Get the elementID
                        let elem: any = sortElements[i][0];

                        // If this element is the first field, proceed.
                        if (!this.validationService.isNullOrEmpty(firstFormElement) && elem != firstFormElement.elementID)
                            continue;

                        // If this element is the last field, stop sequencing.
                        if (!this.validationService.isNullOrEmpty(lastFormElement) && elem == lastFormElement.elementID)
                            breakIteration = true;

                        // If a match was not found before this, but we have a matching element now, set the flag to true and add the sequenceOrder
                        if (matchAlreadyFound == false && elem == findElement.elementID) {
                            matchAlreadyFound = true;
                            sequenceOrder++;
                            formElements[elem].sequenceOrder = sequenceOrder;
                            formElements[elem].elementSkipTab = sequenceOrder;
                        }
                        // Match was already found, change new sequenceOrder for this element
                        else if (matchAlreadyFound == true && formElements[elem].hasOwnProperty("sequenceOrder") && !isNaN(formElements[elem].sequenceOrder)) {
                            sequenceOrder++;
                            formElements[elem].sequenceOrder = sequenceOrder;
                            formElements[elem].elementSkipTab = sequenceOrder;
                        }
                        // Match not found and the current element doesn't match as well. simply increment the sequenceOrder and continue
                        else if (matchAlreadyFound == false && formElements[elem].hasOwnProperty("sequenceOrder") && !isNaN(formElements[elem].sequenceOrder)) {
                            sequenceOrder++;
                        }
                        ;
                        // Check if the current element in sequence has a customFieldForward. If it does, adjust the position of i so that proper sequencing is followed.
                        if (formElements[elem].hasOwnProperty("customFieldForward") && !this.validationService.isNullOrEmpty(formElements[elem].customFieldForward)) {
                            let nextElementId = formElements[elem].customFieldForward;
                            let nextElementPos = sortElements.map((x: any) => {
                                return x[0];
                            }).indexOf(nextElementId);
                            // If elementPos is not found, try to find the position by adding '_1' to the end of elemFieldForwardID since it might be a window controller field.
                            if (nextElementPos == -1) {
                                nextElementPos = sortElements.map((x: any) => {
                                    return x[0];
                                }).indexOf(nextElementId + "_1");
                            }
                            // Set i to this elementPos - 1(minus 1 because for loop will increment by 1) and proceed.
                            if (!this.validationService.isNullOrEmpty(nextElementPos) && !isNaN(nextElementPos) && nextElementPos >= 0) {
                                i = nextElementPos - 1;
                            }
                        }
                        if (breakIteration == true)
                            break;
                    }
                }
            }
        }
    }

    /**
     * Add form elements for each visible window element
     * @param fieldElement
     * @param newFieldElements
     * @param windowList
     * @param formFieldElements
     * @returns {any}
     */
    addFauxWindowElements(fieldElement: any, newFieldElements: any, windowList: any, formFieldElements: any) {
        if (fieldElement.elementWindowController == null) {
            newFieldElements.push(fieldElement);
        } else {
            let currentController: any = windowList[fieldElement.elementWindowController][0];

            if (fieldElement.elementID == fieldElement.elementWindowController) {
                let currentWindowFields: any = _.sortBy(_.filter(formFieldElements, (element: any) => {
                    return element.elementWindowController == fieldElement.elementWindowController;
                }), 'elementOrder');

                for (let j = 0; j < currentWindowFields.length; j++) {
                    currentWindowFields[j] = this.setElementDefaults(currentWindowFields[j], windowList, formFieldElements);
                }

                for (let i = 0; i < currentController.windowRowCount; i++) {
                    for (let j = 0; j < currentWindowFields.length; j++) {
                        if (currentWindowFields[j].elementID == currentWindowFields[j].elementWindowController) {
                            let checkIt = currentController.windowSequenceNumbers;
                            currentWindowFields[j].hasWindowButton = (checkIt == 'Y');
                            currentWindowFields[j].windowSequenceLength = currentController.windowSequenceLength;
                        }

                        let clone: any = _.cloneDeep(currentWindowFields[j]);
                        clone.elementID += '_' + (i + 1);
                        clone.sanitizedID += '_' + (i + 1);
                        clone.rowIndex = i + 1;
                        clone.windowOrientation = currentController.windowOrientation;

                        // Adjust the column for horizontal windows
                        if (currentController.windowOrientation == Models.WindowOrientation.horizontal) {
                            clone.elementColumn = parseInt(currentWindowFields[j].elementColumn) + (parseInt(currentController.windowRowSeparation) * i);
                        } else {
                            // Adjust the row for vertical windows
                            let windowElementRow = parseInt(clone.elementRow);
                            windowElementRow += (parseInt(currentController.windowRowSeparation) * i);
                            clone.elementRow = windowElementRow;
                        }

                        if (i > 0) {
                            clone.elementPrompts = null;
                        }

                        newFieldElements.push(clone);

                        //See if the new element help define the window's borders
                        if (parseInt(clone.elementRow) < parseInt(currentController.windowTopLeftRow)) {
                            currentController.windowTopLeftRow = clone.elementRow;
                        }

                        if (parseInt(clone.elementColumn) < parseInt(currentController.windowTopLeftCol)) {
                            currentController.windowTopLeftCol = clone.elementColumn;
                        }

                        if (parseInt(clone.elementRow) > parseInt(currentController.windowBotRightRow)) {
                            currentController.windowBotRightRow = clone.elementRow;
                        }

                        let totalWidgets: number = parseInt(clone.elementDetailWidgetCount) + parseInt(clone.elementSpaceWidgetCount) + parseInt(clone.elementValcodeWidgetCount);
                        let cloneRightCol: number = parseInt(clone.elementColumn) + parseInt(clone.elementDisplayWidth);
                        cloneRightCol = parseInt(cloneRightCol.toString()) + totalWidgets;
                        currentController.widgets = totalWidgets + 1;

                        if (parseInt(cloneRightCol.toString()) > parseInt(currentController.windowBotRightCol)) {
                            currentController.windowBotRightCol = cloneRightCol;
                        }
                    }
                }
            }
        }
        return newFieldElements;
    }

    /**
     * Take a list of form element objects (form field, form graphics and form text) and sort them by rows
     * @param formElements
     * @returns {{rowArray: Array, lastRow: number, lastColumn: number, firstFocusElement: string, bottomHeaderRow: number}}
     */
    createRowGroups(formElements: any, windowList: any) {
        // Group the form elements by row
        let groupedFields: any = _.groupBy(formElements, Models.HelperText.elementRow);
        let lastColumn: number = 0;
        let lastColumnPadding: any = 0;
        let lastRow: number = 0;
        let topRow: number = 1000000;
        let firstColumn: any = 1000000;
        let bottomHeaderRow: number = 0;
        //
        let firstFocusElement: string = "";
        let firstFocusOrder: number = 999;
        _.each(formElements, (it: any) => {
            let labelWidth: number = it.labelOffset || 0;
            let outermostColumn: number = parseInt(it.elementColumn) + parseInt(it.elementDisplayWidth) + labelWidth;
            let innermostColumn: number = parseInt(it.elementColumn) + parseInt(it.elementDisplayWidth);
            /*
             Define the first focusable element
             */
            if ((firstFocusOrder > parseInt(it.elementOrder)) && (it.elementType == Models.ElementType.maint)) {
                firstFocusElement = it.sanitizedID;
                firstFocusOrder = parseInt(it.elementOrder);
            }

            if (!isNaN(outermostColumn)) {
                firstColumn = Math.min(firstColumn, innermostColumn);
                lastColumn = Math.max(lastColumn, outermostColumn);
                lastColumnPadding = Math.max(lastColumnPadding, lastColumn - parseInt(it.elementColumn) - parseInt(it.elementDisplayWidth));
            }
            if (it.elementType == Models.ElementType.headerField) {
                bottomHeaderRow = Math.max(bottomHeaderRow, it.elementRow) - 1;
            }
        });

        // Determine the first and last rows
        _.each(formElements, (it: any) => {
            if (it.elementType !== Models.ElementType.phantom) {
                let currentRow: number = parseInt(it.elementRow);
                if (!isNaN(currentRow)) {
                    lastRow = Math.max(lastRow, currentRow);
                    topRow = Math.min(topRow, currentRow);
                }
            }
        });

        // For each column, determine the widest element label, then assign the start column and width for the labels
        _.each(_.groupBy(formElements, Models.HelperText.elementColumn), (value: any, key: any, obj: any) => {
            let longestLabel: number = 0;

            _.each(value, (element: any, index: any, list: any) => {
                // Only offset inline labels, not TOP-oriented labels or template text
                let offsetLabel: boolean = (element.elementID.indexOf(Models.HelperText.promptText) > -1) && (element.elementPromptOrientation !== Models.HelperText.top);
                if (offsetLabel) {
                    let labelOffset: number = element.elementDisplayWidth || 0;
                    longestLabel = Math.max(longestLabel, labelOffset);
                }
            });

            _.each(value, (element: any) => {
                // Assign the start column and width based on the longest label for inline labels
                let offsetLabel: boolean = (element.elementID.indexOf(Models.HelperText.promptText) > -1) && (element.elementPromptOrientation !== Models.HelperText.top);
                if (offsetLabel) {
                    let elementEndColumn: number = formElements[element.elementID].elementColumn;
                    let elementStartColumn: number = elementEndColumn - longestLabel;
                    // Adjust the prompt for the window controller button
                    let controller: any = formElements[element.elementID].elementWindowController;
                    if (controller == undefined) {
                        controller = null;
                    }

                    if (controller !== null) {
                        let window = windowList[controller];
                        if (window != null) {
                            if (window[0].hasWindowButton) {
                                elementStartColumn -= 4;
                            }
                        }
                    }
                    formElements[element.elementID].elementColumn = elementStartColumn;
                    formElements[element.elementID].elementDisplayWidth = longestLabel;
                }
            });
        });

        // See if the last column needs to be padded out
        let paddingDifference: number = parseInt(((lastColumnPadding - firstColumn) / 4).toString());

        if (paddingDifference > 0) {
            lastColumn += paddingDifference;
        }

        _.each(formElements, (it: any) => {
            if (topRow > 1) {
                it.elementRow = parseInt(it.elementRow) - topRow + 1;
            }
        });

        let sortedRowArray: any = [];

        for (let i = 0; i < lastRow; i++) {
            sortedRowArray[i] = null;
        }

        _.each(groupedFields, (it: any, index: any) => {
            let sortedRow: any = _.sortBy(it, (val: any) => {
                return parseInt(val.elementColumn);
            });
            sortedRowArray[index - 1] = _.map(sortedRow, Models.HelperText.elementID);
        });

        _.each(sortedRowArray, (it: any, index: any) => {
            _.each(it, (elementId: any, elementIndex: any, list: any) => {
                let element: any = formElements[elementId];

                if (elementIndex > 0) {
                    let previousField = formElements[list[elementIndex - 1]];
                    element.fieldBuffer = parseInt(element.elementColumn) - parseInt(previousField.elementColumn) - parseInt(previousField.elementDisplayWidth);
                } else {
                    element.fieldBuffer = parseInt(element.elementColumn);
                }

            });

        });

        let _sortedRowObject = {
            rowArray: sortedRowArray,
            lastRow: lastRow,
            lastColumn: lastColumn,
            firstFocusElement: firstFocusElement,
            bottomHeaderRow: bottomHeaderRow
        };

        return _sortedRowObject;
    }

    /**
     * The "jsonized" xml string contains 'i:nil="true"' for null values.  Replace this string with null wherever it occurs.
     * @param obj
     * @returns {any}
     */
    private recursiveNullReplacement(obj: any) {
        if (_.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                obj[i] = this.recursiveNullReplacement(obj[i]);
            }
        } else if (_.isObject(obj)) {
            if (!_.isUndefined(obj[this.nullValue])) {
                return null;
            } else {
                let keys: any = _.keys(obj);
                for (let i = 0; i < keys.length; i++) {
                    if (_.isObject(obj[keys[i]]) || _.isArray(obj[keys[i]])) {
                        let newVal: any = this.recursiveNullReplacement(obj[keys[i]]);

                        if (_.isNull(newVal)) {
                            if (!_.isUndefined(obj[keys[i]][this.nullValue])) {
                                obj[keys[i]] = null;
                            }
                        }
                    }
                }
            }
        }

        return obj;
    }

    /**
     * Create an array of window spec objects
     * @param originalMessage
     * @returns {any|{[p: string]: Window}}
     */
    private createWindowList(originalMessage: any) {
        // Parse out the window specs: windowList tag always generated by S.GENERATE.FORM.SPECS
        // even if form has no windows.
        let windowList: any = originalMessage.form.windowList;
        if (windowList == undefined) {
            windowList = [];
        } else {
            // Clean up the window definitions
            _.each(windowList, (it: any, key: any) => {
                if (_.isPlainObject(it.windowMembers)) {
                    let singleMemberId = it.windowMembers.string;
                    it.windowMembers = [singleMemberId];
                    it.cursor = 0;
                }
                it.windowRowCount = parseInt(it.windowRowCount);
                // Initialize values to be used for rendering window shadows
                it.windowTopLeftCol = 9999;
                it.windowTopLeftRow = 9999;
                it.windowBotRightCol = 0;
                it.windowBotRightRow = 0;
                it.windowControllerSanitized = it.windowController.replace(/\./g, '-');
                it.hasWindowButton = it.windowSequenceNumbers == 'Y';
                it.windowTotalEntries = 0;
                it.windowTotalPages = 0;
                it.windowCurrentPage = 0;

            });
            windowList = _.groupBy(windowList, Models.HelperText.windowController);

        }
        return windowList;
    }

    /**
     * Create an array of form element objects containing Graphics, Template Text and Fields
     * @param originalMessage
     * @param windowList
     * @returns {T[]|_Chain<T>}
     * @private
     */
    private createFormElementsList(originalMessage: any, windowList: any) {
        // Parse out the major form element specifications from the xml: Graphics, Template Text and Fields
        let formGraphicElements: any = this.parseFormObjects(originalMessage.form.formGraphics);
        let formTextElements: any = this.parseFormObjects(originalMessage.form.formLabels);
        let formFieldElements: any = this.parseFormObjects(originalMessage.form.elementList);

        // Create form element objects for the template text
        let formLabelCounter: number = 1;
        _.each(formTextElements, (it: any) => {
            it.elementID = Models.HelperText.formLabel + formLabelCounter++;
            it.elementRow = parseInt(it.formLabelRow);
            it.elementColumn = parseInt(it.formLabelCol);
            let value: string = it.formLabelText.toString().replace(/"/g, '');
            it.values = [value];
            it.elementType = Models.ElementType.label;
            it.sanitizedID = it.elementID.replace(/\./g, '-');

            it.elementDisplayWidth = it.formLabelText.length;
            it.elementJustification = Models.ElementJustification.left;
        });

        // Create element objects for the graphics
        let formGraphicCounter: number = 1;

        for (let i = 0; i < formGraphicElements.length; i++) {
            let it: any = formGraphicElements[i];
            it.elementID = Models.HelperText.formGraphic + formGraphicCounter++;
            it.elementRow = parseInt(it.formGraphicStartRow);
            it.elementColumn = parseInt(it.formGraphicStartCol);
            let value = it.formGraphicType;
            it.values = [value];
            it.graphicType = value;
            it.elementType = Models.ElementType.graphic;
            it.sanitizedID = it.elementID.replace(/\./g, '-');


            if (it.graphicType == 'HL' || it.graphicType == 'LT' || it.graphicType == 'TT' || it.graphicType == 'RT') {
                it.elementDisplayWidth = it.formGraphicSize;
            }

            if (it.graphicType == 'VL') {
                it.elementDisplayHeight = it.formGraphicSize;
                it.elementDetailWidgetCount = parseInt(it.formGraphicDetailWidgetCount);
                it.elementDateWidgetCount = parseInt(it.formGraphicDateWidgetCount);
                it.elementCalculatorWidgetCount = parseInt(it.formGraphicCalculatorWidgetCount);
                it.elementSpaceWidgetCount = parseInt(it.formGraphicSpaceWidgetCount);
                it.elementValcodeWidgetCount = parseInt(it.formGraphicValcodeWidgetCount);
            }

            if (it.graphicType == 'C') {
                it.elementDetailWidgetCount = parseInt(it.formGraphicDetailWidgetCount);
                it.elementDateWidgetCount = parseInt(it.formGraphicDateWidgetCount);
                it.elementCalculatorWidgetCount = parseInt(it.formGraphicCalculatorWidgetCount);
                it.elementSpaceWidgetCount = parseInt(it.formGraphicSpaceWidgetCount);
                it.elementValcodeWidgetCount = parseInt(it.formGraphicValcodeWidgetCount);

                let horizontalLine: any = {};
                Object.assign(horizontalLine, it);
                horizontalLine.elementDisplayWidth = it.formGraphicSize;
                horizontalLine.formGraphicType = 'HL';
                horizontalLine.originalFormGraphicType = 'C';
                formGraphicElements.push(horizontalLine);

                let verticalLine: any = {};
                Object.assign(verticalLine, it);
                verticalLine.elementDisplayHeight = it.formGraphicSize;
                verticalLine.formGraphicType = 'VL';
                verticalLine.originalFormGraphicType = 'C';
                formGraphicElements.push(verticalLine);
            }
        }

        // Process the field elements
        let newFieldElements: any = [];
        _.each(formFieldElements, (fieldElement: any) => {

            fieldElement.elementStorageWidth = fieldElement.elementDisplayWidth;

            // Create form elements for the element prompts in formTextElements
            formTextElements = this.addPromptsAsText(fieldElement, formTextElements, formFieldElements, windowList);

            // Set initial values for element attributes
            fieldElement = this.setElementDefaults(fieldElement, windowList, formFieldElements);

            fieldElement.hasWindowButton = false;
            // Add faux element objects for window elements and remove the base elements from the list of fields
            newFieldElements = this.addFauxWindowElements(fieldElement, newFieldElements, windowList, formFieldElements);
        }); // each formFieldElement

        // Reset the fields list to the union of single valued elements and faux window elements
        formFieldElements = newFieldElements;

        // Create a list of all elements on the form
        let formElements: any = _.union(formFieldElements, formTextElements);
        formElements = _.union(formElements, formGraphicElements);

        // Index the field elements by ID
        formElements = _.keyBy(formElements, Models.HelperText.elementID);

        return formElements;
    }

    /**
     * Create a defined array of spec objects, even if there is only one object
     * @param originalMessageSpecs
     * @returns {any}
     */
    private parseFormObjects(originalMessageSpecs: any) {
        let formObjects = originalMessageSpecs;
        if (formObjects == undefined) {
            formObjects = [];
        }
        // If there is only one spec, convert the single object to an array
        if (_.isPlainObject(formObjects)) {
            formObjects = [formObjects];
        }
        return formObjects;
    }

    /**
     * Add the prompt text for each element as its own form text element object
     * @param fieldElement
     * @param formTextElements
     * @returns {any}
     */
    private addPromptsAsText(fieldElement: any, formTextElements: any, formFieldElements: any, windowList: any) {
        // elementPrompts tag always generated by S.GENERATE.FORM.SPECS, even if no prompts are defined for the field element
        if (fieldElement[Models.HelperText.elementPrompts]) {
            // If there is only one spec, convert the single object to an array
            // Modified on 12/16/2015
            let promptList: any = fieldElement.elementPrompts;
            if (_.isPlainObject(promptList)) {
                promptList = [promptList];
            }

            let maxPromptLength: number = 0;
            // Create static prompt text, which are quoted in the form specs xml, as separate objects.  Skip variable prompts.
            let elementPromptCtr: number = 0;

            _.each(promptList, (it: any) => {
                if ((it.elementPromptText.indexOf('"') > -1) || (it.elementPromptText.indexOf("'") > -1)) {
                    it.elementType = Models.ElementType.label;
                    elementPromptCtr += 1;
                    it.elementID = Models.HelperText.promptText + fieldElement.elementID + "_" + elementPromptCtr;
                    /*
                     Only TOP oriented prompts have elementPromptRow.  Use the element's row if elementPromptRow is not defined.
                     */
                    let promptRow: number = parseInt(it.elementPromptRow);
                    if (promptRow == 0) {
                        promptRow = parseInt(fieldElement.elementRow);
                    }
                    it.elementRow = promptRow;
                    /*
                     Only TOP oriented prompts have elementPromptColumn.  Inline elementPrompts are right-justified,
                     so define the column as the right-most column (element start column minus 1).  Actual start
                     column will depend on the widest vertically aligned prompt, determined below in _createRowGroups
                     */
                    let promptColumn: number = parseInt(it.elementPromptColumn);
                    if (promptColumn == 0) {
                        promptColumn = parseInt(fieldElement.elementColumn) - 1;
                    }
                    it.elementColumn = promptColumn;

                    let value: string = it.elementPromptText.toString().replace(/"/g, '');
                    it.values = [value];
                    it.sanitizedID = it.elementID.replace(/\./g, '-');
                    it.elementDisplayWidth = value != null ? (value.length - Math.floor(value.length / 10)) : 0;
                    formTextElements.push(it);

                    if (it.elementPromptOrientation == Models.HelperText.top) {
                        // Follow the element justification for top-oriented prompts
                        it.elementJustification = fieldElement.elementJustification;
                        // Align the start of the text with the start of the field element
                        it.elementColumn = fieldElement.elementColumn;
                        // If the prompt text is narrower that the field width, use the field width for alignment
                        if ((it.elementJustification == Models.ElementJustification.right)) {
                            let _fieldElementEnd: number = parseInt(fieldElement.elementColumn) + parseInt(fieldElement.elementDisplayWidth);
                            it.elementColumn = _fieldElementEnd - it.elementDisplayWidth;
                        }
                    } else {
                        // Right-justify in-line prompts
                        it.elementJustification = Models.ElementJustification.right;
                    }
                    it.elementWindowController = fieldElement.elementWindowController;
                    if (it.elementWindowController != null && it.elementWindowController != '') {
                        // it.elementDisplayWidth -= 1;
                    }
                    it.elementDateWidgetCount = fieldElement.elementDateWidgetCount;
                    it.elementDetailWidgetCount = fieldElement.elementDetailWidgetCount;
                    it.elementCalculatorWidgetCount = fieldElement.elementCalculatorWidgetCount;
                    it.elementSpaceWidgetCount = fieldElement.elementSpaceWidgetCount;
                    it.elementValcodeWidgetCount = fieldElement.elementValcodeWidgetCount;
                    it.elementInHeader = fieldElement.elementInHeader;
                    fieldElement.labelOffset = maxPromptLength;
                } // static prompt
                else { // var prompt
                    let varPrompt: any = null;
                    for (let elKey in formFieldElements) {
                        if (it.elementPromptText == formFieldElements[elKey].elementID) {
                            varPrompt = formFieldElements[elKey];
                            break;
                        }
                    }

                    if (varPrompt != null && it.elementPromptOrientation == "LEFT") {
                        /*
                         Only TOP oriented prompts have elementPromptRow.  Use the element's row if elementPromptRow is not defined.
                         */
                        let promptRow: number = parseInt(it.elementPromptRow);
                        if (promptRow == 0) {
                            promptRow = parseInt(varPrompt.elementRow);
                        }
                        varPrompt.elementRow = promptRow;
                        /*
                         Only TOP oriented prompts have elementPromptColumn.  Inline elementPrompts are right-justified,
                         so define the column as the right-most column (element start column minus 1).  Actual start
                         column will depend on the widest vertically aligned prompt, determined below in _createRowGroups
                         */
                        let promptColumn: number = parseInt(it.elementPromptColumn);
                        if (promptColumn == 0) {
                            promptColumn = parseInt(fieldElement.elementColumn) - parseInt(varPrompt.elementDisplayWidth) - 1;

                            // if prompt is for a window controller need to subtract button width.
                            if (this.validationService.isNullOrEmpty(fieldElement.elementWindowController) == false) {
                                let window = windowList[fieldElement.elementWindowController];
                                if (window != null) {
                                    if (window[0].hasWindowButton) {
                                        promptColumn -= 4;
                                    }
                                }
                            }
                        }
                        varPrompt.elementColumn = promptColumn;
                    }
                    else if (varPrompt != null && it.elementPromptOrientation == "TOP") {
                        varPrompt.elementDateWidgetCount = fieldElement.elementDateWidgetCount;
                        varPrompt.elementDetailWidgetCount = fieldElement.elementDetailWidgetCount;
                        varPrompt.elementCalculatorWidgetCount = fieldElement.elementCalculatorWidgetCount;
                        varPrompt.elementSpaceWidgetCount = fieldElement.elementSpaceWidgetCount;
                        varPrompt.elementValcodeWidgetCount = fieldElement.elementValcodeWidgetCount;
                        varPrompt.elementInHeader = fieldElement.elementInHeader;
                    }
                }
            });
        }
        return formTextElements;
    }

    /**
     * Set initial values for field element attributes
     * @param fieldElement
     * @param windowList
     * @param formFieldElements
     * @returns {any}
     */
    private setElementDefaults(fieldElement: any, windowList: any, formFieldElements: any) {
        fieldElement.screenReaderText = '';
        // Create a sanitized version of the element ID by swapping dots for dashes
        fieldElement.sanitizedID = fieldElement.elementID.replace(/\./g, '-');

        fieldElement.valCodeSanitizedID = Models.HelperText.val + fieldElement.elementID.replace(/\./g, '-');
        // Create a sanitized version of the window controller ID by swapping dots for dashes
        if (fieldElement.elementWindowController == undefined) {
            fieldElement.windowControllerSanitized = '';
        } else {
            fieldElement.windowControllerSanitized = fieldElement.elementWindowController.replace(/\./g, '-');
        }
        fieldElement.originalElementID = fieldElement.elementID;

        fieldElement.isEnabled = true;

        // Initialize element values
        if (!fieldElement[Models.HelperText.values]) {
            fieldElement.values = [];
            fieldElement.originalValue = "";
            fieldElement.updatedValue = "";
            fieldElement.isFocusedDataChanged = false;
        }
        // Create a boolean attribute for inquiry forms.
        fieldElement.inquiry = (fieldElement.elementType === Models.ElementType.inquiry);
        // Set the tab stop for inquiry fields to remove them from the normal tab sequence
        fieldElement.elementSkipTab = "";
        fieldElement.elementReadOnly = "";
        if (fieldElement.inquiry) {
            fieldElement.elementSkipTab = fieldElement.elementOrder;
            fieldElement.elementReadOnly = Models.HelperText.readonly;
        } else {
            fieldElement.elementSkipTab = fieldElement.elementOrder;
        }

        // Create a boolean for required fields
        fieldElement.required = (fieldElement.elementRequired === 'Y');
        // Make Detailable a boolean
        if (fieldElement.elementDetailable == undefined) {
            fieldElement.elementDetailable = false;
        } else {
            fieldElement.elementDetailable = (fieldElement.elementDetailable == 'Y') || (fieldElement.elementDetailable == true);
        }
        // Create a boolean for LookUp fields
        fieldElement.lookUpField = (fieldElement.elementLookUpFlag === 'Y');

        // Make Calc a boolean
        if (fieldElement.elementCalc == undefined) {
            fieldElement.elementCalc = false;
        } else {
            fieldElement.elementCalc = (fieldElement.elementCalc == 'Y' || fieldElement.elementCalc == true);
        }
        // Make Date a boolean
        if (fieldElement.elementDate == undefined) {
            fieldElement.elementDate = false;
        } else {
            fieldElement.elementDate = (fieldElement.elementDate == 'Y' || fieldElement.elementDate == true);
        }
        if (fieldElement.elementVarPromptFor == undefined) {
            /*
             JAWS announces "required" for required fields.  Accomodate this difference between
             screenReadText and hover text (HTML title attribute).  Don't need these atrtibutes
             for variable prompt fields
             */
            let labelData: string = '';
            let hoverText: boolean = false;
            fieldElement.screenReaderText = this.buildHelperText(fieldElement, labelData, hoverText, windowList, formFieldElements);
            hoverText = true;
            fieldElement.hoverText = this.buildHelperText(fieldElement, labelData, hoverText, windowList, formFieldElements);
            let _iconHover = true;
            fieldElement.iconHover = fieldElement.elementLookUpPrompt

        } else {
            /*
             Specifically label variable prompt elements
             */
            fieldElement.elementType = Models.ElementType.varPrompt;
        } //variable prompt element

        return fieldElement;
    }

    /**
     * Determine the best label for a field element
     * @param labelledElement
     */
    private getFieldLabel(labelledElement: any) {
        return labelledElement.elementHelpLabel != null ? labelledElement.elementHelpLabel.toString().replace(/"/g, '') : "";
    }

    /**
     * Get a sorted array which contains each formElement's ID and it's corresponding elementOrder in an ascending order
     * @param {object} formElements
     * @param {boolean} clearSequenceOrderFlag
     * @return {array} sorted array of form elements in ascending order of elementOrder
     */
    private getSortedElements(formElements: any, clearSequenceOrderFlag?: boolean) {
        // Create an array which contains each formElement's ID and it's corresponding elementOrder
        let sortable = [];

        if (clearSequenceOrderFlag != false)
            formElements = this.clearSequenceOrder(formElements);

        for (let element in formElements) {
            if (!this.validationService.isNullOrEmpty(formElements[element].elementOrder)) {
                sortable.push([formElements[element].elementID, formElements[element].elementOrder]);
            }
        }

        // Sort the array based on elementOrder
        sortable.sort(function (a, b) {
            return a[1] - b[1]
        });

        return sortable;
    }

    /**
     * Clear the sequence order before adding a new sequence order
     * @param formElements
     * @returns {any} formElements with sequenceOrder property removed
     */
    private clearSequenceOrder(formElements: any) {
        for (let element in formElements) {
            if (formElements.hasOwnProperty(element) && formElements[element].hasOwnProperty("sequenceOrder")) {
                delete formElements[element].sequenceOrder;
            }
        }
        return formElements;
    }

    /**
     * Check if any element has been customized to be the first field
     * @param customFormElements
     * @return {string} Field that has been customized to be the first field, if any, otherwise undefined.
     */
    private getCustomFirstField(customFormElements: any): string {
        let customFirstField;
        for (let c = 0; c < customFormElements.length; c++) {
            if (customFormElements[c].hasOwnProperty("ElementFirstField") && customFormElements[c].ElementFirstField == true) {
                customFirstField = customFormElements[c].ElementID;
                break;
            }
        }
        return customFirstField;
    }

    /**
     * Check if any element has been customized to be the last field
     * @param {object} customFormElements
     * @return {string} Field that has been customized to be the last field, if any, otherwise undefined.
     */

    /**
     * Check if any element has been customized to be the last field
     * @param customFormElements
     * @returns {string} Field that has been customized to be the last field, if any, otherwise undefined.
     */
    private getCustomLastField(customFormElements: any): string {
        let customLastField;
        for (let c = 0; c < customFormElements.length; c++) {
            if (customFormElements[c].hasOwnProperty("ElementLastField") && customFormElements[c].ElementLastField == true) {
                customLastField = customFormElements[c].ElementID;
                break;
            }
        }
        return customLastField;
    }

    /**
     * Check any list of elements that have been customized to be inquiry only fields
     * @param {object} customFormElements
     * @return {array} Fields that have been customized to be the inquiry only fields, if any, otherwise empty array.
     */

    /**
     * Check any list of elements that have been customized to be inquiry only fields
     * @param {object} customFormElements
     * @return {array} Fields that have been customized to be the inquiry only fields, if any, otherwise empty array.
     */
    private getCustomInquiryFields(customFormElements: any) {
        let customInquiryFields = [];
        for (let c = 0; c < customFormElements.length; c++) {
            if (customFormElements[c].hasOwnProperty("ElementInquiry") && customFormElements[c].ElementInquiry == true) {
                customInquiryFields.push(customFormElements[c].ElementID);
            }
        }
        return customInquiryFields;
    }

    /**
     * Check any list of elements that have been customized to be no access fields
     * @param {object} customFormElements
     * @return {array} Fields that have been customized to be the no access fields, if any, otherwise empty array.
     */
    private getCustomNoAccessFields(customFormElements: any) {
        let customNoAccessFields = [];
        for (let c = 0; c < customFormElements.length; c++) {
            if (customFormElements[c].hasOwnProperty("ElementNoAccess") && customFormElements[c].ElementNoAccess == true) {
                customNoAccessFields.push(customFormElements[c].ElementID);
            }
        }
        return customNoAccessFields;
    }

    /**
     * Checks if the form element is inquiry
     * @param element
     * @returns {boolean}
     */
    private isElementInquiry(element: any) {
        // -- Default Form Specs
        // 1- If element is not inquiry from default form specs
        // 2- If element is inquiry and it is detailable, it can be added to the sequence order
        // 3- If element is inquiry and has a window controller and the element's own ID matches the window controller's ID (Basically if the first field of a window element is inquiry)
        // Custom Form Specs
        // 1- If element is not inquiry from default form specs
        // 2- If element is inquiry and it is detailable, it can be added to the sequence order
        // 3- If element is inquiry and has a window controller and the element's own ID matches the window controller's ID (Basically if the first field of a window element is inquiry)
        return ((!element.isInquiryField) ||
            (element.isInquiryField && !this.validationService.isNullOrEmpty(element.elementDetailable) && element.elementDetailable == true) ||
            (element.isInquiryField && element.hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(element.elementWindowController) &&
                element.elementID.indexOf('_') > 0 && element.elementID.split('_')[0] == element.elementWindowController)) ||
            (!(element.hasOwnProperty("inquiry") && !this.validationService.isNullOrEmpty(element.inquiry) && element.inquiry == true) ||
                ((element.hasOwnProperty("inquiry") && !this.validationService.isNullOrEmpty(element.inquiry) && element.inquiry == true) && !this.validationService.isNullOrEmpty(element.elementDetailable) && element.elementDetailable == true) ||
                ((element.hasOwnProperty("inquiry") && !this.validationService.isNullOrEmpty(element.inquiry) && element.inquiry == true) && element.hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(element.elementWindowController) &&
                    element.elementID.indexOf('_') > 0 && element.elementID.split('_')[0] == element.elementWindowController));
    }

    /**
     * Gets the first field from formElements collection based on the customFirstField property that was set by CustomFormSpecsMessage in the formElements
     * @param formElements
     * @returns {any}
     */
    private getFormElementsFirstField(formElements: any) {
        for (let ele in formElements) {
            if (formElements[ele].hasOwnProperty("customFirstField") && formElements[ele].customFirstField == true) {
                return formElements[ele];
            }
        }

        return null;
    }

    /**
     * Gets the last field from formElements collection based on the customFirstField property that was set by CustomFormSpecsMessage in the formElements
     * @param formElements
     * @returns {any}
     */
    private getFormElementsLastField(formElements: any) {
        for (let ele in formElements) {
            if (formElements[ele].hasOwnProperty("customLastField") && formElements[ele].customLastField == true) {
                return formElements[ele];
            }
        }

        return null;
    }

    /**
     * Customize field sequence element orders -- Done for window controller fields so that element order is only displayed for the first row
     * @param form
     * @returns {*}
     * @private
     */
    private addCustomFieldSequenceElementOrders(form: any) {
        for (let field in form.fields) {
            // Get the current field in the iteration
            let currentField = form.fields[field];
            // Check if this field has a valid element order
            if (currentField != null && currentField.hasOwnProperty("elementOrder") && !isNaN(currentField.elementOrder)) {
                // Check if this field has a window controller
                if (currentField.hasOwnProperty("elementWindowController") && !this.validationService.isNullOrEmpty(currentField.elementWindowController)) {
                    // If this field has a window controller, check if this field is in the first row
                    if (currentField.hasOwnProperty("elementID") && currentField.elementID.indexOf('_') > 0 && currentField.elementID.split('_')[1] == 1) {
                        // IF the condition succeeds, simply add the original element order as the custom element order.
                        currentField.customElementOrder = currentField.elementOrder;
                    } else {
                        // Condition failed. It means the current field is not in the first row of the window controller. Do not add any custom element order.
                        currentField.customElementOrder = "";
                    }
                }
                // If the field doesn't have a window controller, simply add the original element order as the custom element order.
                else {
                    currentField.customElementOrder = currentField.elementOrder;
                }
            }
        }
        return form;
    }
}