/* Copyright 2016 - 2019 Ellucian Company L.P. and its affiliates. */
var sliderCurrentValue = 100;
var _global = "Global";
var sliderForm = "Form";
var FormService = null;


// set new value when clicks on -/+ icons
function setNewValue(value) {
    var currentValue = getScale();
    newValue = currentValue + value;
    setScale(newValue);
    scaleContent((Math.round(130 + 70 - newValue) / 100).toString());
    //Max is 130 and min 70 when user clicks +/- icons should decrease less than 70 and should not increase more than 130
    var resetValue = 130 + 70 - newValue > 70 ? (130 + 70 - newValue) < 130 ? 130 + 70 - newValue : 130 : 70;
    callFormService(resetValue, sliderForm);
    FormService.setLocalStorageSliderSetting(resetValue);
};

function setSliderValue(newValue) {
    // Reset the Form Scale Local Storage to new value 
    callFormService(currentValue, sliderForm);
};


function setSliderForGlobal() {
    // set Global value for slider        	
    var newValue = getScale();
    callFormService(newValue, _global);
};

function callFormService(newValue, methodName) {

    if (FormService == null) FormService = window.formService;

    if (methodName == _global) {
        // set Global value for slider
        FormService.setSliderForGlobal(newValue);
    } else {
        // set for individual sliderForm
        FormService.setSliderSetting(newValue);
    }

}

function setLocalStorageSliderSetting(newValue){
    FormService.setLocalStorageSliderSetting(newValue);
}

function setScale(value) {
    $('#slider').slider('setValue', value);
}

function getScale() {
    return $("#slider").data('slider').getValue();
}
// when user opens the sliderForm should load the slider value from local storage /default
function setSliderForIndividualForm(value) {
    setScale(130 + 70 - value);
    return scaleContent((Math.round(value) / 100).toString());
};

//Set default value when clicks on seve/cancel/saveall/cancel all button  and no forms active
function setDefaultValue(value) {
    setScale(value);
};

function scaleContent(newScale) {
    if (newScale < 0.70 || newScale > 1.30) {
        return;
    }
    var $content = $("#ui-form");
    var scaleString = "scale(" + newScale + ")";
    var $title = $(".form-title-area");

    if ($title[0] == null || $title.length == 0) return false;

    var $headerShadow = $(".form-head-shadow");
    var scaleXString = "scaleX(" + newScale + ")";
    $content.css({
        '-webkit-transform': scaleString,
        '-webkit-transform-origin': '0 0',
        '-ms-transform': scaleString,
        '-ms-transform-origin': '0 0',
        '-moz-transform': scaleString,
        '-moz-transform-origin': '0 0',
        '-o-transform': scaleString,
        '-o-transform-origin': '0 0',
        'transform': scaleString,
        'transform-origin': '0 0',
    });
    //$title.css({
    //    'width': $content[0].getBoundingClientRect().width
    //});
    if (newScale >= 1) {
        $headerShadow.css({
            'width': ($title[0].getBoundingClientRect().width) * newScale
        });
    } else {
        $headerShadow.css({
            'width': ($title[0].getBoundingClientRect().width) / newScale
        });
    }
    scale = newScale;

    $content = null;
    $title = null;
    $headerShadow = null;
    scaleXString = null;
    scaleString = null;
    return true;
}