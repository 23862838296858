/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventsService, FocusService, FormService, HelperService, PromptService, RootScopeService, ServerCommandService, ValidationService, WindowControlService } from "../../../services"
import * as Models from '../../../models'

@Component({
    selector: 'calculator',
    template: require('./calculator.component.html')
})
export class CalculatorComponent implements OnInit, OnDestroy {
    /* Region A --- custom attributes for the component*/
    @Input() element: any;
    /* End Region A */

    /* Region B --- event subscription identifiers */
    launchCalculatorEventId: string = "";
    closeCalculatorEventId: string = "";
    /* End Region B */

    /* Region C --- local properties */
    expression: string = "0";
    showCalculator: boolean = false;
    prefixId: string = "calcModal_";
    expressionId: string = "expression";
    alignLeft: boolean = false;
    alignTop: boolean = false;
    /* End Region C */

    //CTOR
    constructor(private serverCommandService: ServerCommandService, private validationService: ValidationService, private ng4WindowControlService: WindowControlService,
        private formService: FormService, private notificationsService: PromptService,
        private focusService: FocusService, private helperService: HelperService, private eventsService: EventsService, private rootScopeService: RootScopeService) { }

    /* Region D --- life cycle events */
    ngOnInit() {
        this.launchCalculatorEventId = this.eventsService.on(Models.EventConstants.launchCalculator, [this.launchCalculator, this]);
        this.closeCalculatorEventId = this.eventsService.on(Models.EventConstants.closeCalculator, [this.closeCalculator, this]);

        // calculate left and top to position the calculator modal.
        this.alignLeft = (this.element.elementColumn + this.element.elementDisplayWidth) > 50;
        this.alignTop = this.element.elementRow > 15;
    }

    ngOnDestroy() {
        this.eventsService.destroy(Models.EventConstants.launchCalculator, this.launchCalculatorEventId);
        this.eventsService.destroy(Models.EventConstants.closeCalculator, this.closeCalculatorEventId);
    }
    /* End Region D */

    /* Region E --- event listeners */
    // Launch Calculator Event Listener.
    // Mainly used when calculator needs to be opened when down arrow is pressed on the input.
    launchCalculator(calculatorComponent: any, id: any) {
        let self: CalculatorComponent = null;
        self = <CalculatorComponent>calculatorComponent;

        //Check if args (published id) is equal to element's sanitized id.
        if (self.element.sanitizedID == id) {
            // display calculator
            self.showCalculator = true;

            // Focus the element used for displaying the expression.
            setTimeout(() => {
                self.focusExpression();
            }, 250);

            $(document).on("mouseup", self, self.mouseuphandler);
        }
    }

    mouseuphandler(e: any) {
        let self: CalculatorComponent = null;
        self = <CalculatorComponent>e.data;
        let container = $("#calcModal_" + self.element.sanitizedID);
        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            self.closeCalculator(self);
        }
        container = null;
    }


    // Close Calculator Event Listener
    // Mainly used when clicked outside the calculator.
    closeCalculator(calculatorComponent: any) {
        let self = <CalculatorComponent>calculatorComponent;

        // Check if calculator is already open.
        if (self.showCalculator == true) {
            self.clear();
            self.showCalculator = false;

            // cleanup the event handler
            $(document).off("mouseup", self.mouseuphandler);

            //focus the field focused before the opening the calculator.
            setTimeout(() => {
                document.getElementById(self.element.sanitizedID).focus();
            }, 500);

        }

    }

    /* End Region E */

    // Removes the last expression update.
    clearLast() {
        this.expression = this.expression.trim();
        this.expression = this.expression.substring(0, this.expression.length - 1);
        this.expression = this.expression.trim();
    }


    // Output changed event to control the max length of the output.
    outputChanged(newValue: string) {
        if (newValue.length <= 20) this.expression = newValue;
    }

    // Append to the existing expression to evaluate.
    appendExpression(exp: string) {
        if (this.expression == "0") {
            this.expression = exp;
        } else {
            if (this.expression.length < 20) this.expression += exp;
        }
    };


    // Keydown for accessibility
    keyDown($event: any) {
        let keyEvent = $event;
        let keyCode = keyEvent.keyCode;
        let key = $event.key;

        if (this.expression.length >= 20 && key != "Backspace" && key != "Delete" && key != "Escape") return;

        if (key == "1" || key == "2" || key == "3" || key == "4" || key == "5" || key == "6" || key == "7" || key == "8" || key == "9" ||
            key == "0" || key == "." || key.toLowerCase() == 'decimal') {
            if (key.toLowerCase() == 'decimal') {
                key = '.';
            }
            this.appendExpression(key);
        }
        else if (key == '+' || key.toLowerCase() == 'add') {
            this.appendExpression(" + ");
        }
        else if (key == '-' || key.toLowerCase() == 'subtract') {
            this.appendExpression(" - ");
        }
        else if (key == '*' || key.toLowerCase() == 'multiply') {
            this.appendExpression(" * ");
        }
        else if (key == '/' || key.toLowerCase() == 'divide') {
            this.appendExpression(" / ");
        }
        else if (key == '%') {
            this.appendExpression(" % ");
        }
        else if (key == '=') {
            setTimeout(() => {
                this.evaluate();
            }, 100)
        }
        else if (key == "Backspace") {
            this.clearLast();
        }
        else if (key == "Delete") {
            this.expression = "0";
        }
        else if (key == "Escape") {
            this.showCalculator = false;
            this.focusService.focusOn(this.element.sanitizedID, true, true);
            $event.stopPropagation();
        }
    }


    // Evaluate the expression.
    evaluate() {
        if (this.expression) {
            //replace % with equivalent mathematical expression
            this.expression = this.expression.replace(new RegExp('%', 'g'), '/100*');
        }
        else {
            this.expression = "0";
        }

        try {
            // this.expression = eval(this.expression);
            this.expression = (new Function('return ' +this.expression)());
            this.useCalcResult();
            this.showCalculator = false;
        }
        catch (e) {
            let errorMessage: any = {};
            errorMessage.title = "Error";
            let msg = "Error evaluating the expression.";
            errorMessage.text = [msg];
            errorMessage.defaultCallbackNumber = 0;
            errorMessage.buttons = [
                {
                    label: Models.LabelConstants.ok,
                    callback: (param: any) => {
                        let self = <CalculatorComponent>param;
                        self.notificationsService.popupMessageDismiss(() => {
                            self.showCalculator = true;
                            setTimeout(() => {
                                self.focusExpression();
                            }, 100);
                        }, false);
                    },
                    callbackArgs: this,
                    title: ""
                }
            ];
            this.notificationsService.popupMessageShow(errorMessage);
        }
    };

    // clear the existing expression.
    clear() {
        this.expression = "0";
    };

    // use the calculated result and send the field update message.
    useCalcResult() {
        let currentWindowRow;
        let currentWindowElement = this.element.elementID;

        if (!this.validationService.isNullOrEmpty(this.element.elementWindowController)) {
            let arrayElement = (this.element.elementID).split('_');
            currentWindowElement = arrayElement[0];
            currentWindowRow = this.formService.getCurrentWindowRow(arrayElement[1], this.element.elementWindowController);
        }

        // copy the calculated expression to element.
        this.element.text = this.expression;

        let jumpCommand: any = Models.CommandConstants.fieldJump;

        if (this.rootScopeService.lastPromptedField == this.element.elementID || this.rootScopeService.lastFieldPromptMessageId == this.element.elementID) {
            jumpCommand = Models.CommandConstants.ignoreFieldJump;
        }

        this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate], ["", currentWindowElement], ["", this.element.text],
            [currentWindowElement, ""], [this.getAbsoluteWindowRow(), currentWindowRow], ["", ""]);
        // send server command.
        //this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldUpdate, currentWindowElement, this.element.values[0], "", currentWindowRow, "");

        // reset the expression for the next use.
        this.expression = "0";
        document.getElementById(this.element.sanitizedID).focus();
    };

    // close the open calculator when tabbed from '=' or shift tabbed from 'expression'.
    close($event: any, shift: boolean) {
        if ($event == null || ($event && $event.keyCode == 9 && $event.shiftKey == shift)) {
            if ($event) $event.preventDefault();
            this.closeCalculator(this);
        }
    }

    // focus the expression when calculator is set to open.
    focusExpression() {
        this.focusService.focusOn("#" + this.prefixId + this.element.sanitizedID + ' #' + this.expressionId);
    }

    /**
    * Get Absolute Window Row Index based on window page number
    */
    private getAbsoluteWindowRow() {
        if (!this.validationService.isNullOrEmpty(this.element.elementWindowController)) {
            return this.ng4WindowControlService.getWindowPosition(parseInt(this.element.elementID.split("_")[1]), this.element.elementWindowController);
        }
        else {
            return null;
        }
    }
}
