/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import {
    ConfigurationService,
    EventsService,
    FocusService,
    HelperService,
    HelpService,
    UiLocalStorageService,
    RootScopeService,
    SessionService,
    LoginService,
    ValidationService
} from "./";
import * as Models from "../models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as _ from 'lodash';

declare var less: any;
declare var $: any;
declare var adminSelectedEmailClient: any;
declare var themeEditorParameters: any;

@Injectable()
export class PreferencesService {
    // dialogPreferences contains all the currently changed settings in preferences dialog
    dialogPreferences: any = {};
    // savedPreferences contains a snapshot of the preferences that were loaded initially or last saved.
    savedPreferences: any = {};
    themesLoaded: boolean = false;
    isOpen: boolean = false;
    emailClientOptions: any = {
        "0": "Always Ask",
        "1": "Desktop",
        "2": "Web Based",
    }

    // Default values for all fields in the preferences dialog.
    private defaults: any = {
        ColorTheme: "ELLUCIAN",
        FormBackground: "MEDIUM",
        ShowPersonImages: true,
        CardSearchResults: 8,
        GridSearchResults: 20,
        CloseBarGraph: false,
        PlaySound: false,
        VerboseAccess: false,
        FullPageView: true,
        primaryColor: "PRIMARY COLOR",
        preference: "Preferences",
        preferenceHeaderText: "Personal appearance settings. Changes apply to your user only.",
        restoreDefaultColor: "Restore Default Color",
        miscellaneous: "MISCELLANEOUS",
        searchResultsNo: "Number of Search Results Per Page:",
        cardView: "Card View",
        gridView: "Grid View",
        closeBarGraph: "Automatically Close Bar Graph When Finished",
        errorSound: "Play Error Sound on Error Messages",
        verbose: "Verbose Accessibility (screen reader)",
        reportBrowser: "Show Full Page Report Browser View",
        personPhoto: "Display Person Photos: ",
        note: "Note: Your institution may have permanently disabled photos in certain areas, so this setting might not be changeable or have the direct effect. Contact your system administrator for more details.",
        restoreDefaults: "Restore Defaults",
        UserPreferredEmailClient: adminSelectedEmailClient
    }

    /**
     * Constructor for Preferences Dialog
     * @param helperService 
     * @param http 
     * @param configurationService 
     * @param rootScopeService 
     * @param helpService 
     * @param focusService 
     * @param uiLocalStorageService 
     * @param sessionService 
     * @param loginService 
     * @param eventsService 
     * @param validationService 
     */
    constructor(private helperService: HelperService, private http: HttpClient, private configurationService: ConfigurationService,
        private rootScopeService: RootScopeService, private helpService: HelpService,
        private focusService: FocusService, private uiLocalStorageService: UiLocalStorageService,
        private sessionService: SessionService, private loginService: LoginService, private eventsService: EventsService,
        private validationService: ValidationService) {
        this.reset();
        this.eventsService.on("backupLocalStorage", [this.backupLocalStorageListener, this]);
    }

    /**
     * Reset to default preferences
     */
    resetToDefaults() {
        this.loadDefaults();
        this.checkForChanges();
    }

    /**
     * Load all default values into dialogPreferences object. This object is used by other components or services to change any values in Preferences Dialog.
     */
    loadDefaults() {
        this.dialogPreferences.ColorTheme = this.defaults.ColorTheme;
        this.dialogPreferences.FormBackground = this.defaults.FormBackground;
        this.dialogPreferences.ShowPersonImages = (this.defaults.ShowPersonImages) ? "YES" : "NO";
        this.dialogPreferences.CardSearchResults = this.defaults.CardSearchResults;
        this.dialogPreferences.GridSearchResults = this.defaults.GridSearchResults;
        this.dialogPreferences.CloseBarGraph = this.defaults.CloseBarGraph;
        this.dialogPreferences.PlaySound = this.defaults.PlaySound;
        this.dialogPreferences.VerboseAccess = this.defaults.VerboseAccess;
        this.dialogPreferences.FullPageView = this.defaults.FullPageView;
        this.dialogPreferences.primaryColor = this.defaults.primaryColor;
        this.dialogPreferences.preference = this.defaults.preference;
        this.dialogPreferences.preferenceHeaderText = this.defaults.preferenceHeaderText;
        this.dialogPreferences.restoreDefaultColor = this.defaults.restoreDefaultColor;
        this.dialogPreferences.miscellaneous = this.defaults.miscellaneous;
        this.dialogPreferences.searchResultsNo = this.defaults.searchResultsNo;
        this.dialogPreferences.cardView = this.defaults.cardView;
        this.dialogPreferences.gridView = this.defaults.gridView;
        this.dialogPreferences.closeBarGraph = this.defaults.closeBarGraph;
        this.dialogPreferences.errorSound = this.defaults.errorSound;
        this.dialogPreferences.verbose = this.defaults.verbose;
        this.dialogPreferences.reportBrowser = this.defaults.reportBrowser;
        this.dialogPreferences.personPhoto = this.defaults.personPhoto;
        this.dialogPreferences.note = this.defaults.note;
        this.dialogPreferences.restoreDefaults = this.defaults.restoreDefaults;
        this.dialogPreferences.UserPreferredEmailClient = parseInt(this.defaults.UserPreferredEmailClient);
    }

    /**
     * Restore Default settings in Preferences when Preferences Service initiates. This method gets invoked the first time the service is loaded.
     */
    reset() {
        this.loadDefaults();
        this.savedPreferences = {};
        this.themesLoaded = false;
        this.rootScopeService.preferences = {
            ColorTheme: "ELLUCIAN",
            FormBackground: "MEDIUM",
            CloseBarGraph: undefined,
            PlaySound: undefined,
            VerboseAccess: undefined,
            FullPageView: undefined,
            ShowPersonImages: undefined,
            ThemeSettings: undefined,
            AutoCloseContexts: undefined,
            CardSearchResults: 8,
            GridSearchResults: 20
        }
    }

    /**
     * Activate Preferences and store values in RootScope Preferences
     * @param preference 
     */
    activatePreferences(preference: any = null) {
        this.rootScopeService.preferences.ColorTheme = this.savedPreferences.ColorTheme;
        this.rootScopeService.preferences.FormBackground = this.savedPreferences.FormBackground;
        this.rootScopeService.preferences.CardSearchResults = this.savedPreferences.CardSearchResults;
        this.rootScopeService.preferences.GridSearchResults = this.savedPreferences.GridSearchResults;
        this.rootScopeService.preferences.CloseBarGraph = this.savedPreferences.CloseBarGraph;
        this.rootScopeService.preferences.PlaySound = this.savedPreferences.PlaySound;
        this.rootScopeService.preferences.VerboseAccess = this.savedPreferences.VerboseAccess;
        //console.warn(this.rootScopeService.preferences.VerboseAccess);
        this.rootScopeService.preferences.FullPageView = this.savedPreferences.FullPageView;
        this.rootScopeService.preferences.PersonSearchAllowed = this.savedPreferences.PersonSearchAllowed;
        // Person images only show if both account wide and individual setting both say true
        this.rootScopeService.preferences.ShowPersonImages = this.savedPreferences.ShowPersonImages;
        // Get color settings for this theme
        var themeSettings = this.savedPreferences.ThemeSettings[this.savedPreferences.ColorTheme];
        if (themeSettings === undefined) {

        } else {
            // Settings already cached locally
            this.rootScopeService.preferences.ThemeSettings = themeSettings;
        }
        // change themecolor file 
        if (this.savedPreferences.ColorTheme) {
            var color = this.savedPreferences.ColorTheme.toLowerCase();
            if (color === "red") {
                this.changeTheme("red", preference);
            }
            else if (color === "green") {
                this.changeTheme("green", preference);
            }
            else if (color === "orange") {
                this.changeTheme("orange", preference);
            }
            else if (color === "black") {
                this.changeTheme("black", preference);
            }
            else if (color === "blue") {
                this.changeTheme("blue", preference);
            }
            else if (color === "brown") {
                this.changeTheme("brown", preference);
            }
            else if (color === "purple") {
                this.changeTheme("purple", preference);
            }
            else if (color === "highcont") {
                this.changeTheme("highcont");
            }
            else {
                this.changeTheme("ellucian", preference);
            }

            if (themeEditorParameters && themeEditorParameters.hasOwnProperty("optIn") && themeEditorParameters.optIn === true) {
                // If color is ellucian (university default), modify all variables
                if (color === "ellucian") {
                    less.modifyVars(this.rootScopeService.themeEditorLessVariables);
                }
                else {
                    // For any other colors, modify all variables except primary color
                    let copyOfLessVariables = Object.assign({}, this.rootScopeService.themeEditorLessVariables);
                    delete copyOfLessVariables['@primary-color'];
                    delete copyOfLessVariables['@primary-color-focus'];
                    delete copyOfLessVariables['@primary-color-light'];
                    less.modifyVars(copyOfLessVariables);
                }
            }
        }
    }


    changeTheme(newTheme: any, prefrenceChange: any = null) {
        var file = "../content/" + newTheme + ".less";
        document.getElementById("theme").setAttribute("href", file);
        if (prefrenceChange) {
            less.refresh();
        }
        else {
            less.refresh();
        }
        setTimeout(() => {
            this.removeTheme(newTheme);
        }, 250);
    }

    // Remove
    removeTheme(newTheme: any) {
        let temp: any = $("style[id^='less:']");
        let possibleColors: any = ["ellucian", "purple", "red", "green", "orange", "black", "blue", "brown", "highcont"];
        _.each(temp, (style: any) => {
            let isValidThemeStyle: boolean = false;
            _.each(possibleColors, (color: any) => {
                if (style.id.indexOf(color) >= 0 && style.id.indexOf(newTheme) <= 0) {
                    style.remove();
                }
            });
        });
    }

    /**
     * Initialize Preferences. Requests Preferences data from App Server, restores data in local storage and then stores data in savedPreferences object.
     * If no Local Storage data is found, broadcasts a backupLocalStorage message so that user's local storage data can be backed up to the App Server.
     */
    init() {
        // Start off with default settings for everything
        this.savedPreferences.ThemeSettings = {};

        // Make default preferences active for this session
        this.activatePreferences();

        // Get locally stored settings
        if (this.uiLocalStorageService.get(Models.LocalStorageConstants.checked) !== undefined) {
            this.savedPreferences.CloseBarGraph = (this.uiLocalStorageService.get(Models.LocalStorageConstants.checked) === "Y");
        }
        if (this.uiLocalStorageService.get(Models.LocalStorageConstants.playSound) !== undefined) {
            this.savedPreferences.PlaySound = (this.uiLocalStorageService.get(Models.LocalStorageConstants.playSound) === "Y");
        }
        if (this.uiLocalStorageService.get(Models.LocalStorageConstants.verboseAccess) !== undefined) {
            this.savedPreferences.VerboseAccess = (this.uiLocalStorageService.get(Models.LocalStorageConstants.verboseAccess) === "Y");
        }

        // initialize saved preferences for preferred email client to the admin selected value
        this.savedPreferences.UserPreferredEmailClient = this.defaults.UserPreferredEmailClient;

        this.savedPreferences.FullPageView = this.uiLocalStorageService.get(Models.LocalStorageConstants.reportBrowserPageView) !== undefined ? (this.uiLocalStorageService.get(Models.LocalStorageConstants.reportBrowserPageView) === "Y") : false;

        this.rootScopeService.preferences.AutoCloseContexts = (this.uiLocalStorageService.get('CCX') === "Y") ? true : false;

        // Construct request for server preferences
        let retrievePreferencesRequest: any = {
            OperatorID: this.sessionService.getOperatorId()
        };

        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'preferences', this.sessionService.getToken(), this.sessionService.getControlId()].join('/');

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        this.http.post(url, retrievePreferencesRequest, httpOptions).timeoutWith(30000, Observable.throwError({
            type: 'LOAD',
            msg: 'Preferences request timed out.'
        })).subscribe(
            (response: any) => {
                // let data: any = JSON.parse(response._body);
                if (response['Errors'] && response.Errors.length > 0) {
                    // there was an error subset in the dmi response. Log it to console and halt the session
                    let errorMessages = '';
                    _.each(response.Errors, (it: any) => {
                        errorMessages += it.ErrorMessageText;
                    });
                    console.error(errorMessages);
                    let error: any = {};
                    error.type = 'LOAD';
                    error.msg = 'Preferences failed to load.';
                    this.loginService.handleLoginErrors(error);
                } else {
                    try {
                        // Restore local storage with data from app server
                        if (response.UserPreferences.hasOwnProperty("LocalStorage") && !this.validationService.isNullOrEmpty(response.UserPreferences.LocalStorage)) {
                            this.restoreLocalStorage(response.UserPreferences);

                            // Reset the locally stored settings
                            if (this.uiLocalStorageService.get(Models.LocalStorageConstants.checked) !== undefined) {
                                this.savedPreferences.CloseBarGraph = (this.uiLocalStorageService.get(Models.LocalStorageConstants.checked) === "Y");
                            }
                            if (this.uiLocalStorageService.get(Models.LocalStorageConstants.playSound) !== undefined) {
                                this.savedPreferences.PlaySound = (this.uiLocalStorageService.get(Models.LocalStorageConstants.playSound) === "Y");
                            }
                            if (this.uiLocalStorageService.get(Models.LocalStorageConstants.verboseAccess) !== undefined) {
                                this.savedPreferences.VerboseAccess = (this.uiLocalStorageService.get(Models.LocalStorageConstants.verboseAccess) === "Y");
                            }

                            // Reset the preferred email client in savedPreferences to the value found in local storage
                            if (this.uiLocalStorageService.get(Models.LocalStorageConstants.userPreferredEmailClient) !== undefined) {
                                this.savedPreferences.UserPreferredEmailClient = parseInt(this.uiLocalStorageService.get(Models.LocalStorageConstants.userPreferredEmailClient));
                            }
                            /* If no local storage value was found for userPreferredEmailClient, add this value to the local storage. This will trigger a backup of preferences to the App server. 
                                Therefore, add a timeout so that it does not interfere with the existing response processing. */
                            else {
                                setTimeout(() => {
                                    this.uiLocalStorageService.set(Models.LocalStorageConstants.userPreferredEmailClient, this.savedPreferences.UserPreferredEmailClient);
                                }, 500);
                            }

                            this.savedPreferences.FullPageView = this.uiLocalStorageService.get(Models.LocalStorageConstants.reportBrowserPageView) !== undefined ? (this.uiLocalStorageService.get(Models.LocalStorageConstants.reportBrowserPageView) === "Y") : false;

                            this.rootScopeService.preferences.AutoCloseContexts = (this.uiLocalStorageService.get('CCX') === "Y") ? true : false;
                        }
                        else {
                            // Backup local storage data in the app server. Do this asynchronously because PreferencesService init doesn't have to wait for this backup
                            this.eventsService.broadcast("backupLocalStorage");
                        }

                        // Save copy of current server settings
                        _.each(response.UserPreferences, (value: any, key: any) => {
                            this.savedPreferences[key] = value;
                        });

                        // Always set RecordDeleteAllowed to the value sent from the server in preferences. This is based on the security class definition.
                        this.rootScopeService.preferences.RecordDeleteAllowed = response.UserPreferences.hasOwnProperty("RecordDeleteAllowed") && !this.validationService.isNullOrEmpty(response.UserPreferences.RecordDeleteAllowed) ? response.UserPreferences.RecordDeleteAllowed : false;

                        if (this.savedPreferences.FormBackground.toUpperCase() == "TRUE" || this.savedPreferences.FormBackground.toLowerCase() == "FALSE") {
                            this.savedPreferences.FormBackground = "MEDIUM";
                        }

                        if (["ellucian", "purple", "red", "green", "orange", "black", "blue", "brown", "highcont"].indexOf(this.savedPreferences.ColorTheme.toLowerCase()) == -1) {
                            this.savedPreferences.ColorTheme = "ELLUCIAN";
                        }

                        // Make server preferences active for this session
                        this.activatePreferences();
                        this.resetToSavedPreferences();
                        this.eventsService.broadcast("preferencesLoaded");
                        this.rootScopeService.arePreferencesLoadedFlag = true;

                        // Currently logged in user's Colleague Person ID stored in session for colleague attach.
                        if (response.UserPreferences.hasOwnProperty('PersonID') && response.UserPreferences.PersonID) {
                            this.sessionService.session["currentPersonId"] = response.UserPreferences.PersonID;
                        }
                    }
                    catch (err) {
                        // there was an error during processing for the preferences response. Log the error to console and halt the session
                        let error: any = {};
                        error.type = 'LOAD';
                        if (err.name === 'ParseError') {
                            error.msg = err.message;
                        } else {
                            error.msg = 'Stored Preferences failed after load.';
                        }
                        this.loginService.handleLoginErrors(error);
                    }
                }
            }, (error: any) => {
                // an error occurred during the http request. log it to the console and halt the session
                console.error(error);
                error.name = 'LOAD';
                error.msg = 'An unexpected error occurred while loading Preferences.'
                this.loginService.handleLoginErrors(error);
            });
    }

    /**
     * Opens the preferences dialog programmatically.
     */
    getPrefrences() {
        // Apply ETE styles, except for Primary Color, before opening preferences
        if (this.rootScopeService.useThemeEditor === true) {
            let copyOfLessVariables = Object.assign({}, this.rootScopeService.themeEditorLessVariables);
            delete copyOfLessVariables['@primary-color'];
            delete copyOfLessVariables['@primary-color-focus'];
            delete copyOfLessVariables['@primary-color-light'];
            less.modifyVars(copyOfLessVariables);
        }

        this.isOpen = true;
        $('#preferences .modal').modal('show').draggable();
        this.rootScopeService.FavActiveHelp = false;
        setTimeout(() => {
            document.getElementById('btnPreferencesHelp').focus();
        }, 250);
        return true;
    }

    /**
     * Check if any values have changed in the Preferences dialog.
     */
    checkForChanges() {
        var anyChanges = false;
        this.dialogPreferences.CardSearchResults = parseInt(this.dialogPreferences.CardSearchResults);
        this.dialogPreferences.GridSearchResults = parseInt(this.dialogPreferences.GridSearchResults);
        this.dialogPreferences.UserPreferredEmailClient = parseInt(this.dialogPreferences.UserPreferredEmailClient);

        anyChanges = anyChanges || (this.savedPreferences.ColorTheme !== this.dialogPreferences.ColorTheme);
        anyChanges = anyChanges || (this.savedPreferences.FormBackground !== this.dialogPreferences.FormBackground);
        var showImages = (this.dialogPreferences.ShowPersonImages === "YES") ? true : false;
        anyChanges = anyChanges || (this.savedPreferences.ShowPersonImages !== showImages);
        anyChanges = anyChanges || (this.savedPreferences.CardSearchResults !== this.dialogPreferences.CardSearchResults);
        anyChanges = anyChanges || (this.savedPreferences.GridSearchResults !== this.dialogPreferences.GridSearchResults);
        anyChanges = anyChanges || (this.savedPreferences.CloseBarGraph !== this.dialogPreferences.CloseBarGraph);
        anyChanges = anyChanges || (this.savedPreferences.PlaySound !== this.dialogPreferences.PlaySound);
        anyChanges = anyChanges || (this.savedPreferences.VerboseAccess !== this.dialogPreferences.VerboseAccess);
        anyChanges = anyChanges || (this.savedPreferences.FullPageView !== this.dialogPreferences.FullPageView);
        anyChanges = anyChanges || (this.savedPreferences.UserPreferredEmailClient !== this.dialogPreferences.UserPreferredEmailClient);
        this.dialogPreferences.Changed = anyChanges;
    }

    /**
     * Reset dialogPreferences to the savedPreference dialog.
     */
    resetToSavedPreferences() {
        this.dialogPreferences.ColorThemes = this.savedPreferences.ColorThemes;
        this.dialogPreferences.ColorTheme = this.savedPreferences.ColorTheme;
        this.dialogPreferences.FormBackground = this.savedPreferences.FormBackground;
        this.dialogPreferences.ShowPersonImages = (this.savedPreferences.ShowPersonImages) ? "YES" : "NO";
        this.dialogPreferences.CardSearchResults = this.savedPreferences.CardSearchResults;
        this.dialogPreferences.GridSearchResults = this.savedPreferences.GridSearchResults;
        this.dialogPreferences.CloseBarGraph = this.savedPreferences.CloseBarGraph;
        this.dialogPreferences.PlaySound = this.savedPreferences.PlaySound;
        this.dialogPreferences.VerboseAccess = this.savedPreferences.VerboseAccess;
        this.dialogPreferences.FullPageView = this.savedPreferences.FullPageView;
        this.dialogPreferences.Changed = false;
        this.dialogPreferences.UserPreferredEmailClient = this.savedPreferences.UserPreferredEmailClient;
    }

    /**
     * Close the Preferences dialog.
     */
    close() {
        this.isOpen = false;
        $('#preferences .modal').modal('hide');
        this.focusService.focusSearch();
    }

    /**
     * Display Preferences Help
     */
    help() {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses.Preferences);
    }

    /**
     * Restore Default Color 
     */
    restoreThemeColorDefault() {
        this.dialogPreferences.ColorTheme = this.defaults.ColorTheme;
    }

    /**
     * OK button click Handler for Preference Dialog.
     */
    ok() {
        this.apply(true);
        this.close();
    };

    /**
     * Save the current dialog values as the permanent settings and activate them
     * @param preference 
     */
    apply(preference: any) {
        // No need to do anything if no settings changed
        if (!this.dialogPreferences.Changed && this.dialogPreferences.ColorTheme == this.savedPreferences.ColorTheme) return;
        // Permanently save settings
        this.save();
        // Activate current preferences
        this.activatePreferences(preference);
    }

    /**
     * Cancel button click handler for Preferences Dialog
     */
    cancelPreference() {
        this.close();
        this.resetToSavedPreferences();
    };

    validApps() {
        return this.savedPreferences.ValidApplications;
    };

    /**
     * Message listener service for backing up local storage data every time a value is added, updated or removed from local storage
     * @param service
     * @param message
     */
    private backupLocalStorageListener(service: any, message: any) {
        let self = <PreferencesService>service;
        self.backupLocalStorage();
    }

    /**
     * Restore local storage with all the properties that are sent from the app server
     * @param userPreferences
     */
    private restoreLocalStorage(userPreferences: any) {
        //CUI-4891
        // Deserialize the string into JSON object
        let localStorageJson: any = {};
        try {
            localStorageJson = JSON.parse(userPreferences.LocalStorage);
            // Parse through each property and store it in the user's browser
            for (let key in localStorageJson) {
                // Compare the local storage keys with the whitelist in LocalStorageConstants
                for (var constantKey in Models.LocalStorageConstants) {
                    if (Models.LocalStorageConstants.hasOwnProperty(constantKey) && Models.LocalStorageConstants[constantKey] == key) {
                        this.uiLocalStorageService.set(key, localStorageJson[key], false);
                        break;
                    }
                }
            }
        }
        catch (err) {
            console.error(err);
            let error: any = {};
            error.type = 'ParseError';
            error.msg = 'Failed to parse Preferences.';
            console.log(error);
            throw {
                name: 'ParseError',
                message: 'Error loading Preferences.'
            };
        }
    }

    /**
     * Gets all local storage data in JSON format
     */
    private backupLocalStorage() {
        // Get all the keys stored in local storage for this application
        this.save();
    }

    /**
     * Permanently save preferences back to the server for this user
     */
    private save() {
        // Construct update request with preferences that go on server
        let updatePreferencesRequest: any = {};

        // Copy dialog preferences to saved ones
        this.dialogPreferences.CardSearchResults = parseInt(this.dialogPreferences.CardSearchResults);
        this.dialogPreferences.GridSearchResults = parseInt(this.dialogPreferences.GridSearchResults);
        this.savedPreferences.ColorTheme = this.dialogPreferences.ColorTheme;
        this.savedPreferences.FormBackground = this.dialogPreferences.FormBackground;
        let showImages: boolean = (this.dialogPreferences.ShowPersonImages === "YES") ? true : false;
        this.savedPreferences.ShowPersonImages = showImages;
        this.savedPreferences.CardSearchResults = this.dialogPreferences.CardSearchResults;
        this.savedPreferences.GridSearchResults = this.dialogPreferences.GridSearchResults;
        this.savedPreferences.CloseBarGraph = this.dialogPreferences.CloseBarGraph;
        this.savedPreferences.PlaySound = this.dialogPreferences.PlaySound;
        this.savedPreferences.VerboseAccess = this.dialogPreferences.VerboseAccess;
        this.savedPreferences.FullPageView = this.dialogPreferences.FullPageView;
        this.savedPreferences.UserPreferredEmailClient = this.dialogPreferences.UserPreferredEmailClient;

        // Save settings that go in local storage
        this.uiLocalStorageService.set(Models.LocalStorageConstants.checked, (this.savedPreferences.CloseBarGraph) ? "Y" : "N", false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.playSound, (this.savedPreferences.PlaySound) ? "Y" : "N", false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.verboseAccess, (this.savedPreferences.VerboseAccess) ? "Y" : "N", false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.reportBrowserPageView, (this.savedPreferences.FullPageView) ? "Y" : "N", false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.userPreferredEmailClient, this.savedPreferences.UserPreferredEmailClient.toString(), false);

        updatePreferencesRequest.CardSearchResults = this.savedPreferences.CardSearchResults;
        updatePreferencesRequest.GridSearchResults = this.savedPreferences.GridSearchResults;
        updatePreferencesRequest.ColorTheme = this.savedPreferences.ColorTheme;
        updatePreferencesRequest.FullPageView = this.savedPreferences.FullPageView;
        updatePreferencesRequest.FontSize = "MEDIUM";
        updatePreferencesRequest.ShowPersonImages = this.savedPreferences.ShowPersonImages;
        updatePreferencesRequest.FormBackground = this.savedPreferences.FormBackground;
        updatePreferencesRequest.OperatorID = this.sessionService.getOperatorId();
        updatePreferencesRequest.LocalStorage = JSON.stringify(this.uiLocalStorageService.getAll());

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'preferences', 'update', this.sessionService.getToken(), this.sessionService.getControlId()].join('/');

        this.http.post(url, updatePreferencesRequest, httpOptions).subscribe(
            (response: any) => {
                let data: any = response;
            });
    }
}