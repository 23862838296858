/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Inject, Input, OnInit } from "@angular/core";
import {
    HelperService,
    SearchResultsService,
    PromptService,
    SearchService,
    ValidationService,
    ServerCommandService,
    FocusService
} from "../../../services";
import * as Models from "../../../models";

import * as _ from 'lodash';

@Component({
    selector: 'formgrid',
    template: require('./form-grid.component.html')
})
export class FormGridComponent implements OnInit {
    @Input() data: any;
    currentContext: string = "";

    private intervalCount: number = 0;

    constructor(private searchResultsService: SearchResultsService,
        private helperService: HelperService,
        private searchService: SearchService,
        private notificationsService: PromptService,
        private validationService: ValidationService,
        private focusService: FocusService,
        private serverCommandService: ServerCommandService) {

    }

    ngOnInit() {
        this.updateScreenReaderText();
        console.log('data2', this.data);
    }

    /**
     * For form searches, change the sort order of the grid ascending/descending on a column
     */
    sortFormSRColumn(headerField: any): void {
        let sortedFieldName: string;
        let sortedAscending: boolean;

        if (this.data.control.sortedFieldName === headerField.fieldName) {
            sortedFieldName = headerField.fieldName;
            sortedAscending = !this.data.control.sortedAscending;
        } else {
            sortedFieldName = headerField.fieldName;
            sortedAscending = true;
        }

        // Sort records
        let sorted = _.sortBy(this.data.results.data, sortedFieldName);

        if (!sortedAscending) {
            sorted = sorted.reverse();
        }

        this.data.results.data = sorted;
        this.data.control.sortedFieldName = sortedFieldName;
        this.data.control.sortedAscending = sortedAscending;

        // Reset row numbers for each record
        for (let r = 0; r < this.data.results.data.length; r++) {
            this.data.results.data[r].rowNumber = r;
        }
        // Update screen reader text
        this.updateScreenReaderText();
    }

    /**
     * Select or deselect a single result row
     */
    toggleSingleForm(result: any): void {
        this.searchService.searchInput.value = "";
        this.searchService.toggleSingleForm(result);
    }

    /**
     * Keyboard shortcuts for the body of form search results table
     * @param event
     */
    bodyKeyboardShortcuts(event: any): void {
        let pageDown: boolean = event.keyCode == Models.KeyCodes.pageDown;
        let pageUp: boolean = event.keyCode == Models.KeyCodes.pageUp;
        let end: boolean = event.keyCode == Models.KeyCodes.end;
        let home: boolean = event.keyCode == Models.KeyCodes.home;
        let up: boolean = event.keyCode == Models.KeyCodes.up;
        let down: boolean = event.keyCode == Models.KeyCodes.down;
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!pageDown && !pageUp && !home && !end && !up && !down && !tab) {
            return;
        }

        //page up
        if (pageUp) {
            this.previousPage();
        }
        //page down
        else if (pageDown) {
            this.nextPage();
        }
        //home
        else if (home) {
            this.firstPage();
        }
        //end
        else if (end) {
            this.lastPage();
        }
        //up
        else if (up) {
            this.moveUp();
        }
        else if (down) {
            this.moveDown();
        }
        else if (!shift && tab) {
            if (document.getElementById("form-sr-open-bottom-btn").getAttribute("disabled") == undefined) {
                event.preventDefault();
                event.stopPropagation();
                this.focusService.focusOn("form-sr-open-bottom-btn", true);
            }
            else {
                if (document.getElementById("form-sr-input").getAttribute("disabled") == undefined) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.focusService.focusOn("form-sr-input", true);
                }
                else {
                    event.preventDefault();
                    event.stopPropagation();
                    this.focusService.focusOn("form-sr-help-btn", true);
                }
            }
        }
        else if (shift && tab) {
            let tableHeaders = document.querySelectorAll("#tblFormGridComponent th");
            if (tableHeaders.length > 0) {
                let headerToFocus = tableHeaders.length - 2;
                event.preventDefault();
                event.stopPropagation();
                this.focusService.focusOn("sr_form_header_" + headerToFocus, true);
            }
        }
    }

    /**
     * React to space or enter key down on form search result.
     */
    formSearchResultKeyDown(result: any, event: any) {
        this.searchService.searchInput.value = "";
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        let spacebar: boolean = event.keyCode == Models.KeyCodes.space;

        if (!enter && !spacebar) {
            return;
        }

        if (spacebar) {
            this.toggleSingleForm(result);
            event.preventDefault();
            event.stopPropagation();
        }

        if (enter) {
            this.searchResultsService.openForm(this.searchService.selectedForm.form);
        }
    }


    /**
     * Move back 8 results in form search results grid
     */
    private previousPage() {
        let activeElement: any = document.activeElement;

        let focusedId: string = (activeElement && activeElement.id.toLowerCase().startsWith("sr_form_")) ? activeElement.id.toLowerCase() : "";

        if (!this.validationService.isNullOrEmpty(focusedId)) {
            // Get the old active element's index and increment by cursorSize to get new Focus index
            let oldFocusId: any = parseInt(focusedId.split("sr_form_")[1]);
            // For forms, the page size will be set to 8 by default. No changes.
            let newFocusId: any = oldFocusId - 8;
            // If new focus row Number is less than 0, set the focus to this first row
            if (newFocusId < 0) {
                newFocusId = 0;
            }
            setTimeout(() => {
                document.getElementById("sr_form_" + newFocusId).focus();
            }, 250);
        } else {
            this.searchService.focusOnMainInput();
        }
    }

    /**
     * Move forward 8 results in form search results grid
     */
    private nextPage() {
        let activeElement: any = document.activeElement;

        let focusedId: string = (activeElement && activeElement.id.toLowerCase().startsWith("sr_form_")) ? activeElement.id.toLowerCase() : "";

        if (!this.validationService.isNullOrEmpty(focusedId)) {
            // Get the old active element's index and increment by cursorSize to get new Focus index
            let oldFocusId: any = parseInt(focusedId.split("sr_form_")[1]);
            // For forms, the page size will be set to 8 by default. No changes.
            let newFocusId: any = oldFocusId + 8;
            // If new focus row Number is less than 0, set the focus to this first row
            let maxRowNumber = Math.max.apply(Math, this.data.results.data.map((d: any) => {
                return d.rowNumber;
            }));
            // If new focus row Number is greater than the max row number, set the focus to this maxRowNumber
            if (newFocusId > maxRowNumber) {
                newFocusId = maxRowNumber;
            }

            setTimeout(() => {
                document.getElementById("sr_form_" + newFocusId).focus();
            }, 250);
        } else {
            this.searchService.focusOnMainInput();
        }
    }

    /**
     * Move to the first record in form search results grid
     */
    private firstPage() {
        let activeElement: any = document.activeElement;

        let focusedId: string = (activeElement && activeElement.id.toLowerCase().startsWith("sr_form_")) ? "sr_form_0" : "";

        if (!this.validationService.isNullOrEmpty(focusedId)) {
            setTimeout(() => {
                document.getElementById(focusedId).focus();
            }, 250);
        } else {
            this.searchService.focusOnMainInput();
        }
    }

    /**
     * Move to the last record in form search results grid
     */
    private lastPage() {
        let activeElement: any = document.activeElement;

        let focusedId: string = (activeElement && activeElement.id.toLowerCase().startsWith("sr_form_")) ? "sr_form_" + (this.data.results.data.length - 1) : "";

        if (!this.validationService.isNullOrEmpty(focusedId)) {
            setTimeout(() => {
                document.getElementById(focusedId).focus();
            }, 250);
        } else {
            this.searchService.focusOnMainInput();
        }
    }

    /**
     * Move 1 record down the form search results grid
     */
    private moveDown() {
        let activeElement: any = document.activeElement;

        let focusedId: string = (activeElement && activeElement.id.toLowerCase().startsWith("sr_form_")) ? activeElement.id.toLowerCase() : "";

        if (!this.validationService.isNullOrEmpty(focusedId)) {
            // Get the old active element's index and increment by cursorSize to get new Focus index
            let oldFocusId: any = parseInt(focusedId.split("sr_form_")[1]);
            // For forms, the page size will be set to 8 by default. No changes.
            let newFocusId: any = oldFocusId + 1;
            // If new focus row Number is less than 0, set the focus to this first row
            let maxRowNumber = Math.max.apply(Math, this.data.results.data.map((d: any) => {
                return d.rowNumber;
            }));
            // If new focus row Number is greater than the max row number, set the focus to this maxRowNumber
            if (newFocusId > maxRowNumber) {
                newFocusId = maxRowNumber;
            }

            document.getElementById("sr_form_" + newFocusId).focus();
        } else {
            this.searchService.focusOnMainInput();
        }
    }

    /**
     * Move 1 record up the form search results grid
     */
    private moveUp() {
        let activeElement: any = document.activeElement;

        let focusedId: string = (activeElement && activeElement.id.toLowerCase().startsWith("sr_form_")) ? activeElement.id.toLowerCase() : "";

        if (!this.validationService.isNullOrEmpty(focusedId)) {
            // Get the old active element's index and increment by cursorSize to get new Focus index
            let oldFocusId: any = parseInt(focusedId.split("sr_form_")[1]);
            // For forms, the page size will be set to 8 by default. No changes.
            let newFocusId: any = oldFocusId - 1;
            // If new focus row Number is less than 0, set the focus to this first row
            if (newFocusId < 0) {
                newFocusId = 0;
            }

            document.getElementById("sr_form_" + newFocusId).focus();
        } else {
            this.searchService.focusOnMainInput();
        }
    }

    /**
     * Add screen reader text for each result
     */
    private updateScreenReaderText(): void {
        if (this.data != null && this.data.results != null && this.data.results.data != null) {
            _.each(this.data.results.data, (r: any) => {
                let labelText = "Row " + (r.rowNumber + 1) + ": ";
                labelText += "Mnemonic: " + this.helperService.formatMnemonic(r["@ID"]) + ", ";
                labelText += "Name: " + r["PROCESS.DESCRIPTION"] + ", ";
                labelText += "Application: " + this.helperService.formatMnemonic(r["PROCESS.APPLICATION"]);
                labelText += r.highlight === true ?
                    " is selected. Enter to submit." :
                    " is not selected. Press space bar to toggle selection and then Enter to submit";
                r.screenReaderText = labelText;
            });
        }
    }
}
