/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable } from '@angular/core';

declare var logLevel: number;

@Injectable()
export class LoggerService {

    /**
     * Print information message on console
     * @param msg string or object
     */
    public info(msg: any) {
        if (logLevel >= 4)
            console.info(msg);
    }

    /**
     * Print log message  on console.
     * @param msg string or object
     */
    public log(msg: any) {
        if (logLevel >= 3)
            console.log(msg);
    }

    /**
     * Print warning message  on console.
     * @param msg string or object
     */
    public warning(msg: any) {
        if (logLevel >= 2)
            console.warn(msg);
    }

    /**
     * Print error message  on console.
     * @param msg string or object
     */
    public error(msg: any) {
        console.error(msg);
    }

}