/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Inject, Input, OnInit, OnDestroy } from "@angular/core";
import {
    SearchResultsService,
    RootScopeService,
    HelpService,
    PromptService,
    SearchService,
    ConfigurationService,
    ValidationService,
    ContextService,
    ServerCommandService,
    UiLocalStorageService,
    PreferencesService,
    ProcessingService,
    EventsService,
    FocusService
} from "../../../services";
import * as Models from "../../../models";

import * as _ from 'lodash';

declare var $: any;

@Component({
    selector: 'searchresultshell',
    template: require('./search-result-shell.component.html')
})
export class SearchResultShellComponent implements OnInit, OnDestroy {
    @Input() data: any;
    private indexesToOpen: number[];
    private actionToProcess: string;
    private recordToProcess: number;
    private temporaryData: any;
    private refreshGuid: any;
    private selectAllStartRow: number;
    private selectAllCursorSize: number;

    constructor(private searchService: SearchService,
        private uiLocalStorageService: UiLocalStorageService,
        private preferencesService: PreferencesService,
        private contextService: ContextService,
        private notificationsService: PromptService,
        private validationService: ValidationService,
        private serverCommandService: ServerCommandService,
        private configurationService: ConfigurationService,
        private searchResultsService: SearchResultsService,
        private rootScopeService: RootScopeService,
        private eventsService: EventsService,
        private helpService: HelpService, private processingService: ProcessingService, private focusService: FocusService) {
        this.refreshGuid = this.eventsService.on("RefreshResultPage", [this.loadSearchResultsListener, this]);
    }

    ngOnInit() {
        this.searchService.searchInput.value = "";
        this.searchResultsService.selectAll = false;
        this.searchResultsService.selectAllPreviouslySelected = false;
        this.rootScopeService.unselectedRowNumbers.length = 0;
        this.rootScopeService.searchResultRecordsSelected = 0;
        this.loadSearchResults();
    }

    ngOnDestroy() {
        this.searchResultsService.selectAll = false;


        this.searchResultsService.selectAllPreviouslySelected = false;
        if (this.rootScopeService.isFormOpen == false) {
            // Adding timeout to prevent search on closing the search results modal with enter key on close button.
            setTimeout(() => {
                this.searchService.focusOnSearchInput();
            }, 250);
        }
        else {
            setTimeout(() => {
                this.focusService.focusPreviousField();
            }, 250);
        }
        this.rootScopeService.searchCriteria = "";
        this.data = null;
        this.indexesToOpen = [];
        this.recordToProcess = 0;
        this.actionToProcess = "";
        this.temporaryData = null;
        for (let c in this.searchService.state.srData) {
            if (c != "FORM" && c != "PERSON") {
                delete this.searchService.state.srData[c];
            }
        }

        this.eventsService.destroy("RefreshResultPage", this.refreshGuid);
    }

    loadSearchResultsListener(searchResultShellComponent: any) {
        let self = <SearchResultShellComponent>searchResultShellComponent;
        self.loadSearchResults();
    }

    /**
     * Closes the search result upon clicking or activation close button
     * @param event
     */
    closeSearchResult(event: any) {
        if (event == null || (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter)) {
            if (event != null) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover, false);
            this.searchService.toggleVisible(false);
            this.focusService.focusPreviousField();
        }
    }

    /**
     * Notifies server to add a record if allowed.
     */
    addRecord() {
        this.serverCommandService.sendProcessCommandMessage("AddEntry");
        this.searchService.state.isGenericSearch = false;
        if (this.data.layout.context == "PERSON")
            this.contextService.newRecord = true;
        this.searchService.searchInput.value = "";
        this.searchService.toggleVisible(false);
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover, false);
    };

    /**
     * Called when user switches between Grid View and Card View
     * @param renderType -- CARD or GRID
     */
    switchSearchResultView(renderType: string, event: any = null): void {
        if (event != null && !(event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter)) {
            return;
        }
        // Clear the cache and get new results when the search results view is changed.
        this.searchService.clearCache();

        // If formless PERSON search, save initial render type in local storage
        if (!this.searchService.state.isFormSearch || !this.searchService.state.isGenericSearch) {
            this.uiLocalStorageService.set(Models.LocalStorageConstants.searchResultsRenderType, renderType);
        }

        // Set cursorSize based on view type
        if (renderType.toLocaleUpperCase() === Models.HelperText.card)
            this.data.control.cursorSize = this.rootScopeService.preferences.CardSearchResults;
        else if (renderType.toLocaleUpperCase() == Models.HelperText.grid)
            this.data.control.cursorSize = this.rootScopeService.preferences.GridSearchResults;

        // Display processing wait message
        this.searchService.toggleRunning(true, "Switching to " + renderType + " view, please wait", true);

        // If the search is successful, execute the success code
        let successCallback = (searchResultShellComponent: any): void => {
            let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
            //  self.selectRecords();
            self.data.layout.renderType = renderType;
            self.searchService.toggleRunning(false);

            if (self.preferencesService.dialogPreferences.VerboseAccess) {
                if (renderType === Models.HelperText.card) {
                    self.searchResultsService.displayErrorPrompt("Card View Enabled.", "");
                } else if (renderType === Models.HelperText.grid) {
                    self.searchResultsService.displayErrorPrompt("Grid View Enabled.", "");
                }
            } else {
                setTimeout(() => {
                    self.searchService.focusOnMainInput();
                }, 100);
            }
        };

        let failureCallback = (searchResultShellComponent: any): void => {
            let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
            self.searchService.toggleRunning(false);
        };

        // Perform a new page search which will always start with the first page
        this.searchResultsService.newPageSearch(1, this.data, successCallback, failureCallback, this);
    }

    /**
     * Handles keyboard shortcuts from search result input box
     * @param event
     */
    keyboardShortcutsKeyUp(event: any): void {
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        // don't do anything if the target is not card row. If the target is card row or grid cell, proceed only if the user didn't hit enter on the card row or grid cell.
        if (($(event.target).hasClass('searchResultRow') || $(event.target).hasClass('searchResultColumn')) && !enter) {
            return;
        }

        let pageDown: boolean = event.keyCode == Models.KeyCodes.pageDown;
        let pageUp: boolean = event.keyCode == Models.KeyCodes.pageUp;
        let end: boolean = event.keyCode == Models.KeyCodes.end;
        let home: boolean = event.keyCode == Models.KeyCodes.home;

        //page up
        if (pageUp) {
            event.preventDefault();
            event.stopPropagation();
            this.searchResultsService.previousPage(this.data, -1, -1);
        }
        //page down
        else if (pageDown) {
            event.preventDefault();
            event.stopPropagation();
            this.searchResultsService.nextPage(this.data, -1, -1);
        }
        //home
        else if (home) {
            event.preventDefault();
            event.stopPropagation();
            this.searchResultsService.firstPage(this.data, true);

        }
        //end
        else if (end) {
            event.preventDefault();
            event.stopPropagation();
            this.searchResultsService.lastPage(this.data, true);

        }
        //enter
        else if (!this.rootScopeService.isTablet && enter) {
            event.preventDefault();
            event.stopPropagation();
            this.openSelectedItems();
        }
    }

    keyboardShortcutsKeyDown(event: any): void {
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        let ctrl: boolean = event.ctrlKey;
        let alt: boolean = event.altKey;
        let i: boolean = event.keyCode == Models.KeyCodes.i;

        if (ctrl && alt && event.keyCode == Models.KeyCodes.h) {
            event.preventDefault();
            event.stopPropagation();
            this.searchResolutionHelp();

        }
        else if (alt && i) {
            event.preventDefault();
            event.stopPropagation();
            this.searchService.focusOnMainInput();
        }
        //enter
        else if (this.rootScopeService.isTablet && enter) {
            event.preventDefault();
            event.stopPropagation();
            this.openSelectedItems();
        }
    }

    /**
     * Open items selected from search results
     */
    openSelectedItems(): void {
        // Get current context
        let currentContext: string = this.searchResultsService.getCurrentSearchContext(this.data);
        let inputStr = this.searchService.searchInput.value.toLocaleUpperCase().trim();

        // Check if select all option is selected. If it is, get all the data from the server before opening items.
        // Before fetching data from the server, if selectedRowNumbers contains the same number of items as total rows in data results, it means all the data has already been
        // fetched from the server. Do not make an additional call.
        if (this.searchResultsService.selectAll == true && this.rootScopeService.selectedRowNumbers.length < this.data.results.totalRows && this.validationService.isNullOrEmpty(inputStr)) {
            // Clear cache
            this.searchService.clearCache();

            // Display processing message
            this.searchService.toggleRunning(true, "Selecting all, please wait", true);
            // Results call back to open the records, in context, when they are received from the server
            let successCallBack = (hideSearchResults: boolean, searchResultShellComponent: any) => {
                let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
                self.searchResultsService.openSelected(self.searchService.state.currentSRData, true);
                self.searchService.toggleRunning(false);
            };
            // Display error message when there are any errors
            let failureCallback = (hideSearchResults: boolean, errorMessage: string, searchResultShellComponent: any) => {
                let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
                self.searchService.toggleRunning(false);
            };
            // Search request and then return. If the data is returned successfully, results call back will handle opening the records.
            this.searchService.search(currentContext, this.data.results.cursorHandle, 1, this.data.results.totalRows, this.searchService.buildQueryColumns(currentContext), "", "", "", successCallBack, failureCallback, "", this, this);
            return;
        }
        else {

            // Get the input string form search result input box
            if (this.validateSearchResultInput(inputStr) == false)
                return;
            // clear search input
            this.searchService.searchInput.value = "";

            for (let i = 0; i < this.indexesToOpen.length; i++) {
                let index = this.indexesToOpen[i];
                if (this.data.results[index] && this.data.results[index]["@ID"]) {
                    this.rootScopeService.selectedRowNumbers.push(this.data.results[index]["@ID"]);
                    this.indexesToOpen.splice(i, 1);
                    i--;
                }
            }

            if (this.indexesToOpen.length > 0) {

                // Results call back to open the records, in context, when they are received from the server
                let successCallBack = (hideSearchResults: boolean, searchResultShellComponent: any) => {
                    let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
                    let recordsToAdd: any = [];

                    // already loaded data
                    _.each(self.rootScopeService.selectedRowNumbers, (id: any) => {
                        let record: any = _.find(self.temporaryData, (s: any) => {
                            return s["@ID"] == id;
                        });

                        if (record["@ID"] != null) record.ID = record["@ID"];
                        if (record != null) recordsToAdd.push(record);
                    });

                    // new data
                    _.each(self.searchService.state.currentSRData.searchResults.data, (record: any) => {
                        if (record["@ID"] != null) record.ID = record["@ID"];
                        // add only if not already added to the list. CUI-4850
                        if (self.rootScopeService.selectedRowNumbers.indexOf(record.ID) < 0) {
                            recordsToAdd.push(record);
                            self.rootScopeService.selectedRowNumbers.push(record.ID);
                        }
                    });

                    // create a dummy search result object to pass to search result service to open record.
                    self.searchResultsService.openSelected({
                        "results": { "data": recordsToAdd },
                        "control": self.searchService.state.currentSRData.control
                    }, false, self.searchService.state.currentSRData.searchResults.context);
                    self.searchService.toggleRunning(false);
                };
                // Display error message when there are any errors
                let failureCallback = (hideSearchResults: boolean, errorMessage: string, searchResultShellComponent: any) => {
                    let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
                    self.searchService.toggleRunning(false);
                };
                let criteria: string = ";QUEUECURSOR " + this.indexesToOpen.join("|");
                this.temporaryData = null;
                this.temporaryData = {};
                $.extend(true, this.temporaryData, this.data.results.data);
                this.searchService.search(currentContext, this.data.results.cursorHandle, 1, this.indexesToOpen.length, this.searchService.buildQueryColumns(currentContext), criteria, "", "", successCallBack, failureCallback, "", this, this);
            }
            else if (this.isIndexValid(this.recordToProcess) && !this.validationService.isNullOrEmpty(this.actionToProcess)) {
                let startRow: number;
                let page: number;
                switch (this.actionToProcess) {
                    case Models.SearchResultConstants.t:
                        // If the record already exists in the same page, do not attempt to search.
                        if (this.recordToProcess >= this.data.results.currentRow && this.recordToProcess < (this.data.results.currentRow + this.data.control.cursorSize)) {
                            this.processT();
                        }
                        else {
                            let successCallBack = (thisComponent: SearchResultShellComponent) => {
                                let self: SearchResultShellComponent = <SearchResultShellComponent>thisComponent;
                                self.processT();
                            };
                            page = ((this.recordToProcess - 1) / this.data.control.cursorSize) | 0;
                            startRow = (page * this.data.control.cursorSize) + 1;
                            this.searchResultsService.newPageSearch(startRow, this.data, successCallBack, null, this);
                        }
                        break;
                    case Models.SearchResultConstants.j:
                        // If the record already exists in the same page, do not attempt to search.
                        if (this.recordToProcess >= this.data.results.currentRow && this.recordToProcess < (this.data.results.currentRow + this.data.control.cursorSize)) {
                            this.processJ();
                        }
                        else {
                            let successCallBack = (thisComponent: SearchResultShellComponent) => {
                                let self: SearchResultShellComponent = <SearchResultShellComponent>thisComponent;
                                self.processJ();
                            };

                            page = ((this.recordToProcess - 1) / this.data.control.cursorSize) | 0;
                            startRow = (page * this.data.control.cursorSize) + 1;
                            this.searchResultsService.newPageSearch(startRow, this.data, successCallBack, null, this);
                        }
                        break;
                    case Models.SearchResultConstants.r:
                        this.searchService.searchInput.value = "";
                        if (this.preferencesService.dialogPreferences.VerboseAccess) {
                            // If the record already exists in the same page, do not attempt to search.
                            if (this.recordToProcess >= this.data.results.currentRow && this.recordToProcess < (this.data.results.currentRow + this.data.control.cursorSize)) {
                                this.processR();
                            }
                            else {
                                let successCallBack = (thisComponent: SearchResultShellComponent) => {
                                    let self: SearchResultShellComponent = <SearchResultShellComponent>thisComponent;
                                    self.processR();
                                };

                                page = ((this.recordToProcess - 1) / this.data.control.cursorSize) | 0;
                                startRow = (page * this.data.control.cursorSize) + 1;
                                this.searchResultsService.newPageSearch(startRow, this.data, successCallBack, null, this);
                            }
                        }
                        break;
                    case Models.SearchResultConstants.rd:
                        this.searchService.searchInput.value = "";
                        if (this.preferencesService.dialogPreferences.VerboseAccess) {
                            // If the record already exists in the same page, do not attempt to search.
                            if (this.recordToProcess >= this.data.results.currentRow && this.recordToProcess < (this.data.results.currentRow + this.data.control.cursorSize)) {
                                this.processRD();
                            }
                            else {
                                let successCallBack = (thisComponent: SearchResultShellComponent) => {
                                    let self: SearchResultShellComponent = <SearchResultShellComponent>thisComponent;
                                    setTimeout(() => {
                                        self.processRD();
                                    }, 250);
                                };

                                page = ((this.recordToProcess - 1) / this.data.control.cursorSize) | 0;
                                startRow = (page * this.data.control.cursorSize) + 1;
                                this.searchResultsService.newPageSearch(startRow, this.data, successCallBack, null, this);
                            }
                        }
                        break;

                }
            }
            else if (!this.validationService.isNullOrEmpty(this.actionToProcess)) {
                switch (this.actionToProcess) {
                    case Models.SearchResultConstants.c:
                        this.switchSearchResultView("CARD");
                        break;
                    case Models.SearchResultConstants.g:
                        this.switchSearchResultView("GRID");
                        break;
                    case Models.SearchResultConstants.a:
                        if (this.data.control.addMode) {
                            this.addRecord();
                        } else {
                            this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.addNewRecordPromptMessageText, "Error");
                        }
                        break;
                    case Models.SearchResultConstants.e:
                        this.openSRInExcel();
                        break;
                    case Models.SearchResultConstants.question:
                        let textalert = ['Enter records to select in the following format: 1,2-4,7\n',
                            '\t Or enter a command:\n',
                            '\t FA - select all records\n',
                            '\t T x - toggle the selection of result number x\n',
                            '\t A - add a new record (if available on a form)\n',
                            '\t J x - jump to result number x\n',
                            '\t S - enter Sort/Select criteria (if applicable)\n',
                            '\t E - export results to Excel\n'
                        ];
                        if (this.preferencesService.dialogPreferences.VerboseAccess) {
                            textalert.push("\t R x - read result number x\n");
                            textalert.push("\t RD x - read details of result number x\n");
                        }
                        textalert.push('\t ? - re-show these search input options\n');
                        this.searchResultsService.displayErrorPrompt(textalert, "Search Input Options");
                        break;
                    case Models.SearchResultConstants.s:
                        this.showSortSelectDialog();
                        break;
                    case Models.SearchResultConstants.fa:
                        break;
                    default:
                        this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
                        break;
                }

            }
            else if (this.rootScopeService.selectedRowNumbers.length == 0) {
                this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
            }
            else {
                // If select all is not clicked, open the selected records
                this.searchResultsService.openSelected(this.data);
            }

        }

    }

    /**
     * Select All Checkbox toggle
     */
    toggleSelectAll(): void {
        if (this.searchResultsService.selectAll == true) {
            let isFormSearch: boolean = this.searchService.state.isFormSearch;
            let isGenericSearch: boolean = this.searchService.state.isGenericSearch;
            if (!isFormSearch || isGenericSearch) {
                this.selectAllStartRow = this.data.results.currentRow;
                this.selectAllCursorSize = this.data.control.cursorSize;

                let successCallBack = () => {
                    // Load all the data in search results data of search result shell component
                    this.loadSearchResults();
                    // Reset the startRow and cursorSize
                    this.data.results.currentRow = this.selectAllStartRow;
                    this.data.control.cursorSize = this.selectAllCursorSize;
                    this.data.results.cursorSize = this.selectAllCursorSize;
                    this.searchService.cache.cursorSize = this.selectAllCursorSize;
                    for (let j = 1; j <= this.data.results.totalRows; j += this.selectAllCursorSize) {
                        if (!this.searchService.cache.startRows.includes(j)) {
                            this.searchService.cache.startRows.push(j);
                        }
                    }
                    this.searchResultsService.selectRecords(this.data);
                    this.searchResultsService.selectAllPreviouslySelected = true;
                    this.rootScopeService.searchResultRecordsSelected = this.data.results.totalRows;
                    this.processingService.closeProcessing();
                };

                let failureCallBack = (hideSearchResults: boolean, errMsg: string) => {
                    this.searchResultsService.displayErrorPrompt(errMsg, "Error");
                    this.processingService.closeProcessing();
                };

                this.processingService.showProcessing("Selecting all records");
                this.searchService.search(this.searchResultsService.getCurrentSearchContext(this.data),
                    this.data.results.cursorHandle,
                    1,
                    this.data.results.totalRows,
                    "",
                    "",
                    "",
                    "",
                    successCallBack,
                    failureCallBack,
                    null,
                    null,
                    null);
                return;
            }
            this.searchResultsService.selectRecords(this.data);
            this.searchResultsService.selectAllPreviouslySelected = true;
            this.rootScopeService.searchResultRecordsSelected = this.data.results.totalRows;
        }
        else if (this.searchResultsService.selectAll == false) {
            this.rootScopeService.selectedRowNumbers.length = 0;
            this.rootScopeService.searchResultRecordsSelected = 0;
            this.searchResultsService.selectAllPreviouslySelected = false;
            _.each(this.data.results.data, (rec: any) => {
                rec.recordSelected = false;
            });
        }
    }

    /**
     * Open the search results in Excel
     */
    openSRInExcel(): void {
        if (this.preferencesService.savedPreferences.SearchResultsExportAllowed != false) {
            if (this.validationService.isNullOrEmpty(this.data.results.cursorHandle)) {
                //pop up to export no search result
                let popupMessage: any = {};
                popupMessage.title = "Error";
                popupMessage.text = [
                    "This set of search results is unavailable for Excel export."
                ];
                popupMessage.buttons = [{
                    label: Models.LabelConstants.ok,
                    callback: () => {
                        this.notificationsService.popupMessageDismiss(() => {
                            this.searchService.focusOnMainInput();
                            this.processingService.closeProcessing();
                        }, false);

                    }
                }];
                popupMessage.defaultCallbackNumber = "1";
                this.notificationsService.popupMessageShow(popupMessage);
            } else {
                let fieldData: string = this.data.results.cursorHandle + "|" + this.searchResultsService.getCurrentSearchContext(this.data);
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.searchResultsExport, "", fieldData);
            }
        } else {
            let popupMessage: any = {};
            popupMessage.title = "Error";
            popupMessage.text = [
                "Search Results export not allowed."
            ];
            popupMessage.buttons = [{
                label: Models.LabelConstants.ok,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        this.searchService.focusOnMainInput();
                        this.searchService.searchInput.value = "";
                        this.processingService.closeProcessing();
                    }, false);

                },
                SearchFocus: false
            }];
            popupMessage.defaultCallbackNumber = "1";
            this.notificationsService.popupMessageShow(popupMessage);
        }
    };

    /**
     * Load help page for search result
     */
    searchResolutionHelp(event: any = null): void {
        // event will be null for click
        // keydown event only when space or enter
        if (event == null || (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter)) {
            if (event != null) {
                event.stopPropagation();
                event.preventDefault();
            }

            let helpID;
            if (this.searchService.state.isGenericSearch) {
                if (this.data == null || this.data.layout == null || this.data.layout.renderType == Models.HelperText.card) {
                    helpID = this.configurationService.getConfiguration().helpProcesses.NonPersonNonFormCardSR;
                } else {
                    helpID = this.configurationService.getConfiguration().helpProcesses.NonPersonNonFormGridSR;
                }
            } else {
                if (this.searchService.state.isFormSearch) {
                    helpID = this.configurationService.getConfiguration().helpProcesses.SearchResolutionForm;
                } else {
                    if (this.data == null || this.data.layout == null || this.data.layout.renderType == Models.HelperText.card) {
                        helpID = this.configurationService.getConfiguration().helpProcesses.SearchResolutionPersonCard;
                    } else {
                        helpID = this.configurationService.getConfiguration().helpProcesses.SearchResolutionPersonGrid;
                    }
                }
            }

            this.helpService.display(helpID);
        }
    }

    switchSRViewName() {
        this.searchService.clearCache();
        this.data.results.cursorHandle = this.data.views.viewHandles[this.data.views.selectedView];
        this.rootScopeService.selectedRowNumbers = [];
        this.rootScopeService.unselectedRowNumbers.length = 0;
        this.rootScopeService.searchResultRecordsSelected = 0;
        this.searchResultsService.newPageSearch(1, this.data, null, null, null, this.searchService.buildQueryColumns(this.searchResultsService.getCurrentSearchContext(this.data)));
    }

    /**
     * Shift Tab on Card view button should focus on the last element in search results footer
     * @param event
     */
    cardViewBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            // Get elements in the footer that have a higher tabIndex than the current element and are not disabled
            let higherTabElements = $("#searchResultFooter").find('[tabIndex]');
            higherTabElements = higherTabElements.sort((a: any, b: any) => {
                return b.tabIndex - a.tabIndex;
            });

            if (higherTabElements != null && higherTabElements.length > 0) {
                for (let ind in higherTabElements) {
                    if (higherTabElements[ind] != null) {
                        let elem = higherTabElements[ind];
                        if (elem.disabled != true) {
                            event.preventDefault();
                            event.stopPropagation();
                            this.focusService.focusOn(elem.id, true);
                            break;
                        }
                    }
                }
            }
            else {
                return;
            }
        }
    }

    /**
     * Tab on any button in the footer should focus on Card view button in the header
     * @param event
     */
    footerKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            // Get tabIndex of current element where tab key was pressed
            let tabValue = event.target.tabIndex;
            if (tabValue > 0) {
                // Get elements in the footer that have a higher tabIndex than the current element and are not disabled
                let higherTabElements = $("#searchResultFooter").find('[tabIndex]');
                for (let ind in higherTabElements) {
                    if (higherTabElements[ind] != null) {
                        let elem = higherTabElements[ind];
                        if (elem.tabIndex != null && elem.tabIndex > tabValue && elem.disabled != true) {
                            return;
                        }
                    }
                }

                event.preventDefault();
                event.stopPropagation();

                if (this.data.layout != null && this.data.layout.renderType == 'CARD')
                    this.focusService.focusOn("cardView", true);
                else if (this.data.layout != null && this.data.layout.renderType != 'CARD')
                    this.focusService.focusOn("toggleCard", true);

            }
        }
    }

    /**
     * Loads search results when the search results window is initialized.
     */
    private loadSearchResults() {
        this.data = {};
        this.contextService.newRecord = false;
        if (this.searchService.state.isFormSearch) {
            this.searchService.toggleRunning(false);
        }

        let currentSRData: any = this.searchService.state.currentSRData;
        if (currentSRData !== undefined) {
            this.data.results = null;
            this.data.results = currentSRData.searchResults;
            this.data.views = currentSRData.views;
            if (currentSRData.contextData === undefined) {
                this.data.layout = undefined;
            } else {
                this.data.layout = currentSRData.contextData.ResultPage;
            }
            this.data.control = currentSRData.control;
            // Form less PERSON sets GRID vs. CARD view based on currently saved state
            if (this.data.layout !== undefined) {
                if (!this.searchService.state.isFormSearch && !this.searchService.state.isGenericSearch) {
                    let renderType: string = this.uiLocalStorageService.get(Models.LocalStorageConstants.searchResultsRenderType);
                    if (renderType === undefined) {
                        renderType = Models.HelperText.card;
                    }
                    this.data.layout.renderType = renderType;
                }
            }
            if (this.data != null && this.data.layout != null && this.validationService.isNullOrEmpty(this.data.layout.renderType)) {
                this.data.layout.renderType = Models.HelperText.card;
            }
        }

        if (this.searchResultsService.alreadyFocused != true) {
            this.searchService.focusOnMainInput();
        }
        else {
            this.searchResultsService.alreadyFocused = false;
        }
    }

    /**
     * Validates the input entered and creates a list of record indexes to open in search result input box
     * @param inputStr
     * @returns {boolean}
     */
    private validateSearchResultInput(inputStr: string): boolean {
        this.indexesToOpen = [];
        this.recordToProcess = 0;
        this.actionToProcess = "";

        // return if input is empty
        if (this.validationService.isNullOrEmpty(inputStr)) return true;

        // If multiple selection is not allowed, check if the user entered comma, hyphen or FA in the input string and if it exists, display error message.
        if (this.data.control.multiSelection == false) {
            if (inputStr.match(",") != null || inputStr.match("-") != null || inputStr == Models.SearchResultConstants.fa) {
                // Display error message
                this.searchResultsService.displayErrorPrompt("The current resolution requires a single record selection.", "Error");
                // Validation failed, return false;
                return false;
            }
        }

        // if non form search result or is generic search (search from a form)
        if (!this.searchService.state.isFormSearch || this.searchService.state.isGenericSearch) {

            if (inputStr.match(",") != null || inputStr.match("-") != null) { // if input has comma or hyphen
                let commaInput: any = inputStr.split(",");

                let throwError: boolean;
                // check if all the
                _.each(commaInput, (ci: any) => {
                    if (ci.match("-") != null) {
                        let hyphenInput: any = ci.split("-");
                        if (hyphenInput.length >= 2) { // check if there are atleast 2 values when hyphen is used
                            // check if the first value is a number
                            let firstValue: number = parseInt(hyphenInput[0]);
                            if (isNaN(firstValue) || firstValue == null || this.isIndexValid(firstValue) == false) {
                                throwError = true; // throw error
                            }

                            // check if the second value is a number
                            let lastValue: number = parseInt(hyphenInput[1]);
                            if (isNaN(lastValue) || lastValue == null || this.isIndexValid(lastValue) == false) {
                                throwError = true; // throw error
                            }

                            // push all the indexes between the first and last number including first and last
                            for (let i = firstValue; i <= lastValue; i++) {
                                this.indexesToOpen.push(i);
                            }
                        }
                        else {
                            throwError = true;  // throw error
                        }
                    }
                    else {
                        // check if the comma seperated string is a number or is in the range
                        let rowNumber: number = parseInt(ci);
                        if (isNaN(rowNumber) || rowNumber == null || this.isIndexValid(rowNumber) == false) {
                            throwError = true;  // throw error
                        }
                        else {
                            // push the index
                            this.indexesToOpen.push(rowNumber);
                        }
                    }
                });

                if (throwError == true) {
                    this.indexesToOpen = [];
                    this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
                    // Validation failed, return false;
                    return false;
                }
            }
            else { // if input doesn't have comma or hyphen

                //check if entered string is already a valid number.
                let enteredNumber: number = parseInt(inputStr);

                if (isNaN(enteredNumber)) {
                    if (inputStr == Models.SearchResultConstants.fa) {
                        this.searchResultsService.selectAll = !this.searchResultsService.selectAll;
                        this.toggleSelectAll();
                        this.actionToProcess = Models.SearchResultConstants.fa;
                        if (this.preferencesService.dialogPreferences.VerboseAccess) {
                            if (this.searchResultsService.selectAll) {
                                this.searchResultsService.displayErrorPrompt("All records selected", "");
                            }
                            else if (!this.searchResultsService.selectAll) {
                                this.searchResultsService.displayErrorPrompt("All records unselected", "");
                            }
                        }
                        else {
                            setTimeout(() => {
                                this.searchService.focusOnMainInput();
                            }, 100);
                        }
                        return true;
                    }
                    else if (inputStr.substr(0, 1) == Models.SearchResultConstants.j) {
                        this.recordToProcess = parseInt(inputStr.substr(2));
                        this.actionToProcess = Models.SearchResultConstants.j;
                        return true;
                    }
                    // If the user selected character 'T', he/she is trying to toggle a record. Get the substring of the input to find the record number
                    else if (inputStr.substr(0, 1) == Models.SearchResultConstants.t) {
                        this.recordToProcess = parseInt(inputStr.substr(2));
                        this.actionToProcess = Models.SearchResultConstants.t;
                        return true;
                    }
                    // If the user selected character 'RD', he/she is trying to read the details of a record. Get the substring of the input to find the record number
                    else if (inputStr.substr(0, 2) == Models.SearchResultConstants.rd) {
                        this.recordToProcess = parseInt(inputStr.substr(3));
                        this.actionToProcess = Models.SearchResultConstants.rd;
                        return true;
                    }
                    // If the user selected character 'R', he/she is trying to read the key of a record. Get the substring of the input to find the record number
                    else if (inputStr.substr(0, 1) == Models.SearchResultConstants.r) {
                        this.recordToProcess = parseInt(inputStr.substr(2));
                        this.actionToProcess = Models.SearchResultConstants.r;
                        return true;
                    }
                    // if user selected character 'C' or 'G' or 'S' or 'A' or 'E' or '?' it is a valid input let it go.
                    else if (inputStr.substr(0, 1) == Models.SearchResultConstants.c || inputStr.substr(0, 1) == Models.SearchResultConstants.g
                        || inputStr.substr(0, 1) == Models.SearchResultConstants.s || inputStr.substr(0, 1) == Models.SearchResultConstants.a
                        || inputStr.substr(0, 1) == Models.SearchResultConstants.e || inputStr.substr(0, 1) == Models.SearchResultConstants.question) {
                        this.actionToProcess = inputStr.substr(0, 1);
                        return true;
                    }
                }
                else {
                    if (this.isIndexValid(enteredNumber)) {
                        this.indexesToOpen.push(enteredNumber); // push to indexes to open in case of a valid input
                    }
                }

                //if entered number is not a valid number throw error.
                if (isNaN(enteredNumber) || this.isIndexValid(enteredNumber) == false) {
                    this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
                    // Validation failed, return false;
                    return false;
                }
            }
        }

        // If multiple selection is allowed or user's input result into valid selection.
        return true;
    }

    /**
     * return true if the index is a valid selection
     * @param index
     */
    private isIndexValid(index: number): boolean {
        return index > 0 && index <= this.data.results.totalRows;
    }

    /**
     * Jump to a record
     */
    private processJ() {
        // Get the result data for the requested row
        let matchingRecord: any;
        let favFields: any = [];

        for (let i = 0; i < this.data.results.data.length; i++) {
            if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                matchingRecord = this.data.results.data[i];
                favFields = this.data.layout.favoriteFields;
                break;
            }
        }

        if (matchingRecord) {
            let privacyRequest = matchingRecord.searchResultPrivacyMessage != null;
            // Set the text inside the label for 'J'.
            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText = "Result number " + this.recordToProcess + " ";

                // If there are any favorite fields, these fields should be announced first before the other fields. They don't need any privacy request check
                if (favFields && favFields.length > 0) {
                    for (let fav = 0; fav < favFields.length; fav++) {
                        if (!this.validationService.isNullOrEmpty(favFields[fav])) {
                            let favFieldName = favFields[fav];
                            if (!this.validationService.isNullOrEmpty(matchingRecord[favFieldName])) {
                                let favFieldValue = matchingRecord[favFieldName];
                                labelText += favFieldName + ": " + favFieldValue;
                                labelText += fav != favFields.length - 1 ? ", " : " ";
                            }
                        }
                    }
                }

                labelText += matchingRecord.recordSelected == true ? "is currently selected. " : "is currently not selected. ";

                labelText += privacyRequest === true ?
                    "You can't access this record due to the person's request for privacy." :
                    "Close the dialog by pressing space and then press space bar again to toggle selection.";

                // Display message
                this.searchResultsService.displayErrorPrompt(labelText, "", "#sr_" + this.data.layout.renderType.toLowerCase() + "_" + (this.recordToProcess - 1));
            } else {
                // Focus on record
                setTimeout(() => {
                    document.getElementById("sr_" + this.data.layout.renderType.toLowerCase() + "_" + (this.recordToProcess - 1)).focus();
                }, 250);
            }
        }
    }

    /**
     * Toggle a record
     */
    private processT() {
        // Get the result data for the requested row
        let matchingRecord: any;

        for (let i = 0; i < this.data.results.data.length; i++) {
            if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                matchingRecord = this.data.results.data[i];
                break;
            }
        }

        if (matchingRecord) {
            let privacyRequest = matchingRecord.searchResultPrivacyMessage != null;
            // If the record is not private, toggle the record selection
            if (!privacyRequest) {
                // Toggle
                matchingRecord.recordSelected = !matchingRecord.recordSelected;
                if (matchingRecord.recordSelected) {
                    this.rootScopeService.selectedRowNumbers.push(matchingRecord["@ID"]);
                    // Remove this record from unselected row numbers if it is there
                    let index: number = this.rootScopeService.unselectedRowNumbers.indexOf(matchingRecord["@ID"]);    // <-- Not supported in <IE9
                    if (index !== -1) {
                        this.rootScopeService.unselectedRowNumbers.splice(index, 1);
                    }

                    // If All records have been selected, check the Select All checkbox
                    if (this.searchResultsService.selectAll == false && this.data.results.data.length == this.rootScopeService.selectedRowNumbers.length) {
                        this.searchResultsService.selectAll = true;
                        this.rootScopeService.searchResultRecordsSelected = this.data.results.totalRows;
                    }
                    else {
                        this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                            ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                            : this.rootScopeService.selectedRowNumbers.length;
                    }
                }
                else {
                    let index: number = this.rootScopeService.selectedRowNumbers.indexOf(matchingRecord["@ID"]);
                    if (index != -1) {
                        this.rootScopeService.selectedRowNumbers.splice(index, 1);
                    }

                    // Add this record to unselectedRowNumbers if it does not exist
                    index = this.rootScopeService.unselectedRowNumbers.indexOf(matchingRecord["@ID"]);
                    if (index === -1) {
                        this.rootScopeService.unselectedRowNumbers.push(matchingRecord["@ID"]);
                    }

                    this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                        ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                        : this.rootScopeService.selectedRowNumbers.length;

                    // If Select All checkbox was checked, reset it because a record has been unselected
                    if (this.searchResultsService.selectAll == true) {
                        this.searchResultsService.selectAll = false;
                    }
                }
            }

            // Set the text inside the label for 'T'.
            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText = "Result number " + this.recordToProcess + " ";
                labelText += matchingRecord.recordSelected == true ? "is currently selected. " : "is currently not selected. ";
                labelText += privacyRequest === true ?
                    "Access is denied." :
                    "";

                // Display message
                this.searchResultsService.displayErrorPrompt(labelText, "");
            }
        }
    }

    /**
     * Read favorite fields
     */
    private processR() {
        // Get the result data for the requested row
        let matchingRecord: any;
        let favFields: any = [];

        // Find matching record in data.results.data and get the favorite fields to read
        for (let i = 0; i < this.data.results.data.length; i++) {
            if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                matchingRecord = this.data.results.data[i];
                favFields = this.data.layout.favoriteFields;
                break;
            }
        }

        // Get the current context
        let currentContext = this.searchResultsService.getCurrentSearchContext(this.data);

        if (matchingRecord) {
            let labelText = "Row " + this.recordToProcess + " Search result record ";

            // If there are any favorite fields, these fields should be announced first before the other fields. They don't need any privacy request check
            if (favFields && favFields.length > 0) {
                for (let fav = 0; fav < favFields.length; fav++) {
                    if (!this.validationService.isNullOrEmpty(favFields[fav])) {
                        let favFieldName = favFields[fav];
                        if (!this.validationService.isNullOrEmpty(matchingRecord[favFieldName])) {
                            let favFieldValue = matchingRecord[favFieldName];
                            labelText += favFieldName + ": " + favFieldValue;
                            labelText += fav != favFields.length - 1 ? ", " : " ";
                        }
                    }
                }
            }

            labelText += matchingRecord.recordSelected == true ? "is currently selected. " : "is currently not selected. ";

            // If record is private
            if (matchingRecord.searchResultPrivacyMessage != null) {
                labelText += "You can't access this record due to the person's request for privacy.";
            }

            // Display message
            this.searchResultsService.displayErrorPrompt(labelText, "");
        }
    }

    /**
     * Read detail
     */
    private processRD() {
        // Get the result data for the requested row
        let matchingRecord: any;
        let favFields: any = [];

        for (let i = 0; i < this.data.results.data.length; i++) {
            if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                matchingRecord = this.data.results.data[i];
                favFields = this.data.layout.favoriteFields;
                break;
            }
        }

        if (matchingRecord) {
            let privacyRequest = matchingRecord.searchResultPrivacyMessage != null;

            // Set the text inside the label for 'RD'.
            let labelText = "";
            // Get the renderType
            let renType = this.data.layout.renderType.toUpperCase();

            // Read for card view
            if (renType === Models.HelperText.card) {
                labelText = "Card View: Result Number: ";
                // Select the row number
                let selectedRow = $("#sr_card_" + (this.recordToProcess - 1) + "_" + (this.recordToProcess - 1));
                if (selectedRow[0] && !this.validationService.isNullOrEmpty(selectedRow[0].innerText)) {
                    labelText += selectedRow[0].innerText;
                }
                labelText += matchingRecord.recordSelected == true ? ". Record is selected." : ". Record is not selected.";
                selectedRow = null;
            }
            // Read for grid view
            else if (renType === Models.HelperText.grid) {
                labelText = "Grid View: Result Number " + this.recordToProcess + ": ";

                // Get the column headers for the grid
                let gridColumnOrders: any = this.searchResultsService.gridColumnOrders[this.searchResultsService.getCurrentSearchContext(this.data)];

                if (privacyRequest) {
                    // Read only the favorites fields and discard other fields
                    // If there are any favorite fields, these fields should be announced first before the other fields. They don't need any privacy request check
                    if (favFields && favFields.length > 0) {
                        for (let fav = 0; fav < favFields.length; fav++) {
                            if (!this.validationService.isNullOrEmpty(favFields[fav])) {
                                // Check to see if column headers contain any fieldName that matches the favorite field
                                let columnField = $.find(gridColumnOrders, (g: any) => {
                                    return favFields[fav].toLowerCase() == g.fieldName.toLowerCase();
                                });
                                // If there is a match found, read the label instead of fieldName along with the field value
                                if (columnField && columnField.length > 0) {
                                    let favFieldName = columnField[0].label;
                                    if (!this.validationService.isNullOrEmpty(matchingRecord[columnField[0].fieldName])) {
                                        let favFieldValue = matchingRecord[columnField[0].fieldName];
                                        labelText += favFieldName + ": " + favFieldValue;
                                        labelText += fav != favFields.length - 1 ? ", " : " ";
                                    }
                                }
                                // If no matching column Field is found, proceed to read the fieldName
                                else {
                                    let favFieldName = favFields[fav];
                                    if (!this.validationService.isNullOrEmpty(matchingRecord[favFieldName])) {
                                        let favFieldValue = matchingRecord[favFieldName];
                                        labelText += favFieldName + ": " + favFieldValue;
                                        labelText += fav != favFields.length - 1 ? ", " : " ";
                                    }
                                }
                            }
                        }

                        labelText += matchingRecord.recordSelected == true ? ". Record is selected." : ". Record is not selected.";
                        labelText += "You can't access this record due to the person's request for privacy.";
                    }
                }
                else {
                    // Read all the fields from the gridColumnOrders with their field labels
                    for (let f = 0; f < gridColumnOrders.length; f++) {
                        labelText += gridColumnOrders[f].label + ": " + (!this.validationService.isNullOrEmpty(matchingRecord[gridColumnOrders[f].fieldName]) ? matchingRecord[gridColumnOrders[f].fieldName] : "blank");
                        labelText += f != gridColumnOrders.length - 1 ? ", " : " ";
                    }
                    labelText += matchingRecord.recordSelected == true ? ". Record is selected." : ". Record is not selected.";
                }
            }

            // Display message
            this.searchResultsService.displayErrorPrompt(labelText, "");
        }
    }

    private showSortSelectDialog() {

        let popupMessage: any = {};
        popupMessage.title = ' ';
        popupMessage.text = ["SELECT " + this.searchResultsService.getCurrentSearchContext(this.data)];
        popupMessage.inputType = "text";
        popupMessage.inputValue = "";
        popupMessage.defaultCallbackNumber = 0;
        popupMessage.buttons = [{
            label: "Submit",
            callback: () => {
                this.notificationsService.popupMessageDismiss(() => {
                    // If error: Error, Invalid sort/select specification
                    if (this.notificationsService.inputValue.length > 0) {
                        this.searchService.clearCache();
                        let currentContext = this.searchResultsService.getCurrentSearchContext(this.data);
                        let viewLabel = this.data.views.viewNames[this.data.views.selectedView];
                        let msgWait = "Sort/selecting on " + this.notificationsService.inputValue;
                        this.searchService.toggleRunning(true, msgWait, true);
                        let doneCallback = (hideSearchResults: boolean, searchResultShellComponent: any) => {
                            let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
                            self.searchResultsService.selectAll = false;
                            self.rootScopeService.selectedRowNumbers = [];
                            self.rootScopeService.searchResultRecordsSelected = 0;
                            //resetSearch();
                            self.searchService.toggleRunning(false);
                            self.searchService.focusOnMainInput();
                        };
                        let failureCallback = (hideSearchResults: boolean, errMsg: string, searchResultShellComponent: any) => {
                            let self: SearchResultShellComponent = <SearchResultShellComponent>searchResultShellComponent;
                            self.searchResultsService.displayErrorPrompt(errMsg, "Error");
                            self.processingService.closeProcessing();
                        };

                        this.searchService.search(currentContext, this.data.results.cursorHandle, 1, this.data.control.cursorSize, this.searchService.buildQueryColumns(currentContext), this.notificationsService.inputValue, "", "", doneCallback, failureCallback, "", this, this);
                    } else {
                        this.searchService.focusOnMainInput();
                    }
                    this.processingService.closeProcessing();
                }, false);
            },
            title: "",
            SearchFocus: false
        },
        {
            label: "Cancel",
            callback: () => {
                this.notificationsService.popupMessageDismiss(() => {
                    this.searchService.focusOnMainInput();
                    this.processingService.closeProcessing();
                }, false);
            },
            title: "",
            SearchFocus: false
        },
        {
            label: "Help",
            callback: () => {
                this.searchResolutionHelp();
                this.processingService.hideProcessing(true);
            },
            title: "Sort Select Help",
            SearchFocus: false
        }
        ];
        this.notificationsService.popupMessageShow(popupMessage);
    }

}