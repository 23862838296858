/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { ValidationService, RootScopeService } from "./";
import * as Models from "../models";

declare var $: any;

@Injectable()
export class FocusService {
    searchFocusInvoked: boolean = false;
    prevFormFocusElement: any;
    prevSearchFocusElement: any;
    isID_prev: boolean = false;
    elementId: any;
    startFocusInterval: any;
    focusTarget: any;
    searchFocusCount: number = 0;
    nonSearchFocusCount: number = 0;

    constructor(private validationService: ValidationService,
        private rootScopeService: RootScopeService) {
    }

    focusOn(element: any, isID: boolean = false, formField: boolean = false) {

        this.isID_prev = isID;
        if (element != '#form-search' && element != '#person-search') {
            this.prevSearchFocusElement = element;
        }

        if (formField == true) {
            this.prevFormFocusElement = element;
        }

        this.elementId = element;
        let focusTarget = $(element)[0];

        if (isID == true) {
            focusTarget = $('[id=' + element + ']');
            if (focusTarget.length > 1) {
                focusTarget = focusTarget.eq(focusTarget.length - 1);
            }
        }
        if (focusTarget !== null) {
            if (focusTarget != null) {
                focusTarget = $(focusTarget);
                if (focusTarget.is(":focus")) {
                    if (focusTarget) {
                        focusTarget.trigger("select");
                    }
                    this.searchFocusInvoked = false;
                    this.searchFocusCount = 0;
                    this.nonSearchFocusCount = 0;
                    this.isID_prev = false;
                    focusTarget = null;
                } else {
                    focusTarget.trigger("focus");
                    focusTarget.trigger("select");

                    if (this.searchFocusInvoked == true) {
                        this.searchFocusCount++;
                    }
                    else {
                        this.nonSearchFocusCount++;
                    }

                    this.nonSearchFocusCount = 0;
                    this.searchFocusInvoked = false;
                    this.isID_prev = false
                    focusTarget = null;
                }

            }
            else {
                focusTarget = null;
            }

        }
        else {
            focusTarget = null;
        }
        $(document).off("keydown");
    }

    focusSearch() {
        setTimeout(() => {
            this.searchFocusInvoked = true;
            if ($('#form-search').is(':visible')) {
                this.focusOn('#form-search');
            } else if ($('#person-search').is(':visible')) {
                this.focusOn('#person-search');
            } else {
                setTimeout(() => {
                    this.focusSearch();
                }, 500);
            }
        }, 100);
    }

    focusPreviousField() {
        if (this.prevFormFocusElement && this.prevFormFocusElement.selector && this.prevFormFocusElement.selector != '') {
            if (this.validationService.isNullOrEmpty(this.prevFormFocusElement.selector)) {
                this.focusOn(Models.HelperText.hash + this.rootScopeService.focusedFieldName);
            } else {
                this.focusOn(this.prevFormFocusElement.selector, this.isID_prev);
            }
        } else {
            if (this.validationService.isNullOrEmpty(this.prevFormFocusElement) && !this.validationService.isNullOrEmpty(this.rootScopeService.focusedFieldName)) {
                this.focusOn(Models.HelperText.hash + this.rootScopeService.focusedFieldName);
            } else if (!this.validationService.isNullOrEmpty(this.prevFormFocusElement)) {
                this.focusOn(this.prevFormFocusElement, true);

            }
        }
    }

    selectPreviousField() {
        let focusedFieldElement = document.getElementById(this.rootScopeService.focusedFieldName) as HTMLInputElement;
        if (!focusedFieldElement)
            this.focusSearch();
        else {
            focusedFieldElement.select();
            $(document).off("keydown");
        }
    }

    focusContext() {
        let leftTitleFieldLink = document.getElementsByClassName("left-title-field-link")[0] as HTMLElement;
        leftTitleFieldLink.focus();
        $(document).off("keydown");
    }

    setPrevField(fieldName: any) {
        this.prevFormFocusElement = fieldName;
    }

    select(element: any) {
        let focusTarget = $('[id=' + element + ']');

        if (focusTarget !== null) {
            focusTarget.select();
            $(document).off("keydown");
        }
        focusTarget = null;
    }
}
