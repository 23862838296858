/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import {
    ChangePasswordService,
    ConfigurationService,
    EventsService,
    FocusService,
    RootScopeService,
    SessionService,
    ServerCommandService
} from "./";
import * as Models from "../models";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

declare var $: any;
import * as _ from 'lodash';

@Injectable()
export class LoginService {

    loginProgress: any = { message: '', progress: 0 };
    loginUnderProgress: boolean = false;
    dbLoginFailCount: number = -1;

    constructor(private eventsService: EventsService, private http: HttpClient, private sessionService: SessionService,
        private changePasswordService: ChangePasswordService, private serverCommandService: ServerCommandService,
        private configurationService: ConfigurationService, private focusService: FocusService, private rootScopeService: RootScopeService) {
    }

    setProgress(progress: any, message: any) {
        this.loginProgress.progress += progress;
        this.loginProgress.message = message;
    }

    postLoginPromise() {
        this.setProgress(20, "Authenticated");
        this.eventsService.broadcast(Models.CommandConstants.preloadData);
        this.setProgress(20, "Loading Context...");
    }

    handleLoginErrors(error: any) {
        this.loginUnderProgress = false;
        $('#btnLogin').progressSet(-1);
        //added the user friendly error message
        if (error.data === "") {
            this.rootScopeService.systemError = "Log In failed: Please check your Connection";
            this.focusService.focusOn('#UserID');
        }
        else if (error == "UI_DAS_PSWD") {
            this.dbLoginFailCount += 1;
            this.loginUnderProgress = false;
            // Show Database Password Modal
            $("#databasePassword .modal").modal({
                show: true
            });

            //focus password field
            var focusDBPassword = setInterval(() => {
                this.focusService.focusOn('#DBPassword');
                clearInterval(focusDBPassword);
            }, 1000);
        }
        else if ((error.type == "LOAD") || (error.name == "LOAD")) {
            this.rootScopeService.systemError = error.msg + " Please contact your System Administrator.";
            this.rootScopeService.isAuthenticated = false;
            this.serverCommandService.sendProcessCommandMessage("Logout");
        }
        else if (error.status == 0) {
            this.rootScopeService.systemError = "Request timed out. Contact the administrator.";
            this.focusService.focusOn('#UserID');
        }
        else {
            this.rootScopeService.systemError = error;
            this.focusService.focusOn('#UserID');
        }

    }

    resetDBLoginFailCount() {
        this.dbLoginFailCount = -1;
    }

    login(postData: any) {
        this.loginUnderProgress = true;
        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'login'].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        this.setProgress(20, "Authenticating...");
        this.rootScopeService.systemError = '';

        this.http.post(url, postData, httpOptions).timeoutWith(60000, Observable.throwError(new Error("Timeout Occurred."))).subscribe(
            (response: any) => {
                if (response['Errors'] && response.Errors.length > 0) {
                    let errorMessages = '';
                    _.each(response.Errors, (it: any) => {
                        $("#UserPassword").val("");
                        var errorObj = it.ErrorMessageText;
                        var match = [Models.LoginError.BadDatabaseRoleErrorCode, Models.LoginError.UntrustedDomainErrorCode, Models.LoginError.errorCode3, Models.LoginError.PasswordExpiredErrorCode, Models.LoginError.loginTimeOutErrorCode];
                        if ((new RegExp('\\b' + match.join('\\b|\\b') + '\\b')).test(errorObj)) {
                            _.each(match, (errCode: any) => {
                                let match = errorObj.match(errCode);
                                if (match !== null) {
                                    //Bad Database Role
                                    if (errorObj.match(Models.LoginError.BadDatabaseRoleErrorCode) !== null) {
                                        errorMessages = Models.LoginError.BadDatabaseRoleErrorMessage;
                                        //Untrusted Domain
                                    } else if (errorObj.match(Models.LoginError.UntrustedDomainErrorCode) !== null) {
                                        errorMessages = Models.LoginError.UntrustedDomainErrorMessage;
                                        //Password Expired 
                                    } else if (errorObj.match(Models.LoginError.PasswordExpiredErrorCode) !== null) {
                                        errorMessages = Models.LoginError.PasswordExpiredErrorMessage;
                                        this.changePasswordService.openChangePasswordDialog(errorObj, true, true);
                                        //Login timeout Error
                                    } else if (errorObj.match(Models.LoginError.loginTimeOutErrorCode) !== null) {
                                        errorMessages = Models.LoginError.loginTimeOutErrorMessage;
                                    } else {
                                        errorMessages = Models.LoginError.tryAgain + "(UI " + match + ")";
                                    }
                                }
                            });
                            //Invalid application server configuration
                        } else if (it.ErrorCode == Models.LoginError.InvalidConfigurationErrorCode) {
                            errorMessages = Models.LoginError.InvalidConfigurationErrorMessage;
                            //Database Password Differs from OS Password 
                        } else if (it.ErrorMessageText == Models.LoginError.DataBasePasswordErrorCode) {
                            errorMessages = Models.LoginError.DataBasePasswordErrorCode;
                        } else {
                            errorMessages += it.ErrorMessageText;
                        }
                    });
                    this.handleLoginErrors(errorMessages);
                    setTimeout(() => {
                        document.getElementById("UserPassword").focus();
                    }, 250);
                } else {
                    this.sessionService.create({
                        token: response.SecurityToken,
                        controlId: response.ControlID,
                        tokenIndex: response.TokenIndex,
                        operatorId: response.OperatorID,
                        userId: response.UserID
                    });
                    this.postLoginPromise();
                }
            }, (error: any) => {
                let userPwdElement = document.getElementById("UserPassword") as HTMLInputElement;
                userPwdElement.value = "";
                this.handleLoginErrors(error);
                setTimeout(() => {
                    document.getElementById("UserPassword").focus();
                }, 250);
            });
    }

    debugSession(postData: any, userID: any) {
        this.loginUnderProgress = true;

        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'session', "debug", userID].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.setProgress(20, "Authenticating...");
        this.rootScopeService.systemError = '';

        this.http.post(url, postData, httpOptions).timeoutWith(30000, Observable.throwError(new Error("Timeout Occurred."))).subscribe(
            (response: any) => {
                if (response['Errors'] && response.Errors.length > 0) {
                    let errorMessages = '';
                    _.each(response.Errors, (it: any) => {
                        var errorObj = it.ErrorMessageText;
                        var match = [Models.LoginError.BadDatabaseRoleErrorCode, Models.LoginError.UntrustedDomainErrorCode, Models.LoginError.errorCode3, Models.LoginError.PasswordExpiredErrorCode, Models.LoginError.loginTimeOutErrorCode];
                        if ((new RegExp('\\b' + match.join('\\b|\\b') + '\\b')).test(errorObj)) {
                            _.each(match, (errCode: any) => {
                                let match = errorObj.match(errCode);
                                if (match !== null) {
                                    //Bad Database Role
                                    if (errorObj.match(Models.LoginError.BadDatabaseRoleErrorCode) !== null) {
                                        errorMessages = Models.LoginError.BadDatabaseRoleErrorMessage;
                                        //Untrusted Domain
                                    } else if (errorObj.match(Models.LoginError.UntrustedDomainErrorCode) !== null) {
                                        errorMessages = Models.LoginError.UntrustedDomainErrorMessage;
                                        //Password Expired 
                                    } else if (errorObj.match(Models.LoginError.PasswordExpiredErrorCode) !== null) {
                                        errorMessages = Models.LoginError.PasswordExpiredErrorMessage;
                                        this.changePasswordService.openChangePasswordDialog(errorObj, true, true);
                                        //Login timeout Error
                                    } else if (errorObj.match(Models.LoginError.loginTimeOutErrorCode) !== null) {
                                        errorMessages = Models.LoginError.loginTimeOutErrorMessage;
                                    } else {
                                        errorMessages = Models.LoginError.tryAgain + "(UI " + match + ")";
                                    }

                                    //  console.log(errorObj);
                                }
                            });
                            //Invalid application server configuration
                        } else if (it.ErrorCode == Models.LoginError.InvalidConfigurationErrorCode) {
                            errorMessages = Models.LoginError.InvalidConfigurationErrorMessage;
                            //Database Password Differs from OS Password 
                        } else if (it.ErrorMessageText == Models.LoginError.DataBasePasswordErrorCode) {
                            errorMessages = Models.LoginError.DataBasePasswordErrorCode;
                        } else {
                            errorMessages += it.ErrorMessageText;
                        }
                    });
                    this.handleLoginErrors(errorMessages);
                } else {
                    this.sessionService.create({
                        token: response.SecurityToken,
                        controlId: response.ControlID,
                        tokenIndex: response.TokenIndex,
                        operatorId: response.OperatorID,
                        userId: response.UserID
                    });
                    this.postLoginPromise();
                }
            }, (error: any) => {
                this.handleLoginErrors(error);
            });
    };


    resetProgress() {
        this.loginProgress = {
            message: '',
            progress: 0
        };
    }
}