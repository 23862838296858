/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Input } from "@angular/core";
import {
    ContextService,
    EventsService,
    FavoritesService,
    FocusService,
    RootScopeService,
    SearchService
} from "../../services";
import * as Models from "../../models";
declare var $: any;

@Component({
    selector: 'favoritesperson',
    template: require('./favorites-person.component.html')
})
export class FavoritesPersonComponent {
    @Input() root: any;

    constructor(private favoritesService: FavoritesService,
        private focusService: FocusService,
        private contextService: ContextService,
        private searchService: SearchService,
        private rootScopeService: RootScopeService,
        private eventsService: EventsService) {

    }

    folderKeyDown(item: any, rt: any, event: any) {
        // Tab should focus on the form favorites options menu icon
        if (!event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#favorite-form-options-link");
        }
        // Shift + Tab should focus on the person favorites options menu icon
        else if (event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#favorite-people-options-link");
        }
        else if (item.rename != true) {
            if (event.keyCode === Models.KeyCodes.space) {
                this.favoritesService.toggleMultiSelect(null, item, event, rt.FolderID, rt);
            }
            else if (event.keyCode !== Models.KeyCodes.space) {
                this.favoritesService.personFavoritesShortCutKey(event, item, rt, true, rt.FolderID);
            }
        }
    }

    itemKeyDown(item: any, rt: any, event: any) {
        // Tab should focus on the form favorites options menu icon
        if (!event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#favorite-form-options-link");
        }
        // Shift + Tab should focus on the person favorites options menu icon
        else if (event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#favorite-people-options-link");
        }
        else if (item.rename != true) {
            if (event.keyCode === Models.KeyCodes.space) {
                this.favoritesService.toggleMultiSelect(null, item, event, rt.FolderID, rt);
            }
            else if (event.keyCode !== Models.KeyCodes.space) {
                this.favoritesService.personFavoritesShortCutKey(event, item, rt, false, rt.FolderID);
            }
        }
    }

    /**
     * Opens one or more person records from Favorites. Selected items can be either a folder or individual person records.
     * @param item - Object containing favorite person folder or favorite person record data
     */
    openFavoritePerson(item: any) {
        // Clear the selectedItemValues array in favoritesService
        this.favoritesService.selectedItemValues.length = 0;
        // Declare the selectedFavoritePersonRecords array
        let selectedFavoritePersonRecords: any = [];

        if (item.FolderID) {// If item is a favorites folder, get all the favorites person records inside the folder
            this.getfavoriteperson(selectedFavoritePersonRecords, item);
        }
        else {// If item is not a favorites folder, it is a favorite person record
            selectedFavoritePersonRecords.push(item);
        }

        // Process any selected person records in Favorites only if atleast one record is selected (an empty folder won't have any records)
        if (selectedFavoritePersonRecords.length > 0) {
            // Get current context
            let contextData: any = this.contextService.getContext();
            // Indicate that context records are being loaded from favorites
            this.rootScopeService.loadContextFromFavorite = true;
            // Parse through the list of selected people records
            for (let i = 0; i < selectedFavoritePersonRecords.length; i++) {
                let favoriteRecord: any = selectedFavoritePersonRecords[i];
                // If selected favorite record already exists in context records, set the checkContextExistInFavorite to true in rootScope
                if (contextData.data.some(contextRecord => { return contextRecord[Models.HelperText.ID] === favoriteRecord.ItemValue; })) {
                    this.rootScopeService.checkContextExistInFavorite = true;
                    break;
                }
            }

            // If a form is open, set the CheckPersonRelatedFormIsOpen flag in rootScope to true
            if (this.rootScopeService.isContextFormOpen) {
                this.rootScopeService.CheckPersonRelatedFormIsOpen = true;
            }

            // Push the selected favorite person record to list of context records to be sent to the server
            // Generate search parameters
            let listOfSelectedPersonRecords: string = selectedFavoritePersonRecords.map((value: any) => { return value.ItemValue; }).join("|");
            let cursorSize: number = selectedFavoritePersonRecords.length;
            let startRow: number = 1;
            let defaultContext: string = Models.HelperText.person;
            let queryColumns: any = this.searchService.buildQueryColumns(defaultContext);
            let cursorHandle: string = defaultContext;

            // Search Response results call back handler
            let resultsCallback = (results: any) => {
                if (results.TotalRows > 0) {
                    // CUI-5712: cancelPrompted needs to be null, not false
                    this.rootScopeService.cancelPrompted = null;
                    this.contextService.listRefreshRecord(Models.HelperText.person, this.searchService.parseResults(results));
                }
            };

            // Search for person records
            this.searchService.search(defaultContext, cursorHandle, startRow, cursorSize, queryColumns, listOfSelectedPersonRecords, "", "", "", "", resultsCallback);

            // only add to history if item is not a folder.
            if (item.FolderID == false) {
                let msg: any = {
                    "historyID": item.ItemLabel,
                    "isForm": false
                };
                this.eventsService.broadcast(Models.CommandConstants.addToHistory, msg);
            }
        }

        // Close the favorites modal
        $("#favorites .modal").modal('hide');
    }

    checkRename(key: any, object: any, parentId: string, root: any, isFolder: boolean) {
        //if esc key has been pressed.
        let selItem: any = object;
        let focusItem: string = isFolder ? object.FolderID : object.ItemID;
        if (!isFolder)
            focusItem = parentId + "_" + focusItem;
        if (key.keyCode === Models.KeyCodes.escape) {
            delete object.tempName;
            object.rename = false;
        }
        else if (key.keyCode === Models.KeyCodes.enter) { // enter key
            if (object.tempName !== "") {
                this.favoritesService.renamePerson(object, parentId, root);
            }
            key.stopPropagation();
            key.target.parentElement.trigger("focus");
        }
    }

    /**
     * Recursively parses through each item in a folder (sub-folder or favorite person record), and generates a list of selected favorite person records
     * @param selectedFavoritePersonRecords - Array that will contain the list of selected favorite person records
     * @param items - Favorites person folder object
     */
    private getfavoriteperson(selectedFavoritePersonRecords: any, items: any) {
        for (let i = 0; i < items.CurrentItems.length; i++) {
            selectedFavoritePersonRecords.push(items.CurrentItems[i])
        }
        for (let j = 0; j < items.Folders.length; j++) {
            this.getfavoriteperson(selectedFavoritePersonRecords, items.Folders[j])
        }

    }
}