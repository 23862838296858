/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit, Inject } from '@angular/core';
import {
    ConfigurationService,
    HelpService,
    MessageListenerService,
    RootScopeService,
    QueueService,
    ContextService,
    ContextHeaderService,
    AboutService,
    PreferencesService,
    SessionService,
    ValidationService,
    CommentDialogService,
    FormService,
    ReportBrowserService,
    PromptService,
    SearchService,
    EventsService,
    FocusService,
    FavoritesService,
    PrivacyPolicyService,
    AttachService
} from "../../services";
import * as Models from "../../models";

declare var quickTourUrl: any;

@Component({
    selector: 'interface',
    template: require('./interface.component.html')
})
export class InterfaceComponent implements OnInit {
    triggerLogoutListenerId: string = "";
    elementOrderSelectedListenerId: string = "";
    preloadDataListenerId: string = "";

    constructor(private attachService: AttachService, private favoritesService: FavoritesService, private rootScopeService: RootScopeService, private contextService: ContextService, private reportBrowserService: ReportBrowserService,
        private commentDialogService: CommentDialogService, private formService: FormService, private preferencesService: PreferencesService, private validationService: ValidationService, private messageListenerService: MessageListenerService,
        private sessionService: SessionService, private aboutService: AboutService, private contextHeaderService: ContextHeaderService, private queueService: QueueService, private configurationService: ConfigurationService,
        private promptService: PromptService, private searchService: SearchService, private eventsService: EventsService, private focusService: FocusService, private helpService: HelpService, private privacyPolicyService: PrivacyPolicyService) {
        this.triggerLogoutListenerId = this.eventsService.on("TriggerLogout", [this.triggerLogout, this]);
        this.elementOrderSelectedListenerId = this.eventsService.on("elementOrderSelectedListener", [this.elementOrderSelectedListener, this]);
        this.preloadDataListenerId = this.eventsService.on(Models.CommandConstants.preloadData, [this.preLoadDataListener, this]);
    }

    ngOnInit() {
        this.configurationService.init();
    }

    ngOnDestroy() {
        this.eventsService.destroy("TriggerLogout", this.triggerLogoutListenerId);
        this.eventsService.destroy("elementOrderSelectedListener", this.elementOrderSelectedListenerId);
        this.eventsService.destroy(Models.CommandConstants.preloadData, this.preloadDataListenerId);
    }


    preLoadDataListener(comp: any) {
        let self = <InterfaceComponent>comp;
        self.eventsService.broadcast(Models.EventConstants.postLogin);
        self.contextService.init();
    }

    elementOrderSelectedListener(comp: any, elementToSelect: any) {
        let self = <InterfaceComponent>comp;
        self.rootScopeService.selectedElement = elementToSelect;
    }

    keyDown(event: any): void {
        // don't do anything is not authenticated
        if (this.rootScopeService.isAuthenticated == false) {
            return;
        }

        let keyCode = event.keyCode;
        let keyWhich = event.which;
        let keyCharCode = event.charCode;
        let keyChar = String.fromCharCode(keyCode || keyCharCode);
        let keyShift = event.shiftKey;
        let keyCtrl = event.ctrlKey || event.metaKey;
        let keyAlt = event.altKey;
        let keyMeta = event.metaKey;

        // Enabled to test PPW stuck in wrong state and need UI to auto correct.
        if (keyCtrl && keyAlt && !keyShift && event.code == "Backslash") {
            setTimeout(() => {
                this.eventsService.broadcast("openAlert", {
                    alertTitle: "Processing ...",
                    message: "Please Wait ...",
                    event: 'Manual PPW'
                });
            }, 100);
            return;
        }

        // Refresh server side html cache.
        // if(keyCtrl && keyAlt && keyShift && event.code == "Quote"){
        //     this.eventsService.broadcast("openAlert", {
        //         alertTitle: "Refreshing Configuration...",
        //         message: "Please Wait ...",
        //         event: 'Configuration Refresh'
        //     });
        //     this.configurationService.refreshConfiguration();
        //     return;
        // }

        // If report browser is open, don't do anything. They are handled in report browser component
        if (this.reportBrowserService.isOpen == true) return;

        // If multiline editor is open, don't do anything. 
        if (this.rootScopeService.commentDialogOpen == true) return;

        //if lookup prompt open, don't do anything.
        if (this.promptService.isOpen) return;

        // settings
        let gearDropdownDiv = document.getElementById("gearDropdownDiv") as HTMLElement;
        if (keyCode == Models.KeyCodes.escape && gearDropdownDiv.classList.contains("open")) {
            gearDropdownDiv.classList.remove("open");
            return;
        }

        // search results
        if (this.searchService.state.PersonSearchResults == true || this.searchService.state.FormSearchResults == true
            || this.searchService.state.PersonAdvancedSearch == true || this.searchService.state.ShowNavigationMenu == true) {
            if (keyCode == Models.KeyCodes.escape) { // escape key
                this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover, this.rootScopeService.isFormOpen == false);
                if (this.rootScopeService.isFormOpen == false) this.focusService.focusSearch();
            }
            return;
        }

        // favorite panel keyboard shortcuts
        if (this.rootScopeService.favoritePanelOpen == true) {
            if (keyCode == Models.KeyCodes.escape) { // escape key
                let formFavoritesOptionOpen = document.getElementById("formFavoritesOption").classList.contains("open");
                let personFavoritesOptionOpen = document.getElementById("personFavoritesOption").classList.contains("open");
                let addToFavoritesIconOpen = document.getElementById("addToFavoritesIcon").classList.contains("open");
                if (!formFavoritesOptionOpen && !personFavoritesOptionOpen && !addToFavoritesIconOpen) {
                    this.favoritesService.closeFavorites();
                }
                else if (this.favoritesService.renameFormFolders.length > 0) { // cancel renaming the folder.
                    if (!this.validationService.isNullOrEmpty(this.favoritesService.renameFormFolders[0].Item.prevValue) && this.favoritesService.renameFormFolders[0].Item.prevValue != " ") {
                        if (!this.validationService.isNullOrEmpty(this.favoritesService.renameFormFolders[0].Item.FolderLabel)) { //Change back to prevValue
                            this.favoritesService.renameFormFolders[0].Item.FolderLabel = this.favoritesService.renameFormFolders[0].Item.prevValue;
                        } else {
                            this.favoritesService.renameFormFolders[0].Item.ItemLabel = this.favoritesService.renameFormFolders[0].Item.prevValue;
                        }
                    }
                    this.favoritesService.renameFormFolders[0].Item.rename = false;
                }

            }
            else if (keyCode == Models.KeyCodes.enter) { // enter key in favorite dialog
                if (this.favoritesService.renameFormFolders.length > 0 && this.favoritesService.renameFormFolders[0].Item.rename) {
                    this.favoritesService.enterKeyFunction(this.favoritesService.renameFormFolders[0].Item, this.favoritesService.renameFormFolders[0].Root, this.favoritesService.renameFormFolders[0].IsFolder, this.favoritesService.renameFormFolders[0].Parent);
                }
            }
            return;
        }

        // print form
        if (keyCtrl && keyAlt && keyChar == "P") {
            this.eventsService.broadcast(Models.CommandConstants.printForm);
            return;
        }

        // open search results;
        if (keyCtrl && keyAlt && keyChar == "R") {
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.search);
            this.searchService.focusOnMainInput();
            return;
        }

        // Form Short Cuts
        if (this.formService.forms.length > 0) {
            if (keyCode == Models.KeyCodes.f9) {
                if (keyShift) { //Shift + F9
                    document.getElementById("btnFormSaveAll").focus();
                    setTimeout(() => {
                        this.formService.saveAll();
                    }, 250);
                    return;
                }
                else if (keyCtrl) {
                    document.getElementById("gearDropdownButton").focus(); // focus to random element, so text blur event won't add processUpdate as buffered action
                    setTimeout(() => {
                        this.formService.finishForm();
                    }, 250);

                    return;
                }
                else { //F9
                    document.getElementById("btnFormSave").focus();
                    setTimeout(() => {
                        this.formService.saveForm();
                    }, 250);

                    return;
                }
            }
            else if (keyCode == Models.KeyCodes.f8) {
                if (keyShift) { //Shift + F8
                    this.formService.cancelAll();
                    return;
                }
                else { // F8
                    this.formService.cancelForm();
                    return;
                }
            }
            //Cancel All
            else if (keyCtrl && keyAlt && keyCode === Models.KeyCodes.end) {
                this.formService.cancelForm();
                return;
            }
            // else if (keyCode == Models.KeyCodes.zero && keyCtrl) { // ctrl + 0
            //     this.formService.saveForm();
            //     return;
            // }
            // Read Inquiry Fields - Ctrl+Alt+I
            else if (keyCtrl && keyAlt && !keyShift && keyCode == Models.KeyCodes.i && this.preferencesService.dialogPreferences.VerboseAccess) {
                this.formService.readInquiryFields();
                return;
            }
            // Read current tab
            else if (keyCtrl && keyShift && keyChar == "F" && this.preferencesService.dialogPreferences.VerboseAccess) {
                this.formService.readCurrentTab();
                return;
            }
            // Record Delete
            else if (keyCtrl && keyAlt && !keyShift && keyChar == "Z") {
                this.eventsService.broadcast(Models.CommandConstants.recordDelete);
                return;
            }
            // Show Field Sequence
            else if (keyCtrl && keyAlt && (keyCode == Models.KeyCodes.semiColon || keyCode == Models.KeyCodes.firefoxSemiColon)) {
                this.eventsService.broadcast(Models.CommandConstants.disableCustomFieldSequenceClick);
                return;
            }
            // Disable Custom Field Sequence
            else if (keyCtrl && keyAlt && keyCode == Models.KeyCodes.singleQuote) {
                this.eventsService.broadcast(Models.CommandConstants.showFieldSequenceClick);
                return;
            }
            else if (keyCode == Models.KeyCodes.escape) { // escape
                if (this.rootScopeService.commentDialogOpen) {
                    this.commentDialogService.closeCommentDialog();
                    return;
                }
                else if (this.searchService.state.searchformAutoComplete == true) {
                    this.searchService.state.searchformAutoComplete = false;
                    setTimeout(() => {
                        this.focusService.selectPreviousField();
                    }, 100);
                    return;
                }
                else if (this.searchService.state.searchpersonAutocomplete == true) {
                    this.searchService.state.searchpersonAutocomplete = false;
                    setTimeout(() => {
                        this.focusService.selectPreviousField();
                    }, 100);
                    return;
                }
                else {
                    this.eventsService.broadcast(Models.EventConstants.closeCalculator);
                    this.eventsService.broadcast(Models.EventConstants.closeValcode);
                }
            }
            else if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
                this.aboutService.fieldHelp();
                return;
            }
            else if (keyCtrl && keyAlt && keyChar.toLocaleUpperCase() == "G") {// open external hyperlink
                if (this.rootScopeService.showSequenceOrder != true && this.formService.activeForm.externalLinks != null && this.formService.activeForm.externalLinks.length > 0) {
                    this.eventsService.broadcast(Models.CommandConstants.openExternalLink);
                }
            }
            else if (keyCtrl && keyAlt && keyChar.toLocaleUpperCase() == "T") { // Open Colleague Attachments
                console.log("Colleague Attach Code hit");
                if (this.formService.forms && this.formService.forms.length >= 1 && this.attachService.enableAttachLink === true && this.rootScopeService.showSequenceOrder !== true) {
                    console.log("opening colleague attach dialog");
                    this.attachService.displayAttachDialog();
                }
            }

            // propagate calendar key events;
            var activeElem = document.activeElement;
            if (!this.validationService.isNullOrEmpty(activeElem) && !this.validationService.isNullOrEmpty(activeElem.classList) && activeElem.classList.length > 0) {
                // If the active element contains these two classes, it means the focus is on the
                if (activeElem.classList.contains("ZZmyDpDaycell")) {
                    var eventData = {
                        element: activeElem,
                        event: event
                    };
                    this.eventsService.broadcast("CalendarKeyUp", eventData);
                }
                activeElem = null;
            }
        }

        if (keyCode == Models.KeyCodes.escape) { // generic escape
            if (this.searchService.state.searchpersonAutocomplete == true) {
                this.searchService.state.searchpersonAutocomplete = false;
            }
            else if (this.searchService.state.searchformAutocomplete == true) {
                this.searchService.state.searchformAutocomplete = false;
            }
            else if (this.aboutService.isOpen == true) {
                this.aboutService.close();
            }
            else if (this.privacyPolicyService.isOpen == true) {
                this.privacyPolicyService.close();
            }

            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
            this.eventsService.broadcast("CloseViewAllContext");
            this.focusService.focusPreviousField();
            this.focusService.selectPreviousField();
            return;
        }

        if (keyCtrl && keyAlt && (keyChar === "A")) { // CTRL + ALT + A   - Open Advanced Search
            if (this.searchService.state.isFormSearch) {
                this.searchService.state.isFormSearch = false;
            }
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.togglePersonAdvancedSearchPopover);
            return;
        }

        if (keyCtrl && keyAlt && (keyCode == Models.KeyCodes.f)) { // CTRL + ALT + F - Open Favorites
            if (this.rootScopeService.favoritePanelOpen == undefined || this.rootScopeService.favoritePanelOpen == false) {
                this.favoritesService.showFavorites();
            }
            return;
        }

        if (keyCtrl && keyAlt && keyCode === Models.KeyCodes.four) { // CTRL + ALT + 4 - Add Form to Favorites
            this.rootScopeService.FavActiveHelp = true; // opens add-to-favorites modal
            this.favoritesService.showAddToFavorites(Models.HelperText.form, this.formService.activeForm);
            return;
        }

        if (keyCtrl && keyAlt && (keyChar === "L")) { // CTRL + ALT + L  - Logout
            this.logout(event);
            return;
        }

        if (keyCtrl && keyAlt && (keyChar == "S") && this.rootScopeService.preferences != null && this.rootScopeService.preferences.PersonSearchAllowed == true) { // CTRL + ALT + S
            if ((event.target.id == 'person-search') || (event.target.id == 'form-search')) { // toggle search if input is focused
                this.searchService.toggleFormSearch();
                setTimeout(() => {
                    this.searchService.focusOnSearchInput();
                }, 200);
                return;
            }
            else { // focus input
                this.searchService.focusOnSearchInput();
                return;
            }
        }

        if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.Application);
            return;
        }

        if (keyCtrl && keyAlt && keyChar === "N") { // CTRL + ALT + N - Navigation
            if (!this.searchService.state.isFormSearch) {
                this.searchService.state.isFormSearch = true;
            }
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.toggleNavigationPopover);
            return;
        }

        if (keyCtrl && keyAlt && keyChar === "1") { // CTRL + ALT + 1
            this.eventsService.broadcast("ViewAllContext");
            return;
        }

        //close all context records
        if (keyCtrl && keyShift && keyChar === "X") {
            this.contextHeaderService.closeRecord(-1);
            return;
        }

        //Keep Open/Auto Close context area
        if (keyCtrl && keyAlt && keyChar === "O") {
            this.contextHeaderService.contextOpenflag(true)
            return;
        }

        //close current context record
        if (keyCtrl && keyAlt && keyChar === "X") {
            let cursorPosition = this.contextService.context.PERSON.position;
            this.contextHeaderService.closeRecord(cursorPosition);
            return;
        }

        //Add all context record to favorite
        if (keyCtrl && keyAlt && keyChar === "V") {
            let cursorPosition = this.contextService.context.PERSON.position;
            this.contextService.addRecordToFavorites(cursorPosition);
            return;
        }

        // Add all context record to favorite
        if (keyCtrl && keyAlt && keyChar === "2") {
            this.contextService.addRecordToFavorites(-1);
            return;
        }

        //show search history
        if (keyCtrl && keyAlt && keyChar === "5") {
            this.eventsService.broadcast("openSearchHistory");
            setTimeout(() => {
                this.focusService.focusSearch();
            }, 100);
            return;
        }


        // Focus Form field
        if (keyCtrl && keyAlt && keyCode === Models.KeyCodes.home) {
            this.focusService.focusPreviousField();
            return;
        }

        // Next Record
        if (keyCtrl && !keyAlt && keyCode === Models.KeyCodes.dot) {
            this.eventsService.broadcast("MoveContextForward");
            return;
        }

        // Previous Record
        if (keyCtrl && !keyAlt && keyCode === Models.KeyCodes.comma) {
            this.eventsService.broadcast("MoveContextBack");
            return;
        }

        // Next Form
        if (keyCtrl && keyAlt && keyCode === Models.KeyCodes.dot) {
            this.eventsService.broadcast("NextForm");
            return;
        }

        // Previous Form
        if (keyCtrl && keyAlt && keyCode === Models.KeyCodes.comma) {
            this.eventsService.broadcast("PreviousForm");
            return;
        }

        //Context Focus
        if (keyCtrl && keyAlt && keyChar === "W") {
            this.focusService.focusContext();
            return;
        }


        // Open Preferences
        if (keyCtrl && keyAlt && keyCode === Models.KeyCodes.c) {
            this.preferencesService.getPrefrences();
            return;
        }

        // Keyboard shortcuts
        if (keyCtrl && keyAlt && keyChar === "K") {
            this.aboutService.listKeyboardShortcutsPC();
            return;
        }

        if (keyCtrl && keyAlt && keyChar === "U") { // top right options menu
            document.getElementById("gearDropdownButton").click();
            return;
        }

        if (keyCtrl && keyAlt && (keyCode === Models.KeyCodes.fwdSlash)) { // left side bar help
            setTimeout(() => {
                document.getElementById("sidebarHelp").click();
            }, 100);
            return;

        }

        if (keyCtrl && keyAlt && (keyChar === "Y")) { // context help
            this.contextService.help();
            return;
        }

        // Quick Tour
        if (keyCtrl && keyAlt && (keyChar === "Q")) {
            if (!this.validationService.isNullOrEmpty(quickTourUrl)) {
                let quickTourWindow: any = window.open(quickTourUrl);
                quickTourWindow.focus();
                this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
            }
        }
    }

    triggerLogout(comp: any) {
        let self = <InterfaceComponent>comp;
        self.logout();
    }

    logout(event: any = null) {
        this.favoritesService.closeFavorites();
        this.preferencesService.close();
        //var userLogout=true;
        if (this.formService.forms.length === 0) {
            this.afterLogout(event);
        } else {
            this.sessionService.logout();
        }
    }

    private afterLogout(event: any = null) {
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
        this.sessionService.logout();
        this.eventsService.broadcast("logoutComplete");
        if (event)
            event.stopPropagation();
    }


}