/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from "@angular/core";

@Component({
    selector: 'shared-list-search',
    template: require('./shared-list-search.component.html')
})
export class SharedListSearchComponent {
    @Input() recentlyShared: SharedList[] = [];
    @Output() enter: EventEmitter<any> = new EventEmitter();
    @Output() sharedListSelected: EventEmitter<string> = new EventEmitter();
    @ViewChild('sharedlistdropdown', { static: false }) sharedListDropdownElement: ElementRef;
    @HostListener('document:click', ['$event']) clickedOutside($event: Event) {
        // close the dropdown when the user clicks anywhere outside
        // of the actual dropdown interface.
        this.dropdownOpen = false;
    }

    /**
     * The shared list currently selected.
     */
    sharedListName: string;

    /**
     * The active dropdown item (keyboard navigation)
     */
    activeDropdownItem: SharedList;
    dropdownOpen = false;

    onEnter() {
        this.enter.emit(null);
    }

    toggleDropdown(open?: boolean) {
        if (open == null) {
            this.dropdownOpen = !this.dropdownOpen;
        } else {
            this.dropdownOpen = open;
        }
    }

    navigateUpWithKeyboard() {
        if (this.dropdownOpen !== true || this.activeDropdownItem == null) {
            return;
        }

        const prev = this.getPrevListItem(this.activeDropdownItem != null ? this.activeDropdownItem.listname : null);
        if (prev != null) {
            this.activeDropdownItem = prev;
        }
    }

    navigateDownWithKeyboard() {
        if (this.dropdownOpen !== true) {
            this.toggleDropdown(true);

            setTimeout(() => this.sharedListDropdownElement.nativeElement.focus(), 0);
        }

        const next = this.getNextListItem(this.activeDropdownItem != null ? this.activeDropdownItem.listname : null);
        if (next != null) {
            this.activeDropdownItem = next;
        }

        return true;
    }

    prevListItem() {
        this.selectSharedList(this.getPrevListItem(this.sharedListName));
    }

    nextListItem() {
        this.selectSharedList(this.getNextListItem(this.sharedListName));
    }

    getPrevListItem(currentListItemName: string) {
        let currentIndex = _.findIndex(this.recentlyShared, p => p.listname === currentListItemName);

        if (--currentIndex >= 0) {
            return this.recentlyShared[currentIndex];
        }

        return null;
    }

    getNextListItem(currentListItemName: string) {
        if (this.recentlyShared == null || this.recentlyShared.length === 0) {
            return null;
        }

        if (currentListItemName == null) {
            return this.recentlyShared[0];
        }

        let currentIndex = _.findIndex(this.recentlyShared, p => p.listname === currentListItemName);

        if (++currentIndex < this.recentlyShared.length) {
            return this.recentlyShared[currentIndex];
        }

        return null;
    }

    isHighlighted(list: SharedList) {
        return this.activeDropdownItem != null && this.activeDropdownItem.listname === list.listname;
    }

    clickSharedListSearchDropdown(event: Event) {
        // prevent the dropdown from closing if the user clicked inside,
        // our global document click listener above will otherwise close the dropdown.
        event.preventDefault();
        event.stopPropagation();
    }

    selectSharedList(list: SharedList) {
        this.sharedListName = list != null ? list.listname : null;
        this.dropdownOpen = false;
        this.sharedListSelected.emit(this.sharedListName);
    }

    selectSharedListWithKeyboard() {
        const list = _.find(this.recentlyShared, p => p.listname === this.activeDropdownItem.listname);
        this.selectSharedList(list);
        this.onEnter();
    }

    onSharedListUpdated() {
        this.sharedListSelected.emit(this.sharedListName);
    }
}

interface SharedList {
    date: string;
    listname: string;
    numItems: string;
}