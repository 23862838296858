/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import * as Models from '..//models';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    ConfigurationService,
    EventsService,
    RootScopeService,
    ValidationService
} from './';


declare var ssoEnabled: any;
declare var $: any;
import * as _ from 'lodash';

@Injectable()
export class ChangePasswordService {

    constructor(private configurationService: ConfigurationService, private eventsService: EventsService,
        private validationService: ValidationService, private rootScopeService: RootScopeService, private http: HttpClient) {
        this.eventsService.on(Models.EventConstants.configurationLoaded, [this.configurationLoaded, this]);
        this.eventsService.on("ChangePasswordMessage", [this.changePasswordMessageListener, this]);
    }

    allowPasswordChange: boolean = false;
    changePassword: boolean = false;
    errors: any = [];
    ChangePasswordMessage: string = "";

    configurationLoaded(service: any) {
        let self = <ChangePasswordService>service;

        if (ssoEnabled == "false") {
            if (self.configurationService.getConfiguration().ClientParameters.ChangePasswordEnabled == true) {
                self.allowPasswordChange = true;
            }
        }
    }

    changePasswordMessageListener(service: any, msg: any) {
        let self = <ChangePasswordService>service;
        self.ChangePasswordMessage = msg;
    }

    openChangePasswordDialog(customMessage: any = null, changePassword: boolean = null, passwordExpiredFlag: boolean = null) {
        this.changePassword = changePassword == true;
        this.errors = [];

        if (!this.validationService.isNullOrEmpty(customMessage)) this.rootScopeService.customMessage = customMessage;
        else {
            this.rootScopeService.customMessage = "";
        }

        // CUI-3650 - If password is expired, display the option to change password even if Change Password option is disabled.
        if (passwordExpiredFlag == true) {
            $("#changePassword .modal").modal({
                show: true,
                keyboard: false
            });
        }
        else {
            if (this.allowPasswordChange == true) {
                $("#changePassword .modal").modal({
                    show: true,
                    keyboard: false
                });
            }
        }
    }

    closeChangePasswordDialog() {
        $("#changePassword .modal").modal('hide');
    }


    submit(changePasswordModel: any, successCallback: any = null, failureCallback: any = null, successParam: any = null, failureParam: any = null) {
        if (this.changePassword == true) changePasswordModel.ExpiredPassword = true;
        //check if model is valid
        if (changePasswordModel != null && !this.validationService.isNullOrEmpty(changePasswordModel.userID) && !this.validationService.isNullOrEmpty(changePasswordModel.currentPassword)
            && !this.validationService.isNullOrEmpty(changePasswordModel.newPassword) && !this.validationService.isNullOrEmpty(changePasswordModel.confirmNewPassword)
            && changePasswordModel.newPassword == changePasswordModel.confirmNewPassword) {
            let url: string = [this.configurationService.getConfiguration().serviceUrl, 'changePassword'].join('/');
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };

            this.http.put(url, changePasswordModel, httpOptions).subscribe(
                (data: any) => {
                    if (data['Errors'] && data.Errors.length > 0) {
                        _.each(data.Errors, (it: any) => {
                            this.errors.push(it.ErrorMessageText);
                        });
                        if (failureCallback != null) failureCallback(failureParam);
                    } else {
                        if (successCallback != null) successCallback(successParam);
                        this.eventsService.broadcast("passwordChanged");
                    }
                }, (error: any) => {
                    this.errors.push('There was an error in processing the request. Please contact system administrator.');
                    if (failureCallback != null) failureCallback(failureParam);
                });
        }
    }
}