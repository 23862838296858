/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Input, OnInit } from "@angular/core";
import {
    HelpService,
    FocusService,
    SearchResultsService,
    HelperService,
    PromptService,
    RootScopeService,
    SearchService,
    ConfigurationService,
    ValidationService,
    ContextService,
    PreferencesService,
    ProcessingService,
    FavoritesService
} from "../../../services";
import * as Models from "../../../models";

@Component({
    selector: 'formsearchresultshell',
    template: require('./form-search-result-shell.component.html')
})
export class FormSearchResultShellComponent implements OnInit {
    @Input() data: any;
    indexToOpen: number;
    recordToProcess: number;
    actionToProcess: string;
    formFavoritesButton: string = "#form-sr-favorites-btn";

    constructor(private configurationService: ConfigurationService,
        private contextService: ContextService,
        private searchService: SearchService,
        private favoritesService: FavoritesService,
        private notificationsService: PromptService,
        private validationService: ValidationService,
        private preferencesService: PreferencesService,
        private helpService: HelpService,
        private processingService: ProcessingService,
        private helperService: HelperService,
        private searchResultsService: SearchResultsService,
        private rootScopeService: RootScopeService,
        private focusService: FocusService) {

    }

    ngOnInit() {
        this.loadSearchResults();
    }

    /**
     * Closes the form search result upon clicking or activation of close button
     * @param event
     */
    closeFormSearchResult(event: any) {
        if (event == null || (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter)) {
            if (event != null) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
            this.searchService.toggleVisible();
        }
    }

    /**
     * Load help page for form search result
     */
    searchResolutionHelp(event: any = null): void {
        // event will be null for click
        // keydown event only when space or enter
        if (event == null || (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter)) {
            if (event != null) {
                event.stopPropagation();
                event.preventDefault();
            }

            let helpID = this.configurationService.getConfiguration().helpProcesses.SearchResolutionForm;
            this.helpService.display(helpID);
        }
    }

    /**
     * Add the current selected form to favorites
     */
    addToFavorites(): void {
        // If no form is selected, show error message
        if (this.searchService.selectedForm.isSelection == null || this.searchService.selectedForm.isSelection == undefined || this.searchService.selectedForm.isSelection == false) {
            //pop up to check the valid code
            this.searchService.searchInput.value = "";
            let popupMessage: any = {};
            popupMessage.title = "Error";
            popupMessage.text = [
                Models.SearchResultConstants.favoritePromptMessageText
            ];
            popupMessage.buttons = [{
                label: Models.LabelConstants.ok,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        this.processingService.closeProcessing();
                    }, false);
                    setTimeout(() => {
                        if ($(this.formFavoritesButton).is(":visible")) {
                            document.getElementById("form-sr-favorites-btn").focus();
                        }
                    }, 100);
                }
            }];
            popupMessage.defaultCallbackNumber = "1";
            this.notificationsService.popupMessageShow(popupMessage);
        } else {
            // Show Add to favorites dialog for Form
            this.favoritesService.showAddToFavorites("FORM", this.searchService.selectedForm);
        }
    }

    /**
     * Handles keyboard shortcuts from FORM search results
     * @param event
     */
    keyboardShortcuts(event: any): void {
        let ctrl: boolean = event.ctrlKey;
        let alt: boolean = event.altKey;
        let actionFound = false;
        let i: boolean = event.keyCode == Models.KeyCodes.i;
        let h: boolean = event.keyCode == Models.KeyCodes.h;

        //help
        if (ctrl && alt && h) {
            this.searchResolutionHelp();
            actionFound = true;
        }
        else if (alt && i) {
            this.searchService.focusOnMainInput();
            actionFound = true;
        }

        //return if key is not a valid key for navigation
        if (actionFound) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    /**
     * Handles enter key press on form search result input
     */
    enterKey(): void {
        // Input value from the input box on search result modal
        let inputValue: string = this.searchService.searchInput.value.trim().toUpperCase();

        // clear search input
        this.searchService.searchInput.value = "";

        if (this.validateFormSearchInput(inputValue) == false)
            return;

        if (this.indexToOpen > 0) {
            this.searchService.selectedForm.form = this.data.results.data[this.indexToOpen - 1];
            this.searchService.selectedForm.isSelection = true;
            this.searchResultsService.openForm(this.searchService.selectedForm.form);
        }
        else if (this.isIndexValid(this.recordToProcess) && !this.validationService.isNullOrEmpty(this.actionToProcess)) {
            switch (this.actionToProcess) {
                case Models.SearchResultConstants.j:
                    this.processJ();
                    break;
                case Models.SearchResultConstants.t:
                    this.processT();
                    break;
                case Models.SearchResultConstants.r:
                    this.processR();
                    break;
            }
        }
        else if (!this.validationService.isNullOrEmpty(this.actionToProcess)) {
            if (this.actionToProcess == Models.SearchResultConstants.question) {
                this.processQuestion();
            }
        }
    }

    /**
     * Open form when user clicks on Open button
     */
    openClick(): void {
        // Input value from the input box on search result modal
        let inputValue: string = this.searchService.searchInput.value.trim().toUpperCase();

        // Input value always takes preference over a selected record.
        if (this.validationService.isNullOrEmpty(inputValue) && this.searchService.selectedForm.isSelection) {
            this.searchResultsService.openForm(this.searchService.selectedForm.form);
        }
        else {
            this.enterKey();
        }
    }

    /**
     * Tab on Open button in footer should focus on Form Search Input in header
     * @param event
     */
    formSrOpenBottomBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("form-sr-input", true);
        }
        else if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("sr_form_" + (this.data.results.data.length - 1).toString(), true);
        }
    }

    /**
     * Shift tab on Form Search Input should focus on Open button in footer
     * @param event
     */
    formSrInputKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("form-sr-open-bottom-btn", true);
        }
    }

    /**
     * Shift tab should focus on the last record in form results if the Open button is disabled, meaning none of the records are selected.
     * @param event
     */
    formSrHelpBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            if (document.getElementById("form-sr-open-top-btn").getAttribute("disabled") != undefined) {
                event.preventDefault();
                event.stopPropagation();
                this.focusService.focusOn("sr_form_" + (this.data.results.data.length - 1).toString(), true);
            }
        }
    }

    /**
     * Validates the input entered and creates a list of record indexes to open in search result input box
     * @param inputStr
     * @returns {boolean}
     */
    private validateFormSearchInput(inputStr: string): boolean {
        this.indexToOpen = 0;
        this.recordToProcess = 0;
        this.actionToProcess = "";

        // If the input is empty and there is no selection in the search results, throw error message
        if (this.validationService.isNullOrEmpty(inputStr) && !this.searchService.selectedForm.isSelection) {
            this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
            return false;
        }

        //check if entered string is already a valid number.
        let enteredNumber: number = parseInt(inputStr);

        if (isNaN(enteredNumber)) {
            if (inputStr.substr(0, 1) == Models.SearchResultConstants.j) {
                this.recordToProcess = parseInt(inputStr.substr(2));
                this.actionToProcess = Models.SearchResultConstants.j;
            }
            // If the user selected character 'T', he/she is trying to toggle a record. Get the substring of the input to find the record number
            else if (inputStr.substr(0, 1) == Models.SearchResultConstants.t) {
                this.recordToProcess = parseInt(inputStr.substr(2));
                this.actionToProcess = Models.SearchResultConstants.t;
            }
            // If the user selected character 'R', he/she is trying to read the key of a record. Get the substring of the input to find the record number
            else if (inputStr.substr(0, 1) == Models.SearchResultConstants.r) {
                this.recordToProcess = parseInt(inputStr.substr(2));
                this.actionToProcess = Models.SearchResultConstants.r;
            }

            // Check if the recordToProcess is valid
            if (!this.validationService.isNullOrEmpty(this.actionToProcess) && (isNaN(this.recordToProcess) || this.isIndexValid(this.recordToProcess) == false)) {
                this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
                return false;
            }
            else if (!this.validationService.isNullOrEmpty(this.actionToProcess) && !isNaN(this.recordToProcess) && this.isIndexValid(this.recordToProcess) == true) {
                return true;
            }

            // if user selected character '?' it is a valid input let it go.
            if (inputStr.substr(0, 1) == Models.SearchResultConstants.question) {
                this.actionToProcess = inputStr.substr(0, 1);
                return true;
            }
        }
        else {
            if (this.isIndexValid(enteredNumber)) {
                this.indexToOpen = enteredNumber; // push to indexes to open in case of a valid input
                return true;
            }
            else {
                this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
                return false;
            }
        }

        // Pattern to compare the input to
        let resultInputPattern: any = /^[0-9 ,?-]+$/;
        if (this.searchService.searchInput.value.match(resultInputPattern) === null) {
            this.searchResultsService.displayErrorPrompt(Models.SearchResultConstants.recordRangePromptMessageText + this.data.results.totalRows, "Error");
            return false;
        }

        return true;
    }

    /**
     * Jump to a record
     */
    private processJ(): void {
        let matchingForm: any;
        for (let i = 0; i < this.data.results.data.length; i++) {
            if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                matchingForm = this.data.results.data[i];
                break;
            }
        }

        if (matchingForm) {
            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row " + this.recordToProcess + ": ";
                labelText += "Mnemonic: " + this.helperService.formatMnemonic(matchingForm["@ID"]) + ", ";
                labelText += "Name: " + matchingForm["PROCESS.DESCRIPTION"] + ", ";
                labelText += "Application: " + this.helperService.formatMnemonic(matchingForm["PROCESS.APPLICATION"]) + ". ";
                labelText += "Close the dialog by pressing space, then press space bar again to toggle selection followed by Enter to submit";

                let fieldToFocusId: string = "#sr_form_" + (this.recordToProcess - 1);

                if (!this.validationService.isNullOrEmpty(fieldToFocusId))
                    this.searchResultsService.displayErrorPrompt(labelText, "", fieldToFocusId);
                else
                    this.searchResultsService.displayErrorPrompt(labelText, "");
            } else {
                document.getElementById("sr_form_" + (this.recordToProcess - 1)).focus();
            }
        }
    }

    /**
     * Toggles a record
     */
    private processT(): void {
        let matchingForm: any;
        for (let i = 0; i < this.data.results.data.length; i++) {
            if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                matchingForm = this.data.results.data[i];
                break;
            }
        }

        if (matchingForm) {
            // Toggle row number requested by user
            this.searchService.toggleSingleForm(matchingForm);
            // Only display the screen reader message if accessibility is allowed.
            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row " + this.recordToProcess;
                labelText += matchingForm.highlight === true ? " is selected. " : " is not selected.";
                this.searchResultsService.displayErrorPrompt(labelText, "");
            }
        }
    }

    /**
     * Reads a record for the screen reader
     */
    private processR(): void {
        if (this.preferencesService.dialogPreferences.VerboseAccess) {
            let matchingForm: any;
            for (let i = 0; i < this.data.results.data.length; i++) {
                if (this.data.results.data[i].rowNumber === (this.recordToProcess - 1)) {
                    matchingForm = this.data.results.data[i];
                    break;
                }
            }

            if (matchingForm) {
                let labelText: string = "Row " + this.recordToProcess + ": ";
                labelText += "Mnemonic: " + this.helperService.formatMnemonic(matchingForm["@ID"]) + ", ";
                labelText += "Name: " + matchingForm["PROCESS.DESCRIPTION"] + ", ";
                labelText += "Application: " + this.helperService.formatMnemonic(matchingForm["PROCESS.APPLICATION"]);
                labelText += matchingForm.highlight === true ? " is selected. " : " is not selected.";

                this.searchResultsService.displayErrorPrompt(labelText, "");
            }
        }
    }

    /**
     * Display search input options again
     */
    private processQuestion(): void {
        let textalert = ['Enter a single form result to run, e.g. 3\n',
            '\t Or enter a command:\n',
            '\t J x - jump to and focus on result number x\n',
            '\t T x - toggle selection for result number x\n'
        ];
        if (this.preferencesService.dialogPreferences.VerboseAccess) {
            textalert.push("\t R x - read result number x\n");
        }
        textalert.push('\t ? - re-show these search input options\n');
        this.searchResultsService.displayErrorPrompt(textalert, "Search Input Options");
    }

    /**
     * Loads search results when the search results window is initialized.
     */
    private loadSearchResults() {
        this.data = {};
        this.contextService.newRecord = false;
        this.searchService.toggleRunning(false);

        let currentSRData: any = this.searchService.state.currentSRData;
        if (currentSRData !== undefined) {
            this.data.results = null;
            this.data.results = currentSRData.searchResults;
            this.data.views = currentSRData.views;
            if (currentSRData.contextData === undefined) {
                this.data.layout = {};
            } else {
                this.data.layout = currentSRData.contextData.ResultPage;
            }
            this.data.control = currentSRData.control;
            this.data.layout.renderType = Models.HelperText.grid;
        }

        console.log('data', this.data);
        this.searchService.focusOnMainInput();
    }

    /**
     * return true if the index is a valid selection
     * @param index
     */
    private isIndexValid(index: number): boolean {
        return index > 0 && index <= this.data.results.totalRows;
    }
}
