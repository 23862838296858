/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Directive, ElementRef } from '@angular/core';

declare var $: any;
@Directive({
    selector: '[draggable]',
    host: {
        '[style.cursor]': '"move"'
    }
})
export class Draggable {

    private mouseDownEvent: any;
    private elementWindowControl: any;
    private startX = 0;
    private startY = 0;
    private x = 0;
    private y = 0;


    constructor(el: ElementRef<any>) {
        $(el.nativeElement).on("mousedown", "", this, this.mouseDown);
    }

    mouseDown(event: any) {
        let self: Draggable = <Draggable>event.data;

        event.preventDefault();
        self.mouseDownEvent = event;

        //get the most outer div for window control
        let elementTarget = event.currentTarget.parentElement.parentElement.parentElement;


        self.elementWindowControl = elementTarget.id;
        self.x = 0; self.y = 0;

        // attach mouse event for dragging
        $(document).on('mousemove', "", self, self.mouseMove);
        $(document).on('mouseup', "", self, self.mouseUp);
    }

    mouseMove(event: any) {

        let self: Draggable = <Draggable>event.data;

        elementTarget = $("#" + self.elementWindowControl);
        let currentPosition = elementTarget[0].getBoundingClientRect();

        //x == 0 means first execution after dragging enabled.
        if (self.x == 0) {
            self.startX = self.mouseDownEvent.pageX - currentPosition.left; // x offset from window left to mouse clicked location
            self.startY = self.mouseDownEvent.pageY - currentPosition.top; // y offset from window left to mouse clicked location
        }

        // position of parent to calculate offset of window.
        var parentRect = elementTarget[0].parentElement.getBoundingClientRect();

        self.y = (event.pageY - parentRect.top - self.startY); // new top
        self.x = (event.pageX - parentRect.left - self.startX); // new left

        // prevent dragging window outside of left and top form area.
        if (self.x > 10 && self.y > 15) {
            var elementTarget = $("#" + self.elementWindowControl);

            elementTarget.css({
                top: self.y + 'px',
                left: self.x + 'px'
            });
        }
        elementTarget = null;
    }

    // clean up / unbind the event when done dragging
    mouseUp(event: any) {
        let self: Draggable = <Draggable>event.data;
        $(document).off('mousemove', self.mouseMove);
        $(document).off('mouseup', self.mouseUp);
    }
}