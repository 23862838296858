import { Injectable } from "@angular/core";
import { ConfigurationService } from "../services";

@Injectable()
export class DateFormatService {
    constructor(private configurationService: ConfigurationService) {
    }

    getDateFormat() {
        let dateFormat = "MM-dd-yyyy";

        let configDateFormat = this.configurationService.getConfiguration().ClientParameters.Date4Format;
        
        if (configDateFormat) {
            let delimiter = '';
            // Replace the prefix of date dateFormat with empty string and get the delimiter
            if (configDateFormat.indexOf('/') != -1) {
                configDateFormat = configDateFormat.replace('D4/', '');
                delimiter = '/';
            }
            else if (configDateFormat.indexOf('-') != -1) {
                configDateFormat = configDateFormat.replace('D4-', '');
                delimiter = '-';
            }
            else if (configDateFormat.indexOf('.') != -1) {
                configDateFormat = configDateFormat.replace('D4.', '');
                delimiter = '.';
            }

            let yearIndex = configDateFormat.toUpperCase().indexOf('Y');
            let monthIndex = configDateFormat.toUpperCase().indexOf('M');
            let dayIndex = configDateFormat.toUpperCase().indexOf('D');

            if(delimiter){
                if (dayIndex == 0) {
                    dateFormat = "dd" + delimiter;
                }
                else if (monthIndex == 0) {
                    dateFormat = "MM" + delimiter;
                }
                else if (yearIndex == 0) {
                    dateFormat = "yyyy" + delimiter;
                }
    
                if (dayIndex == 1) {
                    dateFormat += "dd" + delimiter;
                }
                else if (monthIndex == 1) {
                    dateFormat += "MM" + delimiter;
                }
                else if (yearIndex == 1) {
                    dateFormat += "yyyy" + delimiter;
                }
    
                if (dayIndex == 2) {
                    dateFormat += "dd";
                }
                else if (monthIndex == 2) {
                    dateFormat += "MM";
                }
                else if (yearIndex == 2) {
                    dateFormat += "yyyy";
                }
            }
        }

        return dateFormat;
    }
}
