/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable, Inject } from '@angular/core';
import * as Models from '../models';
import { RootScopeService, FormService, ValidationService, ServerCommandService, ProcessingService } from './';

@Injectable()
export class WindowControlService {
    constructor(private formService: FormService, private rootScope: RootScopeService,
        private serverCommandService: ServerCommandService, private validationService: ValidationService, private processingService: ProcessingService) {
    }

    /**
     * is previous paging allowed
     * @param windowController window controller name
     */
    isPreviousAllowed(windowController: string) {
        let currentWindow = this.formService.getWindowControl(windowController);
        return currentWindow.windowCurrentPage > 1;
    }

    /**
     * is next paging allowed
     * @param windowController window controller name
     */
    isNextAllowed(windowController: string) {
        let currentWindow = this.formService.getWindowControl(windowController);
        return currentWindow.windowCurrentPage < currentWindow.windowTotalPages;
    };

    /**
     * go to first page of the window
     * @param windowController window controller name
     */
    firstPage(windowController: string) {
        this.setWindowPosition(1, windowController);
    };

    /**
     * go to previous page
     * @param windowController window controller name
     */
    previousPage(windowController: string) {
        // Get current field's name but do not send a FieldJump to it yet. Let forceWindowFieldJump take care of it.
        let fieldName = this.windowJump(false);

        if (this.isPreviousAllowed(windowController)) {
            var fieldsArr = this.rootScope.currentFocusedField.split("_");
            if (this.validationService.isNullOrEmpty(fieldName) == false) {
                fieldsArr = fieldName.split("_");
            }
            var fieldRowNumber = parseInt(fieldsArr[fieldsArr.length - 1]);

            let currentWindow = this.formService.getWindowControl(windowController);

            var rowCount = currentWindow.windowRowCount;

            this.setWindowPosition(((currentWindow.windowCurrentPage - 2) * rowCount) + (fieldRowNumber), windowController);
        }
    };

    /**
     * go to next page
     * @param windowController window controller name
     */
    nextPage(windowController: string) {
        // Get current field's name but do not send a FieldJump to it yet. Let forceWindowFieldJump take care of it.
        let fieldName = this.windowJump(false);

        if (this.isNextAllowed(windowController)) {
            var fieldsArr = this.rootScope.currentFocusedField.split("_");
            if (this.validationService.isNullOrEmpty(fieldName) == false) {
                fieldsArr = fieldName.split("_");
            }
            var fieldRowNumber = parseInt(fieldsArr[fieldsArr.length - 1]);

            let currentWindow = this.formService.getWindowControl(windowController);

            var rowCount = currentWindow.windowRowCount;

            this.setWindowPosition((currentWindow.windowCurrentPage * rowCount) + fieldRowNumber, windowController);
        }
    };

    /**
     * go to last page
     * @param windowController window controller name
     */
    lastPage(windowController: string) {
        let currentWindow = this.formService.getWindowControl(windowController);
        this.setWindowPosition(currentWindow.windowTotalEntries, windowController);
    };

    /**
     * set the window position to a specific row index
     * @param position row index
     * @param windowController  window controller namesideBarExternalLink
     */
    setWindowPosition(position: number, windowController: string) {
        this.forceWindowFieldJump(windowController, position);
    };

    /**
     * Export the window results.
     */
    excelExport(windowController: string = "") {
        this.windowJump();
        if (this.validationService.isNullOrEmpty(windowController) == false)
            this.forceWindowFieldJump(windowController);
        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.excelExport);
    };

    /**
     * do window jump to a specific row index
     * @param position row index
     * @param windowController window controller name
     */
    doWindowJump(position: number, windowController: string) {

        let currentWindow = this.formService.getWindowControl(windowController);

        position = (currentWindow.windowRowCount * position) - (currentWindow.windowRowCount - 1);

        this.setWindowPosition(position, windowController);

    };

    /**
     * gets all window row elements for a given window controller and row index.
     * @param rowIndex row index
     * @param windowController window controller name
     */
    getWindowRowElements(rowIndex: number, windowController: string) {
        // Get current active form
        var currentForm = this.formService.activeForm;
        var windowRowElements = [];
        // If current active form is defined and has fields, proceed
        if (currentForm && currentForm.fields) {
            // Parse through each field. If it is a field from the current elementWindowController and , push the field to inquiryFields object
            for (var key in currentForm.fields) {
                // skip loop if the property is from prototype
                if (!currentForm.fields.hasOwnProperty(key))
                    continue;

                // Get the current field in iteration based on key
                var field = currentForm.fields[key];

                if (this.validationService.isNullOrEmpty(field.elementWindowController) || this.validationService.isNullOrEmpty(field.rowIndex)) {
                    continue;
                } else if (field.elementWindowController === windowController && field.rowIndex == rowIndex) {
                    windowRowElements.push(field);
                }
            }
        }
        return windowRowElements;
    };

    /**
     * get the absolute position based on page number and row index
     * @param rowIndex current row index
     * @param windowController window controller name
     */
    getWindowPosition(rowIndex: number, windowController: string) {
        let currentWindow = this.formService.getWindowControl(windowController);
        return currentWindow.windowRowCount * (currentWindow.windowCurrentPage - 1) + rowIndex;
    }


    readWindowRow(element: any): void {
        var windowController = element.elementWindowController;
        var rowIndex = element.rowIndex;
        var windowRowElements = this.getWindowRowElements(rowIndex, windowController);

        if (windowRowElements && windowRowElements.length > 0) {
            var textToRead = this.formService.forms[this.formService.forms.length - 1].windowList[windowController][0].windowLabel + " Window Row " + rowIndex + " has " + windowRowElements.length;
            textToRead += windowRowElements.length === 1 ? " field: " : " fields: ";
            for (var w = 0; w < windowRowElements.length; w++) {
                var ele = windowRowElements[w];
                textToRead += ele.screenReaderText;
                textToRead += " with value ";
                if (!this.validationService.isNullOrEmpty(ele.data)) {
                    textToRead += ele.data + ".";
                } else if (!this.validationService.isNullOrEmpty(ele.text)) {
                    textToRead += ele.text + ".";
                } else {
                    textToRead += "blank.";
                }
                // Add a space after each field's text to read.
                if (w != windowRowElements.length - 1) {
                    textToRead += " ";
                }
            }
            this.formService.screenReaderPromptMessage(textToRead);
        }
    }

    /**
     * If the current active window is not in sync with app server, send field jump.
     * @param sendFieldJump -- Boolean flag to check whether the method should send a FieldJump back to the App Server. In case of paging, let forceWindowFieldJump method handle both FieldJump and WindowJump in a single ClientInputMessage.
     *        Sending FieldJump and WindowJump messages in separate places causes de-sync. This results in WindowJump being sent with a different rowIndex than FieldJump, resulting in page hops that are unexpected. See CUI-5541 (CR-000164778)
     */
    public windowJump(sendFieldJump: boolean = true): string {
        if (this.rootScope.validActiveWindow != this.rootScope.activeWindow) {
            let fieldName = this.rootScope.currentFocusedField.replace(/-/g, '.');
            let element = this.formService.getLatestForm().fields[fieldName];
            if (element != null) {
                if (element.elementWindowController != element.originalElementID && (element.elementType == Models.ElementType.inquiry || element.runTimeInquiryField === true)
                    && !element.elementDetailable) {
                    let currentWindow = this.formService.getWindowControl(element.elementWindowController);
                    element = this.formService.getLatestForm().fields[element.elementWindowController + "_" + (element.rowIndex % currentWindow.windowRowCount)];
                    if (element == null) return null;
                    // change window controller
                }

                if (sendFieldJump) {
                    this.rootScope.validActiveWindow = element.elementWindowController;
                    this.rootScope.ignoreFieldPromptId.push(element.sanitizedID);
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", element.originalElementID, "", element.rowIndex);
                }
                return element.sanitizedID;
            }
        }
        // CUI-5449 -- If the current Window is the active Window on the app server and the user clicks on an inquiry field, send a Field Jump to the Window Controller element and ignore the FieldPrompt
        else if (this.rootScope.validActiveWindow == this.rootScope.activeWindow) {
            let fieldName = this.rootScope.currentFocusedField.replace(/-/g, '.');
            let element = this.formService.getLatestForm().fields[fieldName];
            if (element != null) {
                if ((element.elementType == Models.ElementType.inquiry || element.runTimeInquiryField === true) && !element.elementDetailable) {
                    let currentWindow = this.formService.getWindowControl(element.elementWindowController);
                    element = this.formService.getLatestForm().fields[element.elementWindowController + "_" + (element.rowIndex % currentWindow.windowRowCount)];
                    if (element == null)
                        return null;

                    if (sendFieldJump) {
                        this.rootScope.validActiveWindow = element.elementWindowController;
                        this.rootScope.ignoreFieldPromptId.push(element.sanitizedID);
                        this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", element.originalElementID, "", element.rowIndex);
                    }

                    return element.sanitizedID;
                }
            }
        }
        return null;
    }

    private forceWindowFieldJump(elementWindowController: any, position: any = "") {
        let fieldName = this.rootScope.currentFocusedField.replace(/-/g, '.');
        let element = this.formService.getLatestForm().fields[fieldName];
        if (element != null) {
            if (this.validationService.isNullOrEmpty(position)) {
                if (fieldName != elementWindowController && (element.elementType == Models.ElementType.inquiry || element.runTimeInquiryField === true) && !element.elementDetailable) {
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", elementWindowController, "", element.rowIndex);
                }
                else {
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", element.originalElementID, "", element.rowIndex);
                }
            }
            else {
                if (element.elementWindowController != element.originalElementID && (element.elementType == Models.ElementType.inquiry || element.runTimeInquiryField === true)
                    && !element.elementDetailable) {
                    let currentWindow = this.formService.getWindowControl(element.elementWindowController);
                    // CUI-5187 -- If the remainder of rowIndex/windowRowCount is 0, it means the user is on the last row. In that case, look for the element in fields that has the ID with the windowRowCount in window fields
                    element = element.rowIndex % currentWindow.windowRowCount == 0
                        ? this.formService.getLatestForm().fields[element.elementWindowController + "_" + (currentWindow.windowRowCount)]
                        : this.formService.getLatestForm().fields[element.elementWindowController + "_" + (element.rowIndex % currentWindow.windowRowCount)];
                    if (element == null) return null;
                    // change window controller
                }

                let jumpCommand: any = Models.CommandConstants.fieldJump;
                // if ignorefield jump is set, ignore field jump command as app server is already aware of and won't like it if required field validation is prompted.
                if (this.rootScope.lastPromptedField == element.elementID || this.rootScope.lastFieldPromptMessageId == element.elementID) {
                    jumpCommand = Models.CommandConstants.ignoreFieldJump;
                }
                else {
                    this.rootScope.validActiveWindow = element.elementWindowController;
                }

                this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.windowJump], ["", ""], ["", ""], [element.originalElementID, element.originalElementID], ["", ""], [element.rowIndex, position]);
            }
        }
    }
}