/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Inject, Injectable } from "@angular/core";
import {
    RootScopeService,
    SearchService,
    HelpService,
    ConfigurationService,
    ValidationService,
    ContextService,
    PreferencesService,
    FocusService
} from "./";
import * as Models from "../models";

declare let $: any;
import * as _ from 'lodash';

@Injectable()
export class ContextManagerService {
    availableContexts: any;
    contextManagerOpen: boolean = false;
    selected: any = []; // Object array containing all highlighted entries
    selectNumber: any = {}; // Number input that selects a single record
    selectAll: any = {}; // Toggle to select/deselect all records
    managerToggle: any = {}; // Toggle to open and close manager

    constructor(private rootScopeService: RootScopeService,
        private contextService: ContextService,
        private searchService: SearchService,
        private helpService: HelpService,
        private configurationService: ConfigurationService,
        private validationService: ValidationService,
        private preferencesService: PreferencesService,
        private focusService: FocusService) {

    }

    /**
     * For a single card selected in the manager, switch to that card in the main context
     * @param personContext
     * @param cardToOpen
     * @param event
     */
    openSelected(personContext: any, cardToOpen: any, event: any) {
        if (event != null && event.keyCode === 13)
            event.stopPropagation();

        this.rootScopeService.viewallContext = true;
        $("#context-warning").show();

        // If the currently selected record in View All Context Records dialog is the active record in context card, do not send listRefreshRecord to the App Server. -- CUI-5683
        if (this.validationService.isNullOrEmpty(cardToOpen) && this.availableContexts.PERSON.data[this.availableContexts.PERSON.position].recordSelected == true) {
            this.close();
            this.focusService.focusPreviousField();
            return;
        }

        // If the user double clicked the record, compare the ID in cardToOpen with the ID of currently active record in context card and if they match, do not send listRefreshRecord to the App Server. -- CUI-5683
        if (!this.validationService.isNullOrEmpty(cardToOpen) && this.availableContexts.PERSON.data[this.availableContexts.PERSON.position].ID === cardToOpen.ID) {
            this.close();
            this.focusService.focusPreviousField();
            return;
        }

        // Set current selected context to the one record flagged
        let idToSelect = cardToOpen ? cardToOpen['@ID'] : this.selected[0]['@ID'];
        let indexToSelect = _.findIndex(personContext.data, (contextCard: any) => {
            return contextCard['@ID'] === idToSelect && this.rootScopeService.isContextFormOpen != true;
        });

        if (indexToSelect >= 0) {
            personContext.position = indexToSelect;
        }
        if (this.rootScopeService.isContextFormOpen) {
            if (cardToOpen) {
                this.selected.push(cardToOpen);
            }
            this.contextService.listRefreshRecord('PERSON', this.selected);
        }

        this.searchService.focusOnMainInput();

        this.close();
    }

    /**
     * Toggle the selected state of a particular context card
     * @param contextCard
     * @param totalCards
     * @param event
     */
    toggleResult(contextCard: any, totalCards: any, event: any = null) {
        // if keybord key is pressed check if it is a space.
        this.selectNumber.value = '';
        this.selectNumber.disabled = false;
        if (!event || event.keyCode === Models.KeyCodes.space) {
            if (event) {
                event.preventDefault();
            }
            if (this.isSelected(contextCard)) {
                // Select, deselect this entry
                this.selectAll.enabled = false;
                _.remove(this.selected, (item: any) => {
                    return item['@ID'] === contextCard['@ID'];
                });
                contextCard.recordSelected = false;
            } else {
                // Not selected yet, select this entry
                this.selected.push(contextCard);
                contextCard.recordSelected = true;
                this.selectNumber.inputDisabled = true;
                if (this.selected.length == totalCards) {
                    this.selectAll.enabled = false;
                }
            }
        }

        // Disable input if items have been selected
        this.selectNumber.inputDisabled = this.selected && this.selected.length > 0 ? true : false;
        this.selectNumber.openDisabled = this.selected && this.selected.length == 1 ? false : true;
        this.selectNumber.favoriteDisabled = this.selected && this.selected.length > 0 ? false : true;
        if (this.availableContexts.PERSON.data[this.availableContexts.PERSON.position].recordSelected == true)
            this.selectNumber.removeDisabled = true;
        else
            this.selectNumber.removeDisabled = false;
    }

    /**
     * Close the context card, but first reset to our initial state for next time
     */
    close() {
        // Clear entire selected array since entries all gone
        while (this.selected.length > 0) {
            this.selected.pop();
        }

        this.selectNumber.value = "";
        this.selectAll.enabled = false;
        // Close context manager automatically
        this.managerToggle.show = false;

        //Enable input textbox
        this.selectNumber.inputDisabled = false;
        this.contextManagerOpen = false;
        this.rootScopeService.contextManagerOpen = false;
    }

    /**
     * Check if a context card is selected in the manager view
     * @param contextCard
     * @returns {boolean}
     */
    isSelected(contextCard: any): boolean {
        if (_.isUndefined(contextCard) || _.isUndefined(this.selected)) {
            return false;
        }
        return !_.isUndefined(
            _.find(this.selected, (val: any) => {
                return val['@ID'] === contextCard['@ID'];
            })
        );
    }

    openInput(personContext: any, event: any) {
        if (event.type.toLowerCase() == "submit") {
            event.preventDefault();
            if (this.selectNumber.value == parseInt(this.selectNumber.value)) {
                if (this.selectNumber.value > 0) {
                    if (this.selectNumber.value <= personContext.data.length) {
                        personContext.position = this.selectNumber.value - 1;
                        this.rootScopeService.viewallContext = true;
                        let id = this.selectNumber.value - 1;
                        let dataArray = [];
                        let selectedData = personContext.data[id];
                        dataArray.push(selectedData);
                        if (this.rootScopeService.isContextFormOpen) {
                            this.contextService.listRefreshRecord('PERSON', dataArray);
                        }
                        this.close();
                    }
                }
            } else {
                this.selectNumber.value = '';
            }
        }
    }

    // Toggle the selected state of all of the cards at once
    selectAllRecords(newValue: any, personContext: any) {
        // Clear entire selected array
        while (this.selected.length > 0) {
            this.selected.pop();
        }
        _.each(personContext.data, (contextCard: any) => {
            contextCard.recordSelected = false;
        });

        // If selecting all then add every card into selected array
        if (this.selectAll.enabled) {
            _.each(personContext.data, (contextCard: any) => {
                this.selected.push(contextCard);
                contextCard.recordSelected = true;
            });
            this.selectNumber.inputDisabled = true;
            this.selectNumber.removeDisabled = false;
            this.selectNumber.favoriteDisabled = false;
        }
        else {
            this.selectNumber.inputDisabled = false;
            this.selectNumber.removeDisabled = true;
            this.selectNumber.favoriteDisabled = true;
        }
    }

    /**
     * Remove all the selected cards from both the View All Context manager and context card area
     * @param personContext
     */
    removeSelected(personContext: any) {
        // Remove all selected cards from the current context
        this.rootScopeService.viewallContext = true;

        // Check if a form is open along with context card records
        let contextOpenForm = this.rootScopeService.isContextFormOpen;

        // Get the active Context Card ID
        let activeId = personContext.data[personContext.position]['@ID'];

        // Parse through all selected records, find corresponding matches in personContext.data, and tag those records to be removed
        if (contextOpenForm == true) {
            _.each(this.selected, selectedRecord => {
                let matchingRecord = personContext.data.filter(person => person['@ID'].toLowerCase() === selectedRecord['@ID'].toLowerCase());

                if (!this.validationService.isNullOrEmpty(matchingRecord) && matchingRecord.length > 0)
                    matchingRecord[0].toBeRemoved = true;
            });

            // Set the ID of the active person record so that the new position of context record can be adjusted accordingly once records have been removed
            this.contextService.activeIdAfterContextRemove = activeId;
        }
        else {
            // If form is not open and View All Context is used to remove records, simply remove them from the corresponding context data
            _.each(this.selected, selectedRecord => {
                _.remove(personContext.data, (person: any) => {
                    return person['@ID'] === selectedRecord['@ID'] && contextOpenForm != true && activeId != selectedRecord['@ID'];
                })
            });

            // Adjust active position
            personContext.position = personContext.data.map((person: any) => { return person['@ID']; }).indexOf(activeId);
        }
        // Clear entire selected array
        if (this.selected.length > 0) {
            if (contextOpenForm) {
                // If form is open, send a command to the app server and process the response
                this.contextService.closeRecord('PERSON', personContext.position, this.selected);
                this.close();
            } else {
                // If form is not open, clear the selected array
                this.selected.pop();
                this.close();
            }
        }
    }


    /**
     * Add the selected cards to the favorites
     * @param personContext
     */
    addSelectedToFavorites(personContext: any) {
        let idToSelect = this.selected[0]['@ID'];
        let position = personContext.position;
        let dataToAddFavorite = [];
        let indexToSelect = _.findIndex(personContext.data, (contextCard: any) => {
            return contextCard['@ID'] === idToSelect;
        });
        if (indexToSelect >= 0) {
            personContext.position = indexToSelect;
        }
        //looping selected records
        for (let i = 0; i < this.selected.length; i++) {
            dataToAddFavorite.push(this.selected[i]);
        }
        personContext.position = indexToSelect;
        this.contextService.viewAllAddRecordToFavorite(personContext.position, dataToAddFavorite);
        personContext.position = position;
        this.close();
    }

    /**
     * Display help about the context manager
     * @param event
     */
    help(event: any) {
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.ContextManager);
        }
    }

    /**
     * Sets the context record's screenReaderText and privacyMessageText
     * @param context
     */
    setAdditionalContextRecordData(context: any) {
        this.availableContexts = context;
        _.each(this.availableContexts.PERSON.data, (contextRecord: any) => {
            contextRecord.screenReaderText = this.setAriaLabelText(contextRecord);
            contextRecord.privacyMessageText = this.viewAllContextPrivacyMessage(contextRecord);
        });
        this.contextManagerOpen = true;
        this.rootScopeService.contextManagerOpen = true;

        // Clear entire selected array
        while (this.selected.length > 0) {
            this.selected.pop();
        }
        _.each(this.availableContexts.PERSON.data, (contextCard: any) => {
            contextCard.recordSelected = false;
        });
        this.selectNumber.openDisabled = true;
        this.selectNumber.removeDisabled = true;
        this.selectNumber.favoriteDisabled = true;
        this.selectNumber.value = "";
        this.selectAll.enabled = false;
        this.selectAll.checkboxDisabled = false;
    }

    /**
     * For a card number input, switch to that card in the main context window
     */
    checkInput() {
        this.selectNumber.inputDisabled = false;
        if (this.selectNumber.value == "") {
            this.selected = [];
            this.selectNumber.value = '';
            this.selectNumber.disabled = false;
        }
        // If parseInt operation failed, input string is not a number. Check to see if it contains comma or hyphen
        if (isNaN(this.selectNumber.value)) {
            let findComma = this.selectNumber.value.match(",") === null ? false : true;
            let findHyphen = this.selectNumber.value.match("-") === null ? false : true;

            // In case the string is either invalid or has a comma or hyphen, open button should get disabled.
            this.selectNumber.openDisabled = true;

            // Start with everything disabled
            this.selectNumber.removeDisabled = true;
            this.selectNumber.favoriteDisabled = true;

            // Always reset the array before proceeding.
            this.selected.length = 0;

            // If input string has both comma and hyphen, check if string is valid
            if ((findComma && findHyphen)) {
                this.processCommasHyphens(this.selectNumber.value, this.availableContexts.PERSON.data, true);
            }
            // only commas in the string
            else if (findComma && !findHyphen) {
                this.processCommasHyphens(this.selectNumber.value, this.availableContexts.PERSON.data, false);
            }
            // only hyphens in the string
            else if (findHyphen && !findComma) {
                this.processHyphens(this.selectNumber.value, this.availableContexts.PERSON.data);
            }
        }
        // If parseInt operation succeeded, input only contains a valid number without any commas or hyphens
        else {
            // Check to see if number lies within the length of records opened in the dialog and is greater than 0
            if (this.selectNumber.value > 0 && this.selectNumber.value <= this.availableContexts.PERSON.data.length) {
                // Valid scenario. Enabled all buttons
                this.selectNumber.openDisabled = false;
                this.selectNumber.removeDisabled = false;
                this.selectNumber.favoriteDisabled = false;

                // Check if the item has not already been added to the this.selected collection. If not, push the item into this.selected collection
                let alreadyAdded = false;
                if (this.selected && this.selected.length > 0) {
                    for (let s = 0; s < this.selected.length; s++) {
                        if (this.selected[s]['@ID'] === this.availableContexts.PERSON.data[this.selectNumber.value - 1]['@ID']) {
                            alreadyAdded = true;
                            break;
                        }
                    }
                    if (!alreadyAdded) {
                        this.selected.push(this.availableContexts.PERSON.data[this.selectNumber.value - 1]);
                    }
                }
                // If there are no items in the this.selected collection, simply add it.
                else {
                    this.selected.push(this.availableContexts.PERSON.data[this.selectNumber.value - 1]); // Array indexes start from 0, therefore, subtract 1 from inputString to get the valid record.
                }
            }
            // If input number lies beyond the range of total records length, disable all buttons.
            else if (this.selectNumber.value > this.availableContexts.PERSON.data.length) {
                this.selectNumber.openDisabled = true;
                this.selectNumber.removeDisabled = true;
                this.selectNumber.favoriteDisabled = true;

                // Clear all the selected items in case an invalid card number is provided.
                this.selected = [];
            }
        }

        _.each(this.selected, (rec: any) => {
            if (rec.ID == this.availableContexts.PERSON.data[this.availableContexts.PERSON.position].ID) {
                this.selectNumber.removeDisabled = true;
            }
        })
    }

    /**
     * Enables/disables Open, Remove, Favorites buttons based on the string with commas and adds the correct card numbers to the _selected array
     * @param value
     * @param totalRecords
     * @param processHyphens
     */
    private processCommasHyphens(value: any, totalRecords: any, processHyphens: boolean) {
        let commaArr = value.split(",");
        if (commaArr && commaArr.length > 0) {
            for (let i = 0; i < commaArr.length; i++) {
                if (commaArr[i] != "" && !isNaN(commaArr[i])) {
                    // If it is a valid number, that number should be greater than 0 and <= the total records being displayed.
                    if (commaArr[i] > 0 && commaArr[i] <= totalRecords.length) {
                        // If in the comma separated list, there is at least one number, then enable the remove and favorite buttons
                        this.selectNumber.removeDisabled = false;
                        this.selectNumber.favoriteDisabled = false;

                        // Check if the item has not already been added to the _selected collection. If not, push the item into _selected collection
                        let alreadyAdded = false;
                        if (this.selected && this.selected.length > 0) {
                            for (let s = 0; s < this.selected.length; s++) {
                                if (this.selected[s]['@ID'] === totalRecords[commaArr[i] - 1]['@ID']) {
                                    alreadyAdded = true;
                                    break;
                                }
                            }
                            if (!alreadyAdded) {
                                this.selected.push(totalRecords[commaArr[i] - 1]);
                            }
                        }
                        // If there are no items in the this.selected collection, simply add it.
                        // Add the entered card numbe - 1 to this.selected array. Array indexes start from 0 so we need to subtract 1.
                        else {
                            this.selected.push(totalRecords[commaArr[i] - 1]);
                        }
                    }
                    else if (commaArr[i] > totalRecords.length) {
                        // Disable the buttons because a number greater than the total records has been entered.
                        this.selectNumber.removeDisabled = true;
                        this.selectNumber.favoriteDisabled = true;

                        // Clear all the selected items in case an invalid card number is provided.
                        this.selected = [];

                        break;
                    }
                }
                else if (processHyphens) {
                    this.processHyphens(commaArr[i], totalRecords);
                }
            }
        }
    }

    /**
     * Enables/disables Open, Remove, Favorites buttons based on the string with hyphens and adds the correct card numbers to the this.selected array
     * @param value
     * @param totalRecords
     */
    private processHyphens(value: any, totalRecords: any) {
        // If there are consecutive hyphens, convert all those hyphens to single hyphens and then process the string to get the range.
        let replaceStr = value.replace(/(-)+/g, "-");

        let hyphenArr = replaceStr.split("-");
        if (hyphenArr && hyphenArr.length >= 2) {
            for (let j = 0; j < hyphenArr.length - 1; j++) {
                let leftRange = parseInt(hyphenArr[j]);
                let rightRange = parseInt(hyphenArr[j + 1]);

                // Check if the input range numbers are not empty and are valid numbers.
                if (!isNaN(leftRange) && !isNaN(rightRange)) {
                    // If range contains valid numbers, leftRange should be <= rightRange.
                    // Also, both leftRange and rightRange should be greater than 0 and <= the total records being displayed.
                    if (leftRange <= rightRange && leftRange > 0 && rightRange > 0 && leftRange <= totalRecords.length && rightRange <= totalRecords.length) {
                        // If in the hyphen range, both the left and right range are integers, enable buttons
                        this.selectNumber.removeDisabled = false;
                        this.selectNumber.favoriteDisabled = false;

                        // Enter all card numbers from leftRange to rightRange to this.selected array. Array indexes start from 0 so we need to subtract 1.
                        for (let l = leftRange; l <= rightRange; l++) {
                            // Check if the item has not already been added to the this.selected collection. If not, push the item into this.selected collection
                            let alreadyAdded = false;
                            if (this.selected && this.selected.length > 0) {
                                for (let s = 0; s < this.selected.length; s++) {
                                    if (this.selected[s]['@ID'] === totalRecords[l - 1]['@ID']) {
                                        alreadyAdded = true;
                                        break;
                                    }
                                }
                                if (!alreadyAdded) {
                                    this.selected.push(totalRecords[l - 1]);
                                }
                            }
                            // If there are no items in the this.selected collection, simply add it.
                            else {
                                this.selected.push(totalRecords[l - 1]);
                            }
                        }
                    }
                    else if (leftRange > totalRecords.length || rightRange > totalRecords.length) {
                        // Disable the buttons because a number, in the range, greater than the total records has been entered.
                        this.selectNumber.removeDisabled = true;
                        this.selectNumber.favoriteDisabled = true;

                        // Clear all the selected items in case an invalid card numbers range is provided.
                        this.selected = [];

                        break;
                    }
                }
            }
        }
    }

    /**
     * Sets the screen reader text for each person context
     * @param contextRecord
     * @returns {string}
     */
    private setAriaLabelText(contextRecord: any): string {
        // Add screen reader text
        // Set privacy flag
        let labelText: string = "";

        let privacyRequest: boolean = false;
        if (!(contextRecord.hasOwnProperty("PRIVACY.FLAG") && !this.validationService.isNullOrEmpty(contextRecord["PRIVACY.FLAG"])) && contextRecord["PRIVACY.FLAG"].toLowerCase() === "s")
            privacyRequest = true;

        // Set the text inside the label for 'R'.
        labelText = "Context card";
        // Select the row number
        if (contextRecord.hasOwnProperty("PCC.FULL.NAME") && !this.validationService.isNullOrEmpty(contextRecord["PCC.FULL.NAME"]))
            labelText += " " + contextRecord["PCC.FULL.NAME"];
        if (contextRecord.hasOwnProperty("@ID") && !this.validationService.isNullOrEmpty(contextRecord["@ID"]))
            labelText += " with ID " + contextRecord["@ID"];
        labelText += this.isSelected(contextRecord) ? " is currently selected." : " is currently not selected.";
        // This only needs to be checked for grid view. For card view, the message will be read automatically
        if (privacyRequest)
            labelText += " You can't access this record due to the person's request for privacy.";
        labelText += " Press space bar to toggle selection.";

        return labelText;
    }

    /**
     * Returns the privacy message
     * @param record
     * @returns {string}
     */
    private viewAllContextPrivacyMessage(record: any): string {
        //Return null. This means there is no privacy code associated with this search record.
        if ((record['PRIVACY.FLAG'] != null && record['PRIVACY.FLAG'].length == 0) || record['PRIVACY.FLAG'] == null) {
            return null;
        }

        if (record['PRIVACY.FLAG'].constructor != Array) {
            record['PRIVACY.FLAG'] = [record['PRIVACY.FLAG']];
        }

        let showError: boolean;
        let foundPrivacyCode: string = "";

        if (this.contextService.context.PERSON.ResultPage.context === Models.HelperText.person) {
            showError = true;
            foundPrivacyCode = "";
        }

        _.each(this.preferencesService.savedPreferences.PrivacyCodeAccess, (prefPrivacyCode: any) => {
            _.each(record['PRIVACY.FLAG'], (pFlag: any) => {
                if (pFlag === prefPrivacyCode) {
                    foundPrivacyCode = pFlag;
                    showError = false;
                }
            });
        });

        if (showError) {
            return this.configurationService.getPersonRecordDenialMessage();
        }

        return null;
    }
}
