/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Injectable} from "@angular/core";
import {AnalyticsService} from "./";

@Injectable()
export class InstrumentationService {
    constructor(private analyticsService: AnalyticsService) {

    }

    event(category: any, action: any, label: any = null, value: any = null) {
        this.analyticsService.trackEvent(category, action, label, value);
    }

    page(name: any, title: any, version: any) {
        this.analyticsService.trackPage(name, title, version);
    }
}
