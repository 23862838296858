/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component} from "@angular/core";
import {ConfigurationService} from "../../services";

@Component({
    selector: 'configuration',
    template: require('./configuration.component.html')
})
export class ConfigurationComponent {
    constructor(private configurationService: ConfigurationService) {

    }
}