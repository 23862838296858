/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit, Inject } from '@angular/core';
import {
    RootScopeService,
    ChangePasswordService,
    ConfigurationService,
    PromptService,
    EventsService,
    LoginService
} from "../../services";

@Component({
    selector: 'changepassword',
    template: require('./changePassword.component.html')
})
export class ChangePasswordComponent implements OnInit {
    passwordChangedDialogOpenedId: string = "";

    constructor(private rootScopeService: RootScopeService, private changePasswordService: ChangePasswordService, private eventsService: EventsService,
        private configurationService: ConfigurationService, private promptService: PromptService, private loginService: LoginService) {
        this.resetChangePasswordModel();
        this.passwordChangedDialogOpenedId = this.eventsService.on("PasswordChangeDialogOpened", [this.passwordChangeDialogOpenedListener, this]);
    }

    ngOnInit() {

    }

    changePasswordModel: any = null;
    ChangePasswordUnderProgress: boolean = false;

    resetChangePasswordModel() {
        this.changePasswordModel = { userID: "", currentPassword: "", newPassword: "", confirmNewPassword: "" };
        if (this.rootScopeService.isAuthenticated) {
            this.changePasswordModel.userID = this.rootScopeService.userId;
        }
    }

    passwordChangeDialogOpenedListener(comp: any) {
        let self = <ChangePasswordComponent>comp;
        self.resetChangePasswordModel();
    }

    changePasswordSuccess(comp: any) {
        let self = <ChangePasswordComponent>comp;

        self.changePasswordService.closeChangePasswordDialog();
        self.ChangePasswordUnderProgress = false;

        let isAuthenticated = self.rootScopeService.isAuthenticated;

        if (isAuthenticated === undefined || isAuthenticated == false) {
            let request: any = {};
            request.UserID = self.changePasswordModel.userID;
            ;
            request.UserPassword = self.changePasswordModel.newPassword;
            request.DatabasePassword = '';
            request.DasConnectString = '';
            request.WebServiceUrl = self.configurationService.getConfiguration().serviceUrl;
            request.IsSSO = false;
            request = JSON.stringify(request);

            self.loginService.login(request);
        } else {
            var alertMessage: any = {
                text: ["Your password was successfully changed and will take effect upon next log in."]
            }
            alertMessage.defaultCallbackNumber = 0;
            alertMessage.buttons = [{
                label: "Ok",
                callback: () => {
                    self.promptService.popupMessageDismiss(() => {
                    }, false);
                },
                title: ""
            }];

            self.promptService.popupMessageShow(alertMessage);
        }
    }

    changePasswordFailure(comp: any) {
        let self = <ChangePasswordComponent>comp;
        self.ChangePasswordUnderProgress = false;
    }

    submit() {
        this.ChangePasswordUnderProgress = true;

        this.changePasswordService.errors = [];
        if (this.changePasswordModel.newPassword != this.changePasswordModel.confirmNewPassword) {
            this.changePasswordService.errors.push("'New Password' and 'Confirm New Password' doesn't match.");
            this.ChangePasswordUnderProgress = false;
            return;
        }

        this.changePasswordService.submit(this.changePasswordModel, this.changePasswordSuccess, this.changePasswordFailure, this, this);

    }

    cancel() {
        this.changePasswordService.closeChangePasswordDialog();
        document.getElementById('anchorChangePassword').focus();
    }

    ngOnDestroy() {
        this.eventsService.destroy("PasswordChangeDialogOpened", this.passwordChangedDialogOpenedId);
    }

}