/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Inject } from "@angular/core";
import {
    HelperService,
    SubMenuDialogService,
    ConfigurationService,
    ServerCommandService,
    FileService,
    SessionService,
    ProcessingService
} from "../../../services";
import * as Models from "../../../models";

declare var FormData: any;
declare var $: any;
declare var XMLHttpRequest: any;

@Component({
    selector: 'fileupload',
    template: require('./file-upload.component.html')
})
export class FileUploadComponent {
    uploadedPart: number = 0;
    totalParts: number = 0;
    uploading: boolean = false;
    error: string = "";
    errors: any = [];
    ellipses: any;
    ellipsesCounter: any;
    count: number = 0;
    partCount: number = 0;
    fileChunk: any;

    constructor(private serverCommandService: ServerCommandService,
        private configurationService: ConfigurationService,
        private sessionService: SessionService,
        private helperService: HelperService,
        private fileService: FileService, private processingService: ProcessingService) {

    }

    /**
     * Progress bar update
     * @returns {number}
     */
    graphValue(): number {
        return Math.floor((this.uploadedPart / this.totalParts) * 100);
    }

    /**
     * Closes the file upload modal on either Cancel or OK buttons
     * @param sendServerMessage
     * @returns {boolean}
     */
    closeModal(sendServerMessage: boolean): void {
        $('#upload-file .modal').modal('hide');
        this.fileService.isUploadOpen = false;

        // do not send screen cancel if modal being closed after file upload complete.
        if (sendServerMessage != false)
            this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.screenCancel, "", "");
        if (this.ellipsesCounter != null)
            clearInterval(this.ellipsesCounter);
        // TODO: What is dismiss()?
        // this.dismiss();
        try {
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = null;
            xhr.abort();
            this.uploading = false;
            this.error = "";
        } catch (e) {

        }
    }

    /**
     * Handles file selection by user on file upload
     * @param element
     */
    fileSelected(event: any) {
        let target = event.target || event.srcElement;
        if (target != null) {
            if (target.files && target.files.length == 1) {
                this.uploading = true;
                this.ellipsesCounter = setInterval(() => {
                    this.updateEllipses()
                }, 3000);
                this.error = "";
                this.uploadFile(target.files[0]);
            }
        }
    }

    /**
     * Upload the file
     * @param file
     */
    uploadFile(file: any) {
        this.partCount = 0;
        this.uploadedPart = 0;
        this.totalParts = 0;
        this.errors = [];
        this.error = '';
        let extension: any = file.name.substring(file.name.lastIndexOf(".") + 1);
        // create array to store the buffer chunks
        this.fileChunk = [];
        // the file object itself that we will work with
        //  var file = TargetFile[0];
        // set up other initial vars
        let MaxFileSizeMB: any = 1;
        let BufferChunkSize = MaxFileSizeMB * (1024 * 1024);
        let FileStreamPos = 0;
        // set the initial chunk length
        let EndPos = BufferChunkSize;
        let Size = file.size;

        // in case file is empty.
        // CR-000158362
        // CUI-5298
        if (file.size == 0) {
            this.fileChunk.push(file.slice(0, 0));
        }
        else {
            // add to the FileChunk array until we get to the end of the file
            while (FileStreamPos < Size) {
                // "slice" the file from the starting position/offset, to  the required length
                this.fileChunk.push(file.slice(FileStreamPos, EndPos));
                FileStreamPos = EndPos; // jump by the amount read
                EndPos = FileStreamPos + BufferChunkSize; // set next chunk length
            }
        }
        // get total number of "file chunks" we will be sending
        this.totalParts = this.fileChunk.length;
        let fileName = this.fileService.settings.FileName;

        //send one chunk and wait to verify the size.
        let chunk = this.fileChunk.shift();
        this.partCount++;
        let filePartName = fileName + "_temp.part_" + this.partCount + "." + this.totalParts;
        this.uploadFileChunk(chunk, filePartName, extension);

        // loop through, pulling the first item from the array each time and sending it
        setTimeout(() => {
            while (chunk = this.fileChunk.shift()) {
                this.partCount++;
                // file name convention
                let filePartName = fileName + "_temp.part_" + this.partCount + "." + this.totalParts;
                // send the file only if there aren't any errors
                if (this.error == '' && this.errors.length == 0) {
                    this.uploadFileChunk(chunk, filePartName, extension);
                }
            }
        }, 5000);
    }

    uploadFileChunk(chunk: any, filePartName: string, extension: string) {
        let webAPIBaseUrl: string = this.configurationService.getConfiguration().serviceUrl;
        let operatorID: string = this.sessionService.getOperatorId();
        let securityToken = this.sessionService.getToken();
        let fileName = this.fileService.settings.FileName;

        let FD = new FormData();
        FD.append('file', chunk, filePartName);
        $.ajax({
            type: "POST",
            url: [webAPIBaseUrl, 'file/uploadFile/create', operatorID, securityToken, fileName, "." + extension].join('/'),
            contentType: false,
            processData: false,
            data: FD,
            success: (response: any) => {
                this.uploadedPart += 1;
                if (this.uploadedPart == this.totalParts) {

                    let data: any = this.helperService.xmlDomToObject(response).SimpleMessageResponse;
                    if (data && data.Errors && data.Errors.ErrorMessageResponse) {
                        data.Errors = [data.Errors.ErrorMessageResponse];
                    }

                    if (data.Errors && data.Errors.length > 0) {
                        this.errors = data.Errors;
                    }
                    else {
                        if (this.ellipsesCounter != null)
                            clearInterval(this.ellipsesCounter);

                        this.processingService.showProcessing("File Upload", "Processing ...", "Processing Uploaded File ...");
                        this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.downloadFile, "", this.fileService.settings.FileName);
                        this.closeModal(false);

                    }
                }
                else {
                    let data = this.helperService.xmlDomToObject(response).SimpleMessageResponse;
                    if (data && data.Errors && data.Errors.ErrorMessageResponse) {
                        data.Errors = [data.Errors.ErrorMessageResponse];
                    }
                    if (data.Errors && data.Errors.length > 0) {
                        this.errors = data.Errors;
                    }
                }
            },
            error: (error: any) => {
                if (this.ellipsesCounter != null)
                    clearInterval(this.ellipsesCounter);
                this.error = "There was an error while uploading the file. Please try again."
            }
        });
    }

    private updateEllipses() {
        let statusValue = "";
        if (this.totalParts != 0) {
            statusValue = "Uploaded " + this.uploadedPart + " mb of total " + this.totalParts + " mb.";
        }
        if (statusValue == "") {
            this.ellipses = "";
        }
        else {
            this.count++;
            if (this.count == 1) this.ellipses = " .";
            else if (this.count == 2) this.ellipses = " . .";
            else if (this.count == 3) this.ellipses = " . . .";
            else if (this.count == 4) this.ellipses = " . . . .";
            else {
                this.count = 0;
                this.ellipses = "";
            }
        }
    }
}