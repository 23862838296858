/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

export class EventSubscription {

    public id: string;
    public name: string;

    constructor(name: string, id: string) {
        this.id = id;
        this.name = name;
    }
}