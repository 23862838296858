/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import {
    ConfigurationService,
    ContextService,
    FavoritesService,
    FocusService,
    HelpService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    SessionService,
    ValidationService,
    PromptService
} from "../../services";
import * as Models from "../../models";

declare var $: any;
import * as _ from 'lodash';

@Component({
    selector: 'addtofavorites',
    template: require('./add-to-favorites.component.html')
})
export class AddToFavoritesComponent {
    createNewFolderDialog: string = "#create-new-folder-dialog";
    folderCreateSuccesDialog: string = "#folder-create-succes-dialog";
    addToFavoritesDialog: string = "#add-to-favorites-dialog";
    btnAddToFavorites: string = "#btn_add-to-favorites";
    favoritesDialog: string = "#favorites-dialog";
    formFavoritesButton: string = "#form-sr-favorites-btn";
    updatePending: boolean = false;

    constructor(private favoritesService: FavoritesService,
        private focusService: FocusService,
        private helpService: HelpService,
        private configurationService: ConfigurationService,
        private rootScopeService: RootScopeService,
        private validationService: ValidationService,
        private notificationsService: PromptService) {

    }

    /**
     * Opens add-to-favorites help
     * @param helpID
     */
    help(helpID: string) {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses[helpID]);
    }

    namesListAriaLabel(addData: any) {
        let labelText = "";
        if (!this.validationService.isNullOrEmpty(addData)) {
            if (!this.validationService.isNullOrEmpty(addData.type)) {
                labelText += addData.type === "FORM" ? "Form Name" : "People Names";
                labelText += ": ";
            }

            if (addData.entries && addData.entries.length > 0) {
                for (let d = 0; d < addData.entries.length; d++) {
                    labelText += addData.entries[d].ItemLabel;
                    labelText += d != addData.entries.length - 1 ? ", " : ". ";
                }
            }
        }

        return labelText;
    }

    addEntries() {
        let type: string = this.favoritesService.addData.type;
        let root: any = this.favoritesService.favorites[type];
        let resolveObject: string = "Success";
        this.favoritesService.folderToCreate = "";
        if (type === Models.HelperText.form) {
            // Get form being added, prepare it to update and make sure it is not a duplicate
            let addToFolder: any = this.favoritesService.addData.selectedAddToFolder[this.favoritesService.addData.type];
            let form: any = this.favoritesService.createFavoriteForm(this.favoritesService.addData.entries[0], addToFolder.FolderID);
            if (this.favoritesService.inFavorites(root, form) === true) {
                $(this.createNewFolderDialog).modal('hide');
                let msg = [
                    "The following items were not added because another entry with the same id already exists within the target folder:"
                    ,
                    '',
                    form.ItemLabel
                ];
                this.favoritesService.duplicateEntriesAlertMsg("Add Favorites Error", msg);
            } else {
                // No obvious issues, send request to server to commit any new folders and this form
                this.favoritesService.entriesToCommit = [form];
                this.favoritesService.addFormItems(resolveObject, this.favoritesService.foldersToCommit, this.favoritesService.entriesToCommit, this.addSuccess, this.addFailure, this);
                $(this.folderCreateSuccesDialog).modal('show');
                setTimeout(() => {
                    let addToFavoritesBtn = document.querySelector(this.folderCreateSuccesDialog + " " + this.btnAddToFavorites) as HTMLElement;
                    addToFavoritesBtn.focus();
                }, 250);
            }
        } else {
            if (type === Models.HelperText.person) {
                // Send request to commit any new folders and people added
                this.favoritesService.entriesToCommit = [];
                let duplicateEntries = [];
                for (let i = 0; i < this.favoritesService.addData.entries.length; i++) {
                    let addToFolder = this.favoritesService.addData.selectedAddToFolder[this.favoritesService.addData.type];
                    let person = this.favoritesService.createFavoritePerson(this.favoritesService.addData.entries[i], addToFolder.FolderID);

                    if (this.favoritesService.inFavorites(root, person) == true) {
                        duplicateEntries.push(person);
                    } else {
                        this.favoritesService.entriesToCommit.push(person);
                    }
                }

                // Report if all persons are duplicate entries that are skipped
                if (duplicateEntries.length == this.favoritesService.addData.entries.length) {
                    this.rootScopeService.createFolder = false;
                    $(this.createNewFolderDialog).modal('hide');
                    let msg = [
                        "The following items were not added because another entry with the same id already exists within the target folder:",
                        ''
                    ];
                    for (let i = 0; i < duplicateEntries.length; i++) {
                        msg.push(duplicateEntries[i].ItemLabel);
                    }
                    this.favoritesService.duplicateEntriesAlertMsg("Add Favorites Error", msg);
                    // If any one person not duplicate pop up create new folder
                } else if (this.favoritesService.entriesToCommit.length > 0 && duplicateEntries.length > 0) {
                    this.openCreateNewFolder();
                }
                else {
                    // Ok to add, put it into the array to commit
                    this.favoritesService.addPersonItems(resolveObject, this.favoritesService.foldersToCommit, this.favoritesService.entriesToCommit, this.addSuccess, this.addFailure, this);
                    $(this.folderCreateSuccesDialog).modal('show');
                    setTimeout(() => {
                        let addToFavoritesBtn = document.querySelector(this.folderCreateSuccesDialog + " " + this.btnAddToFavorites) as HTMLElement;
                        addToFavoritesBtn.focus();
                    }, 250);
                }
            }
        }
    }

    /**
     * Modal window for create new folder
     */
    openCreateNewFolder() {
        this.closeAddToFavorites();
        this.favoritesService.addData.selectedCreateInFolder = this.favoritesService.addData.selectedAddToFolder[this.favoritesService.addData.type];
        this.favoritesService.folderToCreate = "";
        $(this.createNewFolderDialog).modal('show');
        this.rootScopeService.FavActiveHelp = true;
        setTimeout(() => {
            document.getElementById("folderName").focus();
        }, 200);
    }

    closeAddToFavorites() {
        this.rootScopeService.FavActiveHelp = true;
        this.updatePending = false;
        this.favoritesService.folderToCreate = "";
        this.favoritesService.entriesToCommit = [];
        $(this.addToFavoritesDialog).modal('hide');
        this.favoritesService.addData.selectedAddToFolder[this.favoritesService.addData.type] = this.favoritesService.addData.folders[0];
        setTimeout(() => {
            // If favorites dialog is open, focus on add to favorites icon in header of the favorites dialog otherwise shift focus to the previous focus field(either in form or on the search icon)
            if ($(this.favoritesDialog).is(":visible")) {
                let addToFavoritesBtn = document.querySelector(this.favoritesDialog + " #btnAddToFavoritesIcon") as HTMLElement;
                addToFavoritesBtn.focus();
            }
            else if ($(this.formFavoritesButton).is(":visible")) {
                document.getElementById("form-sr-favorites-btn").focus();
            }
            else {
                this.focusService.focusPreviousField();
                this.focusService.selectPreviousField();
            }
        }, 100);
    }

    /**
     * Tab on Cancel button should focus on Help button
     * @param event
     */
    btnCancelFavoriteKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            this.focusService.focusOn("btnAddToFavoritesHelp", true);
        }
    }

    /**
     * Shift tab on help button should focus on Cancel button in footer
     * @param event
     */
    btnAddToFavoritesHelpKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            this.focusService.focusOn("btn_cancel_favorite", true);
        }
    }

    private addSuccess(addToFavoritesComponent: AddToFavoritesComponent) {
        let self: AddToFavoritesComponent = <AddToFavoritesComponent>addToFavoritesComponent;
        // Successfull commit of server side favorites
        let type = self.favoritesService.addData.type;
        let root = self.favoritesService.favorites[type];
        // Now update the current favorites dialog to have the latest folder additions
        for (let i = 0; i < self.favoritesService.foldersToCommit.length; i++) {
            self.favoritesService.insertFavoriteObject(root, self.favoritesService.foldersToCommit[i]);
        }
        // Now any entries added to the favorites, form or person
        _.each(self.favoritesService.entriesToCommit, (entry) => {
            self.favoritesService.insertFavoriteObject(root, entry);
        });
        self.favoritesService.foldersToCommit = [];
        self.closeAddToFavorites();
    }

    private addFailure(addToFavoritesComponent: AddToFavoritesComponent, error: any) {
        let msg = "Failed to update favorites: " + error;
        let self: AddToFavoritesComponent = <AddToFavoritesComponent>addToFavoritesComponent;
        self.notificationsService.popupMessage("Favorites Update Error", msg, null);
        self.updatePending = false;
    }
}
