/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component} from "@angular/core";
import {FocusService, PrivacyPolicyService} from "../../services";
import * as Models from "../../models";

@Component({
    selector: 'privacyPolicy',
    template: require('./privacyPolicy.component.html')
})
export class PrivacyPolicyComponent {
    constructor(private focusService: FocusService,
                private privacyPolicyService: PrivacyPolicyService) {

    }

    gaPrivacyPolicyLinkKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("privacyPolicy-close-btn", true);
        }
    }

    privacyPolicyCloseBtnKeydown(event: any){
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("gaPrivacyPolicyLink", true);
        }
    }
}