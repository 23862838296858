/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import { AboutService } from "../../services";
import * as Models from "../../models";

@Component({
    selector: 'about',
    template: require('./about.component.html')
})
export class AboutComponent {
    copyrightMessage: string = "2010-2021 Ellucian Company L.P. All Rights Reserved.";
    version: string = "5.20";

    constructor(private aboutService: AboutService) {
    }

    aboutCloseBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;

        if (tab) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
    }
}