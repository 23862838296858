/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component, OnInit, Input, OnDestroy, Inject} from '@angular/core';
import {FormService, RootScopeService, EventsService} from '../../../services'
import * as Models from "../../../models"

@Component({
    selector: 'uiform',
    template: require('./form.component.html')
})
export class FormComponent implements OnInit, OnDestroy {

    // @Input() form: any;
    showHeaderShadow: boolean = false;
    showHeaderWindowController: boolean = false;

    constructor(private formService: FormService, private rootScope: RootScopeService, private events: EventsService) {
    }

    ngOnInit() {
    }


    /**
     * Check if a field is hidden on the given form
     * @param form
     * @param elementID
     */
    isFieldHidden(form: any, elementID: string): boolean {
        for (var hf in this.formService.hideHeaderFields) {
            var hiddenField = this.formService.hideHeaderFields[hf];
            if (hiddenField.formID == form.formId) {
                if (hiddenField.fieldName == elementID) {
                    return true;
                }
            }
        }
        return false;
    };


    orderIconClick(customElementOrder: number): void {
        this.events.broadcast(Models.EventConstants.changeCurrentFieldListener, customElementOrder);
        this.events.broadcast(Models.EventConstants.elementOrderSelectedListener, customElementOrder);

        this.events.broadcast(Models.EventConstants.changeCurrentFieldListener, customElementOrder);
        this.events.broadcast(Models.EventConstants.elementOrderSelectedListener, customElementOrder);
    }

    replaceSpace(value: string): string {
        if (value == null) return '';
        return value.replace(/ /g, '&nbsp;');
    }

    ngOnDestroy() {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
    }
}