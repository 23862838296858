/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable, Inject } from '@angular/core';
import { FormService, ValidationService, EventsService } from "./"
import * as Models from "../models"
declare let CSSOM: any;
declare let $: any;
import * as _ from 'lodash';

@Injectable()
export class RendererService {

    constructor(private validationService: ValidationService, private formService: FormService, private eventsService: EventsService) {
    }

    private loadedStyles: any = [];

    clearStyles() {
        for (let i = 0; i < this.loadedStyles.length; i++) {
            $(this.loadedStyles[i]).remove();
        }
        this.loadedStyles = [];
    }

    calculateFormPosition(activeFormId: any, elementList: any, windowList: any, lastRow: any, lastColumn: any, bottomHeaderRow: any, combinedFormId: any, formId: any) {
        activeFormId = activeFormId.replace(/\./g, '-');
        combinedFormId = combinedFormId.replace(/\./g, '-');
        // Set defaults for positioning Constants
        let defaultUnit = "rem";
        let defaultUnitMultiplier = 10; //10 pixels per rem default
        // Get the current dimensions of the form area
        let styleElementId = combinedFormId + "_style";
        let styleElement = $("#" + styleElementId);
        //let formHeight = $("#ui-form").height();
        let baseRows = 24;
        let baseColumns = 80;
        let rowHeight = 2;
        let headerRowHeight = 0.8;
        let formWidth = $("#ui-form").width();
        //default form size incase form is not rendered.
        if (this.validationService.isNullOrEmpty(formWidth) || formWidth === NaN) formWidth = 973;
        let totalRem = formWidth / defaultUnitMultiplier;
        defaultUnitMultiplier = formWidth / baseColumns;
        let columnWidth = formWidth / (baseColumns * defaultUnitMultiplier);

        formWidth = null;

        let localWindowList = windowList;
        let rowCols = 0;
        let defaultleft = 11;

        // If this is the first execution of the form, set the head and style elements for the form
        if (!styleElement.length) {
            let head = document.getElementsByTagName('head')[0];
            let style = document.createElement('style');

            style.id = styleElementId;
            style.type = 'text/css';
            style.innerHTML = '';

            head.appendChild(style);

            styleElement = $("#" + styleElementId);

            this.loadedStyles.push("#" + styleElementId);
        }
        else {
            return;
        }

        let styleContent = styleElement.text();
        let styleModel = CSSOM.parse(styleContent);
        let prevElementLeftPos: number = 0;
        let prevElementWidth: number = 0;
        let elementLeftPos: number = 0;
        let prevElementID: string;

        let lowestRowNumber: number = 25;

        let headerLowestRowNumber: number = 25;

        let highestHeadersRows: number = 0;

        let noofHeadersRows: number = 0;

        let noOfHeaderWindowControl = [];

        // calculate lowest non header element row 
        // calculate total header rows
        // calculate lowest header element row, so that other non-header elements can be shifted up if required.
        _.each(elementList, (element: any) => {

            if (element.elementInHeader != 'Y' && element.formLabelIsInHeader != 'Y') {  //Top adjustment for non header elements 
                if (element.elementRow < lowestRowNumber) {
                    lowestRowNumber = element.elementRow;
                }

            } else if ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') && element.elementType != Models.ElementType.phantom) { // && !this.validationService.isNullOrEmpty(element.elementWindowController)) {

                let found = false;

                if (!this.validationService.isNullOrEmpty(element.elementWindowController)) {
                    for (let hf in this.formService.hideHeaderFields) {
                        let hiddenField = this.formService.hideHeaderFields[hf];

                        if (hiddenField && formId == hiddenField.formID && hiddenField.fieldName == element.originalElementID) {
                            found = true;
                            break;
                        }
                    }
                }

                if (found == false) {
                    if (element.elementRow < headerLowestRowNumber) {  //Top adjustment for header elements 
                        headerLowestRowNumber = element.elementRow;
                    }
                    if (element.elementRow > highestHeadersRows) {
                        highestHeadersRows = element.elementRow;
                    }
                    noofHeadersRows = highestHeadersRows - headerLowestRowNumber + 1; // 
                }
            }
        });


        // adjust element row for all elements based calulated number of header rows and lowest header row.
        _.each(elementList, (element: any) => {
            if (element.elementInHeader != 'Y' && element.formLabelIsInHeader != 'Y' && lowestRowNumber > 1) { //Top adjustment for non header elements 
                element.elementRow -= parseInt((lowestRowNumber - (noofHeadersRows - (noofHeadersRows / 2) + (noofHeadersRows / 4))).toString()); // (noofHeadersRows > 4 ? ((noofHeadersRows / 2) + 1) : 0))));
            } else if ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') && element.elementType != Models.ElementType.phantom) {
                if (headerLowestRowNumber < 25) { //Top adjustment for header elements 
                    element.elementRow -= (headerLowestRowNumber - 1);
                }
            }
        });

        let existingRule: boolean = false;
        // Loop over the elements defined for the form and create individual styles and positions.
        let downArrowElementOverrides = {};
        _.each(elementList, (element: any) => {
            let isMultiValued = (element.elementWindowController !== null);
            // Set the css selector values to use for styling
            let elementSelector = "." + combinedFormId + " div." + element.sanitizedID;
            let labelSelector = "." + combinedFormId + " .label-wrapper." + element.sanitizedID;
            let inputSelector = "." + combinedFormId + " input." + element.sanitizedID + "x";
            let valcodeSelector = "." + combinedFormId + " ." + element.sanitizedID + "-valCode";
            let lookupSelector = "." + combinedFormId + " ." + element.sanitizedID + "-lookup_add_on";
            let detailSelector = "." + combinedFormId + " ." + element.sanitizedID + "-detail_icon";
            let calendarSelector = "." + combinedFormId + " ." + element.sanitizedID + "-calendar-add-on";
            let calculatorSelector = "." + combinedFormId + " ." + element.sanitizedID + "-calculator_icon";
            let downArrowSelector = "." + combinedFormId + " ." + element.sanitizedID + "-down_arrow";

            let lookupElementOverrides = {};
            let lookupRightAlign = 0.2;
            let detailElementOverrides = {};
            let detailIconTop = -21;
            let calendarElementOverrides = {}
            let calendarRightAlign = -10;
            let calculatorElementOverrides = {}
            let calculatorRightAlign = -9;
            downArrowElementOverrides = {};
            let downArrowRightAlign = 2;

            element.windowControllerSanitized;
            let buttonSelector = "";

            // remove the style if it already exist
            let found = -1
            for (let i = 0; (i < styleModel.cssRules.length && found == -1); i++) {
                if (styleModel.cssRules[i].selectorText == elementSelector) {
                    styleModel.cssRules = styleModel.cssRules.splice(i, 1)
                    break;
                }
            }


            let elementColumnPct = Math.ceil((element.elementColumn / lastColumn) * 100);

            let elementWidthPct: number = parseInt(((parseInt(element.elementDisplayWidth) / parseInt(lastColumn)) * 100).toString());

            // Determine the element's justification
            let elementAlign = Models.ElementAlign.center;
            if (element.elementJustification == Models.ElementJustification.right) {
                elementAlign = Models.ElementAlign.right;
            }
            else if (element.elementJustification == Models.ElementJustification.left) {
                elementAlign = Models.ElementAlign.left;
            }

            //Add spacing for detail, calendar, space, valcode and calculator widgets
            let detailWidgets = +parseInt(element.elementDetailWidgetCount);
            let dateWidgets = +parseInt(element.elementDateWidgetCount);
            let calcWidgets = +parseInt(element.elementCalculatorWidgetCount);
            let spaceWidgets = +parseInt(element.elementSpaceWidgetCount);
            let valcodeWidgets = +parseInt(element.elementValcodeWidgetCount);
            let noofWidgets = 0;
            // Set minimum display width as 2 for all elements if it has less than 2
            if (element.elementDisplayWidth < 2) {
                element.elementDisplayWidth = 2;
            }
            if (detailWidgets > 0) {
                element.elementColumn = +element.elementColumn + (detailWidgets * 4);
            }
            ;
            if (dateWidgets > 0) {
                element.elementColumn = +element.elementColumn + (dateWidgets * 4);
            }
            ;
            if (calcWidgets > 0) {
                element.elementColumn = +element.elementColumn + (calcWidgets * 4);
            }
            ;

            if (spaceWidgets > 0) {
                element.elementColumn = +element.elementColumn + (spaceWidgets);
            }
            ;

            if (valcodeWidgets > 0) {
                element.elementColumn = element.elementColumn + (valcodeWidgets);
            }
            ;

            if (element.elementCalc) {
                noofWidgets = noofWidgets + 1;
            }
            if (element.elementDate) {
                noofWidgets = noofWidgets + 1;
            }
            if (element.elementDetailable) {
                noofWidgets = noofWidgets + 1;
            }


            // Adjust the vertical alignment of labels
            let alignmentOffset = 0;
            if (element.elementType == Models.ElementType.label) {
                // Move top-oriented labels down from the line above
                if (element.elementPromptOrientation == Models.HelperText.top || element.elementInHeader == 'Y') {
                    //alignmentOffset = 5/ defaultUnitMultiplier;
                    alignmentOffset = 0;
                }
                else {
                    // Align left-oriented labels toward the center of the associated text box
                    alignmentOffset = 2 / defaultUnitMultiplier;

                }
            }
            ;

            elementLeftPos = (parseInt(element.elementColumn) * columnWidth);
            element.prevElementLeftPos = prevElementLeftPos;
            element.prevElementWidth = prevElementWidth;
            element.prevElementID = prevElementID;


            element.top = ((parseInt(element.elementRow) * ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') ? headerRowHeight : rowHeight)) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2);
            element.left = (parseInt(element.elementColumn) * columnWidth);
            // If previous element and current element have same left position increase the left position for current element
            if (!this.validationService.isNullOrEmpty(element.elementWindowController) && !this.validationService.isNullOrEmpty(element.prevElementID) && element.elementType != Models.ElementType.label) {

                let prevID = (element.prevElementID).split('_');
                let currID = (element.elementID).split('_');


                let prevElementPos = prevElementLeftPos + prevElementWidth;

                if (prevID[0] === currID[0] && element.windowOrientation != Models.WindowOrientation.horizontal) {
                    elementLeftPos = element.prevElementLeftPos;
                } else if (prevID[0] === currID[0] && (elementLeftPos - prevElementPos) <= 0) {

                    if (element.hasWindowButton == true) {
                        if (element.windowSequenceLength == 1)
                            elementLeftPos = prevElementPos + 1.5;
                        else
                            elementLeftPos = prevElementPos + 2.5;
                    }
                    else {
                        elementLeftPos = prevElementPos + 1.5;
                    }
                }
                else if (prevID[0] === currID[0] && element.windowOrientation == Models.WindowOrientation.horizontal) {
                    if (element.windowSequenceLength > 1) {
                        let index = parseInt(currID[1]);
                        if (index > 2) elementLeftPos += (index - 2);
                    }
                }
                //Adjust the horizontal window fields left position not first fields to avoid overlap
                if (element.windowOrientation == Models.WindowOrientation.horizontal && element.rowIndex > 1) {
                    // get previous row element's left and display width and adjust next element's left
                    let prevWindowElement = elementList[element.originalElementID + "_" + (element.rowIndex - 1)];

                    if (element.windowSequenceLength > 1) {
                        elementLeftPos = prevWindowElement.elementColumn + prevWindowElement.elementDisplayWidth + 7;
                    }
                    else if (element.windowSequenceLength == 1) {
                        elementLeftPos = prevWindowElement.elementColumn + prevWindowElement.elementDisplayWidth + 6;
                    }
                    else {
                        elementLeftPos = elementLeftPos + 1;
                    }
                }


            } else if ((elementLeftPos - (prevElementLeftPos + prevElementWidth)) == 0 && !this.validationService.isNullOrEmpty(element.elementWindowController)) {
                elementLeftPos = elementLeftPos + 1;
            }

            if (!this.validationService.isNullOrEmpty(element.elementWindowController) && element.elementPrompts != null) {
                if (element.elementPrompts[0].elementPromptOrientation == Models.HelperText.left || (element.elementPrompts.length > 1 && element.elementPrompts[1].elementPromptOrientation == Models.HelperText.left)) {
                    elementLeftPos = elementLeftPos + 1;
                    element.calculatedLeftPos = elementLeftPos; // store for future use
                }
            }
            else if (!this.validationService.isNullOrEmpty(element.elementWindowController) && element.elementPrompts == null && element.elementWindowController == element.originalElementID && element.windowOrientation != "horizontal") {
                let firstElement = elementList[element.elementWindowController + "_1"];
                if (firstElement != null && firstElement.elementPrompts != null && (firstElement.elementPrompts[0].elementPromptOrientation == Models.HelperText.left || (firstElement.elementPrompts.length > 1 && firstElement.elementPrompts[1].elementPromptOrientation == Models.HelperText.left))) {
                    elementLeftPos = firstElement.calculatedLeftPos; // allign left of all rows
                }
            }

            //For prompt text not required to increase width based on the widget
            if (element.elementID.indexOf(Models.HelperText.promptText) != -1) {
                noofWidgets = 0
            }


            // Style for input elements
            let elementOverrides: any = {};

            //Adjust the header label element position
            if ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') && element.elementType === Models.ElementType.label) {
                let elementWidth = parseInt(element.elementDisplayWidth) * columnWidth * 0.8;
                let elementRightPos = totalRem - (elementLeftPos - elementWidth);

                elementOverrides = {
                    left: elementLeftPos + defaultUnit,
                    top: ((parseInt(element.elementRow) * headerRowHeight) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2) + defaultUnit,
                    width: (parseInt(element.elementDisplayWidth) * columnWidth) + defaultUnit,
                    "text-align": "left",
                    "margin-top": alignmentOffset + defaultUnit,
                    "min-width": 2.2 + defaultUnit
                }
            }
            else {

                elementOverrides = {
                    left: elementLeftPos + defaultUnit,
                    top: (((parseInt(element.elementRow) * (element.elementInHeader == 'Y' ? headerRowHeight : rowHeight)) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2) + (element.headerRowNumber ? (element.headerRowNumber == 1 ? (0.8 * element.headerRowNumber) : (1.1 * element.headerRowNumber)) : 0)) + defaultUnit,
                    width: (parseInt(element.elementDisplayWidth) * columnWidth) + defaultUnit,
                    "text-align": elementAlign,
                    "margin-top": alignmentOffset + defaultUnit,
                    "min-width": 2.1 + defaultUnit
                }


            }


            let devWidth = parseInt(element.elementDisplayWidth + (noofWidgets * 2)) * columnWidth

            //style for parent div  for each element
            let devElementOverrides = {};
            //Adjust the header label element position
            if ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') && element.elementType === Models.ElementType.label) {
                let elementWidth = parseInt(element.elementDisplayWidth) * columnWidth * 0.8;
                let elementRightPos = totalRem - (elementLeftPos - elementWidth);
                devElementOverrides = {
                    left: elementLeftPos + defaultUnit,
                    top: ((parseInt(element.elementRow) * headerRowHeight) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2) + defaultUnit,
                    width: devWidth + defaultUnit,
                    "text-align": elementAlign,
                    "margin-top": alignmentOffset + defaultUnit,
                    "min-width": 2.2 + defaultUnit
                }
            }
            //TODO
            else if (element.elementType === Models.ElementType.valCode) {
                element.width += 1;
                devElementOverrides = {
                    left: elementLeftPos + defaultUnit,
                    top: ((parseInt(element.elementRow) * ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') ? headerRowHeight : rowHeight)) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2) + defaultUnit,
                    width: (devWidth + 1) + defaultUnit,
                    "text-align": elementAlign,
                    "margin-top": alignmentOffset + defaultUnit,
                    "min-width": 2.1 + defaultUnit
                }
            }
            else {

                devElementOverrides = {
                    left: elementLeftPos + defaultUnit,
                    top: (((parseInt(element.elementRow) * ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') ? headerRowHeight : rowHeight)) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2) + (element.headerRowNumber ? (element.headerRowNumber == 1 ? (0.8 * element.headerRowNumber) : (1.1 * element.headerRowNumber)) : 0)) + defaultUnit,
                    width: devWidth + defaultUnit,
                    "text-align": elementAlign,
                    "margin-top": alignmentOffset + defaultUnit,
                    "min-width": 2.1 + defaultUnit
                }

                // positioning for Form Graphics
                if (element.graphicType && element.elementType == Models.ElementType.graphic) {


                    if (element.graphicType == 'VL') {
                        devElementOverrides = {
                            left: (elementLeftPos) + defaultUnit,
                            top: ((parseInt(element.elementRow) * rowHeight) + parseInt(element.elementRow) + 1) + defaultUnit,
                            height: ((element.elementDisplayHeight * (rowHeight + 1.2)) + rowHeight) + defaultUnit,
                            "text-align": elementAlign,
                            "min-width": '1px'
                        }
                    }
                    if (element.graphicType == 'HL' || element.graphicType == 'LT' || element.graphicType == 'TT' || element.graphicType == 'RT') {

                        let newLeft = elementLeftPos;

                        if (element.originalFormGraphicType == 'C') {
                            newLeft = (elementLeftPos - devWidth / 2);
                        }

                        devElementOverrides = {
                            left: newLeft + defaultUnit,
                            top: ((parseInt(element.elementRow) * rowHeight) + parseInt(element.elementRow) + parseInt(element.elementRow) * 0.2 + 1) + defaultUnit,
                            width: devWidth + defaultUnit,
                            "text-align": elementAlign,
                            "min-width": '1px'
                        }
                    }
                }
            }

            //style for valcode element 
            let valCodeelementOverrides = {};
            valCodeelementOverrides = {
                width: ((parseInt(element.elementDisplayWidth) * columnWidth) + 1) + defaultUnit,
                "text-align": elementAlign,
                "margin-top": alignmentOffset + defaultUnit,
                "min-width": 2.1 + defaultUnit + " !important"
            }


            // style for look up icon

            if (noofWidgets > 0) {
                lookupRightAlign = 2;
            }
            lookupElementOverrides = {
                right: lookupRightAlign + defaultUnit
            }

            // style for detailable icon
            if (noofWidgets > 1 && element.elementDate) {
                detailIconTop = -43;
            } else if (noofWidgets > 1 && element.elementCalc) {
                detailIconTop = -20;
            }
            detailElementOverrides = {
                right: -9 + "px !important",
                top: 21 + "px !important"
            }

            // style for calendar icon
            if (noofWidgets > 1) {
                calendarRightAlign = 14;
            }
            calendarElementOverrides = {
                right: calendarRightAlign + "px !important",
            }

            // style for calculator icon
            if (noofWidgets > 1) {
                calculatorRightAlign = 14;
            }
            calculatorElementOverrides = {
                right: calculatorRightAlign + "px !important",
                top: 21 + "px !important"
            }
            // style for dropdown arrow icon
            if (noofWidgets > 0) {
                downArrowRightAlign = 22;
            }
            downArrowElementOverrides = {
                right: downArrowRightAlign + "px"
            }


            prevElementID = element.elementID;
            prevElementLeftPos = elementLeftPos;
            prevElementWidth = (parseInt(element.elementDisplayWidth) * columnWidth);
            if (element.elementCalc || element.elementDate || element.elementDetailable) {
                prevElementWidth += 4.9;
            }
            element.leftPos = elementLeftPos;

            if (element.elementPrompts != null) {
                if (element.elementType === Models.ElementType.label && element.elementPrompts[0].elementPromptOrientation != Models.HelperText.top) {
                    element.width = (parseInt(element.elementDisplayWidth) * columnWidth);
                } else if (element.elementType != Models.ElementType.label) {
                    element.width = (parseInt(element.elementDisplayWidth) * columnWidth);
                } else {
                    element.width = 0;
                }
            } else {
                element.width = (parseInt(element.elementDisplayWidth) * columnWidth);
            }

            element.isPrompt = false;
            if (element.sanitizedID.indexOf(Models.HelperText.prompttextWithoutUnderscore) > -1) {
                element.isPrompt = true;
            }

            if ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y')) {
                delete elementOverrides.width;
            }
            // Inject the css styling attributes into the input
            let elementRule = inputSelector + this.formService.convertToCss(elementOverrides);
            styleModel.insertRule(elementRule);

            // Inject the css styling attributes into the form input parent dev
            let devElementRule = elementSelector + this.formService.convertToCss(devElementOverrides);
            styleModel.insertRule(devElementRule);

            // Inject the css styling attributes into the form valCode
            let valCodeElementRule = valcodeSelector + this.formService.convertToCss(valCodeelementOverrides);
            styleModel.insertRule(valCodeElementRule);


            // Inject the css styling attributes into the form input lookup icon 
            if (element.elementLookUpFlag) {
                let lookupElementRule = lookupSelector + this.formService.convertToCss(lookupElementOverrides);
                styleModel.insertRule(lookupElementRule);

            }
            // Inject the css styling attributes into the form input detailable icon 
            if (element.elementDetailable) {
                let detailElementRule = detailSelector + this.formService.convertToCss(detailElementOverrides);
                styleModel.insertRule(detailElementRule);
            }

            // Inject the css styling attributes into the form input calendar icon 
            if (element.elementDate) {
                let calendarElementRule = calendarSelector + this.formService.convertToCss(calendarElementOverrides);
                styleModel.insertRule(calendarElementRule);

            }

            // Inject the css styling attributes into the form input calculator icon 
            if (element.elementCalc) {
                let calculatorElementRule = calculatorSelector + this.formService.convertToCss(calculatorElementOverrides);
                styleModel.insertRule(calculatorElementRule);

            }

            // Inject the css styling attributes into the form valCode downArrow icon 
            if (element.elementType == Models.ElementType.valCode) {
                let downArrowElementRule = downArrowSelector + this.formService.convertToCss(downArrowElementOverrides);
                styleModel.insertRule(downArrowElementRule);
            }


        });

        //Set the style for Header shadow  
        //Loop Over the header elements and calculate header top and header height for the background shadow.
        let headerTop = 10000;
        let headerHeight = 0;
        _.each(elementList, (element: any) => {

            if ((element.elementInHeader == 'Y' || element.formLabelIsInHeader == 'Y') && element.elementType != Models.ElementType.graphic && element.elementType != Models.ElementType.phantom) {

                let found = false;

                if (!this.validationService.isNullOrEmpty(element.elementWindowController)) {
                    for (let hf in this.formService.hideHeaderFields) {
                        let hiddenField = this.formService.hideHeaderFields[hf];

                        if (hiddenField && formId == hiddenField.formID && hiddenField.fieldName == element.originalElementID) {
                            found = true;
                            break;
                        }
                    }
                }

                if (found == false) {
                    if (headerTop > element.top) {
                        headerTop = element.top;
                    }
                    if (headerHeight < element.top) {
                        headerHeight = element.top;
                    }
                }
            }

        });

        // Set the styles for the header shadow
        let formHeaderShadowSelector = "." + combinedFormId + "_headerShadow";


        existingRule = _.find(styleModel.cssRules, (val: any) => {
            let tempResult = val.selectorText == formHeaderShadowSelector;
            return tempResult;
        });
        headerHeight = headerHeight + 1.1;


        // If this is the first form execution, set the css styling values for the form element
        if (!existingRule) {
            // Set the styling attributes for the header shadow
            let headerShadowOverrides = {
                height: headerHeight + defaultUnit,
                width: totalRem + defaultUnit
            }

            // Inject the css styling attributes into the header shadow
            let formHeaderRule = formHeaderShadowSelector + this.formService.convertToCss(headerShadowOverrides);
            styleModel.insertRule(formHeaderRule);

        }
        ;

        // Loop over the windows defined for the form to create window shadows
        _.each(windowList, (windowArray: any) => {
            // Determine the dimensions of the window shadow for this window, also used for window control
            // Set the css selector values to use for styling
            let envWindow = windowArray[0];
            let windowWidgetOffset = ((parseInt(envWindow.widgets) - 1) * 3);
            let windowLeft = 1000;
            let windowTop = 10000;
            let windowWidth = 0;
            let windowHeight = 0;
            rowCols = 0;
            let elementPromptsLength = 0;
            let orientation = "";
            let lastColLeftPos = 0;
            let lastColWidth = 0;
            let extraWidth = 0;
            let firstElementTop = 0;
            let windowButtonSmall = false, windowButtonLarge = false, windowButton = false;
            // loop through element                             
            _.each(elementList, (element: any) => {

                if (element.elementWindowController !== null) {
                    if (element.elementWindowController == windowArray[0].windowController && element.elementType !== Models.ElementType.label) {
                        if (windowLeft > element.leftPos) {
                            windowLeft = element.leftPos;
                            if (element.hasWindowButton == true) {
                                windowButton = true;
                                if (element.windowSequenceLength == 1) {
                                    windowLeft -= 4;
                                    windowButtonSmall = true;
                                }
                                else {
                                    windowLeft -= 5;
                                    windowButtonLarge = true;
                                }
                            }

                        }
                        if (windowTop > element.top) {
                            windowTop = (element.top - 0.5);
                        }

                        if (windowHeight < (element.top + 2.5)) {
                            windowHeight = element.top + 2.5;
                        }
                        //set orientation when orientation is empty becuase some cases same window controll have both top/left orientation 
                        if (element.elementPrompts != null && element.elementPrompts[0].elementPromptOrientation != null && orientation == "") {
                            orientation = element.elementPrompts[0].elementPromptOrientation;
                            elementPromptsLength = element.elementPrompts.length;
                        } else if (element.elementPrompts == null && orientation == "") {//If it is windowcontroller but not having prompt text
                            orientation = Models.HelperText.top;
                        }
                        let elementSanitizedID = (element.sanitizedID).split('_');
                        let windowRow = 1;

                        if (element.elementCalc || element.elementDate || element.elementDetailable) {
                            extraWidth = 3.9;
                        }
                        else if (element.elementType === Models.ElementType.valCode) {
                            extraWidth = 2.5;
                        } else {
                            extraWidth = 1.5;
                        }

                        // get first row last element width+left position for vertical window
                        if (elementSanitizedID[elementSanitizedID.length - 1] == windowRow && element.windowOrientation != Models.WindowOrientation.horizontal) {

                            lastColLeftPos = element.leftPos;
                            lastColWidth = (element.width + extraWidth);
                            if (windowWidth < (lastColLeftPos + lastColWidth)) {
                                windowWidth = (lastColLeftPos + lastColWidth);
                            }

                            //Calculate window shadow width for horizontal window	 
                        } else if (element.windowOrientation == Models.WindowOrientation.horizontal) {
                            lastColLeftPos = element.leftPos;
                            lastColWidth = (element.width + extraWidth);
                            if (windowWidth < (lastColLeftPos + lastColWidth)) {
                                windowWidth = (lastColLeftPos + lastColWidth) + 1;
                            }
                        }

                        rowCols = rowCols + 1;

                    }
                }


            });

            // Set the styles for the window shadow
            let windowShadowSelector = "." + combinedFormId + " div.windowShadow_" + envWindow.windowControllerSanitized;
            existingRule = _.find(styleModel.cssRules, (val: any) => {
                return val.selectorText == windowShadowSelector;
            });

            // If this is the first form execution, set the css styling values for the form element
            if (!existingRule) {
                // Set the styling attributes for the window shadow
                let windowShadowOverrides = {
                    left: (windowLeft) + defaultUnit,
                    top: windowTop + defaultUnit,
                    width: (windowWidth - windowLeft - 1) + defaultUnit,
                    height: windowHeight - windowTop + defaultUnit
                }


                // Inject the css styling attributes into the form
                let windowShadowRule = windowShadowSelector + this.formService.convertToCss(windowShadowOverrides);
                styleModel.insertRule(windowShadowRule);
                windowShadowRule = windowShadowSelector + ":hover .window-shadow-label {background: 'lightgrey'; height: 1.7rem; width: auto;  position: absolute;  z-index: 0; right: 0; padding: 0 0 0 10px; border: 1px solid #d3dce0; display : block;}";
                styleModel.insertRule(windowShadowRule);

            }
            ;

            // Set the styles for the window control
            let windowControlSelector = "." + combinedFormId + " div.windowControl_" + envWindow.windowControllerSanitized;

            existingRule = _.find(styleModel.cssRules, (val: any) => {
                return val.selectorText == windowControlSelector;
            });
            // If this is the first form execution, set the css styling values for the form element
            if (!existingRule) {
                // Set the styling attributes for the window shadow
                let endingButton = 0;
                let lastWindowElementName = envWindow.windowController;
                let windowMembers = envWindow.windowMembers;
                if (windowMembers != null) {
                }
                if ((windowMembers != null) && (windowMembers[0] != null)) {
                    windowMembers = windowMembers[0];
                }
                if ((windowMembers !== null) && (windowMembers.length > 0)) {
                    lastWindowElementName = windowMembers[windowMembers.length - 1];
                }
                ;
                lastWindowElementName = lastWindowElementName + "_1";
                let lastWindowElement = elementList[lastWindowElementName];
                if (lastWindowElement != null) {
                    if (lastWindowElement.elementCalc || lastWindowElement.elementDate || lastWindowElement.elementDetailable) {
                        windowLeft += 3;
                    }
                }

                //Adjust the window pagination control position to display the bottom of the control if window control is first row in the form
                if (windowTop < 3.5) {
                    windowTop = windowTop + (windowHeight - windowTop) + 3.5;
                }

                let windowControlOverrides = {
                    left: "0px",
                    top: "-12px",
                    position: "absolute",
                    "font-size": "12px"
                }
                // Inject the css styling attributes into the form
                let windowControlRule = windowControlSelector + this.formService.convertToCss(windowControlOverrides);
                styleModel.insertRule(windowControlRule);
            }
            ;

            //Set the style for windowHoverControl                       
            let windowHoverSelector = "." + combinedFormId + " div.windowHoverControl_" + envWindow.windowControllerSanitized;
            existingRule = _.find(styleModel.cssRules, (val: any) => {
                return val.selectorText == windowHoverSelector;
            });
            // If this is the first form execution, set the css styling values for the form element
            if (!existingRule) {
                // Set the styling attributes for the window hover control
                let windowHoverControlOverrides = {
                    left: windowLeft + defaultUnit,
                    top: windowTop + defaultUnit,
                    width: (windowWidth - windowLeft) + defaultUnit,
                    height: windowHeight - windowTop + defaultUnit
                }


                // Inject the css styling attributes into the form
                let windowHoverControlRule = windowHoverSelector + this.formService.convertToCss(windowHoverControlOverrides);
                styleModel.insertRule(windowHoverControlRule);
                /* windowShadowRule = windowShadowSelector + ":hover .window-shadow-label {background: 'lightgrey'; height: 1.7rem; width: auto;  position: absolute;  z-index: 0; right: 0; padding: 0 0 0 10px; border: 1px solid #d3dce0; display : block;}";
                 styleModel.insertRule(windowShadowRule);*/

            }
            ;
        });


        styleElement.html(styleModel.toString());
        styleElement = null;
    };
}