/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable, Inject } from '@angular/core';
import * as Models from "../models";
import { EventsService, ServerCommandService, PreferencesService, FocusService, ProcessingService, BarGraphService } from "./"

declare var $: any;

@Injectable()
export class ReportBrowserService {

    totalPage: number;
    currentPage: number;
    pageIndex: number;
    content: any;
    isOpen: boolean = false;
    isFullPageView: boolean = null;
    fullPageUpdated: boolean;

    constructor(private serverCommandService: ServerCommandService, private focusService: FocusService, private processingService: ProcessingService,
        private preferencesService: PreferencesService,
        private barGraphService: BarGraphService,
        private eventsService: EventsService) {
        this.eventsService.on(Models.EventConstants.reportPageMessage, [this.reportPageMessageListener, this]);
    }

    /**
     * Open Report Browser Modal
     */
    openReport() {
        this.isOpen = true;
        $('.report-browser-modal').modal({ backdrop: 'static', keyboard: false }).draggable();
        $('.report-browser-modal').show();
        this.focusInputBox();
    }

    /**
     * Close Report Browser Modal
     * @param event
     */
    closeReport() {
        if (this.isOpen == false)
            return;

        // send client input message to server
        this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "EXIT");
        // close modal
        $('.report-browser-modal').modal('hide');
        // close backdrop
        $('.modal-backdrop').remove();
        // reset visibility property
        this.isOpen = false;
        // focus on search box in header
        this.focusService.focusSearch();
    }

    /**
     * Report Page Message Listener
     * @param reportBrowserService
     * @param message
     */
    reportPageMessageListener(reportBrowserService: any, message: any) {

        let self = <ReportBrowserService>reportBrowserService;

        // reset all state variables
        self.reset();

        let pageText = "";

        // broadcast an event to close please wait message
        self.processingService.closeProcessing();

        // open report
        setTimeout(() => {
            self.openReport();
        }, 100);

        //set state variables
        self.totalPage = message.TotalPages;
        self.currentPage = message.CurrentPage;
        self.pageIndex = self.currentPage;

        for (let i = 0; i < message.Page.length; i++) {
            if (message.Page[i] !== "") {
                pageText += message.Page[i] + "\n"
            }
            else if (message.Page[i] == "") {
                pageText += "\n";
            }
        }
        self.content = pageText;


    }
    // focus on pagination input box
    focusInputBox() {
        setTimeout(() => {
            let currentPageViewElement = document.getElementById("currentPageView") as HTMLInputElement;
            if (this.barGraphService.open) {
                this.barGraphService.focusFinish();
            }
            currentPageViewElement.focus();
            currentPageViewElement.select();
        }, 250);
    }

    /**
     * Reset state variables
     */
    reset() {
        this.totalPage = 0;
        this.currentPage = 0;
        this.pageIndex = 0;
        this.isOpen = false;
        if (!this.fullPageUpdated) this.isFullPageView = this.preferencesService.savedPreferences.FullPageView;
    }

}