/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit, Inject } from '@angular/core';
import { LoginService, EventsService } from "../../../services";

declare var $: any;

@Component({
    selector: 'databaselogin',
    template: require('./database.component.html')
})
export class DatabaseLoginComponent implements OnInit {
    constructor(private eventsService: EventsService, private loginService: LoginService) { }
    dbInputTypePassword: boolean = true;
    dbPassword: string = "";

    ngOnInit() { }

    hideShowDBPassword() {
        this.dbInputTypePassword = !this.dbInputTypePassword;
    }

    login() {
        this.cancel(false);
        this.eventsService.broadcast("TriggerDBLogin", this.dbPassword);
    }

    cancel(reset: boolean) {
        if (reset) this.loginService.resetDBLoginFailCount();
        $("#databasePassword .modal").modal('hide')
    }
}