/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { JsonProperty, deserialize } from "@dhkatz/json-ts";
export class Window {
    @JsonProperty('hasWindowButton')
    hasWindowButton: boolean;

    @JsonProperty('windowBotRightCol')
    windowBotRightCol: number;

    @JsonProperty('windowBotRightRow')
    windowBotRightRow: number;

    @JsonProperty('windowController')
    windowController: string;

    @JsonProperty('windowControllerSanitized')
    windowControllerSanitized: string;

    @JsonProperty('windowCurrentPage')
    windowCurrentPage: number;

    @JsonProperty('windowDelete')
    windowDelete: string;

    @JsonProperty('windowInsert')
    windowInsert: string;

    @JsonProperty('windowLabel')
    windowLabel: string;

    @JsonProperty('windowMembers')
    windowMembers: any;

    @JsonProperty('windowOrientation')
    windowOrientation: string;

    @JsonProperty('windowRowCount')
    windowRowCount: number;

    @JsonProperty('windowRowSeparation')
    windowRowSeparation: number;

    @JsonProperty('windowSequenceLength')
    windowSequenceLength: number;

    @JsonProperty('windowSequenceNumbers')
    windowSequenceNumbers: any;

    @JsonProperty('windowTopLeftCol')
    windowTopLeftCol: number;

    @JsonProperty('windowTopLeftRow')
    windowTopLeftRow: number;

    @JsonProperty('windowTotalEntries')
    windowTotalEntries: number;

    @JsonProperty('windowTotalPages')
    windowTotalPages: number;

    @JsonProperty('windowType')
    windowType: string;




    constructor() {
        this.hasWindowButton = undefined;
        this.windowBotRightCol = undefined;
        this.windowBotRightRow = undefined;
        this.windowController = undefined;
        this.windowControllerSanitized = undefined;
        this.windowCurrentPage = undefined;
        this.windowDelete = undefined;
        this.windowInsert = undefined;
        this.windowLabel = undefined;
        this.windowMembers = undefined;
        this.windowOrientation = undefined;
        this.windowRowCount = undefined;
        this.windowRowSeparation = undefined;
        this.windowSequenceLength = undefined;
        this.windowSequenceNumbers = undefined;
        this.windowTopLeftCol = undefined;
        this.windowTopLeftRow = undefined;
        this.windowTotalEntries = undefined;
        this.windowTotalPages = undefined;
        this.windowType = undefined;
    }

}