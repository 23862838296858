/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit, Inject } from '@angular/core';
import * as Models from "../../../models";
import {
    ReportBrowserService,
    HelpService,
    ConfigurationService,
    ServerCommandService,
    UiLocalStorageService,
    FocusService,
    PreferencesService
} from "../../../services";

declare var $: any;
declare var document: any;
declare var window: any;

@Component({
    selector: 'reportbrowser',
    template: require('./reportBrowser.component.html')
})
export class ReportBrowserComponent implements OnInit {


    font: string;
    paper: string;
    orientation: string;
    fontSize: number;

    // drop down options for export as pdf modal
    exportPDFFonts = [{ text: "Courier", value: "COURIER" }, {
        text: "Helvetica",
        value: "HELVETICA"
    }, { text: "Times New Roman", value: "TIMES NEW ROMAN" }];
    exportPDFPaperSize = [{ text: "Letter", value: "LETTER" }, { text: "Legal", value: "LEGAL" }, {
        text: "Executive",
        value: "EXECUTIVE"
    }, { text: "Tabloid", value: "TABLOID" }];
    exportPDFOrientation = [{ text: "Portrait", value: "PORTRAIT" }, { text: "Landscape", value: "LANDSCAPE" }];


    cloudEnvironment: boolean;

    /**
     *  messageListenerService is not used but injecting it to instantiate the service
     *
     */
    constructor(private serverCommandService: ServerCommandService,
        private configurationService: ConfigurationService,
        private uiLocalStorageService: UiLocalStorageService,
        private reportBrowserService: ReportBrowserService, private helpService: HelpService, private focusService: FocusService, private preferencesService: PreferencesService) {
    }


    ngOnInit() {
        // set the cloud environment flag from the initial configuration
        let clientParameters = this.configurationService.getConfiguration().ClientParameters;
        if (clientParameters) {
            this.cloudEnvironment = clientParameters.CloudEnvironment == 'y' || clientParameters.CloudEnvironment == 'Y';
        }
    }

    /**
     * Toggles page from default to full or vice versa
     * @param event
     */
    togglePageView(event: any = null) {
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            this.reportBrowserService.isFullPageView = !this.reportBrowserService.isFullPageView;
            // Reset the flag in preferences as well
            this.preferencesService.dialogPreferences.FullPageView = this.reportBrowserService.isFullPageView;
            // Set in local storage and backup to the app server
            this.uiLocalStorageService.set(Models.LocalStorageConstants.reportBrowserPageView, (this.reportBrowserService.isFullPageView) ? "Y" : "N")
            this.reportBrowserService.fullPageUpdated = true;

            setTimeout(() => {
                if (this.reportBrowserService.isFullPageView)
                    document.getElementById('reportToggleFull').focus();
                else
                    document.getElementById('reportToggleDefault').focus();
            }, 250);

        }
    }

    /**
     * Print Remote button event.
     */
    printRemote() {
        this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "PRINT_REMOTE");
        $("#reportBrowser").modal('hide');
        $('.modal-backdrop').remove();
    }

    /**
     * Save Report as button event.
     */
    saveReport() {
        this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "SAVE_LOCAL");
    }

    /**
     * Export PDF button event
     */
    exportPDF() {
        $('#exportPdf.modal').modal({ backdrop: 'static', show: true }).draggable();;

        setTimeout(() => {
            document.getElementById("select-font-family").focus();
        }, 250);

        // set export modal state variables from localstorage if they exists or set it to default values.
        this.font = this.uiLocalStorageService.get(Models.LocalStorageConstants.font) || Models.ReportBrowserConstants.defaultFont;
        this.paper = this.uiLocalStorageService.get(Models.LocalStorageConstants.paper) || Models.ReportBrowserConstants.defaultPaper;
        this.orientation = this.uiLocalStorageService.get(Models.LocalStorageConstants.orientation) || Models.ReportBrowserConstants.defaultOrientation;
        this.fontSize = this.uiLocalStorageService.get(Models.LocalStorageConstants.fontSize) || Models.ReportBrowserConstants.defaultFontSize;

        // Verify that font, paper and orientations are from valid options, if not reset them
        let found: boolean = false
        for (var i = 0; i < this.exportPDFFonts.length; i++) {
            if (this.font == this.exportPDFFonts[i].value) {
                found = true;
            }
        }
        if (!found) {
            this.font = this.exportPDFFonts[0].value
        }

        found = false;
        for (var i = 0; i < this.exportPDFPaperSize.length; i++) {
            if (this.paper == this.exportPDFPaperSize[i].value) {
                found = true;
            }
        }
        if (!found) {
            this.paper = this.exportPDFPaperSize[0].value
        }

        found = false;
        for (var i = 0; i < this.exportPDFOrientation.length; i++) {
            if (this.orientation == this.exportPDFOrientation[i].value) {
                found = true;
            }
        }
        if (!found) {
            this.orientation = this.exportPDFOrientation[0].value
        }

    };

    /**
     * Export PDF - Set Default button event
     */
    setDefault() {
        this.font = Models.ReportBrowserConstants.defaultFont;
        this.paper = Models.ReportBrowserConstants.defaultPaper;
        this.orientation = Models.ReportBrowserConstants.defaultOrientation;
        this.fontSize = Models.ReportBrowserConstants.defaultFontSize;
    };

    /**
     * Create PDF button event.
     */
    createPDF() {
        // store values of state variables in local storage.
        this.uiLocalStorageService.set(Models.LocalStorageConstants.font, this.font, false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.fontSize, this.fontSize, false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.paper, this.paper, false);
        this.uiLocalStorageService.set(Models.LocalStorageConstants.orientation, this.orientation);

        // send server message to generate the PDF
        let fieldData = "EXPORT_PDF FONT=" + this.font + ",FONTSIZE=" + this.fontSize + ",ORIENTATION=" + this.orientation + ",PAPER=" + this.paper;
        this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", fieldData);

        // close export modal
        $('#exportPdf.modal').modal('hide');
    }

    /**
     * Closes the export PDF modal and focuses back into pagination input.
     */
    cancelExportPDF() {
        $('#exportPdf.modal').modal('hide');
        setTimeout(() => {
            document.getElementById('currentPageView').focus();
        }, 250);
    }

    /**
     * First Page button event
     * @param event
     */
    firstPage(event: any = null) {
        // handles click event of the close button or hitting space or enter key on close button
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            // send server message only if not already on first page.
            if (this.reportBrowserService.currentPage > 1) {
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "FIRST");
            }
        }
    }

    /**
     * Previous Page button event
     * @param event
     */
    previousPage(event: any = null) {
        // handles click event of the close button or hitting space or enter key on close button
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            // send server message only if not already on first page.
            if (this.reportBrowserService.currentPage > 1) {
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "PREVIOUS");
            }
        }
    }

    /**
     * Next Page button event
     * @param event
     */
    nextPage(event: any = null) {
        // handles click event of the close button or hitting space or enter key on close button
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            // send server message only if not already on last page.
            if (this.reportBrowserService.currentPage < this.reportBrowserService.totalPage) {
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "NEXT");
            }
        }
    }

    /**
     * Last Page button event.
     * @param event
     */
    lastPage(event: any = null) {
        // handles click event of the close button or hitting space or enter key on close button
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            if (this.reportBrowserService.currentPage < this.reportBrowserService.totalPage) {
                // send server message only if not already on last page.
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "LAST");
            }
        }
    }

    /**
     * Update page event (triggered upon hitting enter in pagination input)
     * @param event
     */
    updatePage(event: any) {
        // Tab on report browser text area should focus on Current Page View input box
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            if (this.reportBrowserService.currentPage > 1)
                this.focusService.focusOn("reportPreviousPage", true);
            else
                this.focusService.focusOn("report-browser-pre-text", true);
            return;
        }

        // if key pressed is enter
        if (event.keyCode === Models.KeyCodes.enter) {
            event.preventDefault();
            event.stopPropagation();
            // if page index is a valid number between 1 and total pages
            if (this.reportBrowserService.pageIndex >= 1 && this.reportBrowserService.pageIndex <= this.reportBrowserService.totalPage) {
                this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "JUMP" + this.reportBrowserService.pageIndex);
                return;
            } else { // if the page index is invalid set it to current page
                this.reportBrowserService.pageIndex = this.reportBrowserService.currentPage;
                return;
            }
        }
    }

    /**
     * Report Browser Help
     * @param event
     */
    reportBrowserHelp(event: any = null) {
        // handles click event of the close button or hitting space or enter key on close button
        if (!event || (event.keyCode === Models.KeyCodes.space || event.keyCode === Models.KeyCodes.enter)) {
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.ReportBrowser);
        }
    }

    /**
     * Key Down event for keyboard shortcuts
     * @param event
     */
    keydown(event: any) {
        // If report browser is not open, do not process any keys and Exit function
        if (this.reportBrowserService.isOpen == false)
            return;

        if (event.keyCode === Models.KeyCodes.pageUp) { // Previous Page
            this.previousPage();
        } else if (event.keyCode === Models.KeyCodes.pageDown) { // Next Page
            this.nextPage();
        } else if (event.keyCode === Models.KeyCodes.home) { // Home or First Page
            this.firstPage();
        } else if (event.keyCode === Models.KeyCodes.end) { // End or Last Page
            this.lastPage()
        } else if (event.keyCode == Models.KeyCodes.up) { // Up or scroll to the top
            let objDiv = document.getElementById("report-browser-pre-text");
            objDiv.scrollTop = 0;
        } else if (event.keyCode == Models.KeyCodes.down) { // down or scroll down to scroll height
            let objDiv = document.getElementById("report-browser-pre-text");
            objDiv.scrollTop = objDiv.scrollHeight;
        } else {
            let keyChar = String.fromCharCode(event.keyCode || event.charCode);
            if (event.ctrlKey && event.altKey) {
                if (keyChar == "S") { // CTRL + ALT + S  - Save Report
                    this.saveReport();
                }
                else if (keyChar == "R") { // CTRL + ALT + R  - Print Remote
                    this.printRemote();
                }
                else if (keyChar == "H") { // CTRL + ALT + H  - Report Browser Help
                    this.reportBrowserHelp();
                }
            }
            else if (event.ctrlKey && event.shiftKey && keyChar == "E") { // CTRL + SHIFT + E  - Export PDF
                this.exportPDF();
            }
            else if (event.ctrlKey && keyChar == "A" && event.target && event.target.id != "currentPageView") { // CTRL + A  - Select current page content
                event.preventDefault();
                let pre = $('#report-browser-pre-text')[0];
                if (pre.select) {
                    pre.select();
                }
                else if (document.selection) { // document.selection is IE specific
                    var range = document.body.createTextRange(); // IE specific
                    range.moveToElementText(pre);
                    range.select();
                } else if (window.getSelection) { // used by browsers other than IE
                    var range = document.createRange(); // browsers except for IE
                    range.selectNode(pre);
                    window.getSelection().addRange(range);
                }
                pre = null;
            }
            else if (event.keyCode == Models.KeyCodes.f8 || event.keyCode == Models.KeyCodes.f9) { // F8 or F9 close Report
                this.reportBrowserService.closeReport();
            }
        }
        event.stopPropagation();
    }

    /**
     * Tab on report browser text area should focus on Current Page View input box
     * @param event
     */
    reportBrowserPreTextKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            // If First page button is enabled, focus on that button. Else, focus on Current Page View input box.
            if (this.reportBrowserService.currentPage > 1)
                this.focusService.focusOn("reportFirstPage", true);
            else
                this.focusService.focusOn("currentPageView", true);
            return;
        }
    }

    /**
     * Shift tab on Font dropdown should focus on Cancel Export PDF.
     * Tab on Font dropdown should focus on Font Size textbox
     * @param event
     */
    selectFontFamilyKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("btnExportToPDFCancel", true);
        }
        else if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("fontsize", true);
        }
    }

    /**
     * Shift tab on Font Size should focus on Font dropdown
     * Tab on Font drop down should focus on Font Size textbox
     * @param event
     */
    fontSizeKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("select-font-family", true);
        }
        else if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("rdb-report-portrait", true);
        }
    }

    /**
     * Tab on Cancel Export PDF should focus on Font drop down
     * @param event
     */
    cancelExportPdfKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("select-font-family", true);
        }
    }

    /**
     * Handles keydown events for Report Browser Close button. Handles Tab, Enter or Space. Other keypresses are ignored and default behavior resumes
     * @param event 
     */
    reportBrowserCloseKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        let space: boolean = event.keyCode === Models.KeyCodes.space;
        let enter: boolean = event.keyCode === Models.KeyCodes.enter;

        // Tab on close report browser should focus on report browser textarea
        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("report-browser-pre-text", true);
        }

        // If keydown was triggered by space or enter, close report browser
        if (space || enter)
            this.reportBrowserService.closeReport();
    }
}