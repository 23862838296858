/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import * as Models from '../models'
import { EventsService } from "./"

declare var isTablet: any;

@Injectable()
export class RootScopeService {

    performanceCounter: number = 0;
    threshold: number = 0
    zone1Message: string = "";
    zone2Message: string = "";
    zone3Message: string = "";
    zone4Message: string = "";

    lastFormSearchHistoryId: string = ""
    directFormApplication: string = "";
    externalLinkTooltip: string = "";
    ignoreFieldPromptId: string[] = [];
    blurFieldId: string = "";
    fieldUpdateSent: boolean = false;
    loggedOut: boolean = false;
    helpLinks: any = [];
    gridCount: boolean = false;
    systemError: string = "";
    customMessage: string = "";
    focusedFieldName: string = "";
    currentFocusedField: string = "";
    lastPromptedField: string = "";
    selectedRowNumbers: any = [];
    unselectedRowNumbers: any = [];
    searchResultRecordsSelected: number = 0;
    showSequenceOrder: boolean = false;
    showElementOrder: boolean = false;
    selectedElement: string = "";
    calendarField: string = "";
    clickedFieldName: string = "";
    FavActiveHelp: boolean;
    preferences: any = {};
    isFormOpen: boolean = false;
    isContextFormOpen: boolean = false;
    isSearchOpen: boolean = false;
    scaleSlider: any;
    searchCriteria: any;
    validActiveWindow: string = "";
    activeWindow: string = "";
    commentDialogOpen: boolean = false;
    reportBrowserOpen: boolean = false;
    interrupted: boolean = false;
    showPopover: boolean = false;
    criteria: any = { autoCompleteMatch: [] };
    multiLineEditorOpen: boolean = false;
    doNotRemoveDuplicate: boolean = false;
    cancelPrompted?: boolean = null;

    checkPrivacyRecord: boolean = false;
    checkContextExistInFavorite: boolean = false;

    callCustomTabRetrieval: boolean = false;
    disableCustomFieldSequence: boolean = false;

    TimeoutMessageOpen: boolean = false;

    showProcessingWaitOnSearchClose: boolean = false;
    searchResultMessage: boolean = false;
    isWaiting: boolean = false;

    viewallContext: boolean = false;
    contextManagerOpen: boolean = false;
    autoCloseContext: boolean = false;

    loadContextFromFavorite: boolean = false;

    favoritePanelOpen: boolean = false;
    CheckPersonRelatedFormIsOpen: boolean = false;
    printedTime: Date = new Date();

    contextPrint: boolean = false;

    isAuthenticated: boolean = false;
    userId: string = "";
    UserOverrideAllowed: boolean = false;

    UserTabOverrideAllowed: boolean = false;

    customizeFieldSequenceFlag: boolean = false;

    AllTabOverrideAllowed: boolean = false;

    AllFieldAccessOverrideAllowed: boolean = false;

    UserFieldAccessOverrideAllowed: boolean = false;

    createFolder: boolean = false;
    sharedListName: boolean = false;

    valCodeElementId: string = "";
    firstTimeFormLoad: boolean = false;

    contextPosition: number = -1;

    colleagueUiBuildVersion: string = "";
    analyticsLastFormOpen: string = "";

    isContextUpdate: boolean = false;
    isContextCancel: boolean = false;

    arePreferencesLoadedFlag: boolean = false;
    isTablet: boolean = false;

    lastFieldPromptMessageId: string = "";

    ignoreFieldSecurities: boolean = false;
    ignoreFieldDisplays: boolean = false;

    closeContextRecordPopout: boolean = false;

    useThemeEditor: any = null;
    themeEditorLessVariables: any = {};

    recordDeleteClicked: boolean = false;

    constructor(private eventsService: EventsService) {
        this.eventsService.on(Models.EventConstants.refreshFooterMessage, [this.refreshFooterMessage, this]);
        this.isTablet = isTablet;
    }

    private refreshFooterMessage(rootScopeService: any, msg: any) {
        let self: RootScopeService = null;
        self = <RootScopeService>rootScopeService;

        msg.Zones.forEach((zone: any) => {

            // trim controller from the zone text as it is not required.
            // zone text are used in window control.

            zone.ZoneText = zone.ZoneText.replace("Controller", "");
            switch (zone.ZoneCode) {
                case "ZONE1":
                    self.zone1Message = zone.ZoneText;
                    break;
                case "ZONE2":
                    self.zone2Message = zone.ZoneText;
                    break;
                case "ZONE3":
                    self.zone3Message = zone.ZoneText;
                    break;
                case "ZONE4":
                    self.zone4Message = zone.ZoneText;
                    break;
            }
        });
    }
}