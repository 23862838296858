/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import {
    AboutService,
    ConfigurationService,
    ContextHeaderService,
    ContextService,
    EventsService,
    FavoritesService,
    FocusService,
    HelperService,
    InstrumentationService,
    UiLocalStorageService,
    ParserService,
    PrivacyPolicyService,
    ProcessingService,
    PromptService,
    RootScopeService,
    ServerCommandService,
    SessionService,
    ValidationService
} from "./";
import * as Models from "../models/";

// Functions in slider.js
declare var setDefaultValue: any;
declare var setSliderForIndividualForm: any;
import * as _ from 'lodash';
declare var $: any;
declare var window: any;


@Injectable()
export class FormService {
    isError: boolean = false;
    formDisabled: boolean = false;
    // activeForm - Form that is currently rendered in the form area. Could be one of the disabled forms as well if you click on it in the form breadcrumbs
    activeForm: any; 
    forms: any = [];
    hideHeaderFields: Models.HideHeaderField[] = [];
    bufferedAction: string = "";
    underProcess: boolean = false;
    modifiedElementAddedToQueue: boolean = false;

    /**
     * Finish Form
     */
    finishForm = function () {
        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.processFinish);
    };

    constructor(private aboutService: AboutService, private eventsService: EventsService,
        private rootScopeService: RootScopeService,
        private sessionService: SessionService,
        private helperService: HelperService,
        private configurationService: ConfigurationService,
        private validationService: ValidationService,
        private parserService: ParserService,
        private serverCommandService: ServerCommandService,
        private contextService: ContextService,
        private contextHeaderService: ContextHeaderService,
        private uiLocalStorageService: UiLocalStorageService,
        private processingService: ProcessingService,
        private favoritesService: FavoritesService,
        private focusService: FocusService,
        private notificationsService: PromptService,
        private instrumentationService: InstrumentationService,
        private privacyPolicyService: PrivacyPolicyService) {
        this.eventsService.on(Models.EventConstants.fieldPromptMessage, [this.fieldPromptMessageListener, this]);
        this.eventsService.on(Models.EventConstants.enableForm, [this.enableFormListener, this]);
        this.eventsService.on(Models.EventConstants.fieldCompleteMessage, [this.fieldCompleteMessageListener, this]);
        this.eventsService.on(Models.EventConstants.fieldDisplayMessage, [this.fieldDisplayMessageListener, this]);
        this.eventsService.on(Models.EventConstants.saveAllForms, [this.saveAllFormsListener, this]);
        this.eventsService.on(Models.EventConstants.saveCurrentForm, [this.saveCurrentFormListener, this]);
        this.eventsService.on(Models.EventConstants.cancelAllForms, [this.cancelAllFormsListener, this]);
        this.eventsService.on(Models.EventConstants.cancelCurrentForm, [this.cancelCurrentFormListener, this]);
        this.eventsService.on(Models.EventConstants.openForm, [this.openFormListener, this]);
        this.eventsService.on(Models.EventConstants.fieldSecurityMessage, [this.fieldSecurityMessageListener, this]);
        this.eventsService.on(Models.CommandConstants.previousForm, [this.previousFormListener, this]);
        this.eventsService.on(Models.CommandConstants.nextForm, [this.nextFormListener, this]);
        window.formService = this;
    }

    /**
     * Event Listener for Field Prompt Message
     * @param formService
     * @param msg
     */
    fieldPromptMessageListener(formService: any, msg: any) {
        let self: FormService = null;
        self = <FormService>formService;

        // dummy css update to trigger CSS refresh for firefox. CUI-4672
        setTimeout(() => {
            $("#asdfg").css("background-color", "yellow");
        }, 500);

        self.rootScopeService.fieldUpdateSent = false;

        // Send bufferred action if previous modal was not error message.
        if (self.isError == false && !self.validationService.isNullOrEmpty(self.bufferedAction)) {
            self.showWaitingDialog('Field Complete Message');
            //Send SaveAllForms Message when click on Save All button after did some changes in the form field
            self.serverCommandService.sendProcessCommandMessage(self.bufferedAction);
            self.bufferedAction = "";
            return;
        }

        self.bufferedAction = "";

        if (self.aboutService.isOpen || self.privacyPolicyService.isOpen) return;

        if (self.getLatestForm() == null && self.underProcess == true) {
            setTimeout(() => {
                self.fieldPromptMessageListener(self, msg);
            }, 100);
            return;
        }
        self.underProcess = true;

        self.formDisabled = false;

        let key: string = msg.FieldName;
        if (!self.validationService.isNullOrEmpty(msg.PhysicalWindowRow)) {
            key += "_" + msg.PhysicalWindowRow;
        }

        let field: any = self.activeForm.fields[key];

        self.rootScopeService.lastFieldPromptMessageId = field.elementID;

        // ignore field prompt if set to ignore.
        if (field != null && self.rootScopeService.ignoreFieldPromptId.indexOf(field.sanitizedID) > -1) { // ignore field prompt for ignore field
            let index: number = self.rootScopeService.ignoreFieldPromptId.indexOf(field.sanitizedID);
            self.rootScopeService.ignoreFieldPromptId.splice(index, 1);
            if (field != null && document.activeElement && document.activeElement.id == field.sanitizedID) {
                if (self.validationService.isNullOrEmpty(field.focusedText) == false && self.validationService.isNullOrEmpty(field.text) == false) {
                    field.originalText = field.focusedText;
                    field.text = field.focusedText;
                }
                if (field.elementDate == true) {
                    self.populateDate(field, field.text);
                }
                let keyElement = document.getElementById(key.replace(/\./g, '-')) as HTMLInputElement;
                if (keyElement)
                    keyElement.select();
            }
            self.rootScopeService.lastPromptedField = field.elementID;
            return;
        }

        // in case of error in validating the data change the text back
        if (self.isError == true && self.rootScopeService.firstTimeFormLoad == false) {
            let el: any = self.getLatestForm().fields[key];
            if (!self.validationService.isNullOrEmpty(el)) {

                if (el.originalText == null)
                    el.originalText = "";

                el.text = el.focusedText;
                el.originalText = el.text;

                if (el.text == el.originalText && el.elementDate) {
                    self.populateDate(el, el.text);
                }
            }

            // In case of error, empty the calendarField
            self.rootScopeService.calendarField = "";
        }
        self.rootScopeService.firstTimeFormLoad = false;

        // broadcast close alert so that processing please wait dialog can be closed.
        self.processingService.closeProcessing();

        // close calculator if any open already.
        let openCalculators = $('.calculate-wrap');
        let hideAllCalculators = true;
        let promptField: string = key.replace(/\./g, '-');

        _.each(openCalculators, function (elem: any) {
            if (elem.id && $(elem).is(":visible") && elem.id.indexOf(promptField) >= 0) {
                hideAllCalculators = false;
            }
        });

        if (hideAllCalculators) {
            self.eventsService.broadcast(Models.EventConstants.closeCalendar);
        }
        self.eventsService.broadcast(Models.EventConstants.closeValcode);

        self.rootScopeService.focusedFieldName = promptField;
        self.rootScopeService.clickedFieldName = promptField;


        // record last prompted field to avoid sending field jump when changing data. So we don't get required field prompts again and again.
        if (field != null) {
            self.rootScopeService.lastPromptedField = field.elementID;
            if (!self.validationService.isNullOrEmpty(field.focusedText)) {
                field.text = field.focusedText;
            }
        }
        if (field != null && !self.validationService.isNullOrEmpty(field.elementWindowController)) {
            self.rootScopeService.validActiveWindow = field.elementWindowController;
        }

        if (promptField != self.rootScopeService.calendarField) {
            setTimeout(() => {
                self.rootScopeService.interrupted = false;
                self.focusService.focusOn(promptField, true, true);
                self.rootScopeService.valCodeElementId = "";

                self.underProcess = false;
                self.rootScopeService.calendarField = "";
            }, 100);

        }

    }

    /**
     * Event Listener for Field Complete Message
     * @param formService
     * @param message
     */
    fieldCompleteMessageListener(formService: any, message: any): void {
        let self: FormService = <FormService>formService;

    }

    fieldDisplayMessageListener(formService: any, message: any): void {
        let self: FormService = <FormService>formService;

        let currentForm = self.getLatestForm();
        // Get correct field name to update, including any window row value
        let targetFieldId: string = message.WindowRow === "" ? message.FieldName : message.FieldName + "_" + message.WindowRow;

        // Set Security issue when detailing from inner form to parent form and element ID is the same. CUI-5610 -- another variant that may affect display values.
        if (currentForm != null && currentForm.formId.toUpperCase() != message.FormID.toUpperCase())
            return;

        if (currentForm == null && self.rootScopeService.ignoreFieldDisplays != true) {
            setTimeout(() => {
                self.fieldDisplayMessageListener(self, message);
            }, 250);
            return;
        }

        if (currentForm != null) {
            // Look for the actual field object tied to that name
            let targetField: any = currentForm.fields[targetFieldId];

            if (!self.validationService.isNullOrEmpty(targetField)) {
                // Server sends date with spaces in front of focusedData or unfocusedData. Trim these spaces for calendar.
                if (targetField.elementDate) {
                    message.FocusedData = message.FocusedData.trim();
                    message.UnfocusedData = message.UnfocusedData.trim();
                }
                // if empty focused and unfocused data is sent by the server, clear temp values.
                if (self.validationService.isNullOrEmpty(message.UnfocusedData) && self.validationService.isNullOrEmpty(message.FocusedData)) {
                    targetField.tempFocusedText = "";
                    targetField.tempUnfocusedText = "";
                }
                if (targetField.unfocusedText != message.UnfocusedData || targetField.focusedData != message.FocusedData) {

                    //setTimeout(() => {
                    //set unfocused text based on focused field name.
                    if (self.rootScopeService.focusedFieldName != targetField.sanitizedID || self.rootScopeService.blurFieldId == targetField.sanitizedID) {
                        targetField.text = message.UnfocusedData;
                    }
                    else {
                        targetField.text = message.FocusedData;
                        if (self.validationService.isNullOrEmpty(message.FocusedData)) {
                            targetField.text = message.UnfocusedData;
                        }
                        if (targetField.elementID != self.rootScopeService.valCodeElementId) {
                        }
                    }

                    // reset text if focused or unfocused text is empty.
                    if (self.validationService.isNullOrEmpty(targetField.text)) {
                        if (self.validationService.isNullOrEmpty(message.FocusedData) == false) {
                            targetField.text = message.FocusedData;
                        }
                        else if (self.validationService.isNullOrEmpty(message.UnfocusedData) == false) {
                            targetField.text = message.UnfocusedData;
                        }
                    }

                    // If the control is date picker, convert the text string to formatted date. -- NEW CALENDAR
                    if (targetField.elementDate) {
                        self.populateDate(targetField, targetField.text);
                        //targetField.text = self.convertToDate(targetField.text);
                        if (self.validationService.isNullOrEmpty(targetField.text)) {
                            self.eventsService.broadcast(Models.EventConstants.clearCalendarDate, targetFieldId);
                        }
                    }

                    targetField.originalText = targetField.text;
                    //}, 250);


                    targetField.unfocusedText = message.UnfocusedData;
                    targetField.focusedText = message.FocusedData;

                }

                let variablePromptField: any = targetField.elementVarPromptFor;
                if (variablePromptField) {
                    let varPromptValue: any = targetField.text;
                    targetField.screenReaderText = self.buildHelperText(targetField, varPromptValue, false);
                    targetField.hoverText = self.buildHelperText(targetField, varPromptValue, true);
                }
            }
        }
    }

    /**
     * Enable Disable Form Component
     */
    enableFormListener(formService: any, enable: any) {
        let self: FormService = <FormService>formService;
        self.formDisabled = !enable;
    }

    /**
     * Save all forms listener
     * @param formService
     */
    saveAllFormsListener(formService: any) {
        let self: FormService = <FormService>formService;
        self.saveAll();
    }

    /**
     * Save form listener
     * @param formService
     */
    saveCurrentFormListener(formService: any) {
        let self: FormService = <FormService>formService;
        self.saveForm();
    }

    /**
     * Cancel all forms listener
     * @param formService
     */
    cancelAllFormsListener(formService: any) {
        let self: FormService = <FormService>formService;
        self.cancelAll();
    }

    /**
     * Cancel form listener
     * @param formService
     */
    cancelCurrentFormListener(formService: any) {
        let self: FormService = <FormService>formService;
        self.cancelForm();
    }

    /**
     * Launch form listener
     * @param formService
     * @param formId
     */
    openFormListener(formService: any, formId: string) {
        let self: FormService = <FormService>formService;
        self.rootScopeService.analyticsLastFormOpen = formId;
        self.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", formId, "", "", "");
    }

    fieldSecurityMessageListener(formService: any, message: any) {
        let self: FormService = <FormService>formService;
        let currentForm: any = self.getLatestForm();

        // CUI-5610
        if (currentForm != null && currentForm.formId.toUpperCase() != message.FormID.toUpperCase())
            return;

        if (currentForm == null && self.rootScopeService.ignoreFieldSecurities != true) {
            setTimeout(() => {
                self.fieldSecurityMessageListener(self, message);
            }, 250);
            return;
        }

        if (currentForm != null) {
            // Get correct fld name to update, including any window row value
            let targetFieldId: string = message.FieldName;
            // Look for the actual field object tied to that name
            let targetField: any = currentForm.fields[targetFieldId];

            if (targetField == null) {
                for (let field in currentForm.fields) {
                    let arr: string[] = field.split('_');

                    if (arr[0] != null && field.indexOf(targetFieldId + "_") == 0) {
                        targetField = currentForm.fields[field];
                        self.setSecurity(field, targetField, message.SecuritySetting);
                    }
                }
            } else if (targetField != null) {
                self.setSecurity(targetFieldId, targetField, message.SecuritySetting);
            }

            if (!self.validationService.isNullOrEmpty(targetField)) {
                self.parserService.updateSequenceOrderForElement(currentForm.fields, targetField, message.SecuritySetting);
            }
        }
    }

    previousFormListener(formService: any) {
        let self: FormService = <FormService>formService;
        let currentFormIndex: number = self.forms.indexOf(self.activeForm);

        if (currentFormIndex > 0) {
            self.showForm(self.forms[currentFormIndex - 1]);
        }
    }

    nextFormListener(formService: any) {
        let self: FormService = <FormService>formService;
        let currentFormIndex: number = self.forms.indexOf(self.activeForm);

        if (currentFormIndex < (self.forms.length - 1)) {
            self.showForm(self.forms[currentFormIndex + 1]);
        }
    }

    openForm(form: any): void {
        this.rootScopeService.analyticsLastFormOpen = form.formMnemonic;
        this.bufferedAction = "";
        this.rootScopeService.activeWindow = "";
        if (this.forms == null) this.forms = [];
        this.rootScopeService.isFormOpen = true;
        this.instrumentationService.page(form.baseFormId, form.formTitle, this.aboutService.buildVersion);
        this.forms.push(form);
        form.sanitizedFormId = form.formId.replace(/\./g, '-');
        form.sanitizedBaseFormId = form.baseFormId.replace(/\./g, '-');
        this.initializeSlide(form.formMnemonic);
        this.setToolTip();
    }

    closeForm(): void {
        // remove hide header field for form getting closed.
        _.remove(this.hideHeaderFields, (field: any) => {
            return field.formID == this.activeForm.formId;
        });

        // Get rid of topmost form
        this.forms[this.forms.length - 1] = null;
        this.forms.pop();

        if (this.forms.length < 1) {
            this.rootScopeService.ignoreFieldSecurities = true;
            this.rootScopeService.ignoreFieldDisplays = true;
        }

        // If any forms still left, focus/open the new topmost
        if (this.forms.length > 0) {
            this.activeForm = this.getLatestForm();
            this.initializeSlide(this.activeForm.formMnemonic);

            // Broadcast message to Attach Service to enable/disable Attach Link based on the active form ID
            this.eventsService.broadcast(Models.EventConstants.activeFormUpdated, this.activeForm.formId);
        } else {
            this.activeForm = null;

            // Disable Attach Link because there is no form in the form area
            this.eventsService.broadcast(Models.EventConstants.activeFormUpdated, "");

            this.rootScopeService.isContextFormOpen = false;
            this.rootScopeService.isFormOpen = false;
        }
        this.setToolTip();
        this.rootScopeService.ignoreFieldPromptId = [];
    }

    setToolTip() {
        this.rootScopeService.externalLinkTooltip = "";
        if (this.activeForm != null && this.activeForm.externalLinks != null && this.activeForm.externalLinks.length > 0) {
            this.rootScopeService.externalLinkTooltip = this.activeForm.externalLinks[0].linkTitle;
        }
    }

    setFormDefaults(): void {
        _.each(this.forms, (form: any) => {
            form.showHeaderShadow = this.isHeaderShadow(form);
            form.showHeaderWindowController = this.showHeaderWindowController(form);
            _.each(form.windowList, (window: any) => {
                window[0].showWindowShadow = this.showWindowShadow(window[0].windowController, window[0].windowType)
            });
        });
    }

    getZoom(formId: string) {
        let zoomSettings: any = this.uiLocalStorageService.get(Models.LocalStorageConstants.sliderZoom) || {};
        return zoomSettings[formId];
    }

    setZoom(formId: string): void {
        let scale: any = this.rootScopeService.scaleSlider.value;
        let zoomSettings: any = this.uiLocalStorageService.get(Models.LocalStorageConstants.sliderZoom) || {}; // Guarantees default object
        zoomSettings[formId] = scale;
        this.uiLocalStorageService.set(Models.LocalStorageConstants.sliderZoom, zoomSettings);
    }

    setSliderSetting(currentValue: any): void {
        let found: boolean = false;
        let _formScale: any = this.getSliderSetting();
        //Check if same form have local storage if yes update new value
        if (_formScale.length > 0) {
            for (let i = 0; i < _formScale.length; i++) {
                if (found) break;
                if (_formScale[i].Form == this.activeForm.formMnemonic) {
                    _formScale[i].Scale = currentValue;
                    found = true;
                    break;
                }
            }

        }
        //If no local storage exists or current form not having local storage set current value in the local storage
        if (!found) _formScale.push({
            Form: this.activeForm.formMnemonic,
            Scale: currentValue
        });
    }

    setLocalStorageSliderSetting(currentValue: any) {
        let found: boolean = false;
        let _formScale: any = this.getSliderSetting();
        //Check if same form have local storage if yes update new value
        if (_formScale.length > 0) {
            for (let i = 0; i < _formScale.length; i++) {
                if (found) break;
                if (_formScale[i].Form == this.activeForm.formMnemonic) {
                    _formScale[i].Scale = currentValue;
                    found = true;
                    break;
                }
            }

        }
        //If no local storage exists or current form not having local storage set current value in the local storage
        if (!found) _formScale.push({
            Form: this.activeForm.formMnemonic,
            Scale: currentValue
        });

        this.uiLocalStorageService.set(Models.LocalStorageConstants.sliderScale, _formScale);
    }

    // Set Form Scale globally.
    setSliderForGlobal(currentValue: any = null): void {
        let found: boolean = false;
        let currValue: any = !this.validationService.isNullOrEmpty(currentValue) ? currentValue : $("#slider").data('slider').getValue();
        currValue = 130 + 70 - currValue;
        let formScale: any = this.getSliderSetting();

        if (formScale.length > 0) {
            for (let i = 0; i < formScale.length; i++) {
                if (formScale[i].Form == Models.FormSlide.globalSliderValue) {
                    //update current global slider value.
                    formScale[i].Scale = currValue;
                    found = true;
                }
            }
        }

        //If no local storage exists for global set local storage
        if (!found) formScale.push({
            Form: Models.FormSlide.globalSliderValue,
            Scale: currValue
        });

        this.uiLocalStorageService.set(Models.LocalStorageConstants.sliderScale, formScale);

        let popupMessage: any = {};
        popupMessage.title = Models.FormSlide.messageTitle;
        popupMessage.text = ['Default form scale value set to ' + currValue + "%"];
        popupMessage.buttons = [{
            label: Models.LabelConstants.ok,
            callback: () => {
                this.notificationsService.popupMessageDismiss(null, false);
                this.processingService.closeProcessing();
            }
        },];
        popupMessage.defaultCallbackNumber = 0;
        this.notificationsService.popupMessageShow(popupMessage);
    }

    /**
     * Gets the slider settings
     * @returns {Array}
     */
    getSliderSetting(): any {
        return this.uiLocalStorageService.get(Models.LocalStorageConstants.sliderScale) || [];
    }

    getWindowFields(cWindowController: string): any {
        let currentForm: any = this.getLatestForm();
        let windowFields: any = [];
        for (let elementID in currentForm.fields) {
            let element: any = currentForm.fields[elementID];
            if (element.elementWindowController && element.elementWindowController === cWindowController && element.elementType !== Models.ElementType.label) {
                windowFields.push(element);
            }
        }
        return windowFields;
    }

    convertToCss(obj: any): string {
        let cssString: string = Models.HelperText.openBrace;
        _.each(obj, (value: any, key: any) => {
            cssString += key + Models.HelperText.colon + value + Models.HelperText.semiColon;
        });
        cssString += Models.HelperText.closeBrace;
        return cssString;
    }

    // Populate date input with input text.
    populateDate(targetField: any, text: any): any {
        if (text && text != "" && (typeof text === 'string' || text instanceof String)) {
            if ($("#" + targetField.sanitizedID) != null) $("#" + targetField.sanitizedID).val(text);
        }
    }

    /**
     * Gets the window object based on controller name.
     * @param cWindowController
     */
    getWindowControl(cWindowController: string): any {
        let window = this.getLatestForm().windowList[cWindowController] !== undefined ? this.getLatestForm().windowList[cWindowController][0] : undefined;

        if (window === undefined && this.forms && this.forms.length > 1) {
            for (let i = 0; i <= this.forms.length - 2; i++) {
                window = this.forms[i].windowList[cWindowController] !== undefined ? this.forms[i].windowList[cWindowController][0] : undefined;
                if (window !== undefined) {
                    break;
                }
            }
        }
        return window;
    }

    /**
     * Gets the current focused window row # for a given window controller
     * @param cWindowElement
     * @param cWindowController
     */
    getCurrentWindowRow(cWindowElement: any, cWindowController: string): number {
        if (this.getCurrentWindowRowCount(cWindowController) == 1) {
            return 1;
        }
        return parseInt(cWindowElement);
    }

    /**
     * Called when saveAll button is clicked or from keyboard shortcut.
     */
    saveAll(event: any = null): void {
        if (this.validationService.isNullOrEmpty(this.bufferedAction) == false) return;


        setTimeout(() => {
            this.formDisabled = true;
            this.showWaitingDialog('Save All');
            this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.saveAllForms);
        }, 200);

    }

    /**
     * Called when save form button is clicked or from keyboard shortcut.
     */
    saveForm(event: any = null): void {

        if (this.validationService.isNullOrEmpty(this.bufferedAction) == false) return;


        setTimeout(() => {
            this.formDisabled = true;
            this.rootScopeService.isContextUpdate = true;
            this.showWaitingDialog('Save');
            this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.processUpdate);
        }, 200);

    }

    /**
     * Called when Cancel All action is invoked on a form
     */
    cancelAll(): void {
        this.showWaitingDialog('Cancel All');

        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.cancelAllForms);
        this.autoCloseContext(false);
        setDefaultValue(Models.FormSlide.sliderDefaultValue);
    }

    /**
     * Called when Cancel Form action is invoked on a form
     */
    cancelForm(): void {
        this.showWaitingDialog('Cancel');

        this.rootScopeService.isContextCancel = true;
        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.screenCancel);
        //If more than one form in the window set slider for current focus form
        if (this.forms && this.forms.length > 1) {
            this.initializeSlide(this.forms[this.forms.length - 2].formMnemonic);
        }
        if (this.forms && this.forms.length == 1) {
            setDefaultValue(Models.FormSlide.sliderDefaultValue);
        }

    }

    showForm(form: any): void {
        this.activeForm = form;
        this.rootScopeService.externalLinkTooltip = "";
        this.initializeSlide(form.formMnemonic);
        //disable form if the focused form is different than the latest form.
        if (this.activeForm.combinedFormId == this.getLatestForm().combinedFormId) {

            //if focused form is latest form focus the last focused form field.
            setTimeout(() => {
                this.formDisabled = false;
                setTimeout(() => {
                    this.focusService.focusOn(this.rootScopeService.focusedFieldName, true, true);
                }, 250);
            }, 250);
        }
        else {
            this.formDisabled = true;
        }

        this.setToolTip();
    }

    formatMnemonic(mnemonic: string): string {
        if (mnemonic != null && mnemonic.length > 0) {
            if (mnemonic.indexOf(':') >= 0) {
                let newMnemonic: string = "";
                let splitText: string[] = mnemonic.split(':');
                if (splitText[0].trim().toUpperCase() == "CORE") {
                    newMnemonic = splitText[0];
                } else {
                    for (let i = 0; i < splitText[0].length; i++) {
                        newMnemonic += (splitText[0][i] + " ");
                    }
                }

                if (mnemonic.length == 2) {
                    newMnemonic = newMnemonic + ":" + mnemonic[1];
                }
                return newMnemonic;
            } else {
                let newMnemonic: string = "";
                if (mnemonic.trim().toUpperCase() == "CORE") {
                    return mnemonic;
                }
                for (let i = 0; i < mnemonic.length; i++) {
                    if (mnemonic[i] == 'a' || mnemonic[i] == 'A')
                        newMnemonic += "AY ";
                    else
                        newMnemonic += (mnemonic[i] + " ");
                }
                return newMnemonic;
            }
        }
        return "";
    }

    addFormToFavorites(event: any, click: boolean) {

        if (click || event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter) {
            this.rootScopeService.FavActiveHelp = true; // opens add-to-favorites modal
            this.favoritesService.showAddToFavorites(Models.HelperText.form, this.activeForm);
            event.stopPropagation();
            event.preventDefault();
        }

    }

    // Read all inquiry fields in the form
    readInquiryFields(): void {
        // Get current active form
        let currentForm = this.activeForm;
        let inquiryFields = [];
        // If current active form is defined and has fields, proceed
        if (currentForm != null && currentForm.fields != null) {
            // Parse through each field. If it is inquiry, push the field to inquiryFields object
            for (let key in currentForm.fields) {
                // skip loop if the property is from prototype
                if (!currentForm.fields.hasOwnProperty(key))
                    continue;

                // Get the current field in iteration based on key
                let field = currentForm.fields[key];
                // If fieldType is inquiry, push it to the inquiryFields object
                if (field.elementType == Models.ElementType.inquiry || field.runTimeInquiryField === true || field.runTimeRTInquiryField == true) {
                    inquiryFields.push(field);
                }
            }
        }
        // Generate Inquiry Fields text to be read by screen readers.
        let inquiryFieldsText: string = '';
        if (inquiryFields != null && inquiryFields.length > 0) {
            inquiryFieldsText = "Inquiry fields on this form are: ";
            for (let f = 0; f < inquiryFields.length; f++) {
                let currentInquiryField = inquiryFields[f];
                if (currentInquiryField.hasOwnProperty("elementHelpLabel") && currentInquiryField.hasOwnProperty("elementDetailable")) {
                    if (!this.validationService.isNullOrEmpty(currentInquiryField.elementWindowController)) {
                        inquiryFieldsText += " Window " + currentInquiryField.elementWindowController + " row " + currentInquiryField.rowIndex + " ";
                    }
                    if (!this.validationService.isNullOrEmpty(currentInquiryField.text)) {
                        inquiryFieldsText += "Inquiry field: " + currentInquiryField.elementHelpLabel + " = " + currentInquiryField.text;
                    } else {
                        inquiryFieldsText += "Inquiry field: " + currentInquiryField.elementHelpLabel + " = blank";
                    }
                    inquiryFieldsText += f != inquiryFields.length - 1 ? ", " : ".";
                }
            }
        } else {
            inquiryFieldsText = "There are no inquiry fields on this form.";
        }

        // Display popup message with the inquiryFieldText
        this.screenReaderPromptMessage(inquiryFieldsText);
    }

    /**
     * Reads the current tab - Mnemonic and Title
     */
    readCurrentTab(): void {


        // Get current active form
        let currentForm: any = this.activeForm;
        let tabText: string;

        tabText = currentForm != null ? currentForm.formMnemonic + " - " + currentForm.formTitle : "There is no active form";

        // Display popup message with the inquiryFieldText
        this.screenReaderPromptMessage(tabText);
    }


    /**
     * Alerts the message the needs to read to the accessibility user.
     */
    screenReaderPromptMessage(textToRead: string): void {
        // Display popup message with the inquiryFieldText
        let focusedElement = document.activeElement;
        let popupMessage: any = {};
        popupMessage.text = [
            textToRead
        ];
        popupMessage.buttons = [{
            label: Models.LabelConstants.ok,
            callback: () => {
                this.notificationsService.popupMessageDismiss(() => {
                    this.focusService.focusOn("#" + focusedElement.id);
                    this.processingService.closeProcessing();
                }, false);
            }
        }];
        popupMessage.defaultCallbackNumber = "1";
        this.notificationsService.popupMessageShow(popupMessage);
    }

    /**
     * Closes the context area depending on auto close context flag when a form is closed.
     * @param force
     */
    autoCloseContext(force: boolean): void {
        // check if auto close context is selected
        let contextOpenForm: boolean = this.contextService.openContextForm;
        let autoClose: boolean = this.contextHeaderService.contextOpenflag(false);
        if ((contextOpenForm || force) && autoClose) { // check if person related form is open
            this.contextService.positionCloseAll = true;
            this.contextService.closeRecord(Models.HelperText.person, -1, false, true);
        }
    }

    /**
     * Get the last form in the list of forms you see in Form Breadcrumbs
     */
    getLatestForm(): any {
        return this.forms[this.forms.length - 1];
    }

    /**
     * Build the helper text or hover text for the given element.
     */
    buildHelperText(fieldElement: any, loadedData: string, hover: boolean, windowList?: any, formFieldElements?: any): string {

        let helperText: string = "";
        let elementLabel: string = "";
        let compoundLabel: string = "";

        if (this.validationService.isNullOrEmpty(fieldElement.elementWindowController)) {
            if (!this.validationService.isNullOrEmpty(windowList)) {
                let windowElement: any = windowList[fieldElement.elementWindowController];

                if (!this.validationService.isNullOrEmpty(formFieldElements)) {
                    if (windowElement[0]) {
                        if (this.validationService.isNullOrEmpty(windowElement[0].windowLabel)) {
                            for (let elKey in formFieldElements) {
                                if (fieldElement.elementWindowController == formFieldElements[elKey].elementID) {
                                    helperText += formFieldElements[elKey].elementHelpLabel + " Window ";
                                }
                            }
                        }
                        else {
                            helperText += windowElement[0].windowLabel + " Window ";
                        }
                    }
                }
            }
        }
        /*
         For elements with variable prompts, allow the value of the variable prompt to be passed in as the element label.
         Otherwise, set the label based on attributes of the field element.
         */
        if (loadedData == undefined) {
            loadedData = "";
        }
        if (hover == undefined) {
            hover = false;
        }
        if (loadedData.length > 0) {
            elementLabel = loadedData;
        } else {
            elementLabel = this.getFieldLabel(fieldElement);
        }
        if (fieldElement.inquiry || fieldElement.runTimeInquiryField || fieldElement.runTimeRTInquiryField) {
            helperText += Models.HelperText.inquiry;
        } else if (fieldElement.runTimeNoAccess) {
            helperText += Models.HelperText.noAccess;
        } else {
            helperText += Models.HelperText.maintainable;
        }

        helperText += Models.HelperText.field + elementLabel;

        if (!fieldElement.runTimeNoAccess) {
            if (fieldElement.elementDetailable) {
                helperText += Models.HelperText.elementDetailable;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.elementType == 'valCode') {
                helperText += Models.HelperText.valCodeField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.lookUpField) {
                helperText += Models.HelperText.lookUpField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.elementDate) {
                helperText += Models.HelperText.dateField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
            if (fieldElement.elementCalc) {
                helperText += Models.HelperText.calcField;
                compoundLabel = Models.HelperText.compoundLabel;
            }
        }

        /*
         JAWS will announce "required" when the input element in html has a required attribute.  Add "required" for hover text
         */
        if (fieldElement.required) {
            helperText += compoundLabel + Models.HelperText.is;
            if (hover) {
                helperText += Models.HelperText.required;
            }
        }
        return helperText;
    }

    /**
     * #WindowController
     * Gets the total row count for the given window controller
     * @param cWindowController
     */
    private getCurrentWindowRowCount(cWindowController: string): number {
        return this.activeForm.windowList[cWindowController][0].windowRowCount;
    }

    // let sliderTimeout = setTimeout(function () {
    //     let clear = setSliderForIndividualForm(sliderDefaultValue);
    //     if(clear == true) clearTimeout(sliderTimeout);
    //     else sliderTimeout();

    /**
     * Initializes slider
     * @param formMnemonic
     */
    private initializeSlide(formMnemonic: string): void {
        let found: boolean = false;
        let sliderDefaultValue: number = Models.FormSlide.sliderDefaultValue;
        let formScale: any[] = this.getSliderSetting();
        //If current form have local storage get that value and set as default for current form slider
        if (formScale.length > 0) {
            for (let i = 0; i < formScale.length; i++) {
                if (found) break;
                if (formScale[i].Form == formMnemonic) {
                    sliderDefaultValue = formScale[i].Scale;
                    found = true;
                    break;
                } else if (formScale[i].Form == Models.FormSlide.globalSliderValue) {
                    sliderDefaultValue = formScale[i].Scale;
                }
            }

        }
        //Call the function inside slider.js to set slider value
        let sliderInterval = setInterval(() => {
            let clear = setSliderForIndividualForm(sliderDefaultValue);
            if (clear == true) clearInterval(sliderInterval);
        }, 500);
    }

    // }, 500);
    /**
     * Show processing please wait dialog
     */
    private showWaitingDialog(event: string) {

        this.processingService.showProcessing(event);
    }

    /**
     * Check if the data is dirty after loading.
     */
    private checkIfDataIsDirty(elem: any): boolean {
        let isDirty: boolean = false;
        if (!this.validationService.isNullOrEmpty(elem)) {
            if (!elem.elementDate && elem.text != elem.originalText && elem.text != elem.unfocusedText && elem.text != elem.focusedText) {
                isDirty = true;
            }
            else {
                if (this.validationService.isNullOrEmpty(elem.text) != this.validationService.isNullOrEmpty(elem.originalText) &&
                    this.validationService.isNullOrEmpty(elem.text) != this.validationService.isNullOrEmpty(elem.focusedText) &&
                    this.validationService.isNullOrEmpty(elem.text) != this.validationService.isNullOrEmpty(elem.unfocusedText)) {
                    isDirty = true;
                }
                else if (!this.validationService.isNullOrEmpty(elem.text) && elem.text.date != null && !this.validationService.isNullOrEmpty(elem.originalText) && elem.originalText.date != null) {
                    if (elem.text.date.year != elem.originalText.date.year || elem.text.date.month != elem.originalText.date.month ||
                        elem.text.date.day != elem.originalText.date.day) {
                        isDirty = true;
                    }
                }
            }
        }

        return isDirty;
    }

    /*
     Determine the best label for a field element
     */
    private getFieldLabel(labelledElement: any) {
        return labelledElement.elementHelpLabel != null ? labelledElement.elementHelpLabel.toString().replace(/"/g, '') : "";
    }

    /**
     * Set security for field based on run time security settings.
     */
    private setSecurity(targetFieldId: string, targetField: any, securitySetting: string): void {
        targetFieldId = targetField['sanitizedID'];
        if (targetField != null) {
            targetField.runTimeInquiryField = false;
            targetField.runTimeRTInquiryField = false;
            targetField.runTimeNoAccess = false;
            targetField.runtTimeAddonly = false;
            targetField.noDelete = false;
            //NOACCESS
            if (securitySetting == Models.FieldSecurityConstants.noaccess) {
                setTimeout(() => {
                    targetField.runTimeNoAccess = true;
                    this.emptyFieldValues(targetField);

                    // Change the screen Reader and hover text
                    targetField.screenReaderText = this.buildHelperText(targetField, "", false);
                    targetField.hoverText = this.buildHelperText(targetField, "", true);
                }, 50);
            }
            // INQUIRY
            else if (securitySetting == Models.FieldSecurityConstants.inquiry) {
                setTimeout(() => {
                    targetField.runTimeInquiryField = true;

                    // Change the screen Reader and hover text
                    targetField.screenReaderText = this.buildHelperText(targetField, "", false);
                    targetField.hoverText = this.buildHelperText(targetField, "", true);
                }, 10);
            }
            // FULL
            else if (securitySetting == Models.FieldSecurityConstants.full) {
                setTimeout(() => {
                    targetField.permRunTimeRTInquiryField = false;
                    targetField.runTimeInquiryField = false;
                    targetField.runTimeRTInquiryField = false;
                    targetField.runTimeNoAccess = false;
                    targetField.runtTimeAddonly = false;
                    targetField.noDelete = false;
                    // Change the screen Reader and hover text
                    targetField.screenReaderText = this.buildHelperText(targetField, "", false);
                    targetField.hoverText = this.buildHelperText(targetField, "", true);
                    if (document && document.activeElement && document.activeElement.id == targetField.sanitizedID) {
                        let targetElement = document.getElementById(targetField.sanitizedID) as HTMLInputElement;
                        targetElement.select();
                    }
                }, 10);
            }
            // RT_INQUIRY
            else if (securitySetting == Models.FieldSecurityConstants.rtinquiry) {
                // set perm flag so that we can always send field jump upon focusing the field.
                targetField.permRunTimeRTInquiryField = true;
                setTimeout(() => {
                    targetField.runTimeRTInquiryField = true;
                }, 10);

                // Change the screen Reader and hover text
                targetField.screenReaderText = this.buildHelperText(targetField, "", false);
                targetField.hoverText = this.buildHelperText(targetField, "", true);
            }
            // ADDONLY
            else if (securitySetting == Models.FieldSecurityConstants.addonly) {
                setTimeout(() => {
                    //if (this.validationService.isNullOrEmpty(targetField.text) == false) {
                    targetField.runtTimeAddonly = true;
                    // }

                    // Change the screen Reader and hover text
                    targetField.screenReaderText = this.buildHelperText(targetField, "", false);
                    targetField.hoverText = this.buildHelperText(targetField, "", true);
                }, 10);
            }
            //NODELETE
            else if (securitySetting == Models.FieldSecurityConstants.noDelete) {
                targetField.noDelete = true;
            }

        }
    }

    /**
     * Empty the content of the target field.
     */
    private emptyFieldValues(targetField: any): void {
        targetField.text = "";
        targetField.originalText = "";
        targetField.focusedText = "";
        targetField.unfocusedText = "";
    }

    private isHeaderShadow(form: any): boolean {
        let isHeader: boolean = false;
        _.each(form.fields, (element: any) => {
            if (isHeader || (element.elementInHeader == 'Y' && element.elementType != Models.ElementType.phantom && this.showHeaderWindowController(form) == true)) {
                isHeader = true;
            }
        });
        return isHeader;
    }

    private showHeaderWindowController(form: any): boolean {
        let result: boolean = false;
        if (this.hideHeaderFields != null && this.hideHeaderFields.length > 0) {
            for (let field in form.fields) {
                let actualField: any = form.fields[field];
                if (actualField != null && actualField.elementInHeader == 'Y' && actualField.elementType != Models.ElementType.phantom) {
                    let hiddenOnForm: boolean = false;
                    for (let hf in this.hideHeaderFields) {
                        let hiddenField: any = this.hideHeaderFields[hf];
                        if (hiddenField.formID == form.formId) {
                            if (hiddenField.fieldName == actualField.originalElementID) {
                                hiddenOnForm = true;
                            }
                        }
                    }

                    if (hiddenOnForm == false)
                        result = true;
                }
            }
        } else {
            result = true;
        }
        return result;
    }

    private showWindowShadow(windowController: any, windowType: string): boolean {
        let isShowWindowShadow: boolean = true;
        for (let i = 0; i < this.hideHeaderFields.length; i++) {
            if (this.hideHeaderFields[i].fieldName == windowController) {
                isShowWindowShadow = false;
                break;
            }
        }
        //If windowType is block than no need to display the windowshadow and window pagination
        if (windowType == "Block") {
            isShowWindowShadow = false;
        }
        return isShowWindowShadow;
    }
}