/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit } from "@angular/core";
import { ContextManagerService, EventsService, RootScopeService, FocusService } from "../../services/";
import * as Models from "../../models";

@Component({
    selector: 'contextmanager',
    template: require('./context-manager.component.html')
})
export class ContextManagerComponent implements OnInit {
    constructor(private contextManagerService: ContextManagerService,
        private rootScopeService: RootScopeService, private eventsService: EventsService,
        private focusService: FocusService) {
        // this.eventsService.on("CloseContextManager", [this.closeContextManagerListener, this]);
    }

    ngOnInit() {
        document.getElementById("select-number").focus();
    }

    // closeContextManagerListener(contextManagerComponent: any) {
    //     let self: ContextManagerComponent = <ContextManagerComponent>contextManagerComponent;
    //     self.contextManagerService.contextManagerOpen = false;
    //     self.rootScopeService.contextManagerOpen = false;
    // }

    contextManagerKeyDown(event: any) {
        let actionFound = false;

        let keyCode: number = event.keyCode;
        let esc: boolean = keyCode == Models.KeyCodes.escape;
        let ctrl: boolean = event.ctrlKey;
        let alt: boolean = event.altKey;
        let h: boolean = keyCode == Models.KeyCodes.h;

        if (esc) {
            this.contextManagerService.close();
        }
        else if (ctrl && alt && h) {
            actionFound = true;
            this.contextManagerService.help(null);
        }

        if (actionFound == true) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    /**
     * Shift + Tab on Select All Checkbox should focus on the last card in View All Context List.
     * @param event
     */
    selectAllContextCardsKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("context-manager-card-result-" + (this.contextManagerService.availableContexts.PERSON.data.length - 1), true);
        }
    }
}