/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component, OnInit} from '@angular/core';
import {FocusService, PreferencesService} from "../../services";
import * as Models from "../../models";

@Component({
    selector: 'preferences',
    template: require('./preferences.component.html')
})
export class PreferencesComponent implements OnInit {
    constructor(private focusService: FocusService ,
                private preferencesService: PreferencesService) {
    }

    ngOnInit() {
    }

    /**
     * Tab on Restore Defaults should focus on preferences help
     * @param event
     */
    restoreDefaultKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("btnPreferencesHelp", true);
        }
    }

    /**
     * Shift tab on preferences help should focus on Restore Defaults
     * @param event
     */
    btnPreferencesHelpKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("restoreDefault", true);
        }
    }
}