/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { WindowControlService, RootScopeService, PreferencesService } from "../../../services";

@Component({
    selector: 'windowcontrol',
    template: require('./windowControl.component.html')
})
export class WindowControlComponent implements OnInit, OnDestroy {

    @Input() window: any;
    windowsExportAllowed: boolean = false;

    private mouseDownEvent: any;
    private elementWindowControl: any;
    private startX = 0;
    private startY = 0;
    private x = 0;
    private y = 0;


    constructor(private windowControlService: WindowControlService, private preferencesService: PreferencesService, private rootScopeService: RootScopeService) {

    }

    ngOnInit() {
        this.windowsExportAllowed = this.preferencesService.savedPreferences.WindowsExportAllowed;
    }


    doWindowJump = function (keyEvent: any) {
        var position = parseInt(keyEvent.currentTarget.value);

        if (keyEvent.keyCode == '13') {

            if (position > 0 && position <= this.window.windowTotalPages) {
                this.windowControlService.doWindowJump(position, this.window.windowController);
            } else {

                keyEvent.currentTarget.value = this.window.windowTotalPages;
                this.doWindowJump(keyEvent);
            }

        }
    };





    ngOnDestroy() { }
}