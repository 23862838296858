/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable, Inject } from '@angular/core';
import * as Models from "../models"
import {
    EventsService,
    FormService,
    PromptService,
    RootScopeService,
    RendererService,
    ContextService,
    SearchService,
    SubMenuDialogService,
    HelpService,
    ValidationService,
    SessionService,
    ParserService,
    ContextHeaderService,
    ServerCommandService,
    UiLocalStorageService,
    SessionStorage,
    PreferencesService,
    ProcessingService,
    FocusService
} from "./";


import * as _ from 'lodash';
import { AttachService } from './attach.service';

declare var beep: any;
declare var isFirefox: any;
declare var webAPIBaseUrl: any;

@Injectable()
export class MessageListenerService {
    getRowIndex = function (currentRow: any, currentPage: any, currentWindowRowCount: any) {

        return ((currentPage * currentWindowRowCount) - currentWindowRowCount) + currentRow;
    };
    private form: any = {};
    private isLockedRecordByUserMsg: boolean = false;
    private isPrivacyRecord: boolean = false;
    private isInValidDate: boolean = false;
    private isInValidLookup: boolean = false;
    private isContextJump: boolean = false;
    private isContextNext: boolean = false;
    // private isContextUpdate: boolean = false;
    private isContextPrevious: boolean = false;
    private isContextReturn: boolean = false;
    // private isContextCancel: boolean = false;
    private isDeleteRecord: boolean = false;
    private isPersonLookup: boolean = true;
    private isLockedRecordByUser: boolean = false;
    private jumpPosition: number = 0;
    private cachedFormSpecs: any = {};
    private errorCount: number = 0;

    constructor(private serverCommandService: ServerCommandService, private contextService: ContextService,
        private uiLocalStorageService: UiLocalStorageService, private preferencesService: PreferencesService, private sessionService: SessionService,
        private validationService: ValidationService, private sessionStorage: SessionStorage, private parserService: ParserService,
        private contextHeaderService: ContextHeaderService,
        private eventsService: EventsService, private formService: FormService, private promptService: PromptService,
        private rootScopeService: RootScopeService, private rendererService: RendererService,
        private searchService: SearchService, private subMenuDialogService: SubMenuDialogService,
        private processingService: ProcessingService,
        private helpService: HelpService, private focusService: FocusService, private attachService: AttachService) {
        this.eventsService.on("ButtonPromptMessage", [this.buttonPromptMessageListener, this]);
        this.eventsService.on("ChangeHeaderMessage", [this.changeHeaderMessageListener, this]);
        this.eventsService.on("ClearContextMessage", [this.clearContextMessageListener, this]);
        this.eventsService.on("ConfirmCloseContextMessage", [this.confirmCloseContextMessageListener, this]);
        this.eventsService.on("CustomFormSpecsMessage", [this.customFormSpecsMessageListener, this]);
        this.eventsService.on("OperatorInfoMessage", [this.operatorInfoMessageListener, this]);
        this.eventsService.on("FormSpecsMessage", [this.formSpecsMessageListener, this]);
        this.eventsService.on("DetailMenuMessage", [this.detailMenuMessageListener, this]);
        this.eventsService.on("DialogPromptMessage", [this.dialogPromptMessageListener, this]);
        this.eventsService.on("DisplayBroadcastMessage", [this.displayBroadcastMessageListener, this]);
        this.eventsService.on("DisplayDialogMessage", [this.displayDialogMessageListener, this]);
        this.eventsService.on("FileDownloadMessage", [this.fileDownloadMessageListener, this]);
        this.eventsService.on("FormStateMessage", [this.formStateMessageListener, this]);
        this.eventsService.on("FormStatusMessage", [this.formStatusMessageListener, this]);
        this.eventsService.on("HideHeaderFieldsMessage", [this.hideHeaderFieldsListener, this]);
        this.eventsService.on("OSButtonMessage", [this.OSButtonMessageListener, this]);
        this.eventsService.on("NavigationLockMessage", [this.navigationLockMessageListener, this]);
        this.eventsService.on("InquiryFormReadyMessage", [this.inquiryFormReadyMessageListener, this]);
        this.eventsService.on("ProcessStatusMessage", [this.processStatusMessageListener, this]);
        this.eventsService.on("RefreshHeaderMessage", [this.refreshHeaderMessageListener, this]);
        this.eventsService.on("RemoveContextMessage", [this.removeContextMessageListener, this]);
        this.eventsService.on("SearchResultsMessage", [this.searchResultsMessageListener, this]);
        this.eventsService.on("DebugPauseMessage", [this.debugPauseMessageListener, this]);
        this.eventsService.on("DebugResumeMessage", [this.debugResumeMessageListener, this]);
        this.eventsService.on("ValcodeResponseMessage", [this.valcodeResponseMessageListener, this]);
        this.eventsService.on("WindowDataMessage", [this.windowDataMessageListener, this]);
        this.eventsService.on("HyperlinkMenuMessage", [this.hyperlinkMenuMessageListener, this]);
        this.eventsService.on("ClientInfoMessage", [this.clientInfoMessageListener, this]);
    }

    dismissDialogMessageAlways() {
        this.processingService.closeProcessing();
    };

    hyperlinkMenuMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        var linkList = message.HyperlinkList;
        self.rootScopeService.helpLinks = [];
        _.each(linkList, (it: any) => {
            self.rootScopeService.helpLinks.push(it);
        });
    }

    buttonPromptMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        if (self.subMenuDialogService.isOpen == true) {
            self.subMenuDialogService.close('', true);
        }

        //self.formService.bufferedAction = "";

        // clear out ignore Field PromptIds, as we want to respect field prompt message after modal goes away.
        self.rootScopeService.ignoreFieldPromptId = [];
        // Ignore search result export message.  We now put up the download
        // dialog as a modal rather than as a 2nd tab/window, so no need for
        // a modal dialog in the primary window.  Wish there was a better way...
        if (message.PromptMessage[0] === "Search result export complete") {
            self.serverCommandService.sendProcessCommandWithDataMessage(message.Buttons[0].ButtonCommand, message.MessageId, message.Buttons[0].ButtonShortcut);
            self.dismissDialogMessageAlways();
            self.searchService.focusOnMainInput();
            return;
        }
        if (message.PromptMessage[0] === "Screen cancelled due to unresolvable locked record.") {
            self.isLockedRecordByUserMsg = true;
        }
        if (message.PromptMessage[0] === "You can't access this record due to the person's request for privacy.") {
            self.isPrivacyRecord = true;
        }
        if (message.ErrorFlag) {
            self.formService.isError = true;
        }
        if (message.ErrorFlag && message.PromptMessage[0] === "Entry must be a date.") {
            self.isInValidDate = true;
        } else if (message.ErrorFlag && message.PromptMessage[0] !== "Action canceled") {
            self.isInValidLookup = true;
        }
        // Generate a dialog with the requested text and buttons
        let popupMessage: any = {};
        popupMessage.title = ' ';
        popupMessage.text = message.PromptMessage;
        popupMessage.buttons = [];
        //Modified on 12/16/2015
        let buttonsToAdd = message.Buttons;
        if (!_.isArray(buttonsToAdd)) {
            buttonsToAdd = [buttonsToAdd];
        }

        let setCancelPrompted = false;
        for (let i = 0; i < buttonsToAdd.length; i++) {
            //Format the button label with underscore
            let btnLabel = buttonsToAdd[i].ButtonLabel;
            if (btnLabel.toLowerCase() === "cancel") {
                setCancelPrompted = true;
            }
            let btnShortcut = btnLabel.indexOf(buttonsToAdd[i].ButtonShortcut);
            let labelValue = null;
            //Shortcut key position is first
            if (btnShortcut == 0) {
                labelValue = "<span class='textunderline'>" + btnLabel.slice(btnShortcut, btnShortcut + 1) + "</span>" + btnLabel.slice(btnShortcut + 1, btnLabel.length);
            } else if (btnShortcut == btnLabel.length - 1) {
                labelValue = btnLabel.slice(0, btnLabel.length - 1) + "<span class='textunderline'>" + btnLabel.slice(btnShortcut, btnLabel.length) + "</span>";
            } else if (buttonsToAdd[i].ButtonShortcut != null) {
                labelValue = btnLabel.slice(0, btnShortcut) + "<span class='textunderline'>" + btnLabel.slice(btnShortcut, btnShortcut + 1) + "</span>" + btnLabel.slice(btnShortcut + 1, btnLabel.length);
            } else {
                labelValue = btnLabel;
            }

            popupMessage.buttons.push({
                label: labelValue,
                callbackArgs: {
                    buttonCommand: buttonsToAdd[i].ButtonCommand,
                    buttonShortcut: buttonsToAdd[i].ButtonShortcut,
                    messageId: message.MessageId,
                    buttonLabel: buttonsToAdd[i].ButtonLabel
                },
                callback: (callbackArgs: any) => {
                    self.promptService.popupMessageDismiss(() => {
                        self.processingService.showProcessing("Button Prompt Message");
                        self.serverCommandService.sendProcessCommandWithDataMessage(callbackArgs.buttonCommand, callbackArgs.messageId, callbackArgs.buttonShortcut);
                        //context related modal: after button clicks these functions should call
                        let currentContextPosition = self.contextService.getContext() == null ? -1 : self.contextService.getContext().position;
                        self.isContextJump = false;
                        self.isContextNext = false;
                        self.isContextPrevious = false;
                        self.isContextReturn = false;
                        self.rootScopeService.isContextUpdate = false;
                        self.rootScopeService.isContextCancel = false;
                        if (callbackArgs.buttonShortcut === "N") { // Next
                            self.isContextNext = true;
                        } else if (callbackArgs.buttonShortcut === "P" && callbackArgs.buttonLabel.toLowerCase() == "previous") { // previous
                            self.isContextPrevious = true;
                        }
                        else if (callbackArgs.buttonShortcut === "J") { // jump
                            self.isPersonLookup = true;
                            self.isContextJump = true;
                        } else if (callbackArgs.buttonShortcut === "D" && callbackArgs.ButtonCommand === "EmptyString") { // Discard
                            self.contextService.position("PERSON", '-1');
                        } else if (callbackArgs.buttonShortcut === "C") { // Cancel
                            self.rootScopeService.isContextCancel = true;

                            if (self.rootScopeService.recordDeleteClicked === true)
                                self.rootScopeService.recordDeleteClicked = false;
                        } else if (callbackArgs.buttonShortcut === "U") { // update
                            self.rootScopeService.isContextUpdate = true;

                            // CUI-5837: If Record Delete was clicked, get the current record in the Context Card and mark it to be removed in ConfirmCloseContextMessage
                            if (self.rootScopeService.recordDeleteClicked === true) {
                                let currentContext = self.contextService.getContext();
                                let currentContextPos = currentContext.position;
                                if (currentContext.data && currentContext.data.length > 0 && currentContextPos !== -1)
                                    currentContext.data[currentContextPos].toBeRemoved = true;
                            }
                        } else if (callbackArgs.buttonShortcut === "R") {
                            self.contextService.checkCancel = false;
                            self.contextService.checkContextRecord = false;

                            // Do not change context position if no contextPosition was set by ListRefreshRecord
                            if (self.contextService.getContext().Header.context == "PERSON" && self.rootScopeService.contextPosition > 0) {
                                self.contextService.setContextPosition("PERSON", self.rootScopeService.contextPosition);
                                self.rootScopeService.contextPosition = -1;
                            }

                            if (self.rootScopeService.viewallContext === true)
                                self.rootScopeService.viewallContext = false;

                            if (self.rootScopeService.recordDeleteClicked === true)
                                self.rootScopeService.recordDeleteClicked = false;
                        } else if (callbackArgs.buttonShortcut === "O") { // ok
                            if (self.isLockedRecordByUserMsg) {
                                self.isLockedRecordByUser = true;
                            }
                            self.processingService.closeProcessing();
                        } else if (callbackArgs.buttonShortcut === "A") {
                            self.contextService.newRecord = true;
                        }
                        else if (callbackArgs.buttonShortcut === "S") {
                        }
                    }, true);
                },
                title: buttonsToAdd[i].ButtonLabel

            });
        }


        if (message.ErrorFlag == true && self.preferencesService.dialogPreferences.PlaySound == true) {
            beep();
        }

        self.dismissDialogMessageAlways();

        setTimeout(() => {
            self.rootScopeService.isWaiting = false;
            self.promptService.popupMessageShow(popupMessage, true);
            if (setCancelPrompted === true) {
                self.rootScopeService.cancelPrompted = true;
            }
        }, 250);
    }

    /**
     * Process ChangeHeaderMessage - Sends the NewContext and NewRecordID to display in the context header
     */
    changeHeaderMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;

        // If calendar component was open, close it.
        self.eventsService.broadcast("closeCalendar");

        if (message.NewRecordID && self.contextService.newRecord) {// User entered a new context record ID that does not exist in Colleague
            self.contextService.addTempContext({
                recordID: message.NewRecordID,
                context: "PERSON"
            });
            self.contextService.newRecord = false;
        } else { // User entered a context record ID that already exists in Colleague.
            // Add this record to context. If context data already contains this ID, duplicate entries will be handled. addContextData will try to set the context position if entry is not a duplicate.
            self.contextService.addContextData(message.NewContext, [{
                '@ID': message.NewRecordID,
                'ID': message.NewRecordID
            }])
        }

        // Track if context position has been changed at any point during this method.
        let contextPositionChanged = false;

        // Get current context position and context data
        let currentContextposition = self.contextService.getContext().position;
        let contextData = self.contextService.getContext().data;

        // Was there a ButtonPromptMessage dialog and did the user click Jump?
        if (self.isContextJump) {
            // Get the new context position index from the list of contextData
            let newContextPosition: number = contextData.map((record: any) => { return record["@ID"]; }).indexOf(message.NewRecordID);
            self.contextService.setContextPosition("PERSON", newContextPosition);
            contextPositionChanged = true;
            self.isContextJump = false;
        }

        // Was there a ButtonPromptMessage dialog and did the user click either Update or Cancel?
        if (self.rootScopeService.isContextUpdate || self.rootScopeService.isContextCancel) {
            // User selected Cancel
            if (self.contextService.checkCancel) {
                if (self.contextService.positionCloseAll) {
                    self.contextService.position("PERSON", '-1');
                    contextPositionChanged = true;
                    self.contextService.checkCancel = false;
                } else {
                    // If the user selected Close Record option from Context Header, remove the record from Context data.
                    if (!self.validationService.isNullOrEmpty(self.contextService.contextPositionToRemove)) {
                        self.contextService.position("PERSON", self.contextService.contextPositionToRemove);
                        self.contextService.contextPositionToRemove = null;
                    }
                    else
                        self.contextService.position("PERSON", currentContextposition);
                    // Get the new context position index from the list of contextData
                    let newContextPosition: number = contextData.map((record: any) => { return record["@ID"]; }).indexOf(message.NewRecordID);
                    self.contextService.setContextPosition("PERSON", newContextPosition);
                    contextPositionChanged = true;
                    self.contextService.checkCancel = false;
                }
            }

            // User clicked previous or next from the context header
            if (self.contextService.checkContextRecord) {
                if (self.contextService.checkContextRecordPrevious) {
                    self.contextService.setContextPosition("PERSON", currentContextposition - 1);
                    contextPositionChanged = true;
                    self.contextService.checkContextRecord = false;
                } else {
                    self.contextService.setContextPosition("PERSON", currentContextposition + 1);
                    contextPositionChanged = true;
                    self.contextService.checkContextRecord = false;
                }
            }

            if (self.rootScopeService.isContextUpdate && self.rootScopeService.checkContextExistInFavorite) {
                // Get the new context position index from the list of contextData
                let newContextPosition: number = contextData.map((record: any) => { return record["@ID"]; }).indexOf(message.NewRecordID);
                self.contextService.setContextPosition("PERSON", newContextPosition);
                contextPositionChanged = true;
                self.rootScopeService.checkContextExistInFavorite = false;
            }
        }

        // Was there a ButtonPromptMessage dialog and did the user click Next?
        if (self.isContextNext) {
            // Get the new context position index from the list of contextData
            let newContextPosition: number = contextData.map((record: any) => { return record["@ID"]; }).indexOf(message.NewRecordID);
            self.contextService.setContextPosition("PERSON", newContextPosition);
            contextPositionChanged = true;
            self.isContextNext = false;
        }

        // Was there a ButtonPromptMessage dialog and did the user click Previous?
        if (self.isContextPrevious) {
            // Get the new context position index from the list of contextData
            let newContextPosition: number = contextData.map((record: any) => { return record["@ID"]; }).indexOf(message.NewRecordID);
            self.contextService.setContextPosition("PERSON", newContextPosition);
            contextPositionChanged = true;
            self.isContextPrevious = false;
        }

        // Was there a ButtonPromptMessage dialog that prompts the user that the person record is locked by another user?
        if (self.isLockedRecordByUser) {
            let currentContext = self.contextService.context.PERSON.data.length;
            let minusOne = 1;
            let contextPosition = currentContext - minusOne;
            if (currentContextposition === contextPosition) {
                self.contextService.setContextPosition("PERSON", currentContextposition);
                contextPositionChanged = true;
                self.isLockedRecordByUser = false;
            } else {
                self.contextService.setContextPosition("PERSON", currentContextposition + 1);
                contextPositionChanged = true;
                self.isLockedRecordByUser = false;
            }
        }

        // If the current record a private record and the user doesn't have access to it
        if (self.isPrivacyRecord) {
            if (message.NewRecordID != null && message.NewRecordID != '') {
                for (let i = 0; i < contextData.length; i++) {
                    if (contextData[i]['@ID'] === message.NewRecordID) {
                        self.contextService.setContextPosition("PERSON", i);
                        return;
                    }
                }
            }

            let currentContext = self.contextService.context.PERSON.data.length;
            let minusOne = 1;
            let contextPosition = currentContext - minusOne;
            if (currentContextposition === contextPosition) {
                self.contextService.setContextPosition("PERSON", currentContextposition);
                contextPositionChanged = true;
                self.isPrivacyRecord = false;
            } else {
                if (self.rootScopeService.checkPrivacyRecord && self.isPrivacyRecord) {
                    self.contextService.setContextPosition("PERSON", currentContextposition);
                    contextPositionChanged = true;
                    self.isPrivacyRecord = false;
                    self.rootScopeService.checkPrivacyRecord = false;
                } else {
                    self.contextService.setContextPosition("PERSON", currentContextposition + 1);
                    contextPositionChanged = true;
                    self.isPrivacyRecord = false;
                }
            }
        }

        // If the context position still hasn't changed, set the appropriate position by finding a matching record.
        if (contextPositionChanged == false) {
            /* If the user has decided to remove a record from context area and the context position has not changed yet, it means that final prompts were turned off and no ButtonPromptMessage button was clicked.
            Therefore, handle the positioning of the context area in this condition and reset the closeContextRecordPopout in rootScope to its default value of false. See CUI-5411. */

            // If the user selected Close Record option from Context Header, remove the record from Context data.
            if (!self.contextService.positionCloseAll && self.rootScopeService.closeContextRecordPopout === true && self.formService.forms.length >= 1) {
                // Get the new context position index from the list of contextData and splice the appropriate record from the context data
                let newContextPosition: number = contextData.map((record: any) => { return record["@ID"]; }).indexOf(message.NewRecordID);
                self.contextService.position("PERSON", newContextPosition - 1);
                self.rootScopeService.closeContextRecordPopout = false;
            }

            // Set the new context position based on the new list of context records
            for (let i = 0; i < contextData.length; i++) {
                if (contextData[i]['@ID'] === message.NewRecordID) {
                    self.contextService.setContextPosition("PERSON", i);
                    break;
                }
            }
        }

        // Reset all the flags since the ChangeHeaderMessage processing has finished.
        self.contextService.contextPositionToRemove = null;
        self.rootScopeService.isContextUpdate = false;
        self.rootScopeService.isContextCancel = false;
        self.contextService.checkContextRecord = false;
        self.rootScopeService.checkContextExistInFavorite = false;
        self.rootScopeService.closeContextRecordPopout = false;
        self.rootScopeService.viewallContext = false;
    }

    clearContextMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.contextService.clearAllRecords();
    }

    /**
     * Listener for ConfirmCloseContextMessage. This message is sent by the App Server in response to the CloseSelected command.
     * Scenario -- 
     * Launch a form -> 
     * open person records -> 
     * View All Context records -> 
     * Select one or more person records other than the active context card -> 
     * Click Remove -> 
     * Remove records from Context Card Area
     * @param service MessageListenerService instance
     * @param message Message indicating whether the Context Record Removal, from View All Context Records, was successful or not.
     */
    confirmCloseContextMessageListener(service: any, message: any) {
        // Cast service object to MessageListenerService
        let self = <MessageListenerService>service;

        // Close any PPW dialogs
        self.dismissDialogMessageAlways();

        // If RemovalSuccessful is true, invoke ContextService to remove records from context card area
        if (message.RemovalSuccessful) {
            self.contextService.removeRecordsViaViewAllContext();
        }

        if (self.rootScopeService.recordDeleteClicked === true)
            self.rootScopeService.recordDeleteClicked = false;
    }

    customFormSpecsMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        let loggedInUserId = self.sessionService.getOperatorId();
        let currentForm = self.formService.getLatestForm();
        if (!currentForm.hasOwnProperty("customizations"))
            currentForm.customizations = {};

        // If the custom form specs message is for the currently logged in user
        if (message.hasOwnProperty("UserID") && !self.validationService.isNullOrEmpty(message.UserID) && loggedInUserId.toLocaleUpperCase() == message.UserID.toLocaleUpperCase()) {
            // Cache the elementList in CustomFormSpecsMessage so that they can be used when enabling/disabling custom field sequence
            self.sessionStorage.setPrefix("customFormSpecsElementList");
            self.sessionStorage.set(message.FormID, message.ElementList);

            // CUI-3496
            for (let i = 0; i < message.ElementList.length; i++) {
                let elem = message.ElementList[i];

                let targetField = currentForm.fields[elem.ElementID];

                if (targetField == null) {
                    for (let field in currentForm.fields) {
                        let arr = field.split('_');
                        if (arr[0] != null && field.indexOf(elem.ElementID + "_") == 0) {
                            targetField = currentForm.fields[field];
                            if (targetField != null) {

                                //update tabbing for CFS
                                self.updateFieldTabbing(targetField, elem, currentForm);

                                if (targetField.hasOwnProperty("elementRequired") && targetField.elementRequired == "Y") {
                                    targetField.required = true;
                                    //Changes to make a field required based on customized field sequence.
                                    if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === true) {
                                        // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                                        targetField.customFieldRequired = true;
                                    } else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === false) {
                                        // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                                        targetField.customFieldRequired = false;
                                    }
                                }
                                //Changes to make a field required based on customized field sequence.
                                else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === true) {
                                    targetField.required = true;
                                    // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                                    targetField.customFieldRequired = true;
                                } else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === false) {
                                    targetField.required = false;
                                    // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                                    targetField.customFieldRequired = false;
                                }
                                //Changes to make a field inquiry based on customized field sequence.
                                if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === true) {
                                    targetField.inquiry = true;
                                    // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                                    // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                    targetField.customFieldInquiry = true;
                                } else if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === false) {
                                    targetField.inquiry = targetField.elementType.toLocaleLowerCase() === "inquiry";
                                    // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                                    // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                    targetField.customFieldInquiry = false;
                                }

                                //Changes to make a field not accessible based on customized field sequence.
                                if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === true) {
                                    targetField.runTimeNoAccess = true;
                                    // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                                    // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                    targetField.customFieldNoAccess = true;
                                } else if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === false) {
                                    targetField.runTimeNoAccess = false;
                                    // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                                    // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                    targetField.customFieldNoAccess = false;
                                }

                                //Changes needed to track if a given field is the first field in customize field sequence
                                if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === true) {
                                    targetField.customFirstField = true;
                                } else if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === false) {
                                    targetField.customFirstField = false;
                                }

                                //Changes needed to track if a given field is the first field in customize field sequence
                                if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === true) {
                                    targetField.customLastField = true;
                                } else if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === false) {
                                    targetField.customLastField = false;
                                }

                                targetField.screenReaderText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, false);
                                targetField.hoverText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, true);
                            }
                        }
                    }
                } else if (targetField != null) {

                    //update tabbing for CFS
                    self.updateFieldTabbing(targetField, elem, currentForm);

                    if (targetField.hasOwnProperty("elementRequired") && targetField.elementRequired == "Y") {
                        targetField.required = true;
                        //Changes to make a field required based on customized field sequence.
                        if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === true) {
                            // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                            targetField.customFieldRequired = true;
                        } else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === false) {
                            // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                            targetField.customFieldRequired = false;
                        }
                    }
                    //Changes to make a field required based on customized field sequence.
                    else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === true) {
                        targetField.required = true;
                        // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                        targetField.customFieldRequired = true;
                    } else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === false) {
                        targetField.required = false;
                        // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                        targetField.customFieldRequired = false;
                    }

                    //Changes to make a field inquiry based on customized field sequence.
                    if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === true) {
                        targetField.inquiry = true;
                        // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                        targetField.customFieldInquiry = true;
                    } else if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === false) {
                        targetField.inquiry = targetField.elementType.toLocaleLowerCase() === "inquiry";
                        // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                        targetField.customFieldInquiry = false;
                    }

                    //Changes to make a field not accessible based on customized field sequence.
                    if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === true) {
                        targetField.runTimeNoAccess = true;
                        // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                        targetField.customFieldNoAccess = true;
                    } else if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === false) {
                        targetField.runTimeNoAccess = false;
                        // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                        targetField.customFieldNoAccess = false;
                    }

                    //Changes needed to track if a given field is the first field in customize field sequence
                    if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === true) {
                        targetField.customFirstField = true;
                    } else if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === false) {
                        targetField.customFirstField = false;
                    }

                    //Changes needed to track if a given field is the first field in customize field sequence
                    if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === true) {
                        targetField.customLastField = true;
                    } else if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === false) {
                        targetField.customLastField = false;
                    }

                    targetField.screenReaderText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, false);
                    targetField.hoverText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, true);
                }
            }

            // CUI-3515
            if (!self.validationService.isNullOrEmpty(currentForm.fields))
                self.parserService.createSequenceOrderForElements(currentForm.fields, message.ElementList);

            // Save the current user's elementList to the form
            currentForm.customizations[loggedInUserId] = message;
        }
        // If the customizations are not for the currently logged in user,
        else {
            // In customize field sequence properties window, when a user, other than the currently logged in user, is selected from Customize for dropdown,
            // we need to update the sequence order of elements on the window.
            // Therefore, we will pass a property called UpdateTargetFields to indicate the loading of target fields with appropriate flags.
            if (message.hasOwnProperty("UpdateTargetFields") && !self.validationService.isNullOrEmpty(message.UpdateTargetFields) && message.UpdateTargetFields === true) {
                for (let i = 0; i < message.ElementList.length; i++) {
                    let elem = message.ElementList[i];

                    let targetField = currentForm.fields[elem.ElementID];

                    if (targetField == null) {
                        for (let field in currentForm.fields) {
                            let arr = field.split('_');
                            if (arr[0] != null && field.indexOf(elem.ElementID + "_") == 0) {
                                targetField = currentForm.fields[field];
                                if (targetField != null) {

                                    //Changes to make a field required based on customized field sequence.
                                    if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === true) {
                                        targetField.required = true;
                                        // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                                        targetField.customFieldRequired = true;
                                    } else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === false) {
                                        targetField.required = false;
                                        // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                                        targetField.customFieldRequired = false;
                                    }
                                    //Changes to make a field inquiry based on customized field sequence.
                                    if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === true) {
                                        targetField.inquiry = true;
                                        // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                        targetField.customFieldInquiry = true;
                                    } else if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === false) {
                                        targetField.inquiry = targetField.elementType.toLocaleLowerCase() === "inquiry";
                                        // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                        targetField.customFieldInquiry = false;
                                    }

                                    //Changes to make a field not accessible based on customized field sequence.
                                    if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === true) {
                                        targetField.runTimeNoAccess = true;
                                        // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                        targetField.customFieldNoAccess = true;
                                    } else if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === false) {
                                        targetField.runTimeNoAccess = false;
                                        // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                                        // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                                        targetField.customFieldNoAccess = false;
                                    }

                                    //Changes needed to track if a given field is the first field in customize field sequence
                                    if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === true) {
                                        targetField.customFirstField = true;
                                    } else if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === false) {
                                        targetField.customFirstField = false;
                                    }

                                    //Changes needed to track if a given field is the first field in customize field sequence
                                    if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === true) {
                                        targetField.customLastField = true;
                                    } else if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === false) {
                                        targetField.customLastField = false;
                                    }

                                    targetField.screenReaderText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, false);
                                    targetField.hoverText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, true);
                                }
                            }
                        }
                    } else if (targetField != null) {
                        //Changes to make a field required based on customized field sequence.
                        if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === true) {
                            targetField.required = true;
                            // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                            targetField.customFieldRequired = true;
                        } else if (elem.hasOwnProperty("ElementRequired") && elem.ElementRequired === false) {
                            targetField.required = false;
                            // Parser sets the same property (required) as the custom form specs. Therefore, setting a different property.
                            targetField.customFieldRequired = false;
                        }

                        //Changes to make a field inquiry based on customized field sequence.
                        if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === true) {
                            targetField.inquiry = true;
                            // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                            // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                            targetField.customFieldInquiry = true;
                        } else if (elem.hasOwnProperty("ElementInquiry") && elem.ElementInquiry === false) {
                            targetField.inquiry = targetField.elementType.toLocaleLowerCase() === "inquiry";
                            // Both CustomFormSpecs and FormSpecs set the same property inquiry to the targetField.
                            // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                            targetField.customFieldInquiry = false;
                        }

                        //Changes to make a field not accessible based on customized field sequence.
                        if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === true) {
                            targetField.runTimeNoAccess = true;
                            // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                            // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                            targetField.customFieldNoAccess = true;
                        } else if (elem.hasOwnProperty("ElementNoAccess") && elem.ElementNoAccess === false) {
                            targetField.runTimeNoAccess = false;
                            // Both CustomFormSpecsMessage and FieldSecurityMessage set the same property runTimeNoAccess to the targetField.
                            // There is no way to distinguish between the two. For Custom Field Sequence checkboxes, we need a distinction, hence this property.
                            targetField.customFieldNoAccess = false;
                        }

                        //Changes needed to track if a given field is the first field in customize field sequence
                        if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === true) {
                            targetField.customFirstField = true;
                        } else if (elem.hasOwnProperty("ElementFirstField") && elem.ElementFirstField === false) {
                            targetField.customFirstField = false;
                        }

                        //Changes needed to track if a given field is the first field in customize field sequence
                        if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === true) {
                            targetField.customLastField = true;
                        } else if (elem.hasOwnProperty("ElementLastField") && elem.ElementLastField === false) {
                            targetField.customLastField = false;
                        }

                        targetField.screenReaderText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, false);
                        targetField.hoverText = self.formService.buildHelperText(targetField, targetField.elementHelpLabel, true);
                    }
                }

                // CUI-3515
                if (!self.validationService.isNullOrEmpty(currentForm.fields))
                    self.parserService.createSequenceOrderForElements(currentForm.fields, message.ElementList);
            }
            // Save the current user's elementList to the form
            currentForm.customizations[message.UserID] = message;
            if (self.rootScopeService.callCustomTabRetrieval == true)
                self.eventsService.broadcast("updateCopyToOtherUsersCustomizedListener", message.UserID);
        }
    }

    updateFieldTabbing(fieldElement: any, customFieldSpec: any, form: any) {
        if (fieldElement.isInputField || fieldElement.isValcodeField) {

            fieldElement.customNextField = null;
            fieldElement.customNextElement = null;
            fieldElement.customPreviousField = null;

            // Calculate next field to be used when tabbing on non window field or
            if (this.validationService.isNullOrEmpty(customFieldSpec.ElementElementForward)) {

                if (form.fields[customFieldSpec.ElementFieldForward] != null) {
                    fieldElement.customNextField = form.fields[customFieldSpec.ElementFieldForward].sanitizedID;
                }
                else if (form.fields[customFieldSpec.ElementFieldForward + "_1"] != null) {
                    fieldElement.customNextField = form.fields[customFieldSpec.ElementFieldForward + "_1"].sanitizedID;
                }
            }
            else {
                if (form.fields[customFieldSpec.ElementElementForward + "_" + fieldElement.rowIndex] != null) { // if window element
                    fieldElement.customNextField = form.fields[customFieldSpec.ElementElementForward + "_" + +fieldElement.rowIndex].sanitizedID;
                }
                else if (form.fields[customFieldSpec.ElementElementForward] != null) { // non window element
                    fieldElement.customNextField = form.fields[customFieldSpec.ElementElementForward].sanitizedID;
                }
            }

            // Calculate next element to be used when tabbing on empty window row element
            if (this.validationService.isNullOrEmpty(fieldElement.elementWindowController) == false &&
                fieldElement.elementWindowController == fieldElement.originalElementID) {
                if (form.fields[customFieldSpec.ElementFieldForward] != null) {
                    fieldElement.customNextElement = form.fields[customFieldSpec.ElementFieldForward].sanitizedID;
                }
                else if (form.fields[customFieldSpec.ElementFieldForward + "_1"] != null) {
                    fieldElement.customNextElement = form.fields[customFieldSpec.ElementFieldForward + "_1"].sanitizedID;
                }
            }

            // Used for Shift + Tab
            if (form.fields[customFieldSpec.ElementFieldBack] != null) {
                fieldElement.customPreviousField = form.fields[customFieldSpec.ElementFieldBack].sanitizedID;
            }
            else if (form.fields[customFieldSpec.ElementFieldBack + "_1"] != null) {
                fieldElement.customPreviousField = form.fields[customFieldSpec.ElementFieldBack + "_1"].sanitizedID;
            }
        }
    }

    operatorInfoMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        if (message.hasOwnProperty("UserList") && !self.validationService.isNullOrEmpty(message.UserList) && message.UserList.length > 0) {
            self.eventsService.broadcast("customizeForUserDataReadyListener", message.UserList);
        }
    }

    formSpecsMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.rootScopeService.firstTimeFormLoad = true;
        self.rootScopeService.focusedFieldName = "";
        self.rootScopeService.clickedFieldName = "";
        self.showWaitingDialog('Form Specs Message', 'Loading data', "Processing ...");
        self.formService.formDisabled = true;

        // CSSP-8625: Disable Attach Link any time a new form is launched. Let AttachSpecsMessage enable it, if needed.
        self.attachService.setAttachLinkState(false);

        var formRecordDeleteAllowed = message.form.formRecordDeleteAllowed;
        let form: any = {};

        form = self.parserService.parseFormSpecs(message, message.FormID);
        let combinedFormId = '';
        for (let i = 0; i < self.formService.forms.length; i++) {
            combinedFormId += self.formService.forms[i].baseFormId + "_";
        }
        combinedFormId += form.baseFormId;
        form.combinedFormId = combinedFormId.replace(/\./g, '-');
        form.baseFormId = form.baseFormId.replace(/\./g, '-');

        self.rendererService.calculateFormPosition(form.baseFormId, form.fields, form.windowList, form.lastRow, form.lastColumn, form.bottomHeaderRow, combinedFormId, form.formId);

        self.formService.activeForm = form;
        self.formService.openForm(form);
        self.eventsService.broadcast('ResetPreviousField');
        // Change Form Search Text only if it is not first form
        // Change Form Search Text if mnemonic doesn't start with wf (workflow)
        if ((!self.formService.forms || self.formService.forms.length == 1) && !(form.formMnemonic.indexOf("wf") == 0 && form.formMnemonic.length >= 5)) {
            self.searchService.criteria.form = form.formMnemonic + ": " + form.formTitle;
            self.eventsService.broadcast("UpdateSearchInput", self.searchService.criteria.form);

            // CUI-5051
            // CR-000155048: appl reference in Form Search not retained for subsequent access
            // let application: string = message.form.formApplication;
            // console.log(self.searchService.criteria);
            // self.searchService.addToHistory(form.formMnemonic + ": " + form.formTitle, true, application + "-" + message.form.formMnemonic, true);
        }
        else if (!self.validationService.isNullOrEmpty(self.rootScopeService.lastFormSearchHistoryId) && (!self.formService.forms || self.formService.forms.length == 1) && form.formMnemonic.indexOf("wf") == 0 && form.formMnemonic.length >= 5) {
            let parts = self.rootScopeService.lastFormSearchHistoryId.split(" $$$ ")
            self.searchService.addToHistory(parts[0], true, parts[1], true);
            self.rootScopeService.lastFormSearchHistoryId = "";
        }
        form.formRecordDeleteAllowed = formRecordDeleteAllowed;

        // In case the Disable Custom Field Sequence option is checked, CustomFormSpecsMessage is not sent when launching a form. Call it from here.
        if (self.rootScopeService.disableCustomFieldSequence == true) {
            self.parserService.createSequenceOrderForElements(self.formService.getLatestForm().fields, null);
        }

        setTimeout(() => {
            self.formService.setFormDefaults();
            // Toggle the styles because of FIrefox issue related to refreshing styles.
            // CUI-4467
            if (!self.formService.formDisabled && isFirefox == true) {
                self.formService.formDisabled = true;
                setTimeout(() => {
                    self.formService.formDisabled = false;
                    setTimeout(function () {
                        let focusFieldName = document.getElementById(self.rootScopeService.focusedFieldName) as HTMLInputElement;
                        focusFieldName.focus();
                        focusFieldName.select();
                    }, 100);
                }, 10);
            }
        }, 500);
        form = null;
        combinedFormId = null;
    }

    detailMenuMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.dismissDialogMessageAlways();
        let selectedDetailCallback = (mnemonic: string) => {
            mnemonic === '' ? self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.screenCancel, message.MessageId, "") : self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, message.MessageId, mnemonic);
        };
        self.formService.formDisabled = true;
        self.subMenuDialogService.openSubMenuDialog(message.MenuItems, selectedDetailCallback);
    }

    dialogPromptMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.dismissDialogMessageAlways();
        self.searchService.toggleRunning(false);
        if (self.rootScopeService.isFormOpen) self.formService.formDisabled = true;
        var contextData = self.contextService.getContext();
        let isContextExist = contextData;
        if (message.ContextFormOpen != null) {
            self.contextService.openContextForm = message.ContextFormOpen;
            self.rootScopeService.isContextFormOpen = message.ContextFormOpen;
        }

        if (message.AutoLoad != null && message.AutoLoad == true) {
            self.contextService.openContextForm == true;
            self.rootScopeService.isContextFormOpen = true;
            // self.contextService.activeFormContext = message.Context;
        }

        if ((message.Context == 'PERSON' || message.Context == 'CORP' || message.Context == 'STAFF')) {
            self.isPersonLookup = true;
        }


        // Reset for first field empty bug
        self.formService.isError = false;

        if (self.isPersonLookup && message.AutoLoad == true) {

            if (isContextExist.data.length > 0) {
                self.isPersonLookup = false;
                let contextPersonData: any = [];
                _.each(isContextExist.data, (it: any) => {
                    contextPersonData.push(it.ID);
                });
                var currentContext = self.contextService.context.PERSON.position;
                var xposition = currentContext;
                var plusOne = 1;
                var position = xposition + plusOne;
                var listOfpersonId = contextPersonData.join('|');
                self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "ID", contextPersonData.length > 1 ? (listOfpersonId + ";" + position) : contextPersonData[0]);
                self.contextService.checkCancel = false;
            } else {
                //open form first
                self.isContextNext = false;
                self.isPersonLookup = true;
                self.rootScopeService.isContextFormOpen = true;
            }
        }

        self.rootScopeService.doNotRemoveDuplicate = message.ListComplete == true;
        // If list complete option set, may need to clear context information
        if (message.ListComplete) {
            if (self.contextHeaderService.contextOpenflag(false)) {

                var currentContext = self.contextService.getContext();

                _.each(currentContext.data, (contextCard: any) => {
                    contextCard.toBeRemoved = true;
                });

                self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.closeSelected, message.MessageId, "ALL");

                //Remove all context if not person. Form Person we will get confirm close message.
                // if (message.Context != Constants.helperText.person) {
                self.formService.autoCloseContext(false);
                //}
            }
            if (message.Context === "PERSON") {
                self.isPersonLookup = true;
            }
        }
        // Set up the dialog prompt contents and show it
        if (self.isPersonLookup) {
            if (message.PromptText === "Person LookUp") {
                self.formService.autoCloseContext(false);
            }
            self.isContextNext = false;
            self.contextService.checkContextRecord = false;
            let currentContextposition = self.contextService.getContext().position;
            if (self.contextService.checkCancel) {
                if (self.contextService.positionCloseAll) {
                    self.contextService.position("PERSON", '-1');
                    self.contextService.checkCancel = false;
                } else {
                    self.contextService.position("PERSON", currentContextposition);
                    self.contextService.checkCancel = false;
                }
            }
            let popupMessage: any = {};
            let promptTextMsg = "This is a Prompt Wide Phantom";
            popupMessage.title = ' ';
            popupMessage.text = [message.PromptText];
            popupMessage.placeHolderText = message.PromptText;
            message.PromptText === promptTextMsg ? popupMessage.placeHolderText = "Prompt Wide Phantom" : popupMessage.placeHolderText;
            popupMessage.inputType = message.MaskInput === true ? "password" : "text";
            popupMessage.inputValue = message.DefaultInput;
            popupMessage.defaultCallbackNumber = 0;
            popupMessage.buttons = [{
                label: "Ok",
                callback: (args: any, jumpPosition: any) => {
                    self.promptService.popupMessageDismiss(() => {
                        self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, message.MessageId, jumpPosition);
                    });
                    self.jumpPosition = jumpPosition;
                },
                title: ""
            },
            {
                label: "Cancel",
                callback: () => {

                    self.promptService.popupMessageDismiss(() => {
                        self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.screenCancel, message.MessageId, "");
                    });
                },
                title: ""
            },
            {
                label: "Finish",
                callback: () => {

                    self.promptService.popupMessageDismiss(() => {
                        self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.processFinish, message.MessageId, "");
                    });
                },
                title: ""
            },
            {
                label: "Help",
                callback: () => {
                    self.processingService.hideProcessing(true);
                    let formData = self.formService.activeForm.original.form;
                    let helpKey = formData.formApplication + "-" + formData.formID;
                    self.helpService.display(helpKey, "FORM");
                },
                title: ""
            }
            ];
            self.promptService.popupMessageShow(popupMessage, true);

        } else if (message.Context !== "PERSON" && message.Context != 'CORP' && message.Context != 'STAFF') {
            let popupMessage: any = {};
            let promptTextMsg = "This is a Prompt Wide Phantom";
            popupMessage.title = ' ';
            popupMessage.text = [message.PromptText];
            popupMessage.placeHolderText = message.PromptText;
            message.PromptText === promptTextMsg ? popupMessage.placeHolderText = "Prompt Wide Phantom" : popupMessage.placeHolderText;
            popupMessage.inputType = message.MaskInput === true ? "password" : "text";
            popupMessage.inputValue = message.DefaultInput;
            popupMessage.defaultCallbackNumber = 0;
            popupMessage.buttons = [{
                label: "Ok",
                callback: (args: any, jumpPosition: any) => {
                    self.promptService.popupMessageDismiss(() => {
                        self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, message.MessageId, jumpPosition);
                        self.showWaitingDialog('Dialog Prompt Ok', 'Please Wait ...', 'Processing ...');
                    });
                    self.jumpPosition = jumpPosition;
                },
                title: ""
            },
            {
                label: "Cancel",
                callback: () => {

                    self.promptService.popupMessageDismiss(() => {
                        self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.screenCancel, message.MessageId, "");
                    });
                },
                title: ""
            },
            {
                label: "Finish",
                callback: () => {

                    self.promptService.popupMessageDismiss(() => {
                        self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.processFinish, message.MessageId, "");
                    });
                },
                title: ""
            },
            {
                label: "Help",
                callback: () => {
                    self.processingService.hideProcessing(true);
                    let formData = self.formService.activeForm.original.form;
                    let helpKey = formData.formApplication + "-" + formData.formID;
                    self.helpService.display(helpKey, "FORM");
                },
                title: ""
            }
            ];
            self.promptService.popupMessageShow(popupMessage, true);
        }

        self.rootScopeService.focusedFieldName = "";
    }

    displayBroadcastMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        var broadCastMessage: any = {};
        if (!self.validationService.isNullOrEmpty(message.TitleBar))
            broadCastMessage.title = message.TitleBar;
        broadCastMessage.text = message.BroadcastMessage;
        broadCastMessage.buttons = [{
            label: "Dismiss",
            callback: () => {
                self.promptService.popupMessageDismiss(() => {
                    self.processingService.closeProcessing();
                }, false);
            },
            SearchFocus: false
        }];
        broadCastMessage.defaultCallbackNumber = "1";
        self.promptService.popupMessageShow(broadCastMessage);
    }

    displayDialogMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        if (message.Message.indexOf("Selecting Packaging") == -1) {
            self.processingService.showProcessing("Display Dialog Message", "Please Wait ...", message.Message);
        }
    }

    fileDownloadMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;

        let action = message.DownloadAction;
        if (action === "PrintFile") {
            // Ignore - fully handled by Report Browser module which
            // will send a different message to service the download.
        } else if (action === "ReadFile") {
            // Not implemented, but react to with cancel to be safe...
            self.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.screenCancel);
        }
    }

    formStateMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        if (message.FormStateCode === "CLOSE") {
            self.dismissDialogMessageAlways();

            // Save the ID of the form that will be closed. Once it is closed, remove its AttachSpecs.
            let formIdToRemove = self.formService.getLatestForm().formId;

            // Remove the form from formService's forms collection
            self.formService.closeForm();

            // CSSP-8495 -> Do not need to check if formService contains a length of form. removeAttachSpecs method already contains checks to delete formID if it exists in the collection.
            // if (self.formService.forms.length > 0)
            
            // Remove the form's AttachSpecs, if any, from attachService
            self.attachService.removeAttachSpecs(formIdToRemove);

            //Close All Context if the form getting closed is first form;
            var formids = message.FormID.split('_');
            if (parseInt(formids[formids.length - 1]) == 1) {
                self.rootScopeService.focusedFieldName = "";
                // self.searchService.focusOnSearchInput();
                self.formService.autoCloseContext(true);
            }
        }

        // Reset these variables to avoid context card and form area mismatch
        self.isContextJump = false;
        self.isContextNext = false;
        self.isContextPrevious = false;
        self.isContextReturn = false;
        self.rootScopeService.isContextUpdate = false;
        self.rootScopeService.isContextCancel = false;
    }

    formStatusMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        if (message.ContextFormOpen != null) {
            self.contextService.openContextForm = message.ContextFormOpen;
            self.rootScopeService.isContextFormOpen = message.ContextFormOpen;
        }
    }

    hideHeaderFieldsListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        _.remove(self.formService.hideHeaderFields, (field: any) => {
            return field.formID == message.FormID;
        });

        if (message.HeaderFields.length > 0) {
            for (let i = 0; i < message.HeaderFields.length; i++) {
                self.formService.hideHeaderFields.push({
                    formID: message.FormID,
                    fieldName: message.HeaderFields[i].FieldName
                });

            }
        }

        setTimeout(() => {
            self.formService.setFormDefaults();
        }, 500);
    }

    inquiryFormReadyMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.dismissDialogMessageAlways();
        self.formService.formDisabled = false;
    }

    navigationLockMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        if (message.LockControls === true) {
            self.contextService.active.locked = true;
            self.formService.formDisabled = true;
        }
        else {
            self.contextService.active.locked = false;
            self.formService.formDisabled = false;
        }
    }

    OSButtonMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.rootScopeService.TimeoutMessageOpen = true;
        let popupMessage: any = {};
        popupMessage.title = message.TitleBar;
        popupMessage.text = message.OSMessage;
        popupMessage.defaultCallbackNumber = 1;
        popupMessage.buttons = [{
            label: "Ok",
            callback: () => {
                self.promptService.popupMessageDismiss(() => {
                    self.rootScopeService.TimeoutMessageOpen = false;
                    self.eventsService.broadcast("logoutComplete");
                    self.processingService.closeProcessing();
                    self.eventsService.broadcast("SessionCloseMessage");
                }, false);
            },
            title: ""
        }];
        self.promptService.popupMessageShow(popupMessage, true);
    }

    processStatusMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        var status = message.Status;
        if (status === "InProgress") {
            self.eventsService.broadcast("startWaiting");
            self.showWaitingDialog('Start Waiting', 'Please Wait ...', 'Processing ...');
        } else if (status === "ReadyForInput") {
            self.eventsService.broadcast("stopWaiting");
            self.dismissDialogMessageAlways();
            self.rootScopeService.isWaiting = false;
            setTimeout(() => {
                self.searchService.focusOnSearchInput();
            }, 100);
        }
    }

    clientInfoMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        let uiVersionString = "UiVersion";
        let uiWebApiUrlString = "UiWebApiUrl";
        let colleagueApiTokenString = "ColleagueApiToken";
        let requestedProperty = message['RequestedProperty'];

        if (_.isEqual(requestedProperty, uiVersionString)) {
            self.serverCommandService.sendProcessCommandWithDataMessage("AuthenticatedSettings", uiVersionString, Models.UI.version);
        }
        else if (_.isEqual(requestedProperty, uiWebApiUrlString)) {
            self.serverCommandService.sendProcessCommandWithDataMessage("UiWebApiUrlSettings", uiWebApiUrlString, webAPIBaseUrl);
        }
        else if (_.isEqual(requestedProperty, colleagueApiTokenString)) {
            self.serverCommandService.sendProcessCommandWithDataMessage("AuthenticatedSettings", colleagueApiTokenString, self.sessionService.getColleagueToken());
        }
    }

    refreshHeaderMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;

        let resultsCallback = (results: any, contextId: any, searchCriteria: any) => {
            if (results.TotalRows > 0 && !isNaN(searchCriteria) && searchCriteria !== '') {
                // User is searching for a person record that already exists in Colleague
                self.contextService.addContextData(contextId, self.searchService.parseResults(results), true);
            } else if (results.TotalRows == 0 && message.NewRecordID && self.contextService.newRecord) {
                // User is trying to add a new person to Colleague
                self.contextService.addTempContext({
                    recordID: message.NewRecordID,
                    context: "PERSON"
                });
                self.contextService.newRecord = false
            }
        };

        // Search for the given RefreshRecordID. In response, add the result to the context data.
        self.searchService.search(message.RefreshContext, "PERSON", 1, 1, self.searchService.buildQueryColumns(message.RefreshContext), message.RefreshRecordID, "", "", "", "", resultsCallback);
    }

    removeContextMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.contextService.removeContextRecord(message.ContextList)
    }

    searchResultsMessageListener(service: any, message: any, fromError: any = false) {
        let self = <MessageListenerService>service;
        if (fromError) self.errorCount += 1;
        else {
            self.errorCount = 0;
            self.searchService.clearCache();
        }

        if (self.errorCount == 2) {
            self.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.screenCancel, "", "");
            return;
        }
        self.rootScopeService.searchResultMessage = true;
        self.rootScopeService.isWaiting = false;
        self.dismissDialogMessageAlways();
        self.showWaitingDialog('Search Results Message', "Searching ...", " Searching for " + message.SearchCriteria);
        var contextId = message.Context;
        var ViewName = message.Results.ViewName;
        self.contextService.fetchContext(contextId, [self.fetchContextCallback, self, contextId, message, self.searchResultsMessageListener]); // .then(() => {


        // }, (reason: any) => {
        //     // create default context with just @ID and use that
        //     // console.log('Failed:');
        // });
    }

    fetchContextCallback(service: any, contextId: any, message: any) {
        let self = <MessageListenerService>service;
        var contextData = self.contextService.getContext(contextId);
        // Reset render type, GRID or CARD, to default since earlier search may have changed it
        contextData.ResultPage.renderType = contextData.ResultPage.initialRenderType;
        // Now set the appropriate cursor size based on that render type
        var cursorSize;
        if (contextData.ResultPage.renderType === "CARD") {
            cursorSize = self.rootScopeService.preferences.CardSearchResults;
        } else {
            cursorSize = self.rootScopeService.preferences.GridSearchResults;
        }
        var startRow = 1;
        var queryColumns = self.searchService.buildQueryColumns(contextId);
        var cursorHandle;
        if (_.isArray(message.Results)) {
            cursorHandle = message.Results[0].QueryHandle;
        } else {
            cursorHandle = message.Results.QueryHandle;
        }

        if (!self.validationService.isNullOrEmpty(message.SearchCriteria))
            self.rootScopeService.searchCriteria = message.SearchCriteria;

        var criteria = '';
        self.searchService.state.currentContextIndex = "LKUP_" + contextId;
        self.searchService.clearResults();
        // form or person search icon change
        self.searchService.state.isGenericSearch = true;
        var srData = self.searchService.getCurrentSRData();
        srData.searchResults = {};
        srData.contextData = contextData;
        self.showWaitingDialog('Search after Context', "Searching ... ", " Searching for " + message.SearchCriteria);

        var clearWaitingCallback = (hideSearchResults: any) => {
            self.dismissDialogMessageAlways();
            self.searchService.toggleVisible(!hideSearchResults);
            self.eventsService.broadcast("RefreshResultPage");
            var srData = self.searchService.getCurrentSRData();
            srData.searchResults.criteria = message.SearchCriteria;
            srData.control.addMode = (message.AddMode === true) ? true : false;
            srData.control.autoLoad = (message.AutoLoad === true) ? true : false;
            srData.control.multiSelection = (message.MultiSelection) ? true : false;
            srData.control.sortSelectAllowed = (message.SortSelectAllowed === true) ? true : false;
            var viewNames = [];
            var viewHandles = [];
            if (self.searchService.state.error === undefined) {
                if (_.isArray(message.Results.QueryResult)) {
                    for (var i = 0; i < message.Results.QueryResult.length; i++) {
                        viewNames.push(message.Results.QueryResult[i].ViewName);
                        viewHandles.push(message.Results.QueryResult[i].QueryHandle);
                    }
                } else {
                    for (var i = 0; i < message.Results.length; i++) {
                        viewNames.push([message.Results[i].ViewName]);
                        viewHandles.push([message.Results[i].QueryHandle]);
                    }
                }
            }
            srData.views.viewNames = viewNames;
            srData.views.viewHandles = viewHandles;
            srData.views.selectedView = 0;
            //   setTimeout(function () {
            self.dismissDialogMessageAlways();
            //  }, 150);
        };
        var failureCallback = (hideSearchResults: any, errMsg: any) => {
            self.searchError(errMsg);
            self.processingService.closeProcessing();
        };
        self.rootScopeService.showProcessingWaitOnSearchClose = true;
        self.searchService.search(contextId, cursorHandle, startRow, cursorSize, queryColumns, criteria, "", "", clearWaitingCallback, failureCallback);
    }


    debugPauseMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.showWaitingDialog('Debugging', 'Debugging', 'Execution has been halted.');
    }

    debugResumeMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        self.dismissDialogMessageAlways();
    }


    valcodeResponseMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        var currentForm = self.formService.getLatestForm();

        // Since Form State Message is processed before any other messages, if a ValcodeResponseMessage is encountered along with the FormStateMessage request, the currentForm may not have any record.
        // Therefore, added a null check before proceeding further. See CUI-5641 (https://jirateams.ellucian.com/browse/CUI-5641).
        if (currentForm == null || currentForm == undefined)
            return;

        var displayDescription = false;
        _.each(message.Contents, (valcode: any) => {
            if (valcode.Code != valcode.Description) displayDescription = true;
        });

        // Find the target field(s) to go against.
        var targetField = currentForm.fields[message.FieldName];
        if (targetField === undefined) {
            // Field may be in a window, look for it there
            var rowCount = 0;
            _.each(currentForm.windowList, (value: any, key: any) => {
                if (rowCount === 0) {
                    // Get the Total Number of window Rows with valcode
                    for (let field in currentForm.fields) {
                        var arr = field.split('_');

                        if (arr[0] != null && field.indexOf(message.FieldName + "_") >= 0) {
                            rowCount++;
                        }
                    }
                }
            });
            // If field is a window controller or element, apply valcode to all rows for that field
            if (rowCount > 0) {
                for (var i = 1; i <= rowCount; i++) {
                    targetField = currentForm.fields[message.FieldName + "_" + i];
                    if (targetField !== undefined) {
                        targetField.options = [];
                        targetField.displayValcodeDescription = displayDescription;
                        targetField.options = $.extend(true, [], _.clone(message.Contents));
                    }
                }
            }
        } else {
            // Single valued field
            currentForm.fields[message.FieldName].options = [];
            currentForm.fields[message.FieldName].displayValcodeDescription = displayDescription;
            currentForm.fields[message.FieldName].options = $.extend(true, [], _.clone(message.Contents));
            currentForm.fields[message.FieldName].values = $.extend(true, [], _.clone(message.Contents));

        }
    }


    windowDataMessageListener(service: any, message: any) {
        let self = <MessageListenerService>service;
        var currentForm = self.formService.getLatestForm();
        var windowController = message.WindowController;
        if (windowController != null) {
            currentForm.windowList[windowController][0].windowTotalEntries = message.TotalEntries === 0 ? 1 : message.TotalEntries;
            currentForm.windowList[windowController][0].windowTotalPages = message.TotalPages === 0 ? 1 : message.TotalPages;
            currentForm.windowList[windowController][0].windowCurrentPage = message.CurrentPage === 0 ? 1 : message.CurrentPage;

            var windowFields = self.formService.getWindowFields(windowController);
            for (var i = 0; i < windowFields.length; i++) {
                var it = windowFields[i];
                it.rowIndex = self.getRowIndex(parseInt(it.elementID.split('_')[1]), currentForm.windowList[windowController][0].windowCurrentPage, currentForm.windowList[windowController][0].windowRowCount)
            }
        }

    }

    private disableForm() {
        $(document).keydown((event: any) => {
            return false;
        });
        this.formService.formDisabled = true;
    }

    private showWaitingDialog(event: string, message: string, title: string = null) {
        var alertArgs = {
            message: "Processing ...",
            alertTitle: "Please Wait ...",
            event: event
        };

        if (message) alertArgs.message = message;
        if (title) alertArgs.alertTitle = title;


        this.processingService.showProcessing(event, alertArgs.alertTitle, alertArgs.message);
    };

    private searchError(msg: string) {
        let errorMessage: any = {};
        errorMessage.title = 'Error';
        errorMessage.text = [msg];
        errorMessage.defaultCallbackNumber = 0;
        errorMessage.buttons = [{
            label: "Ok",
            callback: () => {
                // When help message has totally faded away, bring back the detail dialog
                this.promptService.popupMessageDismiss(() => {
                    setTimeout(() => {
                        this.searchService.state.isGenericSearch = false;
                        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.screenCancel);
                    }, 100);
                }, true);
            },
            title: ""
        }];
        this.processingService.closeProcessing();
        this.promptService.popupMessageShow(errorMessage);
    };
}