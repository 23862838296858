/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Inject, Injectable } from "@angular/core";
import { EventsService, ConfigurationService, SessionService, ServerCommandService, ProcessingService } from "./";
import * as Models from "../models/";

declare var $: any;

@Injectable()
export class FileService {
    fileUploadProgressMessageText: string = "";
    settings: any;
    isUploadOpen: boolean = false;
    isDownloadOpen: boolean = false;
    requestCount: number = 0;
    openFileArguments: any = {
        link: "",
        fileName: ""
    };

    constructor(private eventsService: EventsService, private processingService: ProcessingService,
        private sessionService: SessionService,
        private configurationService: ConfigurationService,
        private serverCommandService: ServerCommandService) {
        this.eventsService.on(Models.EventConstants.fileUploadProgressMessage, [this.fileUploadProgressMessageListener, this]);
        this.eventsService.on(Models.EventConstants.fileUploadMessage, [this.fileUploadMessageListener, this]);
        this.eventsService.on(Models.EventConstants.fileDownloadMessage, [this.fileDownloadMessageListener, this]);
    }

    /**
     * Listener to update the file upload progress message.
     * @param fileService
     * @param msg
     */
    fileUploadProgressMessageListener(fileService: any, msg: any) {
        let self: FileService = <FileService>fileService;
        self.fileUploadProgressMessageText = msg.Message;
    }

    /**
     * Listen to FileUploadMessage from server and load file upload modal.
     * @param fileService
     * @param settings
     */
    fileUploadMessageListener(fileService: any, settings: any) {
        let self: FileService = <FileService>fileService;
        self.processingService.closeProcessing();
        self.fileUploadProgressMessageText = "";
        self.settings = settings;
        self.isUploadOpen = true;
        // Show the modal dialog
        $('#upload-file .modal').modal({ backdrop: Models.DialogContants.staticStr, show: true });
    }

    /**
     * Process a File Download message
     * @param fileService
     * @param msg
     */
    fileDownloadMessageListener(fileService: any, msg: any) {
        let self: FileService = <FileService>fileService;
        let action: string = msg.DownloadAction;

        if (action === "SaveFile" || action === "OpenFile") {
            // Create the download URI
            let token: string = self.sessionService.getToken();
            let userId: string = self.sessionService.getUserId().toUpperCase();
            let fileName: string = msg.FileName;

            let urlArgs: string = userId + "/" + token + "/" + fileName;

            // Add a query string with a unique value - this is to prevent a cached response from being used
            // if a subsequent request is made for the same filename under the same session (same abs url)
            urlArgs += "?r=" + (++self.requestCount);
            let legacyBaseUrl: string = self.configurationService.getConfiguration().serviceUrl;
            let downloadURL: string = legacyBaseUrl + "/file/downloadFile/" + encodeURI(urlArgs);

            // Bring up the modal dialog with the link and file name
            self.openFileArguments.link = downloadURL;
            self.openFileArguments.fileName = fileName;

            // If alert dialog is open, close it
            self.processingService.closeProcessing();

            // Show the modal dialog
            self.isDownloadOpen = true;
            $('#open-file .modal').modal({ backdrop: 'static', show: true }).draggable();

            setTimeout(function () {
                document.getElementById("fileCloseBtn").focus();
            }, 100);
        }
    }
}