/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import {
    EventsService,
    UiLocalStorageService,
    NavigateService,
    PreferencesService,
    ProcessingService,
    PromptService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    SessionService,
    ValidationService
} from "../../../services";
import * as Models from "../../../models";
import * as _ from 'lodash';
declare var $: any;

@Component({
    selector: 'navbarsearch',
    template: require('./navbarSearch.component.html')
})
export class NavbarSearchComponent {

    personSearchCriteria: string = "";
    formSearchCriteria: string = "";
    inputFocused: boolean = false;
    personLastSearch: boolean = false;
    formLastSearch: boolean = false;
    lastPersonSearchResult: any = null;
    lastFormSearchResult: any = null;
    lastPersonSearchCriteria: any = null;
    lastFormSearchCriteria: any = null;

    changeSearchInputListenerId: string = "";
    initiateFormSearchListenerId: string = "";
    initiatePersonSearchListenerId: string = "";
    clearSearchInputListenerId: string = "";
    openSearchHistoryListenerId: string = "";
    enableFormSearchListenerId: string = "";

    constructor(private searchService: SearchService, private uiLocalStorageService: UiLocalStorageService,
        private serverCommandService: ServerCommandService, private preferencesService: PreferencesService, private processingService: ProcessingService,
        private sessionService: SessionService, private rootScopeService: RootScopeService, private promptService: PromptService, private eventsService: EventsService,
        private navigateService: NavigateService, private validationService: ValidationService) {
        this.changeSearchInputListenerId = this.eventsService.on("UpdateSearchInput", [this.changeSearchInput, this]);
        this.initiateFormSearchListenerId = this.eventsService.on("InitiateFormSearch", [this.initiateFormSearch, this])
        this.initiatePersonSearchListenerId = this.eventsService.on("InitiatePersonSearch", [this.initiatePersonSearch, this]);
        this.clearSearchInputListenerId = this.eventsService.on("ClearSearchInput", [this.clearSearchInput, this]);
        this.openSearchHistoryListenerId = this.eventsService.on("openSearchHistory", [this.openSearchHistory, this]);
        this.enableFormSearchListenerId = this.eventsService.on('enableFormSearch', [this.enableFormSearchListener, this]);


    }

    ngOnDestroy() {
        this.eventsService.destroy("UpdateSearchInput", this.changeSearchInputListenerId);
        this.eventsService.destroy("InitiateFormSearch", this.initiateFormSearchListenerId)
        this.eventsService.destroy("InitiatePersonSearch", this.initiatePersonSearchListenerId);
        this.eventsService.destroy("ClearSearchInput", this.clearSearchInputListenerId);
        this.eventsService.destroy("openSearchHistory", this.openSearchHistoryListenerId);
        this.eventsService.destroy('enableFormSearch', this.enableFormSearchListenerId);
    }

    changeSearchInput(navbarComponent: any, input: string) {
        let self = <NavbarSearchComponent>navbarComponent;
        self.setSearchInput(input);
    }

    initiateFormSearch(navbarComponent: any, input: string) {
        let self = <NavbarSearchComponent>navbarComponent;
        self.setSearchInput(input);
        self.search();
    }

    initiatePersonSearch(navbarComponent: any, input: string) {
        let self = <NavbarSearchComponent>navbarComponent;
        self.setSearchInput(input);
        self.search();
    }

    clearSearchInput(navbarComponent: any) {
        let self = <NavbarSearchComponent>navbarComponent;
        self.setSearchInput("");
    }

    openSearchHistory(navbarComponent: any) {
        let self = <NavbarSearchComponent>navbarComponent;
        if (self.searchService.state.isFormSearch) {
            self.toggleFormAutoComplete("");
        }
        else {
            self.togglePersonAutoComplete("");
        }
    }

    enablePersonSearch(event: any) {
        if (this.searchService.state.isFormSearch) {
            this.closePopover();
            this.searchService.toggleFormSearch();
            setTimeout(() => {
                this.searchService.focusOnSearchInput();
            }, 250);

        }
        else {
            this.closePopover();
            this.searchService.focusOnSearchInput();
        }
    }

    enableFormSearchListener(navbarSearch: NavbarSearchComponent) {
        let self: NavbarSearchComponent = <NavbarSearchComponent>navbarSearch;
        self.enableFormSearch(null);
    }

    enableFormSearch(event: any) {
        if (!this.searchService.state.isFormSearch) {
            this.closePopover();
            this.searchService.toggleFormSearch();
            setTimeout(() => {
                this.searchService.focusOnSearchInput();
            }, 250);
        }
        else {
            this.closePopover();
            this.searchService.focusOnSearchInput();
        }
    }

    search(event: any = null) {
        //return if keycode is not enter or UI is open in a tablet. Tablet enter key is handled in searchInputKey function
        if ((event && event.keyCode != Models.KeyCodes.enter)) return;
        this.performSearch();

    }

    private performSearch() {
        let criteriaBad = false;
        let formSearchCriteria, personSearchCriteria, formSearchApplicationCriteria = '';
        let directForm = false;
        let isFormSearch: boolean = this.searchService.state.isFormSearch;
        let handleSearchResultsCallback: boolean = false;

        // Reset cancelPrompted flag for each search so that if context already has person records and there is a form open, Client sends a ListRefresh instead of EmptyString request
        // CUI-5712: cancelPrompted needs to be null, not false
        this.rootScopeService.cancelPrompted = null;

        if (isFormSearch) {
            formSearchCriteria = this.searchService.formatSearchCriteria(this.formSearchCriteria);
            formSearchCriteria = this.searchService.scrubWildcards(formSearchCriteria);
            if (formSearchCriteria.length < 2) {
                criteriaBad = true;
            }


            let splitCriteria = formSearchCriteria.split(' $$$ ');
            let found = false;
            let formMnemonic = ''
            let historyList = this.searchService.criteria.formHistory;
            for (let i = 0; i < historyList.length; i++) {
                let history = ''
                if (historyList[i].indexOf(' $$$') > 0) {
                    history = historyList[i].substring(0, historyList[i].indexOf(' $$$'));


                    if (history === splitCriteria[0]) {
                        formMnemonic = historyList[i].substring(historyList[i].indexOf('$$$') + 4);
                        found = true;
                        break;
                    }

                    let splitCriteriaColon = splitCriteria[0].split(':');
                    let splitHistoryColon = historyList[i].split(':');

                    if (splitCriteriaColon[0].trim().toUpperCase() == splitHistoryColon[0].trim().toUpperCase()) {
                        formMnemonic = historyList[i].substring(historyList[i].indexOf('$$$') + 4);
                        this.formSearchCriteria = history;
                        found = true;
                        break;
                    }
                }

            }

            // Trap wafm and perform form-launch directly to avoid dmi connection error.
            // CUI-3695
            if (!found && formSearchCriteria.toLowerCase().indexOf('wafm') >= 0) {
                this.searchService.criteria.formHistory.push('WAFM: WebAdvisor File Maintenance $$$ UT-WAFM');
                this.uiLocalStorageService.set(Models.LocalStorageConstants.formSearchHistory, this.searchService.criteria.formHistory);
                found = true;
                formMnemonic = 'UT-WAFM';
                formSearchCriteria = 'WAFM: WebAdvisor File Maintenance';
            }

            if (found) {
                this.rootScopeService.analyticsLastFormOpen = formMnemonic;
                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", formMnemonic, "", "", "");
                //Close popover as this is direct form request.
                this.closePopover();
                return;
            }
            // cast to any due to using an outdated version of lodash, typings mismatch
            else if (_.includes(this.searchService.criteria.formHistory, formSearchCriteria)) {
                let colonPos = formSearchCriteria.indexOf(':');
                if (colonPos >= 0) {
                    formSearchCriteria = formSearchCriteria.substring(0, colonPos);
                }
            } else if (formSearchCriteria.indexOf('-') >= 0) {
                //split the criteria and eliminate empty items.
                let arr = formSearchCriteria.split('-').filter((el: any) => {
                    return el.length != 0
                });

                //check length to verify if the supplied criteria is a valid <application-mnemonic> format
                if (arr != null && arr.length == 2) {
                    arr[0] = arr[0].trim().toUpperCase(); //application
                    arr[1] = arr[1].trim().toUpperCase(); //mnemonic

                    //Check if application is part of valid applications based on preferences
                    if (this.preferencesService.validApps().indexOf(arr[0]) >= 0) {
                        //Check if string can be a mnemonic
                        if (arr[1].match('[A-Z]')) {
                            this.rootScopeService.directFormApplication = arr[0].toUpperCase();
                            this.rootScopeService.analyticsLastFormOpen = arr[1];

                            let foundInHistory = false;
                            for (let i = 0; i < historyList.length; i++) {
                                if (historyList[i] != null) {
                                    // Split form search history record on '$$$'. Part 0 provides the key in format FormMnemonic: FullFormName. Further split it at ':' and get the first part.
                                    // Match this with the second part of search array item. If they match, the user is searching for a form that is already in the history. Therefore, send LaunchForm command.
                                    let splitHistoryFormName = historyList[i].split('$$$')[0].split(':')[0];
                                    if (splitHistoryFormName.toUpperCase() == arr[1].toUpperCase()) {
                                        foundInHistory = true;
                                        break;
                                    }
                                }
                            }
                            // If the form is already in search history, simply send a LaunchForm command instead of sending a search request again.
                            if (foundInHistory === true) {
                                directForm = true;
                                //send launch form to server
                                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", '!' + arr[0] + '-' + arr[1], "", "", "");
                                //Close popover as this is direct form request.
                                this.closePopover();
                            }
                            else {
                                handleSearchResultsCallback = true;
                                formSearchApplicationCriteria = arr[0];
                                formSearchCriteria = arr[1];
                                //send launch form to server
                                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", '!' + arr[0] + '-' + arr[1], "", "", "");
                                //Close popover as this is direct form request.
                                this.closePopover();
                            }
                        } else {
                            //Throw message for invalid mnemonic
                            this.searchService.searchInputValidation("Invalid Mnemonic - " + arr[1]);
                        }
                    } else {
                        //TOOL is no longer allowed - explicitly tell users
                        if (arr[0] == "TOOL") {
                            this.searchService.searchInputValidation("TOOL is no longer allowed.");
                        }
                    }
                }
            } else {
                let pattern = /^[\w. ]+$/;
                if (this.validationService.isNullOrEmpty(formSearchCriteria)) {
                    this.searchService.searchInputValidation("Search strings must be at least two characters long and cannot be wildcards");
                    return false;
                }
                else if (!formSearchCriteria.match(pattern)) {
                    this.searchService.searchInputValidation("Form search strings must not contain special characters.");
                    return false;
                }
            }
        } else {
            personSearchCriteria = this.searchService.formatSearchCriteria(this.personSearchCriteria);
            personSearchCriteria = this.searchService.scrubWildcards(personSearchCriteria);
            if (personSearchCriteria.length < 2) {
                criteriaBad = true;
            } else {
                // Add person search criteria to search history
                this.searchService.addToHistory(personSearchCriteria, false);
            }

        }
        if (!directForm) {
            // Don't allow invalid criteria searches
            if (criteriaBad) {
                this.searchService.searchInputValidation("Search strings must be at least two characters long and cannot be wildcards.");
                return false;
            }


            let startRow = 1;
            let cursorSize;
            // Get cursor size for formless PERSON based on saved CARD or GRID state.
            let renderType = this.uiLocalStorageService.get(Models.LocalStorageConstants.searchResultsRenderType);
            if ((renderType !== undefined) && (renderType === "GRID")) {
                cursorSize = this.rootScopeService.preferences.GridSearchResults;
            } else {
                cursorSize = this.rootScopeService.preferences.CardSearchResults;
            }
            let queryColumns = this.searchService.buildQueryColumns(isFormSearch ? "FORM" : "PERSON");
            let resultsCallback: any = null;

            if (handleSearchResultsCallback === true) {
                resultsCallback = (searchResults) => {
                    this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover, false);
                    // Launch single form search result
                    let results = this.searchService.parseResults(searchResults);
                    let formResult: any = {};

                    if (results && results.length > 1) {
                        formResult = results.filter((f) => {
                            return f['PROCESS.APPLICATION'].toUpperCase() === formSearchApplicationCriteria.toUpperCase();
                        })[0];

                        let formId = formResult['PROCESS.APPLICATION'] + "-" + formResult['@ID'];
                        this.rootScopeService.analyticsLastFormOpen = formResult['@ID'];
                        // Add form to search history
                        let historyID = formResult['@ID'] + ": " + formResult['PROCESS.DESCRIPTION'];
                        this.searchService.criteria.form = historyID;
                        // this.eventsService.broadcast("UpdateSearchInput", historyID);
                        this.rootScopeService.lastFormSearchHistoryId = historyID + " $$$ " + formId;
                        // Form search history should be added from form search results and not the form specs message. Reverting old code. -- Naman -- 02/11/2019
                        this.searchService.addToHistory(historyID, true, formId, true);
                    }
                    else if (results && results.length == 1) {
                        formResult = results[0];
                        let formId = formResult['PROCESS.APPLICATION'] + "-" + formResult['@ID'];
                        this.rootScopeService.analyticsLastFormOpen = formResult['@ID'];
                        // Add form to search history
                        let historyID = formResult['@ID'] + ": " + formResult['PROCESS.DESCRIPTION'];
                        this.searchService.criteria.form = historyID;
                        // this.eventsService.broadcast("UpdateSearchInput", historyID);
                        this.rootScopeService.lastFormSearchHistoryId = historyID + " $$$ " + formId;
                        // Form search history should be added from form search results and not the form specs message. Reverting old code. -- Naman -- 02/11/2019
                        this.searchService.addToHistory(historyID, true, formId, true);
                    }
                }
            }

            let clearWaitingCallback = (hideSearchResults: any, navbarSearchComponent: any) => {
                let self = <NavbarSearchComponent>navbarSearchComponent;

                if (self.searchService.state.isFormSearch) {
                    self.lastFormSearchResult = $.extend(true, {}, self.searchService.state.currentSRData);
                    self.lastFormSearchCriteria = self.formSearchCriteria;

                }
                else {
                    self.lastPersonSearchResult = null;
                    self.lastPersonSearchResult = $.extend(true, {}, self.searchService.state.currentSRData);
                    self.lastPersonSearchCriteria = self.personSearchCriteria;
                }

                self.processingService.closeProcessing();
            };

            let failureCallback = (success: any, error: any, navbarSearchComponent: any) => {
                let self = <NavbarSearchComponent>navbarSearchComponent;
                self.processingService.closeProcessing();
                let errorMessage: any = {};
                errorMessage.title = 'Error';
                let msg = error;
                errorMessage.text = [msg];
                errorMessage.defaultCallbackNumber = 0;

                // Invalid context means CUI-3281. Session is frozen due to the lack of context loading.
                let logout = error.indexOf('Invalid context') >= 0;
                if (logout == true) {
                    errorMessage.title = "Logging out";
                    errorMessage.text = ['There was a problem in loading the default settings. Please log in again.'];
                }
                errorMessage.buttons = [{
                    label: "Ok",
                    callback: () => {
                        // When help message has totally faded away, bring back the detail dialog
                        self.promptService.popupMessageDismiss(() => {
                            self.processingService.closeProcessing();
                            self.searchService.focusOnSearchInput();
                            if (logout == true) { //logout
                                self.sessionService.logout();
                                self.eventsService.broadcast("logoutComplete");
                            }
                        }, false);
                    },
                    title: ""
                }];
                self.promptService.popupMessageShow(errorMessage);
            }

            this.closePopover();
            this.processingService.showProcessing("Header Search", "Please Wait ...", "Searching for " + (isFormSearch ? formSearchCriteria : personSearchCriteria), isFormSearch ? false : true);
            this.searchService.search(isFormSearch ? "FORM" : "PERSON", isFormSearch ? "FORM" : "PERSON", startRow, cursorSize, queryColumns, isFormSearch ? formSearchCriteria : personSearchCriteria, "", "", clearWaitingCallback, failureCallback, resultsCallback, this, this);
        }
    }

    searchInputKey(event: any, inputControl: boolean) {
        if (event.keyCode == Models.KeyCodes.up && inputControl) {
            this.selectHistoryItem(false);
        }
        else if (event.keyCode == Models.KeyCodes.down && inputControl) {
            this.selectHistoryItem(true);
        }
        else if (event.keyCode == Models.KeyCodes.tab && inputControl && event.shiftKey) {
            this.closePopover();
        }
        else if (this.rootScopeService.isTablet && event.keyCode == Models.KeyCodes.enter) {
            this.performSearch();
        }
    }

    searchInputChanged(newValue: string) {
        if (this.searchService.state.isFormSearch) {
            let valueNotUpdated = this.formSearchCriteria == newValue;
            this.formSearchCriteria = newValue;
            if (this.searchService.state.searchformAutocomplete == false && !valueNotUpdated) {
                this.toggleFormAutoComplete();
            }
            this.eventsService.broadcast(Models.CommandConstants.updateFormMatchItems, this.formSearchCriteria);
        }
        else if (this.searchService.state.isFormSearch == false) {
            let valueNotUpdated = this.personSearchCriteria == newValue;
            this.personSearchCriteria = newValue;
            if (this.searchService.state.searchpersonAutocomplete == false && !valueNotUpdated) {
                this.togglePersonAutoComplete();
            }
            this.eventsService.broadcast(Models.CommandConstants.updatePersonMatchItems, this.personSearchCriteria);
        }
    }

    inputFocusChanged(focus: boolean) {
        this.inputFocused = focus;
        if (focus) {
            this.searchService.focusOnSearchInput();
        }
    }

    //autocomplete
    togglePersonAutoComplete(event: any = null) {
        let currentState: boolean = this.searchService.state.searchpersonAutocomplete;
        this.closePopover();
        this.searchService.state.searchpersonAutocomplete = !currentState;

        if (currentState) {
            this.searchService.focusOnSearchInput();
        }
        else if (event != null) { // event is not null when auto complete is toggle via button. show entire history.
            this.eventsService.broadcast(Models.CommandConstants.updatePersonMatchItems, "");
        }
    }

    //autocomplete
    toggleFormAutoComplete(event: any = null) {
        let currentState: boolean = this.searchService.state.searchformAutocomplete;
        this.closePopover();
        this.searchService.state.searchformAutocomplete = !currentState;
        if (currentState) {
            this.searchService.focusOnSearchInput();
        }
        else if (event != null) { // event is not null when auto complete is toggle via button. show entire history.
            this.eventsService.broadcast(Models.CommandConstants.updateFormMatchItems, "");
        }
    }

    //search result
    togglePersonSearchResultPopover() {
        let currentState: boolean = this.searchService.state.PersonSearchResults && this.personLastSearch;
        this.closePopover();
        this.personLastSearch = !currentState;
        this.searchService.state.PersonSearchResults = !currentState;
        if (currentState) this.searchService.focusOnSearchInput();
        else {
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.search);
            this.searchService.state.currentSRData = $.extend(true, {}, this.lastPersonSearchResult);
            this.rootScopeService.searchCriteria = this.lastPersonSearchCriteria;
        }
    }

    // Advanced Search
    togglePersonAdvancedSearchPopover() {
        let currentState: boolean = this.searchService.state.PersonAdvancedSearch;
        this.closePopover();
        this.searchService.state.PersonAdvancedSearch = !currentState;
        if (currentState) this.searchService.focusOnSearchInput();
    }

    //search result
    toggleFormSearchResultPopover() {
        let currentState: boolean = this.searchService.state.FormSearchResults && this.formLastSearch;
        this.closePopover();
        this.formLastSearch = !currentState;
        this.searchService.state.FormSearchResults = !currentState;
        if (currentState) this.searchService.focusOnSearchInput();
        else {
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.search);
            this.searchService.state.currentSRData = $.extend(true, {}, this.lastFormSearchResult);
            this.rootScopeService.searchCriteria = this.lastFormSearchCriteria;
        }
    }

    //navigation
    toggleNavigationPopover() {
        let currentState: boolean = this.searchService.state.ShowNavigationMenu;
        this.navigateService.openNavigate();
        this.closePopover();
        this.searchService.state.ShowNavigationMenu = !currentState;
    }

    closePopover() {
        this.searchService.state.searchformAutocomplete = false;
        this.searchService.state.searchpersonAutocomplete = false;

        this.searchService.state.PersonAdvancedSearch = false;
        this.searchService.state.PersonSearchResults = false;

        this.searchService.state.FormSearchResults = false;
        this.searchService.state.ShowNavigationMenu = false;
    }

    private selectHistoryItem(down: boolean) {
        var matchList = this.rootScopeService.criteria.autoCompleteMatch;

        if (matchList.length == 0 && this.getSearchInput().trim().length == 0) {
            if (this.searchService.state.isFormSearch) {
                this.rootScopeService.criteria.autoCompleteMatch = this.searchService.criteria.formHistory;
            } else {
                this.rootScopeService.criteria.autoCompleteMatch = this.searchService.criteria.personHistory;
            }
            this.selectHistoryItem(down);
            return;
        }

        let savedSearch = this.getSearchInput();
        if (matchList.length > 0) {
            if (this.searchService.state.selectedHistory === "") {
                this.searchService.state.selectedHistory = matchList[down ? 0 : (matchList.length - 1)];


                if (this.searchService.state.selectedHistory.indexOf(' $$$') > 0) {
                    this.setSearchInput(this.searchService.state.selectedHistory.substring(0, this.searchService.state.selectedHistory.indexOf(' $$$')));
                } else {
                    this.setSearchInput(this.searchService.state.selectedHistory);
                }
            } else {
                var currentSelection = this.searchService.state.selectedHistory;
                var found = false;
                for (var i = 0 + (down ? 0 : 1); i < (matchList.length - (down ? 1 : 0)); i++) {
                    if (matchList[i] === currentSelection) {
                        this.searchService.state.selectedHistory = matchList[i + (down ? 1 : -1)];
                        if (this.searchService.state.selectedHistory.indexOf(' $$$') > 0) {
                            this.setSearchInput(this.searchService.state.selectedHistory.substring(0, this.searchService.state.selectedHistory.indexOf(' $$$')));
                        } else {
                            this.setSearchInput(this.searchService.state.selectedHistory);
                        }
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    this.searchService.state.selectedHistory = "";
                    this.setSearchInput("");
                }
            }
        }
    }

    private getSearchInput() {
        if (this.searchService.state.isFormSearch) {
            return this.formSearchCriteria;
        }
        else {
            return this.personSearchCriteria;
        }
    }

    private setSearchInput(input: string) {
        if (this.searchService.state.isFormSearch) {
            this.formSearchCriteria = input;
        }
        else {
            this.personSearchCriteria = input;
        }
    }
}
