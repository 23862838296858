/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable, Injector } from '@angular/core';

declare var X2JS: any;

@Injectable()
export class HelperService {

    tempDate: Date;
    constructor(private injector: Injector) { }



    start() {
        this.tempDate = new Date();
    }

    stop() {
        let dt = new Date();
        let dif = dt.getTime() - this.tempDate.getTime();

        console.warn(dif + " ms");
    }




    /**
     * Gets a new guid.
     */
    NewGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    formatMnemonic(mnemonic: string): string {
        if (mnemonic != null && mnemonic.length > 0) {
            if (mnemonic.indexOf(':') >= 0) {
                let newMnemonic: string = "";
                let splitText: any = mnemonic.split(':');
                if (splitText[0].trim().toUpperCase() == "CORE") {
                    newMnemonic = splitText[0];
                } else {
                    for (let i = 0; i < splitText[0].length; i++) {
                        newMnemonic += (splitText[0][i] + " ");
                    }
                }

                if (mnemonic.length == 2) {
                    newMnemonic = newMnemonic + ":" + mnemonic[1];
                }
                return newMnemonic;
            } else {
                let newMnemonic = "";
                if (mnemonic.trim().toUpperCase() == "CORE") {
                    return mnemonic;
                }
                for (let i = 0; i < mnemonic.length; i++) {
                    if (mnemonic[i] == 'a' || mnemonic[i] == 'A') newMnemonic += "AY ";
                    else newMnemonic += (mnemonic[i] + " ");
                }
                return newMnemonic;
            }
        }
        return "";
    }

    objectToXml(json: any): any {
        return new X2JS({ escapeMode: false }).json2xml_str(json);
    }

    xmlToObject(xml: any) {
        return new X2JS({ escapeMode: false }).xml_str2json(xml);
    }

    xmlDomToObject(xml: any) {
        return new X2JS({ escapeMode: false }).xml2json(xml);
    }

    objectToJson(obj: any) {
        return obj ? JSON.stringify(obj) : obj;
    }

    jsonToObject(json: any) {
        return json ? JSON.parse(json) : json;
    }

    registeredType(name: string) {
        let classType: any;

        try {
            classType = this.injector.get(name);
        } catch (e) {
            // Type not defined; ignore error
        }

        return classType;
    }
}


