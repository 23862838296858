/**
* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
*/

import { Component, Input, OnInit } from "@angular/core";
import {
    ConfigurationService,
    PreferencesService,
    RootScopeService,
    SearchResultsService,
    ValidationService,
    PromptService,
    EventsService,
    UiLocalStorageService,
} from "../../../services";
import * as Models from "../../../models";

import * as _ from 'lodash';
import { DataType } from "../../../models";

declare var showErrorMessage: any;
declare var adminSelectedEmailClient: any;
declare var overwriteEmailClientPreference: any;
// Constant to point to the row number in the third position in a card ID
const rowNumberinCardId: number = 2;
@Component({
    selector: 'card',
    template: require('./card.component.html')
})
export class CardComponent implements OnInit {

    @Input() data: SearchResultsWrapper;
    personImagesEnabled: boolean;

    constructor(private searchResultsService: SearchResultsService,
        private rootScopeService: RootScopeService,
        private preferencesService: PreferencesService,
        private configurationService: ConfigurationService,
        private validationService: ValidationService,
        private promptService: PromptService,
        private eventsService: EventsService,
        private uiLocalStorageService: UiLocalStorageService) {

    }

    ngOnInit() {
        this.searchResultsService.setPrivacyFlag(this.data);
        this.personImagesEnabled = this.configurationService.getConfiguration().ClientParameters.PersonImagesEnabled;
    }

    /**
     * Handles keyboard navigation within the card
     * @param event
     */
    cardKeyDown(event: any): void {
        // don't do anything if the target is not card row
        if (!($(event.target).hasClass('searchResultRow'))) {
            return;
        }

        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let spacebar: boolean = event.keyCode == Models.KeyCodes.space;
        let upArrow: boolean = event.keyCode == Models.KeyCodes.up;
        let downArrow: boolean = event.keyCode == Models.KeyCodes.down;
        let shift: boolean = event.shiftKey;
        let pageDown: boolean = event.keyCode == Models.KeyCodes.pageDown;
        let pageUp: boolean = event.keyCode == Models.KeyCodes.pageUp;
        let end: boolean = event.keyCode == Models.KeyCodes.end;
        let home: boolean = event.keyCode == Models.KeyCodes.home;
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;

        //return if key is not a valid key for navigation
        if ((!spacebar && !tab && !upArrow && !downArrow && !pageDown && !pageUp && !home && !end) || enter) {
            return;
        }
        else if (enter) {
            return;
        }
        else {
            event.preventDefault();
            event.stopPropagation();
        }

        let continueLoop: boolean = true;
        if (!shift && tab) { // tab out the card view to first enabled element in footer
            let currentTabIndex: number = event.target.tabIndex;
            do {
                let element: any = $("[tabindex=" + (currentTabIndex + 1) + "]")[0];
                if (element == null) {
                    continueLoop = false;
                }
                else if (element != null && element.disabled == true) {
                    currentTabIndex = currentTabIndex + 1;
                }
                else {
                    element.focus();
                    continueLoop = false;
                }
                element = null;
            }
            while (continueLoop);
            return;
        }
        else if (shift && tab) { // shift tab out the card view to first enabled element in header
            let currentTabIndex: number = event.target.tabIndex;
            do {
                let prevElements: any = $("[tabindex=" + (currentTabIndex - 1) + "]");
                let element: any = prevElements[prevElements.length - 1];
                if (element == null) {
                    continueLoop = false;
                }
                else if (element != null && element.disabled == true) {
                    currentTabIndex = currentTabIndex - 1;
                }
                else {
                    element.focus();
                    continueLoop = false;
                }
                prevElements = null;
            }
            while (continueLoop);
        }
        else if (upArrow) {
            if ($(event.target).hasClass('searchResultRow')) {
                let row: any = $(event.target)[0];
                let rowIndex: number = row.rowIndex;
                if (rowIndex == 0) { // navigate to previous page when on first row
                    let idParts = $(event.target).attr("id").split("_");
                    // parse the row number from the card ID
                    let currentRowNumber: number = parseInt(idParts[rowNumberinCardId]);
                    this.searchResultsService.previousPage(this.data, currentRowNumber - 1, -1);
                    idParts = null;
                }
                else if (rowIndex > 0) {
                    $(row).prev().trigger("focus");
                }
                row = null;
            }
        }
        else if (downArrow) {
            if ($(event.target).hasClass('searchResultRow')) {
                let row: any = $(event.target)[0];
                let rowIndex: number = row.rowIndex;
                // If this is the last record in the page, move to next page
                if (rowIndex == (this.data.results.cursorSize - 1)) {
                    let idParts = $(event.target).attr("id").split("_");
                    // parse the row number from the card ID
                    let currentRowNumber: number = parseInt(idParts[rowNumberinCardId]);
                    this.searchResultsService.nextPage(this.data, currentRowNumber + 1, -1);
                    idParts = null;
                }
                else {
                    $(row).next().trigger("focus");
                }
                row = null;
            }
        }
        else if (pageDown) {
            if ($(event.target).hasClass('searchResultRow')) {
                let idParts = $(event.target).attr("id").split("_");
                // parse the row number from the card ID
                let currentRowNumber: number = parseInt(idParts[rowNumberinCardId]);
                let newRowNumber: number = (currentRowNumber + this.data.control.cursorSize) >= this.data.results.totalRows
                    ? this.data.results.totalRows - 1
                    : currentRowNumber + this.data.control.cursorSize;
                this.searchResultsService.nextPage(this.data, newRowNumber, -1);
                idParts = null;
            }
        }
        else if (pageUp) {
            if ($(event.target).hasClass('searchResultRow')) {
                let idParts = $(event.target).attr("id").split("_");
                // parse the row number from the card ID
                let currentRowNumber: number = parseInt(idParts[rowNumberinCardId]);
                let newRowNumber: number = (currentRowNumber - this.data.control.cursorSize) < 1
                    ? 0
                    : currentRowNumber - this.data.control.cursorSize;
                this.searchResultsService.previousPage(this.data, newRowNumber, -1);
                idParts = null;
            }
        }
        else if (end) {
            this.searchResultsService.lastPage(this.data);
        }
        else if (home) {
            this.searchResultsService.firstPage(this.data);
        }
        else if (spacebar) {
            this.toggleCardSelection(event);
        }
    }

    /**
     * Toggle the selection of a card
     * @param event
     */
    toggleCardSelection(event: any): void {
        const elementId = $(event.target).attr("id");
        if (elementId == null) {
            return;
        }

        // Get the current rowNumber. rowNumber starts from 0 and not 1.
        let idParts = elementId.split("_");
        // parse the row number from the card ID
        let currentRowNumber: number = parseInt(idParts[rowNumberinCardId]);
        // Toggle recordSelected property of the current record
        this.data.results.data[currentRowNumber].recordSelected = !this.data.results.data[currentRowNumber].recordSelected;
        // If recordSelected is false, it means the current record has been de-selected.
        if (this.data.results.data[currentRowNumber].recordSelected == false) {
            let index: number = this.rootScopeService.selectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
            if (index !== -1) {
                this.rootScopeService.selectedRowNumbers.splice(index, 1);
                // If Select All checkbox was checked, uncheck it.
                if (this.searchResultsService.selectAll == true) {
                    this.searchResultsService.selectAll = false;
                }
            }

            // Add the record to unselectedRowNumbers
            index = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
            if (index === -1) {
                // Push it to the unselected records so that if select all was previously checked, this record doesn't get selected again
                this.rootScopeService.unselectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
            }

            this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                : this.rootScopeService.selectedRowNumbers.length;

            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row Number " + (currentRowNumber + 1) + " is not selected";
                let elementToFocusId: string = $(event.target).attr("id");
                this.searchResultsService.screenReaderPromptMessage(labelText, "", elementToFocusId);
                elementToFocusId = null;
            }
        }
        // If recordSelected is true, it means the current record has been selected
        else {
            // If multi-selection is allowed, push the current record's ID to selectedRowNumbers
            if (this.data.control.multiSelection == true) {
                this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
                // Remove this record from unselected row numbers if it is there
                let index: number = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
                if (index !== -1) {
                    this.rootScopeService.unselectedRowNumbers.splice(index, 1);
                }

                if (this.rootScopeService.selectedRowNumbers.length == this.data.results.totalRows) {
                    this.searchResultsService.selectAll = true;
                    this.rootScopeService.searchResultRecordsSelected = this.data.results.totalRows;
                }
                else {
                    this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                        ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                        : this.rootScopeService.selectedRowNumbers.length;
                }
            }
            // If multi-selection is not allowed, only set recordSelected property for the current record and push the current record in selectedRowNumbers while removing others.
            else if (this.data.control.multiSelection == false) {
                // Reset recordSelected for all other cards
                _.each(this.data.results.data, (rec: any) => {
                    rec.recordSelected = false;
                });
                // Set recordSelected to true for the current selected card
                this.data.results.data[currentRowNumber].recordSelected = true;
                // Reset selectedRowNumbers and add the current record to selectedRowNumbers
                this.rootScopeService.selectedRowNumbers.length = 0;
                this.rootScopeService.searchResultRecordsSelected = 0;
                this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
                // Remove this record from unselected row numbers if it is there
                let index: number = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
                if (index !== -1) {
                    this.rootScopeService.unselectedRowNumbers.splice(index, 1);
                }
                this.rootScopeService.searchResultRecordsSelected = this.rootScopeService.selectedRowNumbers.length;
            }

            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row Number " + (currentRowNumber + 1) + " is selected";
                let elementToFocusId: string = $(event.target).attr("id");
                this.searchResultsService.screenReaderPromptMessage(labelText, "", elementToFocusId);
                elementToFocusId = null;
            }
        }
        idParts = null;
    }

    cardDoubleClick(event: any) {
        let idParts = $(event.target).attr("id").split("_");
        // parse the row number from the card ID
        let currentRowNumber: number = parseInt(idParts[rowNumberinCardId]);

        for (let i = 0; i < this.data.results.data.length; i++) {
            this.data.results.data[i].recordSelected = false;
        }

        this.rootScopeService.selectedRowNumbers = [];
        this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
        this.data.results.data[currentRowNumber].recordSelected = true;

        this.searchResultsService.openSelected(this.data);
        idParts = null;
    }

    isLinkField(fieldName: string) {
        const header = _.find(this.data.layout.headerFields, p => p.fieldName == fieldName);
        return header != null && (header.dataType == DataType.Link);
    }

    /**
     * Opens the Admin/User preferred Email Client
     * @param email - Email address of PERSON record from context header
     */
    openEmail(email: any) {
        showErrorMessage = false;

        /* Always Ask: 0, Desktop: 1, Web Based: 2
        Start with the default email client option of Desktop.
        If Admin has not allowed users to select their own preferred client, proceed with admin selected email client option
        If Admin has allowed users to select their own preferred client, proceed with user preferred email client option.
         */
        let emailClientOption: number = 1;

        // Check if overwriteEmailClientPreference variable is defined
        if (!this.validationService.isNullOrEmpty(overwriteEmailClientPreference)) {
            // If Admins have not allowed users to opt for their preferred email client, use Admin's preferred email client option
            if (overwriteEmailClientPreference.toLocaleLowerCase() === "false") {
                // Check if adminSelectedEmailClient is a valid number
                if (!this.validationService.isNullOrEmpty(adminSelectedEmailClient) && !isNaN(adminSelectedEmailClient)) {
                    emailClientOption = parseInt(adminSelectedEmailClient); // Parse the preferred email client value, set by Admin, to a number (either 1 or 2)
                }
            }
            // If Admins have allowed users to choose their preferred email client, use the preferredEmailClient saved in local storage
            else if (overwriteEmailClientPreference.toLowerCase() === "true") {
                let localStorageEmailClient = this.uiLocalStorageService.get(Models.LocalStorageConstants.userPreferredEmailClient);
                // Check if localStorage value of email client is defined and is a valid number
                if (!this.validationService.isNullOrEmpty(localStorageEmailClient) && !isNaN(localStorageEmailClient)) {
                    emailClientOption = parseInt(localStorageEmailClient); // Parse the local storage email client value to a number (either 0 or 1 or 2)
                }
            }
        }

        switch (emailClientOption) {
            case 0: // Always ask (Display dialog message prompting the user to select their preference)
                let popupMessage: any = {};
                let message: any = Models.DialogContants.PreferredEmailClient;
                popupMessage.title = "Email Client";
                popupMessage.text = [message];
                popupMessage.buttons = [
                    {
                        label: Models.PromptMessageConstants.DesktopEmailClient,
                        callback: () => {
                            this.promptService.popupMessageDismiss(() => {
                                window.location.href = "mailto:" + email;
                            }, false);
                        },
                        SearchFocus: false
                    },
                    {
                        label: Models.PromptMessageConstants.WebBasedEmailClient,
                        callback: () => {
                            this.promptService.popupMessageDismiss(() => {
                                window.open("mailto:" + email);
                            }, false);
                        },
                        SearchFocus: false
                    },
                    {
                        label: "Cancel",
                        callback: () => {
                            this.promptService.popupMessageDismiss(() => {
                                this.eventsService.broadcast(Models.EventConstants.closeAlert, true);
                            }, false);
                        },
                        SearchFocus: false
                    }
                ];
                popupMessage.defaultCallbackNumber = "0";
                this.promptService.popupMessageShow(popupMessage);
                break;
            case 1: // Desktop email client (open link in same window)
                window.location.href = "mailto:" + email;
                break;
            case 2: // Web based client (open link in a new tab/window)
                window.open("mailto:" + email);
                break;
            default:
                // Default option is always Desktop email client (open link in same window)
                window.location.href = "mailto:" + email;
                break;
        }
    }
}

// todo: these might be more generic than for search results
interface SearchResultsWrapper {
    results: SearchResult;
    views: any[];
    layout: SearchResultLayout;
    control: SearchResultControl;
}

interface SearchResult {
    totalRows: number;
    cursorHandle: string;
    context: string;
    currentRow: number;
    cursorSize: number;
    criteria: string;
    data: any[];
}

interface SearchResultControl {
    error: string;
    sortedFieldName: string;
    sortedAscending: boolean;
    cursorSize: number;
    addMode: boolean;
    autoLoad: boolean;
    multiSelection: boolean;
    sortSelectAllowed: boolean;
}

interface SearchResultLayout {
    addNewRecord: boolean;
    context: string;
    demandFields: string[];
    favoriteFields: string[];
    group: string;
    imageEnabled: boolean;
    multipleSelection: boolean;
    totalColumns: number;
    totalRows: number;
    renderType: string;
    initialRenderType: string;
    headerFields: SearchResultHeader[];

}

interface SearchResultHeader {
    fieldName: string;
    column: string;
    columnSpan: string;
    dataType: DataType;
    label: string;
    cellLabel: string;
    row: string;
    rowSpan: string;
    columnWidthClass: string;
}
