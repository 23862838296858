/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import { EventsService, FocusService, PromptService, ValidationService, RootScopeService } from "../../services";
import * as Models from "../../models";

declare var $: any;

@Component({
    selector: 'prompt',
    template: require('./prompt.component.html')
})
export class PromptComponent {
    openPromptListenerId: string = "";
    closePromptListenerId: string = "";
    constructor(private eventsService: EventsService, private promptService: PromptService,
        private focusService: FocusService, private validationService: ValidationService, private rootScopeService: RootScopeService) {
        this.openPromptListenerId = this.eventsService.on(Models.EventConstants.openPrompt, [this.openPromptListener, this]);
        this.closePromptListenerId = this.eventsService.on(Models.EventConstants.closePrompt, [this.closePromptListener, this]);
      
    }

    ngOnDestroy() {
        this.eventsService.destroy(Models.EventConstants.openPrompt, this.openPromptListenerId);
        this.eventsService.destroy(Models.EventConstants.closePrompt, this.closePromptListenerId);
    }

    openPromptListener(promptComponent: any) {
        let self = <PromptComponent>promptComponent;

        setTimeout(() => {
            self.eventsService.broadcast(Models.EventConstants.closeAlert, true);
        }, 100);

        // display modal
        $('#prompt.modal').modal({
            backdrop: "static",
            keyboard: false
        }).draggable({ handle: '.modal-header' });

        // if input is present focus on input
        // else focus on first button
        if (self.promptService.inputType === undefined) {
            setTimeout(() => {
                self.focusService.focusOn('#popup_lookup_button_0');
            }, 250);
        } else {
            setTimeout(() => {
                self.focusService.focusOn('#popup-lookup');
            }, 250);
        }


    }

    closePromptListener(promptComponent: any, showProcessing: boolean = false) {
        let self = <PromptComponent>promptComponent;
        $('#prompt.modal').modal('hide');

        if (showProcessing == true) {
            var alertArgs = {
                id: "wait",
                message: "Processing ...",
                alertTitle: "Please Wait ...",
                event: "Close Prompt Message"
            };
            this.eventsService.broadcast(Models.EventConstants.openAlert, alertArgs);
        }
    }

    keyup(event: any, ind: number) {
        let key = String.fromCharCode(event.which).toUpperCase();

        if (!this.validationService.isNullOrEmpty(key)) {
            this.promptService.triggerShortcutAction(key);
        }

        if (event.keyCode == Models.KeyCodes.left) {
            this.focusService.focusOn('#popup_lookup_button_' + (ind - 1));
        }
        else if (event.keyCode == Models.KeyCodes.right) {
            this.focusService.focusOn('#popup_lookup_button_' + (ind + 1));
        }
        else if (event.keyCode == Models.KeyCodes.enter || event.keyCode == Models.KeyCodes.space) {
            this.promptService.popupButtonEvent(ind);
        }
    }

    /**
     * Tab on the last button in dialog should focus on input(if available) or the first button
     * @param event
     * @param ind
     */
    promptFooterButtonKeydown(event: any, ind: number) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            // If this is the last button in dialog and there is an input, focus on input
            if (ind == this.promptService.buttons.length - 1 && !this.validationService.isNullOrEmpty(this.promptService.inputType)) {
                event.preventDefault();
                event.stopPropagation();

                this.focusService.focusOn("popup-lookup", true);
            }
            // If there is no input in the dialog, focus on the first button
            else if (ind == this.promptService.buttons.length - 1) {
                event.preventDefault();
                event.stopPropagation();

                this.focusService.focusOn("popup_lookup_button_0", true);
            }
        }
        else if (shift && tab) {
            if (ind == this.promptService.buttons.length - 1) {
                event.preventDefault();
                event.stopPropagation();

                this.focusService.focusOn("popup_lookup_button_0", true);
            }
        }
    }

    /**
     * Shift tab on input should focus on the last button in the dialog footer
     * @param event
     */
    popupLookupKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            let lastButtonIndex = this.promptService.buttons.length - 1;
            this.focusService.focusOn("popup_lookup_button_" + lastButtonIndex, true);
        }
        else if (this.rootScopeService.isTablet && enter ) {
            this.promptService.popupButtonEvent(parseInt(this.promptService.defaultButtonIndex), event);
        }
    }

    popupLookupKeyup(event: any = null) {
        if (!this.rootScopeService.isTablet && event != null && event.keyCode == Models.KeyCodes.enter) {
            this.promptService.popupButtonEvent(parseInt(this.promptService.defaultButtonIndex), event);
        }
    }


    buttonClick(event: any, ind: any) {
        if (event.clientX > 0 && event.clientY > 0) {
            this.promptService.popupButtonEvent(ind);
        }
    }
}