/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Inject, Input } from "@angular/core";
import * as Models from "../../models";
import {
    HelperService,
    NavigateService,
    PromptService,
    RootScopeService,
    SearchService,
    PreferencesService, EventsService, FocusService, ServerCommandService
} from "../../services";

declare let $: any;
import * as _ from 'lodash';

@Component({
    selector: 'navigatemenu',
    template: require('./navigate-menu.component.html'),
})
export class NavigateMenuComponent {
    @Input() menu: any;
    @Input() application: any;
    @Input() folder: any;
    @Input() applications: any;
    @Input() currentApplication: any;
    items: any = [];
    selectedNode: any = undefined;

    constructor(private preferencesService: PreferencesService,
        private searchService: SearchService,
        private eventsService: EventsService,
        private navigateService: NavigateService,
        private rootScopeService: RootScopeService,
        private promptService: PromptService,
        private helperService: HelperService,
        private serverCommandService: ServerCommandService,
        private focusService: FocusService) {

    }

    /**
     * Toggle the state of a folder
     * @param folder
     * @param application
     * @param menu
     */
    toggleExpand(folder: any, application: any, menu: any) {
        let parent = this.parentMenu(folder, menu);
        if (folder.IsOpen && folder.Folders.length > 0) { //collapse all sub folders
            this.navigateService.collapseChildFolders(folder);
        } else if (folder.IsOpen) {
            folder.IsOpen = false
        } else if (!folder.IsOpen) {
            folder.IsOpen = true;

            if (application && folder && parent) {
                // Get data for the expanded folder if we do not have it.
                this.getData(application, folder, parent);
            }

            //Collapse non selected folders
            this.collapseOtherMenus(menu, folder, parent);
        }
    }

    /**
     * Toggle the state of an item
     * @param item
     * @param event
     */
    toggleSelected(item: any, event: any) {
        let ele: any = event.target;

        // If the target item doesn't have a selected property, add it and set it to false by default
        if (!_.has(item, Models.HelperText.selected)) {
            item.selected = false;
        }

        // If the item was selected, unselect it and reset the selectedNode property
        if (item.selected) {
            item.selected = false;
            this.selectedNode = undefined;
        } else { // If item was not selected, add it to selectedNode and set the selected property to true
            if (this.selectedNode !== undefined) {
                this.selectedNode.selected = false;
            }
            item.selected = true;
            this.selectedNode = item;
        }
    }

    /**
     * Keydown event handler for folder/item
     * @param event
     * @param folder
     * @param application
     * @param menu
     * @param isFolder
     */
    checkFolderKey(event: any, folder: any, application: string, menu: any, isFolder: boolean) {
        let left: boolean = event.keyCode == Models.KeyCodes.left;
        let right: boolean = event.keyCode == Models.KeyCodes.right;
        let down: boolean = event.keyCode == Models.KeyCodes.down;
        let up: boolean = event.keyCode == Models.KeyCodes.up;
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        // Tab on last item in navigation menu should focus on the first Application button
        if (!shift && tab) {
            let navigationItems = $(".navgation-body").find(".navigation-menu-font.item, .text-black.item");
            if (navigationItems != null && navigationItems.length > 0) {
                let lastItem = navigationItems[navigationItems.length - 1];
                if (event.target.id == lastItem.id) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.focusService.focusOn("btn_application_0", true);
                }
            }
            return;
        }

        // Get the current folder's or item's parent
        let parent: string = this.parentMenu(folder, menu);

        if (left) { //Collapse the folder
            if (folder.IsOpen && folder.Folders.length > 0 && isFolder) { //collapse all sub folders
                this.navigateService.collapseChildFolders(folder);
            } else if (folder.IsOpen && isFolder) {
                folder.IsOpen = false;
            } else if (isFolder && !folder.IsOpen) {
                this.setFocusCurrentItem(parent);

            } else if (!isFolder) {
                this.setFocusCurrentItem(parent);
            } else {
                this.setFocusCurrentItem(folder.Mnemonic);
            }
        }
        else if (right) { //Expand the folders
            if (!folder.IsOpen) {
                folder.IsOpen = true;
            }
            if (application && folder && parent) {
                this.getData(application, folder, parent);
            }
            this.setFocusCurrentItem(folder.Mnemonic);
            this.collapseOtherMenus(menu, folder, parent);
        }
        else if (down) {
            this.keyDownArrow(folder, menu, isFolder, parent, menu);
        }
        else if (up) {
            this.keyUpArrow(folder, menu, isFolder, parent, menu);
        }
        else if (enter && !isFolder) {
            this.open(application, folder);
        }
    }

    /**
     * Open a form
     * @param application
     * @param item
     */
    open(application: string, item: any) {
        let fullFormMnemonic: string = "";
        let fullMnemonic: string = "";

        if (typeof item != 'undefined' && item.Mnemonic && item.MnemonicTitle) {
            fullFormMnemonic = item.Mnemonic + ': ' + item.MnemonicTitle;
        }

        this.rootScopeService.criteria.form = fullFormMnemonic;

        if (this.selectedNode != undefined) {
            // Open previously highlighted item
            fullMnemonic = this.selectedNode.Mnemonic;
            this.selectedNode.selected = false;
        }

        // Add form to form search history
        if (item != undefined) {
            fullMnemonic = item.Mnemonic;
            item.selected = false;
            let historyList: any = this.searchService.criteria.formHistory;
            for (let i = 0; i < historyList.length; i++) {
                if (historyList[i] != null) {
                    // Split form search history record on '$$$'. Part 0 provides the key in format FormMnemonic: FullFormName. Further split it at ':' and get the first part.
                    // Match this with the second part of search array item. If they match, the user is searching for a form that is already in the history. Therefore, send LaunchForm command.
                    let splitHistoryFormName = historyList[i].split('$$$')[0].split(':')[0];
                    if (splitHistoryFormName.toUpperCase() == item.Mnemonic.toUpperCase()) {
                        // Existing form search history record found, remove it and later, add the newly selected item
                        historyList.splice(i, 1);
                        break;
                    }
                }
            }
            this.searchService.addToHistory(item.Mnemonic + ': ' + item.MnemonicTitle, true, application + '-' + fullMnemonic);
        }

        // Close navigate and open form
        if (fullMnemonic != undefined) {
            // Generate full mnemonic with the application prefixed to the form mnemonic
            if (fullMnemonic.indexOf('-') < 0) {
                fullMnemonic = application + '-' + fullMnemonic;
            }

            //Close modal
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
            this.selectedNode = undefined;
            // this.eventsService.broadcast(Models.EventConstants.openForm, fullMnemonic);
            this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", fullMnemonic, "", "", "");
        } else { // Handle errors related to form
            this.promptService.popupMessage(Models.HelperText.formError, null, null);
        }
    }

    /**
     * Get the data for a folder if it is not already fetched from a server, usually in cases where folder is expanded.
     * @param application
     * @param child
     * @param parent
     */
    private getData(application: any, child: any, parent: any) {
        // A Folders/currentItems array will exist only if the object contains un-rendered data on the back end
        if (_.isArray(child.Folders) && child.Folders.length == 0) {
            let getMenuCallBack = (menuResponse: any, navigateMenuComponent: any) => {
                let self: NavigateMenuComponent = <NavigateMenuComponent>navigateMenuComponent;
                let resp: any = self.navigateService.addParent(menuResponse);
                if (resp.Menu.Folders.length > 0 || resp.Menu.CurrentItems.length > 0) {
                    Object.assign(child.Folders, resp.Menu.Folders);

                    for (let i = 0; i < resp.Menu.CurrentItems.length; i++) {
                        resp.Menu.CurrentItems[i].position = i;
                        if (resp.Menu.CurrentItems[i].ProcessClass == Models.NavigateConstants.M) {
                            resp.Menu.CurrentItems[i].order = 0;
                        } else if (resp.Menu.CurrentItems[i].ProcessClass == Models.NavigateConstants.I) {
                            resp.Menu.CurrentItems[i].order = 1;
                        } else if (resp.Menu.CurrentItems[i].ProcessClass == Models.NavigateConstants.R) {
                            resp.Menu.CurrentItems[i].order = 2;
                        } else if (resp.Menu.CurrentItems[i].ProcessClass == Models.NavigateConstants.P) {
                            resp.Menu.CurrentItems[i].order = 3;
                        }

                    }

                    resp.Menu.CurrentItems.sort((a: any, b: any) => {
                        return (a.order == b.order) ? (a.position - b.position) : (a.order - b.order);
                    });


                    Object.assign(child.CurrentItems, resp.Menu.CurrentItems);
                } else {
                    let currentItems: any = [];
                    currentItems.push({
                        Mnemonic: Models.NavigateConstants.Mnemonic,
                        MnemonicTitle: Models.NavigateConstants.MnemonicTitle,
                        ProcessClass: Models.NavigateConstants.ProcessClass,
                        ProcessType: Models.NavigateConstants.ProcessType
                    });

                    for (let i = 0; i < currentItems.length; i++) {
                        currentItems[i].position = i;
                        if (currentItems[i].ProcessClass == Models.NavigateConstants.M) {
                            currentItems[i].order = 0;
                        } else if (currentItems[i].ProcessClass == Models.NavigateConstants.I) {
                            currentItems[i].order = 1;
                        } else if (currentItems[i].ProcessClass == Models.NavigateConstants.R) {
                            currentItems[i].order = 2;
                        } else if (currentItems[i].ProcessClass == Models.NavigateConstants.P) {
                            currentItems[i].order = 3;
                        }

                    }

                    currentItems.sort((a: any, b: any) => {
                        return (a.order == b.order) ? (a.position - b.position) : (a.order - b.order);
                    });
                    Object.assign(child.CurrentItems, currentItems);
                }
            };

            this.navigateService.getMenu(application, child.Mnemonic, parent, getMenuCallBack, this);
        }
    }

    /**
     * Collapse other folders except for the folder passed in the argument
     * @param parentFolder
     * @param folder
     * @param parent
     */
    private collapseOtherMenus(parentFolder: any, folder: any, parent: any) {
        let found: boolean = false;

        for (let i = 0; i < parentFolder.Folders.length; i++) {
            if (found) {
                break;
            }

            let tmp = parentFolder.Folders[i];

            if (tmp.IsOpen && folder.Mnemonic != tmp.Mnemonic) {
                this.collapseOtherMenus(tmp, folder, parent);
            }
            else if (folder.Mnemonic == tmp.Mnemonic) {
                // Collapse all other folders in the same level
                for (let j = 0; j < parentFolder.Folders.length; j++) {
                    let child: any = parentFolder.Folders[j];
                    if (child.IsOpen && child.Mnemonic != folder.Mnemonic && parent == parentFolder.Mnemonic) {
                        if (child.Folders.length > 0) {
                            this.navigateService.collapseChildFolders(child);
                        } else {
                            child.IsOpen = false;
                        }
                    }
                }
                found = true;
            }
        }
    }

    private parentMenu(folder: any, parentFolder: any) {
        let parent: any = folder.Parent !== undefined && folder.Parent != "" ? folder.Parent : "";

        if (parent == "") {
            for (let i = 0; i < parentFolder.Folders.length; i++) {
                if (parent != "") break;
                let tmp: any = parentFolder.Folders[i];
                if (folder.Mnemonic != tmp.Mnemonic && tmp.IsOpen) {
                    parent = this.parentMenu(folder, tmp);
                } else if (folder.Mnemonic == tmp.Mnemonic) {
                    parent = parentFolder.Mnemonic;
                    break;
                }

            }

            if (parent == "" && parentFolder.CurrentItems.length > 0) {
                for (let i = 0; i < parentFolder.CurrentItems.length; i++) {
                    if (parent != "") break;
                    let tmp: any = parentFolder.CurrentItems[i];
                    if (folder.Mnemonic == tmp.Mnemonic) {
                        parent = parentFolder.Mnemonic;
                        break;
                    }
                }
            }
        }

        return parent;
    }

    /**
     * Set focus on current item
     * @param item
     */
    private setFocusCurrentItem(item: any) {
        item && document.getElementById("btn_" + item).focus();
    }

    private keyDownArrow(folder: any, menu: any, isFolder: boolean, parent: string, originalmsg: any) {
        let found: boolean = false;
        let focusItem = "";
        for (let i = 0; i < menu.Folders.length; i++) {
            if (found) {
                break;
            }

            let tmp = menu.Folders[i];

            if (tmp.IsOpen && isFolder) { // Move to the Sub Levels if Root folder is open
                if (tmp.Mnemonic != folder.Mnemonic) { //loop inner levels till match the selected menu
                    if (tmp.Folders.length > 0) {
                        this.keyDownArrow(folder, tmp, isFolder, parent, originalmsg);
                    }
                } else if (tmp.Mnemonic == folder.Mnemonic && parent == menu.Mnemonic) { // once found the selected menu Focus to the first child folder/ form
                    if (tmp.Folders.length > 0) {
                        focusItem = tmp.Folders[0].Mnemonic;
                        found = true;
                    } else if (tmp.CurrentItems.length > 0) {
                        focusItem = this.getCurrentItems(tmp.CurrentItems, tmp.Mnemonic); // sorting the items based on the processClass and focus to the first form
                        focusItem = tmp.Mnemonic + "_" + focusItem;
                        found = true;
                    }
                    this.setFocusCurrentItem(focusItem);
                }
            } else if (!tmp.IsOpen && isFolder) { //Move to next menu(main folder) in same level
                if (tmp.Mnemonic == folder.Mnemonic && i < menu.Folders.length - 1 && parent == menu.Mnemonic) {
                    tmp = menu.Folders[i + 1];
                    focusItem = tmp.Mnemonic;
                    found = true;
                    this.setFocusCurrentItem(focusItem);
                } else if (tmp.Mnemonic == folder.Mnemonic && parent == menu.Mnemonic && menu.CurrentItems.length > 0) { // Move to parent forms
                    focusItem = this.getCurrentItems(menu.CurrentItems, menu.Mnemonic); // go to the parent forms
                    focusItem = menu.Mnemonic + "_" + focusItem;
                    found = true;
                    this.setFocusCurrentItem(focusItem);
                } else if (tmp.Mnemonic == folder.Mnemonic && parent == menu.Mnemonic) { //move to the parent level
                    this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.down, originalmsg);
                    found = true;
                    break;
                }
            } else if (!isFolder) { // Moving to the form levels
                if (this.items.length == 0) {
                    // Get the current item's parent folder and get the items based on that.
                    let parentItem = $.grep(menu.Folders, (f: any) => {
                        return f.Mnemonic == parent;
                    });
                    if (parentItem.length > 0) {
                        focusItem = this.getCurrentItems(parentItem[0].CurrentItems, null);
                    }
                }
                for (let j = 0; j < this.items.length; j++) { // Loop the same level items
                    if (this.items[j].Item == folder.Mnemonic && this.items[j].Position < this.items.length - 1) {
                        let nextItem = $.grep(this.items, (e: any) => {
                            return e.Position == this.items[j].Position + 1;
                        });
                        if (nextItem.length > 0) {
                            focusItem = nextItem[0].Item;
                            focusItem = parent + "_" + focusItem;
                            //console.log(focusItem);
                            this.setFocusCurrentItem(focusItem);
                            found = true;
                        }
                        break;
                    } else if (this.items[j].Item == folder.Mnemonic && this.items[j].Position == this.items.length - 1) { // Move to parent level next folder / parent->parent current items
                        this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.down, originalmsg);
                        found = true;
                        break;
                    }
                }
            }

        }

    }

    private getCurrentItems(currentItems: any, parent: any) {
        // console.log("_getCurrentItems");
        this.items = [];
        let order: number;
        let position: number;
        for (let i = 0; i < currentItems.length; i++) {
            position = i;
            if (currentItems[i].ProcessClass == Models.NavigateConstants.M) {
                order = 0;
            } else if (currentItems[i].ProcessClass == Models.NavigateConstants.I) {
                order = 1;
            } else if (currentItems[i].ProcessClass == Models.NavigateConstants.R) {
                order = 2;
            } else if (currentItems[i].ProcessClass == Models.NavigateConstants.P) {
                order = 3;
            }
            this.items.push({
                Order: order,
                ProcessClass: currentItems[i].ProcessClass,
                Item: currentItems[i].Mnemonic,
                Position: position
            });
        }

        this.items.sort((a: any, b: any) => {
            return (a.Order == b.Order) ? (a.Position - b.Position) : (a.Order - b.Order);
        });

        let returnItem: any = $.grep(this.items, (i: any) => {
            return i.Position == 0;
        });

        return returnItem.length > 0 ? returnItem[0].Item : this.items[0].Item;
    }

    private moveToParentLevel(parent: any, menu: any, key: any, originalmsg: any) {
        let found: boolean = false;
        let focusItem: string = "";

        if (key == Models.KeyCodes.up) {
            for (let i = 0; i < menu.Folders.length; i++) {
                if (found) {
                    break;
                }

                let tmp: any = menu.Folders[i];

                if (parent != tmp.Mnemonic && tmp.IsOpen) {
                    this.moveToParentLevel(parent, tmp, Models.KeyCodes.up, originalmsg);
                } else if (parent == tmp.Mnemonic && tmp.IsOpen) {
                    let folder: string = "";
                    if (tmp.Folders.length > 0) {
                        focusItem = tmp.Folders[tmp.Folders.length - 1].Mnemonic; //focus to child menu
                        folder = tmp.Folders[tmp.Folders.length - 1];
                    } else {
                        focusItem = menu.Folders[i].Mnemonic; //focus to parent menu
                        folder = menu.Folders[i];
                    }

                    found = true;
                    //CollapseNon Selected Menus
                    let parentParent = this.parentMenu(folder, originalmsg);
                    this.collapseOtherMenus(originalmsg, folder, parentParent);
                    this.setFocusCurrentItem(focusItem);
                }
            }
        }
        else if (key == Models.KeyCodes.down) {
            for (let i = 0; i < menu.Folders.length; i++) {
                if (found) break;
                let tmp = menu.Folders[i];
                if (parent != tmp.Mnemonic && tmp.IsOpen) {
                    this.moveToParentLevel(parent, tmp, Models.KeyCodes.down, originalmsg);
                } else if (parent == tmp.Mnemonic && tmp.IsOpen) {

                    if (i == menu.Folders.length - 1) {
                        focusItem = this.getCurrentItems(menu.CurrentItems, menu.Mnemonic); // go to the parent forms
                        focusItem = menu.Mnemonic + "_" + focusItem;
                        found = true;
                        tmp.IsOpen = false;

                    } else {
                        focusItem = menu.Folders[i + 1].Mnemonic; //focus to next folder in same level
                        found = true;
                        menu.Folders[i].IsOpen = false;
                    }
                    this.setFocusCurrentItem(focusItem);
                }
            }
        }
    }

    private keyUpArrow(folder: any, menu: any, isFolder: boolean, parent: any, originalmsg: any) {
        let found: boolean = false;
        let focusItem: string = "";
        for (let i = 0; i < menu.Folders.length; i++) {
            if (found) {
                break;
            }

            let tmp: any = menu.Folders[i];
            if (tmp.IsOpen && isFolder) { // Move within the same level of folders
                if (tmp.Mnemonic != folder.Mnemonic) { //loop inner levels till reach the selected menu
                    if (tmp.Folders.length > 0) {
                        this.keyUpArrow(folder, tmp, isFolder, parent, originalmsg);
                    }
                } else if (tmp.Mnemonic == folder.Mnemonic && parent == menu.Mnemonic) { // once the selected menu is found, focus to the previous folder in same level/ form
                    if (menu.Folders.length > 0 && i > 0) {
                        tmp = menu.Folders[i - 1];
                        if (tmp.IsOpen) {
                            focusItem = this.getCurrentItems(tmp.CurrentItems, tmp.Mnemonic);
                            focusItem = this.items[this.items.length - 1].Item;
                            focusItem = tmp.Mnemonic + "_" + focusItem;

                        } else {
                            focusItem = tmp.Mnemonic;
                        }
                        this.setFocusCurrentItem(focusItem);
                        found = true;
                        break;
                    } else if (menu.Folders.length > 0 && i == 0) { //move to the parent level
                        focusItem = menu.Mnemonic;
                        this.setFocusCurrentItem(focusItem);
                        found = true;
                        tmp.IsOpen = false;
                        break;
                    }
                }
            }
            else if (!tmp.IsOpen && isFolder) { // Move from sub-level folder to the parent level folder
                if (tmp.Mnemonic == folder.Mnemonic && i > 0 && parent == menu.Mnemonic) {
                    tmp = menu.Folders[i - 1];
                    if (tmp.IsOpen) {
                        focusItem = this.getCurrentItems(tmp.CurrentItems, tmp.Mnemonic);
                        focusItem = this.items[this.items.length - 1].Item;
                        focusItem = tmp.Mnemonic + "_" + focusItem;

                    } else {
                        focusItem = tmp.Mnemonic;
                    }
                    this.setFocusCurrentItem(focusItem);
                    found = true;
                    break;
                } else if (tmp.Mnemonic == folder.Mnemonic && i == 0 && parent == menu.Mnemonic) {
                    focusItem = menu.Mnemonic;
                    this.setFocusCurrentItem(focusItem);
                    found = true;
                    break;
                }

            }
            else if (!isFolder) { // Moving to the form levels
                if (tmp.Mnemonic != parent && tmp.IsOpen) {
                    this.keyUpArrow(folder, tmp, isFolder, parent, originalmsg);
                } else if (tmp.Mnemonic == parent && tmp.IsOpen && tmp.CurrentItems.length > 0) {
                    focusItem = this.getCurrentItems(tmp.CurrentItems, tmp.Mnemonic);
                    for (let j = 0; j < this.items.length; j++) { // Loop the same level items
                        if (this.items[j].Item == folder.Mnemonic && this.items[j].Position > 0) {
                            let nextItem = $.grep(this.items, (e: any) => {
                                return e.Position == this.items[j].Position - 1;
                            });
                            if (nextItem.length > 0) {
                                focusItem = nextItem[0].Item;
                                focusItem = tmp.Mnemonic + "_" + focusItem;
                                this.setFocusCurrentItem(focusItem);
                                found = true;
                                break;
                            }
                        } else if (this.items[j].Item == folder.Mnemonic && this.items[j].Position == 0) { // Move to parent level child folder /parent
                            //   console.log("Move to parent level child folder /parent");
                            this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.up, originalmsg);
                            found = true;
                            break;
                        }
                    }
                } else if (tmp.Mnemonic == parent && tmp.IsOpen && tmp.Folder.length > 0) {
                    focusItem = tmp.Folders[tmp.Folders.length - 1].Mnemonic;
                    this.setFocusCurrentItem(focusItem);
                }

            }

        }
    }
}