/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable, Inject } from '@angular/core';
import { EventsService, ServerCommandService, UiLocalStorageService, FocusService, ProcessingService } from './';
import * as Models from "../models";

declare var $: any;
import * as _ from 'lodash';

@Injectable()
export class BarGraphService {

    open: boolean;
    graphId: string;
    title: string; // graph modal dialog
    secondaryTitle: string;// Secondary Graph title
    start: string; // metadata section
    current: string; // metadata section
    elapsed: string; // metadata section
    estCompletion: string; // metadata section
    ended: string; // metadata section
    maxValue: number; // primary graph total processes
    completed: boolean; // graph completed
    detail: any; // graph progress detail messages
    primaryProgress: number; // primary graph %
    primaryProcessed: number; // primary graph completed processes
    secondaryProgress: number; // secondary graph %
    autoClose: boolean; // auto close check box


    constructor(private eventsService: EventsService,
        private focusService: FocusService,
        private processingService: ProcessingService,
        private serverCommandService: ServerCommandService,
        private uiLocalStorageService: UiLocalStorageService) {
        this.eventsService.on(Models.EventConstants.initializeGraphMessage, [this.initializeGraphEventListener, this]);
        this.eventsService.on(Models.EventConstants.graphTextMessage, [this.graphTextMessageEventListener, this]);
        this.eventsService.on(Models.EventConstants.updateGraphMessage, [this.updateGraphMessageEventListener, this]);
        this.eventsService.on(Models.EventConstants.finishGraphMessage, [this.finishGraphMessageEventListener, this]);
    }

    /**
     * Events listener for Initilizing bar graph
     * @param barGraphservice
     * @param msg
     */
    initializeGraphEventListener(barGraphservice: any, msg: any) {
        let self: BarGraphService = <BarGraphService>barGraphservice;
        if (msg.GraphPrimary) {
            //TODO: Once the Wait dialog component is upgraded change the broadcast from pubsub service to angular 4 events.
            // close any processing wait if exists
            self.processingService.closeProcessing();

            // if bar graph is already open, close bar graph.
            if (self.open) self.closeBarGraph(false);

            // reset all the previous values .
            self.reset();

            // setup metadata for graph
            self.graphId = msg.GraphID;
            self.open = true;
            self.title = msg.GraphTitle;

            // if primary graph set start, current and max value

            self.start = msg.GraphStartTime + "   " + msg.GraphStartDate;
            self.maxValue = msg.GraphMax;


            //open bar graph
            self.openBarGraph();
        }
        else { // if the bar graph is not primary set the title for secondary bar graph.
            self.secondaryTitle = msg.GraphTitle;
        }
    }

    /**
     * Graph Text message listener
     * @param barGraphservice
     * @param msg
     */
    graphTextMessageEventListener(barGraphservice: any, msg: any) {
        let self: BarGraphService = <BarGraphService>barGraphservice;
        //push text message to detail array
        if (_.isArray(msg.GraphText)) {
            _.each(msg.GraphText, (txt: string) => {
                self.detail.push(txt);
            });

        }

        // scroll to the bottom after a delay so that new text is already rendered on UI.
        setTimeout(function () {
            var objDiv = document.getElementById("bargraph-result-container");
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 250);
    }

    /**
     * Update Graph Message Listener
     * @param barGraphservice
     * @param msg
     */
    updateGraphMessageEventListener(barGraphservice: any, msg: any) {
        let self: BarGraphService = <BarGraphService>barGraphservice;

        // if graph is primary, update header metadata
        if (msg.GraphPrimary) {
            self.elapsed = msg.GraphElapsedTime;
            self.estCompletion = msg.GraphEstimatedTime + "  " + msg.GraphEstimatedDate;
            self.current = msg.GraphCurrentTime;
            self.primaryProgress = msg.GraphPercentComplete;
            self.primaryProcessed = msg.GraphItemIndex;

            if (self.primaryProgress == 100) { // if primary progress bar is complete, complete the secondary progress bar as well.
                self.secondaryProgress = 100;
            }
        }
        else { // if graph is not primary update secondary progress %
            self.secondaryProgress = msg.GraphPercentComplete;
        }
        self.processingService.closeProcessing();
    }

    /**
     * Finish Bar Graph Message Listener
     * @param barGraphservice
     * @param msg
     */
    finishGraphMessageEventListener(barGraphservice: any, msg: any) {
        let self: BarGraphService = <BarGraphService>barGraphservice;

        if (msg.GraphPrimary) {
            self.completed = true;
            self.ended = msg.GraphEndTime + "   " + msg.GraphEndDate;
            if (self.autoClose) {
                self.closeBarGraph(true);
            }
            else {
                // if graph is set to pause after finish, focus on the finish button.
                self.focusFinish();
            }
        }
    }
    /**
     * Close bar graph
     * @param sendClose  true if app server needs to be informed of close.
     */
    closeBarGraph(sendClose: boolean) {
        if (sendClose) {
            this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.emptyString, "", "CLOSE_BAR_GRAPH");
        }
        $('.bar-graph-modal').modal('hide');
        $('.modal-backdrop').remove();
        setTimeout(() => {
            this.focusService.focusOn("currentPageView", true);
        }, 500);
    }

    /**
     * Resets all the params for the service
     */
    private reset() {
        this.open = false;
        this.title = "";
        this.start = "";
        this.current = "";
        this.elapsed = "";
        this.estCompletion = "";
        this.ended = "";
        this.maxValue = 0;
        this.completed = false;
        this.detail = [];
        this.primaryProgress = 0;
        this.secondaryProgress = 0;
        this.primaryProcessed = 0;
        this.secondaryTitle = "";

        // get the autoclose from local storage (it also reflects the value changed in the user preferences)
        var getlocal = this.uiLocalStorageService.get(Models.LocalStorageConstants.checked);
        this.autoClose = (getlocal == null || getlocal == "N") ? false : true;
    }

    /**
     * Open Bar Graph Modal
     */
    private openBarGraph() {
        setTimeout(function () {
            $('.bar-graph-modal').modal({ backdrop: 'static', keyboard: false }).draggable();
            $('.bar-graph-modal').show();
            document.getElementById("close-bar-graph-btn").focus();
        }, 250);
    }
    /**
     * Focus finish button
     */
    focusFinish() {
        // private focusFinish() {
        this.processingService.closeProcessing();
        setTimeout(() => {
            document.getElementById('finish-btn').focus();
        }, 250);
    }
}