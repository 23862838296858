/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import { RootScopeService, ValidationService, FocusService, EventsService } from "./";
import * as Models from "../models";

declare var $: any;

@Injectable()
export class PromptService {

    isOpen: boolean = false;
    title: string;
    messages: any;
    inputType: string;
    inputValue: string;
    buttons: any;
    internalMessageQueue: any[] = [];
    lastPrompt: any;
    defaultButtonIndex: string;
    placeHolderText: string;
    maxLength: number = -1;

    constructor(private eventsService: EventsService, private rootScopeService: RootScopeService,
        private validationService: ValidationService, private focusService: FocusService) {

    }

    popupMessageDismiss(callback: any = null, showProcessing: boolean = null, callbackParam: any = null): void {

        //if callback is defined invoke callback else send a close alert.
        if (callback) {
            callback(callbackParam);
        }


        this.eventsService.broadcast(Models.EventConstants.closePrompt);
        this.reset();

        // if there are message waiting to be in queue load them.
        if (this.internalMessageQueue.length > 0) {
            var message = this.internalMessageQueue[0].message;
            var close = this.internalMessageQueue[0].close;

            this.internalMessageQueue.splice(0, 1);
            this.popupMessageShow(message, close);
        }
        else {
            this.lastPrompt = null;
        }
    }

    popupMessageShow(message: any, closeSearch: any = false) {
        // check if dialog is already open
        // do nothing if the new message is same as last prompt
        // enqueue the new message if different than the new message.
        if (this.isOpen) {
            if (JSON.stringify(message) != JSON.stringify(this.lastPrompt)) {
                this.internalMessageQueue.push({
                    message: message,
                    close: closeSearch
                })
            } else {
                setTimeout(() => {
                    this.eventsService.broadcast(Models.EventConstants.closeAlert, true);
                }, 100);
            }

            return;
        }

        this.rootScopeService.performanceCounter += 3;

        // make a copy of the new prompt being displayed.
        this.lastPrompt = $.extend(true, {}, message);


        this.rootScopeService.interrupted = true;
        this.isOpen = true;

        this.inputType = message.inputType;
        this.placeHolderText = message.placeHolderText;
        if (this.validationService.isNullOrEmpty(message.inputValue)) {
            this.inputValue = "";
        }
        else {
            this.inputValue = message.inputValue;
        }
        // set the default button to focus on
        this.defaultButtonIndex = message.defaultCallbackNumber;
        if (this.defaultButtonIndex == null) {
            this.defaultButtonIndex = "0";
        }
        //reset max length
        this.maxLength = -1;

        this.title = message.title;
        if (!this.validationService.isNullOrEmpty(message.title)) {
            if (this.title.length > this.maxLength) {
                this.maxLength = this.title.length;
            }
        }

        this.messages = message.text;
        if (!this.validationService.isNullOrEmpty(message.text)) {
            for (let i = 0; i < this.messages.length; i++) {
                if (this.messages[i].length > this.maxLength) {
                    this.maxLength = this.messages[i].length;
                }
            }
        }

        this.buttons = message.buttons;

        // if buttons are not passed by the caller, insert a dummy ok button to close the prompt.
        if (this.buttons == null) {
            this.buttons = [{
                label: "Ok",
                title: "Close",
                callback: () => {
                    this.eventsService.broadcast(Models.EventConstants.closeAlert, true);
                }
            }]
        }

        // close search if requested.
        if (closeSearch && closeSearch == true) {
            this.eventsService.broadcast(Models.EventConstants.closeSearchPopover);
        }

        this.eventsService.broadcast(Models.EventConstants.openPrompt);

    }

    popupMessage(title: string, msg: string, buttons: any) {
        this.popupMessageShow({ 'title': title, 'messages': msg, 'buttons': buttons }, true);
    }

    /**
     * Short cut button event
     * @param key
     */
    triggerShortcutAction(key: string) {
        for (var i = 0; i < this.buttons.length; i++) {
            if (this.buttons[i].callbackArgs && this.buttons[i].callbackArgs.buttonShortcut == key) {
                this.popupButtonEvent(i);
                return;
            }
        }
    }

    /**
     * Popup button event
     * @param btnIndex
     * @param event
     */
    popupButtonEvent(btnIndex: number, event: any = null) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        let button = this.buttons[btnIndex];
        let callback = button.callback;
        if (callback !== undefined) {
            if (button.SearchFocus == true) {
                setTimeout(() => {
                    this.focusService.focusSearch();
                }, 500);
            }
            if (!this.validationService.isNullOrEmpty(this.inputValue)) {
                this.eventsService.broadcast("openAlert", {
                    alertTitle: "Please Wait...",
                    message: 'Processing ...',
                    event: 'Popup Lookup value entered'
                });
            }
            callback(button.callbackArgs, this.inputValue);
        } else {
            if (button.SearchFocus == true) {
                setTimeout(() => {
                    this.focusService.focusSearch();
                }, 500);
            }
            this.popupMessageDismiss(null, false, null);
        }
    }


    private reset() {
        this.title = "";
        this.messages = [];
        this.inputType = "";
        this.inputValue = "";
        this.buttons = null;
        this.defaultButtonIndex = "0";
        this.placeHolderText = "";
        this.isOpen = false;
    }
}