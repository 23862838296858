/**
* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import {
    ConfigurationService,
    FavoritesService,
    FocusService,
    HelpService,
    RootScopeService,
} from "../../services";

declare var $: any;

@Component({
    selector: 'foldercreatesuccess',
    template: require('./folder-create-success.component.html')
})
export class FolderCreateSuccessComponent {
    createNewFolderDialog: string = "#create-new-folder-dialog";
    folderCreateSuccesDialog: string = "#folder-create-succes-dialog";
    addToFavoritesDialog: string = "#add-to-favorites-dialog";
    btnAddFavorite: string = "#btn_add-favorite-addEntries";
    favoritesDialog: string = "#favorites-dialog";
    updatePending: boolean = false;
    formFavoritesButton: string = "#form-sr-favorites-btn";

    constructor(private favoritesService: FavoritesService,
        private focusService: FocusService,
        private helpService: HelpService,
        private configurationService: ConfigurationService,
        private rootScopeService: RootScopeService) {

    }

    /**
     * Opens add-to-favorites help
     * @param helpID
     */
    help(helpID: string) {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses[helpID]);
    }

    closeCreateNewFolder() {
        $(this.createNewFolderDialog).modal('hide');
        $(this.folderCreateSuccesDialog).modal('hide');
        $(this.addToFavoritesDialog).modal('show');
        setTimeout(() => {
            let elementToFocus = document.querySelector("#add-to-favorites-dialog #btn_add-favorite-addEntries") as HTMLElement;
            elementToFocus.focus();
        }, 100);
    }

    closeAddToFavorites() {
        this.rootScopeService.FavActiveHelp = true;
        this.updatePending = false;
        this.favoritesService.folderToCreate = "";
        // this.favoritesService.foldersToCommit = [];
        this.favoritesService.entriesToCommit = [];
        $(this.addToFavoritesDialog).modal('hide');
        setTimeout(() => {
            // If favorites dialog is open, focus on add to favorites icon in header of the favorites dialog otherwise shift focus to the previous focus field(either in form or on the search icon)
            if ($(this.favoritesDialog).is(":visible")) {
                let addToFavoritesBtn = document.querySelector("#favorites-dialog #btnAddToFavoritesIcon") as HTMLElement;
                addToFavoritesBtn.focus();
            }
            else if ($(this.formFavoritesButton).is(":visible")) {
                document.getElementById("form-sr-favorites-btn").focus();
            }
            else {
                this.focusService.focusPreviousField();
                this.focusService.selectPreviousField();
            }
        }, 100);
    }
}
