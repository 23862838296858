/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Injectable} from "@angular/core";

@Injectable()
export class ValidationService {
    isNullOrEmpty(elementOrData: any): boolean {
        return elementOrData === undefined || elementOrData === "" || elementOrData === null;
    }
}