/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import { RootScopeService, SubMenuDialogService, ValidationService, FocusService } from "../../../services";
import * as Models from "../../../models";
import * as _ from 'lodash';

@Component({
    selector: 'submenudialog',
    template: require('./sub-menu-dialog.component.html')
})
export class SubMenuDialogComponent {
    constructor(private rootScopeService: RootScopeService,
        private validationService: ValidationService,
        private subMenuDialogService: SubMenuDialogService,
        private focusService: FocusService) {

    }

    /**
     * Toggles the item
     * @param menuItem
     */
    subMenuSelectItem(menuItem: any): void {
        this.subMenuDialogService.subMenuItems.selectedItem = menuItem;
    }

    /**
     * Open a sub-menu item(form)
     * @param mnemonic
     */
    subMenuOpenItem(mnemonic: string): void {
        this.subMenuDialogService.close(mnemonic);
    }

    /**
     * Handles key press on sub-menu items
     * @param event
     * @param menuItem
     */
    subMenuItemKeyDown(event: any, menuItem: any): void {
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        let space: boolean = event.keyCode == Models.KeyCodes.space;
        let up: boolean = event.keyCode == Models.KeyCodes.up;
        let down: boolean = event.keyCode == Models.KeyCodes.down;
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!enter && !space && !up && !down && !shift && !tab) {
            return;
        }

        if (enter) {
            this.subMenuOpenItem(menuItem.ItemMnemonic);
            event.preventDefault();
            event.stopPropagation();
        }
        else if (space) {
            this.subMenuSelectItem(menuItem);
            event.preventDefault();
            event.stopPropagation();
        }
        else if (up) {
            this.subMenuUpKey();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (down) {
            this.subMenuDownKey();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (shift && tab) {
            if (event.target.id == "sub-menu-item-list-0") {
                event.preventDefault();
                event.stopPropagation();
                this.focusService.focusOn("sub-menu-help-btn", true);
            }
        }
    }

    /**
     * React to OK button being pressed or triggered from the input field.
     */
    subMenuInputKeyDown(event: any): void {
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        let up: boolean = event.keyCode == Models.KeyCodes.up;
        let down: boolean = event.keyCode == Models.KeyCodes.down;

        if (!enter && !up && !down) {
            return;
        }

        if (enter) {
            this.subMenuOk();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (up) {
            this.subMenuUpKey();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (down) {
            this.subMenuDownKey();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    /**
     * If the enter key is pressed, open the selected item.
     */
    subMenuOk(): void {
        if (this.isValidInput()) {
            let selected: any = this.subMenuDialogService.subMenuItems.inputValue;
            if (selected.length === 0) {
                selected = this.subMenuDialogService.subMenuItems.selectedItem.ItemMnemonic;
            }
            this.subMenuDialogService.close(selected);
        }
    }

    /**
     * React to cancel button by return back an empty string for the mnemonic selected
     */
    subMenuCancel(): void {
        this.subMenuDialogService.close('');
    }

    /**
     * Bring up a small help window describing the purpose of the dialog
     */
    subMenuHelp(): void {
        this.subMenuDialogService.showHelp();
    }

    /**
     * Tab on Help should focus on first item in the list
     * @param event
     */
    btnSubMenuHelpKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("sub-menu-item-list-0", true);
        }
    }

    /**
     * If the up arrow key is pressed, move to the previous item.
     */
    private subMenuUpKey(): void {
        // Get the currently selected item
        let currentItem: any = this.subMenuDialogService.subMenuItems.selectedItem;
        // Index to switch to. This will decide which item in the detailMenuArguments.menuItems will be selected
        let switchToIndex: number;
        for (let i = 0; i < this.subMenuDialogService.subMenuItems.menuItems.length; i++) {
            // Get the current item's index and if it is not first item in the menuItems list, set the switchToIndex to an item before the currentItem
            if (this.subMenuDialogService.subMenuItems.menuItems[i].hasOwnProperty("ItemMnemonic") && this.subMenuDialogService.subMenuItems.menuItems[i]["ItemMnemonic"] === currentItem["ItemMnemonic"]) {
                switchToIndex = (i == 0) ? i : (i - 1);
                break;
            }
        }

        // Toggle to the previous item
        this.subMenuSelectItem(this.subMenuDialogService.subMenuItems.menuItems[switchToIndex]);

        // Shift focus on the selected item
        setTimeout(() => {
            let highlightItem = document.getElementsByClassName("highlight")[0] as HTMLElement;
            highlightItem.focus();
        }, 100);
    }

    /**
     * If the down arrow key is pressed, move to the next item.
     */
    private subMenuDownKey(): void {
        // Get the currently selected item
        let currentItem: any = this.subMenuDialogService.subMenuItems.selectedItem;
        // Index to switch to. This will decide which item in the detailMenuArguments.menuItems will be selected
        let switchToIndex: number;
        for (let i = 0; i < this.subMenuDialogService.subMenuItems.menuItems.length; i++) {
            // Get the current item's index and if it is not first item in the menuItems list, set the switchToIndex to an item before the currentItem
            if (this.subMenuDialogService.subMenuItems.menuItems[i].hasOwnProperty("ItemMnemonic") && this.subMenuDialogService.subMenuItems.menuItems[i]["ItemMnemonic"] === currentItem["ItemMnemonic"]) {
                switchToIndex = (i == this.subMenuDialogService.subMenuItems.menuItems.length - 1) ? i : (i + 1);
                break;
            }
        }

        // Toggle to the previous item
        this.subMenuSelectItem(this.subMenuDialogService.subMenuItems.menuItems[switchToIndex]);

        // Shift focus on the selected item
        setTimeout(() => {
            let highlightItem = document.getElementsByClassName("highlight")[0] as HTMLElement;
            highlightItem.focus();
        }, 100);
    }

    /**
     * Return true if we have a valid mnemonic in the input box, or an empty input box in which case we are valid since the currently selected mnemonic must be valid.
     * @returns {boolean}
     */
    private isValidInput(): boolean {
        let valid: boolean = false;
        let inputValue: any = this.subMenuDialogService.subMenuItems.inputValue;
        if (!this.validationService.isNullOrEmpty(inputValue) && inputValue.length > 0) {
            if (!isNaN(inputValue)) {
                inputValue = inputValue | 0;
                if ((inputValue > 0) && (inputValue <= this.subMenuDialogService.subMenuItems.menuItems.length)) {
                    valid = true;
                }
            } else {
                _.each(this.subMenuDialogService.subMenuItems.menuItems, (value: any, key: any) => {
                    if (value.ItemMnemonic.toUpperCase() === inputValue.toUpperCase()) {
                        valid = true;
                    }
                });
            }
        } else {

            if (this.subMenuDialogService.subMenuItems.selectedItem !== undefined) {
                valid = true;
            }
        }
        return valid;
    }
}