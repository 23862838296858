/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    ConfigurationService,
    EventsService,
    HelperService,
    MessageHandlerService,
    ProcessingService,
    PromptService,
    ServerCommandService,
    SessionService
} from "./";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as Models from "../models";

// This variable is set from the Admin Site in main.js. Declare it here to shut typescript up.
declare var clientQueuePollingInterval: any;
declare var $: any;
import * as _ from 'lodash';
declare var $: any;

@Injectable()
export class QueueService {
    pollUrl: string;
    sendUrl: string;
    polling: boolean = false;
    pollErrorCounter: number;

    constructor(private configurationService: ConfigurationService,
        private eventsService: EventsService,
        private messageHandlerService: MessageHandlerService,
        private helperService: HelperService,
        private sessionService: SessionService,
        private serverCommandService: ServerCommandService,
        private http: HttpClient, private processingService: ProcessingService, private promptService: PromptService) {
        this.pollUrl = this.configurationService.getConfiguration().serviceUrl + "/getClientMessages/0";
        this.sendUrl = this.configurationService.getConfiguration().serviceUrl + "/sendServerMessage";
        this.pollErrorCounter = 0;
        this.eventsService.on(Models.EventConstants.sendServerMessage, [this.sendServerMessageListener, this]);
        this.eventsService.on(Models.EventConstants.logoutComplete, [this.logoutCompleteMessageListener, this]);
        this.eventsService.on("preferencesLoaded", [this.preferencesLoadedListener, this]);
        this.eventsService.on("favoritesLoaded", [this.favoritesLoadedListener, this]);
        this.eventsService.on("SessionCloseMessage", [this.sessionCloseMessageListener, this]);
        this.eventsService.on("stopPolling", [this.stopPollingListener, this]);
    }

    preferencesLoadedListener(queueService: QueueService, message: any) {
        let self: QueueService = <QueueService>queueService;
        self.poll();
    }

    favoritesLoadedListener(queueService: QueueService, message: any) {
        let self: QueueService = <QueueService>queueService;
        // self.poll();
        if ($.cookie('refresh_session') != null) {
            $.removeCookie('refresh_session', { path: '/' });
            $.removeCookie('refresh_sso_logout_token', { path: '/' });
            var uiVersionString = "UiVersion";
            setTimeout(() => {
                self.serverCommandService.sendProcessCommandWithDataMessage("AuthenticatedSettings", uiVersionString, Models.UI.version);
            }, 500);
        }
    }

    stopPollingListener(queueService: QueueService, message: any) {
        let self: QueueService = <QueueService>queueService;
        self.stop();
    }

    sendServerMessageListener(queueService: QueueService, message: any) {
        let self: QueueService = <QueueService>queueService;
        self.send(message);
    }

    logoutCompleteMessageListener(queueService: QueueService) {
        let self: QueueService = <QueueService>queueService;
        self.stop();
    }

    sessionCloseMessageListener(service: any) {
        let self = <QueueService>service;
        self.stop();
    }

    poll() {
        if (this.polling === false) {
            this.polling = true;

            let pollingFn = (queueService: QueueService) => {
                (function poll(queueSvc: QueueService = queueService) {
                    let self: QueueService = <QueueService>queueSvc;
                    let requestBody: any = self.helperService.objectToXml(self.createQueueMessageRequest(self.sessionService.getToken()));
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Content-Type': 'text/xml',
                            'Accept': 'application/json'
                        })
                    };

                    self.http.post(self.pollUrl, requestBody, httpOptions).timeoutWith(clientQueuePollingInterval, Observable.throwError(new Error("Timeout Occurred."))).subscribe(
                        (response: any) => {
                            // If the server responds with a valid response, check if the response has any Errors collection
                            if (response.Errors != null && response.Errors.length > 0) {
                                // Log the error for troubleshooting purposes
                                console.error(response.Errors);

                                if (self.polling) {
                                    if (self.pollErrorCounter < 3) { // retry 3 times (30 seconds)
                                        self.pollErrorCounter++;
                                        // After 10 seconds, try to poll again
                                        setTimeout(() => {
                                            poll(self);
                                        }, 10000);
                                    }
                                    // If the error poll counter is less than 12 and greater than or equal to 3, display the processing dialog to the users
                                    else if (self.pollErrorCounter < 12) {
                                        // Close any prior processing dialogs, if any
                                        self.processingService.closeProcessing();
                                        // If there are no prompt dialogs open, proceed
                                        if (!self.promptService.isOpen) {
                                            let alertArgs: any = {
                                                id: "pollingError",
                                                message: "Connection to the UI Web Server has been lost. Trying to reconnect...",
                                                alertTitle: "Please Wait ...",
                                                event: "Queue Service Polling"
                                            };
                                            self.eventsService.broadcast(Models.EventConstants.openAlert, alertArgs);
                                        }
                                        // Increment the error poll counter by 1
                                        self.pollErrorCounter++;
                                        // After 10 seconds, try to poll again
                                        setTimeout(() => {
                                            poll(self);
                                        }, 10000);
                                    }
                                    // Error poll counter has already exceeded it's maximum tries (12) to establish a valid connection with the web server
                                    // Stop the polling and display a prompt dialog to the user letting them know that their session has expired
                                    else {
                                        // Stop polling
                                        self.stop();
                                        // Close any processing dialogs and display the error message prompt
                                        self.processingService.closeProcessing();
                                        // Generate error message for prompt dialog
                                        let errorMessage: any = {};
                                        errorMessage.title = 'Error';
                                        errorMessage.text = ["Due to a fatal error on the web server, your session has expired and you have been logged out of Colleague."];
                                        errorMessage.defaultCallbackNumber = 0;
                                        errorMessage.buttons = [{
                                            label: "Ok",
                                            callback: () => {
                                                self.promptService.popupMessageDismiss(() => {
                                                    self.eventsService.broadcast(Models.EventConstants.logoutComplete);
                                                    self.configurationService.displayLogoutComplete = true;
                                                }, false);
                                            },
                                            title: ""
                                        }];
                                        self.promptService.popupMessageShow(errorMessage);
                                    }
                                }
                            }
                            // The response received from the server is a valid response without any errors
                            else {
                                if (self.pollErrorCounter > 0) {
                                    // Reset the error poll counter
                                    self.pollErrorCounter = 0;
                                    // Clear PPW as we have connected successfully.
                                    self.processingService.closeProcessing();
                                }

                                // Parse the messages received from the server
                                self.messageHandlerService.parseMessages(response, true);

                                // Start polling again after a 100ms delay
                                if (self.polling) {
                                    setTimeout(() => {
                                        poll(self);
                                    }, 100);
                                }
                            }
                        }, (error: any) => { // Server responded with an error
                            // Log the error for troubleshooting purposes
                            console.error(error);

                            if (self.polling) {
                                if (self.pollErrorCounter < 3) { // retry 3 times (9 seconds)
                                    self.pollErrorCounter++;
                                    // After 3 seconds, try to poll again
                                    setTimeout(() => {
                                        poll(self);
                                    }, 3000);
                                }
                                else if (self.pollErrorCounter < 63) {  // If the error poll counter is less than 63, display the processing dialog to the users
                                    // Close any prior processing dialogs, if any
                                    self.processingService.closeProcessing();
                                    // If there are no prompt dialogs open, proceed
                                    if (!self.promptService.isOpen) {
                                        let alertArgs: any = {
                                            id: "pollingError",
                                            message: "Connection to the UI Web Server has been lost. Trying to reconnect...",
                                            alertTitle: "Please Wait ...",
                                            event: "Queue Service Polling"
                                        };
                                        self.eventsService.broadcast(Models.EventConstants.openAlert, alertArgs);
                                    }
                                    // Increment the error poll counter by 1
                                    self.pollErrorCounter++;
                                    // After 5 seconds, try to poll again
                                    setTimeout(() => {
                                        poll(self);
                                    }, 5000);
                                }
                                else {
                                    // Stop polling
                                    self.stop();
                                    // Generate error message for prompt dialog
                                    let errorMessage: any = {};
                                    errorMessage.title = 'Error';
                                    errorMessage.text = ["Due to a fatal error on the web server, your session has expired and you have been logged out of Colleague."];
                                    errorMessage.defaultCallbackNumber = 0;
                                    errorMessage.buttons = [{
                                        label: "Ok",
                                        callback: () => {
                                            self.promptService.popupMessageDismiss(() => {
                                                self.eventsService.broadcast(Models.EventConstants.logoutComplete);
                                                self.configurationService.displayLogoutComplete = true;
                                            }, false);
                                        },
                                        title: ""
                                    }];
                                    // Close any processing dialogs and display the error message prompt
                                    self.processingService.closeProcessing();
                                    self.promptService.popupMessageShow(errorMessage);
                                }
                            }
                        });
                })();
            };

            pollingFn(this);
        }
    }

    send(message: any) {
        let containsDotDotDot: boolean = false;
        //update field data for escaping the html just in case. Mainly required in case of sending html from multi-line editor.
        if (_.isArray(message)) {
            for (let i = 0; i < message.length; i++) {
                // replace function can be undefined for numbers
                if (message[i].FieldData && message[i].FieldData.replace) {
                    message[i].FieldData = message[i].FieldData.replace(/&/g, "&amp;").replace(/\n/g, "&#xD;").replace(/"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\//g, "&#x2F;");
                    if (message[i].DoNotReplaceSingleQuote == false) {
                        message[i].FieldData = message[i].FieldData.replace(/'/g, "&#39;")
                    }
                }
                if (message[i].FieldData && message[i].FieldData.indexOf && message[i].FieldData.indexOf('...') >= 0) {
                    containsDotDotDot = true;
                }
            }
        }
        else {
            // replace function can be undefined for numbers
            if (message.FieldData && message.FieldData.replace) {
                message.FieldData = message.FieldData.replace(/&/g, "&amp;").replace(/\n/g, "&#xD;").replace(/"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\//g, "&#x2F;");
                if (message.DoNotReplaceSingleQuote == false) {
                    message.FieldData = message.FieldData.replace(/'/g, "&#39;")
                }
            }
            if (message.FieldData && message.FieldData.indexOf && message.FieldData.indexOf('...') >= 0) {
                containsDotDotDot = true;
            }
        }

        let messageArray: any = this.createQueueMessage(message);
        let request: any = this.helperService.objectToXml(this.createQueueMessageRequest(this.sessionService.getToken(), messageArray));

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'text/xml',
                'Accept': 'application/json'
            })
        };

        if (containsDotDotDot == true) {
            this.processingService.showProcessing("dot dot dot");
        }

        this.http.post(this.sendUrl, request, httpOptions).timeoutWith(30000, Observable.throwError(new Error("Timeout Occurred."))).subscribe(
            (response: any) => {
                this.messageHandlerService.parseMessages(response, false);
            }, (error: any) => {
                console.error("Error sending queue message");
                console.error(error);
            });
    }

    stop() {
        this.polling = false;
    }

    private createQueueMessage(message: any) {
        return {
            "QueueMessage": message
        };
    }

    private createQueueMessageRequest(token: any, messages: any = []) {
        return {
            "QueueMessageRequest": {
                "_xmlns:i": "http://www.w3.org/2001/XMLSchema-instance",
                "SecurityToken": token,
                "Messages": messages || []
            }
        };
    }
}
