/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnDestroy, OnInit } from "@angular/core";
import * as Models from "../../models";
import {
    EventsService,
    FocusService,
    FormService,
    UiLocalStorageService,
    ParserService,
    PreferencesService,
    ProcessingService,
    PromptService,
    RendererService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    SessionService,
    SessionStorage,
    ValidationService
} from "../../services";

declare var $: any;

@Component({
    selector: 'navbarright',
    template: require('./navbarRight.component.html')
})
export class NavbarRightComponent implements OnInit {
    CustomTabSettingsMessageListenerId: string = "";
    recordDeleteListenerId: string = "";
    showFieldSequenceListenerId: string = "";
    disableCustomFieldSequenceListenerId: string = "";
    cancelCustomizeFieldSequenceListenerId: string = "";
    customTabSettings: boolean = false;

    constructor(private sessionService: SessionService, private searchService: SearchService, private preferencesService: PreferencesService
        , private serverCommandService: ServerCommandService, private focusService: FocusService, private formService: FormService, private rootScopeService: RootScopeService,
        private validationService: ValidationService, private sessionStorage: SessionStorage, private parserService: ParserService, private eventsService: EventsService
        , private promptService: PromptService, private processingService: ProcessingService, private uiLocalStorageService: UiLocalStorageService, private rendererService: RendererService) {
        this.CustomTabSettingsMessageListenerId = this.eventsService.on("CustomTabSettingsMessage", [this.CustomTabSettingsMessageListener, this]);
        this.recordDeleteListenerId = this.eventsService.on(Models.CommandConstants.recordDelete, [this.recordDeleteListener, this]);
        this.showFieldSequenceListenerId = this.eventsService.on(Models.CommandConstants.showFieldSequenceClick, [this.showFieldSequenceListener, this]);
        this.disableCustomFieldSequenceListenerId = this.eventsService.on(Models.CommandConstants.disableCustomFieldSequenceClick, [this.disableCustomFieldSequenceListener, this]);
        this.cancelCustomizeFieldSequenceListenerId = this.eventsService.on("cancelCustomizeFieldSequence", [this.cancelCustomizeFieldSequenceListener, this]);
    }

    ngOnDestroy() {
        this.eventsService.destroy("CustomTabSettingsMessage", this.CustomTabSettingsMessageListenerId);
        this.eventsService.destroy(Models.CommandConstants.recordDelete, this.recordDeleteListenerId);
        this.eventsService.destroy(Models.CommandConstants.showFieldSequenceClick, this.showFieldSequenceListenerId);
        this.eventsService.destroy(Models.CommandConstants.disableCustomFieldSequenceClick, this.disableCustomFieldSequenceListenerId);
        this.eventsService.destroy("cancelCustomizeFieldSequence", this.cancelCustomizeFieldSequenceListenerId);
    }

    recordDeleteListener(comp: any) {
        let self = <NavbarRightComponent>comp;

        if (self.formRecordDelete()) {
            self.deleteRecord();
        }
    }

    showFieldSequenceListener(comp: any) {
        let self = <NavbarRightComponent>comp;
        self.showFieldSequenceClick();
    }

    cancelCustomizeFieldSequenceListener(comp: any) {
        let self = <NavbarRightComponent>comp;
        self.rootScopeService.selectedElement = "";
        self.toggleCustomizeFieldSequenceOverlay(false);
        self.toggleElementOrderAndDisabledDiv(false);
        self.hideSequenceOrderAndDisabledDiv(self);
    }

    disableCustomFieldSequenceListener(comp: any) {
        let self = <NavbarRightComponent>comp;
        self.disableCustomFieldSequenceClick();
    }

    ngOnInit() {
    }

    logout(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.eventsService.broadcast("TriggerLogout");
        }
    }

    closeAllotherPopup() {
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
        this.searchService.toggleVisible();
        return true;
    }

    openPreferences(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.preferencesService.getPrefrences();
            this.closeGearMenu();
        }
    }

    preferenceKeyDown(event: any = null) {
        if (event.keyCode == Models.KeyCodes.tab && event.shiftKey) {
            this.closeGearMenu();
        }
    }

    gearDropdownButtonKeydown(event: any) {
        if (event.keyCode == Models.KeyCodes.tab && event.shiftKey) {
            this.closeGearMenu();
        }
        else {
            return;
        }
    }

    reloadSession(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.serverCommandService.sendProcessCommandMessage("ClearCache");
            this.focusService.focusSearch();
            this.closeGearMenu();
            this.rendererService.clearStyles();

        }
    }

    showFieldSequenceClick(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.showSequenceOrderAndDisabledDiv(false);
        }
    }

    showSequenceOrderAndDisabledDiv(customizeFieldSequencePropertiesFlag: boolean) {
        // Set the flag to true so that the span in form-element and form-valcode get enabled
        this.rootScopeService.showSequenceOrder = true;

        // If the user clicked "Customize Field Sequence and Properties" option, do not enable the background on click of this hidden div.
        // The background should get enabled when the user is done saving or cancelling out of customization.
        if (customizeFieldSequencePropertiesFlag != true) {
            this.rootScopeService.showElementOrder = false;
            // Display the div that will disable the background form and interface
            let disablingDiv = $("#disablingDiv");
            disablingDiv.css("display", "block");

            // If the user clicked Show Field Sequence, disable any keyboard hits
            $(document).on("keydown", (event: any) => {
                disablingDiv = null;
                return false;
            });

            // // When the user clicks on the disabled div, sequence order will be hidden and the keyboard events will be enabled once again.
            disablingDiv.on("click", this, this.hideSequenceOrderAndDisabledDiv);
            // disablingDiv.click(this, this.hideSequenceOrderAndDisabledDiv);
            disablingDiv = null;
        } else {
            this.rootScopeService.showElementOrder = true;
        }
    }

    hideSequenceOrderAndDisabledDiv(comp: any) {
        let self = <NavbarRightComponent>comp.data;

        if (comp.data == null)
            self = <NavbarRightComponent>comp;

        // Set the flag to false so that the span in form-element and form-valcode get disabled
        self.rootScopeService.showSequenceOrder = false;

        // Hide the div so that the background can be enabled again
        let disablingDiv = $("#disablingDiv");
        disablingDiv.css("display", "none");

        // Remove the click event from disabling div
        disablingDiv.off("click");

        disablingDiv = null;

        // Enable the keyboard events on the form and interface of UI
        $(document).off("keydown");

        // Set focus on previous field in the form
        // self.focusService.focusOn(self.focusService.prevFormFocusElement, true);
        setTimeout(() => {
            self.focusService.focusPreviousField();
            self.focusService.selectPreviousField();
        }, 50);
    }

    disableCustomFieldSequenceClick(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            if (this.formService.forms.length >= 1) {
                this.rootScopeService.disableCustomFieldSequence = !this.rootScopeService.disableCustomFieldSequence;

                //region Display check in front of Disable Custom Field Sequence option
                let disableCustomFieldSequenceCheck = document.getElementById("disableCustomFieldSequenceCheck") as HTMLElement;
                disableCustomFieldSequenceCheck.style.display = this.rootScopeService.disableCustomFieldSequence ? "inline-block" : "none";
                disableCustomFieldSequenceCheck = null;
                //endregion

                //region Send a message to server with Command CustomTabDisable
                this.serverCommandService.sendProcessCommandWithDataMessage("CustomTabDisable", null, this.rootScopeService.disableCustomFieldSequence);
                //endregion

                //region Re-create the Sequence Order for elements
                if (!this.validationService.isNullOrEmpty(this.formService.getLatestForm().fields)) {
                    // Get customFormElementList that was cached
                    this.sessionStorage.setPrefix("customFormSpecsElementList");
                    var key = this.formService.getLatestForm().formId;
                    var elementList = this.sessionStorage.get(key);

                    // Create Sequence Order for the elements in the form
                    this.parserService.createSequenceOrderForElements(this.formService.getLatestForm().fields, elementList);
                }
                //endregion
            }
        }
    }

    CustomTabSettingsMessageListener(comp: any, message: any) {
        let self = <NavbarRightComponent>comp;
        self.customTabSettings = true;

        self.rootScopeService.UserOverrideAllowed = message.UserOverrideAllowed;
        // If UserOverrideAllowed is false, create the sequence order from here since there will be no CustomFormSpecsMessage from the server.
        if (self.rootScopeService.UserOverrideAllowed == false) {
            self.parserService.createSequenceOrderForElements(self.formService.getLatestForm().fields, null);
        }
        self.rootScopeService.UserTabOverrideAllowed = message.UserTabOverrideAllowed;
        self.rootScopeService.AllTabOverrideAllowed = message.AllTabOverrideAllowed;
        self.rootScopeService.UserFieldAccessOverrideAllowed = message.UserFieldAccessOverrideAllowed;
        self.rootScopeService.AllFieldAccessOverrideAllowed = message.AllFieldAccessOverrideAllowed;

        self.rootScopeService.showElementOrder = false;
        self.rootScopeService.showSequenceOrder = false;
        self.rootScopeService.customizeFieldSequenceFlag = false;
        // Populate the Customize for user list with multiple users
        if (self.rootScopeService.AllTabOverrideAllowed == true || self.rootScopeService.AllFieldAccessOverrideAllowed == true) {
            self.rootScopeService.callCustomTabRetrieval = true;
        }
    }

    toggleElementOrderAndDisabledDiv(showFlag: boolean) {
        // Set the flag to true so that the span in form-element and form-valcode get enabled
        this.rootScopeService.showElementOrder = showFlag;
    }

    toggleCustomizeFieldSequenceOverlay(showFlag: boolean) {
        this.rootScopeService.customizeFieldSequenceFlag = showFlag;
    }

    reloadColleagueUI(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.sessionService.backupSession();
        }
    }

    customizeFieldSequencePropertiesClick() {
        // If no forms up, then disable

        if (this.formService.forms.length < 1) {
            this.rootScopeService.showElementOrder = false;
            this.rootScopeService.customizeFieldSequenceFlag = false;
        } else if (this.customTabSettings) {
            this.rootScopeService.customizeFieldSequenceFlag = true;
            this.toggleElementOrderAndDisabledDiv(true);
            // If Custom Field Sequence is disabled, re-create the sequence orders before displaying them on customize window
            if (this.rootScopeService.disableCustomFieldSequence == true) {
                var currentOperator = this.sessionService.getOperatorId();
                if (this.formService.getLatestForm().customizations != null) {
                    var customFormSpecsMsg = this.formService.getLatestForm().customizations[currentOperator];
                    if (customFormSpecsMsg.ElementList != null && customFormSpecsMsg.ElementList.length > 0) {
                        var customizedElementList = customFormSpecsMsg.ElementList;
                        if (!this.validationService.isNullOrEmpty(customizedElementList) && customizedElementList.length > 0) {
                            this.rootScopeService.disableCustomFieldSequence = false;
                            this.parserService.createSequenceOrderForElements(this.formService.getLatestForm().fields, customizedElementList);
                            this.rootScopeService.disableCustomFieldSequence = true;
                        }
                    }
                }
            }
            this.showSequenceOrderAndDisabledDiv(true);
            this.toggleCustomizeFieldSequenceOverlay(true);
            //Send CustomTabRetrieval message to get OperatorInfoMessage
            if (this.rootScopeService.callCustomTabRetrieval == true) {
                this.serverCommandService.sendProcessCommandMessage("CustomTabRetrieval");
            }
        } else {
            this.rootScopeService.showElementOrder = false;
            this.rootScopeService.customizeFieldSequenceFlag = false;
        }
    }

    setSliderForGlobal(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.formService.setSliderForGlobal();
            this.closeGearMenu();
        }
    }

    clearAllFormScale(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            let popupMessage: any = {};
            popupMessage.title = "Confirm";
            popupMessage.text = ["This will clear all saved form scaling data and reset all forms to their default sizes. Are you sure you wish to do this?"];
            popupMessage.buttons = [

                {
                    label: "Ok",
                    callback: () => {
                        this.promptService.popupMessageDismiss();
                        // Remove Local Storage
                        this.uiLocalStorageService.remove(Models.LocalStorageConstants.sliderScale);
                        this.processingService.closeProcessing();
                    },
                    SearchFocus: true
                },
                {
                    label: "Cancel",
                    callback: () => {
                        this.promptService.popupMessageDismiss();
                        this.processingService.closeProcessing();
                    },
                    SearchFocus: true
                },
            ];
            popupMessage.defaultCallbackNumber = 0;
            this.promptService.popupMessageShow(popupMessage);
            this.closeGearMenu();
        }
    }

    deleteRecord(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.rootScopeService.recordDeleteClicked = true;
            this.serverCommandService.sendProcessCommandMessage("DeleteRecord");
        }
    }

    formRecordDelete() {
        var formRecordDelete = false;
        var activeForm = this.formService.getLatestForm();
        if (!this.validationService.isNullOrEmpty(this.rootScopeService.preferences.RecordDeleteAllowed) &&
            this.formService.forms.length > 0 && !this.validationService.isNullOrEmpty(activeForm.formRecordDeleteAllowed)) {
            //Enable only if RecordDeleteAllowed is true in the preferences and individual form and form should be active
            if (this.rootScopeService.preferences.RecordDeleteAllowed && activeForm.formRecordDeleteAllowed == 'Y' && !this.formService.formDisabled) {
                formRecordDelete = true;
            }
        }

        return formRecordDelete;
    }

    closeGearMenu() {
        document.getElementById("gearDropdownDiv").classList.remove("open");
    }

    recordDeleteKeyDown(event: any) {
        if (event.keyCode == Models.KeyCodes.tab && !event.shiftKey) {
            this.closeGearMenu();
        }
    }

    clearAllFormScaleValuesKeyDown(event: any) {
        if (!this.formRecordDelete() && event.keyCode == Models.KeyCodes.tab && !event.shiftKey) {
            this.closeGearMenu();
        }
    }
}