/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { JsonProperty } from "@dhkatz/json-ts";


export class Field {

    constructor() {
        this.customElementOrder = undefined;
        this.elementCalc = undefined;
        this.elementCalculatorWidgetCount = undefined;
        this.elementColumn = undefined;
        this.elementConversion = undefined;
        this.elementDate = undefined;
        this.elementDateWidgetCount = undefined;
        this.elementDetailable = undefined;
        this.elementDisplayWidth = undefined;
        this.elementEcho = undefined;
        this.elementElementBack = undefined;
        this.elementElementForward = undefined;
        this.elementFieldBack = undefined;
        this.elementFieldForward = undefined;
        this.elementHelpLabel = undefined;
        this.elementID = undefined;
        this.elementInHeader = undefined;
        this.elementJustification = undefined;
        this.elementLookUpFlag = undefined;
        this.elementLookUpPrompt = undefined;
        this.elementOrder = undefined;
        this.elementProcessFlag = undefined;
        this.elementPrompts = undefined;
        this.elementReadOnly = undefined;
        this.elementRequired = undefined;
        this.elementRow = undefined;
        this.elementSkipTab = undefined;
        this.elementSpaceWidgetCount = undefined;
        this.elementStorageWidth = undefined;
        this.elementType = undefined;
        this.elementValCode = undefined;
        this.elementValcodeDisplay = undefined;
        this.elementValcodeWidgetCount = undefined;
        this.elementVarPromptFor = undefined;
        this.elementWindowController = undefined;
        this.fieldBuffer = undefined;
        this.hasWindowButton = undefined;
        this.inquiry = undefined;
        this.isEnabled = undefined;
        this.isFocusedDataChanged = undefined;
        this.isFormLabel = undefined;
        this.isGraphicField = undefined;
        this.isHeaderField = undefined;
        this.isInputField = undefined;
        this.isInquiryField = undefined;
        this.isPhantomField = undefined;
        this.isValcodeField = undefined;
        this.isVariableLabel = undefined;
        this.lookUpField = undefined;
        this.originalElementID = undefined;
        this.originalValue = undefined;
        this.required = undefined;
        this.sanitizedID = undefined;
        this.screenReaderText = undefined;
        this.updatedValue = undefined;
        this.valCodeSanitizedID = undefined;
        this.values = undefined;
        this.windowControllerSanitized = undefined;
        this.text = undefined;
        this.originalText = undefined;
        this.focusedText = undefined;
        this.unfocusedText = undefined;
    }


    @JsonProperty('customElementOrder')
    customElementOrder: number;

    @JsonProperty('elementCalc')
    elementCalc: boolean;

    @JsonProperty('elementCalculatorWidgetCount')
    elementCalculatorWidgetCount: number;

    @JsonProperty('elementColumn')
    elementColumn: number;

    @JsonProperty('elementConversion')
    elementConversion: string;

    @JsonProperty('elementDate')
    elementDate: boolean;

    @JsonProperty('elementDateWidgetCount')
    elementDateWidgetCount: number;

    @JsonProperty('elementDetailWidgetCount')
    elementDetailWidgetCount: number;

    @JsonProperty('elementDetailable')
    elementDetailable: boolean;

    @JsonProperty('elementDisplayWidth')
    elementDisplayWidth: number;

    @JsonProperty('elementEcho')
    elementEcho: string;

    @JsonProperty('elementElementBack')
    elementElementBack: string;

    @JsonProperty('elementElementForward')
    elementElementForward: string;

    @JsonProperty('elementFieldBack')
    elementFieldBack: string;

    @JsonProperty('elementFieldForward')
    elementFieldForward: string;

    @JsonProperty('elementHelpLabel')
    elementHelpLabel: string;

    @JsonProperty('elementID')
    elementID: string;

    @JsonProperty('elementInHeader')
    elementInHeader: string;

    @JsonProperty('elementJustification')
    elementJustification: string;

    @JsonProperty('elementLookUpFlag')
    elementLookUpFlag: string;

    @JsonProperty('elementLookUpPrompt')
    elementLookUpPrompt: string;

    @JsonProperty('elementOrder')
    elementOrder: number;

    @JsonProperty('elementProcessFlag')
    elementProcessFlag: string;

    @JsonProperty('elementPrompts')
    elementPrompts: string;

    @JsonProperty('elementReadOnly')
    elementReadOnly: string;

    @JsonProperty('elementRequired')
    elementRequired: string;

    @JsonProperty('elementRow')
    elementRow: number;

    @JsonProperty('elementSkipTab')
    elementSkipTab: number;

    @JsonProperty('elementSpaceWidgetCount')
    elementSpaceWidgetCount: number;

    @JsonProperty('elementStorageWidth')
    elementStorageWidth: number;

    @JsonProperty('elementType')
    elementType: string;

    @JsonProperty('elementValCode')
    elementValCode: string;

    @JsonProperty('elementValcodeDisplay')
    elementValcodeDisplay: string;

    @JsonProperty('elementValcodeWidgetCount')
    elementValcodeWidgetCount: number;

    @JsonProperty('elementVarPromptFor')
    elementVarPromptFor: string;

    @JsonProperty('elementWindowController')
    elementWindowController: string;

    @JsonProperty('fieldBuffer')
    fieldBuffer: number;

    @JsonProperty('focusedText')
    focusedText: string;

    @JsonProperty('hasWindowButton')
    hasWindowButton: boolean;

    @JsonProperty('inquiry')
    inquiry: boolean;

    @JsonProperty('isEnabled')
    isEnabled: boolean;

    @JsonProperty('isFocusedDataChanged')
    isFocusedDataChanged: boolean;

    @JsonProperty('isFormLabel')
    isFormLabel: boolean;

    @JsonProperty('isGraphicField')
    isGraphicField: boolean;

    @JsonProperty('isHeaderField')
    isHeaderField: boolean;

    @JsonProperty('isInputField')
    isInputField: boolean;

    @JsonProperty('isInquiryField')
    isInquiryField: boolean;

    @JsonProperty('isPhantomField')
    isPhantomField: boolean;

    @JsonProperty('isValcodeField')
    isValcodeField: boolean;

    @JsonProperty('isVariableLabel')
    isVariableLabel: boolean;

    @JsonProperty('lookUpField')
    lookUpField: boolean;

    @JsonProperty('originalElementID')
    originalElementID: string;

    @JsonProperty('originalText')
    originalText: string;

    @JsonProperty('originalValue')
    originalValue: string;

    @JsonProperty('required')
    required: boolean;

    @JsonProperty('sanitizedID')
    sanitizedID: string;

    @JsonProperty('screenReaderText')
    screenReaderText: string;

    @JsonProperty('text')
    text: string;

    @JsonProperty('unfocusedText')
    unfocusedText: string;

    @JsonProperty('updatedValue')
    updatedValue: string;

    @JsonProperty('valCodeSanitizedID')
    valCodeSanitizedID: string;

    @JsonProperty({type: String, name: 'values'})
    values: string[];

    @JsonProperty('windowControllerSanitized')
    windowControllerSanitized: string;
}