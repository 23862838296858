/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Component, Inject } from '@angular/core';
import { BarGraphService, UiLocalStorageService, FocusService, PreferencesService } from "../../../services"
import * as Models from "../../../models";

@Component({
    selector: 'bargraph',
    template: require('./barGraph.component.html')
})
export class BarGraphComponent {
    constructor(private barGraphService: BarGraphService, private uiLocalStorageService: UiLocalStorageService, private focusService: FocusService, private preferencesService: PreferencesService) {
    }

    /**
     * Toggle Auto Close
     * @param newValue boolean
     */
    toggleAutoClose(newValue: boolean) {
        //set service autoClose 
        this.barGraphService.autoClose = newValue;
        // Reset this flag in preferences service as well
        this.preferencesService.dialogPreferences.CloseBarGraph = newValue;
        // set the new value in local storage so that it can be used by preferences as well
        this.uiLocalStorageService.set(Models.LocalStorageConstants.checked, newValue == true ? "Y" : "N");
    }

    /**
     * Tab on Finish button should focus on Checkbox to close bar graph automatically
     * @param event
     */
    finishBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        if (event.keyCode == Models.KeyCodes.f8 || event.keyCode == Models.KeyCodes.f9) { // F8 or F9 close bar graph dialog
            this.barGraphService.closeBarGraph(true);
        }
        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("close-bar-graph-btn", true);
        }
    }

    /**
     * Tab or Shift+tab on close bar graph checkbox should focus on Finish button in footer
     * @param event
     */
    closeBarGraphBtnKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        if (event.keyCode == Models.KeyCodes.f8 || event.keyCode == Models.KeyCodes.f9) { // F8 or F9 close bar graph dialog
            this.barGraphService.closeBarGraph(true);
        }
        if (tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("finish-btn", true);
        }
    }
    Keydown(event: any) {
        if (event.keyCode == Models.KeyCodes.f8 || event.keyCode == Models.KeyCodes.f9) { // F8 or F9 close bar graph dialog
            this.barGraphService.closeBarGraph(true);
        }
    }
}