/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Inject, Injectable } from "@angular/core";
import * as Models from "../models";
import { ValidationService, ProcessingService, EventsService, PromptService, RootScopeService } from "./";

declare var promptReloadColleagueUI: any;

@Injectable()
export class ServerCommandService {
    doNotRemindForSlowness: boolean = false;

    constructor(private eventsService: EventsService,
        private validationService: ValidationService, private processingService: ProcessingService,
        private promptService: PromptService, private rootScopeService: RootScopeService) {
        this.eventsService.on("sendKeepAliveCommand", [this.sendProcessCommandMessageListener, this]);
    }

    sendProcessCommandMessageListener(service: any, command: any) {
        let self = <ServerCommandService>service;
        self.sendFieldNavigationMessageList([Models.CommandConstants.fieldJump, Models.CommandConstants.emptyString], ["random.keep.alive", ""], ["random.keep.alive", ""], ["random.keep.alive", ""], ["random.keep.alive", ""], ["random.keep.alive", ""]);
    }

    sendProcessCommandMessage(command: any) {
        this.showWaitingDialog(true, [command]);
        let clientInputMessage: any = new Models.ClientInputMessage();
        clientInputMessage["_i:type"] = "ClientInputMessage";
        clientInputMessage.Command = command;
        this.eventsService.broadcast(Models.EventConstants.sendServerMessage, clientInputMessage);
    }

    sendProcessCommandWithDataMessage(command: any, fieldName: any, fieldData: any, doNotProcessQuote: boolean = false) {
        this.showWaitingDialog(true, [command]);

        let clientInputMessage: any = new Models.ClientInputMessage();
        clientInputMessage["_i:type"] = "ClientInputMessage";
        clientInputMessage.Command = command;

        if (!this.validationService.isNullOrEmpty(fieldName)) {
            clientInputMessage.CurrentFieldName = fieldName;
        }
        if (!this.validationService.isNullOrEmpty(fieldData)) {
            clientInputMessage.FieldData = fieldData;
        }

        if (doNotProcessQuote) {
            clientInputMessage.DoNotReplaceSingleQuote = true;
        }

        this.eventsService.broadcast(Models.EventConstants.sendServerMessage, clientInputMessage);
    }

    sendFieldNavigationMessage(command: any, currentField: any, currentFieldData: any, jumpField: any, currentWindowRow: any, windowJumpIndex: any) {
        if (command == Models.CommandConstants.launchForm) {
            this.rootScopeService.ignoreFieldSecurities = false;
            this.rootScopeService.ignoreFieldDisplays = false;
        }

        if (promptReloadColleagueUI == true && command.toUpperCase() == "LAUNCHFORM") {
            let result = this.promptReloadColleagueUIModal(command, currentField, currentFieldData, jumpField, currentWindowRow, windowJumpIndex);

            if (result == false) return;
        }

        if (command == Models.CommandConstants.ignoreFieldJump) return;

        this.showWaitingDialog(true, [command]);

        let clientInputMessage: any = new Models.ClientInputMessage();
        clientInputMessage["_i:type"] = "ClientInputMessage";
        clientInputMessage.Command = command;


        if (!this.validationService.isNullOrEmpty(currentField)) {
            clientInputMessage.CurrentFieldName = currentField;
        }
        if (!this.validationService.isNullOrEmpty(currentFieldData)) {
            clientInputMessage.FieldData = currentFieldData;
        }
        if (!this.validationService.isNullOrEmpty(jumpField)) {
            clientInputMessage.FieldJumpName = jumpField;
        }
        if (!this.validationService.isNullOrEmpty(currentWindowRow)) {
            clientInputMessage.CurrentWindowRow = currentWindowRow;
        }
        if (!this.validationService.isNullOrEmpty(windowJumpIndex)) {
            clientInputMessage.WindowJumpIndex = windowJumpIndex;
        }

        this.eventsService.broadcast(Models.EventConstants.sendServerMessage, clientInputMessage);
    }

    sendFieldNavigationMessageList(commandList: any, currentFieldList: any, currentFieldDataList: any, jumpFieldList: any, currentWindowRowList: any, windowJumpIndexList: any) {
        let clientInputMessage = [];
        this.showWaitingDialog(true, commandList);

        for (let i = 0; i < commandList.length; i++) {
            let msg: any = new Models.ClientInputMessage();
            msg["_i:type"] = "ClientInputMessage";

            if (!this.validationService.isNullOrEmpty(commandList[i])) {
                msg.Command = commandList[i];
                // if ignorefield jump is set ignore field jump command as app server is already aware of and won't like if required field validation is prompted.
                if (msg.Command == Models.CommandConstants.ignoreFieldJump) continue;
            }
            if (!this.validationService.isNullOrEmpty(currentFieldList[i])) {
                msg.CurrentFieldName = currentFieldList[i];
            }

            if (!this.validationService.isNullOrEmpty(currentFieldDataList[i])) {
                msg.FieldData = currentFieldDataList[i];
            }

            if (!this.validationService.isNullOrEmpty(jumpFieldList[i])) {
                msg.FieldJumpName = jumpFieldList[i];
            }

            if (!this.validationService.isNullOrEmpty(currentWindowRowList[i])) {
                msg.CurrentWindowRow = currentWindowRowList[i];
            }

            if (!this.validationService.isNullOrEmpty(windowJumpIndexList[i])) {
                msg.WindowJumpIndex = windowJumpIndexList[i];
            }

            clientInputMessage.push(msg);
        }
        this.eventsService.broadcast(Models.EventConstants.sendServerMessage, clientInputMessage);
    }

    /**
     * Loads Modal to inform user for slowness.
     */
    promptReloadColleagueUIModal(command: any, currentField: any, currentFieldData: any, jumpField: any, currentWindowRow: any, windowJumpIndex: any) {
        if (this.doNotRemindForSlowness == false && !this.rootScopeService.isFormOpen && this.rootScopeService.performanceCounter > this.rootScopeService.threshold) {
            let popupMessage: any = {};
            popupMessage.identifier = "reloadColleagueUIModal";
            popupMessage.title = "Refresh Browser Session";
            popupMessage.text = ["If Browser seems to be slowing down, click Reload UI Now, or use the Refresh Browser Session on the UI Options menu."];
            popupMessage.defaultCallbackNumber = 1;
            popupMessage.buttons = [{
                label: "Reload UI Now",
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        this.eventsService.broadcast("BackupSession");
                    }, false);
                },
                title: ""
            },
            {
                label: "Remind Later",
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        this.rootScopeService.threshold = this.rootScopeService.performanceCounter * 1.10;
                        this.sendFieldNavigationMessage(command, currentField, currentFieldData, jumpField, currentWindowRow, windowJumpIndex);
                    }, false);
                },
                title: ""
            },
            {
                label: "Do Not Remind Again",
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        this.doNotRemindForSlowness = true;
                        this.sendFieldNavigationMessage(command, currentField, currentFieldData, jumpField, currentWindowRow, windowJumpIndex);
                    }, false);
                },
                title: ""
            }];
            this.promptService.popupMessageShow(popupMessage, true);
            return false;
        }
        else {
            return true;
        }
    }

    private showWaitingDialog(conditional: boolean, commandList: any) {
        let alertArgs: any = {
            id: Models.PromptMessageConstants.alertId,
            message: "Processing ...",
            alertTitle: "Please Wait ...",
            event: commandList
        };

        if (conditional == true) {
            for (let i = 0; i < commandList.length; i++) {
                if (commandList[i].toUpperCase() == "PROCESSUPDATE" || commandList[i].toUpperCase() == "PROCESSFINISH" || commandList[i].toUpperCase() == "EXCELEXPORT" ||
                    commandList[i].toUpperCase() == "CANCELALLFORMS" || commandList[i].toUpperCase() == "SAVEALLFORMS" || commandList[i].toUpperCase() == "SCREENCANCEL" ||
                    commandList[i].toUpperCase() == "LAUNCHFORM" || commandList[i].toUpperCase() == "WINDOWROWBUTTON") {
                    this.processingService.showProcessing(commandList);
                }
            }
        } else {
            this.processingService.showProcessing(commandList);
        }
    }
}
