/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { enableProdMode, forwardRef, NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { Routes } from "@angular/router";
import { AngularMyDatePickerModule } from "angular-mydatepicker";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

require('../Content/bootstrap/bootstrap.less');

import {
    EventsService,
    FormService,
    HelperService,
    LoggerService,
    RootScopeService,
    SearchResultsService,
    WindowControlService,
    HelpService,
    CommentDialogService,
    BarGraphService,
    ReportBrowserService,
    SubMenuDialogService,
    PromptService,
    FileService,
    RendererService,
    NavigateService,
    SearchService,
    ConfigurationService,
    ParserService,
    ValidationService,
    MessageListenerService,
    ContextManagerService,
    ContextHeaderService,
    MessageHandlerService,
    QueueService,
    ContextService,
    AboutService,
    PrivacyPolicyService,
    ImageService,
    ServerCommandService,
    UiLocalStorageService,
    SessionStorage,
    PreferencesService,
    FocusService,
    AnalyticsService,
    InstrumentationService,
    SessionService,
    LoginService,
    ProcessingService,
    ChangePasswordService,
    FavoritesService,
    AttachService,
    DateFormatService
} from "./services";
import {
    CalculatorComponent,
    CalendarComponent,
    CardComponent,
    FormComponent,
    GridComponent,
    SearchResultShellComponent,
    TextComponent,
    WindowControlComponent,
    FormGridComponent,
    CommentDialogComponent,
    FormSearchResultShellComponent,
    BarGraphComponent,
    ReportBrowserComponent,
    SubMenuDialogComponent,
    PromptComponent,
    FileUploadComponent,
    FileDownloadComponent,
    FormSearchHistoryComponent,
    PersonSearchHistoryComponent,
    AdvancedSearchComponent,
    NavbarSearchComponent,
    NavigateModalComponent,
    NavigateMenuComponent,
    ConfigurationComponent,
    ContextManagerCardComponent,
    ContextManagerComponent,
    ContextHeaderComponent,
    AboutComponent,
    PrivacyPolicyComponent,
    PreferencesComponent,
    SidebarComponent,
    LoginComponent,
    DatabaseLoginComponent,
    ProcessingComponent,
    NavbarRightComponent,
    InterfaceComponent,
    ChangePasswordComponent,
    CustomFieldSequenceComponent,
    FavoritesFormComponent,
    FavoritesPersonComponent,
    FavoritesComponent,
    AddToFavoritesComponent,
    CreateNewFolderComponent,
    CreateSharedListComponent,
    FolderCreateSuccessComponent,
    RecentlySharedListsComponent,
    SharedListSearchComponent,
    ExternalLinkComponent,
    AttachComponent,
} from "./components";
import {
    Draggable
} from "./directives";
import { KeysPipe } from "./pipes/pipe";

const routes: Routes = [
    {
        path: "",
        redirectTo: "",
        pathMatch: "full"
    }
];

@NgModule({
    declarations: [
        TextComponent,
        CalculatorComponent,
        CalendarComponent,
        WindowControlComponent,
        Draggable,
        KeysPipe,
        FormComponent,
        GridComponent,
        CardComponent,
        SearchResultShellComponent,
        CommentDialogComponent,
        FormGridComponent,
        FormSearchResultShellComponent,
        BarGraphComponent,
        ReportBrowserComponent,
        SubMenuDialogComponent,
        PromptComponent,
        FileUploadComponent,
        FileDownloadComponent,
        FormSearchHistoryComponent,
        PersonSearchHistoryComponent,
        AdvancedSearchComponent,
        NavbarSearchComponent,
        NavigateModalComponent,
        NavigateMenuComponent,
        ConfigurationComponent,
        ContextHeaderComponent,
        ContextManagerCardComponent,
        ContextManagerComponent,
        AboutComponent,
        PrivacyPolicyComponent,
        PreferencesComponent,
        SidebarComponent,
        ChangePasswordComponent,
        LoginComponent,
        DatabaseLoginComponent,
        InterfaceComponent,
        NavbarRightComponent,
        ProcessingComponent,
        CustomFieldSequenceComponent,
        FavoritesFormComponent,
        FavoritesPersonComponent,
        FavoritesComponent,
        AddToFavoritesComponent,
        CreateNewFolderComponent,
        CreateSharedListComponent,
        FolderCreateSuccessComponent,
        RecentlySharedListsComponent,
        SharedListSearchComponent,
        ExternalLinkComponent,
        AttachComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AngularMyDatePickerModule,
        NgbModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot()
    ],
    providers: [
        EventsService,
        HelperService,
        LoggerService,
        Title,
        RootScopeService,
        ValidationService,
        ParserService,
        FormService,
        WindowControlService,
        SearchResultsService,
        HelpService,
        SearchService,
        MessageListenerService,
        CommentDialogService,
        BarGraphService,
        ReportBrowserService,
        SubMenuDialogService,
        PromptService,
        FileService,
        RendererService,
        NavigateService,
        ConfigurationService,
        ContextManagerService,
        ContextHeaderService,
        MessageHandlerService,
        QueueService,
        ContextService,
        AboutService,
        PrivacyPolicyService,
        ImageService,
        ServerCommandService,
        UiLocalStorageService,
        SessionStorage,
        PreferencesService,
        FocusService,
        AnalyticsService,
        InstrumentationService,
        SessionService,
        LoginService,
        ProcessingService,
        ChangePasswordService,
        FavoritesService,
        AttachService,
        DateFormatService
    ],
    exports: [],
    bootstrap: [InterfaceComponent]
})

export class AppModule {
}

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule);
