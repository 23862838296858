/**
 * Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
 */

export class LabelConstants {
    static ok: string = "OK";
}

export class ActionConstants {
    static Add: string = "ADD";
    static Modify: string = "MODIFY";
    static Delete: string = "DELETE";
}

export class EventConstants {
    static openAlert: string = "openAlert";
    static closeAlert: string = "closeAlert";
    static launchCalculator: string = "launchCalculator";
    static closeCalculator: string = "closeCalculator";
    static closeValcode: string = "closeValcode";
    static launchCalendar: string = "launchCalendar";
    static closeCalendar: string = "closeCalendar";
    static fieldPromptMessage: string = "FieldPromptMessage";
    static initializeGraphMessage: string = "InitializeGraphMessage";
    static graphTextMessage: string = "GraphTextMessage";
    static updateGraphMessage: string = "UpdateGraphMessage";
    static finishGraphMessage: string = "FinishGraphMessage";
    static reportPageMessage: string = "ReportPageMessage";
    static enableForm: string = "EnableFormEvent";
    static changeCurrentFieldListener: string = "changeCurrentFieldListener";
    static elementOrderSelectedListener: string = "elementOrderSelectedListener";
    static refreshFooterMessage: string = "RefreshFooterMessage";
    static clearCalendarDate: string = "ClearCalendarDate";
    static calendarKeyUp: string = "CalendarKeyUp";
    static activeWindow: string = "ActiveWindow";
    static fieldCompleteMessage: string = "FieldCompleteMessage";
    static fieldDisplayMessage: string = "FieldDisplayMessage";
    static formSpecsMessage: string = "FormSpecsMessage";
    static saveAllForms: string = "saveAllForms";
    static saveCurrentForm: string = "saveCurrentForm";
    static cancelAllForms: string = "cancelAllForms";
    static cancelCurrentForm: string = "cancelCurrentForm";
    static openForm: string = "openForm";
    static fieldSecurityMessage: string = "FieldSecurityMessage";
    static closeLeftSidebar: string = "CloseleftSidebar";
    static closeSearchPopover: string = "closeSearchPopover";
    static openPrompt: string = "openPrompt";
    static closePrompt: string = "closePrompt";
    static fileUploadProgressMessage: string = "FileUploadProgressMessage";
    static fileUploadMessage: string = "FileUploadMessage";
    static fileDownloadMessage: string = "FileDownloadMessage";
    static preloadData: string = 'preloadData';
    static postLogin: string = "loginComplete";
    static openNavigate: string = "OpenNavigate";
    static configurationLoaded: string = "configurationLoaded";
    static sessionCloseMessage: string = "SessionCloseMessage";
    static logoutComplete: string = "logoutComplete";
    static sendServerMessage: string = "sendServerMessage";
    static usage: string = "usage";
    static cancelCustomizeFieldSequence: string = "cancelCustomizeFieldSequence";
    static favoritesLoaded: string = "favoritesLoaded";
    static activeFormUpdated: string = "activeFormUpdated";
}

export class CommandConstants {
    static ignoreFieldJump: string = "IgnoreFieldJump";
    static arrowDown: string = "ArrowDown";
    static arrowUp: string = "ArrowUp";
    static elementForward: string = "ElementForward";
    static fieldBack: string = "FieldBack";
    static fieldForward: string = "FieldForward";
    static fieldJump: string = "FieldJump";
    static fieldUpdate: string = "FieldUpdate";
    static openCalculator: string = "OpenCalculator";
    static openCalendar: string = "OpenCalendar";
    static closeCalendar: string = "CloseCalendar";
    static openValcode: string = "OpenValcode";
    static fieldDelete: string = "FieldDelete";
    static fieldDetail: string = "Detail";
    static firstPage: string = "FirstPage";
    static lastPage: string = "LastPage";
    static nextPage: string = "NextPage";
    static prevPage: string = "PrevPage";
    static windowJump: string = "WindowJump";
    static windowRowButton: string = "WindowRowButton";
    static excelExport: string = "ExcelExport";
    static cancelAllForms: string = "CancelAllForms";
    static screenCancel: string = "ScreenCancel";
    static saveAllForms: string = "SaveAllForms";
    static processUpdate: string = "ProcessUpdate";
    static processFinish: string = "ProcessFinish";
    static launchForm: string = "LaunchForm";
    static previousForm: string = "PreviousForm";
    static nextForm: string = "NextForm";
    static updateSequenceOrderForElement: string = "UpdateSequenceOrderForElement";
    static windowInsert: string = "WindowInsert";
    static loadPersonContextData: string = "loadPersonContextData";
    static emptyString: string = "EmptyString";
    static searchResultsExport: string = "SearchResultsExport";
    static downloadFile: string = "DownloadFile";
    static updateFormMatchItems: string = "UpdateFormMatchItems";
    static updatePersonMatchItems: string = "UpdatePersonMatchItems";
    static closeSelected: string = "CloseSelected";
    static customFormSpecs: string = "CustomFormSpecsMessage";
    static customTabUpdate: string = "CustomTabUpdate";
    static addToHistory: string = "addToHistory";
    static preloadData: string = "preloadData";
    static printForm: string = "PrintForm";
    static recordDelete: string = "RecordDelete";
    static disableCustomFieldSequenceClick: string = "disableCustomFieldSequenceClick";
    static showFieldSequenceClick: string = "showFieldSequenceClick";
    static openExternalLink: string = "openExternalLink";
}

export class PromptMessageConstants {
    static alertId: string = "searchInProgress";
    static okPromptButton: string = "OK";
    static cancelPromptButton: string = "Cancel";
    static externalError: string = "Favorites failed. Please try again later.";
    static renameFoldermessage1: string = "A folder/item named ";
    static renameFoldermessage2: string = " already exists at this level.";
    static folderLengthValidation: string = "The length of a folder name may not exceed 40 characters.";
    static folderExistsPart1: string = "A folder/item named";
    static folderExistsPart2: string = "already exists at this level.";
    static favoriteErrorMessageText: string = "Please select at least one form or person item to open.";
    static favoriteErrorMessageTitle: string = "Open Favorites Error";
    static emptyFolderSharedList: string = 'The selected folder(s) contain no items.';
    static emptySharedList: string = "Please select one or more items and/or folders to share.";
    static favoriteEmptySelectionRename: string = "Please select an item or folder to rename.";
    static favoriteMultipleSelectionRename: string = "Please select a single item or folder to rename.";
    static favoriteRemoveMultipleSelection: string = "Please select one or more items and or folders to remove";
    static removeFavoritesError: string = "Remove Favorites";
    static favoriteRemoveSinleSelection: string = "Please select one item or folder to remove.";
    static favoriteValidationMessageNoSelection: string = "Please select an item or folder to rename.";
    static favoriteValidationMessageMultiSelection: string = "Please select a single item or folder to rename.";
    static DesktopEmailClient: string = "Desktop";
    static WebBasedEmailClient: string = "Web Based";
}

export class ElementType {
    static valCode: string = "valCode";
    static inquiry: string = "inquiry";
    static label: string = "label";
    static graphic: string = "graphic";
    static maint: string = "maint";
    static headerField: string = "headerField";
    static phantom: string = "phantom";
    static varPrompt: string = "varPrompt";
    static list: string = "List";
    static boolean: string = "boolean";
}

export class ElementAlign {
    static left: string = "left";
    static right: string = "right";
    static center: string = "center";
}

export class ElementJustification {
    static right: string = "R"
    static left: string = "L";
}

export class WindowOrientation {
    static horizontal: string = "horizontal";
    static Vertical: string = "vertical";
}

export class UI {
    static version: string = "5.20";
}

export class KeyCodes {
    static b: number = 66;
    static c: number = 67;
    static comma: number = 188;
    static d: number = 68;
    static dash: number = 189;
    static dot: number = 190;
    static down: number = 40;
    static e: number = 69;
    static end: number = 35;
    static enter: number = 13;
    static escape: number = 27;
    static f: number = 70;
    static f2: number = 113;
    static f8: number = 119;
    static f9: number = 120;
    static fwdSlash: number = 191;
    static firefoxDash: number = 173;
    static firefoxSemiColon: number = 59;
    static four: number = 52;
    static h: number = 72;
    static home: number = 36;
    static i: number = 73;
    static insert: number = 45;
    static left: number = 37;
    static p: number = 80;
    static pageUp: number = 33;
    static pageDown: number = 34;
    static right: number = 39;
    static semiColon: number = 186;
    static shift: number = 16;
    static singleQuote: number = 222;
    static space: number = 32;
    static tab: number = 9;
    static up: number = 38;
    static leftSquareBracket: number = 219;
    static rightSquareBracket: number = 221;
    static zero: number = 48;
}

export class HelperText {
    static person: string = "PERSON";
    static form: string = "FORM";
    static inquiry: string = "Inquiry ";
    static maintainable: string = "Maintainable ";
    static noAccess: string = "No Access ";
    static field: string = "field ";
    static elementDetailable: string = " is a detailable field";
    static lookUpField: string = " is a LookUp field";
    static valCodeField: string = " is a ValCode field";
    static dateField: string = " is a Date field";
    static calcField: string = " is a Calculator field";
    static compoundLabel: string = " and ";
    static required: string = " required";
    static whiteSpace: string = " ";
    static empty: string = "";
    static is: string = " is ";
    static val: string = "val";
    static readonly: string = "readonly";
    static elementPrompts: string = "elementPrompts";
    static zoom: string = "zoom";
    static openBrace: string = " { ";
    static closeBrace: string = " } ";
    static semiColon: string = ";";
    static colon: string = ":";
    static card: string = "CARD";
    static grid: string = "GRID";
    static process: string = "PROCESS";
    static top: string = "TOP";
    static left: string = "LEFT";
    static prompttextWithoutUnderscore: string = "PROMPTTEXT";
    static promptText: string = "PROMPTTEXT_";
    static formError: string = "Form Open Error , No form selected!";
    static selected: string = "selected";
    static windowController: string = "windowController";
    static formLabel: string = "FORMLABEL";
    static formGraphic: string = "FORMGRAPHIC";
    static elementID: string = "elementID";
    static values: string = "values";
    static y: string = "Y";
    static elementRow: string = "elementRow";
    static elementColumn: string = "elementColumn";
    static hash: string = "#";
    static favorites: string = "favorites";
    static backSlash: string = "/";
    static Errors: string = "Errors";
    static Item: string = "ITEM";
    static Share: string = "share";
    static Folder: string = "FOLDER";
    static update: string = "update";
    static tempName: string = "tempName";
    static folderLabel: string = "Form Favorites";
    static Highlight: string = "highlight";
    static Rename: string = "rename";
    static Expanded: string = "expanded";
    static addToContext: string = "addToContext";
    static processDescription: string = "PROCESS.DESCRIPTION";
    static processApplication: string = "PROCESS.APPLICATION";
    static ID: string = "@ID";
    static ItemValue: string = "ItemValue";
}

export class FormSlide {
    static sliderDefaultValue: number = 100;
    static globalSliderValue: string = "GlobalSettings";
    static sliderScale: string = "SliderScale";
    static plusToolTip: string = "Increase Form Scale";
    static minusToolTip: string = "Decrease Form Scale";
    static messageTitle: string = "Confirm";
    static clearAllConfirm: string = "This will clear all saved form scaling data and reset all forms to their default sizes. Are you sure you wish to do this?";
}

export class FieldSecurityConstants {
    static inquiry: string = "INQUIRY";
    static full: string = "FULL";
    static rtinquiry: string = "RT_INQUIRY";
    static addonly: string = "ADDONLY";
    static noaccess: string = "NOACCESS";
    static noDelete: string = "NODELETE";

}

export enum MonthsList {
    Jan = 1,
    Feb = 2,
    Mar = 3,
    Apr = 4,
    May = 5,
    Jun = 6,
    Jul = 7,
    Aug = 8,
    Sep = 9,
    Oct = 10,
    Nov = 11,
    Dec = 12
}

export class ToggleSearchCategory {
    static closePopover: string = 'ClosePopover';
    static toggleFormPopover: string = 'ToggleFormPopover';
    static togglePersonPopover: string = 'TogglePersonPopover';
    static togglePersonSearchResultPopover: string = 'TogglePersonSearchResultPopover';
    static ogglePersonAdvancedSearchPopover: string = 'TogglePersonAdvancedSearchPopover';
    static toggleFormSearchResultPopover: string = 'ToggleFormSearchResultPopover';
    static toggleNavigationPopover: string = 'ToggleNavigationPopover';
    static togglePersonAdvancedSearchPopover: string = "TogglePersonAdvancedSearchPopover";
    static search: string = 'Search';
    static openSelectedItems: string = 'OpenSelectedItems';
    static openForm: string = 'OpenForm';
    static showForm: string = 'ShowForm';
    static autocomplete: string = 'Autocomplete';
    static leftPersonSidebar: string = 'LeftPersonSidebar';
    static leftHelpSidebar: string = 'LeftHelpSidebar';
    static closeleftSidebar: string = 'CloseleftSidebar';
}

export class SearchResultConstants {
    static fa: string = "FA";
    static j: string = "J";
    static t: string = "T";
    static r: string = "R";
    static rd: string = "RD";
    static c: string = "C";
    static g: string = "G";
    static question: string = "?";
    static a: string = "A";
    static s: string = "S";
    static e: string = "E";
    static recordRangePromptMessageText: string = "Please select at least one record to submit in the range of 1 to ";
    static addNewRecordPromptMessageText: string = "Adding new records is not allowed during this query. Please access a form which allows it.";
    static favoritePromptMessageText: string = "Please select a form from the list below to add it to your Favorites.";
    static formPromptMessageText: string = "Please Select a form to execute";
}

export class LocalStorageConstants {
    static favoritesSharedList: string = "list";
    static checked: string = "CBG";
    static font: string = "FONT";
    static paper: string = "PAPER";
    static orientation: string = "ORIENTATION";
    static fontSize: string = "FONTSIZE";
    static formSearchHistory: string = "SGFH";
    static personSearchHistory: string = "SGPH";
    static searchResultsRenderType: string = "SRV";
    static sliderZoom: string = "zoom";
    static sliderScale: string = "SliderScale";
    static playSound: string = "PDNG";
    static verboseAccess: string = "UVBA";
    static reportBrowserPageView: string = "MRB";
    static navBarSearchType: string = "ST";
    static refreshBrowserSessionThreshold: string = "userThreshold";
    static contextClose: string = "contextClose";
    static navigateSelectedApplication: string = "nav";
    static userPreferredEmailClient: string = "userPreferredEmailClient";
}

export class ReportBrowserConstants {
    static defaultFontSize: number = 10.0;
    static defaultFont: string = "COURIER";
    static defaultPaper: string = "LETTER";
    static defaultOrientation: string = "PORTRAIT";
}

export class DialogContants {
    static help: string = "Help";
    static dialogMessage: string = 'Click on the menu item you wish to execute, and press "OK". To exit the menu press "CANCEL".';
    static staticStr: string = "static";
    static modalDrag: string = "modal-drag";
    static labelClose: string = "Close";
    static noNotifications: string = "No notifications have occurred";
    static PM: string = "PM";
    static AM: string = "AM";
    static messages: string = "Messages";
    static lastFiveNotifications: string = "Last 5 UI notifications";
    static UINotifications: string = "UI Notifications";
    static PreferredEmailClient: string = 'Select your preferred email client. To exit, click "Cancel".';
}

export class NavigateConstants {
    static currentNavApplication: string = "nav";
    static externalError: string = "Navigation failed. Please try again later.";
    static Mnemonic: string = "N";
    static MnemonicTitle: string = "No Results.";
    static ProcessClass: string = "N";
    static ProcessType: string = "N";
    static M: string = "M";
    static I: string = "I";
    static R: string = "R";
    static P: string = "P";
    static ToggleNavigationPopover: string = "ToggleNavigationPopover";
}

export class CustomFieldSequenceConstants {
    static sessionStoragePrefix: string = "customFieldSequence";
    static clearAllFieldsPopupTitle: string = "Remove Custom Field Properties";
    static clearAllFieldsPopupMessage: string = "Once field customizations are removed they cannot be restored. Are you sure you want to remove all customizations for this process for user";
    static changeCurrentFieldListener: string = "changeCurrentFieldListener";
    static customizeForUserDataReadyListener: string = "customizeForUserDataReadyListener";
    static customTabbingLoopPopupMessage: string = "The current tabbing configuration will not hit all Required fields and may contain a tabbing loop.";
    static updateCopyToOtherUsersCustomizedListener: string = "updateCopyToOtherUsersCustomizedListener";
    static elementOrderSelectedListener: string = "elementOrderSelectedListener";
}

export class ClientInputMessage {
    FieldData: any = '';
    Command: any = '';
    FieldJumpName: any = '';
    WindowJumpIndex: any = '';
    CurrentFieldName: any = '';
    CurrentWindowRow: any = '';
    DoNotReplaceSingleQuote: any = false;
}

export class LoginError {
    static checkConnection: string = "Log In failed: string =  Please check your Connection";
    static BadDatabaseRoleErrorCode: string = "15161";
    static UntrustedDomainErrorCode: string = "18452";
    static errorCode3: string = "80011";
    static InvalidConfigurationErrorCode: string = "Application server configuration invalid";
    static DataBasePasswordErrorCode: string = "UI_DAS_PSWD";
    static PasswordExpiredErrorCode: string = "80144";
    static loginTimeOutErrorCode: string = "81009";
    static tryAgain: string = "An error occurred during log in. Please try again.";
    static problemPreparingYourSession: string = "There was a problem preparing your session: string =  ";
    static BadDatabaseRoleErrorMessage: string = "Bad Database Role";
    static UntrustedDomainErrorMessage: string = "Untrusted Domain";
    static InvalidConfigurationErrorMessage: string = "Application server configuration invalid";
    static DataBasePasswordErrorMessage: string = "Database Password Differs from OS Password / Bad Database Password";
    static PasswordExpiredErrorMessage: string = "Password Expired";
    static loginTimeOutErrorMessage: string = "Unable to log in at this time. Please try again later (81009)"

}

export class RetrieveFavoritesRequest {
    Context: any = "";
    OperatorID: any = "";
}

export class ShareFavoritesRequest {
    Context = "";
    OperatorID = "";
    AffectedTypes = [];
    AffectedUIDs = [];
    ListName = "";
    OverwriteList = false;
}

export class ModifyFavoritesRequest {
    Context = "";
    OperatorID = "";
    Action = [];
    AffectedTypes = [];
    AffectedUIDs = [];
    AffectedRecordIDs = [];
    ModifiedLabels = [];
    ModifiedParents = [];
}

export class SharedListConstants {
    static recentlyCreatedbody: string = "No history is available.";
    static recentlyCreatedTitle: string = "Recently Created Shared Lists";
}

export class FavoriteConstants {
    static favoritesHeader: string = "Favorites";
    static favoritePersonPannel: string = "FAVORITE PEOPLE";
    static favoriteFormPannel: string = "FAVORITE FORMS";
    static formFavoriteHeaderTitle: string = "Add Active Form to Favorites";
    static personFavoriteHeaderTitle: string = "Add Active People to Favorites";
    static favoriteHeaderText: string = "Select one or multiple people. Select a form; or select both people AND a form.";
    static formAddtoFavoriteHeaderTitle: string = "Add Form to Favorites";
    static personAddtoFavoriteHeaderTitle: string = "Add Person to Favorites";
    static newFolderHeaderTitle: string = "Create New Folder";
    static personSuccessMessage: string = "People Added to Favorites";
    static formSuccessMessage: string = "Form Added to Favorites";
    static personHeaderText: string = "When adding multiple people use a folder to better organize them.";
    static favoriteMesssage: string = "The following items were not added because another entry with the same id already exists within the target folder:";
    static addFavError: string = "Add Favorites Error";
    static favoritesFolder: string = "Favorites Folder ";
    static created: string = " created.";
    static alreadyExistLabel: string = "already exists at this level.";
    static FolderNameLabel: string = "A Folder/Item named";
    static newFolderError: string = "Create New Folder Error";
    static favUpdateMessageOnFailure: string = "Failed to update favorites: string = ";
    static favoritesUpdateError: string = "Favorites Update Error";
    static updateSR: string = "updateSR";
    static enterOpenFormSR: string = 'enterOpenFormSR';
    static enterOpenPersonSR: string = 'enterOpenPersonSR';
    static formFavorite: string = "";
    static success: string = "Success";
    static folderNamed: string = "A Folder named";
    static alreadyExists: string = "already exists at this level.";
    static formName: string = 'Form Name';
    static peopleNames: string = 'People Names';
    static addtoFolder: string = 'Add to Folder';
    static newFolder: string = 'New Folder';
    static folderName: string = 'Folder Name';
    static createIn: string = 'Create In';
    static favoriteView: string = 'This favorites view is empty';
    static removeSelectedForm: string = 'Remove Selected Forms';
}

export enum DataType {
    Text = "TEXT",
    Link = "LINK",
    Email = "EMAIL",
}