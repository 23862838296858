/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component } from "@angular/core";
import {
    ConfigurationService,
    ContextService,
    FavoritesService,
    FocusService,
    HelpService,
    PromptService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    SessionService,
    ValidationService
} from "../../services";
import * as Models from "../../models";

declare var $: any;

@Component({
    selector: 'createnewfolder',
    template: require('./create-new-folder.component.html')
})
export class CreateNewFolderComponent {
    addToFavoritesDialog: string = "#add-to-favorites-dialog";
    createNewFolderDialog: string = "#create-new-folder-dialog";
    folderCreateSuccesDialog: string = "#folder-create-succes-dialog";
    btnCreateNewFolder: string = "#btn_create-new-folder";
    btnOpenCreateNewFolder: string = "#btn-open-create-new-folder";
    successMessage: string = "";
    enableCreate: boolean = false;

    constructor(private favoritesService: FavoritesService,
        private rootScopeService: RootScopeService,
        private validationService: ValidationService,
        private focusService: FocusService) {
        this.enableCreate = false;
    }

    closeCreateNewFolder() {
        $(this.createNewFolderDialog).modal('hide');
        $(this.folderCreateSuccesDialog).modal('hide');
        $(this.addToFavoritesDialog).modal('show');
        setTimeout(() => {
            let createNewFolderBtn = document.querySelector(this.addToFavoritesDialog + " " + this.btnOpenCreateNewFolder) as HTMLElement;
            createNewFolderBtn.focus();
        }, 100);
    }

    folderNameKeyUp(event: any) {
        if (event.keyCode == Models.KeyCodes.enter) {
            this.createNewFolder();
        }
        else if (!this.validationService.isNullOrEmpty(this.favoritesService.folderToCreate)) {
            this.enableCreate = true;
        }
    }

    /**
     * Creates new folder object if it does not already exist
     */
    createNewFolder() {
        if (!this.validationService.isNullOrEmpty(this.favoritesService.folderToCreate)) {
            // Create new favorite folder container
            this.successMessage = Models.FavoriteConstants.favoritesFolder + " '" + this.favoritesService.folderToCreate + "' " + Models.FavoriteConstants.created;
            let createFolderLabel: any = this.favoritesService.folderToCreate;
            let parentFolder: any = this.favoritesService.addData.selectedCreateInFolder;
            let newFolder = this.favoritesService.createFavoriteFolder(createFolderLabel, parentFolder);
            // Check if folder already exists at this level
            if (this.favoritesService.inFavorites(this.favoritesService.favorites[this.favoritesService.addData.type], newFolder) == true) {
                this.rootScopeService.createFolder = true;
                $(this.createNewFolderDialog).modal('hide');
                // It does, can't create duplicate folders
                let msg: any = [
                    Models.FavoriteConstants.FolderNameLabel,
                    createFolderLabel,
                    Models.FavoriteConstants.alreadyExistLabel
                ];
                //pop up to person select
                this.favoritesService.duplicateEntriesAlertMsg(Models.FavoriteConstants.newFolderError, msg);
                this.favoritesService.folderToCreate = "";
            } else {
                // Ok to create
                this.favoritesService.foldersToCommit.push(newFolder);
                // Insert it into the drop down list the favorites dialog is working of off
                let dropdownFolders: any = this.favoritesService.addData.folders;
                for (let i = 0; i < dropdownFolders.length; i++) {
                    if (dropdownFolders[i].FolderID === newFolder.ParentID) {
                        // Found the parent entry, scan forward to fine the last entry under this parent
                        let childLevel = dropdownFolders[i].Level + 1;
                        let foundAt = -1;
                        for (let j = i + 1; j < dropdownFolders.length; j++) {
                            if (dropdownFolders[j].Level < childLevel) {
                                foundAt = j;
                                break;
                            }
                        }

                        // We know where the new entry goes, generate the new entry
                        let newDropdownFolder: any = this.favoritesService.generateDisplayedFolderLabel(newFolder, childLevel);
                        // Now put it in the right place in the dropdown list
                        if (foundAt < 0) {
                            // New entry goes at the end
                            this.favoritesService.addData.folders.push(newDropdownFolder);
                        } else {
                            // New entry is inserted
                            this.favoritesService.addData.folders.splice(foundAt, 0, newDropdownFolder);
                        }
                        this.favoritesService.addData.selectedAddToFolder[this.favoritesService.addData.type] = newDropdownFolder;
                        break;
                    }
                }
                // this.closeCreateNewFolder();
                $(this.createNewFolderDialog).modal('hide');
                $(this.folderCreateSuccesDialog).modal('show');
                setTimeout(() => {
                    let createNewFolderBtn = document.querySelector(this.folderCreateSuccesDialog + " " + this.btnCreateNewFolder) as HTMLElement;
                    createNewFolderBtn.focus();
                }, 100);
            }
        }
    }

    /**
     * Tab on cancel button should focus on the close dialog button
     * @param event
     */
    btnCreateNewFolderCancelKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            this.focusService.focusOn("form-close-icon-cnfc", true);
        }
    }

    /**
     * Shift tab on close button should focus on Cancel button in the footer
     * @param event
     */
    btnCreateNewFolderCloseKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            this.focusService.focusOn("btnCreateNewFolderCancel", true);
        }
    }
}