/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Injectable} from "@angular/core";
import {
    FocusService,
    SearchService
} from "./";
import * as Models from "../models";

declare var $: any;

@Injectable()
export class PrivacyPolicyService {
    isOpen: boolean = false;

    constructor(private searchService: SearchService, private focusService: FocusService) {

    }

    display() {
        this.isOpen = true;
        $("#privacyPolicy .modal").modal({
            show: true,
            keyboard: true
        }).draggable();
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
    }

    close() {
        this.isOpen = false;

        setTimeout(() => {
            this.focusService.selectPreviousField();
        }, 100);
    }
}