/**
* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    ConfigurationService,
    EventsService,
    FocusService,
    HelperService,
    PromptService,
    RootScopeService,
    SessionService,
    LoginService,
    ValidationService
} from "./";
import * as Models from "../models";

declare let $: any;
import * as _ from 'lodash';

@Injectable()
export class FavoritesService {
    favorites: any = {};
    order: number = 1;
    folderLength: number = 0;
    selectedForm: any = {};
    uidPrefix: string;
    uidCounter: number = 0;
    selectedItemValues: any = [];
    noofEventOccurs: number = 0;
    renameFlag: any = { rename: false };
    nextSelectCounter: number = 0;
    isAllowFormRename: boolean = false;
    renameFormFolders: any = [];
    enterbtnClicked: number = 0;
    selectedPersonFavorites: number = 0;
    selectedPersonArray: any = [];
    addToFavoritesDialog: string = "#add-to-favorites-dialog";
    btnAddFavorite: string = "#btn_add-favorite-addEntries";
    createNewFolderDialog: string = "#create-new-folder-dialog";
    folderName: string = '#folderName';
    addData: any = {
        type: "",
        entries: [],
        folders: [],
        selectedAddToFolder: { PERSON: "", FORM: "'" },
        selectedCreateInFolder: ""
    };
    folderToCreate: string = "";
    listName: string = "";
    personArray: any = [];
    selectedPeople: any = [];
    sharedListOptions: any = [];
    foldersToCommit: any = [];
    entriesToCommit: any = [];
    formFavoritesButton: string = "#form-sr-favorites-btn";

    constructor(private rootScopeService: RootScopeService,
        private validationService: ValidationService,
        private notificationsService: PromptService,
        private focusService: FocusService,
        private pubSubService: EventsService,
        private helperService: HelperService,
        private configurationService: ConfigurationService,
        private sessionService: SessionService,
        private loginService: LoginService,
        private http: HttpClient,
        private eventsService: EventsService) {
        this.favorites.FORM = {};
        this.favorites.PERSON = {};
        this.selectedForm.form = {};
        this.selectedForm.isSelection = false;
        while (this.selectedItemValues.length > 0) {
            this.selectedItemValues.pop();
        }
        this.nextSelectCounter = 0;
        this.uidPrefix = "UI";
        this.uidCounter = 0;
        this.noofEventOccurs = 0;
    }

    showFavorites() {
        this.order = 1;
        this.sortPersonsFavorites(this.favorites.PERSON);
        this.setSequenceNumber(this.favorites.PERSON);
        this.sortFormsFavorites(this.favorites.FORM);
        this.setSequenceNumber(this.favorites.FORM);

        this.rootScopeService.FavActiveHelp = true;
        this.rootScopeService.favoritePanelOpen = true;

        $("#favorites .modal").modal({ show: true, keyboard: false });
        setTimeout(() => {
            $(".auto-focus-element>li>.favorite-list>li:first-child").trigger("focus");
            // When the favorites dialog is opened, the focus should either go to the Add Favorites Icon or the Favorites Help button in the header.
            this.pubSubService.broadcast("favoritesInitialFocus");
        }, 100);

        this.alignTree($(".outer-tree-wrap>favoritesperson>li>.favorite-list"), 0, "favoritesperson");
        this.alignTree($(".outer-tree-wrap>favoritesform>li>.favorite-list"), 0, "favoritesform");
    }

    private alignTree(elm: any, level: number, type: string) {
        let offset = 17;
        let temp = level + offset;
        _.each(elm[0].children, (e: any) => {
            if (e.localName != type) {
                $(e).css("padding-left", temp + "px");
            }
            else if (e.localName == type) {
                this.alignTree($(e).find(">li>.favorite-list"), temp, type);
            }
        });
    }

    /**
     * Closes the favorites dialog
     */
    closeFavorites() {
        if ($("#favorites-dialog").modal().length > 0 && $("#favorites-dialog").modal()[0].hidden == false) {
            $("#favorites-dialog").modal('hide');
        }
        this.rootScopeService.FavActiveHelp = false;
        this.rootScopeService.favoritePanelOpen = false;
        setTimeout(() => {
            this.focusService.selectPreviousField();
        }, 100);
    }

    clearSelectedForm() {
        this.selectedForm.form = {};
        this.selectedForm.isSelection = false;
    }

    createFavoriteForm(displayedForm: any, parentID: any) {
        return {
            ItemID: this.generateUid(),
            ItemLabel: displayedForm.ItemLabel,
            ItemValue: displayedForm.ItemID,
            ParentID: parentID
        }
    }

    createFavoritePerson(displayedPerson: any, parentID: any) {
        return {
            ItemID: this.generateUid(),
            ItemLabel: displayedPerson.ItemLabel,
            ItemValue: displayedPerson.ItemID,
            ParentID: parentID
        }
    }

    createFavoriteFolder(label: any, parent: any) { // creates favorites folder with label and parent folder
        return {
            CurrentItems: [],
            FolderID: this.generateUid(),
            FolderLabel: label,
            Folders: [],
            ParentID: parent.FolderID
        }
    }

    insertFavoriteObject(root: any, object: any): boolean { // inserts object into favorites according to object's ParentID
        if (this.isItem(object)) {
            // Inserting an item
            if (root.FolderID == object.ParentID) { // found the parent folder
                root.CurrentItems.push(object);
                return true;
            }
        } else {
            // Inserting a folder
            if (root.FolderID == object.ParentID) { // found the parent folder
                let isExist = false;
                //Check sub folders is exist or not
                for (let i = 0; i < root.Folders.length; i++) {
                    if (root.Folders[i].FolderID == object.FolderID) {
                        isExist = true;
                        break;
                    }
                }

                if (!isExist) root.Folders.push(object);
                return true;
            }
        }
        // Did not find proper parent, search recursively
        for (let x in root.Folders) {
            let insertItem = this.insertFavoriteObject(root.Folders[x], object);
            if (insertItem === true) {
                return true;
            }
        }
        return false;
    }

    duplicateEntriesAlertMsg(title: string, ErrorMessage: any, optionContextType: string = null) {
        let popupMessage: any = {};
        popupMessage.title = title;
        popupMessage.text = ErrorMessage;
        popupMessage.buttons = [
            {
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        if (this.rootScopeService.createFolder === true) {
                            $(this.createNewFolderDialog).modal('show');
                            setTimeout(() => {
                                document.getElementById("folderName").focus();
                            }, 500);
                        }
                        else if (this.rootScopeService.sharedListName === true) {
                            setTimeout(() => {
                                document.getElementById("sharedListName").focus();
                            }, 100);
                        }
                        else {
                            setTimeout(() => {
                                if ($("#shared-list-dialog").is(":visible")) {
                                    document.getElementById("recentlyCreatedSharedListLink").focus();
                                }
                                else if ($(this.formFavoritesButton).is(":visible")) {
                                    document.getElementById("form-sr-favorites-btn").focus();
                                }
                                else if (optionContextType) {
                                    if (optionContextType.toLowerCase() === "person")
                                        this.focusService.focusOn("#favorite-people-options-link");
                                    else if (optionContextType.toLowerCase() === "form")
                                        this.focusService.focusOn("#favorite-form-options-link");
                                    else
                                        this.focusService.focusOn("#open-favorites-btn");
                                }
                                else {
                                    this.focusService.focusPreviousField();
                                    this.focusService.selectPreviousField();
                                }
                            }, 100);
                        }
                        this.pubSubService.broadcast(Models.EventConstants.closeAlert);
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = "1";
        this.notificationsService.popupMessageShow(popupMessage);
    }

    inFavorites(root: any, object: any) {
        if (root.FolderID == object.ParentID) { // found the parent folder
            if (this.isItem(object)) {// object is an item
                for (let x in root.CurrentItems) {
                    if (root.CurrentItems[x].ItemLabel == object.ItemLabel) { // found the item
                        return true;
                    }
                }
            }
            else { // object is a folder
                for (let y in root.Folders) {
                    if (root.Folders[y].FolderLabel == object.FolderLabel) { // found the folder
                        return true;
                    }
                }
            }
        }
        else {
            for (let x in root.Folders) {
                let checkItem = this.inFavorites(root.Folders[x], object);
                if (checkItem === true) {
                    return true;
                }
            }
        }
        return false;
    }

    init() {
        this.initFavorite("FORM");
    }

    initFavorite(favoriteId: string) {
        let url = [this.configurationService.getConfiguration().serviceUrl, Models.HelperText.favorites, this.sessionService.getToken(), this.sessionService.getControlId()].join('/');
        let request = new Models.RetrieveFavoritesRequest();
        request.Context = favoriteId;
        request.OperatorID = this.sessionService.getOperatorId();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        this.http.post(url, request, httpOptions).timeoutWith(30000, Observable.throwError({
            type: 'LOAD',
            msg: 'Favorites request timed out.'
        })).subscribe(
            (response: any) => {
                if (response[Models.HelperText.Errors] && response.Errors.length > 0) {
                    // there was an error subset in the dmi response. Log it to console and halt the session
                    let errorMessages = '';
                    _.each(response.Errors, (it: any) => {
                        errorMessages += it.ErrorMessageText;
                    });
                    console.error(errorMessages);
                    let error: any = {};
                    error.type = 'LOAD';
                    error.msg = favoriteId + ' Favorites failed to load.';
                    this.eventsService.broadcast("stopPolling");
                    this.loginService.handleLoginErrors(error);
                } else {
                    this.favorites[favoriteId] = $.extend(true, {}, response.Favorites);
                    if (favoriteId == 'FORM') {
                        this.initFavorite("PERSON");
                    }
                    if (favoriteId === 'PERSON') {
                        this.pubSubService.broadcast(Models.EventConstants.favoritesLoaded);
                    }
                }
            }, (error: any) => {
                // an error occurred during the http request. log it to the console and halt the session
                console.error(error);
                error.name = 'LOAD';
                error.msg = 'An unexpected error occurred while loading Favorites.';
                this.eventsService.broadcast("stopPolling");
                this.loginService.handleLoginErrors(error);
            });
    }

    isItem(object: any): boolean {
        return _.isArray(object.CurrentItems) ? false : true;
    }

    createSharedList(personArray: any, listName: any, overwrite: boolean, successCallBack: any, errorCallBack: any, inst: any) {
        // overwrite true will delete the exisiting Shared List with the same listName and create a new Shared List filled with personArray
        let url: string = [this.configurationService.getConfiguration().serviceUrl, Models.HelperText.favorites, Models.HelperText.Share, this.sessionService.getToken(), this.sessionService.getControlId()].join('/');
        let request = new Models.ShareFavoritesRequest();
        request.Context = "PERSON";
        request.OperatorID = this.sessionService.getOperatorId();
        request.ListName = listName;
        if (overwrite) {
            request.OverwriteList = overwrite;
        }

        _.each(personArray, (person: any) => {
            request.AffectedTypes.push(Models.HelperText.Item);
            request.AffectedUIDs.push(person.ItemID);
        });
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        this.http.post(url, request, httpOptions).timeoutWith(30000, Observable.throwError(new Error("Timeout Occurred."))).subscribe(
            (response: any) => {
                if (response[Models.HelperText.Errors] && response.Errors.length > 0) {
                    let errorObject = {
                        ErrorCode: response.Errors[0].ErrorCode,
                        ErrorMessageText: response.Errors[0]
                    };
                    if (!this.validationService.isNullOrEmpty(errorCallBack))
                        errorCallBack(inst, errorObject);
                } else {
                    let listObject = {
                        listname: listName,
                        numItems: personArray.length,
                        date: new Date()
                    };
                    if (!this.validationService.isNullOrEmpty(successCallBack))
                        successCallBack(inst, listObject);
                }
            }, (error: any) => {
                let errorObject = {
                    ErrorCode: "",
                    ErrorMessageText: error
                };
                console.error(errorObject);
                if (!this.validationService.isNullOrEmpty(errorCallBack))
                    errorCallBack(inst, errorObject);
            });
    }

    /**
     * Add any new folders and people to person favorites
     * @param resolveObject
     * @param personFolders
     * @param personRecords
     * @param successCallBack
     * @param errorCallBack
     * @returns {undefined}
     */
    addPersonItems(resolveObject: any, personFolders: any, personRecords: any, successCallBack: any, errorCallBack: any, inst: any) {
        let request = new Models.ModifyFavoritesRequest();
        request.Context = "PERSON";
        request.OperatorID = this.sessionService.getOperatorId();

        // First add any person folder favorite creation requests
        _.each(personFolders, (folder: any) => {
            request.Action.push(Models.ActionConstants.Add);
            request.AffectedTypes.push(Models.HelperText.Folder);
            request.AffectedUIDs.push(folder.FolderID);
            request.AffectedRecordIDs.push("");
            request.ModifiedLabels.push(folder.FolderLabel);
            request.ModifiedParents.push(folder.ParentID);
        });

        // Then include any person record favorite creation requests
        _.each(personRecords, (person: any) => {
            request.Action.push(Models.ActionConstants.Add);
            // Person item being added to a folder
            request.AffectedTypes.push(Models.HelperText.Item);
            request.AffectedUIDs.push(person.ItemID);
            request.AffectedRecordIDs.push(person.ItemValue);
            request.ModifiedLabels.push(person.ItemLabel);
            request.ModifiedParents.push(person.ParentID);
        });

        // Finally send the update request, our caller will wait for a response
        return this.sendUpdateRequest(resolveObject, request, successCallBack, errorCallBack, inst);
    }

    /**
     * Add any new folders and forms to form favorites
     * @param resolveObject
     * @param formFolders
     * @param formNames
     * @param successCallBack
     * @param errorCallBack
     * @returns {any}
     */
    addFormItems(resolveObject: any, formFolders: any, formNames: any, successCallBack: any, errorCallBack: any, inst: any) {
        let request = new Models.ModifyFavoritesRequest();
        request.Context = "FORM";
        request.OperatorID = this.sessionService.getOperatorId();
        // First add any form folder favorite creation requests
        _.each(formFolders, (folder) => {
            request.Action.push(Models.ActionConstants.Add);
            request.AffectedTypes.push(Models.HelperText.Folder);
            request.AffectedUIDs.push(folder.FolderID);
            request.AffectedRecordIDs.push("");
            request.ModifiedLabels.push(folder.FolderLabel);
            request.ModifiedParents.push(folder.ParentID);
        });
        // Then include any form favorite creation requests
        _.each(formNames, (form: any) => {
            request.Action.push(Models.ActionConstants.Add);
            // Form item being added to a folder
            request.AffectedTypes.push(Models.HelperText.Item);
            request.AffectedUIDs.push(form.ItemID);
            request.AffectedRecordIDs.push(form.ItemValue);
            request.ModifiedLabels.push(form.ItemLabel);
            request.ModifiedParents.push(form.ParentID);
        });
        // Finally send the update request, our caller will wait for a response
        return this.sendUpdateRequest(resolveObject, request, successCallBack, errorCallBack, inst);
    }

    deleteObjects(objectArray: any, context: any, successCallBack: any, errorCallBack: any, inst: any) { // context specifies whether favorites objects in objectArray are in person or form favorites
        let request = new Models.ModifyFavoritesRequest();
        request.Context = context;
        request.OperatorID = this.sessionService.getOperatorId();

        _.each(objectArray, (object: any) => {
            request.Action.push(Models.ActionConstants.Delete);
            request.AffectedTypes.push(this.isItem(object) ? Models.HelperText.Item : Models.HelperText.Folder);
            request.AffectedRecordIDs.push("");
            request.ModifiedLabels.push("");
            request.ModifiedParents.push("");
            if (this.isItem(object)) {
                request.AffectedUIDs.push(object.ItemID);
            }
            else { // is a folder
                request.AffectedUIDs.push(object.FolderID);
            }
        });

        setTimeout(() => {
            let removeArea = ".person-favorite-panel .outer-tree-wrap>li>.favorite-list>li:first-child";
            if (context === "FORM") {
                removeArea = ".form-favorite-panel .outer-tree-wrap>li>.favorite-list>li:first-child";
            }
            $(removeArea).trigger("focus");
        }, 1000);

        return this.sendUpdateRequest(objectArray, request, successCallBack, errorCallBack, inst);
    }

    validateFolderName(parentId: any, root: any, foundName: any, FolderLabel: any) {
        if (parentId === root.FolderID) {
            for (let i = 0; i < root.Folders.length; i++) {
                if (!(Models.HelperText.tempName in root.Folders[i])) {
                    if (root.Folders[i].FolderLabel === FolderLabel) {
                        foundName.found = true;
                        break;
                    }
                }
            }
        }
        else {
            for (let j = 0; j < root.Folders.length; j++) {
                this.validateFolderName(parentId, root.Folders[j], foundName, FolderLabel)
            }
        }
    }

    validatepersonName(parentId: any, root: any, foundName: any, FolderLabel: any) {
        if (parentId === root.FolderID) {
            for (let i = 0; i < root.CurrentItems.length; i++) {
                if (!(Models.HelperText.tempName in root.CurrentItems[i])) {
                    if (root.CurrentItems[i].ItemLabel === FolderLabel) {
                        foundName.found = true;
                        break;
                    }
                }
            }
        }
        else {
            for (let j = 0; j < root.Folders.length; j++) {
                this.validatepersonName(parentId, root.Folders[j], foundName, FolderLabel)
            }
        }
    }

    /**
     * check if user has selected empty folder for creating sharedlist
     * @param root
     * @param item
     */
    checkSelectedFolder(root, item) {
        for (let i = 0; i < root.Folders.length; i++) {
            if (root.Folders[i].highlight) {
                item.highlight = true;
            }
            else if (root.Folders[i].Folders.length > 0) {
                this.checkSelectedFolder(root.Folders[i], item)
            }
        }
    }

    renameObject(object: any, modifiedLabel: any, context: any, parentID: any, successCallBack: any = null, errorCallBack: any = null, inst: any = null) { // context specifies whether a favorites object is in person or form favorites, can only rename a single item or folder at a time
        let request = new Models.ModifyFavoritesRequest();
        request.Context = context;
        request.OperatorID = this.sessionService.getOperatorId();
        request.Action.push(Models.ActionConstants.Modify);
        request.AffectedTypes.push(this.isItem(object) ? Models.HelperText.Item : Models.HelperText.Folder);
        request.ModifiedLabels.push(modifiedLabel); // new name
        request.ModifiedParents.push(parentID); // folder that form is in
        if (this.isItem(object)) {
            request.AffectedUIDs.push(object.ItemID);
            request.AffectedRecordIDs.push(object.ItemValue);
        }
        else { // is a folder
            request.AffectedUIDs.push(object.FolderID);
            request.AffectedRecordIDs.push("");
        }

        return this.sendUpdateRequest(object, request, successCallBack, errorCallBack, inst);
    }

    getSelectedPeople(root: any, personArray: any) { // fills personArray with selected people with unique ItemValue, duplicates not allowed, used to create a Shared List
        for (let x = 0; x < root.CurrentItems.length; x++) {
            if (root.CurrentItems[x].highlight == true && this.selectedItemValues.indexOf(root.CurrentItems[x].ItemValue) == -1) { // is selected and not a duplicate
                this.selectedItemValues.push(root.CurrentItems[x].ItemValue);
                personArray.push(root.CurrentItems[x]);
            }
        }

        for (let y = 0; y < root.Folders.length; y++) {
            if (root.Folders[y].highlight == true) {
                this.selectFolder(root.Folders[y], personArray);
            }
            this.getSelectedPeople(root.Folders[y], personArray);
        }
    }

    /**
     * Clear highlighting on any selected items and folders
     * @param root
     * @param itemId
     */
    clearSelected(root: any, itemId: string = null) {
        for (let x = 0; x < root.CurrentItems.length; x++) {
            if (root.CurrentItems[x].ItemID === itemId)
                continue;
            root.CurrentItems[x].highlight = false;
        }

        for (let y = 0; y < root.Folders.length; y++) {
            root.Folders[y].highlight = false;
            this.clearSelected(root.Folders[y], itemId);
        }
    }

    getSelected(root: any, objectArray: any) { // fills objectArray with selected items and folders, duplicates allowed
        for (let x = 0; x < root.CurrentItems.length; x++) {
            if (root.CurrentItems[x].highlight == true) {
                objectArray.push(root.CurrentItems[x]);
            }
        }

        for (let y = 0; y < root.Folders.length; y++) {
            if (root.Folders[y].highlight == true) {
                objectArray.push(root.Folders[y]);
            }
            this.getSelected(root.Folders[y], objectArray);
        }
    }

    removeSelected(root: any, objectArray: any) { // remove items and/or folders in objectArray from root after they have been removed from the backend
        _.remove(root.CurrentItems, (item: any) => {
            return objectArray.indexOf(item) != -1 ? true : false;
        });

        _.remove(root.Folders, (folder: any) => {
            return objectArray.indexOf(folder) != -1 ? true : false;
        });

        for (let y = 0; y < root.Folders.length; y++) {
            this.removeSelected(root.Folders[y], objectArray);
        }
    }

    getFolders(root: any) { // TODO: maybe use Session Storage
        for (let y = 0; y < root.Folders.length; y++) {
            this.getFolders(root.Folders[y]);
        }
    }

    /**
     * returns number of items underneath folder
     * @param folder
     * @returns {number}
     */
    numItems(folder: any): number {
        let temp = [];
        this.selectFolder(folder, temp, false);
        return temp.length;
    }

    renamePerson(item: any, parentId: any, root: any) {
        let foundName = { found: false };
        if (!_.isArray(item.Folders)) { // is an item
            // if blank name is there restore its preveous name;
            if (item.tempName.length <= 0) {
                delete item.tempName;
                item.rename = false;
            }
            else { // if value is entered check for duplicate name in same level
                this.validatepersonName(parentId, root, foundName, item.tempName);
                if (foundName.found) {
                    let msg: any = {};
                    let bodyText: string = Models.PromptMessageConstants.renameFoldermessage1 + item.tempName + Models.PromptMessageConstants.renameFoldermessage2;
                    msg.title = '';
                    msg.text = [bodyText];
                    this.duplicateEntriesAlertMsg(msg.title, msg.text, 'person');
                    delete item.tempName;
                    item.rename = false;
                }
                else {
                    item.ItemLabel = item.tempName;
                    delete item.tempName;
                    this.renameObject(item, item.ItemLabel, "PERSON", parentId);
                    item.rename = false;
                }
            }
        }
        else {	// if it is a folder.
            // if blank name is there restore its preveous name;
            if (item.tempName.length <= 0) {
                delete item.tempName;
                item.rename = false;
            }
            // check if folder name is already exists in same level
            else {
                this.validateFolderName(parentId, root, foundName, item.tempName);
                if (foundName.found) {
                    let msg: any = {};
                    let bodyText: string = Models.PromptMessageConstants.renameFoldermessage1 + item.tempName + Models.PromptMessageConstants.renameFoldermessage2;
                    msg.title = '';
                    msg.text = [bodyText];
                    this.duplicateEntriesAlertMsg(msg.title, msg.text, 'person');
                    delete item.tempName;
                    item.rename = false;
                }
                else {
                    item.FolderLabel = item.tempName;
                    delete item.tempName;
                    this.renameObject(item, item.FolderLabel, "PERSON", parentId);
                    item.rename = false;
                }
            }
        }
    }

    unselectForm(root: any) {
        for (let i = 0; i < root.CurrentItems.length; i++) {
            if (root.CurrentItems[i].highlight) {
                root.CurrentItems[i].highlight = false;
                this.clearSelectedForm();
                let focusItem = root.FolderID + "_" + root.CurrentItems[i].ItemID;
                this.setFocusCurrentItem(focusItem);
                break
            }
        }
        if (root.Folders && root.Folders.length > 0) {
            for (let j = 0; j < root.Folders.length; j++) {
                if (root.Folders[j].highlight) {
                    root.Folders[j].highlight = false;
                    this.clearSelectedForm();
                    let focusItem = root.Folders[j].FolderID;
                    this.setFocusCurrentItem(focusItem);
                    break;
                }
                else {
                    this.unselectForm(root.Folders[j]);
                }
            }
        }

    }

    setFocusCurrentItem(item: any) {
        let btnId = Models.HelperText.hash + "btn_" + item;
        this.focusService.focusOn(btnId);
    }

    toggleMultiSelect(favSvc: FavoritesService = null, item: any, event: any, parentId: any, root: any) { // TODO: support shift functionality
        let self: any;
        if (favSvc != null) {
            self = <FavoritesService>favSvc;
        }
        else {
            self = this;
        }

        // input box disappears for items and folders
        self.noofEventOccurs = self.noofEventOccurs + 1;
        //Check user directly clicks item(li) not input box and item(li)
        if (self.renameFlag.rename && self.noofEventOccurs == 1) {
            if (item.rename) {
                self.renamePerson(item, parentId, root)
            } else {
                self.disablePersonRename(self.favorites.PERSON);
            }
            self.renameFlag.rename = false;
        }
        self.noofEventOccurs = 0;
        // Remove annoying select highlight when doing a range
        document.getSelection().removeAllRanges();
        // Get state of control, shift and alt keys in click
        let ctrl, shift, alt;
        if (event != null) {
            event.preventDefault();
            ctrl = event.ctrlKey;
            shift = event.shiftKey;
            alt = event.altKey;
        }
        else {
            ctrl = false;
            shift = false;
            alt = false;
        }

        // Only ctrl and/or alt option directly toggles the current entry and nothing else
        if (!ctrl && !alt) {
            if (shift) {
                // Shift by itself selects all from this one to the first one selected
                let oldest = self.findOldestMultiSelect(self.favorites.PERSON);
                if ((oldest === undefined) || (oldest === item)) {
                    // No previous selection or same as current one, we do nothing.
                    return;
                } else {
                    // Select everything between and including the two endpoints.
                    // Anything else selected outside that range is unselected.
                    self.selectRange(self.favorites.PERSON, item, oldest, false);
                    return;
                }
            } else {
                // No ctrl, alt or shift clears everything selected then selects this one
                self.clearSelected(self.favorites.PERSON, item.ItemID);
            }
        }
        self.defaultNewAttributes(item);
        item.highlight = !item.highlight;
        // Each multi selected item has a counter associated with it.
        // This allows us to keep track of the order they were selected in
        // since shift-select highlights up to oldest active selection.
        if (item.highlight) {
            item.selectedSequence = self.nextSelectCounter++;
        }
    }

    selectFormItem(favSvc: FavoritesService = null, item: any, isFolder: boolean, root: any, parent: any, event: any) {
        let self: any;
        if (favSvc != null) {
            self = <FavoritesService>favSvc;
        }
        else {
            self = this;
        }

        self.defaultNewAttributes(item);
        self.noofEventOccurs++;
        // input box disappears for items and folders
        //Check user directly clicks item(li) not input box
        if (self.isAllowFormRename && self.noofEventOccurs == 1) {
            if (item.rename) {
                //Check the renamed name exists or not
                let isItemExist: boolean = self.isItemExist(item, isFolder, root, parent);
                if (isItemExist) {
                    // If renamed name exists popup message
                    self.itemExistsNotification(item, isFolder);
                } else {
                    self.sendRenameRequest(item, isFolder, parent);
                }
                item.rename = false;
            } else {
                self.disableRenameOption(self.favorites.FORM);
            }

        }
        self.noofEventOccurs = 0;
        self.isAllowFormRename = true;
        //Remove exisitng items from the this.renameFormFolders array
        self.renameFormFolders.splice(0, self.renameFormFolders.length);
        self.renameFormFolders.push({ Item: item, IsFolder: isFolder, Root: root, Parent: parent });
        let prevSelected;
        if (self.selectedForm && self.selectedForm.form && self.selectedForm.form.ItemID != null) {
            prevSelected = self.selectedForm.form.ItemID;
        }
        else if (self.selectedForm && self.selectedForm.form && self.selectedForm.form.FolderID != null) {
            prevSelected = self.selectedForm.form.FolderID;
        }

        let newSelected = item.ItemID;
        if (newSelected === undefined) {
            newSelected = item.FolderID;
        }
        if (prevSelected !== newSelected) {
            // Different item being selected, unselect old first then select new
            self.selectedForm.form.highlight = false;
            item.highlight = true;
            self.selectedForm.form = item;
            self.selectedForm.isSelection = true;
        }
        else {
            self.selectedForm.form.highlight = false;
            item.highlight = false;
            self.selectedForm.form = item;
            self.selectedForm.isSelection = false;
        }
        if (event != null) {
            event.stopPropagation();
        }
    }

    notificationMessage(message: any, optionContextType: string = null) {
        let popupMessage: any = {};
        popupMessage.title = '';
        popupMessage.text = [message];
        popupMessage.buttons = [
            {
                label: Models.PromptMessageConstants.okPromptButton,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        // $timeout(function () {
                        if (!this.validationService.isNullOrEmpty(optionContextType)) {
                            if (optionContextType.toLowerCase() === "person")
                                setTimeout(() => {
                                    document.getElementById('favorite-person-options-link').focus();
                                }, 200);
                            else if (optionContextType.toLowerCase() === "form")
                                setTimeout(() => {
                                    document.getElementById("favorite-form-options-link").focus();
                                }, 200);
                        }
                        else {
                            // this.searchService.focusOnSearchInput();
                        }
                        this.pubSubService.broadcast(Models.EventConstants.closeAlert);
                        //   }, 100);
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = 0;
        this.notificationsService.popupMessageShow(popupMessage);
    }

    notAllowFormRename(item: any, isFolder: boolean, e: any) {
        if (isFolder && item.FolderLabel != "") {
            item.prevValue = item.FolderLabel;
        } else if (!isFolder && item.ItemLabel != "") {
            item.prevValue = item.ItemLabel;
        }
        this.noofEventOccurs = 1;
        this.isAllowFormRename = false;
        e.stopPropagation();
    }

    disableMultiToggle(e: any) {
        e.stopPropagation();
    }

    toggleExpand(folder: any) { // expands a folder in the tree directive
        if (!_.has(folder, Models.HelperText.Expanded)) {
            folder.expanded = false;
        }
        folder.expanded = !folder.expanded;
        this.noofEventOccurs = 1;
        this.isAllowFormRename = false;
    }

    formFavoritesRenameShortCutKey(keyEvent: any, folder: any, root: any, isFolder: boolean, parent: any) {
        let keyCode = keyEvent.keyCode;
        let focusItem = "";
        let selectedItem = "";
        if (keyCode == Models.KeyCodes.escape) {
            //Favorite escape key Functionality
            if (this.renameFormFolders.length > 0) {
                if (!this.validationService.isNullOrEmpty(this.renameFormFolders[0].Item.prevValue) && this.renameFormFolders[0].Item.prevValue != " ") {
                    if (!this.validationService.isNullOrEmpty(this.renameFormFolders[0].Item.FolderLabel)) {//Change back to prevValue
                        this.renameFormFolders[0].Item.FolderLabel = this.renameFormFolders[0].Item.prevValue;
                        focusItem = this.renameFormFolders[0].Item.FolderID;
                        selectedItem = this.renameFormFolders[0].Item;
                    } else {
                        this.renameFormFolders[0].Item.ItemLabel = this.renameFormFolders[0].Item.prevValue;
                        focusItem = this.renameFormFolders[0].Item.ItemID;
                        focusItem = parent + "_" + focusItem;
                        selectedItem = this.renameFormFolders[0].Item;
                    }
                }

                this.renameFormFolders[0].Item.rename = false;
                this.setFocusCurrentItem(focusItem);
            }

        } else if (keyCode == Models.KeyCodes.enter) {
            this.enterKeyFunction(folder, root, isFolder, parent);
            focusItem = isFolder ? folder.FolderID : folder.ItemID;
            if (!isFolder) focusItem = parent + "_" + focusItem;
            document.getElementById(focusItem).focus();
        }
        keyEvent.stopPropagation();
    }

    formFavoritesShortCutKey(keyEvent: any, folder: any, root: any, isFolder: boolean, parent: any) {
        let keyCode = keyEvent.keyCode;

        if (keyCode == Models.KeyCodes.left) {//Collase the folder
            if (folder.expanded && isFolder) {
                folder.expanded = false;
            }
        } else if (keyCode == Models.KeyCodes.right) {//Expand the folders
            if (!folder.expanded) {
                folder.expanded = true;
            }
        } else if (keyCode == Models.KeyCodes.down) {
            this.keyDownArrow(folder, this.favorites.FORM, isFolder, parent, this.favorites.FORM, root, this.selectFormItem, this);

        } else if (keyCode == Models.KeyCodes.up) {
            this.keyUPArrow(folder, this.favorites.FORM, isFolder, parent, this.favorites.FORM, root, this.selectFormItem, this);

        } else if (keyCode == Models.KeyCodes.enter) {
            this.enterKeyFunction(folder, root, isFolder, parent);
        } else if (keyCode == Models.KeyCodes.f2) {
            folder.rename = true;
            if (isFolder) {
                folder.FolderText = folder.FolderLabel;
                folder.prevValue = folder.FolderLabel;
            } else {
                folder.ItemText = folder.ItemLabel;
                folder.prevValue = folder.ItemLabel;
            }
        }
    }

    enterKeyFunction(folder: any, root: any, isFolder: boolean, parent: any) {
        if (folder.rename) {
            this.enterbtnClicked = this.enterbtnClicked + 1;
            let currentValue = isFolder ? folder.FolderText : folder.ItemText;
            //Check input text box have value or not if not no action should taken when user press enter key
            if (this.validationService.isNullOrEmpty(currentValue)) {
                folder.rename = true;
            } else {
                //Check the renamed name exists or not
                let isItemExist = this.isItemExist(folder, isFolder, root, parent);
                if (isItemExist) {
                    // If renamed name exists popup message
                    this.itemExistsNotification(folder, isFolder);
                    folder.rename = false;
                } else {
                    this.sendRenameRequest(folder, isFolder, parent);
                }

                if (!this.validationService.isNullOrEmpty(folder.prevValue) && currentValue != folder.prevValue) {
                    folder.rename = false;
                }
            }
        } else {
            this.enterbtnClicked = this.enterbtnClicked + 1;
            if (isFolder && this.enterbtnClicked == 1) {
                this.notificationMessage(Models.PromptMessageConstants.favoriteErrorMessageText);
            } else {
                if (this.enterbtnClicked == 1) {
                    this.pubSubService.broadcast(Models.HelperText.addToContext);
                }

            }
            this.enterbtnClicked = 0;
        }
    }

    personFavoritesShortCutKey(keyEvent: any, folder: any, root: any, isFolder: boolean, parent: any) {
        // stop executing function if folder is in renaming state
        if (folder.rename === true) {
            return;
        }
        let keyCode = keyEvent.keyCode;
        // Call Sort By Name
        this.favorites.PERSON.Folders.sort((a: any, b: any) => {
            let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
            let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

            if (folderA < folderB) {
                return -1;
            }
            if (folderA > folderB) {
                return 1;
            }
            return 0;
        });
        root.Folders.sort((a: any, b: any) => {
            let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
            let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

            if (folderA < folderB) {
                return -1;
            }
            if (folderA > folderB) {
                return 1;
            }
            return 0;
        });
        root.CurrentItems.sort((a: any, b: any) => {
            let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
            let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

            if (folderA < folderB) {
                return -1;
            }
            if (folderA > folderB) {
                return 1;
            }
            return 0;
        });

        if (keyCode == Models.KeyCodes.left) {
            //Collase the Folder and Stay in the Current Position
            if (folder.expanded && isFolder) {
                folder.expanded = false;
            }
        } else if (keyCode == Models.KeyCodes.right) {
            //Expand the Folder and Stay in the Current Position
            if (!folder.expanded && (folder.Folders.length > 0 || folder.CurrentItems.length > 0)) {
                folder.expanded = true;
            }
        } else if (keyCode == Models.KeyCodes.down) {
            this.keyDownArrow(folder, this.favorites.PERSON, isFolder, parent, this.favorites.PERSON, root, this.toggleMultiSelect, this);
        } else if (keyCode == Models.KeyCodes.up) {
            this.keyUPArrow(folder, this.favorites.PERSON, isFolder, parent, this.favorites.PERSON, root, this.toggleMultiSelect, this);
        } else if (keyCode == Models.KeyCodes.enter) {
            this.pubSubService.broadcast("addToContext");
        } else if (keyCode == Models.KeyCodes.f2) {
            this.selectedPersonFavorites = 0;
            this.selectedPersonArray.splice(0, this.selectedPersonArray.length);
            // Get No of selected folders/Items
            let noofSelectedItems = this.checkNoOfSelectedItems(this.favorites.PERSON);
            if (noofSelectedItems == 1) { // Enable Input text box only when user select one person/folder
                let folderId = isFolder ? folder.FolderID : folder.ItemID;
                if (this.selectedPersonArray[0] == folderId) folder.rename = true;
                if (isFolder) {
                    folder.tempName = folder.FolderLabel;
                } else {
                    folder.tempName = folder.ItemLabel;
                }
                this.renameFlag.rename = true;
                // get focus on rename input box asynchronously
                setTimeout(() => {
                    let folderPersonRenameInput = document.getElementById("folder-person-rename") as HTMLInputElement;
                    folderPersonRenameInput.focus();
                    folderPersonRenameInput.select();
                }, 100);
            }
        }
    }

    checkNoOfSelectedItems(root: any) {
        if (root.CurrentItems.length > 0) {
            for (let x = 0; x < root.CurrentItems.length; x++) {
                if (root.CurrentItems[x].highlight) {
                    this.selectedPersonFavorites = this.selectedPersonFavorites + 1;
                    this.selectedPersonArray.push(root.CurrentItems[x].ItemID);
                }
            }
        }
        if (root.Folders.length > 0) {
            for (let i = 0; i < root.Folders.length; i++) {
                let tmp = root.Folders[i];
                if (tmp.highlight) {
                    this.selectedPersonFavorites = this.selectedPersonFavorites + 1;
                    this.selectedPersonArray.push(tmp.FolderID);
                }
                if (tmp.Folders.length > 0) {
                    this.checkNoOfSelectedItems(tmp);
                } else if (tmp.CurrentItems.length > 0) {
                    for (let x = 0; x < tmp.CurrentItems.length; x++) {
                        if (tmp.CurrentItems[x].highlight) {
                            this.selectedPersonFavorites = this.selectedPersonFavorites + 1;
                            this.selectedPersonArray.push(tmp.CurrentItems[x].ItemID);
                        }
                    }
                }
            }
        }
        return this.selectedPersonFavorites;
    }

    /**
     * Bring up the add to favorites dialog with the entries being added
     * @param type
     * @param data
     */
    showAddToFavorites(type: string, data: any) {
        this.addData.type = type;
        this.addData.entries = [];
        if (type === Models.HelperText.form) {
            // Form being added, either from search or the active one
            let formTitle: string;
            let formMnemonic: string;
            if (data.original !== undefined) {
                if (data.original.form !== undefined) {
                    // Currently running form is being added
                    formTitle = data.original.form.formTitle + "(" + data.original.form.formMnemonic + ")";
                    formMnemonic = data.original.form.formApplication + "-" + data.original.form.formMnemonic;
                }
            } else {
                // Form found via search is being added
                if (data.form !== undefined) {
                    formTitle = data.form[Models.HelperText.processDescription] + "(" + data.form[Models.HelperText.ID] + ")";
                    formMnemonic = data.form[Models.HelperText.processApplication] + "-" + data.form[Models.HelperText.ID];
                }
            }
            if ((formTitle !== undefined) && (formMnemonic !== undefined)) {
                // Update the dialog settings to show form info being added and display it
                this.displayFolderNames("Form Favorites");
                let formEntry: any = {
                    ItemLabel: formTitle,
                    ItemID: formMnemonic
                };
                this.addData.entries = [formEntry];
                $(this.addToFavoritesDialog).modal('show');
                setTimeout(() => {
                    let addFavoriteBtn = document.querySelector(this.addToFavoritesDialog + " " + this.btnAddFavorite) as HTMLElement;
                    addFavoriteBtn.focus();
                }, 250);
            }
        } else if (type === Models.HelperText.person) {
            // A list of one or more people from the context header is being added
            // Update the dialog settings to show all person info being added and display it
            this.displayFolderNames("People Favorites");
            this.addData.entries = [];
            for (let i = 0; i < data.length; i++) {
                let personEntry: any = {
                    ItemLabel: data[i]["PCC.FULL.NAME"],
                    ItemID: data[i][Models.HelperText.ID]
                };
                this.addData.entries.push(personEntry);
            }
            $(this.addToFavoritesDialog).modal('show');
            setTimeout(() => {
                let addFavoriteBtn = document.querySelector(this.addToFavoritesDialog + " " + this.btnAddFavorite) as HTMLElement;
                addFavoriteBtn.focus();
            }, 250);
        }
    }

    generateDisplayedFolderLabel(root: any, level: number) {
        let displayedLabel = "";
        for (let i = 0; i < level; i++) {
            displayedLabel += "\u00A0";// space in dropdown list for tree format.
        }
        displayedLabel += root.FolderLabel;
        let folder: any = {
            FolderID: root.FolderID,
            ParentID: root.ParentID,
            FolderLabel: root.FolderLabel,
            Folders: root.Folders,
            CurrentItems: root.CurrentItems,
            DisplayedLabel: displayedLabel,
            Level: level
        };
        return folder;
    }

    /**
     * Populate the dropdown list of folder names with ones appropriate for the type of entries being maintained.  Set the initial value to any previous legal one, otherwise select the first entry in the list
     * @param topLabel
     */
    private displayFolderNames(topLabel: any) {
        let folders: any = [];
        this.getFoldersTree(this.favorites[this.addData.type], folders, 0);
        if (folders[0].DisplayedLabel === "") {
            folders[0].DisplayedLabel = topLabel;
        }
        let prevSelected: any = this.addData.selectedAddToFolder[this.addData.type];
        this.addData.folders = folders;
        // Pre-select previous selection, or first entry if no valid previous selection
        this.addData.selectedAddToFolder[this.addData.type] = folders[0];
        for (let i = 0; i < folders.length; i++) {
            if (folders[i].FolderID === prevSelected.FolderID) {
                this.addData.selectedAddToFolder[this.addData.type] = folders[i];
            }
        }
    }

    private getFoldersTree(root: any, folders: any, level: any) {
        folders.push(this.generateDisplayedFolderLabel(root, level));
        for (let j = 0; j < root.Folders.length; j++) {
            this.getFoldersTree(root.Folders[j], folders, level + 1);
        }
    }

    private keyDownArrow(folder: any, Menu: any, isFolder: any, parent: any, originalmsg: any, root: any, toggleMode: any, favSvc: any) {
        let found: boolean = false;
        let focusItem: string = "";
        let selectedItem: any = folder;

        if (Menu.Folders.length > 0) {
            for (let i = 0; i < Menu.Folders.length; i++) {
                if (found)
                    break;
                let tmp = Menu.Folders[i];
                //Loop the Menus and if it is folder and expanded
                if (isFolder && tmp.expanded) {
                    //loop inner levels till match the current focus folder
                    if (tmp.FolderID != folder.FolderID) {
                        if (tmp.Folders.length > 0) {
                            this.keyDownArrow(folder, tmp, isFolder, parent, originalmsg, root, toggleMode, favSvc);
                        }
                        // Once found the current focused folder,Focus to the first child Folder/ CurrentItems
                    } else if (tmp.FolderID == folder.FolderID && parent == Menu.FolderID) {
                        //Focus first child folder if current folder have folders
                        if (tmp.Folders.length > 0) {
                            focusItem = tmp.Folders[0].FolderID;
                            selectedItem = tmp.Folders[0];
                            found = true;
                            //Focus first child CurrentItems if current Folder have CurrentItems
                        } else if (tmp.CurrentItems.length > 0) {
                            focusItem = tmp.CurrentItems[0].ItemID;
                            selectedItem = tmp.CurrentItems[0];
                            focusItem = tmp.FolderID + "_" + focusItem;
                            found = true;
                            //Move to the parent if current focused Folder not having Folders/CurrentItems
                        } else {
                            this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.down, toggleMode, originalmsg, favSvc);
                            found = true;
                            break;
                        }
                        this.setFocusCurrentItem(focusItem);
                    }
                    //Move to next Folder/CurrentItems in same level
                } else if (isFolder && !tmp.expanded) {
                    //Move to next Folder if cuurent folder is not last folder in the menu list
                    if (tmp.FolderID == folder.FolderID && i < root.Folders.length - 1 && parent == Menu.FolderID) {
                        tmp = Menu.Folders[i + 1];
                        focusItem = tmp.FolderID;
                        selectedItem = tmp;
                        found = true;
                        this.setFocusCurrentItem(focusItem);
                        //Move to CurrentItems if cuurent folder is last folder in the menu list
                    } else if (tmp.FolderID == folder.FolderID && parent == Menu.FolderID && Menu.CurrentItems.length > 0) {
                        focusItem = Menu.CurrentItems[0].ItemID;
                        selectedItem = Menu.CurrentItems[0];
                        focusItem = parent + "_" + focusItem;
                        found = true;
                        this.setFocusCurrentItem(focusItem);
                        //Move to parent level
                    } else if (tmp.FolderID == folder.FolderID && parent == Menu.FolderID) {
                        this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.down, toggleMode, originalmsg, favSvc);
                        found = true;
                        break;
                    }
                    //Move to next CurrentItems in same level if current focused items is not folder
                } else if (!isFolder) {
                    // Loop the same level CurrentItems
                    for (let j = 0; j < root.CurrentItems.length; j++) {
                        // Move next CurrentItems if current focused item is not last items
                        if (root.CurrentItems[j].ItemID == folder.ItemID && j < root.CurrentItems.length - 1) {
                            focusItem = root.CurrentItems[j + 1].ItemID;
                            selectedItem = root.CurrentItems[j + 1];
                            focusItem = parent + "_" + focusItem;
                            this.setFocusCurrentItem(focusItem);
                            found = true;
                            break;
                            // Move to parent level if current focused item is last item
                        } else if (root.CurrentItems[j].ItemID == folder.ItemID && j == root.CurrentItems.length - 1) {
                            this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.down, toggleMode, originalmsg, favSvc);
                            found = true;
                            break;
                        }
                    }
                }
            }
            //Menus have only CurrentItems
        } else if (Menu.CurrentItems.length > 0) {
            for (let i = 0; i < Menu.CurrentItems.length; i++) {
                if (Menu.CurrentItems[i].ItemID == folder.ItemID) {
                    focusItem = Menu.CurrentItems[i + 1].ItemID;
                    selectedItem = Menu.CurrentItems[i + 1];
                    focusItem = parent + "_" + focusItem;
                    this.setFocusCurrentItem(focusItem);
                    break;
                }
            }
        }
    }

    private keyUPArrow(folder: any, Menu: any, isFolder: any, parent: any, originalmsg: any, root: any, toggleMode: any, favSvc: any) {
        let found: boolean = false;
        let focusItem: string = "";
        let selItem: any = folder;

        if (Menu.Folders.length > 0) {
            for (let i = 0; i < Menu.Folders.length; i++) {
                if (found)
                    break;
                let tmp = Menu.Folders[i];

                //Loop the Menus and if it is folder and expanded
                if (tmp.expanded && isFolder) {
                    //loop inner levels till match the current focus folder
                    if (tmp.FolderID != folder.FolderID) {
                        if (tmp.Folders.length > 0) {
                            this.keyUPArrow(folder, tmp, isFolder, parent, originalmsg, root, toggleMode, favSvc);
                        }
                        // Once found the current focused folder,Focus to the previous Folder/ CurrentItems
                    } else if (tmp.FolderID == folder.FolderID && parent == Menu.FolderID) {
                        // if parent have folders and current focused folder is not first folder in the level
                        if (Menu.Folders.length > 0 && i > 0) {
                            tmp = Menu.Folders[i - 1];
                            // If previous folder is expanded than focus the previous folder currentItems
                            if (tmp.expanded && tmp.CurrentItems.length > 0) {
                                selItem = tmp.CurrentItems[tmp.CurrentItems.length - 1];
                                focusItem = tmp.CurrentItems[tmp.CurrentItems.length - 1].ItemID;
                                focusItem = tmp.FolderID + "_" + focusItem;
                                // If previous folder is expanded and having currentItems than focus to that folder
                            } else if (tmp.expanded && tmp.Folders.length > 0) {
                                let childTemp = tmp.Folders[tmp.Folders.length - 1];
                                if (childTemp.CurrentItems.length > 0 && childTemp.expanded) {
                                    selItem = childTemp.CurrentItems[childTemp.CurrentItems.length - 1];
                                    focusItem = childTemp.CurrentItems[childTemp.CurrentItems.length - 1].ItemID;
                                    focusItem = childTemp.FolderID + "_" + focusItem;
                                } else {
                                    selItem = childTemp;
                                    focusItem = childTemp.FolderID;
                                }
                            } else {
                                selItem = tmp;
                                focusItem = tmp.FolderID;
                            }
                            this.setFocusCurrentItem(focusItem);
                            found = true;
                            break;
                            // if parent have folders and current focused folder is not first in the level
                            // focus to parent folder
                        } else if (Menu.Folders.length > 0 && i == 0) {
                            focusItem = Menu.FolderID;
                            selItem = Menu;
                            this.setFocusCurrentItem(focusItem);
                            found = true;
                            break;
                        }
                    }
                    //Move to previous folder/currentItems in same level
                } else if (!tmp.expanded && isFolder) {
                    // Current focused folder is not first and move to previous folder
                    if (tmp.FolderID == folder.FolderID && i > 0 && parent == Menu.FolderID) {
                        tmp = Menu.Folders[i - 1];
                        // if previous folder is expanded and move to previous folder last currentitem
                        if (tmp.expanded && tmp.CurrentItems.length > 0) {
                            selItem = tmp.CurrentItems[tmp.CurrentItems.length - 1];
                            focusItem = tmp.CurrentItems[tmp.CurrentItems.length - 1].ItemID;
                            focusItem = tmp.FolderID + "_" + focusItem;
                            // If previous folder is expanded and having currentItems than focus to that folder
                        } else if (tmp.expanded && tmp.Folders.length > 0) {
                            let childTemp = tmp.Folders[tmp.Folders.length - 1];
                            if (childTemp.CurrentItems.length > 0 && childTemp.expanded) {
                                selItem = childTemp.CurrentItems[childTemp.CurrentItems.length - 1];
                                focusItem = childTemp.CurrentItems[childTemp.CurrentItems.length - 1].ItemID;
                                focusItem = childTemp.FolderID + "_" + focusItem;
                            } else {
                                selItem = childTemp;
                                focusItem = childTemp.FolderID;
                            }
                        } else {
                            selItem = tmp;
                            focusItem = tmp.FolderID;
                        }
                        this.setFocusCurrentItem(focusItem);
                        found = true;
                        break;
                        // Current focused folder is  first and move to parent folder
                    } else if (tmp.FolderID == folder.FolderID && i == 0 && parent == Menu.FolderID) {
                        focusItem = Menu.FolderID;
                        selItem = Menu;
                        this.setFocusCurrentItem(focusItem);
                        found = true;
                        break;
                    }
                    // Current focused item is not folder
                } else if (!isFolder) {
                    if (root.FolderID == parent && root.CurrentItems.length > 0) {
                        // Loop the same level currentitems
                        for (let j = 0; j < root.CurrentItems.length; j++) {
                            // If current focused item is not first than move to previous currentitem in the same level
                            if (root.CurrentItems[j].ItemID == folder.ItemID && j > 0) {
                                focusItem = root.CurrentItems[j - 1].ItemID;
                                focusItem = parent + "_" + focusItem;
                                selItem = root.CurrentItems[j - 1];
                                this.setFocusCurrentItem(focusItem);
                                found = true;
                                break;
                                // If current focused item is first than move to parent level
                            } else if (root.CurrentItems[j].ItemID == folder.ItemID && j == 0 && parent != originalmsg.FolderID) {
                                this.moveToParentLevel(parent, originalmsg, Models.KeyCodes.up, toggleMode, originalmsg, favSvc);
                                found = true;
                                break;
                            } else if (root.CurrentItems[j].ItemID == folder.ItemID && j == 0 && parent == originalmsg.FolderID) {
                                let tmp = root.Folders[root.Folders.length - 1];
                                if (tmp.expanded && tmp.CurrentItems.length > 0) {
                                    focusItem = tmp.CurrentItems[tmp.CurrentItems.length - 1].ItemID;
                                    focusItem = tmp.FolderID + "_" + focusItem;
                                    selItem = tmp.CurrentItems[tmp.CurrentItems.length - 1];
                                } else if (tmp.expanded && tmp.CurrentItems.length == 0 && tmp.Folders.length > 0) {
                                    focusItem = tmp.Folders[tmp.Folders.length - 1].FolderID;
                                    selItem = tmp.Folders[tmp.Folders.length - 1];
                                } else {
                                    focusItem = tmp.FolderID;
                                    selItem = tmp;
                                }
                                this.setFocusCurrentItem(focusItem);
                            }
                        }
                    }
                }
            }
            //Menus have only CurrentItems
        } else if (Menu.CurrentItems.length > 0) {
            for (let i = 0; i < Menu.CurrentItems.length; i++) {
                if (Menu.CurrentItems[i].ItemID == folder.ItemID) {
                    focusItem = Menu.CurrentItems[i - 1].ItemID;
                    focusItem = parent + "_" + focusItem;
                    selItem = Menu.CurrentItems[i - 1];
                    this.setFocusCurrentItem(focusItem);
                    break;
                }
            }
        }
    }

    private moveToParentLevel(parent: any, root: any, key: any, toggleMode: any, originalmsg: any, favSvc: any) {
        let found: boolean = false;
        let selItem;
        let focusItem: string = "";

        if (key == Models.KeyCodes.up) {
            for (let i = 0; i < root.Folders.length; i++) {
                if (found) break;
                let tmp = root.Folders[i];

                //Loop till parent folder reaches
                if (parent != tmp.FolderID && tmp.expanded && parent != "ROOT") {
                    this.moveToParentLevel(parent, tmp, Models.KeyCodes.up, toggleMode, originalmsg, favSvc);
                    // If Parent folder is expanded
                } else if ((parent == tmp.FolderID && tmp.expanded) || (parent == "ROOT")) {
                    let folder = "";
                    // If Parent have more folders other than current focused folder
                    if (tmp.Folders.length > 0) {
                        let childtemp = tmp.Folders[tmp.Folders.length - 1];
                        // Focus to the previous folder child item if previous folder have currentitems
                        if (childtemp.expanded && childtemp.CurrentItems.length > 0) {
                            focusItem = childtemp.CurrentItems[childtemp.CurrentItems.length - 1].ItemID;
                            focusItem = childtemp.FolderID + "_" + focusItem;
                            // Focus to the previous folder folders if previous folder not having currentitems
                        } else if (childtemp.expanded && childtemp.CurrentItems.length == 0 && childtemp.Folders.length > 0) {

                            let grandChild = childtemp.Folders[childtemp.Folders.length - 1];
                            if (grandChild.Folders.length > 0) {
                                focusItem = grandChild.Folders[grandChild.Folders.length - 1].FolderID;
                                folder = grandChild.Folders[grandChild.Folders.length - 1];
                            } else if (grandChild.CurrentItems.length > 0) {
                                focusItem = grandChild.CurrentItems[grandChild.CurrentItems.length - 1].ItemID;
                                focusItem = grandChild.FolderID + "_" + focusItem;
                            } else {
                                focusItem = grandChild.FolderID;
                                folder = grandChild;
                            }
                            // Focus to the previous folder if previous folder not having currentitems/folders
                        } else {
                            focusItem = childtemp.FolderID;
                            folder = childtemp;
                        }
                        // If Parent not having more folders other than current focused folder than focus to parent folder
                    } else {
                        focusItem = root.Folders[i].FolderID;
                        folder = root.Folders[i];
                    }
                    found = true;
                    toggleMode(favSvc, folder);
                    this.setFocusCurrentItem(focusItem);
                }
            }
        } else if (key == Models.KeyCodes.down) {
            for (let i = 0; i < root.Folders.length; i++) {
                if (found) break;
                let tmp = root.Folders[i];
                //tmp.Folders.sort(_sortItems);
                // tmp.CurrentItems.sort(_sortItems);
                if (parent != tmp.FolderID && tmp.expanded) {
                    this.moveToParentLevel(parent, tmp, Models.KeyCodes.down, toggleMode, originalmsg, favSvc);
                } else if (parent == tmp.FolderID && tmp.expanded) {
                    if (i == root.Folders.length - 1 && root.CurrentItems.length > 0) {
                        focusItem = root.CurrentItems[0].ItemID;
                        selItem = root.CurrentItems[0];
                        focusItem = root.FolderID + "_" + focusItem;
                        found = true;

                    } else if (i < root.Folders.length - 1) {
                        selItem = root.Folders[i + 1];
                        focusItem = root.Folders[i + 1].FolderID; //focus to next folder in same level
                        found = true;
                    } else {

                        let grandParent = this.getParent(tmp, originalmsg);
                        this.moveToParentLevel(grandParent, originalmsg, Models.KeyCodes.down, toggleMode, originalmsg, favSvc);
                    }
                    toggleMode(favSvc, selItem);
                    this.setFocusCurrentItem(focusItem);
                }
            }
        }
    }

    private getParent(item: any, root: any) {
        let parent: string = "";
        if (root.Folders.length > 0) {
            for (let i = 0; i < root.Folders.length; i++) {
                if (parent != "") break;
                let tmp = root.Folders[i];
                if (tmp.expanded && tmp.FolderID != item.FolderID) {
                    parent = this.getParent(item, tmp);
                } else if (tmp.FolderID == item.FolderID && tmp.expanded) {
                    parent = root.FolderID;
                    break;
                }

            }
        }
        return parent;
    }

    private disableRenameOption(formRoot: any) {
        let isItemExist = false;
        // input box disappears for folders
        if (formRoot.Folders.length > 0) {
            for (let i = 0; i < formRoot.Folders.length; i++) {
                if (formRoot.Folders[i].rename) {
                    isItemExist = this.isItemExist(formRoot.Folders[i], true, formRoot, formRoot.FolderID);
                    if (isItemExist) {
                        this.itemExistsNotification(formRoot.Folders[i], true);
                    } else {
                        this.sendRenameRequest(formRoot.Folders[i], true, formRoot.FolderID);
                    }
                    formRoot.Folders[i].rename = false;
                }
                if (formRoot.Folders[i].Folders.length > 0) {
                    this.disableRenameOption(formRoot.Folders[i]);
                } else if (formRoot.Folders[i].CurrentItems.length > 0) {
                    for (let x = 0; x < formRoot.Folders[i].CurrentItems.length; x++) {
                        if (formRoot.Folders[i].CurrentItems[x].rename) {
                            isItemExist = this.isItemExist(formRoot.Folders[i].CurrentItems[x], false, formRoot.Folders[i], formRoot.Folders[i].FolderID);
                            if (isItemExist) {
                                this.itemExistsNotification(formRoot.Folders[i].CurrentItems[x], false);
                            } else {
                                this.sendRenameRequest(formRoot.Folders[i].CurrentItems[x], false, formRoot.Folders[i].FolderID);
                            }
                            formRoot.Folders[i].CurrentItems[x].rename = false;
                        }
                    }
                }
            }
        }
        // input box disappears for items
        if (formRoot.CurrentItems.length > 0) {
            formRoot.rename = false;
            for (let y = 0; y < formRoot.CurrentItems.length; y++) {
                if (formRoot.CurrentItems[y].rename) {
                    isItemExist = this.isItemExist(formRoot.CurrentItems[y], false, formRoot, formRoot.FolderID);
                    if (isItemExist) {
                        this.itemExistsNotification(formRoot.CurrentItems[y], false);
                    } else {
                        this.sendRenameRequest(formRoot.CurrentItems[y], false, formRoot.FolderID);
                    }
                    formRoot.CurrentItems[y].rename = false;
                }
            }
        }
    }

    private sendRenameRequest(item: any, isFolder: boolean, parent: any) {
        if (isFolder) {
            item.FolderLabel = item.FolderText;
        } else {
            item.ItemLabel = item.ItemText;
        }
        let modifiedLabel = isFolder ? item.FolderLabel : item.ItemLabel;
        this.renameObject(item, modifiedLabel, "FORM", parent);
        item.rename = false;
    }

    private itemExistsNotification(item: any, isFolder: boolean) {
        let folder = isFolder ? item.FolderLabel : item.ItemLabel;
        if (isFolder) {//Change back to prevValue
            item.FolderLabel = item.prevValue;
            item.FolderText = item.prevValue;
        } else {
            item.ItemLabel = item.prevValue;
            item.ItemText = item.prevValue;
        }
        this.notificationMessage(Models.PromptMessageConstants.folderExistsPart1 + " " + folder + " " + Models.PromptMessageConstants.folderExistsPart2);
        item.prevValue = "";
    }

    private isItemExist(item: any, isFolder: boolean, formRoot: any, parent: any) {
        let found = false;
        if (isFolder) {
            item.FolderLabel = item.FolderText;
        } else {
            item.ItemLabel = item.ItemText;
        }
        let folder = isFolder ? item.FolderLabel : item.ItemLabel;
        //Check the value is empty or not
        if (this.validationService.isNullOrEmpty(folder)) {
            if (isFolder) {//Change back to prevValue
                item.FolderLabel = item.prevValue;
                item.FolderText = item.prevValue;
            } else {
                item.ItemLabel = item.prevValue;
                item.ItemText = item.prevValue;
            }
            item.rename = false;
            //Validate Folder Length not exceed 40 characters
        } else if (isFolder && folder.length > 40) {
            this.notificationMessage(Models.PromptMessageConstants.folderLengthValidation);
            item.FolderLabel = item.prevValue;
            item.FolderText = item.prevValue;
            item.prevValue = "";
        } else {
            if (isFolder && formRoot.FolderID == parent) {
                for (let i = 0; i < formRoot.Folders.length; i++) {
                    if (found) break;
                    if (formRoot.Folders[i].FolderLabel == item.FolderLabel && formRoot.Folders[i].FolderID != item.FolderID) {
                        found = true;
                        break;
                    }
                }
            } else if (!isFolder && formRoot.FolderID == parent) {
                for (let y = 0; y < formRoot.CurrentItems.length; y++) {
                    if (formRoot.CurrentItems[y].ItemLabel == item.ItemLabel && formRoot.CurrentItems[y].ItemID != item.ItemID) {
                        found = true;
                        break;
                    }
                }
            }
        }
        return found;
    }

    private selectRange(root: any, newest: any, oldest: any, selecting: any) {
        for (let y = 0; y < root.Folders.length; y++) {
            selecting = this.selectInRange(root.Folders[y], newest, oldest, selecting);
            selecting = this.selectRange(root.Folders[y], newest, oldest, selecting);
        }
        for (let x = 0; x < root.CurrentItems.length; x++) {
            selecting = this.selectInRange(root.CurrentItems[x], newest, oldest, selecting);
        }
        return selecting;
    }

    private selectInRange(thisItem: any, newest: any, oldest: any, selecting: any) {
        this.defaultNewAttributes(thisItem);
        let thisID = this.getItemID(thisItem);
        let newestID = this.getItemID(newest);
        let oldestID = this.getItemID(oldest);
        let selectThis = selecting;
        if ((thisID === oldestID) || (thisID === newestID)) {
            selectThis = true;
            selecting = !selecting;
        }
        if (selectThis) {
            // In select range, make sure item is selected
            if (!thisItem.highlight) {
                thisItem.highlight = true;
                thisItem.selectedSequence = this.nextSelectCounter++;
            }
        } else {
            // Ouside range, make sure item is unselected
            thisItem.highlight = false;
        }
        return selecting;
    }

    private getItemID(thisItem: any) {
        let thisID = "";
        if (thisItem.ItemID !== undefined) {
            thisID = thisItem.ItemID;
        } else {
            if (thisItem.FolderID !== undefined) {
                thisID = thisItem.FolderID;
            }
        }
        return thisID;
    }

    private defaultNewAttributes(item: any) {
        if (!_.has(item, Models.HelperText.Highlight)) {
            item.highlight = false;
        }
        if (!_.has(item, Models.HelperText.Rename)) {
            item.rename = false;
        }
    }

    private findOldestMultiSelect(root: any, oldest: any = undefined) {
        for (let x = 0; x < root.CurrentItems.length; x++) {
            if (root.CurrentItems[x].highlight) {
                if (oldest === undefined) {
                    oldest = root.CurrentItems[x];
                } else {
                    if (root.CurrentItems[x].selectedSequence < oldest.selectedSequence) {
                        oldest = root.CurrentItems[x];
                    }
                }
            }
        }

        for (let y = 0; y < root.Folders.length; y++) {
            if (root.Folders[y].highlight) {
                if (oldest === undefined) {
                    oldest = root.Folders[y];
                } else {
                    if (root.Folders[y].selectedSequence < oldest.selectedSequence) {
                        oldest = root.Folders[y];
                    }
                }
            }
            oldest = this.findOldestMultiSelect(root.Folders[y], oldest);
        }
        return oldest;
    }

    private disablePersonRename(formRoot: any) {
        let isItemExist = false;
        // input box disappears for folders
        if (formRoot.Folders.length > 0) {
            for (let i = 0; i < formRoot.Folders.length; i++) {
                if (formRoot.Folders[i].rename) {
                    this.renamePerson(formRoot.Folders[i], formRoot.FolderID, formRoot);
                }
                else if (formRoot.Folders[i].Folders.length > 0) {
                    this.disablePersonRename(formRoot.Folders[i]);
                } else if (formRoot.Folders[i].CurrentItems.length > 0) {
                    for (let x = 0; x < formRoot.Folders[i].CurrentItems.length; x++) {
                        if (formRoot.Folders[i].CurrentItems[x].rename) {
                            this.renamePerson(formRoot.Folders[i].CurrentItems[x], formRoot.Folders[i].FolderID, formRoot.Folders[i]);
                        }


                    }
                }

            }

        }
        // input box disappears for items
        if (formRoot.CurrentItems.length > 0) {
            for (let y = 0; y < formRoot.CurrentItems.length; y++) {
                if (formRoot.CurrentItems[y].rename) {
                    this.renamePerson(formRoot.CurrentItems[y], formRoot.FolderID, formRoot);
                }


            }
        }
    }

    private selectFolder(folder: any, personArray: any, overwrite: boolean = null) {
        // fills personArray w/ all people underneath selected folder w/ unique ItemValue
        // overwrite set to true does not allow for duplicates, use overwrite set to false to calculate number of items underneath a folder in _numItems()
        for (let x = 0; x < folder.CurrentItems.length; x++) {
            if (overwrite == false) {
                personArray.push(folder.CurrentItems[x]);
            }
            if (overwrite != false && this.selectedItemValues.indexOf(folder.CurrentItems[x].ItemValue) == -1) {
                this.selectedItemValues.push(folder.CurrentItems[x].ItemValue);
                personArray.push(folder.CurrentItems[x]);
            }
        }
        for (let y = 0; y < folder.Folders.length; y++) {
            this.selectFolder(folder.Folders[y], personArray, overwrite)
        }
    }

    private sortPersonsFavorites(personArray: any) {
        if (personArray.Folders != null && personArray.Folders.length > 0) {
            personArray.Folders.sort((a: any, b: any) => {
                let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
                let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

                if (folderA < folderB) {
                    return -1;
                }
                if (folderA > folderB) {
                    return 1;
                }
                return 0;
            });
            for (let i = 0; i < personArray.Folders.length; i++) {
                let tmp: any = personArray.Folders[i];
                if (tmp.CurrentItems.length > 0) {
                    tmp.CurrentItems.sort((a: any, b: any) => {
                        let person1 = a.ItemLabel;
                        let person2 = b.ItemLabel;

                        let firstPersonArray: any = a.ItemLabel.split(" ");
                        let secondPersonArray: any = b.ItemLabel.split(" ");

                        a.ItemLabel = this.reArrangePersons(firstPersonArray, a);
                        b.ItemLabel = this.reArrangePersons(secondPersonArray, b);

                        let itemA: string = !this.validationService.isNullOrEmpty(a.ItemLabel) ? a.ItemLabel.toLowerCase() : "";
                        let itemB: string = !this.validationService.isNullOrEmpty(b.ItemLabel) ? b.ItemLabel.toLowerCase() : "";

                        a.ItemLabel = person1;
                        b.ItemLabel = person2;

                        if (itemA < itemB)
                            return -1;
                        if (itemA > itemB)
                            return 1;
                        return 0;
                    });
                }
                if (tmp.Folders.length > 0) {
                    tmp.Folders.sort((a: any, b: any) => {
                        let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
                        let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

                        if (folderA < folderB) {
                            return -1;
                        }
                        if (folderA > folderB) {
                            return 1;
                        }
                        return 0;
                    });
                    this.sortPersonsFavorites(tmp);
                }
            }
        }
        if (personArray.currentItems != null && personArray.CurrentItems.length > 0) {
            personArray.CurrentItems.sort((a: any, b: any) => {
                let person1 = a.ItemLabel;
                let person2 = b.ItemLabel;

                let firstPersonArray: any = a.ItemLabel.split(" ");
                let secondPersonArray: any = b.ItemLabel.split(" ");

                a.ItemLabel = this.reArrangePersons(firstPersonArray, a);
                b.ItemLabel = this.reArrangePersons(secondPersonArray, b);

                let itemA: string = !this.validationService.isNullOrEmpty(a.ItemLabel) ? a.ItemLabel.toLowerCase() : "";
                let itemB: string = !this.validationService.isNullOrEmpty(b.ItemLabel) ? b.ItemLabel.toLowerCase() : "";

                a.ItemLabel = person1;
                b.ItemLabel = person2;

                if (itemA < itemB)
                    return -1;
                if (itemA > itemB)
                    return 1;
                return 0;
            });
        }
    }

    private sortFolders(a: any, b: any) {
        let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
        let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

        if (folderA < folderB) {
            return -1;
        }
        if (folderA > folderB) {
            return 1;
        }
        return 0;
    }

    private sortItems(a: any, b: any) {
        let person1 = a.ItemLabel;
        let person2 = b.ItemLabel;

        let firstPersonArray: any = a.ItemLabel.split(" ");
        let secondPersonArray: any = b.ItemLabel.split(" ");

        a.ItemLabel = this.reArrangePersons(firstPersonArray, a);
        b.ItemLabel = this.reArrangePersons(secondPersonArray, b);

        let itemA: string = !this.validationService.isNullOrEmpty(a.ItemLabel) ? a.ItemLabel.toLowerCase() : "";
        let itemB: string = !this.validationService.isNullOrEmpty(b.ItemLabel) ? b.ItemLabel.toLowerCase() : "";

        a.ItemLabel = person1;
        b.ItemLabel = person2;

        if (itemA < itemB)
            return -1;
        if (itemA > itemB)
            return 1;
        return 0;
    }

    private reArrangePersons(personArray: any, person: any) {
        //If the display name is 1 word or more than 3 words use as is without space for sorting
        if (personArray.length == 1 || personArray.length > 3) {
            person.ItemLabel = person.ItemLabel.replace(' ', '');
            //If the display name is 2 words use lastfirst (no space) for sorting
        } else if (personArray.length == 2) {
            person.ItemLabel = personArray[1] + personArray[0];
            person.ItemLabel = person.ItemLabel.replace(' ', '');
            //If the display name is 3 words use lastfirstmiddle (no space) without sorting
        } else if (personArray.length == 3) {
            person.ItemLabel = personArray[2] + personArray[0] + personArray[1];
            person.ItemLabel = person.ItemLabel.replace(' ', '');
        }
        return person.ItemLabel;
    }

    private setSequenceNumber(favoritesArray: any) {
        if (favoritesArray.Folders != null && favoritesArray.Folders.length > 0) {
            for (let i = 0; i < favoritesArray.Folders.length; i++) {
                let tmp = favoritesArray.Folders[i];
                tmp.Order = this.order;
                tmp.Level = i;
                tmp.ParentOrder = favoritesArray.Order != "" && favoritesArray.Order != null ? favoritesArray.Order : 0;
                this.folderLength = 0;

                //Assign length of the individual folders
                tmp.Length = this.setFolderLength(tmp);
                this.order = this.order + 1;

                if (tmp.Folders.length > 0) {
                    this.setSequenceNumber(tmp)
                }
                else if (tmp.CurrentItems.length > 0) {
                    for (let x = 0; x < tmp.CurrentItems.length; x++) {
                        tmp.CurrentItems[x].Order = this.order;
                        this.order = this.order + 1;
                        tmp.CurrentItems[x].Level = x;
                        tmp.CurrentItems[x].ParentOrder = tmp.Order;
                    }
                }
            }
        }

        if (favoritesArray.CurrentItems != null && favoritesArray.CurrentItems.length > 0) {
            for (let j = 0; j < favoritesArray.CurrentItems.length; j++) {
                favoritesArray.CurrentItems[j].Order = this.order;
                this.order = this.order + 1;
                favoritesArray.CurrentItems[j].Level = j;
                favoritesArray.CurrentItems[j].ParentOrder = favoritesArray.Order != "" && favoritesArray.Order != null ? favoritesArray.Order : 0;
            }
        }
    }

    private setFolderLength(folder: any) {
        this.folderLength = this.folderLength + (folder.Folders.length) + (folder.CurrentItems.length);

        for (let i = 0; i < folder.Folders.length; i++) {
            if (i > folder.Folders.length - 1) break;
            this.setFolderLength(folder.Folders[i]);
        }

        return this.folderLength;
    }

    private sortFormsFavorites(fromArray: any) {
        if (fromArray.Folders != null && fromArray.Folders.length > 0) {
            fromArray.Folders.sort((a: any, b: any) => {
                let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
                let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

                if (folderA < folderB) {
                    return -1;
                }
                if (folderA > folderB) {
                    return 1;
                }
                return 0;
            });
            for (let i = 0; i < fromArray.Folders.length; i++) {
                let tmp = fromArray.Folders[i];
                if (tmp.CurrentItems.length > 0) {
                    tmp.CurrentItems.sort((a: any, b: any) => {
                        let itemA: string = !this.validationService.isNullOrEmpty(a.ItemLabel) ? a.ItemLabel.toLowerCase() : "";
                        let itemB: string = !this.validationService.isNullOrEmpty(b.ItemLabel) ? b.ItemLabel.toLowerCase() : "";
                        if (itemA < itemB) //sort string ascending
                            return -1;
                        if (itemA > itemB)
                            return 1;
                        return 0; //default return value (no sorting)
                    });
                }
                if (tmp.Folders.length > 0) {
                    tmp.Folders.sort((a: any, b: any) => {
                        let folderA: string = !this.validationService.isNullOrEmpty(a.FolderLabel) ? a.FolderLabel.toLowerCase() : "";
                        let folderB: string = !this.validationService.isNullOrEmpty(b.FolderLabel) ? b.FolderLabel.toLowerCase() : "";

                        if (folderA < folderB) {
                            return -1;
                        }
                        if (folderA > folderB) {
                            return 1;
                        }
                        return 0;
                    });
                    this.sortFormsFavorites(tmp)
                }
            }
        }
        if (fromArray.CurrentItems != null && fromArray.CurrentItems.length > 0) {
            fromArray.CurrentItems.sort((a: any, b: any) => {
                let itemA: string = !this.validationService.isNullOrEmpty(a.ItemLabel) ? a.ItemLabel.toLowerCase() : "";
                let itemB: string = !this.validationService.isNullOrEmpty(b.ItemLabel) ? b.ItemLabel.toLowerCase() : "";
                if (itemA < itemB) //sort string ascending
                    return -1;
                if (itemA > itemB)
                    return 1;
                return 0; //default return value (no sorting)
            });
        }
    }

    private sortFormItems(a: any, b: any) {
        let itemA: string = !this.validationService.isNullOrEmpty(a.ItemLabel) ? a.ItemLabel.toLowerCase() : "";
        let itemB: string = !this.validationService.isNullOrEmpty(b.ItemLabel) ? b.ItemLabel.toLowerCase() : "";
        if (itemA < itemB) //sort string ascending
            return -1;
        if (itemA > itemB)
            return 1;
        return 0; //default return value (no sorting)
    }

    private generateUid() { // creates unique UID from UTC seconds value
        return this.uidPrefix + Date.now() + this.uidCounter++;
    }

    private sendUpdateRequest(resolveObject: any, request: any, successCallBack: any, errorCallBack: any, inst: any) {
        let url: string = [this.configurationService.getConfiguration().serviceUrl, Models.HelperText.favorites, Models.HelperText.update, this.sessionService.getToken(), this.sessionService.getControlId()].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.http.post(url, request, httpOptions).timeoutWith(30000, Observable.throwError(new Error("Timeout Occurred."))).subscribe(
            (response: any) => {
                if (response[Models.HelperText.Errors] && response.Errors.length > 0) {
                    if (!this.validationService.isNullOrEmpty(errorCallBack))
                        console.error(response.Errors[0]); // application error
                } else {
                    if (!this.validationService.isNullOrEmpty(successCallBack))
                        successCallBack(inst, resolveObject);
                }
            }, (error: any) => {
                console.error(error);
                if (!this.validationService.isNullOrEmpty(errorCallBack))
                    errorCallBack(inst, error);
            });
    }
}