/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Input } from "@angular/core";
import { EventsService, FavoritesService, FocusService, SearchService, RootScopeService } from "../../services";
import * as Models from "../../models";

declare var $: any;

@Component({
    selector: 'favoritesform',
    template: require('./favorites-form.component.html')
})
export class FavoritesFormComponent {
    @Input() root: any;

    constructor(private favoritesService: FavoritesService,
        private focusService: FocusService,
        private searchService: SearchService,
        private pubSubService: EventsService,
        private rootScopeService: RootScopeService) {

    }

    folderKeyDown(item: any, rt: any, event: any) {
        // Tab should focus on the Open button in the favorites footer
        if (!event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#open-favorites-btn");
        }
        // Shift + Tab should focus on the person favorites options menu icon
        else if (event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#favorite-form-options-link");
        }
        else if (event.keyCode === Models.KeyCodes.space) {
            this.favoritesService.selectFormItem(null, item, true, rt, rt.FolderID, event);
        }
        else if (event.keyCode !== Models.KeyCodes.space) {
            this.favoritesService.formFavoritesShortCutKey(event, item, rt, true, rt.FolderID);
        }
    }

    itemKeyDown(item: any, rt: any, event: any) {
        // Tab should focus on the Open button in the favorites footer
        if (!event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#open-favorites-btn");
        }
        // Shift + Tab should focus on the person favorites options menu icon
        else if (event.shiftKey && event.keyCode == Models.KeyCodes.tab) {
            event.preventDefault();
            event.stopPropagation();
            this.focusService.focusOn("#favorite-form-options-link");
        }
        else if (item.rename != true) {
            if (event.keyCode === Models.KeyCodes.space) {
                this.favoritesService.selectFormItem(null, item, false, rt, rt.FolderID, event);
            }
            else if (event.keyCode !== Models.KeyCodes.space) {
                this.favoritesService.formFavoritesShortCutKey(event, item, rt, false, rt.FolderID);
            }
        }
    }

    openFavoriteForm(item: any) {
        let selectedForm: string = "";
        if (item.ItemValue) {
            selectedForm = item.ItemValue;
        }
        if (selectedForm !== "") {
            this.searchService.toggleVisible(false);
            this.pubSubService.broadcast('enableFormSearch');
            this.pubSubService.broadcast(Models.EventConstants.openForm, selectedForm);
        }
        this.rootScopeService.favoritePanelOpen = false;
        $("#favorites .modal").modal('hide');
    }
}