/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigurationService, EventsService, UiLocalStorageService, PreferencesService, SearchService, SessionService } from "./";

declare var $: any;
import * as _ from 'lodash';

@Injectable()
export class NavigateService {
    selectedApplication: any = null;
    applications: any = [];
    applicationsMenu: any = [];
    fullApplications: any = {};
    disabled: boolean = false;
    treeTemplate: any = {
        Menu: {
            CurrentItems: [],
            Folders: [],
        }
    };

    constructor(private uiLocalStorageService: UiLocalStorageService,
        private sessionService: SessionService,
        private searchService: SearchService,
        private configurationService: ConfigurationService,
        private preferencesService: PreferencesService,
        private http: HttpClient,
        private eventsService: EventsService) {
        this.eventsService.on("openNavigate", [this.openNavigateListener, this]);
    }

    openNavigateListener(navigateService: NavigateService) {
        let self: NavigateService = <NavigateService>navigateService;
        self.openNavigate();
    }

    /**
     * Sets up the data for navigate dialog
     */
    openNavigate() {
        this.colleagueApps();

        // Instantiate the skeleton for applications
        _.each(this.applications, (app: any) => {
            if (this.fullApplications[app] == null) {
                let appTree: any = {};
                _.defaults(appTree, this.treeTemplate);
                this.fullApplications[app] = appTree;
            }
        });

        this.currentApplication(this.selectedApplication);

        if (this.applicationsMenu.length == 0) {
            this.getMenu(this.selectedApplication, this.selectedApplication, this.selectedApplication, this.getMenuCallBack, this);
        }

        this.searchService.resetState();
        this.searchService.state.ShowNavigationMenu = true;
    }

    /**
     * Default applications to browse from preferences
     * @returns {any}
     */
    colleagueApps() {
        this.applications = this.preferencesService.validApps();
        return this.applications;
    }

    /**
     * Gets the selected application
     * @param navApplication
     * @returns {any}
     */
    currentApplication(navApplication: any = null): void {
        if (navApplication == null) {
            let preApplication = this.uiLocalStorageService.get("nav");
            this.selectedApplication = preApplication !== undefined ? preApplication : this.applications[0];
        }
        else {
            this.selectedApplication = navApplication !== null ? navApplication : this.applications != null ? this.applications[0] : null;
        }

        // Move focus to current selected application
        setTimeout(() => {
            $("#" + this.selectedApplication + ">.btn-application-menu").trigger("focus");
        }, 250);
        // Put the selected application into local storage
        this.uiLocalStorageService.set("nav", this.selectedApplication);

        return this.selectedApplication;
    }

    /**
     * Get the menu items for navigation including all folders
     * @param application
     * @param mnemonic
     * @param parentMnemonic
     */
    getMenu(application: any, mnemonic: string, parentMnemonic: string, callBack: any, inst: any): void {
        this.disabled = true;
        console.log("getMenu disabled navigate service");
        let request: any = {
            OperatorID: this.sessionService.getOperatorId(),
            Application: application,
            MenuMnemonic: mnemonic,
            MenuLevels: 1, // Only gets data one level deep
            ParentMnemonic: parentMnemonic
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let url = [this.configurationService.getConfiguration().serviceUrl, 'navigation', this.sessionService.getToken(), this.sessionService.getControlId()].join('/');

        this.http.post(url, request, httpOptions).subscribe(
            (response: any) => {
                if (response.hasOwnProperty("Errors") && response.Errors.length > 0) {
                    let errorMessages: any = '';
                    _.each(response.Errors, (it: any) => {
                        errorMessages += it.ErrorMessageText;
                    });
                    console.error(errorMessages);
                } else {
                    this.disabled = false;
                    console.log("getMenu enabled navigate service");
                    callBack(response, inst);
                }
            });
    }

    addParent(menuResponse: any): any {
        if (menuResponse.Menu.Folders.length > 0) {
            // if (menuResponse && menuResponse.Menu && menuResponse.Menu.Folders && menuResponse.Menu.Folders.length > 0) {
            for (let i = 0; i < menuResponse.Menu.Folders.length; i++) {
                menuResponse.Menu.Folders[i].Parent = menuResponse.Menu.Mnemonic;
                if (menuResponse.Menu.Folders[i].Folders.length > 0) {
                    for (let j = 0; j < menuResponse.Menu.Folders[i].Folders.length; j++) {
                        menuResponse.Menu.Folders[i].Folders[j].Parent = menuResponse.Menu.Folders[i].Mnemonic;
                    }
                }
            }
        }
        return menuResponse;
    }

    collapseChildFolders(folder: any) {
        for (let i = 0; i < folder.Folders.length; i++) {
            let tmp: any = folder.Folders[i];
            if (tmp.Folders.length > 0 && folder.IsOpen) {
                this.collapseChildFolders(tmp);
            } else {
                tmp.IsOpen = false;
            }
        }
        folder.IsOpen = false;
    }

    private getMenuCallBack(menuResponse: any, navigateService: any) {
        let self: NavigateService = <NavigateService>navigateService;
        let resp: any = self.addParent(menuResponse);
        self.fullApplications[self.selectedApplication].Menu = resp.Menu;
        self.applicationsMenu.push({
            Application: self.currentApplication,
            menu: resp.Menu
        });
        //store it in service to use frequent selection
        self.disabled = false;
        console.log("getMenuCallBack enabled navigate service");
        setTimeout(() => {
            $("#" + self.selectedApplication + ">.btn-application-menu").trigger("focus");
        }, 250);
    }
}