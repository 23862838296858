/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Inject, Injectable} from "@angular/core";
import {EventsService, RootScopeService, ValidationService} from "./";

import * as _ from 'lodash';

@Injectable()
export class MessageHandlerService {
    typeAttribute: string = "QueueMessageType";

    constructor(private eventsService: EventsService, private rootScopeService: RootScopeService, private validationService: ValidationService) {
    }

    parseMessages(messages: any, isQueueMessage: boolean) {
        if (messages && isQueueMessage) {
            let serverMessages = _.isArray(messages.Messages.QueueMessage) ? messages.Messages.QueueMessage : messages.Messages;
            // Process messages of type HideHeaderFieldsMessage
            _.each(serverMessages, (it: any) => {
                let type: string = it[this.typeAttribute];
                if (type == "FormStateMessage") {
                    this.eventsService.broadcast(type, it);
                }

                if (type == "HideHeaderFieldsMessage") {
                    this.eventsService.broadcast(type, it);
                }

                // check if there are multiple Field Security messages for same field. Ignore all except last one in case of multiple.
                if (type == "FieldSecurityMessage" && it["ignore"] != true) {
                    _.each(serverMessages, (msg: any) => {
                        if (msg[this.typeAttribute] == "FieldSecurityMessage" && msg["ignore"] != true && msg["FormID"] == it["FormID"] &&
                            msg["FieldName"] == it["FieldName"] && msg["SecuritySetting"] != it["SecuritySetting"]) {
                            it.ignore = true;
                        }
                    })
                }
            });

            // Process messages other than HideHeaderFieldsMessage
            _.each(serverMessages, (it: any) => {
                let type: string = it[this.typeAttribute];
                if (type != "HideHeaderFieldsMessage" && type != "FormStateMessage" && it.ignore != true) {
                    this.eventsService.broadcast(type, it);

                }

            });
        }
    }
}