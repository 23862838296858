/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Injectable} from "@angular/core";
import {EventsService} from "./";
import * as Models from "../models";

import * as _ from 'lodash';

@Injectable()
export class SessionStorage {
    prefix: string = "";

    constructor(private eventsService: EventsService) {
        if (typeof sessionStorage == "undefined" || sessionStorage == null) {
            console.warn("This browser does not support session storage");
        }
        this.eventsService.on(Models.EventConstants.logoutComplete, [this.logoutCompleteMessageListener, this]);
    }

    get(key: string) {
        let localKey = this.prefix + key;
        let rawObject = sessionStorage[localKey];

        return (rawObject && (rawObject.indexOf('{') == 0 || rawObject.indexOf('[') == 0 ) ) ? JSON.parse(rawObject) : rawObject;
    }

    set(key: string, value: any) {
        let preservedValue = value;
        let localKey = this.prefix + key;
        if (value) {
            if (_.isObject(value) || _.isArray(value)) {
                preservedValue = JSON.stringify(preservedValue);
            }

            sessionStorage[localKey] = preservedValue;
        }
    }

    remove(key: string) {
        let localKey = this.prefix + key;
        let value = sessionStorage[localKey];

        if (value) {
            delete sessionStorage[localKey];
        }
    }

    setPrefix(prefix: string) {
        if (_.isString(prefix)) {
            this.prefix = prefix;
        }
    }

    logoutCompleteMessageListener() {
        sessionStorage.clear();
    }
}