/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit } from '@angular/core';
import { ProcessingService } from "../../services";

@Component({
    selector: 'processing',
    template: require('./processing.component.html')
})
export class ProcessingComponent implements OnInit {

    constructor(private processingService: ProcessingService) {

    }

    ngOnInit() {
        let textArea = document.getElementById('waitDivInput');
        textArea.addEventListener("focus", () => {
            textArea.style.height = textArea.scrollHeight + "px";
        });
    }
}