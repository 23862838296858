/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable } from '@angular/core';
import * as Rx from 'rxjs/Rx';

import { HelperService } from "./"
import { EventParams } from "../models"

@Injectable()
export class EventsService {
    listeners: any = {};
    eventsSubject: Rx.Subject<EventParams>;
    events: Rx.Observable<EventParams>;

    /**
        CTOR
     */
    constructor(private helperService: HelperService) {
        this.listeners = {};

        // Subject that will notify subscribers by calling on observer.next
        this.eventsSubject = new Rx.Subject();

        // Observable from the Subject. Consumers can subscribe to this observable to get notified when subject triggers observer.next
        this.events = Rx.Observable.from(this.eventsSubject);

        // Subscribe to the observable. whenever the observable calls observer.next, the callback inside subscribe will be invoked
        this.events.subscribe(
            ({ name, args }) => {
                if (this.listeners[name]) {
                    for (let listener of this.listeners[name]) {
                        if (listener.callbackParams.length == 2)
                            listener.callbackParams[0](listener.callbackParams[1], ...args);
                        else {
                            listener.callbackParams[0](...args);
                        }
                    }
                }
            });
    }


    /**
        Subscribe to a global event.

        @param name name of the event to subscribe

        @param callbackParams dynamic array with 2 elements  1st element is callback function which can accept 2 params (1st will the param of your choice and 2nd will be passed by broadcaster) 2nd paramter is object of callers choice (mostly pass the instance of component or provider)

        @return subscriptionId: string  *note:  maintain it as it will be used for destroying the subscription when done.
     */
    on(name: string, callbackParams: any): string {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        let id = this.helperService.NewGuid();

        this.listeners[name].push({ subscriptionId: id, callbackParams: callbackParams });

        return id;
    }


    /**
        Broadcast an event

        @param name name of the event to broadcast

        @param ...args array of data that needs to be broadcasted.
     */
    broadcast(name: string, ...args: any[]) {
        this.eventsSubject.next({
            name,
            args
        });
    }

    /**
        Destroy the event listener

        @param name name of the event to destroy

        @param subscriptionId id of event subscription
     */
    destroy(name: string, subscriptionId: string) {
        if (this.listeners[name]) {
            for (let i = 0; i < this.listeners[name].length; i++) {
                if (subscriptionId == this.listeners[name][i].subscriptionId) {
                    this.listeners[name].splice(i, 1);
                    i--;
                }
            }
        }
    }
}

