/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { FocusService, HelperService, ProcessingService, PromptService, RootScopeService, ValidationService } from "./";
import * as Models from "../models";

declare var $: any;

@Injectable()
export class SubMenuDialogService {
    subMenuItems: any = {};
    isOpen: boolean;

    constructor(private helperService: HelperService, private validationService: ValidationService, private focusService: FocusService,
        private notificationsService: PromptService, private processingService: ProcessingService, private rootScopeService: RootScopeService) {

    }

    showHelp() {
        this.subMenuItems.askingForHelp = false;
        let helpMessage: any = {};
        helpMessage.title = Models.DialogContants.help;
        helpMessage.text = [Models.DialogContants.dialogMessage];
        helpMessage.defaultCallbackNumber = 0;
        helpMessage.buttons = [
            {
                label: Models.LabelConstants.ok,
                callback: () => {
                    // When help message has totally faded away, bring back the detail dialog
                    this.notificationsService.popupMessageDismiss(() => {

                        this.processingService.closeProcessing();
                        $('#detail-menu .modal').modal({
                            backdrop: Models.DialogContants.staticStr,
                            show: true,
                            keyboard: false
                        }).draggable();
                    }, false);
                },
                title: ""
            }
        ];
        this.notificationsService.popupMessageShow(helpMessage);
    }

    close(returnedMnemonic: string, forceClose: boolean = false) {
        let detailCallback: any = this.subMenuItems.detailCallback;
        this.subMenuItems.menuItems = [];
        this.subMenuItems.inputValue = "";
        this.subMenuItems.askingForHelp = false;
        this.subMenuItems.returnedMnemonic = '';
        this.subMenuItems.detailCallback = undefined;
        if (forceClose == false)
            detailCallback(returnedMnemonic);
        this.isOpen = false;
        $('#detail-menu .modal').modal('hide');
    }

    openSubMenuDialog(menuItems: any, detailCallBack: any): void {
        // Initialize dialog state and list of mnemonic to show
        this.subMenuItems.menuItems = menuItems;
        this.subMenuItems.inputValue = "";
        this.subMenuItems.askingForHelp = false;
        this.subMenuItems.returnedMnemonic = '';
        this.subMenuItems.detailCallback = detailCallBack;
        this.subMenuItems.announcementText = this.detailMenuAnnouncementText();
        this.isOpen = true;
        // Highlight the first item
        this.subMenuItems.selectedItem = this.subMenuItems.menuItems[0];


        // Show the modal dialog
        $('#detail-menu .modal').modal({
            backdrop: 'static',
            show: true,
            keyboard: false
        });

        setTimeout(() => {
            this.focusService.focusOn("#detail-menu-input");
        }, 250);
    }

    private detailMenuAnnouncementText(): string {
        let announcementText: string = "Use the up and down arrows to select from the list or enter a form mnemonic directly.";
        if (this.subMenuItems.menuItems.length > 0) {
            announcementText = "This dialog contains the following items: ";
            for (let i = 0; i < this.subMenuItems.menuItems.length; i++) {
                let currentItem: any = this.subMenuItems.menuItems[i];
                announcementText += this.helperService.formatMnemonic(currentItem.ItemMnemonic) + " - " + currentItem.ItemDescription + " row " + (i + 2) + " of " + (this.subMenuItems.menuItems.length + 1) + ". ";
            }
            announcementText += "Enter the mnemonic of the form to execute";
        }
        return announcementText;
    };
}