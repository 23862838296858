/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
    HelpService,
    RootScopeService,
    EventsService,
    SearchService,
    ConfigurationService,
    UiLocalStorageService
} from "../../../services"
import * as Models from "../../../models"

import * as _ from 'lodash';

@Component({
    selector: 'formsearchhistory',
    template: require('./formSearchHistory.component.html')
})
export class FormSearchHistoryComponent implements OnInit, OnDestroy {

    updateFormMatchItemsListener: string;

    constructor(private searchService: SearchService, private uiLocalStorageService: UiLocalStorageService,
        private configurationService: ConfigurationService,
        private helpService: HelpService, private rootScopeService: RootScopeService, private eventsService: EventsService) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.eventsService.destroy(Models.CommandConstants.updateFormMatchItems, this.updateFormMatchItemsListener);
    }

    criteria: any;

    ClearAllhistory() {

        while (this.searchService.criteria.formHistory.length > 0) {
            this.searchService.criteria.formHistory.pop();
        }
        this.uiLocalStorageService.set(Models.LocalStorageConstants.formSearchHistory, this.searchService.criteria.formHistory);
        this.eventsService.broadcast('ClearSearchInput');
        while (this.searchService.criteria.autoCompleteMatch != null && this.searchService.criteria.autoCompleteMatch.length > 0) {
            this.searchService.criteria.autoCompleteMatch.pop();
        }

        while (this.rootScopeService.criteria.autoCompleteMatch.length > 0) {
            this.rootScopeService.criteria.autoCompleteMatch.pop();
        }
    }

    clearSearchHistoryFormHelp() {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses.ClearHistoryForm);
    };

    closePopover() {
        this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);

        setTimeout(() => {
            this.searchService.focusOnSearchInput();
        }, 150);
    }

    removeForm(formToRemove: string) {
        _.remove(this.searchService.criteria.autoCompleteMatch, function (form: string) {
            return (form === formToRemove);
        });
        _.remove(this.rootScopeService.criteria.autoCompleteMatch, function (form: string) {
            return (form === formToRemove);
        });

        _.remove(this.searchService.criteria.formHistory, function (form: string) {
            return (form === formToRemove);
        });
        this.uiLocalStorageService.set(Models.LocalStorageConstants.formSearchHistory, this.searchService.criteria.formHistory);
    }

    formatMnemonic(mnemonic: string) {
        if (mnemonic != null && mnemonic.length > 0) {
            let newMnemonic = "";
            let arr = mnemonic.split(':');
            let mnemonicName: string = "";
            let formName: string = "";

            if (arr.length > 1) {
                mnemonicName = arr[0];
                formName = arr[1];
            }

            if (mnemonicName != "") {
                for (let i = 0; i < mnemonicName.length; i++) {
                    newMnemonic += (mnemonic[i] + " ");
                }

                newMnemonic += ": " + formName;
            }
            else {
                for (let i = 0; i < mnemonic.length; i++) {
                    newMnemonic += (mnemonic[i] + " ");
                }
            }

            return newMnemonic;
        }
        return "";
    }

    filterFormHistory(form: string) {
        if (form.indexOf(' $$$') > 0) {
            return form.substring(0, form.indexOf(' $$$'));
        }
        return form;
    }

    selectFromHistory(form: string) {
        this.closePopover();
        this.eventsService.broadcast("InitiateFormSearch", form.substring(0, form.indexOf(" $$$")));
    }

    /**
     * Form Search History keyboard shortcut for Help
     * @param event
     */
    formSearchHistoryKeydown(event: any) {
        let keyCtrl = event.ctrlKey;
        let keyAlt = event.altKey;
        let keyCode = event.keyCode;
        let keyCharCode = event.charCode;
        let keyChar = String.fromCharCode(keyCode || keyCharCode);

        if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
            event.stopPropagation();
            event.preventDefault();
            this.clearSearchHistoryFormHelp();
        }
        else {
            return;
        }
    }
}