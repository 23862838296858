/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, Inject, Input, OnInit } from "@angular/core";
import {
    RootScopeService,
    SearchResultsService,
    PromptService,
    SearchService,
    ValidationService,
    ProcessingService,
    PreferencesService
} from "../../../services";
import * as Models from "../../../models";

declare var $: any;
import * as _ from 'lodash';
import { DataType } from "../../../models";

@Component({
    selector: 'grid',
    template: require('./grid.component.html')
})
export class GridComponent implements OnInit {
    @Input() data: any;
    // tabIndex: number = 12;
    lastSelectedRowNumber: number = -1;
    currentContext: string = "";
    private disableSort: boolean = false;
    private intervalCount: number = 0;

    constructor(private searchResultsService: SearchResultsService,
        private rootScopeService: RootScopeService,
        private validationService: ValidationService,
        private notificationsService: PromptService,
        private processingService: ProcessingService,
        private searchService: SearchService,
        private preferencesService: PreferencesService) {

    }

    emptyRange() {
        let totalNonFavoriteFields = this.searchResultsService.gridColumnOrders[this.currentContext].length - this.data.layout.favoriteFields.length;
        let result: any = [];

        for (let i = 0; i < totalNonFavoriteFields; i++) {
            result.push(i);
        }
        return result;
    }

    ngOnInit() {
        this.searchResultsService.setPrivacyFlag(this.data);
        this.currentContext = this.data.layout.context;
        var headerFields = this.searchResultsService.gridColumnOrders[this.currentContext];
        if (headerFields == null) {
            this.searchResultsService.gridColumnOrders[this.currentContext] = this.data.layout.headerFields;
        }

        let dragTableInitializationInterval = setInterval(() => {
            this.intervalCount++;
            if (this.data && this.data.results && this.data.results.data && this.data.results.data.length > 0) {
                clearInterval(dragTableInitializationInterval);
                $('#tblGridComponent').dragtable({
                    maxMovingRows: 1,
                    persistState: (table: any) => {
                        this.searchResultsService.gridColumnOrders[this.currentContext].move(table.startIndex - 1, table.endIndex - 1);
                    }
                });
            }
            else if (this.intervalCount == 20) { // clear interval after 20 tries i.e 10 seconds if the grid is not loaded by that time.
                clearInterval(dragTableInitializationInterval);
            }
        }, 500);
    }


    cellKeyDown(event: any): void {
        // don't do anything if the target is not grid row or grid column
        if (!($(event.target).hasClass('searchResultRow') || $(event.target).hasClass('searchResultColumn'))) {
            return;
        }

        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let spacebar: boolean = event.keyCode == Models.KeyCodes.space;
        let leftArrow: boolean = event.keyCode == Models.KeyCodes.left;
        let upArrow: boolean = event.keyCode == Models.KeyCodes.up;
        let rightArrow: boolean = event.keyCode == Models.KeyCodes.right;
        let downArrow: boolean = event.keyCode == Models.KeyCodes.down;
        let shift: boolean = event.shiftKey;
        let pageDown: boolean = event.keyCode == Models.KeyCodes.pageDown;
        let pageUp: boolean = event.keyCode == Models.KeyCodes.pageUp;
        let end: boolean = event.keyCode == Models.KeyCodes.end;
        let home: boolean = event.keyCode == Models.KeyCodes.home;
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;

        //return if key is not a valid key for navigation
        if ((!spacebar && !tab && !upArrow && !downArrow && !leftArrow && !rightArrow && !pageDown && !pageUp && !home && !end) || enter) {
            return;
        }
        else {
            event.preventDefault();
            event.stopPropagation();
        }

        let continueLoop: boolean = true;
        if (!shift && tab) { // tab out the grid to first enabled element in footer
            let currentTabIndex: number = event.target.tabIndex;
            do {
                let nextTabIndex: number = currentTabIndex + 1;
                let element: any = $("[tabindex=" + nextTabIndex + "]")[0];
                if (element == null) {
                    continueLoop = false;
                }
                else if (element != null && element.disabled == true) {
                    currentTabIndex = nextTabIndex;
                }
                else {
                    element.focus();
                    continueLoop = false;
                }
            }
            while (continueLoop);
            return;
        }
        else if (shift && tab) { // shift tab out the grid to first enabled element in header
            let previousElements: any = $("#tblGridComponent th[tabindex=" + $(event.target)[0].tabIndex + "]");
            if (previousElements) {
                let element: any = $(previousElements[previousElements.length - 1]);
                if (element && element.disabled != true) {
                    element.focus();
                }
            }
        }
        else if (rightArrow) {
            this.rightArrowKeyEvent(event);
        }
        else if (leftArrow) {
            this.leftArrowKeyEvent(event);
        }
        else if (upArrow) {
            if ($(event.target).hasClass('searchResultRow')) {
                let row: any = $(event.target)[0];
                let rowIndex: number = row.rowIndex;
                if (rowIndex == 1) { // navigate to previous page when on first row
                    let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                    this.searchResultsService.previousPage(this.data, currentRowNumber - 1, -1);
                }
                else if (rowIndex > 1) {
                    $(row).prev().trigger("focus");
                }
            }
            else if ($(event.target).hasClass('searchResultColumn')) {
                let cellIndex: number = $(event.target)[0].cellIndex;
                let row: any = $(event.target)[0].parentNode;
                if (row) {
                    let rowIndex: number = row.rowIndex;
                    if (rowIndex == 1) { // navigate to previous page when on first row
                        let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                        this.searchResultsService.previousPage(this.data, currentRowNumber - 1, cellIndex);
                    }
                    else {
                        let prevRow: any = row.previousElementSibling;
                        if (prevRow) {
                            let cellToFocus: any = $(prevRow)[0].children[cellIndex];
                            if (cellToFocus) {
                                cellToFocus.focus();
                            }
                            else {
                                cellToFocus = $(prevRow)[0].children[cellIndex - 1];
                                if (cellToFocus) {
                                    cellToFocus.focus();
                                }
                                else {
                                    prevRow.focus();
                                }
                            }
                        }
                    }
                }
            }
        }
        else if (downArrow) {
            if ($(event.target).hasClass('searchResultRow')) {
                let row: any = $(event.target)[0];
                let rowIndex: number = row.rowIndex;
                // If this is the last record in the page, move to next page
                if (rowIndex == (this.data.results.cursorSize)) {
                    let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                    this.searchResultsService.nextPage(this.data, currentRowNumber + 1, -1);
                }
                else {
                    $(row).next().trigger("focus");
                }
            }
            else if ($(event.target).hasClass('searchResultColumn')) {
                let cellIndex: number = $(event.target)[0].cellIndex;
                let row: any = $(event.target)[0].parentNode;
                if (row != null) {
                    let rowIndex: number = row.rowIndex;
                    // If this is the last record in the page, move to next page
                    if (rowIndex == (this.data.results.cursorSize)) {
                        let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                        this.searchResultsService.nextPage(this.data, currentRowNumber + 1, cellIndex);
                    }
                    else {
                        let nextRow: any = row.nextElementSibling;
                        if (nextRow != null) {
                            let cellToFocus: any = $(nextRow)[0].children[cellIndex];
                            if (cellToFocus) {
                                cellToFocus.focus();
                            }
                            else {
                                cellToFocus = $(nextRow)[0].children[cellIndex - 1];
                                if (cellToFocus) {
                                    cellToFocus.focus();
                                }
                                else {
                                    nextRow.focus();
                                }
                            }
                        }
                    }
                }
            }
        }
        else if (pageDown) {
            if ($(event.target).hasClass('searchResultRow')) {
                let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                let newRowNumber: number = (currentRowNumber + this.data.control.cursorSize) >= this.data.results.totalRows
                    ? this.data.results.totalRows - 1
                    : currentRowNumber + this.data.control.cursorSize;
                this.searchResultsService.nextPage(this.data, newRowNumber, -1);
            }
            else if ($(event.target).hasClass('searchResultColumn')) {
                let cellIndex: number = $(event.target)[0].cellIndex;
                let row: any = $(event.target)[0].parentNode;
                if (row != null) {
                    let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                    let newRowNumber: number = (currentRowNumber + this.data.control.cursorSize) >= this.data.results.totalRows
                        ? this.data.results.totalRows - 1
                        : currentRowNumber + this.data.control.cursorSize;
                    this.searchResultsService.nextPage(this.data, newRowNumber, cellIndex);
                }
            }
        }
        else if (pageUp) {
            if ($(event.target).hasClass('searchResultRow')) {
                let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                let newRowNumber: number = (currentRowNumber - this.data.control.cursorSize) < 1
                    ? 0
                    : currentRowNumber - this.data.control.cursorSize;
                this.searchResultsService.previousPage(this.data, newRowNumber, -1);
            }
            else if ($(event.target).hasClass('searchResultColumn')) {
                let cellIndex: number = $(event.target)[0].cellIndex;
                let row: any = $(event.target)[0].parentNode;
                if (row != null) {
                    let currentRowNumber: number = parseInt($(event.target).attr("id").split("_")[2]);
                    let newRowNumber: number = (currentRowNumber - this.data.control.cursorSize) < 1
                        ? 0
                        : currentRowNumber - this.data.control.cursorSize;
                    this.searchResultsService.previousPage(this.data, newRowNumber, cellIndex);
                }
            }
        }
        else if (end) {
            this.searchResultsService.lastPage(this.data);
        }
        else if (home) {
            this.searchResultsService.firstPage(this.data);
        }
        else if (spacebar) {
            this.cellClick(event);
        }
    }

    cellClick(event: any): void {

        // don't do anything if the target is not grid row or grid column
        if (!($(event.target).hasClass('searchResultRow') || $(event.target).hasClass('searchResultColumn'))) {
            return;
        }
        let idParts = $(event.target).attr("id").split("_");
        let currentRowNumber: number = parseInt(idParts[idParts.length - 1]);
        let startRowNumber: number = currentRowNumber - (currentRowNumber % this.data.control.cursorSize);

        let shift: boolean = event.shiftKey;
        // If lastSelectedRowNumber falls outside the current page's row numbers, consider it as a normal click.
        if (!(this.lastSelectedRowNumber >= startRowNumber && this.lastSelectedRowNumber <= (startRowNumber + this.data.control.cursorSize))) {
            shift = false;
        }

        // metaKey is used to record Ctrl click for mac
        let ctrl: boolean = event.ctrlKey || event.metaKey;
        let spacebar: boolean = event.keyCode == Models.KeyCodes.space;

        // Toggles the selection of a row while retaining any other row selections.
        if (ctrl || (spacebar && !shift)) {
            this.toggleRowSelection(event);
        }
        // Toggles a range of rows
        else if (shift) {
            let startRow = Math.min(this.lastSelectedRowNumber, currentRowNumber);
            let endRow = Math.max(this.lastSelectedRowNumber, currentRowNumber);

            for (let rowNumber = startRowNumber; rowNumber < startRowNumber + this.data.control.cursorSize; rowNumber++) {
                let rowData = this.data.results.data[rowNumber];
                if (rowNumber >= startRow && rowNumber <= endRow) {
                    rowData.recordSelected = true;
                    let index: number = this.rootScopeService.selectedRowNumbers.indexOf(rowData["@ID"]);
                    if (index == -1) {
                        this.rootScopeService.selectedRowNumbers.push(rowData["@ID"]);
                    }

                    // Remove this record from unselectedRowNumbers if it exists
                    index = this.rootScopeService.unselectedRowNumbers.indexOf(rowData["@ID"]);
                    if (index !== -1) {
                        this.rootScopeService.unselectedRowNumbers.splice(index, 1);
                    }

                    this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                        ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                        : this.rootScopeService.selectedRowNumbers.length;
                }
                else {
                    if (rowData.recordSelected == true) {
                        let index: number = this.rootScopeService.selectedRowNumbers.indexOf(rowData["@ID"]);
                        if (index !== -1) {
                            this.rootScopeService.selectedRowNumbers.splice(index, 1);
                        }

                        // Add this record to unselectedRowNumbers if it does not exist
                        index = this.rootScopeService.unselectedRowNumbers.indexOf(rowData["@ID"]);
                        if (index === -1) {
                            this.rootScopeService.unselectedRowNumbers.push(rowData["@ID"]);
                        }
                        this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                            ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                            : this.rootScopeService.selectedRowNumbers.length;
                    }
                    rowData.recordSelected = false;
                }
            }
        }
        // Single row select on a page
        else {
            this.lastSelectedRowNumber = currentRowNumber;
            let startRowNumber: number = currentRowNumber - (currentRowNumber % this.data.control.cursorSize);
            for (let rowNumber = startRowNumber; rowNumber < startRowNumber + this.data.control.cursorSize; rowNumber++) {
                let rowData = this.data.results.data[rowNumber];
                if (rowData != null) {
                    if (rowData.recordSelected == true) {
                        let index: number = this.rootScopeService.selectedRowNumbers.indexOf(rowData["@ID"]);    // <-- Not supported in <IE9
                        if (index !== -1) {
                            this.rootScopeService.selectedRowNumbers.splice(index, 1);
                        }

                        // Add this record to unselectedRowNumbers if it does not exist
                        index = this.rootScopeService.unselectedRowNumbers.indexOf(rowData["@ID"]);
                        if (index === -1) {
                            this.rootScopeService.unselectedRowNumbers.push(rowData["@ID"]);
                        }

                        this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                            ? this.data.result.totalRows - this.rootScopeService.unselectedRowNumbers.length
                            : this.rootScopeService.selectedRowNumbers.length;
                    }
                    rowData.recordSelected = false;
                }
            }
            this.data.results.data[currentRowNumber].recordSelected = true;
            this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);

            // Remove this record from unselected row numbers if it is there
            let index: number = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
            if (index !== -1) {
                this.rootScopeService.unselectedRowNumbers.splice(index, 1);
            }

            this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                : this.rootScopeService.selectedRowNumbers.length;

            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row Number " + (currentRowNumber + 1) + " is selected";
                let elementToFocusId: string = $(event.target).attr("id");
                this.searchResultsService.screenReaderPromptMessage(labelText, "", elementToFocusId);
            }
        }
    }

    cellDoubleClick(event: any) {
        if (event == null || $(event.target) == null || this.validationService.isNullOrEmpty($(event.target).attr("id")) || $(event.target).attr("id").indexOf("column_sort") >= 0) return;
        let idParts = $(event.target).attr("id").split("_");
        let currentRowNumber: number = parseInt(idParts[idParts.length - 1]);

        for (let i = 0; i < this.data.results.data.length; i++) {
            this.data.results.data[i].recordSelected = false;
        }

        this.rootScopeService.selectedRowNumbers = [];
        this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
        this.data.results.data[currentRowNumber].recordSelected = true;

        // Clear unselectedRowNumbers
        this.rootScopeService.unselectedRowNumbers.length = 0;

        this.searchResultsService.openSelected(this.data);
    }

    /**
     * For non-form searches, change the sort order of the grid ascending/descending on a column
     * @param headerField - Field to be sorted
     */
    sortNonFormSRColumn(headerField: any): void {
        if (this.disableSort) return;
        this.disableSort = true;
        if (this.validationService.isNullOrEmpty(this.data.results.cursorHandle)) {
            let popupMessage: any = {};
            popupMessage.title = "Error";
            popupMessage.text = [
                "The Sort/Select feature is not available for the active search."
            ];
            popupMessage.buttons = [{
                label: Models.LabelConstants.ok,
                callback: () => {
                    this.notificationsService.popupMessageDismiss(() => {
                        this.processingService.closeProcessing();
                    }, false);

                }
            }];
            popupMessage.defaultCallbackNumber = "1";
            this.notificationsService.popupMessageShow(popupMessage);
        } else {
            this.searchService.clearCache();
            let sortedFieldName: string;
            let sortedAscending: boolean;
            if (this.data.control.sortedFieldName === headerField.fieldName) {
                sortedFieldName = headerField.fieldName;
                sortedAscending = !this.data.control.sortedAscending;
            } else {
                sortedFieldName = headerField.fieldName;
                sortedAscending = true;
            }
            let currentContext: string = this.searchResultsService.getCurrentSearchContext(this.data);
            let queryColumns: any = this.searchService.buildQueryColumns(currentContext);
            let msgWait: string = "Sorting on " + ((sortedAscending) ? "ascending " : "descending ") + headerField.label + ", please wait";
            this.searchService.toggleRunning(true, msgWait, true);
            let successCallback = (hideSearchResults: boolean, gridComponent: any) => {
                // autoSelectAll();
                let self: GridComponent = <GridComponent>gridComponent;
                self.data.control.sortedFieldName = sortedFieldName;
                self.data.control.sortedAscending = sortedAscending;
                self.searchService.toggleRunning(false);
                self.searchResultsService.setPrivacyFlag(this.data);
                setTimeout(() => {
                    self.disableSort = false;
                }, 1000);
            };
            let failureCallback = (hideSearchResults: boolean, errorMessage: string, gridComponent: any) => {
                let self: GridComponent = <GridComponent>gridComponent;
                self.searchService.toggleRunning(false);
                setTimeout(() => {
                    self.disableSort = false;
                }, 1000);
            };
            this.searchService.search(currentContext, this.data.results.cursorHandle, 1, this.data.control.cursorSize, queryColumns, "",
                [sortedFieldName], [(sortedAscending) ? true : false], successCallback, failureCallback, null, this, this);
        }
    };

    /**
     * Toggles the selection of a row in the grid view
     * @param event
     */
    private toggleRowSelection(event: any): void {
        // Get the current rowNumber. rowNumber starts from 1 here because the header row reserves rowNumber 0 for itself.
        let idParts = $(event.target).attr("id").split("_");
        let currentRowNumber: number = parseInt(idParts[idParts.length - 1]);
        // Toggle recordSelected property of the current record
        this.data.results.data[currentRowNumber].recordSelected = !this.data.results.data[currentRowNumber].recordSelected;
        // If recordSelected is false, it means the current record has been de-selected.
        if (this.data.results.data[currentRowNumber].recordSelected == false) {
            let index: number = this.rootScopeService.selectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
            if (index !== -1) {
                this.rootScopeService.selectedRowNumbers.splice(index, 1);
                this.searchResultsService.selectAll = false;
            }

            index = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
            if (index === -1) {
                // Push it to the unselected records so that if select all was previously checked, this record doesn't get selected again
                this.rootScopeService.unselectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
            }

            this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                : this.rootScopeService.selectedRowNumbers.length;

            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row Number " + (currentRowNumber + 1) + " is not selected";
                let elementToFocusId: string = $(event.target).attr("id");
                this.searchResultsService.screenReaderPromptMessage(labelText, "", elementToFocusId);
            }
        }
        // If recordSelected is true, it means the current record has been selected
        else {
            // If multi-selection is allowed, push the current record's ID to selectedRowNumbers
            if (this.data.control.multiSelection == true) {
                this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);

                // Remove this record from unselected row numbers if it is there
                let index: number = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
                if (index !== -1) {
                    // Push it to the unselected records so that if select all was previously checked, this record doesn't get selected again
                    this.rootScopeService.unselectedRowNumbers.splice(index, 1);
                }

                this.rootScopeService.searchResultRecordsSelected = this.searchResultsService.selectAllPreviouslySelected === true
                    ? this.data.results.totalRows - this.rootScopeService.unselectedRowNumbers.length
                    : this.rootScopeService.selectedRowNumbers.length;
            }
            // If multi-selection is not allowed, only set recordSelected property for the current record and push the current record in selectedRowNumbers while removing others.
            else if (this.data.control.multiSelection == false) {
                // Reset recordSelected for all other rows
                _.each(this.data.results.data, (rec: any) => {
                    rec.recordSelected = false;
                });
                // Set recordSelected to true for the current selected row
                this.data.results.data[currentRowNumber].recordSelected = true;
                // Reset selectedRowNumbers and add the current record to selectedRowNumbers
                this.rootScopeService.selectedRowNumbers.length = 0;
                this.rootScopeService.searchResultRecordsSelected = 0;
                this.rootScopeService.selectedRowNumbers.push(this.data.results.data[currentRowNumber]["@ID"]);
                // Remove this record from unselected row numbers if it is there
                let index: number = this.rootScopeService.unselectedRowNumbers.indexOf(this.data.results.data[currentRowNumber]["@ID"]);    // <-- Not supported in <IE9
                if (index !== -1) {
                    this.rootScopeService.unselectedRowNumbers.splice(index, 1);
                }

                this.rootScopeService.searchResultRecordsSelected = this.rootScopeService.selectedRowNumbers.length;
            }

            if (this.preferencesService.dialogPreferences.VerboseAccess) {
                let labelText: string = "Row Number " + (currentRowNumber + 1) + " is selected";
                let elementToFocusId: string = $(event.target).attr("id");
                this.searchResultsService.screenReaderPromptMessage(labelText, "", elementToFocusId);
            }

            this.lastSelectedRowNumber = currentRowNumber;
        }
    }

    /**
     * Handles Left arrow key on search result grid.
     * @param event
     */
    private leftArrowKeyEvent(event: any): void {
        // if the target element is a row, focus the first content cell of the row.
        if ($(event.target).hasClass('searchResultRow')) {
            let elementChildren: any = $(event.target)[0].children;
            if (elementChildren) {
                for (let i = 0; i < elementChildren.length; i++) {
                    if ($(elementChildren[i]).hasClass('searchResultColumn') && $(elementChildren[i]).hasClass('contentColumn')) {
                        elementChildren[i].focus();
                        break;
                    }
                }
            }
        }
        // if the target element is a content column, move the focus to previous content column.
        else if ($(event.target).hasClass('searchResultColumn')) {
            if ($(event.target).prev().length > 0) { // check this so that we don't go beyond second column hitting left arrow.
                $(event.target).prev().trigger("focus");
            }
        }
    }

    /**
     *
     * @param event
     */
    private rightArrowKeyEvent(event: any): void {
        // if the target element is a row, focus the first content cell of the row.
        if ($(event.target).hasClass('searchResultRow')) {
            let elementChildren: any = $(event.target)[0].children;
            if (elementChildren) {
                for (let i = 0; i < elementChildren.length; i++) {
                    if ($(elementChildren[i]).hasClass('searchResultColumn') && $(elementChildren[i]).hasClass('contentColumn')) {
                        elementChildren[i].focus();
                        break;
                    }
                }
            }
        }
        // if the target element is a content column, move the focus to next content column.
        else if ($(event.target).hasClass('searchResultColumn')) {
            // If target has next cell, it means the current focus was on the content table cell. Move to the next cell
            if ($(event.target).next().length > 0) {
                $(event.target).next().trigger("focus");
            }
            // If target does not have a next cell, the current focus was either on the fixedColumn(sequence number) or the last cell in row. Move focus to first cell.
            else {
                let rowIndex: number = $(event.target).parent()[0].rowIndex;
                document.getElementById('sr_grid_' + (rowIndex - 1) + "_0").focus(); // First cell in the row
            }
        }
    }

    isLinkField(fieldName: string) {
        const header = _.find(this.data.layout.headerFields, p => p.fieldName == fieldName);
        return header != null && header.dataType == DataType.Link;
    }
}