/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConfigurationService, EventsService, FocusService, FormService, HelperService, ProcessingService, RootScopeService, ServerCommandService, ValidationService, WindowControlService, PromptService, ReportBrowserService } from "../../../services";
import * as Models from "../../../models";
import { IMyDateModel, IMySingleDateModel, IAngularMyDpOptions, AngularMyDatePickerDirective } from "angular-mydatepicker";
import { TextComponent } from "../text/text.component";

declare var $: any;

@Component({
    selector: 'calendar',
    template: require('./calendar.component.html')
})
export class CalendarComponent extends TextComponent implements OnInit, OnDestroy, AfterViewInit {
    //region Event subscription identifiers

    calendarOpen: boolean = false;
    dp: AngularMyDatePickerDirective;
    format: string;
    appServerFormat: string;

    //endregion Event subscription identifiers

    //region Local properties
    // dt: any;
    opened: boolean = false;
    dateOptions: any = {
        formatYear: 'yy',
        startingDay: 0
    };
    @ViewChild('dp', { static: false }) datePicker: any;
    private launchCalendarEventId: string = "";
    private closeCalendarEventId: string = "";
    private clearCalendarDateEventId: string = "";
    private calendarKeyUpEventId: string = "";
    private centuryPivot: number;
    private lowerYear: number = 30;
    private lastCentury: number = 1990;
    private dateFormat: string;
    private yearIndex: number = 2;
    private monthIndex: number = 0;
    private dayIndex: number = 1;
    private delimiter: string = "";

    //endregion Local Properties
    private myOptions: IAngularMyDpOptions;

    //region Constructor

    constructor(private _eventsService: EventsService,
        private _focusService: FocusService,
        private _formService: FormService,
        private _validationService: ValidationService,
        private configurationService: ConfigurationService,
        private _rootScope: RootScopeService,
        private _serverCommandService: ServerCommandService,
        private _ng4WindowControlService: WindowControlService, private _processingService: ProcessingService, private _helperService: HelperService, private _promptService: PromptService, private _reportBrowserService: ReportBrowserService) {
        super(_rootScope, _ng4WindowControlService, _serverCommandService, _validationService
            , _focusService, _eventsService, _formService, _processingService, _helperService, _promptService, _reportBrowserService);

        // Century Pivot
        this.centuryPivot = configurationService.getConfiguration().ClientParameters.CenturyPivot;
        if (!_validationService.isNullOrEmpty(this.centuryPivot)) {
            this.lowerYear = this.centuryPivot % 100;
            this.lastCentury = Math.floor(this.centuryPivot / 100) * 100;
        }

    }

    //endregion Constructor

    //region Life cycle events

    ngOnInit() {
        super.ngOnInit();

        this.dateFormat = this.element.elementConversion === "SN.DATE4" ? this.configurationService.getConfiguration().ClientParameters.Date4Format :
            (this.element.elementConversion === "SN.DATE2" ? this.configurationService.getConfiguration().ClientParameters.Date2Format : this.element.elementConversion);

        if (!this._validationService.isNullOrEmpty(this.dateFormat)) {
            if (this.dateFormat.indexOf("/") != -1) {
                this.delimiter = "/";
            }
            else if (this.dateFormat.indexOf("-") != -1) {
                this.delimiter = "-";
            }
            else if (this.dateFormat.indexOf(".") != -1) {
                this.delimiter = ".";
            }

            if (this.dateFormat.indexOf('D2') >= 0) { // Use D2
                // If other format than SN.DATE2 is used( D2/MY or D2/YM)
                this.dateFormat = this.configurationService.getConfiguration().ClientParameters.Date2Format;
                this.dateFormat = this.dateFormat.replace('D2/', '');
                this.dateFormat = this.dateFormat.replace('D2-', '');
                this.dateFormat = this.dateFormat.replace('D2.', '');
            }
            else {
                // If other format than SN.DATE4 is used( D4/MY or D4/YM)
                this.dateFormat = this.configurationService.getConfiguration().ClientParameters.Date4Format;
                this.dateFormat = this.dateFormat.replace('D4/', '');
                this.dateFormat = this.dateFormat.replace('D4-', '');
                this.dateFormat = this.dateFormat.replace('D4.', '');
            }

            this.yearIndex = this.dateFormat.toUpperCase().indexOf('Y');
            this.monthIndex = this.dateFormat.toUpperCase().indexOf('M');
            this.dayIndex = this.dateFormat.toUpperCase().indexOf('D');
        }
        this._rootScope.performanceCounter += 0.5;
        this.getFormat();

        this.myOptions = {
            // other options...
            dateFormat: this.format,
            markCurrentDay: true,
            firstDayOfWeek: "su",
            dateRange: false,
            moveFocusByArrowKeys: true
        };

        // Re-position calendar to the right or top based on the position
        this.myOptions.alignSelectorRight = ((this.element.elementColumn + this.element.elementDisplayWidth) > 50) === true ? true : false;
        this.myOptions.openSelectorTopOfInput = this.element.elementRow > 15 ? true : false;

        //this.setElementText();

        this.launchCalendarEventId = this._eventsService.on(Models.EventConstants.launchCalendar, [this.launchCalendar, this]);
        this.closeCalendarEventId = this._eventsService.on(Models.EventConstants.closeCalendar, [this.closeCalendar, this]);
        this.clearCalendarDateEventId = this._eventsService.on(Models.EventConstants.clearCalendarDate, [this.clearCalendarDate, this]);
        this.calendarKeyUpEventId = this._eventsService.on(Models.EventConstants.calendarKeyUp, [this.calendarKeyUp, this]);


    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this._eventsService.destroy(Models.EventConstants.launchCalendar, this.launchCalendarEventId);
        this._eventsService.destroy(Models.EventConstants.closeCalendar, this.closeCalendarEventId);
        this._eventsService.destroy(Models.EventConstants.clearCalendarDate, this.clearCalendarDateEventId);
        this._eventsService.destroy(Models.EventConstants.calendarKeyUp, this.calendarKeyUpEventId);
    }

    ngAfterViewInit() {
        this.dp = this.datePicker;

        // if text is not empty(which means form is getting re-painted), set datepicker value.
        if (!this._validationService.isNullOrEmpty(this.element.text)) {
            this._formService.populateDate(this.element, this.element.text)
        }
    }

    //endregion Life cycle events

    //region Private methods

    /**
     * Gets the format of the calendar dates to be displayed in the calendar input
     */
    getFormat() {
        if (!this._validationService.isNullOrEmpty(this.delimiter)) {
            if (this.dayIndex == 0) {
                this.format = "dd" + this.delimiter;
            }
            else if (this.monthIndex == 0) {
                this.format = "mm" + this.delimiter;
            }
            else if (this.yearIndex == 0) {
                this.format = "yyyy" + this.delimiter;
            }

            if (this.dayIndex == 1) {
                this.format += "dd" + this.delimiter;
            }
            else if (this.monthIndex == 1) {
                this.format += "mm" + this.delimiter;
            }
            else if (this.yearIndex == 1) {
                this.format += "yyyy" + this.delimiter;
            }

            if (this.dayIndex == 2) {
                this.format += "dd";
            }
            else if (this.monthIndex == 2) {
                this.format += "mm";
            }
            else if (this.yearIndex == 2) {
                this.format += "yyyy";
            }
        }
    }

    /**
     * Triggered when calendar icon is clicked.
     * @param event
     */
    open($event: any) {
        // Do not do anything if the form is readonly or element is readonly.
        if (this._formService.formDisabled || this.element.inquiry || this.element.runTimeInquiryField || this.element.runTimeRTInquiryField || (this.element.originalText != '' && this.element.runtTimeAddonly))
            return false;

        this.calendarOpen = true;
        if (this.element.elementType !== Models.ElementType.inquiry && !this._validationService.isNullOrEmpty(this._rootScope.focusedFieldName) && this._rootScope.focusedFieldName !== this.element.sanitizedID) {
            this._rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
            this._serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", this.element.originalElementID, "", this.element.rowIndex);
        }

        this._rootScope.calendarField = this.element.sanitizedID;
        this._rootScope.focusedFieldName = this.element.sanitizedID;
        this._rootScope.clickedFieldName = this.element.sanitizedID;

        // Convert the date to a 4-digit year format before opening the calendar so that the calendar can open on the correct date
        if (!this._validationService.isNullOrEmpty(this.element.text)) {
            this.element.text = this.convertToDate(this.element.text);
            //this.element.originalText = this.element.text;
            this.convertToFourDigitYear();
        }
        else if (this._validationService.isNullOrEmpty(this.element.text)) {
            if (this.datePicker != null && this.datePicker.elem != null && this.datePicker.elem.nativeElement != null && !this._validationService.isNullOrEmpty(this.datePicker.elem.nativeElement.value)) {
                let elemValue: any = this.datePicker.elem.nativeElement.value;
                let elemValueLength: number = elemValue.length;

                if (elemValueLength == this.element.elementDisplayWidth && this._validationService.isNullOrEmpty(this.element.text)) {
                    this.element.text = elemValue;
                    this.element.text = this.convertToDate(this.element.text);
                    // this.element.originalText = this.element.text;
                    this.convertToFourDigitYear();
                }
            }
        }

        this.launchCalendar(this, this.element.sanitizedID, true);

        return true;
    }

    /**
     * Invoked when the date on calendar is changed
     * @param event
     */
    onDateChanged(dateModel: IMyDateModel): void {
        this.calendarOpen = false;
        let dontSendUpdate: boolean = false;

        if (this._rootScope.focusedFieldName != this.element.sanitizedID)
            return;

        // return if date is not changed, to avoid field update when not required.
        if (!this._validationService.isNullOrEmpty(this.element.text) && this.element.text == dateModel.singleDate.formatted) {
            return;
        }

        // return if date is not changed.
        if (this.element.text && this.element.text.date && this.element.text.date.year == dateModel.singleDate.date.year
            && this.element.text.date.month == dateModel.singleDate.date.month && this.element.text.date.day == dateModel.singleDate.date.day) {
            return;
        }

        if (this.element.text == dateModel.singleDate.formatted && dateModel.singleDate.formatted == "")
            dontSendUpdate = true;

        this.element.text = dateModel.singleDate;
        if (!this._validationService.isNullOrEmpty(this.element.text.formatted)) {
            this.element.originalText = dateModel.singleDate;
        }

        let jumpCommand: any = Models.CommandConstants.fieldJump;
        if (this._rootScope.lastPromptedField == this.element.elementID || this._rootScope.lastFieldPromptMessageId == this.element.elementID) {
            jumpCommand = Models.CommandConstants.ignoreFieldJump;
        }

        if (!this._validationService.isNullOrEmpty(this.element.text.formatted)) {
            this._rootScope.calendarField = "";
            this._serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate], ["", this.fieldName], ["", "#" + this.element.text.date.month + "/" + this.element.text.date.day + "/" + this.element.text.date.year],
                [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
        }
        else if (this.element.text.formatted != null && dontSendUpdate == false) {
            this._serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate], ["", this.fieldName], ["", this.element.text.formatted],
                [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
        }
    }

    /**
         * Called when the calendar toggles it's state
         * @param event - values below
         * 1 = calendar opened
         * 2 = calendar closed by date select
         * 3 = calendar closed by calendar button
         * 4 = calendar closed by outside click (document click)
         */
    onCalendarToggle(event: number): void {
        if (event == 1) {
            let dayToSelect: string;
            if (!this._validationService.isNullOrEmpty(this.element.text) && this.element.text.date && this.element.text.date.day) {
                dayToSelect = this.element.text.date.day.toString();
            }
            else {
                // Get today's date
                let dateObj = new Date();
                dayToSelect = dateObj.getDate().toString();
            }

            setTimeout(function () {
                // get the span containing the day using jQuery
                let dayToFocusSpan: any = $(".ng-mydp .myDpDaycell.myDpCurrMonth .myDpDayValue").filter(function () {
                    return $(this).text() === dayToSelect;
                });

                // Parent cell of the table is two levels above this span
                if (dayToFocusSpan) {
                    let tdCell: any = dayToFocusSpan.parent();
                    if (tdCell) {
                        tdCell.trigger("focus");
                    }
                }
                dayToFocusSpan = null;
            }, 50);

            this.calendarOpen = true;
        }
        else {
            this.hideOverlay();
        }
    }

    /**
     * Invoked when the calendar input loses focus
     * @param event
     * @param isBlurEvent
     */
    calendarBlur(event: any, isBlurEvent: boolean) {
        if (isBlurEvent == true) {
            if (this._validationService.isNullOrEmpty(this.element.text) || this._validationService.isNullOrEmpty(event.currentTarget.value) || event.currentTarget.value != this.element.text) {
                this.element.text = event.currentTarget.value;
            }
        }
        else {
            let tab: boolean = event.keyCode == Models.KeyCodes.tab;
            let enter: boolean = event.keyCode == Models.KeyCodes.enter;
            let down: boolean = event.keyCode == Models.KeyCodes.down;
            let up: boolean = event.keyCode == Models.KeyCodes.up;
            let pagedown: boolean = event.keyCode == Models.KeyCodes.pageDown;
            let pageup: boolean = event.keyCode == Models.KeyCodes.pageUp;
            let home: boolean = event.keyCode == Models.KeyCodes.home;
            let end: boolean = event.keyCode == Models.KeyCodes.end;

            // different ways user can get out of input.
            if (tab || enter || down || up || pagedown || pageup || home || end) {
                if (this._validationService.isNullOrEmpty(this.element.text) || this._validationService.isNullOrEmpty(event.currentTarget.value) || event.currentTarget.value != this.element.text) {
                    this.element.text = event.currentTarget.value;
                }
            }
        }

        // Check to see if the
    }
    pasteEvent(event: any) {
        this.element.text = "";
    }
    /**
     * When the user clicks anywhere else except for the open calendar, there is a hidden modal. When the hidden modal is clicked, it hides itself and sets the calendar's open flag and other related properties
     */
    hideOverlay() {
        this.calendarOpen = false;
        if (!this._validationService.isNullOrEmpty(this._rootScope.calendarField))
            this._focusService.focusOn('#' + this._rootScope.calendarField);
        this._rootScope.calendarField = "";
    }

    /**
     * Launch Calendar Event Listener when calendar is opened using down arrow key.
     * @param calendarComponent
     * @param id
     */
    private launchCalendar(calendarComponent: any, id: any, fromOpen: boolean = false) {
        let self: CalendarComponent = null;
        self = <CalendarComponent>calendarComponent;

        //Check if args (published id) is equal to element's sanitized id.
        if (self.element.sanitizedID == id) {
            if (fromOpen == false) { // it means calendar is getting opened using down arrow
                self.open(null);
                return;
            }
            // display calendar
            self.calendarOpen = true;
            if (self.dp) {
                self.dp.closeCalendar();
                self.dp.openCalendar();
            }
            else if (self.datePicker) {
                self.datePicker.closeCalendar();
                self.datePicker.openCalendar();
            }

        }
    }

    /**
     * Close Calendar Event listener when user clicks outside the calendar.
     * @param calendarComponent
     */
    private closeCalendar(calendarComponent: any) {
        let self = <CalendarComponent>calendarComponent;

        // Check if calendar is already open.
        if (self.calendarOpen == true) {
            self.calendarOpen = false;
            if (self.dp) {
                self.dp.closeCalendar();
            }
            else if (self.datePicker) {
                self.datePicker.closeCalendar();
            }
        }
    }

    /**
     * Clear Calendar Date event listener when a FieldDisplayMessage is sent to clear the date fields
     * @param calendarComponent
     * @param id
     */
    private clearCalendarDate(calendarComponent: any, id: any) {
        let self: CalendarComponent = null;
        self = <CalendarComponent>calendarComponent;

        //Check if args (published id) is equal to element's sanitized id.
        if (self.element.elementID.toLocaleLowerCase() == id.toLocaleLowerCase() || self.element.sanitizedID.toLocaleLowerCase() == id.toLocaleLowerCase()) {
            if (self.dp) {
                self.dp.clearDate();
            }
            else if (self.datePicker) {
                self.datePicker.clearDate()
            }
        }
    }

    /**
     * Sets the model's value on calendar component's initialization
     */
    private setElementText() {
        if (this.element.text && this.element.text != "" && (typeof this.element.text === 'string' || this.element.text instanceof String)) {
            this.element.formattedDateText = this.element.text;
            // Change year when year is specified as just 2 digit, so calendar control doesn't get confused.
            // Change the century to 19 if year between 30 & 99
            // Change the century to 20 if year before 30
            let dateElementsSlash: Array<any> = this.element.text.split('/');
            let dateElementsDash: Array<any> = this.element.text.split('-');
            let dateElementsDot: Array<any> = this.element.text.split('.');

            if (dateElementsSlash.length == 3) {
                if (dateElementsSlash[this.yearIndex].length == 2) {
                    let year: number = parseInt(dateElementsSlash[this.yearIndex]);
                    if (year >= this.lowerYear) {
                        dateElementsSlash[this.yearIndex] = this.lastCentury + year;
                    }
                    else {
                        dateElementsSlash[this.yearIndex] = this.lastCentury + 100 + year;
                    }
                }

                this.element.text = {
                    date: {
                        year: parseInt(dateElementsSlash[this.yearIndex]),
                        month: parseInt(dateElementsSlash[this.monthIndex]),
                        day: parseInt(dateElementsSlash[this.dayIndex])
                    }
                };

            }
            else if (dateElementsDash.length == 3) {
                if (dateElementsDash[this.yearIndex].length == 2) {
                    let year: number = parseInt(dateElementsDash[this.yearIndex]);
                    if (year >= this.lowerYear) {
                        dateElementsDash[this.yearIndex] = this.lastCentury + year;
                    }
                    else {
                        dateElementsDash[this.yearIndex] = this.lastCentury + 100 + year;
                    }
                }

                this.element.text = {
                    date: {
                        year: parseInt(dateElementsDash[this.yearIndex]),
                        month: parseInt(dateElementsDash[this.monthIndex]),
                        day: parseInt(dateElementsDash[this.dayIndex])
                    }
                };
            }
            else if (dateElementsDot.length == 3) {
                if (dateElementsDot[this.yearIndex].length == 2) {
                    let year: number = parseInt(dateElementsDot[this.yearIndex]);
                    if (year >= this.lowerYear) {
                        dateElementsDot[this.yearIndex] = this.lastCentury + year;
                    }
                    else {
                        dateElementsDot[this.yearIndex] = this.lastCentury + 100 + year;
                    }
                }

                this.element.text = {
                    date: {
                        year: parseInt(dateElementsDot[this.yearIndex]),
                        month: parseInt(dateElementsDot[this.monthIndex]),
                        day: parseInt(dateElementsDot[this.dayIndex])
                    }
                };
            }
        }
    }

    /**
     * Keyboard keys event listener when the calendar is open. Used for navigation between dates in the calendar.
     * @param calendarComponent
     * @param eventData
     */
    private calendarKeyUp(calendarComponent: any, eventData: any): void {
        let self: CalendarComponent = <CalendarComponent>calendarComponent;

        // Only proceed for the calendar that is open right now.
        if (self.calendarOpen != true)
            return;

        let keyEvent: KeyboardEvent = eventData.event;

        let activeElem: any = eventData.element;

        // Parse the attributes of the keyboard event
        let keyCode: number = keyEvent.keyCode;
        let keyCharCode: number = keyEvent.charCode;
        let keyChar: number = keyCode || keyCharCode;
        let keyShift: boolean = keyEvent.shiftKey;
        let keyCtrl: boolean = keyEvent.ctrlKey;
        let keyAlt: boolean = keyEvent.altKey;

        // to prevent page scrolling when home, end, page up or page down key is pressed
        // tabbing should be allowed in order to move to the arrow buttons on top
        if (keyCode != Models.KeyCodes.tab) {
            keyEvent.preventDefault();
            keyEvent.stopPropagation();
        }

        if (!keyCtrl && !keyAlt && !keyShift) {
            switch (keyChar) {
                case Models.KeyCodes.right:
                    // Next date
                    self.rightKey(activeElem);
                    break;
                case Models.KeyCodes.left:
                    // Previous date
                    self.leftKey(activeElem);
                    break;
                case Models.KeyCodes.up:
                    // Move to row above
                    self.upKey(activeElem);
                    break;
                case Models.KeyCodes.down:
                    // Move to row below
                    self.downKey(activeElem);
                    break;
                case Models.KeyCodes.pageUp:
                    // Previous month
                    self.pageUpKey(activeElem);
                    break;
                case Models.KeyCodes.pageDown:
                    // Next month
                    self.pageDownKey(activeElem);
                    break;
                case Models.KeyCodes.home:
                    // Move to the first date of the month
                    self.homeKey();
                    break;
                case Models.KeyCodes.end:
                    // Move to the last date of the month
                    self.endKey();
                    break;
                default:
                    break;
            }
        }
        else {
            if (!keyShift && !keyAlt) {
                switch (keyChar) {
                    case Models.KeyCodes.home:
                        // Move to the first date of the month
                        self.homeKey();
                        break;
                    case Models.KeyCodes.end:
                        // Move to the last date of the month
                        self.endKey();
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /**
     * Handle up arrow key on calendar dates. Move up by a week.
     * @param activeCell
     */
    private upKey(activeCell: any): void {
        let activeCellIndex: number = activeCell.cellIndex;
        let parentRow: any = activeCell.parentElement;

        if (activeCellIndex >= 0 && activeCellIndex < parentRow.cells.length) {
            let previousRow: any = parentRow.previousElementSibling;
            // User hit up key on a row that has another row above it. Move to the same cell index in the row above.
            if (!this._validationService.isNullOrEmpty(previousRow)) {
                let cellToFocus: any = previousRow.cells[activeCellIndex];
                // The cell to focus in previous row is from the current month. Shift focus to this cell
                if (cellToFocus.classList.contains("myDpCurrMonth")) {
                    cellToFocus.focus();
                }
                // The cell to focus in previous row is not from the current month. Trigger previous month view and shift focus to the cell that has the same date in the previous month.
                else {
                    let dateToSelect: string = cellToFocus.textContent;

                    // Move to the previous month
                    let previousMonthBtn = document.getElementsByClassName('myDpIconLeftArrow')[0] as HTMLElement;
                    previousMonthBtn.click();

                    setTimeout(function () {
                        // Get the cell that has the dateToSelect
                        let cellToFocus: any = $(".ng-mydp .myDpDaycell.myDpCurrMonth").filter(function () {
                            return $(this).text() == dateToSelect;
                        });
                        if (cellToFocus)
                            cellToFocus[0].focus();
                        cellToFocus = null;
                    }, 50);
                }
            }
            // User hit up key on the first row. Move to the previous month and focus on the same cell index in the previous month's first row which has the current month's dates
            else {
                // Get the current date and then subtract a week for the up key. This will give us the new date in the previous month and allows us to select the particular day in the previous month
                let currentDay: number = parseInt(activeCell.textContent);
                let currentMonth: number = parseInt(Models.MonthsList[$("button.myDpHeaderBtn.myDpMonthBtn.myDpMonthLabel").text()]) - 1;
                let currentYear: number = parseInt($("button.myDpHeaderBtn.myDpYearBtn.myDpYearLabel").text());
                let currentDate: Date = new Date(currentYear, currentMonth, currentDay);
                // Subtract a week from currentDate and get the new date
                currentDate.setDate(currentDate.getDate() - 7);
                let dateToSelect: number = currentDate.getDate();

                // Move to the next month
                let previousMonthBtn = document.getElementsByClassName('myDpIconLeftArrow')[0] as HTMLElement;
                previousMonthBtn.click();

                setTimeout(function () {
                    // Get the cell that has the dateToSelect
                    let cellToFocus: any = $(".ng-mydp .myDpDaycell.myDpCurrMonth").filter(function () {
                        return $(this).text() == dateToSelect.toString();
                    });
                    if (cellToFocus)
                        cellToFocus[0].focus();
                    cellToFocus = null;
                }, 50);
            }
        }
    }

    /**
     * Handle down arrow key on calendar dates. Move down by a week.
     * @param activeCell
     */
    private downKey(activeCell: any): void {
        let activeCellIndex: number = activeCell.cellIndex;
        let parentRow: any = activeCell.parentElement;

        if (activeCellIndex >= 0 && activeCellIndex < parentRow.cells.length) {
            let nextRow: any = parentRow.nextElementSibling;
            // User hit down key on a row that has another row below it. Move to the same cell index in the row below.
            if (!this._validationService.isNullOrEmpty(nextRow)) {
                let cellToFocus: any = nextRow.cells[activeCellIndex];
                // The cell to focus in next row is from the current month. Shift focus to this cell
                if (cellToFocus.classList.contains("myDpCurrMonth")) {
                    cellToFocus.focus();
                }
                // The cell to focus in next row is not from the current month. Trigger next month view and shift focus to the cell that has the same date in the next month.
                else {
                    let dateToSelect: string = cellToFocus.textContent;

                    // Move to the next month
                    let nextMonthBtn = document.getElementsByClassName('myDpIconRightArrow')[0] as HTMLElement;
                    nextMonthBtn.click();

                    setTimeout(function () {
                        // Get the cell that has the dateToSelect
                        let cellToFocus: any = $(".ng-mydp .myDpDaycell.myDpCurrMonth").filter(function () {
                            return $(this).text() == dateToSelect;
                        });
                        if (cellToFocus)
                            cellToFocus[0].focus();
                        cellToFocus = null;
                    }, 50);
                }
            }
            // User hit down key on the last row. Move to the next month and focus on the same cell index in the next month's first row which has the current month's dates
            else {
                // Get the current date and then add a week for the down key. This will give us the new date in the next month and allows us to select the particular day in the next month
                let currentDay: number = parseInt(activeCell.textContent);
                let currentMonth: number = parseInt(Models.MonthsList[$("button.myDpHeaderBtn.myDpMonthBtn.myDpMonthLabel").text()]) - 1;
                let currentYear: number = parseInt($("button.myDpHeaderBtn.myDpYearBtn.myDpYearLabel").text());
                let currentDate: Date = new Date(currentYear, currentMonth, currentDay);
                // Subtract a week from currentDate and get the new date
                currentDate.setDate(currentDate.getDate() + 7);
                let dateToSelect: number = currentDate.getDate();

                // Move to the next month
                let nextMonthBtn = document.getElementsByClassName('myDpIconRightArrow')[0] as HTMLElement;
                nextMonthBtn.click();

                setTimeout(function () {
                    // Get the cell that has the dateToSelect
                    let cellToFocus: any = $(".ng-mydp .myDpDaycell.myDpCurrMonth").filter(function () {
                        return $(this).text() == dateToSelect.toString();
                    });
                    if (cellToFocus)
                        cellToFocus[0].focus();
                    cellToFocus = null;
                }, 50);
            }
        }
    }

    //endregion Private methods

    //region Component methods

    /**
     * Handle left arrow key on calendar dates. Move to the previous day.
     * @param activeCell
     */
    private leftKey(activeCell: any): void {
        let activeCellIndex: number = activeCell.cellIndex;
        let parentRow: any = activeCell.parentElement;

        if (activeCellIndex >= 0 && activeCellIndex < parentRow.cells.length) {
            // User hit left on the first cell, move to the previous row's last cell
            if (activeCellIndex == 0) {
                let previousRow: any = parentRow.previousElementSibling;
                // If this cell is the first cell in the first row, previousRow will be null. Therefore, trigger previous month view and focus on the last date of the previous month.
                if (this._validationService.isNullOrEmpty(previousRow)) {
                    let previousMonthBtn = document.getElementsByClassName('myDpIconLeftArrow')[0] as HTMLElement;
                    previousMonthBtn.click();

                    setTimeout(function () {
                        // Select the previous month's last date
                        let currMonthDays = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth");
                        let numberOfDates: number = currMonthDays.length;
                        let lastDayOfPreviousMonth = currMonthDays[numberOfDates - 1] as HTMLElement;
                        lastDayOfPreviousMonth.focus();
                    }, 50);
                } else {
                    let lastCellInPreviousRow: any = previousRow.cells[previousRow.cells.length - 1];
                    // Previous date is in the previous month, change the view to the previous month
                    if (parseInt(activeCell.textContent) < parseInt(lastCellInPreviousRow.textContent)) {
                        // Trigger the click event of the previous month's last date cell, which flips the view to the previous month
                        $(lastCellInPreviousRow).trigger('click');
                        setTimeout(function () {
                            // Select the previous month's last date
                            let currMonthDays = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth");
                            let numberOfDates: number = currMonthDays.length;
                            let lastDayOfPreviousMonth = currMonthDays[numberOfDates - 1] as HTMLElement;
                            lastDayOfPreviousMonth.focus();
                        }, 50);
                    } else {
                        lastCellInPreviousRow.focus();
                    }
                }
            }
            // User hit the left key on a cell that is not the first cell, shift focus to the previous cell in the same row
            else if (activeCellIndex > 0 && activeCellIndex <= parentRow.cells.length - 1) {
                let previousCellInCurrentRow: any = parentRow.cells[activeCellIndex - 1];
                // Previous date is in the previous month, change the view to the previous month
                if (parseInt(activeCell.textContent) < parseInt(previousCellInCurrentRow.textContent)) {
                    // Trigger the click event of the previous month's last date cell, which flips the view to the previous month
                    $(previousCellInCurrentRow).trigger('click');
                    setTimeout(function () {
                        // Select the previous month's last date
                        let currMonthDays = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth");
                        let numberOfDates: number = currMonthDays.length;
                        let lastDayOfPreviousMonth = currMonthDays[numberOfDates - 1] as HTMLElement;
                        lastDayOfPreviousMonth.focus();
                    }, 50);
                } else {
                    previousCellInCurrentRow.focus();
                }
            }
        }
    }

    /**
     * Handle right arrow key on calendar dates. Move to the next day.
     * @param activeCell
     */
    private rightKey(activeCell: any): void {
        let activeCellIndex: number = activeCell.cellIndex;
        let parentRow: any = activeCell.parentElement;

        if (activeCellIndex >= 0 && activeCellIndex < parentRow.cells.length) {
            // User hit right on the last cell, move to the next row's first cell
            if (activeCellIndex == parentRow.cells.length - 1) {
                // Next date is in the next month, change the view to the next month
                let nextRow: any = parentRow.nextElementSibling;
                // If this cell is the last cell in the last row, nextRow will be null. Therefore, trigger next month view and focus on the first date of the next month.
                if (this._validationService.isNullOrEmpty(nextRow)) {
                    let nextMonthBtn = document.getElementsByClassName('myDpIconRightArrow')[0] as HTMLElement;
                    nextMonthBtn.click();
                    setTimeout(function () {
                        // Select the next month's first date
                        let nextMonthFirstDay = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth")[0] as HTMLElement;
                        nextMonthFirstDay.focus();
                    }, 50);
                } else {
                    let firstCellInNextRow: any = nextRow.cells[0];
                    // Next date is in the next month, change the view to the next month
                    if (parseInt(activeCell.textContent) > parseInt(firstCellInNextRow.textContent)) {
                        // Trigger the click event of the next month's first date cell, which flips the view to the next month
                        $(firstCellInNextRow).trigger('click');
                        setTimeout(function () {
                            // Select the next month's first date
                            let nextMonthFirstDay = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth")[0] as HTMLElement;
                            nextMonthFirstDay.focus();
                        }, 50);
                    } else {
                        firstCellInNextRow.focus();
                    }
                }
            }
            // User hit the right key on a cell that is not the last cell, shift focus to the next cell in the same row
            else if (activeCellIndex < parentRow.cells.length - 1) {
                let nextCellInCurrentRow: any = parentRow.cells[activeCellIndex + 1];
                // Next date is in the next month, change the view to the next month
                if (parseInt(activeCell.textContent) > parseInt(nextCellInCurrentRow.textContent)) {
                    // Trigger the click event of the next month's first date cell, which flips the view to the next month
                    $(nextCellInCurrentRow).trigger('click');
                    setTimeout(function () {
                        // Select the next month's first date
                        let nextMonthFirstDay = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth")[0] as HTMLElement;
                        nextMonthFirstDay.focus();
                    }, 50);
                } else {
                    nextCellInCurrentRow.focus();
                }
            }
        }
    }

    /**
     * Handle home key on calendar dates. Move to the first day of the selected month.
     */
    private homeKey(): void {
        let firstDayOfCurrentMonth: any = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth")[0] as HTMLElement;
        firstDayOfCurrentMonth.focus();
    }

    /**
     * Handle end key on calendar dates. Move to the last day of the selected month.
     * @param activeCell
     */
    private endKey(): void {
        let currMonthDays = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth");
        let numberOfDates: number = currMonthDays.length;
        let lastDayOfPreviousMonth = currMonthDays[numberOfDates - 1] as HTMLElement;
        lastDayOfPreviousMonth.focus();
    }

    /**
     * Handle page up key on calendar dates. Move up by a month.
     * @param activeCell
     */
    private pageUpKey(activeCell: any): void {
        // Get activeCell's date.
        let dateToMatch: number = parseInt(activeCell.textContent);
        // Click the button for previous month
        let previousMonthBtn = document.getElementsByClassName('myDpIconLeftArrow')[0] as HTMLElement;
        previousMonthBtn.click();

        setTimeout(() => {
            // Check to see if the previous month has the same date. If not, focus on the last date
            let dates: any = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth");
            let numberOfDates: number = dates.length;
            // If there is no match for the date from the current month, focus last date in previous month
            let dateToFocus = (dateToMatch > numberOfDates) ? dates[numberOfDates - 1] as HTMLElement : dates[dateToMatch - 1] as HTMLElement;
            dateToFocus.focus();
        }, 50);
    }

    /**
     * Handle page down key on calendar dates. Move down by a month.
     * @param activeCell
     */
    private pageDownKey(activeCell: any): void {
        // Get activeCell's date.
        let dateToMatch: number = parseInt(activeCell.textContent);
        // Click the button for next month
        let nextMonthBtn = document.getElementsByClassName('myDpIconRightArrow')[0] as HTMLElement;
        nextMonthBtn.click();

        setTimeout(() => {
            let dates: any = document.querySelectorAll(".ng-mydp .myDpDaycell.myDpCurrMonth");
            let numberOfDates: number = dates.length;
            // If there is no match for the date from the current month, focus last date in next month
            let dateToFocus = (dateToMatch > numberOfDates) ? dates[numberOfDates - 1] as HTMLElement : dates[dateToMatch - 1] as HTMLElement;
            dateToFocus.focus();
        }, 50);
    }

    /**
     * Convert text to date object used by calendar component.
     * @param text
     */
    private convertToDate(text: any): any {
        if (text && text != "" && (typeof text === 'string' || text instanceof String)) {


            // Change year when year is specified as just 2 digit, so calendar control doesn't get confused.
            // Change the century to 19 if year between 30 & 99
            // Change the century to 20 if year before 30
            let dateElementsSlash: Array<any> = text.split('/');
            let dateElementsDash: Array<any> = text.split('-');
            let dateElementsDot: Array<any> = text.split('.');

            // in case of MY or YM format insert dummy day at day index using D4
            if (dateElementsSlash.length == 2) {
                dateElementsSlash.splice(this.dayIndex, 0, "01");
            }
            else if (dateElementsDash.length == 2) {
                dateElementsDash.splice(this.dayIndex, 0, "01");
            }
            else if (dateElementsDot.length == 2) {
                dateElementsDot.splice(this.dayIndex, 0, "01");
            }
            let yearIndex: number = this.yearIndex;
            let monthIndex: number = this.monthIndex;
            let dayIndex: number = this.dayIndex;


            if (dateElementsSlash.length == 3) {
                // Datepicker research - NK
                if (dateElementsSlash[yearIndex].length == 2) {
                    let year: number = parseInt(dateElementsSlash[yearIndex]);
                    if (year >= this.lowerYear) {
                        dateElementsSlash[yearIndex] = this.lastCentury + year;
                    }
                    else {
                        dateElementsSlash[yearIndex] = this.lastCentury + 100 + year;
                    }
                }

                text = {
                    date: {
                        year: parseInt(dateElementsSlash[yearIndex]),
                        month: parseInt(dateElementsSlash[monthIndex]),
                        day: parseInt(dateElementsSlash[dayIndex])
                    }
                };

            }
            else if (dateElementsDash.length == 3) {
                // Datepicker research - NK
                if (dateElementsDash[yearIndex].length == 2) {
                    let year: number = parseInt(dateElementsDash[yearIndex]);
                    if (year >= this.lowerYear) {
                        dateElementsDash[yearIndex] = this.lastCentury + year;
                    }
                    else {
                        dateElementsDash[yearIndex] = this.lastCentury + 100 + year;
                    }
                }

                text = {
                    date: {
                        year: parseInt(dateElementsDash[yearIndex]),
                        month: parseInt(dateElementsDash[monthIndex]),
                        day: parseInt(dateElementsDash[dayIndex])
                    }
                };
            }
            else if (dateElementsDot.length == 3) {
                // Datepicker research - NK
                if (dateElementsDot[yearIndex].length == 2) {
                    let year: number = parseInt(dateElementsDot[yearIndex]);
                    if (year >= this.lowerYear) {
                        dateElementsDot[yearIndex] = this.lastCentury + year;
                    }
                    else {
                        dateElementsDot[yearIndex] = this.lastCentury + 100 + year;
                    }
                }

                text = {
                    date: {
                        year: parseInt(dateElementsDot[yearIndex]),
                        month: parseInt(dateElementsDot[monthIndex]),
                        day: parseInt(dateElementsDot[dayIndex])
                    }
                };
            }

            return text;
        }
        // TODO: Why do we need this, as upon opening calendar year will automatically be converted by converToFourDigitYearFunction
        // else if (text && text != null && text.date != null && text.date.year != null) {
        //     let lowerYear: number = 30;
        //     let lastCentury: number = 1900;
        //     let year: number = parseInt(text.date.year);
        //     text.date.year = year >= lowerYear ? lastCentury + year : lastCentury + 100 + year;
        // }

        return text;
    }

    private convertToFourDigitYear() {
        if (this.datePicker != null && this.datePicker.elem != null && this.datePicker.elem.nativeElement != null && !this._validationService.isNullOrEmpty(this.datePicker.elem.nativeElement.value)) {
            let dateParts = this.datePicker.elem.nativeElement.value.split(this.delimiter);
            let yr: any = "";
            // If the format is D2 MY, lower year index by one 
            if (dateParts.length == 2 && this.yearIndex > 0) {
                yr = dateParts[this.yearIndex - 1];
            }
            else {
                yr = dateParts[this.yearIndex];
            }
            if (yr.length == 2) {
                yr = parseInt(yr);
                yr = yr >= this.lowerYear ? this.lastCentury + yr : this.lastCentury + 100 + yr;
                let dt = dateParts;
                dt[this.yearIndex] = yr;
                dt = dt.join(this.delimiter);
                this.datePicker.elem.nativeElement.value = dt;
                this.element.originalText = dt;
            }
        }
    }

    //endregion Component methods
}