/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit } from "@angular/core";
import {
    ConfigurationService,
    ContextService,
    EventsService,
    FavoritesService,
    FocusService,
    FormService,
    HelpService,
    UiLocalStorageService,
    RootScopeService,
    SearchService,
    ServerCommandService,
    SessionService,
    ValidationService
} from "../../services";
import * as Models from "../../models";

declare var $: any;

@Component({
    selector: 'favorites',
    template: require('./favorites.component.html')
})
export class FavoritesComponent implements OnInit {
    addToFavoritesFlag: boolean = false;
    sharedListDialog: string = "#shared-list-dialog";
    sharedListName: string = "#sharedListName";
    recentlySharedListsDialog: string = "#recently-shared-lists-dialog";
    folderPersonRename: string = "#folder-person-rename";
    sharedListDialogOpen: string = "#btn_recently_shared_list_Ok";
    sharedListSuccessOk: string = "#btn_ok_favorite";
    displayShared: boolean = false;
    selected: any = [];

    addToContextListenerId: string = "";
    initialFocusListenerId: string = "";

    constructor(private contextService: ContextService,
        private formService: FormService,
        private helpService: HelpService,
        private configurationService: ConfigurationService,
        private favoritesService: FavoritesService,
        private sessionService: SessionService,
        private validationService: ValidationService,
        private rootScopeService: RootScopeService,
        private searchService: SearchService,
        private serverCommandService: ServerCommandService,
        private uiLocalStorageService: UiLocalStorageService,
        private eventsService: EventsService,
        private focusService: FocusService) {
        this.addToContextListenerId = this.eventsService.on("addToContext", [this.addToContextListener, this]);
        this.initialFocusListenerId = this.eventsService.on("favoritesInitialFocus", [this.initialFocusListener, this]);
    }

    ngOnInit() {
        $("#addToFavoritesIcon > *")
            .on("focus", () => {
                $("#addToFavoritesIcon").addClass('addToFavoritesIconFocused');
            })
            .on("blur", () => {
                $("#addToFavoritesIcon").removeClass('addToFavoritesIconFocused');
            });
    }

    /**
     * Listener to focus on either Add to favorites or Help button in the header of the favorites dialog when it opens. If Add to Favorites button is disabled, focus will go to the help button
     * @param favComponent
     */
    initialFocusListener(favComponent: FavoritesComponent) {
        let self: FavoritesComponent = <FavoritesComponent>favComponent;
        if (((self.contextService.context.PERSON == null || self.contextService.context.PERSON.data == null || self.contextService.context.PERSON.data.length < 1) && (self.formService.forms.length < 1))) {
            document.getElementById("favorites-help-btn").focus();
        }
        else {
            document.getElementById("btnAddToFavoritesIcon").focus();
        }
    }

    /**
     * Toggles the Add to favorites dropdown
     */
    addToFavoritesClick() {
        this.addToFavoritesFlag = !this.addToFavoritesFlag;
    }

    /**
     * Help for Favorites
     * @param helpID
     */
    help(helpID: string) {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses[helpID]);
    }

    /**
     * Create shared list modal window open.
     * @param event
     * @param root
     * @param itemArray
     */
    showCreateSharedList(event: any, root: any, itemArray: any) {
        event.stopPropagation();

        this.favoritesService.listName = this.sessionService.getOperatorId() + "_";
        this.favoritesService.getSelectedPeople(root, itemArray); // itemArray contains selected people
        this.favoritesService.personArray = itemArray;
        if (itemArray == null || (itemArray != null && itemArray.length == 0)) {
            let msg: any = {};
            let item: any = {};
            msg.title = Models.PromptMessageConstants.favoriteErrorMessageTitle;
            this.favoritesService.checkSelectedFolder(root, item);
            if (item.highlight) {
                msg.text = [Models.PromptMessageConstants.emptyFolderSharedList];
            } else {
                msg.text = [Models.PromptMessageConstants.emptySharedList];
            }
            this.favoritesService.duplicateEntriesAlertMsg(msg.title, msg.text, "person");
        } else {
            this.displayShared = true;
            $(this.sharedListDialog).modal({
                show: true,
                keyboard: false
            });
            setTimeout(() => {
                document.getElementById("sharedListName").focus();
            }, 500);
        }
    }

    getlocalstoragedata() {
        this.favoritesService.sharedListOptions = [];
        if (this.uiLocalStorageService.get(Models.LocalStorageConstants.favoritesSharedList)) {
            this.favoritesService.sharedListOptions = this.uiLocalStorageService.get(Models.LocalStorageConstants.favoritesSharedList);
        }
    }

    /**
     * Local Storage used to store 10 recently shared list
     * @param event
     * @param linkEvent
     */
    showRecentlyShared(event, linkEvent) {
        if (this.favoritesService.sharedListOptions && this.favoritesService.sharedListOptions.length > 0) {
            $(this.recentlySharedListsDialog).modal('show');
            setTimeout(() => {
                document.getElementById("btn_recently_shared_list_Ok").focus();
            }, 500);
        } else if (linkEvent) {
            let message: any = [Models.SharedListConstants.recentlyCreatedbody];
            this.favoritesService.duplicateEntriesAlertMsg(Models.SharedListConstants.recentlyCreatedTitle, message, 'person');
        } else {
            event.stopPropagation(); //avoid closing of bootstrap dropdown
        }
    }

    /**
     * Collapse all
     * @param root
     * @param optionContextType
     */
    collapseAll(root: any, optionContextType: string) {
        let x;
        for (let x = 0; x < root.Folders.length; x++) {
            root.Folders[x].expanded = false;
            this.collapseAll(root.Folders[x], optionContextType);
        }

        if (optionContextType && optionContextType != null && optionContextType != undefined && optionContextType != "") {
            if (optionContextType.toLowerCase() === "person") {
                setTimeout(() => {
                    document.getElementById('favorite-people-options-link').focus();
                }, 200);
            } else if (optionContextType.toLowerCase() === "form") {
                setTimeout(() => {
                    document.getElementById('favorite-form-options-link').focus();
                }, 200);
            }
        } else {
            setTimeout(() => {
                document.getElementById('open-favorites-btn').focus();
            }, 200);
        }
    }

    /**
     * Expand all
     * @param root
     * @param optionContextType
     */
    expandAll(root: any, optionContextType: string) {
        let x;
        for (let x = 0; x < root.Folders.length; x++) {
            root.Folders[x].expanded = true;
            this.expandAll(root.Folders[x], optionContextType);
        }

        if (optionContextType && optionContextType != null && optionContextType != undefined && optionContextType != "") {
            if (optionContextType.toLowerCase() === "person") {
                setTimeout(() => {
                    document.getElementById('favorite-people-options-link').focus();
                }, 200);
            } else if (optionContextType.toLowerCase() === "form") {
                setTimeout(() => {
                    document.getElementById('favorite-form-options-link').focus();
                }, 200);
            }
        } else {
            setTimeout(() => {
                document.getElementById('open-favorites-btn').focus();
            }, 200);
        }
    }

    renamePerson(objectArray: any) {
        let msg: any = {};
        this.favoritesService.getSelected(this.favoritesService.favorites.PERSON, objectArray);
        if (objectArray.length == 0) {
            msg.text = Models.PromptMessageConstants.favoriteEmptySelectionRename,
                this.favoritesService.duplicateEntriesAlertMsg('', [msg.text], 'person');
        }
        if (objectArray.length > 1) {
            msg.text = Models.PromptMessageConstants.favoriteMultipleSelectionRename;
            this.favoritesService.duplicateEntriesAlertMsg('', [msg.text], 'person');
        }
        if (objectArray.length == 1) {
            objectArray[0].rename = true; // makes input box appear
            if (objectArray[0].FolderLabel) {
                objectArray[0].tempName = objectArray[0].FolderLabel; // if it is a folder
            } else {
                objectArray[0].tempName = objectArray[0].ItemLabel; // if it is an item
            }
            this.favoritesService.renameFlag.rename = true;
            // get focus on rename input box asynchronously
            setTimeout(() => {
                let folderPersonRenameElement = document.getElementById("folder-person-rename") as HTMLInputElement;
                folderPersonRenameElement.focus();
                folderPersonRenameElement.select();
            }, 100);
        }
        this.selected.length = 0;
    }

    removePeople(objectArray: any) { // delete items and/or folder in objectArray
        this.favoritesService.getSelected(this.favoritesService.favorites.PERSON, objectArray);
        if (objectArray.length == 0) {
            let msg: any = {};
            msg.text = Models.PromptMessageConstants.favoriteRemoveMultipleSelection;
            this.favoritesService.duplicateEntriesAlertMsg(Models.PromptMessageConstants.removeFavoritesError, [msg.text], 'person');
        } else {
            this.favoritesService.deleteObjects(objectArray, Models.HelperText.person, this.removePeopleSuccess, null, this);
        }
    }

    /**
     * Input box appears for item or folder to be renamed
     * @param formFavorites
     */
    renameForm(formFavorites: any) {
        if (this.favoritesService.renameFormFolders.length == 0) {
            this.favoritesService.notificationMessage(Models.PromptMessageConstants.favoriteValidationMessageNoSelection, 'form');
        } else if (this.favoritesService.renameFormFolders.length > 1) {
            this.favoritesService.notificationMessage(Models.PromptMessageConstants.favoriteValidationMessageMultiSelection, 'form');
        } else {
            this.favoritesService.renameFormFolders[0].Item.rename = true;
            if (!this.validationService.isNullOrEmpty(this.favoritesService.renameFormFolders[0].Item.FolderLabel)) {
                this.favoritesService.renameFormFolders[0].Item.FolderText = this.favoritesService.renameFormFolders[0].Item.FolderLabel;
                this.favoritesService.renameFormFolders[0].Item.prevValue = this.favoritesService.renameFormFolders[0].Item.FolderLabel;
                // get focus on rename input box asynchronously
                setTimeout(() => {
                    let renameFolderElement = document.getElementById("txt_" + this.favoritesService.renameFormFolders[0].Item.FolderID) as HTMLInputElement;
                    renameFolderElement.focus();
                    renameFolderElement.select();
                }, 100);
            } else {
                this.favoritesService.renameFormFolders[0].Item.ItemText = this.favoritesService.renameFormFolders[0].Item.ItemLabel;
                this.favoritesService.renameFormFolders[0].Item.prevValue = this.favoritesService.renameFormFolders[0].Item.ItemLabel;
                // get focus on rename input box asynchronously
                setTimeout(() => {
                    let renameFolderItemElement = document.getElementById("txt_" + this.favoritesService.renameFormFolders[0].Item.ItemID) as HTMLInputElement;
                    renameFolderItemElement.focus();
                    renameFolderItemElement.select();
                }, 100);
            }
        }
    }

    removeForm() {
        if (this.favoritesService.selectedForm.isSelection == false) {
            let msg: any = {};
            msg.text = Models.PromptMessageConstants.favoriteRemoveSinleSelection;
            this.favoritesService.duplicateEntriesAlertMsg(Models.PromptMessageConstants.removeFavoritesError, [msg.text], 'form');
        } else {
            let temp = [];
            temp.push(this.favoritesService.selectedForm.form);
            this.favoritesService.deleteObjects(temp, Models.HelperText.form, this.removeFormSuccess, null, this);
        }

        setTimeout(() => {
            document.getElementById("favorite-form-options-link").focus();
        }, 100);
    }

    addToContextListener(favComponent: FavoritesComponent) {
        let self: FavoritesComponent = <FavoritesComponent>favComponent;
        self.openSelectedFavorites();
    }

    shiftTabKeyDown(event: any, requestFromFavoriteHeader: boolean = false) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        let esc: boolean = event.keyCode == Models.KeyCodes.escape;

        if (shift && tab) {
            if (requestFromFavoriteHeader == true) {
                let favoritesIcon = document.getElementById("addToFavoritesIcon");
                favoritesIcon.classList.remove("open");
                document.getElementById("btnAddToFavoritesIcon").focus();
            }
            else {
                $(".favorite-overlay").trigger("click");
            }
        }
        else if (esc && requestFromFavoriteHeader == true) {
            let favoritesIcon = document.getElementById("addToFavoritesIcon");
            favoritesIcon.classList.remove("open");
            document.getElementById("btnAddToFavoritesIcon").focus();
        }
    }

    tabKeyDown(event: any, requestFromFavoriteHeader: boolean = false) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        let esc: boolean = event.keyCode == Models.KeyCodes.escape;

        if (!shift && tab) {
            if (requestFromFavoriteHeader == true) {
                let favoritesIcon = document.getElementById("addToFavoritesIcon");
                favoritesIcon.classList.remove("open");
            }
            else {
                $(".favorite-overlay").trigger("click");
            }
        }
        else if (esc && requestFromFavoriteHeader == true) {
            let favoritesIcon = document.getElementById("addToFavoritesIcon");
            favoritesIcon.classList.remove("open");
            document.getElementById("btnAddToFavoritesIcon").focus();
        }
    }

    /**
     * Open a single form and/or one or more person records to context card area
     */
    openSelectedFavorites() {
        // Declare the selectedFavoriteForm string 
        let selectedFavoriteForm: string = "";

        // If favoritesService indicates that a form has been selected, assign the form value (ApplicationMnemonic-FormMnemonic) to selectedForm
        if (this.favoritesService.selectedForm.isSelection === true) {
            selectedFavoriteForm = this.favoritesService.selectedForm.form.ItemValue;
        }

        // Clear the selectedItemValues array in favoritesService
        this.favoritesService.selectedItemValues.length = 0;

        // Declare the selectedFavoritePersonRecords array
        let selectedFavoritePersonRecords: any = [];

        // Get selected people in Favorites Person area of Favorites dialog
        this.favoritesService.getSelectedPeople(this.favoritesService.favorites.PERSON, selectedFavoritePersonRecords);

        if (this.validationService.isNullOrEmpty(selectedFavoriteForm) && (selectedFavoritePersonRecords.length === 0)) {// If neither a form nor a person record is selected, display an Alert dialog
            let msg: any = {};
            msg.title = Models.PromptMessageConstants.favoriteErrorMessageTitle;
            msg.text = [Models.PromptMessageConstants.favoriteErrorMessageText];
            this.favoritesService.duplicateEntriesAlertMsg(msg.title, msg.text, "open");
        } else {
            // Process any selected person records in Favorites
            if (selectedFavoritePersonRecords.length > 0) {
                // Get current context
                let contextData: any = this.contextService.getContext();
                // Indicate that context records are being loaded from favorites
                this.rootScopeService.loadContextFromFavorite = true;
                // Parse through the list of selected people records
                for (let i = 0; i < selectedFavoritePersonRecords.length; i++) {
                    let favoriteRecord: any = selectedFavoritePersonRecords[i];
                    // If selected favorite record already exists in context records, set the checkContextExistInFavorite to true in rootScope
                    if (contextData.data.some(contextRecord => { return contextRecord[Models.HelperText.ID] === favoriteRecord.ItemValue; })) {
                        this.rootScopeService.checkContextExistInFavorite = true;
                        break;
                    }
                }

                // Push the selected favorite person record to list of context records to be sent to the server
                // Generate search parameters
                let listOfSelectedPeople: string = selectedFavoritePersonRecords.map((value: any) => { return value.ItemValue; }).join("|");
                let cursorSize: number = selectedFavoritePersonRecords.length;
                let startRow: number = 1;
                let defaultContext: string = Models.HelperText.person;
                let queryColumns: any = this.searchService.buildQueryColumns(defaultContext);
                let cursorHandle: string = defaultContext;

                // Search Response results call back handler
                let resultsCallback = (results: any) => {
                    if (results.TotalRows > 0) {
                        // CUI-5712: cancelPrompted needs to be null, not false
                        this.rootScopeService.cancelPrompted = null;
                        this.contextService.listRefreshRecord(Models.HelperText.person, this.searchService.parseResults(results));
                    }
                };

                // Search for person records
                this.searchService.search(defaultContext, cursorHandle, startRow, cursorSize, queryColumns, listOfSelectedPeople, "", "", "", "", resultsCallback);

                // Add person to person search history
                let msg: any;
                for (let s = 0; s < selectedFavoritePersonRecords.length; s++) {
                    msg = {
                        "historyID": selectedFavoritePersonRecords[s].ItemLabel,
                        "isForm": false
                    };
                    this.eventsService.broadcast(Models.CommandConstants.addToHistory, msg);
                }
            }

            // Process any selected form
            if (!this.validationService.isNullOrEmpty(selectedFavoriteForm)) {
                // Toggle form search button
                this.rootScopeService.analyticsLastFormOpen = selectedFavoriteForm;
                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.launchForm, "", selectedFavoriteForm, "", "", "");
            }

            // Hide favorites modal
            $("#favorites .modal").modal('hide');
            this.rootScopeService.favoritePanelOpen = false;

            // Since we are opening all the selected items, remove any highlighting for next time the user opens favorites modal.
            this.favoritesService.selectedForm.form = {};
            this.favoritesService.selectedForm.isSelection = false;

            while (this.favoritesService.selectedItemValues.length) {
                this.favoritesService.selectedItemValues.pop();
            }

            this.favoritesService.clearSelected(this.favoritesService.favorites.PERSON);
            this.favoritesService.clearSelected(this.favoritesService.favorites.FORM);
        }
    }

    /**
     * Tabbing on Open button in footer should move focus to either Add to favorites icon or Help icon in the header
     * @param event - window event
     */
    openFavoritesBtnKeyDown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.stopPropagation();
            event.preventDefault();

            // If addToFavorites Icon is disabled, focus on help
            if (document.getElementById("addToFavoritesIcon").classList.contains("disabled")) {
                this.focusService.focusOn("favorites-help-btn", true);
            }
            // If addToFavorites Icon is enabled, focus on this icon
            else {
                this.focusService.focusOn("btnAddToFavoritesIcon", true);
            }
        }
    }

    /**
     * Shift tab on Add to favorites button should focus on Open button in the footer
     * @param event
     */
    btnAddToFavoritesIconKeyDown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("open-favorites-btn", true);
        }
    }

    /**
     * Shift tab on Help button should focus on either the add to favorites button if it is active or the Open button in the footer
     * @param event
     */
    favoritesHelpBtnKeyDown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            // If addToFavorites Icon is disabled, focus on Open button
            if (document.getElementById("addToFavoritesIcon").classList.contains("disabled")) {
                this.focusService.focusOn("open-favorites-btn", true);
            }
            // If addToFavorites Icon is enabled, focus on this icon
            else {
                this.focusService.focusOn("btnAddToFavoritesIcon", true);
            }
        }
    }

    /**
     * Favorites keyboard shortcut for Help
     * @param event
     */
    favoritesModalKeydown(event: any) {
        let keyCtrl = event.ctrlKey;
        let keyAlt = event.altKey;
        let keyCode = event.keyCode;
        let keyCharCode = event.charCode;
        let keyChar = String.fromCharCode(keyCode || keyCharCode);

        if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
            event.stopPropagation();
            event.preventDefault();
            this.help('Favorites');
        }
        else {
            return;
        }
    }

    private removePeopleSuccess(favoritesComponent: FavoritesComponent, objectArray: any) {
        let self: FavoritesComponent = <FavoritesComponent>favoritesComponent;
        self.favoritesService.removeSelected(self.favoritesService.favorites.PERSON, objectArray);
        self.selected.length = 0;
    }

    private removeFormSuccess(favoritesComponent: FavoritesComponent, objectArray: any) {
        let self: FavoritesComponent = <FavoritesComponent>favoritesComponent;
        // remove items and/or folders from treeview
        self.favoritesService.removeSelected(self.favoritesService.favorites.FORM, objectArray);
        // clean up selection for next remove
        self.favoritesService.clearSelectedForm();
    }

    ngOnDestroy() {
        this.eventsService.destroy("addToContext", this.addToContextListenerId);
        this.eventsService.destroy("favoritesInitialFocus", this.initialFocusListenerId);
    }
}