/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import {Component, Inject, Input, OnInit} from "@angular/core";
import {
    ContextManagerService,
    ConfigurationService,
    ContextService,
    PreferencesService,
    FocusService
} from "../../services/";
import * as Models from "../../models";
import * as _ from 'lodash';

@Component({
    selector: 'contextmanagercard',
    template: require('./context-manager-card.component.html')
})
export class ContextManagerCardComponent implements OnInit {
    @Input() record: any;
    @Input() index: number;
    @Input() selected: any;
    @Input() preferences: any;
    @Input() totalCards: any;
    @Input() headerDefinition: any;
    @Input() activePosition: any;
    availableContexts: any;
    contextSelection: any;
    personImagesEnabled: boolean = false;

    constructor(private contextManagerService: ContextManagerService,
                private contextService: ContextService,
                private preferencesService: PreferencesService,
                private configurationService: ConfigurationService,
                private focusService: FocusService) {

    }

    ngOnInit() {
        this.availableContexts = this.contextService.context;
        // Calculate column width
        _.each(this.headerDefinition.sorted, (row: any) => {
            _.each(row, (col: any) => {
                var columnPrefixes = ["col-lg-", "col-md-", "col-sm-", "col-xs-"];
                var colWidth = Math.ceil(col.columnSpan * (12 / row.length));
                col.columnWidthClass = ["col-lg-" + colWidth, "col-md-" + colWidth, "col-sm-" + colWidth, "col-xs-" + colWidth].join(" ");
            });
        });
        this.personImagesEnabled = this.configurationService.getConfiguration().ClientParameters.PersonImagesEnabled;
    }

    cardKeyDown(event: any, index: number) {
        let space: boolean = event.keyCode == Models.KeyCodes.space;
        let enter: boolean = event.keyCode == Models.KeyCodes.enter;
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        // If key press is tab and the current card is the last one in the list, focus on Select All checkbox. Otherwise, proceed.
        if (!shift && tab) {
            if (index == this.contextManagerService.availableContexts.PERSON.data.length - 1) {
                event.preventDefault();
                event.stopPropagation();
                this.focusService.focusOn("selectAllContextCards", true);
                return;
            }
        }

        if (space || enter) {
            event.preventDefault();
            event.stopPropagation();
        }

        //Toggle Result
        if (space) {
            this.contextManagerService.toggleResult(this.record, this.totalCards, event);
        }
        // Open Selected
        else if (enter && this.selected.length == 1) {
            this.contextManagerService.openSelected(this.availableContexts.PERSON, undefined, event);
        }
    }
}