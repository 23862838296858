/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit } from "@angular/core";
import * as Models from "../../../models";
import {
    ConfigurationService,
    ContextService,
    FocusService,
    HelpService,
    UiLocalStorageService,
    ProcessingService,
    PromptService,
    RootScopeService,
    SearchService,
    ValidationService
} from "../../../services";

import * as _ from 'lodash';

@Component({
    selector: 'advancedsearch',
    template: require('./advancedSearch.component.html')
})
export class AdvancedSearchComponent implements OnInit {

    personName: string = "";
    sharedListName: string = "";
    recentlysharedlist: any = [];
    advancedFields: any = [];

    constructor(private uiLocalStorageService: UiLocalStorageService, private contextService: ContextService,
        private searchService: SearchService, private validationService: ValidationService,
        private processingService: ProcessingService, private configurationService: ConfigurationService,
        private promptService: PromptService, private rootScopeService: RootScopeService, private helpService: HelpService, private focusService: FocusService) {
    }

    ngOnInit() {
        this.recentlysharedlist = this.uiLocalStorageService.get(Models.LocalStorageConstants.favoritesSharedList);
        this.advancedFields = this.contextService.context["PERSON"].Advanced.displayFields;

        document.getElementById("personName").focus();
    }

    keyDown(event: any = null) {
        if ((event && event.keyCode == Models.KeyCodes.enter) || event == null) {
            this.search();
        }
    }

    overallkeydown(event: any = null) {

        let actionFound = false;

        if (event && event.ctrlKey && event.altKey && event.keyCode == Models.KeyCodes.h) { // CTRL + ALT + H
            this.help();
            actionFound = true;
        }
        else if (event && event.keycode == Models.KeyCodes.escape) { // Escape
            this.closePopover();
            actionFound = true;
        }

        if (actionFound) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onSharedListSelected(list: string) {
        this.sharedListName = list;
    }

    search() {
        // clear person search text from input
        this.rootScopeService.criteria.person = "";

        // initial cursor size is card size
        let cursorSize = this.uiLocalStorageService.get(Models.LocalStorageConstants.searchResultsRenderType) == "GRID"
            ? this.rootScopeService.preferences.GridSearchResults : this.rootScopeService.preferences.CardSearchResults;
        let srData = this.searchService.state.srData;
        // grab cursor size from last person search if available
        if (srData != null && srData['PERSON'] != null && srData['PERSON'].control != null && srData['PERSON'].control.cursorSize != null) {
            cursorSize = srData['PERSON'].control.cursorSize;
        }

        let criteriaAruguments: any = [];

        // push person name criteria
        if (!this.validationService.isNullOrEmpty(this.personName)) {
            criteriaAruguments.push(this.personName);
        }

        // push shared list name criteria
        if (!this.validationService.isNullOrEmpty(this.sharedListName)) {
            criteriaAruguments.push(";LIST " + this.sharedListName);
        }

        // add other fields criteria
        _.each(this.advancedFields, (field: any) => {
            if (!this.validationService.isNullOrEmpty(field.data)) {
                criteriaAruguments.push(";" + field.shortcut + " " + field.data);
            }
        });

        // create criteria for search request
        let criteria = criteriaAruguments.join(' ');
        criteria = this.searchService.formatSearchCriteria(criteria);

        if (!this.validationService.isNullOrEmpty(criteria)) {

            this.reset();

            // close advanced search
            // this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);


            this.processingService.showProcessing("Advanced Search", "Please Wait ...", "Search ...");


            this.searchService.search("PERSON", "PERSON", 1, cursorSize, this.searchService.buildQueryColumns("PERSON"), criteria, "", "", this.successCallback, this.errorCallback, null, this, this);
        } else {
            this.searchService.searchInputValidation("Please provide at least one criteria for a search.");
        }
    }

    reset() {
        this.personName = "";
        this.sharedListName = "";
        _.each(this.advancedFields, (field: any) => {
            field.data = "";
        });
    }

    successCallback(dummy: any, component: any) {
        let self = <AdvancedSearchComponent>component;
        self.processingService.closeProcessing();
    }

    errorCallback(state: any, error: any, component: any) {
        let self = <AdvancedSearchComponent>component;
        self.processingService.closeProcessing();
        var errorMessage: any = {};
        errorMessage.title = 'Error';
        var msg = error;
        errorMessage.text = [msg];
        errorMessage.defaultCallbackNumber = 0;


        errorMessage.buttons = [
            {
                label: "Ok",
                callback: () => {
                    // When help message has totally faded away, bring back the detail dialog
                    self.promptService.popupMessageDismiss(function () {

                        self.processingService.closeProcessing();
                        setTimeout(function () {
                            document.getElementById('personName').focus();
                        }, 250);
                    }, false);
                },
                title: ""
            }
        ];
        self.promptService.popupMessageShow(errorMessage);

    }

    closePopover(event: any = null) {
        if (event == null || (event.keyCode == Models.KeyCodes.enter || event.keyCode == Models.KeyCodes.space)) {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closePopover);
        }
    }

    help(event: any = null) {
        if (event == null || (event.keyCode == Models.KeyCodes.enter || event.keyCode == Models.KeyCodes.space)) {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.AdvancedSearch);
            document.getElementById('personName').focus();
        }
        // Shift tab on advanced search help should focus on search in footer
        else {
            let tab: boolean = event.keyCode == Models.KeyCodes.tab;
            let shift: boolean = event.shiftKey;

            if (shift && tab) {
                event.preventDefault();
                event.stopPropagation();

                this.focusService.focusOn("btnAdvancedSearch", true);
            }
        }
    }

    /**
     * Tab on Search should focus on Advanced Search Help
     * @param event
     */
    btnAdvancedSearchKeydown(event: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            this.focusService.focusOn("btnAdvancedSearchHelp", true);
        }
    }
}