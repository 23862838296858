/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'external-link',
    template: require('./external-link.component.html')
})
export class ExternalLinkComponent implements OnInit {
    // only schemes in this list are supported
    // the exception is links that start with www
    // will automatically be assumed as http
    private uriSchemes = [
        'http',
        'https',
        'ftp',
        'sftp',
        'ftps',
        'mailto',
        'file',
        'rtsp',
        'rtsps',
        'ssh',
        'smb',
        'tel',
        'ldap',
        'urn',
        'news',
    ];

    @Input() url: string;

    ngOnInit() {
        if (!_.isNil(this.url)) {
            this.url = _.toLower(this.url);
        }
    }

    getRenderLink() {
        let url = this.url;

        if (this.isImplicitHttp(url)) {
            // if the user didn't enter a url with the
            // protocol but it starts with www. then just
            // assume it is http. would do https, but more sites 
            // now are upgrading http to https. we don't want a
            // site with only http to fail since we tried https.
            url = `http://${url}`;
        }

        return this.encodeUrl(url);
    }

    canRenderLink() {
        return this.hasSupportedUriScheme(this.url);
    }

    private hasSupportedUriScheme(url: string): boolean {
        if (_.isNil(url)) {
            return false;
        }

        // we will automatically add the http scheme
        // if the url starts with www, so consider it valid
        if (this.isImplicitHttp(url)) {
            return true;
        }

        const scheme = _.find(this.uriSchemes, s => {
            return _.startsWith(url, `${s}://`)
        });

        return scheme != null;
    }

    private isImplicitHttp(url: string): boolean {
        return _.startsWith(url, 'www.');
    }

    private encodeUrl(url: string): string {
        return encodeURI(url);
    }
}
