/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import {
    RootScopeService,
    ConfigurationService,
    ServerCommandService,
    EventsService,
    InstrumentationService,
    UiLocalStorageService
} from "./";
import { Title } from "@angular/platform-browser";
import * as Models from "../models";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var $: any;
declare var ssoEnabled: any;
declare var window: any;

@Injectable()
export class SessionService {
    session: any = {};
    sessionStartTime: any;
    ssoEnabled: boolean = false;
    sessionToken: string = "";

    constructor(private titleService: Title, private rootScopeService: RootScopeService, private configurationService: ConfigurationService,
        private serverCommandService: ServerCommandService, private eventsService: EventsService, private instrumentationService: InstrumentationService,
        private uiLocalStorageService: UiLocalStorageService, private http: HttpClient) {
        this.ssoEnabled = ssoEnabled.toUpperCase() == "TRUE";
        this.eventsService.on("SessionCloseMessage", [this.sessionCloseMessageListener, this]);
        this.eventsService.on("logoutComplete", [this.logoutCompleteListener, this]);
        this.eventsService.on("BackupSession", [this.backupSessionListener, this])
    }

    backupSessionListener(service: any) {
        let self = <SessionService>service;
        self.backupSession();
    }

    create(params: any = null) {
        this.session = $.extend(true, {}, params)
        this.session.authenticated = true;
        this.rootScopeService.isWaiting = true;
        this.rootScopeService.isAuthenticated = this.session.authenticated;
        this.rootScopeService.userId = this.session.userId;
        this.sessionStartTime = new Date();

        this.titleService.setTitle(this.configurationService.getConfiguration().ClientParameters.HtmlTitleBar + ' - [' + this.configurationService.getConfiguration().EnvironmentName + "]");

        this.getColleagueApiToken();
    }

    /**
     * Get Colleague API JSON Web Token via an HTTP call to Colleague Web API
     */
    getColleagueApiToken(callback: any = null) {
        let collToken = this.getColleagueToken();

        if (!collToken) {
            let url: string = [this.configurationService.getConfiguration().serviceUrl, 'session/colleagueapi/token'].join('/');
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };

            const requestBody = {
                "ControlId": this.getControlId(),
                "SecurityToken": this.getToken()
            };

            this.http.post(url, requestBody, httpOptions)
                .timeoutWith(30000, Observable.throwError(new Error("Get Colleague API Token call timed out.")))
                .subscribe((response) => {
                    this.session["colleagueToken"] = response;
                    // Invoke callback
                    if (callback) {
                        callback();
                    }
                }, (error: any) => {
                    console.error(error);
                    // Invoke callback
                    if (callback) {
                        callback(error);
                    }
                });
        }
    }

    delete() {
        this.session = undefined;
        this.rootScopeService.isAuthenticated = false;
    }

    getProperty(property: string) {
        return this.session[property] || '';
    }

    backupSession() {

        $.removeCookie('refresh_session', { path: '/' });
        $.removeCookie('refresh_sso_logout_token', { path: '/' });

        // Only Create cookie for non-saml cases
        // SAML enabled env will auto redirect to identity login page, which would redirect to login page with token because of existing session

        if (this.ssoEnabled == false) {
            var date = new Date();
            var minutes = 2;
            date.setTime(date.getTime() + (minutes * 60 * 1000));

            $.cookie('refresh_session', JSON.stringify(this.session), { expires: date, path: '/' });
            $.cookie('refresh_sso_logout_token', JSON.stringify(this.sessionToken), { expires: date, path: '/' });
        }
        window.allowReload = true;

        // if current threshold is bigger than current counter, change the threshold to 95% of set threshold
        if (this.rootScopeService.threshold > this.rootScopeService.performanceCounter) {
            this.uiLocalStorageService.set(Models.LocalStorageConstants.refreshBrowserSessionThreshold, 0.95 * this.rootScopeService.threshold);
        }
        else { // if threshold is smaller or equal to current counter, change the threshold to current counter.
            this.uiLocalStorageService.set(Models.LocalStorageConstants.refreshBrowserSessionThreshold, this.rootScopeService.performanceCounter);
        }

        window.location.href = "index.html";

    }

    restoreSession() {
        let params = JSON.parse($.cookie('refresh_session'));
        this.sessionToken = JSON.parse($.cookie('refresh_sso_logout_token'));

        this.create(params);
        this.rootScopeService.isWaiting = false;

        // Log the user's current threshold that they have updated to
        console.log("Your current performance threshold number is: " + this.uiLocalStorageService.get(Models.LocalStorageConstants.refreshBrowserSessionThreshold));
    }

    logout() {
        window.sessionToken = this.sessionToken;
        this.serverCommandService.sendProcessCommandMessage("Logout");
    }

    sessionCloseMessageListener(service: any) {
        let self = <SessionService>service;

    }

    closeSAMLSession(service: any) {
        let self = <SessionService>service;
        if (self.ssoEnabled === true) { //SSO Logout 

            // set session token in case of session timeout event, as actual logout event (triigered by clicking logout) won't occur to set session timeout. CUI-4038
            if (window.sessionToken == null || window.sessionToken == '') {
                window.sessionToken = self.sessionToken;
            }

            if (window.sessionToken != null && window.sessionToken != '') {
                // setTimeout(function () {
                window.location.href = self.configurationService.configuration.serviceUrl.concat('/sso/logout?token=' + window.sessionToken);
                // }, 1000);
            }
        }
    }


    sessionDestroy() {
        let sessionEndTime: any = new Date();
        let totalSessionMinutes = Math.round(((sessionEndTime - this.sessionStartTime) / 1000 / 60) % 60);
        this.instrumentationService.event("Session", 'Duration', 'Minutes', totalSessionMinutes);
        this.sessionStartTime = null;
        this.rootScopeService.isAuthenticated = false;

        // Clearing Login inputs
        // CUI-5324 - Added timeout so that the login component can be loaded and jQuery selectors can find the ID and Password fields.
        setTimeout(() => {
            let userIdElement = document.getElementById("UserID") as HTMLInputElement;
            userIdElement.value = "";
            let userPasswordElement = document.getElementById("UserPassword") as HTMLInputElement;
            userPasswordElement.value = "";
        }, 50);
        this.rootScopeService.loggedOut = true;

        if (this.ssoEnabled == true) {
            this.closeSAMLSession(this);
        }

        setTimeout(() => {
            window.close();
            // refresh only if SSO is not enabled.
            // if SSO is enabled and logging out of IDP is enabled SAML logout will redirect to SAML logout page.
            // if SSO is enabled and logging out of IDP is disabled page doesn't need to be refreshed as if you do so it will automatically log in.
            // if SSO is enabled and logging out of IDP is disabled page doesn't need to be refreshed as if you do so it will automatically log in.
            if (this.ssoEnabled == false) {
                window.location.href = "index.html";
            }
        }, 3000);

    }

    logoutCompleteListener(service: any) {
        let self = <SessionService>service;
        self.sessionDestroy();
    }

    getToken() {
        return this.getProperty("token");
    };

    getControlId() {
        return this.getProperty("controlId");
    }

    getTokenIndex() {
        return this.getProperty("tokenIndex");
    }

    getOperatorId() {
        return this.getProperty("operatorId");
    }

    getUserId() {
        return this.getProperty("userId");
    }

    getCurrentPersonId() {
        return this.getProperty("currentPersonId");
    }

    // Get Colleague Token from Session Service properties saved on the front-end.
    getColleagueToken() {
        return this.getProperty("colleagueToken");
    }

    isAuthenticated() {
        return this.getProperty("authenticated") === true;
    }

}