/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as Models from "../models";
import { Title } from "@angular/platform-browser";
import { EventsService, ProcessingService, PromptService, RootScopeService } from "./";

import * as _ from 'lodash';
declare var webAPIBaseUrl: any;
declare var baseWebsiteUrl: any;
declare var themeEditorParameters: any;
declare var less: any;

@Injectable()
export class ConfigurationService {
    clientProperties: any = {
        title: "",
        version: "",
        disclaimer: "",
        copyright: ""
    };

    configuration: any = {
        serviceUrl: webAPIBaseUrl,
        baseUrl: baseWebsiteUrl + "/Client",
        environment: "ellucian",
        helpProcesses: "",
        clientProperties: this.clientProperties
    };

    privacyCodes: any = [];
    personRecordDenialMessage: string = "";
    displayConfigurationError: boolean = false;
    displayLoadingConfiguration: boolean = false;
    displayLogoutComplete: boolean = false;

    constructor(private http: HttpClient, private titleService: Title, private processingService: ProcessingService,
        private eventsService: EventsService, private rootScopeService: RootScopeService, private promptService: PromptService) {
        this.eventsService.on(Models.EventConstants.sessionCloseMessage, [this.logoutCompleteMessageListener, this]);
    }

    init() {
        // // If site administrators have opted for Ellucian Theme Editor, apply them
        if (themeEditorParameters && themeEditorParameters.hasOwnProperty("optIn") && themeEditorParameters.optIn === true) {
            this.applyThemeEditorSettings();
        }
        else {
            this.rootScopeService.useThemeEditor = false;

            $('#uiWaiting').hide();
            this.processConfigurationInit();
        }
    }

    getConfiguration() {
        return this.configuration;
    }

    getPersonRecordDenialMessage() {
        return this.personRecordDenialMessage;
    }

    logoutCompleteMessageListener(configurationService: any) {
        let self: ConfigurationService = <ConfigurationService>configurationService;
        // only logout if timeout message dialog is not active.
        if (self.rootScopeService.TimeoutMessageOpen != true) {

            self.processingService.closeProcessing();
            self.eventsService.broadcast(Models.EventConstants.logoutComplete);
            self.displayLoadingConfiguration = false;
            self.displayConfigurationError = false;
            self.displayLogoutComplete = true;
        }
    }

    private getClientHelpProcesses() {
        let clientUrl: string = [this.configuration.serviceUrl, 'Resource', 'Client', 'false'].join('/');
        let headers: any = { 'Content-Type': 'application/json' };
        this.http.get(clientUrl, headers).subscribe(
            (response: any) => {
                this.clientProperties.title = response.Title;
                this.clientProperties.version = response.Version;
                this.clientProperties.disclaimer = response.Disclaimer;
                this.clientProperties.copyright = response.Copyright;
            },
            (error: any) => {
                this.displayLoadingConfiguration = false;
                this.displayConfigurationError = true;
                console.error(error);
            });

        let helpUrl: string = [this.configuration.serviceUrl, 'Resource', 'HelpProcesses', 'false'].join('/');
        this.http.get(helpUrl, headers).subscribe(
            (response: any) => {
                this.configuration.helpProcesses = response;
            }, (error: any) => {
                this.displayLoadingConfiguration = false;
                this.displayConfigurationError = true;
                console.error(error);
            });
    }

    private processConfigurationInit() {
        // Display Loading Configuration message
        this.displayLoadingConfiguration = true;

        this.getClientHelpProcesses();

        let url = [this.configuration.serviceUrl, 'configuration'].join('/');
        let httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
        };
        this.http.get(url, httpOptions).timeoutWith(30000, Observable.throwError({
            type: 'LOAD',
            msg: 'Configuration request timed out.'
        })).subscribe(
            (data: any) => {
                this.displayLoadingConfiguration = false;
                if (data.hasOwnProperty("Errors") && data.Errors.length > 0) {
                    let errorMessages: any = '';
                    _.each(data.Errors, (it: any) => {
                        errorMessages += it.ErrorMessageText;
                    });
                    this.displayConfigurationError = true;
                    console.error(errorMessages);
                    this.displayLoadingConfiguration = false;
                    this.displayConfigurationError = true;
                } else {
                    _.extend(this.configuration, data);
                    if (data && data.hasOwnProperty("ClientParameters") && data.ClientParameters && data.ClientParameters.ChangePasswordEnabled == true) {
                        let reqUrl = [this.configuration.serviceUrl, 'ChangePasswordMessage'].join('/');
                        this.http.get(reqUrl, httpOptions).subscribe((response: any) => {
                            this.eventsService.broadcast("ChangePasswordMessage", response.data);
                        });
                    }
                    data.ClientParameters.HtmlTitle = data.ClientParameters.HtmlTitleBar + ' - [' + data.EnvironmentName + ']';
                    this.titleService.setTitle(data.ClientParameters.HtmlTitle);
                    // If date formats are empty add a default date format.
                    if (data.ClientParameters.Date2Format == "D2/" || data.ClientParameters.Date2Format == "D2-") {
                        data.ClientParameters.Date2Format += "MDY";
                    }

                    if (data.ClientParameters.Date4Format == "D4/" || data.ClientParameters.Date4Format == "D4-") {
                        data.ClientParameters.Date4Format += "MDY";
                    }
                    //loop privacy codes
                    for (let i = 0; i < data.ClientParameters.PrivacyCodes.length; i++) {
                        this.privacyCodes.push({
                            Key: data.ClientParameters.PrivacyCodes[i],
                            Value: data.ClientParameters.PrivacyCodeDescriptions[i]
                        });
                    }
                    this.personRecordDenialMessage = data.ClientParameters.PersonRecordDenialMessage;
                    this.eventsService.broadcast(Models.EventConstants.configurationLoaded);
                }
            }, (error: any) => {
                this.displayLoadingConfiguration = false;
                this.displayConfigurationError = true;
                console.error(error);
            });
    }

    /**
     * Apply Theme Editor settings to Colleague UI stylesheets
     */
    private applyThemeEditorSettings() {
        // Get Theme Editor settings
        let { baseUrl, accountApiId, themeName } = themeEditorParameters;

        // Remove any trailing slashes so that URL can be compiled with slashes by the source code
        baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
        accountApiId = accountApiId.endsWith('/') ? accountApiId.slice(0, -1) : accountApiId;
        themeName = themeName.endsWith('/') ? themeName.slice(0, -1) : themeName;

        // Compile EllucianThemeEditor URL
        let themeEditorUrl: string = `${baseUrl}/${accountApiId}/theme/get?name=${themeName}`;
        let headers: any = { 'Content-Type': 'application/json' };

        // GET Theme
        this.http.get(themeEditorUrl, headers).subscribe(
            (response: any) => {
                try {
                    // Get Theme Editor Parameters
                    let { favicon, bgimage, logo, secondaryLogo, color1, color2, color3 } = response;
                    let color3_hover = response['color3-hover'];
                    let color1_active = response['color1-active'];
                    let color1_light = response['color1-light'];
                    let color2_active = response['color2-active'];
                    let hexColorRegex: RegExp = /#[A-Za-z0-9]+/;
                    let lessVarObject: any = {};

                    // If Sign In Background image is available, set it up for modification in less variables
                    if (bgimage) { lessVarObject['@signInBackground'] = `'${bgimage}'`; }

                    // If Sign In Logo image is available, set it up for modification in less variables
                    if (logo) { lessVarObject['@signInLogo'] = `'${logo}'`; }

                    // If Header Logo image is available, set it up for modification in less variables
                    if (secondaryLogo) { lessVarObject['@headerLogo'] = `'${secondaryLogo}'`; }

                    // If Primary Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color1 && hexColorRegex.test(color1)) { lessVarObject['@primary-color'] = `${color1}`; }

                    // If Primary Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color1 && hexColorRegex.test(color1)) { lessVarObject['@university-default'] = `${color1}`; }

                    // If Secondary Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color2 && hexColorRegex.test(color2)) { lessVarObject['@secondary-color'] = `${color2}`; }

                    // If Action Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color3 && hexColorRegex.test(color3)) { lessVarObject['@action-color'] = `${color3}`; }

                    // If Action Hover Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color3_hover && hexColorRegex.test(color3_hover)) { lessVarObject['@action-hover-color'] = `${color3_hover}`; }

                    // If Primary Focus Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color1_active && hexColorRegex.test(color1_active)) { lessVarObject['@primary-color-focus'] = `${color1_active}`; }

                    // If Primary Focus Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color1_light && hexColorRegex.test(color1_light)) { lessVarObject['@primary-color-light'] = `${color1_light}`; }

                    // If Primary Focus Color is available and in correct Hex color code format, set it up for modification in less variables
                    if (color2_active && hexColorRegex.test(color2_active)) { lessVarObject['@secondary-color-active'] = `${color2_active}`; }

                    // Apply Theme Editor settings
                    if (Object.keys(lessVarObject).length >= 1) {
                        less.modifyVars(lessVarObject);
                        this.rootScopeService.themeEditorLessVariables = lessVarObject;
                    }

                    // Modify favicon
                    if (favicon) {
                        let head = document.getElementsByTagName('head')[0];

                        if (head) {
                            // Prevent default favicon loads by deleting the links -- CUI-5747
                            let defaultFavicon: HTMLLinkElement = document.getElementById('defaultFavicon') as HTMLLinkElement;
                            defaultFavicon && head.removeChild(defaultFavicon);

                            // Set ETE favicon
                            let eteFaviconLink = document.createElement('link');
                            eteFaviconLink.href = `${favicon}?${Math.random()}`;
                            eteFaviconLink.type = 'image/x-icon';
                            eteFaviconLink.rel = 'icon';
                            head.appendChild(eteFaviconLink);
                        }
                    }

                    $('#uiWaiting').hide();
                    this.processConfigurationInit();
                    this.rootScopeService.useThemeEditor = true;
                }
                catch (err) {
                    console.error(`Failed to completely apply Ellucian Theme Editor settings:
                    ${err}`);

                    $('#uiWaiting').hide();
                    this.processConfigurationInit();
                    this.rootScopeService.useThemeEditor = false;
                }
            },
            (error: any) => {
                console.error(`Failed to retrieve Theme Editor Settings:\n
                                ${error}`);

                $('#uiWaiting').hide();
                this.processConfigurationInit();
                this.rootScopeService.useThemeEditor = false;
            }
        );
    }
}