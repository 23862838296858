/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable, Inject } from '@angular/core';
import {
    EventsService,
    RootScopeService,
    SearchService,
    ContextManagerService,
    ContextService,
    ServerCommandService,
    UiLocalStorageService
} from "./";
import * as Models from "../models";

declare var $: any;

@Injectable()
export class ContextHeaderService {
    titles: any = {};

    constructor(private contextService: ContextService, private uiLocalStorageService: UiLocalStorageService,
        private serverCommandService: ServerCommandService, private rootScopeService: RootScopeService,
        private searchService: SearchService, private contextManagerService: ContextManagerService, private eventsService: EventsService) {
        this.reset();
        this.eventsService.on("preferencesLoaded", [this.preferencesLoadedListener, this]);
    }

    reset(position: number = 1) {
        this.titles.first = "First Button";
        this.titles.previous = "Previous Button";
        this.titles.next = "Next Button";
        this.titles.last = "Last Button";

    }

    openPopoverContext() {
        this.contextManagerService.setAdditionalContextRecordData(this.contextService.context);
    }

    isNextAllowed(position: number) {
        let currentContext = this.contextService.context.PERSON;
        let dataCount = (currentContext) ? currentContext.data.length : 0;
        if (dataCount == 0) return false;

        let data = currentContext.data;
        let header = currentContext.Header;


        if (position < (dataCount - 1)) {
            let nextPerson = data[position + 1][header['leftTitleField']] + " " + data[position + 1][header['rightTitleField']];
            let lastPerson = data[dataCount - 1][header['leftTitleField']] + " " + data[dataCount - 1][header['rightTitleField']];
            this.titles.next = nextPerson + '- Next Record (Ctrl+Dot)';
            this.titles.last = lastPerson + '- Last Record';
        } else if (dataCount > 0 && position === (dataCount - 1)) {
            let nextPerson = data[0][header['leftTitleField']] + " " + data[0][header['rightTitleField']];
            let lastPerson = data[dataCount - 1][header['leftTitleField']] + " " + data[dataCount - 1][header['rightTitleField']];
            this.titles.next = nextPerson + '- Next Record (Ctrl+Dot)';
            this.titles.last = lastPerson + '- Last Record';
            return dataCount - 1;
        }
        else {
            this.titles.next = "";
            this.titles.last = "";
        }
        return position < dataCount - 1;
    };

    isPreviousAllowed(position: number) {
        let currentContext = this.contextService.context.PERSON;
        let dataCount = (currentContext) ? currentContext.data.length : 0;

        let data = currentContext.data;
        let header = currentContext.Header;

        if (position > 0) {
            let prevPerson = data[position - 1][header['leftTitleField']] + " " + data[position - 1][header['rightTitleField']];
            let firstPerson = data[0][header['leftTitleField']] + " " + data[0][header['rightTitleField']];
            this.titles.previous = prevPerson + "- Previous Record (Ctrl+Comma)";
            this.titles.first = firstPerson + "- First Record";
        } else if (position === 0) {
            let prevPerson = data[dataCount - 1][header['leftTitleField']] + " " + data[dataCount - 1][header['rightTitleField']];
            let firstPerson = data[0][header['leftTitleField']] + " " + data[0][header['rightTitleField']];
            this.titles.previous = prevPerson + "- Previous Record (Ctrl+Comma)";
            this.titles.first = firstPerson + "- First Record";
            return dataCount - 1;
        }
        else {
            this.titles.previous = "";
            this.titles.first = "";
        }
        return position > 0;
    };

    firstRecord(position: number) {
        if (this.isPreviousAllowed(position)) {
            this.contextService.setContextPosition("PERSON", 0);
        }
    };

    previousRecord(position: number) {
        let contextOpenForm = this.contextService.openContextForm;
        $("#context-warning").show();
        if (this.isPreviousAllowed(position)) {
            if (contextOpenForm) {
                this.contextService.checkContextRecord = true;
                this.contextService.checkContextRecordPrevious = true;
                this.contextService.checkContextRecordForward = false;
                this.contextService.positionCloseAll = false;
                this.serverCommandService.sendProcessCommandMessage("RecordBack");
            } else {

                position === 0 ? this.contextService.setContextPosition('PERSON', (this.isPreviousAllowed(position))) : this.contextService.setContextPosition('PERSON', position - 1);

            }
        }
    };

    nextRecord(position: number) {
        let contextOpenForm = this.contextService.openContextForm;
        $("#context-warning").show();
        if (this.isNextAllowed(position)) {
            if (contextOpenForm) {
                this.contextService.checkContextRecord = true;
                this.contextService.checkContextRecordForward = true;
                this.contextService.checkContextRecordPrevious = false;
                this.contextService.positionCloseAll = false;
                this.serverCommandService.sendProcessCommandMessage("RecordForward");
            } else {
                position === (this.isNextAllowed(position)) ? this.contextService.setContextPosition("PERSON", 0) : this.contextService.setContextPosition('PERSON', position + 1);
            }
        }
    };

    lastRecord(dataCount: number) {
        if (dataCount > 1) {
            this.contextService.setContextPosition("PERSON", dataCount - 1);
        }
    };

    preferencesLoadedListener(contextHeaderService: any) {
        let self: ContextHeaderService = <ContextHeaderService>contextHeaderService;
        self.contextOpenflag(false);
    }

    contextOpenflag(setContext: boolean) {
        if (setContext) {
            if (this.uiLocalStorageService.get(Models.LocalStorageConstants.contextClose)) {
                this.uiLocalStorageService.remove(Models.LocalStorageConstants.contextClose);
            } else {
                this.uiLocalStorageService.set(Models.LocalStorageConstants.contextClose, true);
            }
        }
        this.rootScopeService.autoCloseContext = this.uiLocalStorageService.get(Models.LocalStorageConstants.contextClose) == "true" ? false : true;
        return this.uiLocalStorageService.get(Models.LocalStorageConstants.contextClose) == "true" ? false : true;
    }

    closeRecord(position: number) {
        let contextOpenForm = this.contextService.openContextForm;
        if (position !== -1) {
            this.contextService.checkCancel = true;
            this.contextService.contextPositionToRemove = position;
            this.contextService.positionCloseAll = false;

        } else {
            this.contextService.checkCancel = true;
            this.contextService.setPositionCloseAll(true);
            this.searchService.focusOnSearchInput();
        }

        if (this.contextService.getContext().data.length == 1 && position == 0) this.searchService.focusOnSearchInput();

        this.contextService.closeRecord("PERSON", position, contextOpenForm);
    }

    help(length: number) {
        if (length > 0) {
            this.contextService.help();
        }
    }

}