/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit } from '@angular/core';
import * as Models from '../../models';
import { AboutService, AttachService, ChangePasswordService, ConfigurationService, ContextService, EventsService, FavoritesService, FormService, HelpService, PrivacyPolicyService, PromptService, RootScopeService, SearchService, ValidationService } from '../../services';

declare var $: any;
declare var scaleContent: any;
declare var callFormService: any;
declare var setLocalStorageSliderSetting: any;
declare var setNewValue: any;
declare var quickTourUrl: any;
declare var ssoEnabled: any;
declare var print: any;

@Component({
    selector: 'sidebar',
    template: require('./sidebar.component.html')
})
export class SidebarComponent implements OnInit {

    allowPasswordChange: boolean = false;

    constructor(private changePasswordService: ChangePasswordService, private promptService: PromptService,
        private favoritesService: FavoritesService, private rootScopeService: RootScopeService,
        private searchService: SearchService, private formService: FormService, private contextService: ContextService,
        private configurationService: ConfigurationService, private helpService: HelpService, private aboutService: AboutService,
        private validationService: ValidationService, private eventsService: EventsService, private privacyPolicyService: PrivacyPolicyService,
        private attachService: AttachService) {
        this.eventsService.on(Models.CommandConstants.printForm, [this.printFormListener, this]);
        this.eventsService.on(Models.CommandConstants.openExternalLink, [this.openExternalLinkListener, this]);
    }

    ngOnInit() {
        $('#slider').slider({
            formater: function (value: any) {
                var scale = this.max + this.min - value;
                return scale + '%';
            }
        });

        // Move slider handle or click slider handle
        $('#slider').on("slide", function (slideEvt: any) {
            var newValue = 130 + 70 - slideEvt.value;
            scaleContent((Math.round(newValue) / 100).toString());
            callFormService(newValue, "Form");

        });

        // Move slider handle or click slider handle
        $('#slider').on("slideStop", function (slideEvt: any) {
            var newValue = 130 + 70 - slideEvt.value;
            scaleContent((Math.round(newValue) / 100).toString());
            setLocalStorageSliderSetting(newValue, "Form");
        });

        // Reduce 5 from Current slider value(because this slider functioning opposite direction) when click on + icon
        //if you move towards + decrease the value  if you move towards the - increasing the value
        $('#sliderplus').on("click", () => {
            setNewValue(-5);
        });

        $('#sliderplus').on("keyup", ($event: any) => {
            if (($event.keyCode == 13 || $event.keyCode == 32))
                setNewValue(-5);
        })
        // Add 5 from Current slider value when click on -icon
        $('#sliderminus').on("click", () => {
            setNewValue(5);
        });

        $('#sliderminus').on("keyup", ($event: any) => {
            if (($event.keyCode == 13 || $event.keyCode == 32))
                setNewValue(5);
        });
    }


    printFormListener(comp: any) {
        let self = <SidebarComponent>comp;
        self.printForm();
    }

    openExternalLinkListener(comp: any) {
        let self = <SidebarComponent>comp;
        self.openExternalLink();
    }

    showFavorites(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.favoritesService.showFavorites();
        }
    }

    togglePersonSidebar(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.leftPersonSidebar);
        }
    }

    toggleHelpSidebar(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.leftHelpSidebar);
        }
    }

    closeEverything(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
        }
    }

    printForm(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            if (this.formService.forms.length > 0) {
                this.rootScopeService.printedTime = new Date();
                this.rootScopeService.contextPrint = this.rootScopeService.isContextFormOpen;
                setTimeout(() => {
                    print();
                }, 500);
            }
        }
    }

    openExternalLink(event: any = null) {
        $('#externalForm').empty();
        let submit: boolean = false;
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            if (this.formService.forms.length > 0 && this.formService.activeForm.externalLinks != null && this.formService.activeForm.externalLinks.length > 0) {
                let fields = this.formService.activeForm.fields;

                let currentContext: any = this.contextService.getContext();
                let position: number = currentContext.position;
                let errors: any = [];

                for (let i = 0; i < this.formService.activeForm.externalLinks[0].linkDataSets.length; i++) {
                    let linkDataSet = this.formService.activeForm.externalLinks[0].linkDataSets[i];
                    if (linkDataSet.linkDataLocation.toUpperCase() == "BODY") {
                        let name = linkDataSet.linkDataName;
                        let dataSource = linkDataSet.linkDataSource.trim();

                        if (dataSource.indexOf("\"") == 0) { // static values or Context values.

                            dataSource = dataSource.substring(1, dataSource.length - 1);

                            if (dataSource.indexOf("[") == 0 && dataSource.indexOf("]") == dataSource.length - 1) { // context


                                if (position >= 0 && position < currentContext.data.length) { // valid position
                                    dataSource = dataSource.substring(1, dataSource.length - 1); // remove angle brackets and we have context fields.
                                    let data: any = currentContext.data[position][dataSource];

                                    if (this.validationService.isNullOrEmpty(data)) {
                                        errors.push("Context field " + dataSource + " was not found in the " + currentContext.Header.context + " context record.");
                                    }
                                    else {
                                        dataSource = data;
                                        $('#externalForm').append('<input type="hidden" name="' + name + '" value="' + encodeURIComponent(dataSource) + '" />');
                                        submit = true;
                                    }
                                }
                                else {
                                    console.error("Invalid context position");
                                }
                            }
                            else { // static value

                                $('#externalForm').append('<input type="hidden" name="' + name + '" value="' + encodeURIComponent(dataSource) + '" />');
                                submit = true;
                            }
                        }
                        else { // actual fields on form.
                            let field = fields[linkDataSet.linkDataSource];
                            if (field == null) {
                                field = fields[linkDataSet.linkDataSource + "_1"];
                            }

                            if (field != null) {
                                $('#externalForm').append('<input type="hidden" name="' + name + '" value="' + encodeURIComponent(field.text) + '" />');
                                submit = true;
                            }
                        }
                    }
                }

                if (submit) {
                    if (errors.length > 0) {
                        var broadCastMessage: any = {};
                        broadCastMessage.title = "Warning";
                        broadCastMessage.text = errors;
                        broadCastMessage.buttons = [{
                            label: "Dismiss",
                            callback: () => {
                                this.promptService.popupMessageDismiss(() => {
                                    document.forms['externalForm'].submit();
                                }, false);
                            },
                            SearchFocus: false
                        }];
                        broadCastMessage.defaultCallbackNumber = "1";
                        this.promptService.popupMessageShow(broadCastMessage);
                    }
                    else {
                        document.forms['externalForm'].submit();
                    }
                }
            }
        }
    }

    generalHelp(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.Application);
            this.closeEverything();
        }
    }

    quickTour(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            if (!this.validationService.isNullOrEmpty(quickTourUrl)) {
                var quickTourWindow = window.open(quickTourUrl);
                quickTourWindow.focus();
                this.searchService.toggleSearchOptions(Models.ToggleSearchCategory.closeleftSidebar);
            }
        }
    }

    openAbout(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.aboutService.display();
        }
    }

    openPrivacyPolicy(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.privacyPolicyService.display();
        }
    }

    listKeyboardShortcutsPC(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.aboutService.listKeyboardShortcutsPC();
        }
    }

    fieldHelp(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            let formApplication: string = this.formService.activeForm.original.form.formApplication;
            let formId: string = this.formService.activeForm.original.form.formID;
            let helpId: string = formApplication + "-" + formId;
            let fieldId: string = this.rootScopeService.currentFocusedField.replace(/-/g, '.');
            if (fieldId.indexOf("_") > 0) {
                fieldId = fieldId.split("_")[0];
            }
            this.helpService.display(helpId, Models.HelperText.form, fieldId);
            document.getElementById(this.rootScopeService.currentFocusedField).focus();
        }
    }

    processHelp(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            let formApplication: string = this.formService.activeForm.original.form.formApplication;
            let formId: string = this.formService.activeForm.original.form.formID;
            let helpId: string = formApplication + "-" + formId;
            this.helpService.display(helpId, Models.HelperText.form);
            document.getElementById(this.rootScopeService.currentFocusedField).focus();
        }
    }

    openNavigationLink(link: any, event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.closeEverything();
            let quickTourWindow = window.open(link.LinkURL);
            quickTourWindow.focus();
        }
    }

    showChangePassword(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.changePasswordService.openChangePasswordDialog();
            this.eventsService.broadcast("PasswordChangeDialogOpened");
        }
    }

    logout(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.eventsService.broadcast("TriggerLogout");
        }
    }

    /**
     * Open Colleague Attachments dialog
     * @param event 
     */
    openAttachments(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.attachService.displayAttachDialog();
            // setTimeout(() => {
            //     this.attachService.dragElement(document.getElementById("attach"));
            // }, 250);
        }
    }
}