/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { EventsService, FocusService, FormService, PreferencesService, ProcessingService, RootScopeService, ServerCommandService, ValidationService, WindowControlService, HelperService, PromptService, ReportBrowserService } from "../../../services";
import * as Models from "../../../models";

declare var $: any;
declare var document: any;

import * as _ from 'lodash';

@Component({
    selector: 'text',
    template: require('./text.component.html'),
})
export class TextComponent implements OnInit, OnDestroy {

    @Input() element: any;

    isEcho: boolean = false;
    isWindowElement: boolean = false;
    isValcode: boolean = false;
    isLookup: boolean = false;
    windowParts: string[] = null;
    fieldName: string = "";
    fieldUpdateSent: boolean = false;
    tabIndex: number = -1;
    windowRow: number = null;
    alignTop: boolean = false;
    valCodeTop: number = 0;
    fieldPromptMessageListenerId: any;
    closeValcodeEventId: any;
    prefixScreenReaderText: string = "";
    forceFieldProc: boolean = false;
    exitFieldProc: boolean = false;

    constructor(private rootScope: RootScopeService, private ng4WindowControlService: WindowControlService,
        private serverCommandService: ServerCommandService,
        private validationService: ValidationService, private focusService: FocusService,
        private events: EventsService, private formService: FormService, private processingService: ProcessingService, private helperService: HelperService, private promptService: PromptService, private reportBrowserService: ReportBrowserService) {
        this.fieldPromptMessageListenerId = this.events.on(Models.EventConstants.fieldPromptMessage, [this.fieldPromptMessageListener, this]);
        this.closeValcodeEventId = this.events.on(Models.EventConstants.closeValcode, [this.closeValcodeListener, this]);
    }

    closeValcodeListener(textComponent: any) {
        let self: TextComponent = null;
        self = <TextComponent>textComponent;
        self.closeValcode();
    }

    closeValcode() {
        let dropDown: any = document.getElementById(this.element.sanitizedID);

        if (dropDown) {
            let dropdownOpen: boolean = document.getElementById(this.element.sanitizedID).classList.contains("open");

            if (dropdownOpen) {
                this.toggleDropDown(false);
                setTimeout(() => {
                    document.getElementById(this.element.sanitizedID).focus();
                }, 100);
            }
        }
    }

    /**
     * Event Listener for Field Prompt Message
     * @param textComponent
     * @param msg
     */
    fieldPromptMessageListener(textComponent: any, msg: any) {
        let self: TextComponent = null;
        self = <TextComponent>textComponent;

        self.fieldUpdateSent = false;
    };


    ngOnInit() {

        this.rootScope.performanceCounter += 0.5;
        this.isEcho = this.element.elementEcho == "noEcho";

        this.forceFieldProc = this.element.elementForceFieldProc == "Y";
        this.exitFieldProc = this.element.elementExitFieldProc == "Y";

        // initialize if the element is part of the window.
        this.isWindowElement = !this.validationService.isNullOrEmpty(this.element.elementWindowController);

        // initilize if the element is valcode.
        this.isValcode = this.element.elementType == Models.ElementType.valCode;

        //initilize if the element is lookup.
        this.isLookup = this.element.elementLookUpFlag == 'Y';

        // If window element, get window row and field name from the elementID.
        if (this.isWindowElement) {
            this.prefixScreenReaderText = " Window " + this.element.elementWindowController + " row " + this.element.rowIndex + " ";
            this.windowParts = this.element.elementID.split("_");
            this.windowRow = this.formService.getCurrentWindowRow(this.windowParts[1], this.element.elementWindowController);
            this.fieldName = this.windowParts[0];
        }
        else {
            this.fieldName = this.element.elementID;
        }

        // set the tab index.
        this.tabIndex = (this.element.sequenceOrder != null && this.element.sequenceOrder > 0) ? 150 + this.element.sequenceOrder : -1;

        // if the tab index is still -1 check if element is detailable or is not inquiry element.
        if (this.tabIndex == -1 && (this.element.elementDetailable || !(this.element.elementType == Models.ElementType.inquiry || this.element.runTimeInquiryField === true || this.element.runTimeRTInquiryField == true))) {
            this.tabIndex = 0;
        }

        // calculate top to position the valcode.
        setTimeout(() => {
            // Verify if the element is below row 12 and is a valcode and has options
            if (this.element.elementRow > 12 && this.element.elementType == "valCode" && this.element.options != null && this.element.options.length > 0) {
                this.alignTop = true;
                // Each element's li is 22px high. Max height of ul for valcode dropdown is 200px. Multiply 22 by the number of element options. If it is greater than 200, set the top position to -200px
                this.valCodeTop = this.element.options.length * 22 > 200 ? -200 : -22 * this.element.options.length;
            }
        }, 1000);


    }


    /**
     * Launch Calculator button click.
     */
    launchCalculator() {
        this.click();
        this.rootScope.focusedFieldName = this.element.sanitizedID;
        this.rootScope.clickedFieldName = this.element.sanitizedID;
        this.events.broadcast(Models.EventConstants.launchCalculator, this.element.sanitizedID);
    }

    /**
     * Form Detail
     */
    formDetail() {
        // disable form to prevent double click CR-000153554
        this.disableForm();

        let updateSent: boolean = this.sendFieldUpdate(false, false, false, false, false, false, true);

        if (updateSent == false) {
            let jumpCommand: any = Models.CommandConstants.fieldJump;
            // if ignorefield jump is set ignore field jump command as app server is already aware of and won't like if required field validation is prompted.
            if (this.rootScope.lastPromptedField == this.element.elementID || this.rootScope.lastFieldPromptMessageId == this.element.elementID) {
                jumpCommand = Models.CommandConstants.ignoreFieldJump;
            }
            this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldDetail], ["", ""], ["", ""],
                [this.fieldName, ""], ["", ""], [this.getAbsoluteWindowRow(), ""]);
        }
    }

    windowRowButtonClick() {
        let currentWindowElement = (this.element.elementID).split("_");
        if (this.rootScope.focusedFieldName != this.element.sanitizedID) {
            let jumpCommand: any = Models.CommandConstants.fieldJump;
            // if ignorefield jump is set ignore field jump command as app server is already aware of and won't like if required field validation is prompted.
            if (this.rootScope.lastPromptedField == this.element.elementID || this.rootScope.lastFieldPromptMessageId == this.element.elementID) {
                jumpCommand = Models.CommandConstants.ignoreFieldJump;
            }
            this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.windowRowButton], ["", ""], ["", ""], [currentWindowElement[0], ""], ["", ""], [this.element.rowIndex, ""]);
        }
        else {
            this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.windowRowButton, "", "", "", "", "");
        }
    }

    /**
     * Blur Event
     */
    blur(event: any = null) {
        if (this.fieldUpdateSent !== true) {
            if (event != null && event.relatedTarget != null && event.relatedTarget.id != null && (event.relatedTarget.id == "btnFormCancel" || event.relatedTarget.id == "btnFormCancelAll")) {
                if (event.relatedTarget.id == "btnFormCancel") {
                    this.element.text = this.element.originalText;
                    if (this.element.elementDate == true) {
                        this.formService.populateDate(this.element, this.element.text);
                    }
                }
            }
            else {
                let dataDirty: boolean = this.isElementDirty();

                if (event != null && event.relatedTarget != null && event.relatedTarget.id != null && (event.relatedTarget.id == "btnFormSave" || event.relatedTarget.id == "btnFormSaveAll")) {
                    if (dataDirty) {
                        if (event.relatedTarget.id == "btnFormSave") {
                            this.formService.bufferedAction = Models.CommandConstants.processUpdate;
                        }
                        else if (event.relatedTarget.id == "btnFormSaveAll") {
                            this.formService.bufferedAction = Models.CommandConstants.saveAllForms;
                        }
                    }
                }
                if (dataDirty) {
                    // since data is already dirty, set force to true
                    this.sendFieldUpdate(true, false, false, false, false, false, false, true);
                    this.fieldUpdateSent = false;
                }
            }
        }
        else {
            this.fieldUpdateSent = false;
        }

        // only update text if unfocused is not empty and text is not empty. In other cases value will be updated by field display message.
        if (this.validationService.isNullOrEmpty(this.element.unfocusedText) == false && this.validationService.isNullOrEmpty(this.element.text) == false)
            this.element.text = this.element.unfocusedText;
        if (this.element.elementDate == true) {
            this.formService.populateDate(this.element, this.element.text);
        }

    }

    /**
     * Focus Event
     * @param event
     */
    focus(event: any) {
        this.rootScope.activeWindow = this.element.elementWindowController;


        this.rootScope.currentFocusedField = this.element.sanitizedID;

        // do not do any thing if the element is read only and not detailable.
        if (!this.element.elementDetailable && (this.element.elementType == Models.ElementType.inquiry || this.element.runTimeInquiryField === true || (this.element.runTimeRTInquiryField == true && this.element.permRunTimeRTInquiryField != true))) {
            return;
        }

        // send field jump if previous button prompt was error and current element is not required. CUI-5091
        // if previous button prompt was not error and force field proc is yes.
        // if previous button prompt was not error and current element is window element.
        // focus on runtime rt inquiry field so that we can get new security from server if available
        if ((this.element.permRunTimeRTInquiryField === true) || (this.formService.isError == true && this.element.required == false && this.element.isValcode && !this.isWindowElement) ||
            (this.formService.isError == false && (this.forceFieldProc || (this.isWindowElement && this.rootScope.validActiveWindow != this.element.elementWindowController)))) {
            // only send field jump if the last prompt field is not the current field under focus or if the field being focused in an RT_INQUIRY field & is not detailable & is not a part of window.
            // In the if condition below, the last "&& !this.isWindowElement" is added as part of CUI-5346 (CR-000160325)
            if (this.rootScope.lastPromptedField != this.element.elementID || (this.element.permRunTimeRTInquiryField === true && this.element.elementDetailable == false && !this.isWindowElement)) {
                this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", this.fieldName, "", this.getAbsoluteWindowRow());
            }
        }

        // restore data, because this is the first focus after error
        if (this.formService.isError == true && this.validationService.isNullOrEmpty(this.element.focusedText)) {
            this.element.focusedText = this.element.tempFocusedText;
            this.element.unfocusedText = this.element.tempUnfocusedText;
            this.element.text = this.element.focusedText;

        }
        this.formService.isError = false;

        this.rootScope.validActiveWindow = this.element.elementWindowController;

        // only update text if focused is not empty and text is not empty. In other cases value will be updated by field display message.
        if (this.validationService.isNullOrEmpty(this.element.focusedText) == false && this.validationService.isNullOrEmpty(this.element.text) == false) {
            this.element.originalText = this.element.focusedText;
            this.element.text = this.element.focusedText;
        }
        if (this.element.elementDate == true) {
            this.formService.populateDate(this.element, this.element.text);
        }

        if (this.formService.formDisabled == false) {
            if (this.isEcho) {
                this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", this.fieldName, "", this.getAbsoluteWindowRow());
            }
            //select/highlight text if active element is same as current element.
            setTimeout(() => {
                if (document.activeElement.id == this.element.sanitizedID && !this.element.elementDate) {
                    let inputElement = document.getElementById(this.element.sanitizedID) as HTMLInputElement;
                    inputElement.select();
                }
            }, 10);
        }

    }

    // Window navigation for arrow up and arrow down.
    sendWindowNavigation(command: any) {
        let jumpCommand: any = Models.CommandConstants.fieldJump;
        // if ignorefield jump is set ignore field jump command as app server is already aware of and won't like if required field validation is prompted.
        if (this.rootScope.lastPromptedField == this.element.elementID || this.rootScope.lastFieldPromptMessageId == this.element.elementID) {
            jumpCommand = Models.CommandConstants.ignoreFieldJump;
        }
        if (this.element.originalElementID == this.element.elementWindowController ||
            (this.element.elementDetailable || (true != this.element.runTimeInquiryField && true != this.element.runTimeRTInquiryField && true != this.element.inquiry)) && true != this.element.runTimeNoAccess) {
            this.disableForm();
            this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, command], ["", this.fieldName], ["", ""],
                [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
        }
        else {
            this.disableForm();
            this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, command], ["", this.element.elementWindowController], ["", ""],
                [this.element.elementWindowController, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
        }
    }

    /**
     *
     * @param Key down Event
     */
    keyDown($event: any) {
        let tab: boolean = $event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = $event.shiftKey;
        let ctrl: boolean = $event.ctrlKey;
        let alt: boolean = $event.altKey;
        let action: string = null;
        let keyCode: number = $event.keyCode;
        let enter: boolean = keyCode == Models.KeyCodes.enter;
        let f2: boolean = keyCode == Models.KeyCodes.f2;
        let pageUp: boolean = keyCode == Models.KeyCodes.pageUp;
        let pageDown: boolean = keyCode == Models.KeyCodes.pageDown;
        let leftSquareBracket: boolean = keyCode == Models.KeyCodes.leftSquareBracket;
        let rightSquareBracket: boolean = keyCode == Models.KeyCodes.rightSquareBracket;
        let updateSent: boolean = false;

        // CUI-5449 -- If the current element is inside a window and it is an inquiry field, do not send a FieldJump along with FieldForward. This is to prevent app server from getting stuck in an infinite loop.
        let isElementInWindowAndInquiry: boolean = this.element.elementWindowController != null && this.element.elementWindowController != this.element.originalElementID &&
            (this.element.elementType == Models.ElementType.inquiry || this.element.runTimeInquiryField === true) && !this.element.elementDetailable;

        // prevent default if tab or shift tab or enter and value is modified.
        // need to wait for field prompt message
        if (tab || enter) {
            // If element is a window element and an inquiry field, do not send Field Update on tab or enter.
            if (!isElementInWindowAndInquiry) {
                if (shift)
                    updateSent = this.sendFieldUpdate(false, false, true);
                else if (enter)
                    updateSent = this.sendFieldUpdate(false, false, false, true);
                else
                    updateSent = this.sendFieldUpdate(false, true, false);
                $event.preventDefault();
                $event.stopPropagation();
                //return because data is dirty and field navigation is already sent with field update.
                if (updateSent) {
                    return;
                }
            }
        }

        // if stand alone down key is pressed and element is part of window
        if (!ctrl && this.isWindowElement && keyCode == Models.KeyCodes.down) {
            action = Models.CommandConstants.arrowDown;
        }
        // when key stroke combination is (ctrl + down arrow) or non-window + downarrow.
        else if ((ctrl || !this.isWindowElement) && keyCode == Models.KeyCodes.down) {
            if (this.element.elementDate == true) { // if element is date
                let updateSent: boolean = this.sendFieldUpdate(); // try to send field update if data is dirty.

                //if update is sent don't do anything else open calendar
                if (updateSent == false) action = Models.CommandConstants.openCalendar;
            }
            else if (this.element.elementCalc == true) { // if element is calculator
                action = Models.CommandConstants.openCalculator;
            }
            else if (this.isValcode) { // if element is valcode
                action = Models.CommandConstants.openValcode;
            }
        }

        if (action == null) {
            if (this.isWindowElement && keyCode == Models.KeyCodes.up) { // if element is part of windows
                action = Models.CommandConstants.arrowUp;
            }
            else if (tab) { // tab
                if (shift) { // shift + tab
                    action = Models.CommandConstants.fieldBack;
                }
                else { // tab
                    action = Models.CommandConstants.fieldForward;
                }
            }
            else if (enter || (ctrl && alt && rightSquareBracket)) { // enter
                action = Models.CommandConstants.elementForward;
            }
            else if (ctrl && alt && keyCode == Models.KeyCodes.d && !this.element.runtTimeAddonly) { // Field Delete
                action = Models.CommandConstants.fieldDelete;
            }
            else if (f2) { // Field Detail
                action = Models.CommandConstants.fieldDetail;
            }
            else if (this.isWindowElement && ctrl && keyCode == Models.KeyCodes.home) {  // window first page
                action = Models.CommandConstants.firstPage;
            }
            else if (this.isWindowElement && ctrl && keyCode == Models.KeyCodes.end) { // window last page
                action = Models.CommandConstants.lastPage;
            }
            else if (this.isWindowElement && pageUp) { // window previous page
                action = Models.CommandConstants.prevPage;
            }
            else if (this.isWindowElement && pageDown) { // window next page
                action = Models.CommandConstants.nextPage;
            } else if (this.isWindowElement && !ctrl && !shift && !alt && keyCode == Models.KeyCodes.insert) {  // keyboard short cut for window row insert
                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.windowInsert, '', '', '', '', this.ng4WindowControlService.getWindowPosition(parseInt(this.windowParts[1]), this.element.elementWindowController));
            }
            else if (this.isWindowElement && ctrl && alt && keyCode == Models.KeyCodes.e) {  // keyboard short cut for export to excel
                this.ng4WindowControlService.excelExport();
            }
            else if (this.isWindowElement && ctrl && alt && (keyCode == Models.KeyCodes.dash || keyCode == Models.KeyCodes.firefoxDash)) { // read window row for accessbility
                this.ng4WindowControlService.readWindowRow(this.element);
            }
            else if (ctrl && alt && leftSquareBracket) {
                action = Models.CommandConstants.fieldBack;
            }
        }

        let jumpCommand: any = Models.CommandConstants.fieldJump;
        // if ignorefield jump is set ignore field jump command as app server is already aware of and won't like if required field validation is prompted.
        if (this.rootScope.lastPromptedField == this.element.elementID || this.rootScope.lastFieldPromptMessageId == this.element.elementID) {
            jumpCommand = Models.CommandConstants.ignoreFieldJump;
        }

        switch (action) {
            case Models.CommandConstants.arrowDown:

                if (this.isValcode) {
                    this.toggleDropDown(false); // close dropdown if open
                }
                let updateSent: boolean = this.sendFieldUpdate(false, false, false, false, true, false);
                if (!updateSent) {
                    if (this.isWindowElement) {

                        let emptyWindow: boolean = true;
                        let emptyNextRow: boolean = true;
                        let tempFields = this.formService.getLatestForm().fields;
                        var self = this;
                        _.each(tempFields, function (fe) {
                            if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex) {
                                if (self.validationService.isNullOrEmpty(fe.text) == false) {
                                    emptyWindow = false;
                                }
                            }
                            else if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex + 1) {
                                if (self.validationService.isNullOrEmpty(fe.text) == false) {
                                    emptyNextRow = false;
                                }
                            }
                        });

                        if (emptyWindow || emptyNextRow) { // if empty window then do field navigation
                            this.rootScope.focusedFieldName = "";
                            this.sendWindowNavigation(Models.CommandConstants.arrowDown);
                            return;
                        }
                        else {
                            let tempField: any = this.formService.getLatestForm().fields[this.element.originalElementID + "_" + (this.windowRow + 1)];
                            if (tempField != null && this.exitFieldProc == false) { // check if the next row exists
                                this.rootScope.focusedFieldName = tempField.sanitizedID;
                                if (this.rootScope.zone3Message.indexOf("Value ") >= 0) {
                                    let currentString = this.rootScope.zone3Message.substring(6, this.rootScope.zone3Message.indexOf("/"));
                                    this.rootScope.zone3Message = this.rootScope.zone3Message.replace(currentString + "/", (this.element.rowIndex + 1) + "/");
                                }

                                this.focusService.focusOn(tempField.sanitizedID, true);
                                return;
                            }
                            else {
                                this.rootScope.focusedFieldName = "";
                                this.sendWindowNavigation(Models.CommandConstants.arrowDown);
                                return;
                            }
                        }
                    }
                }
                break;
            case Models.CommandConstants.arrowUp:
                if (this.isValcode) {
                    this.toggleDropDown(false); // close dropdown if open
                }
                let updateSent_: boolean = this.sendFieldUpdate(false, false, false, false, false, true);
                if (!updateSent_) {
                    if (this.isWindowElement) {

                        let emptyWindow: boolean = true;

                        let tempFields = this.formService.getLatestForm().fields;
                        var self = this;
                        _.each(tempFields, function (fe) {
                            if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex) {
                                if (self.validationService.isNullOrEmpty(fe.text) == false) {
                                    emptyWindow = false;
                                }
                            }
                        });

                        if (emptyWindow) { // if empty window then do field navigation
                            this.rootScope.focusedFieldName = "";
                            this.sendWindowNavigation(Models.CommandConstants.arrowUp);
                            return;
                        }
                        else {
                            let tempField: any = this.formService.getLatestForm().fields[this.element.originalElementID + "_" + (this.windowRow - 1)];
                            if (tempField != null && this.exitFieldProc == false) { // check if the next row exists
                                this.rootScope.focusedFieldName = tempField.sanitizedID;
                                this.rootScope.zone3Message = this.rootScope.zone3Message.replace(this.element.rowIndex + "/", (this.element.rowIndex - 1) + "/");
                                this.focusService.focusOn(tempField.sanitizedID, true);
                                return;
                            }
                            else {
                                this.rootScope.focusedFieldName = "";
                                this.sendWindowNavigation(Models.CommandConstants.arrowUp);
                                return;
                            }
                        }

                    }

                }
                break;
            case Models.CommandConstants.fieldForward:
                if (this.exitFieldProc == false) {
                    if (this.isValcode) {
                        this.toggleDropDown(false); // close dropdown if open
                    }
                    let done = this.fieldForward();
                    if (done) return;
                }

                this.disableForm();
                this.rootScope.focusedFieldName = "";

                if (isElementInWindowAndInquiry) {
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldForward, this.fieldName, "", "", this.windowRow, "");
                }
                else {
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldForward], ["", this.fieldName], ["", ""],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                }
                break;
            case Models.CommandConstants.fieldBack:
                if (this.exitFieldProc == false) {
                    if (this.isValcode) {
                        this.toggleDropDown(false); // close dropdown if open
                    }
                    if (this.element.previousField != null) {
                        var requiredField = false;
                        if (this.isWindowElement) {

                            let emptyWindow: boolean = true;

                            let tempFields = this.formService.getLatestForm().fields;
                            var self = this;

                            _.each(tempFields, function (fe) {
                                if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex) {
                                    if (fe.required && self.validationService.isNullOrEmpty(fe.text)) {
                                        requiredField = true;
                                    }
                                }
                            });

                        }
                        // if any empty field in window is required, we will follow normal messaging.
                        // else we will handle it on client side.
                        if (requiredField == false) {
                            let previousField: any = (this.rootScope.disableCustomFieldSequence || this.validationService.isNullOrEmpty(this.element.customPreviousField)) ?
                                this.element.previousField : this.element.customPreviousField;

                            let temp = previousField.replace(/-/g, '.');
                            let tempPreviousElement = this.formService.getLatestForm().fields[temp];

                            // field customized. but previous field is empty.
                            if ('customPreviousField' in this.element) {
                                if (this.validationService.isNullOrEmpty(this.element.customPreviousField)) {
                                    tempPreviousElement = null;
                                }
                            }

                            if (tempPreviousElement != null && (tempPreviousElement.originalElementID == tempPreviousElement.elementWindowController ||
                                (tempPreviousElement.elementDetailable || (true != tempPreviousElement.runTimeInquiryField && true != tempPreviousElement.runTimeRTInquiryField && true != tempPreviousElement.inquiry)) && true != tempPreviousElement.runTimeNoAccess)) {
                                this.rootScope.focusedFieldName = tempPreviousElement.sanitizedID;
                                this.focusService.focusOn(previousField, true);
                                return;
                            }
                        }
                    }
                }
                this.disableForm();
                this.rootScope.focusedFieldName = "";

                if (isElementInWindowAndInquiry) {
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldBack, this.fieldName, "", "", this.windowRow, "");
                }
                else {
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldBack], ["", this.fieldName], ["", ""],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                }
                break;
            case Models.CommandConstants.elementForward:
                if (this.exitFieldProc == false) {
                    if (this.isValcode) {
                        this.toggleDropDown(false); // close dropdown if open
                    }

                    if (this.isWindowElement) {
                        if (!isElementInWindowAndInquiry) {
                            let emptyWindowRow: boolean = true;
                            let requiredField: boolean = false;
                            let readonlyWindowRow: boolean = true;
                            let tempFields = this.formService.getLatestForm().fields;
                            var self = this;
                            _.each(tempFields, function (fe) {
                                if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex) {
                                    if (self.validationService.isNullOrEmpty(fe.text) == false) {
                                        emptyWindowRow = false;
                                    }

                                    if (fe.elementWindowController != fe.originalElementID && true != fe.runTimeInquiryField && true != fe.runTimeRTInquiryField && true != fe.inquiry && true != fe.runTimeNoAccess) {
                                        readonlyWindowRow = false;
                                    }

                                    if (fe.required == true && self.validationService.isNullOrEmpty(fe.text)) requiredField = true;
                                }
                            });

                            if (requiredField == false) {
                                // If current focus is on first field of a window row
                                if (this.element.elementWindowController == this.element.originalElementID) {
                                    //If row is empty, use fieldForward. If no fieldForward, send to server.
                                    if (emptyWindowRow) {
                                        let currentWindow = this.formService.getWindowControl(this.element.elementWindowController);
                                        if (currentWindow.windowCurrentPage == 1) {
                                            let done = this.fieldForward();
                                            if (done) return;
                                        }
                                    }
                                    else if (readonlyWindowRow) {
                                        // If entire window is readonly or its a single value window
                                        let tempField: any = this.formService.getLatestForm().fields[this.element.originalElementID + "_" + (this.windowRow + 1)];
                                        if (tempField != null) { // check if the next row exists
                                            this.rootScope.focusedFieldName = tempField.sanitizedID;
                                            this.focusService.focusOn(tempField.sanitizedID, true);
                                            return;
                                        }
                                    }
                                    else {
                                        let done = this.fieldForward();
                                        if (done) return;
                                    }
                                }
                                else {
                                    // If row is empty and current focus is not on the window controller field (somewhere in middle or last field), use elementForward.
                                    // If no elementForward, use fieldForward.
                                    // If nothing, send to server.
                                    let nextField: any = (this.rootScope.disableCustomFieldSequence || this.validationService.isNullOrEmpty(this.element.customNextField)) ?
                                        this.element.nextField : this.element.customNextField;

                                    // field customized. but next field is empty.
                                    if ('customNextField' in this.element) {
                                        if (this.validationService.isNullOrEmpty(this.element.customNextField)) {
                                            nextField = null;
                                        }
                                    }

                                    // if window row has data send it to element forward.
                                    if (this.validationService.isNullOrEmpty(this.element.elementElementForward) == false && nextField != null) {
                                        let temp = nextField.replace(/-/g, '.');
                                        let tempNextElement = this.formService.getLatestForm().fields[temp];
                                        if (tempNextElement != null && (tempNextElement.originalElementID == tempNextElement.elementWindowController ||
                                            (tempNextElement.elementDetailable || (true != tempNextElement.runTimeInquiryField && true != tempNextElement.runTimeRTInquiryField && true != tempNextElement.inquiry)) && true != tempNextElement.runTimeNoAccess)) {
                                            this.rootScope.focusedFieldName = tempNextElement.sanitizedID;
                                            this.focusService.focusOn(nextField, true);
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else {
                        let done = this.fieldForward();
                        if (done) return;
                    }
                }
                this.disableForm();
                this.rootScope.focusedFieldName = "";
                if (isElementInWindowAndInquiry) {
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.elementForward, this.fieldName, "", "", this.windowRow, "");
                }
                else {
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.elementForward], ["", this.fieldName], ["", ""],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                }
                //this.navigate(Models.CommandConstants.elementForward);
                break;
            case Models.CommandConstants.openCalendar:
                this.events.broadcast(Models.EventConstants.launchCalendar, this.element.sanitizedID);
                break;
            case Models.CommandConstants.openCalculator:
                this.events.broadcast(Models.EventConstants.launchCalculator, this.element.sanitizedID);
                break;
            case Models.CommandConstants.closeCalendar:
                this.events.broadcast(Models.EventConstants.closeCalendar, this.element.sanitizedID);
                break;
            case Models.CommandConstants.openValcode:
                this.openDropDown($event, false);
                break;
            case Models.CommandConstants.fieldDelete:
                // only delete if field is not inquiry or is detailable and field is not already empty
                // Allowing delete for runTimeRTInquiryField CUI-4539
                if ((!(this.element.elementType == Models.ElementType.inquiry || this.element.runTimeInquiryField === true)
                    || this.element.elementDetailable) && !this.validationService.isNullOrEmpty(this.element.text)) {
                    this.element.text = ""; // remove current data;
                    if (this.element.elementDate) { // send clear date
                        this.events.broadcast(Models.EventConstants.clearCalendarDate, this.element.sanitizedID);
                    }
                    this.sendFieldUpdate(true); // send field update
                }
                break;
            case Models.CommandConstants.fieldDetail:
                this.formDetail();
                break;
            case Models.CommandConstants.firstPage:
                this.sendFieldUpdate();  // send field update in case data has changed

                // send window jump only if allowed.
                if (this.ng4WindowControlService.isPreviousAllowed(this.element.elementWindowController)) {
                    this.disableForm();
                    this.ng4WindowControlService.firstPage(this.element.elementWindowController);
                }
                break;
            case Models.CommandConstants.lastPage:
                this.sendFieldUpdate(); // send field update in case data has changed

                // send window jump only if allowed.
                if (this.ng4WindowControlService.isNextAllowed(this.element.elementWindowController)) {
                    this.disableForm();
                    this.ng4WindowControlService.lastPage(this.element.elementWindowController);
                }
                break;
            case Models.CommandConstants.nextPage:
                this.sendFieldUpdate(); // send field update in case data has changed

                // send window jump only if allowed.
                if (this.ng4WindowControlService.isNextAllowed(this.element.elementWindowController)) {
                    this.disableForm();
                    this.ng4WindowControlService.nextPage(this.element.elementWindowController);
                }
                break;
            case Models.CommandConstants.prevPage:
                this.sendFieldUpdate(); // send field update in case data has changed

                // send window jump only if allowed.
                if (this.ng4WindowControlService.isPreviousAllowed(this.element.elementWindowController)) {
                    this.disableForm();
                    this.ng4WindowControlService.previousPage(this.element.elementWindowController);
                }
                break;
            default:
                break;
        }
    }

    /**
     * Click Event
     */
    click(event: any = null, sendJump: boolean = true) {
        // do not send field update if field is readonly or tabbing is not enabled for it and element is not detailable.
        // if the element clicked is window controller and is readonly allow click.

        this.rootScope.clickedFieldName = this.element.sanitizedID;

        // CUI-5243 -- Instead of clearing all the values from ignore field prompts, only remove the IDs of the field that is being clicked right now.
        this.rootScope.ignoreFieldPromptId = _.without(this.rootScope.ignoreFieldPromptId, this.element.sanitizedID);

        // Do not ignore RT_INQUIRY field to be able to send field jump upon clicking. CR-000151412.
        if (((this.isWindowElement == false) || (this.element.elementWindowController != this.element.originalElementID)) && (this.element.elementType == Models.ElementType.inquiry || this.element.runTimeInquiryField === true)
            && !this.element.elementDetailable) {
            if (this.isWindowElement) {
                let field = this.ng4WindowControlService.windowJump();
                if (field == null) {
                    if (this.rootScope.zone3Message.indexOf("Value ") >= 0) {
                        let currentString = this.rootScope.zone3Message.substring(6, this.rootScope.zone3Message.indexOf("/"));
                        this.rootScope.zone3Message = this.rootScope.zone3Message.replace(currentString + "/", this.element.rowIndex + "/");
                    }
                }
            }
            return;
        }

        // Send field jump if element is valcode.
        // send field jump if force field proc is false
        // send field jump if focused field is different than current field.
        if (this.isValcode || (this.rootScope.focusedFieldName != this.element.sanitizedID && !this.forceFieldProc)) {
            if (event != null) event.preventDefault();

            if (this.rootScope.lastPromptedField != this.element.elementID && sendJump && (!this.isValcode || !this.isWindowElement)) { // only send if last field prompt was for not the same field.
                // send field jump command to server.
                this.rootScope.lastPromptedField = this.element.elementID;
                setTimeout(() => {
                    this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
                    this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", this.fieldName, "", this.getAbsoluteWindowRow());
                    if (this.element.elementDate && !this.isTextSelected(this.element.sanitizedID)) {
                        let inputElement = document.getElementById(this.element.sanitizedID) as HTMLInputElement;
                        inputElement.select();
                    }
                }, 100);
            }
            else {
                if (this.element.elementDate && !this.isTextSelected(this.element.sanitizedID)) {
                    let inputElement = document.getElementById(this.element.sanitizedID) as HTMLInputElement;
                    inputElement.select();
                }
            }
        }
        else {
            if (this.element.elementDate && !this.isTextSelected(this.element.sanitizedID)) {
                let inputElement = document.getElementById(this.element.sanitizedID) as HTMLInputElement;
                inputElement.select();
            }
        }
    }

    orderIconClick() {
        this.events.broadcast(Models.EventConstants.changeCurrentFieldListener, this.element.customElementOrder);
        this.events.broadcast(Models.EventConstants.elementOrderSelectedListener, this.element.customElementOrder);

        this.events.broadcast(Models.EventConstants.changeCurrentFieldListener, this.element.customElementOrder);
        this.events.broadcast(Models.EventConstants.elementOrderSelectedListener, this.element.customElementOrder);
    }

    /**
     * Open dropdown options.
     * @param $event
     * @param arrow true if arrow is clicked using mouse.
     */
    openDropDown($event: any, arrow: any) {
        let tab: boolean = $event.keyCode == Models.KeyCodes.tab;
        let ctrl: boolean = $event.ctrlKey;

        var open = false;
        if (arrow == true) {
            this.rootScope.valCodeElementId = this.element.elementID;
            this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
            this.click(null, false);
        }

        let dropdownOpen: boolean = document.getElementById(this.element.sanitizedID).classList.contains("open");
        if (arrow == true || ((ctrl || !this.isWindowElement) && ($event.keyCode == Models.KeyCodes.down))) {
            open = true;
            // Open the dropdown options if not already open.
            if (!dropdownOpen) {
                this.toggleDropDown(true);
            }
            let optionIndex = 0;
            for (let i = 0; i < this.element.options.length; i++) {
                let option = this.element.options[i];
                if (this.element.text == option.Code || this.element.text == option.Description || this.element.focusedText == option.Code || this.element.focusedText == option.Description) {
                    optionIndex = i;
                }
            }
            setTimeout(() => {
                document.getElementById(this.element.sanitizedID + "_ul_li_" + optionIndex).focus();
            }, 250);
        } else {
            // If the user tabs on the val code input, close dropdown
            if (!ctrl && (tab || $event.keyCode == Models.KeyCodes.enter) && dropdownOpen) {
                setTimeout(() => {
                    let elementBtn = document.getElementsByClassName(this.element.sanitizedID + "_btn")[0] as HTMLElement;
                    elementBtn.click();
                }, 100);
            }
            this.keyDown($event);
            $event.stopPropagation();
            $event.preventDefault();
            return false;
        }
    }

    /**
     * triggered when an option is selected from the dropdown.
     * @param item option.
     */
    valCodeChanged(item: any) {
        if (item.Code !== this.element.focusedText) {
            this.element.text = item.Code;
            this.sendFieldUpdate(false, false, false, true);
        } else if (this.element.unfocusedText) {
            this.element.text = this.element.unfocusedText;
        }
        //this.element.text = item.code;

        this.rootScope.focusedFieldName = "";
        this.rootScope.clickedFieldName = "";
        this.rootScope.valCodeElementId = "";

    };

    /**
     * Triggered on a on dropdown items
     * @param event
     * @param option
     */
    dropdownKeydown(event: any, itemIndex: any) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        let down: boolean = (!shift && tab) || (event.keyCode == Models.KeyCodes.down);
        let up: boolean = shift && tab || (event.keyCode == Models.KeyCodes.up);
        if (down) {
            // Check if the current item is the last one in dropdown
            if (itemIndex == this.element.options.length - 1) {
                let downBtn = document.getElementsByClassName(this.element.sanitizedID + "_btn")[0] as HTMLElement;
                downBtn.focus();
                downBtn.click();

            }
            else {
                let parts = event.target.id.split("_ul_li_");
                if (parts != null && parts.length == 2) {
                    document.getElementById(parts[0] + "_ul_li_" + (itemIndex + 1)).focus();
                }
                event.stopPropagation();
                event.preventDefault();
            }

        }
        else if (up) {
            // Check if the current item is the first one in the dropdown
            if (itemIndex == 0) {
                let upBtn = document.getElementsByClassName(this.element.sanitizedID + "_btn")[0] as HTMLElement;
                upBtn.focus();
                upBtn.click();
            }
            else {
                let parts = event.target.id.split("_ul_li_");
                if (parts != null && parts.length == 2) {
                    document.getElementById(parts[0] + "_ul_li_" + (itemIndex - 1)).focus();
                }
                event.stopPropagation();
                event.preventDefault();
            }
        }
        else {
            return;
        }
    }

    /**
     * Get Absolute Window Row Index based on window page number
     */
    public getAbsoluteWindowRow() {
        if (this.isWindowElement) {
            return this.ng4WindowControlService.getWindowPosition(parseInt(this.windowParts[1]), this.element.elementWindowController);
        }
        else {
            return null;
        }
    }

    ngOnDestroy() {
        this.events.destroy(Models.EventConstants.fieldPromptMessage, this.fieldPromptMessageListenerId);
    }

    private disableForm() {
        $(document).on("keydown", (event: any) => {
            // If report browser is open and users go to Export to PDF dialog, the keyboard events are disabled because the form is disabled. Therefore, check to see if the report browser is open and do not block keyboard events. CUI-5466
            if (this.reportBrowserService.isOpen != true)
                return false;
        });
        this.formService.formDisabled = true;
    }


    /**
     * Private Helper Methods
     */

    private fieldForward(): boolean {
        let nextField: any = (this.rootScope.disableCustomFieldSequence || this.validationService.isNullOrEmpty(this.element.customNextField)) ?
            this.element.nextField : this.element.customNextField;
        let nextElement: any = (this.rootScope.disableCustomFieldSequence || this.validationService.isNullOrEmpty(this.element.customNextElement)) ?
            this.element.nextElement : this.element.customNextElement;
        // form is customized but next field and next element both are empty. So send the message to server.
        if ('customNextField' in this.element && 'customNextElement' in this.element) {
            if (this.validationService.isNullOrEmpty(this.element.customNextField) && this.validationService.isNullOrEmpty(this.element.customNextElement)) {
                nextField = null;
            }
        }

        if (nextField != null) {
            let temp = nextField.replace(/-/g, '.');
            let tempNextElement = this.formService.getLatestForm().fields[temp];
            if (tempNextElement != null && (tempNextElement.originalElementID == tempNextElement.elementWindowController ||
                (tempNextElement.elementDetailable || (true != tempNextElement.runTimeInquiryField && true != tempNextElement.runTimeRTInquiryField && true != tempNextElement.inquiry)) && true != tempNextElement.runTimeNoAccess)) {

                if (this.isWindowElement) {
                    let tempFields = this.formService.getLatestForm().fields;
                    var self = this;
                    let requiredField: boolean = false;
                    _.each(tempFields, function (fe) {
                        if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex) {
                            if (fe.required == true && self.validationService.isNullOrEmpty(fe.text)) requiredField = true;
                        }
                    });

                    // if any empty field in window is required, we will follow normal messaging.
                    if (requiredField) return false;
                }
                if (this.isWindowElement && nextElement != null) { // if current element is window element and elementForward is not null
                    let emptyWindowRow: boolean = true;
                    let tempFields = this.formService.getLatestForm().fields;
                    var self = this;
                    _.each(tempFields, function (fe) {
                        if (fe.elementWindowController == self.element.elementWindowController && fe.rowIndex == self.element.rowIndex) {
                            if (self.validationService.isNullOrEmpty(fe.text) == false) {
                                emptyWindowRow = false;
                            }
                        }
                    });


                    // if entire window row is empty.
                    // focus to next element out of window (elementFieldForward)
                    if (emptyWindowRow) {
                        // if entire row is empty and current page is greater than 1 send navigation message to server to paginate.
                        if (this.element.elementWindowController == this.element.originalElementID) {
                            let currentWindow = this.formService.getWindowControl(this.element.elementWindowController);
                            if (currentWindow.windowCurrentPage > 1) {
                                return false;
                            }
                        }
                        let temp = nextElement.replace(/-/g, '.');
                        let tempNextElement = this.formService.getLatestForm().fields[temp];
                        if (tempNextElement != null) {
                            if ((tempNextElement.elementDetailable || (true != tempNextElement.runTimeInquiryField && true != tempNextElement.runTimeRTInquiryField && true != tempNextElement.inquiry)) && true != tempNextElement.runTimeNoAccess) {
                                this.rootScope.focusedFieldName = tempNextElement.sanitizedID;
                                this.focusService.focusOn(nextElement, true);
                                return true;
                            }
                            // Return false and let server decide where to focus
                            else {
                                return false;
                            }
                        }
                    }
                }

                // focus to next field.
                this.rootScope.focusedFieldName = tempNextElement.sanitizedID;
                this.focusService.focusOn(nextField, true);
                return true;
            }
        }
        return false;
    }

    /**
     * Toggle Dropdown
     * @param open
     */
    private toggleDropDown(open: boolean) {
        // send field jump if dropdown is getting opened.
        if (open) {
            if (this.rootScope.lastPromptedField != this.element.elementID) { // only send if last field prompt was for not the same field.
                this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
                this.serverCommandService.sendFieldNavigationMessage(Models.CommandConstants.fieldJump, "", "", this.fieldName, "", this.getAbsoluteWindowRow());
            }
        }

        // check if dropdown is already open
        let dropdownOpen: boolean = $('#' + this.element.sanitizedID).parent().hasClass('open');

        // toggle the state of the dropdown options.
        if ((open && !dropdownOpen) || (!open && dropdownOpen)) {
            setTimeout(() => {
                let dropDownBtn = document.getElementsByClassName(this.element.sanitizedID + "_btn")[0] as HTMLElement;
                dropDownBtn.focus();
                dropDownBtn.click();
            }, 100);
        }
        dropdownOpen = null;
    }

    private isElementDirty() {
        if (((this.validationService.isNullOrEmpty(this.element.text) && !this.validationService.isNullOrEmpty(this.element.originalText)) || ((this.element.text != this.element.originalText && this.element.text != this.element.focusedText && this.element.text != this.element.unfocusedText) && !(this.validationService.isNullOrEmpty(this.element.text) && this.validationService.isNullOrEmpty(this.element.originalText))))
            && !this.element.elementDate) {
            return true;
        }
        else if (this.element.elementDate) {
            // Check to see if the element text only contains integers without de-limiters. If so, field navigation was not sent by calendar's onDateChanged event.
            // onDateChanged event only sets the date when there is a valid date that satisfies the format along with the delimiters(/ or -)
            if (this.validationService.isNullOrEmpty(this.element.originalText)) {
                this.element.originalText = "";
            }
            let elementTextIsStr: boolean = typeof this.element.text == "string";
            let originalTextIsStr: boolean = typeof this.element.originalText == "string";
            let elemEqOrig: boolean = this.element.text != this.element.originalText;
            let sendFieldUpdateForDateFlag: boolean = elementTextIsStr && originalTextIsStr &&  elemEqOrig;

            return sendFieldUpdateForDateFlag;
        }
        return false;
    }

    /**
     * Send field update to server if the data is modified.
     */
    private sendFieldUpdate(force: boolean = false, tab: boolean = false, shiftTab: boolean = false, enter: boolean = false, arrowDown: boolean = false, arrowUp: boolean = false, detail: boolean = false, blur: boolean = false): boolean {
        this.rootScope.fieldUpdateSent = false;
        // send field update only if text is dirty
        // if element is not date

        let send: boolean = false;

        if (force == false) {
            // always send data when text is empty.
            if (((this.validationService.isNullOrEmpty(this.element.text) && !this.validationService.isNullOrEmpty(this.element.originalText)) || ((this.element.text != this.element.originalText && this.element.text != this.element.focusedText && this.element.text != this.element.unfocusedText) && !(this.validationService.isNullOrEmpty(this.element.text) && this.validationService.isNullOrEmpty(this.element.originalText))))
                && !this.element.elementDate) {
                this.rootScope.calendarField = "";
                this.events.broadcast(Models.EventConstants.closeCalendar);

                this.fieldUpdateSent = true;

                send = true;
            }
            else if (this.element.elementDate) {
                // Check to see if the element text only contains integers without de-limiters. If so, field navigation was not sent by calendar's onDateChanged event.
                // onDateChanged event only sets the date when there is a valid date that satisfies the format along with the delimiters(/ or - or .)
                let sendFieldUpdateForDateFlag: boolean = typeof this.element.text == "string" &&
                    this.element.text != this.element.originalText;
                if (sendFieldUpdateForDateFlag || force) {
                    send = true;
                }
            }
        }
        else {
            this.events.broadcast(Models.EventConstants.closeCalendar);
            this.fieldUpdateSent = true;
        }
        if (send || force) {
            // if element is date, change the delimiter so that server always sends field update. :D
            // CR-000159825 -  CUI-5309 - If there are alphabets in the entered string that means its a term lookup and send the data without alteration.
            /* if (this.element.elementDate && !this.element.text.match(/[a-z]/i)) {
            //     if (this.element.delimiter == '-') {
            //         this.element.text = this.element.text.replace(/-/g, '/');
            //     }
            //     else {
            //         this.element.text = this.element.text.replace(/\//g, '-').replace(/\./g, '-');
            //     }
             }*/

            // Field display message will set it.
            // also make a copy of focused and unfocused data to restore in case of error
            this.element.tempFocusedText = this.element.focusedText;
            this.element.tempUnfocusedText = this.element.unfocusedText;
            this.element.focusedText = "";
            this.element.unfocusedText = "";

            this.element.originalText = this.element.text;
            this.fieldUpdateSent = true;
            if (blur == false) this.formService.formDisabled = true;
            else this.rootScope.fieldUpdateSent = true;

            // lowercase the data so that server always sends field display data with updated focused and unfocused text.
            let newData: any = this.element.text;
            if (this.isValcode && !newData.startsWith("=")) { // CUI-5350: Using an "=" should retain the case that the user entered.
                newData = newData.toLowerCase();
            }

            let jumpCommand: any = Models.CommandConstants.fieldJump;

            if (this.rootScope.lastPromptedField == this.element.elementID || this.rootScope.lastFieldPromptMessageId == this.element.elementID) {
                jumpCommand = Models.CommandConstants.ignoreFieldJump;
            }
            if (!this.validationService.isNullOrEmpty(newData)) {
                if (tab == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldForward], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
                else if (shiftTab) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldBack], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
                else if (enter == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.elementForward], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
                else if (arrowDown == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.arrowDown], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
                else if (arrowUp == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.arrowUp], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
                else if (detail == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldDetail], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
                else { // Field jump and Field Updates when blur event is triggered when clicking outside of the field.
                    if (blur == true) {
                        this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
                        this.rootScope.blurFieldId = this.element.sanitizedID;
                    }
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
            }
            else {
                if (tab == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate, Models.CommandConstants.fieldForward],
                        ["", this.fieldName, this.fieldName],
                        ["", newData, ""],
                        [this.fieldName, "", ""], ["", this.windowRow, this.windowRow], [this.getAbsoluteWindowRow(), "", ""]);
                    return true;
                }
                else if (shiftTab) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate, Models.CommandConstants.fieldBack],
                        ["", this.fieldName, this.fieldName],
                        ["", newData, ""],
                        [this.fieldName, "", ""], ["", this.windowRow, this.windowRow], [this.getAbsoluteWindowRow(), "", ""]);
                    return true;
                }
                else if (enter == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate, Models.CommandConstants.elementForward],
                        ["", this.fieldName, this.fieldName],
                        ["", newData, ""],
                        [this.fieldName, "", ""],
                        ["", this.windowRow, this.windowRow], [this.getAbsoluteWindowRow(), "", ""]);
                    return true;
                }
                else if (arrowDown == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate, Models.CommandConstants.arrowDown],
                        ["", this.fieldName, this.fieldName],
                        ["", newData, ""],
                        [this.fieldName, "", ""],
                        ["", this.windowRow, this.windowRow], [this.getAbsoluteWindowRow(), "", ""]);
                    return true;
                }
                else if (arrowUp == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate, Models.CommandConstants.arrowUp],
                        ["", this.fieldName, this.fieldName],
                        ["", newData, ""],
                        [this.fieldName, "", ""],
                        ["", this.windowRow, this.windowRow], [this.getAbsoluteWindowRow(), "", ""]);
                    return true;
                }
                else if (detail == true) {
                    this.rootScope.focusedFieldName = "";
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate, Models.CommandConstants.fieldDetail],
                        ["", this.fieldName, ""],
                        ["", newData, ""],
                        [this.fieldName, "", ""],
                        ["", this.windowRow, ""], [this.getAbsoluteWindowRow(), "", ""]);
                    return true;
                }
                else {

                    if (blur == true) {
                        this.rootScope.ignoreFieldPromptId.push(this.element.sanitizedID);
                        this.rootScope.blurFieldId = this.element.sanitizedID;
                    }
                    this.serverCommandService.sendFieldNavigationMessageList([jumpCommand, Models.CommandConstants.fieldUpdate], ["", this.fieldName], ["", newData],
                        [this.fieldName, ""], ["", this.windowRow], [this.getAbsoluteWindowRow(), ""]);
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Reset focused and unfocused text if the element is not lookup or valcode, as values for lookup and valcode should be updated upon receiving field display message.
     */
    private resetFocusedUnfocusedText() {
        if (this.element.text != this.element.originalText && this.element.focusedText != this.element.text && this.element.unfocusedText != this.element.text) {
            if (this.element.elementDate != true) {
                this.element.focusedText = this.element.text;
                this.element.unfocusedText = this.element.text;
            }
        }
    }

    /**
     * Send Field Forward or Field Back message to server with updated values.
     * @param fieldForward true if forward, false if back.
     */
    private navigate(command: string) {
        let text: string = "";

        // only send field data if value is dirty.
        // only if field is not date.
        if (this.element.text != this.element.originalText && !this.element.elementDate) {
            this.fieldUpdateSent = true;
            text = this.element.text;
        }
        else if (this.element.elementDate) {
            // Check to see if the element text only contains integers without de-limiters. If so, field navigation was not sent by calendar's onDateChanged event.
            // onDateChanged event only sets the date when there is a valid date that satisfies the format along with the delimiters(/ or - or .)
            let sendFieldUpdateForDateFlag: boolean = (this.element.text != this.element.originalText && typeof this.element.text == "string" && !this.validationService.isNullOrEmpty(this.element.text));
            if (sendFieldUpdateForDateFlag) {
                this.fieldUpdateSent = true;
                text = this.element.text;
                this.element.unfocusedText = text;
                this.element.focusedText = text;
            }
        }

        // send field update if current value was removed.
        // only if not date
        if (!this.validationService.isNullOrEmpty(this.element.originalText) && this.validationService.isNullOrEmpty(this.element.text) && !this.element.elementDate) {

            if (command != Models.CommandConstants.elementForward) this.sendFieldUpdate();
            else this.sendFieldUpdate(true, false, false, true);

            if (!this.isWindowElement) { // only send navagation if value is removed from non-window's element.
                this.formService.formDisabled = true;
                this.serverCommandService.sendFieldNavigationMessage(command, this.fieldName, text, "", this.windowRow, "");
            }
        }
        else { // if value is not removed send navigation with updated data if applicable.
            if (!((command == Models.CommandConstants.elementForward || command == Models.CommandConstants.fieldForward || command == Models.CommandConstants.fieldBack) && this.validationService.isNullOrEmpty(text))) {
                //   this.resetFocusedUnfocusedText();
            }
            else {
                this.fieldUpdateSent = true;
                this.blur();
            }

            if (command == Models.CommandConstants.elementForward) {
                this.fieldUpdateSent = true;
                this.formService.formDisabled = true; // disable the form and wait for server to send the field prompt message.
            }

            if (this.validationService.isNullOrEmpty(this.element.text) == false && command == Models.CommandConstants.elementForward) {
                this.rootScope.focusedFieldName = "";
                this.rootScope.clickedFieldName = "";
            }
            if (this.validationService.isNullOrEmpty(text) == false) {
                this.formService.formDisabled = true;
            }
            if (command != Models.CommandConstants.elementForward) this.serverCommandService.sendFieldNavigationMessage(command, this.fieldName, text, "", this.windowRow, "");
            else this.sendFieldUpdate(true, false, false, true);
            if (this.element.elementDate) {
                this.formService.populateDate(this.element, this.element.text);
            }
        }
    }

    /**
     * Get Absolute Window Row Index based on window page number
     */
    private getAbsoluteWindowRow_element(element: any) {
        if (this.validationService.isNullOrEmpty(element.elementWindowController) == false && element.elementWindowController == element.originalElementID) {
            return this.ng4WindowControlService.getWindowPosition(parseInt(element.rowIndex), element.elementWindowController);
        }
        else {
            return null;
        }
    }

    private isTextSelected(elementId: string) {
        let inputElement = document.getElementById(elementId);
        let startPos = inputElement.selectionStart;
        let endPos = inputElement.selectionEnd;
        let doc = document.selection;

        if (doc && doc.createRange().text.length != 0) {
            return true;
        } else if (!doc && inputElement.value.substring(startPos, endPos).length != 0) {
            return true;
        }
        return false;
    }
}