/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    ConfigurationService,
    EventsService,
    FavoritesService,
    HelperService,
    HelpService,
    ImageService,
    UiLocalStorageService,
    PreferencesService,
    RootScopeService,
    ServerCommandService,
    SessionService,
    LoginService,
    ValidationService
} from "./";

import * as _ from 'lodash';

@Injectable()
export class ContextService {
    openContextForm: boolean = false;
    positionCloseAll: boolean = false;
    checkContext: boolean = false;
    checkCancel: boolean = false;
    checkContextRecord: boolean = false;
    checkContextRecordForward: boolean = false;
    checkContextRecordPrevious: boolean = false;
    // contextupdatedData: any = [];
    contextExistPosition: boolean = false;
    newRecord: boolean = false;
    duplicateContext: boolean = false;
    contextPositionToRemove: any = null;
    active: any = {
        locked: false
    }
    context: any = {};
    previousPosition = -1;
    previousId = '';

    activeIdAfterContextRemove: string = "";

    private defaultContext: any = {
        Advanced: {},
        Description: {},
        Header: {},
        MoreDetail: {},
        ResultPage: {},
        Sort: {},
        data: [],
        position: -1
    }

    constructor(private helpService: HelpService, private rootScopeService: RootScopeService, private http: HttpClient, private helperService: HelperService, private configurationService: ConfigurationService, private validationService: ValidationService,
        private sessionService: SessionService, private eventsService: EventsService, private loginService: LoginService,
        private preferencesService: PreferencesService, private imageService: ImageService,
        private favoritesService: FavoritesService, private serverCommandService: ServerCommandService,
        private uiLocalStorageService: UiLocalStorageService) {
        this.reset();
    }

    reset() {
        _.each(this.context, (it: any, index: any) => {
            it.Advanced = {};
            it.Description = {};
            it.Header = {};
            it.MoreDetail = {};
            it.ResultPage = {};
            it.Sort = {};
            while (it.data.length > 0) {
                it.data.pop();
            }
            it.position = -1;
        });
        this.active.locked = false;
    }

    init() {
        //var initialContextNames = ["PERSON", "CORP", "ORG.PERSON", "STAFF", "FORM", "VOC"];
        let initialContextNames = ["ALL"];
        return this.fetchContextFromServer(initialContextNames);
    }

    fetchContextFromServer(contextNames: any, callback: any = null) {
        let request: any = { Context: contextNames, Group: "", ProcessID: "", InformationType: "FULL" };
        let url: string = [this.configurationService.getConfiguration().serviceUrl, 'context', this.sessionService.getToken(), this.sessionService.getControlId()].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        this.http.post(url, request, httpOptions).timeoutWith(30000, Observable.throwError({
            type: 'LOAD',
            msg: 'Context request timed out.'
        })).subscribe(
            (data: any) => {
                this.contextResponse(data, callback);
            },
            (error: any) => {
                // an error occurred during the http request. log it to the console and halt the session
                console.error(error);
                if (this.rootScopeService.isWaiting) {
                    // this function is called during the login process and can be called whenever 
                    // a new Context is required for Search Results. For now, only trap HTTP
                    // errors at startup
                    error.name = 'LOAD';
                    error.msg = 'An unexpected error occurred while loading Context.'
                    this.loginService.handleLoginErrors(error);
                }
            });
    }

    organizeContext(data: any) {
        var context = {};
        if (data.ResultPage) {
            data.ResultPage.push({
                "Context": "FORM",
                "Group": "",
                "RenderType": "GRID",
                "FavoriteField1": "",
                "FavoriteField2": "",
                "NumberOfRows": 1,
                "NumberOfColumns": 3,
                "ImageEnabled": false,
                "AddNewRecordAllowed": false,
                "MultipleSelection": false,
                "FieldCddNames": ["PROCESS.CLASS", "PROCESS.DESCRIPTION", "@ID", "PROCESS.APPLICATION"],
                "FieldLabels": ["", "", "", ""],
                "FieldColumnHeaders": ["Class", "Description", "Mnemonic", "Application"],
                "FieldColumns": ["1", "2", "3", "4"],
                "FieldColumnSpans": ["1", "1", "1", "1"],
                "FieldRows": ["1", "1", "1", "1"],
                "FieldRowSpans": ["1", "1", "1", "1"],
                "FieldDataTypes": ["TEXT", "TEXT", "TEXT", "TEXT"],
                "DemandFields": []
            });
        }
        _.each(data.Description, (it: any) => {
            if (!_.has(context, it.Context)) {
                context[it.Context] = {};
                _.defaults(context[it.Context], this.defaultContext);
            }
            context = this.parseHeaderContext(context, data, it.Context);
            context = this.parseResultPageContext(context, data, it.Context);
            context = this.parseAdvancedSearchContext(context, data, it.Context);
        });

        // Add context field so that they can be used to display hidden table for ImageNow integration.
        for (var con in context) {
            var contextemp = context[con];
            if (contextemp != null && contextemp.Header != null && contextemp.Header.sorted != null) {
                contextemp.Header.contextFields = [];
                _.each(contextemp.Header.sorted, (row: any) => {
                    _.each(row, (column: any) => {
                        contextemp.Header.contextFields.push({
                            fieldName: column.fieldName,
                            label: column.label
                        });
                    });
                });

            }
        }
        return context;
    }

    findDataByContext(contexts: any, contextId: any) {
        var data;
        for (var i = 0; i < contexts.length; i++) {
            if (contexts[i].Context === contextId) {
                data = contexts[i];
                break;
            }
        }
        return data;
    }

    parseHeaderContext(context: any, data: any, contextId: any) {
        let it = this.findDataByContext(data.Header, contextId);
        // If header data not under Header, it may be under ResultPage
        if (it === undefined) {
            it = this.findDataByContext(data.ResultPage, contextId);
        }
        if (it !== undefined) {
            var headerContext = {
                context: contextId,
                demandFields: it.DemandFields,
                group: it.Group,
                imageEnabled: it.ImageEnabled,
                mapEnabled: it.MapEnabled,
                totalColumns: it.NumberOfColumns,
                totalRows: it.NumberOfRows,
                processId: it.ProcessID,
                leftTitleField: it.TitleField1,
                rightTitleField: it.TitleField2
            };
            context[contextId].Header = headerContext;

            var displayFields: any = [];
            _.each(it.FieldCddNames, (fieldName: any, index: any) => {
                var label, cellLabel;
                if (it.FieldColumnHeaders !== undefined) {
                    label = it.FieldColumnHeaders[index];
                    cellLabel = it.FieldLabels[index];
                } else {
                    label = it.FieldLabels[index];
                    cellLabel = it.FieldLabels[index];
                }
                var consolidatedField = {
                    fieldName: fieldName,
                    column: it.FieldColumns[index],
                    columnSpan: it.FieldColumnSpans[index],
                    dataType: it.FieldDataTypes[index],
                    label: label,
                    cellLabel: cellLabel,
                    row: it.FieldRows[index],
                    rowSpan: it.FieldRowSpans[index]
                };
                displayFields.push(consolidatedField);
            });

            var sorted = _.groupBy(displayFields, "row");

            // CUI-3684
            // Sort by column for each row
            for (var s in sorted) {
                var sortedRow = sorted[s];
                sorted[s].sort((a: any, b: any) => {
                    return (a.column > b.column) ? 1 : ((b.column > a.column) ? -1 : 0);
                });

                // Enter empty column values if a column data is missing
                var totalColumns = headerContext.totalColumns;
                var currentRowColumns = sorted[s].length;
                var columnCounter = 1;
                if (currentRowColumns < totalColumns) {
                    for (var c = 0; c < totalColumns; c++) {
                        var selectedCol = sorted[s][c];
                        if (this.validationService.isNullOrEmpty(selectedCol)) {
                            var fieldToAdd = {
                                fieldName: "",
                                column: columnCounter,
                                columnSpan: 1,
                                dataType: "TEXT",
                                label: "",
                                cellLabel: "",
                                row: s,
                                rowSpan: 1
                            };
                            sorted[s].splice(c, 0, fieldToAdd);
                            columnCounter++;
                        } else if (selectedCol.hasOwnProperty("column") && !this.validationService.isNullOrEmpty(selectedCol.column) && !isNaN(selectedCol.column)) {
                            if (columnCounter == selectedCol.column) {
                                columnCounter++;
                            } else {
                                var fieldToAdd = {
                                    fieldName: "",
                                    column: columnCounter,
                                    columnSpan: 1,
                                    dataType: "TEXT",
                                    label: "",
                                    cellLabel: "",
                                    row: s,
                                    rowSpan: 1
                                };
                                sorted[s].splice(c, 0, fieldToAdd);
                                columnCounter++;
                            }
                        }
                    }
                }
            }

            //#region CUI-3577
            var totalRows = headerContext.totalRows;
            var numberOfKeys = Object.keys(sorted).length;
            var missingRow;
            // Sorted has lesser number of rows than there are total rows. Add blank record at the appropriate location.
            if (numberOfKeys < totalRows) {
                for (var i = 1; i <= totalRows; i++) {
                    if (sorted.hasOwnProperty(i)) {
                        continue;
                    } else {
                        missingRow = i;
                        break;
                    }
                }
                if (!this.validationService.isNullOrEmpty(missingRow)) {
                    // Add missing row number to sorted
                    sorted[missingRow] = [];
                }
            }
            //#endregion

            _.each(sorted, (it: any, index: any) => {
                it = _.sortBy(it, "column");
            });


            context[contextId].Header.displayFields = displayFields;
            context[contextId].Header.sorted = sorted;

            var maxColumns = 0;
            _.each(sorted, (row: any) => {
                var tempMaxColumn = _.maxBy(row, (element: any) => {
                    return element.column;
                });
                if (tempMaxColumn != null && maxColumns < tempMaxColumn.column) maxColumns = tempMaxColumn.column;
            });

            var keys = Object.keys(sorted);
            for (var i = 0; i < keys.length; i++) {
                keys[i] = parseInt(keys[i]).toString();
            }

            for (var ind = 0; ind < keys.length; ind++) {
                var row = keys[ind];
                if (sorted[row] && maxColumns > sorted[row].length) {

                    var missingColumns = [];
                    //Find missing columns
                    for (var j = 1; j <= maxColumns; j++) {
                        var column = _.find(sorted[row], (c: any) => {
                            return c.column == j;
                        });

                        if (column == null) {
                            missingColumns.push(j)
                        }
                    }

                    //Insert dummy missing columns
                    for (var i = 0; i < missingColumns.length; i++) {
                        sorted[row].splice(missingColumns[i] - 1, 0, {
                            fieldName: '',
                            column: missingColumns[i],
                            columnSpan: 1,
                            dataType: '',
                            label: '',
                            cellLabel: '',
                            row: row,
                            rowSpan: 1
                        });
                    }
                }
            }
        }

        if ((contextId === "FORM") && !_.has(context, "FORM")) {
            context["FORM"] = {
                Header: this.defaultContext
            };
        }

        _.each(sorted, (row: any) => {
            _.each(row, (col: any) => {
                var columnPrefixes = ["col-lg-", "col-md-", "col-sm-", "col-xs-"];
                var colWidth = Math.ceil(col.columnSpan * (12 / row.length));
                col.columnWidthClass = ["col-lg-" + colWidth, "col-md-" + colWidth, "col-sm-" + colWidth, "col-xs-" + colWidth].join(" ");
            });
        });


        return context;
    };

    parseResultPageContext(context: any, data: any, contextId: any) {
        let it = this.findDataByContext(data.ResultPage, contextId);
        if (it !== undefined) {
            var resultPageContext = {
                addNewRecordAllowed: it.AddNewRecordAllowed,
                context: it.Context,
                demandFields: it.DemandFields,
                favoriteFields: [
                    it.FavoriteField1,
                    it.FavoriteField2
                ],
                group: it.Group,
                imageEnabled: it.ImageEnabled,
                multipleSelection: it.MultipleSelection,
                totalColumns: it.NumberOfColumns,
                totalRows: it.NumberOfRows - 1, // header field is separate
                processId: it.ProcessID,
                renderType: it.RenderType,
                initialRenderType: it.RenderType
            };
            context[it.Context].ResultPage = resultPageContext;

            var displayFields: any = [];
            var headerFields: any = [];
            _.each(it.FieldCddNames, (fieldName: any, index: any) => {
                var label, cellLabel;
                if (it.FieldColumnHeaders !== undefined) {
                    label = it.FieldColumnHeaders[index];
                    cellLabel = it.FieldLabels[index];
                } else {
                    label = it.FieldLabels[index];
                    cellLabel = it.FieldLabels[index];
                }
                var consolidatedField = {
                    fieldName: fieldName,
                    column: it.FieldColumns[index],
                    columnSpan: it.FieldColumnSpans[index],
                    dataType: it.FieldDataTypes[index],
                    label: label,
                    cellLabel: cellLabel,
                    row: it.FieldRows[index],
                    rowSpan: it.FieldRowSpans[index]
                };
                headerFields.push(consolidatedField);
            });
            let tempHeaderFields: any = [];
            for (let r = 0; r < 5; r++) {
                for (let c = 0; c < 5; c++) {
                    let temp = _.find(headerFields, (hf: any) => {
                        return hf.column == c && hf.row == r;
                    });

                    if (temp != null) tempHeaderFields.push(temp);
                }
            }
            headerFields = tempHeaderFields;

            var sorted = _.groupBy(_.union(displayFields, headerFields), "row");
            context[it.Context].ResultPage.headerFields = headerFields;
            context[it.Context].ResultPage.sorted = sorted;

            var maxColumns = 0;

            _.each(sorted, (row: any) => {

                var sortedRow = row;
                row.sort((a: any, b: any) => {
                    return (a.column > b.column) ? 1 : ((b.column > a.column) ? -1 : 0);
                });

                var maxRowColumns = 0;
                _.each(row, (col: any) => {
                    maxRowColumns += parseInt(col.columnSpan);
                });
                //var tempMaxColumn = _.max(row, function (element) {
                //    return element.column;
                //});
                if (maxRowColumns != null && maxColumns < maxRowColumns) maxColumns = maxRowColumns;
            });


            var keys = Object.keys(sorted);
            for (var i = 0; i < keys.length; i++) {
                keys[i] = parseInt(keys[i]).toString();
            }

            for (var ind = 0; ind < keys.length; ind++) {
                var row = keys[ind];
                if (maxColumns > sorted[row].length) {

                    var missingColumns = [];
                    //Find missing columns
                    for (var j = 1; j <= maxColumns; j++) {
                        var column = _.find(sorted[row], (c: any) => {
                            return c.column == j;
                        });

                        if (column == null) {
                            missingColumns.push(j)
                        }
                    }

                    //Insert dummy missing columns
                    for (var i = 0; i < missingColumns.length; i++) {
                        sorted[row].splice(missingColumns[i] - 1, 0, {
                            fieldName: '',
                            column: missingColumns[i],
                            columnSpan: 1,
                            dataType: '',
                            label: '',
                            cellLabel: '',
                            row: row,
                            rowSpan: 1
                        });
                    }
                }
            }
        }

        if ((contextId === "FORM") && !_.has(context["FORM"].ResultPage, 'context')) {
            context["FORM"].ResultPage = {
                context: "FORM",
                demandFields: ["PROCESS.CLASS"],
                headerFields: _.map([{
                    id: "PROCESS.DESCRIPTION",
                    label: 'Name'
                }, {
                    id: "@ID",
                    label: 'Mnemonic'
                }, {
                    id: "PROCESS.APPLICATION",
                    label: 'Application'
                }], (obj: any) => {
                    return {
                        fieldName: obj.id,
                        label: obj.label
                    };
                }),
                favoriteFields: []
            };
            context["FORM"].ResultPage.displayFields = context["FORM"].ResultPage.headerFields;
        }


        // Calculate column width
        _.each(sorted, (row: any) => {
            _.each(row, (col: any) => {
                var columnPrefixes = ["col-lg-", "col-md-", "col-sm-", "col-xs-"];
                var colWidth = Math.ceil(col.columnSpan * (12 / row.length));
                col.columnWidthClass = ["col-lg-" + colWidth, "col-md-" + colWidth, "col-sm-" + colWidth, "col-xs-" + colWidth].join(" ");
            });
        });

        return context;
    };

    parseAdvancedSearchContext(context: any, data: any, contextId: any) {
        let it = this.findDataByContext(data.Advanced, contextId);
        if (it !== undefined) {
            var advancedSearchContext = {
                context: it.Context,
                group: it.Group,
                processId: it.ProcessID,
            };
            context[it.Context].Advanced = advancedSearchContext;
            var displayFields: any = [];
            _.each(it.FieldCddNames, (fieldName: any, index: any) => {
                var consolidatedField = {
                    fieldName: fieldName,
                    sanitizedId: fieldName.replace(".", "_"),
                    shortcut: it.FieldShortcuts[index],
                    dataType: it.FieldDataTypes[index],
                    label: it.FieldLabels[index]
                };
                displayFields.push(consolidatedField);
            });
            context[it.Context].Advanced.displayFields = displayFields;
        }
        return context;
    };

    setContextPosition(contextId: any, position: any) {
        if (_.has(this.context, contextId)) {
            this.context[contextId].position = position;
        }
    }

    addTempContext(tempContext: any) {
        let currentContext = this.getContext("PERSON");
        let existingRecord = _.find(currentContext.data, (val: any) => {
            return val['@ID'] === tempContext['recordID'];
        });

        if (!existingRecord) {
            this.context[tempContext.context].data.push({
                'ID': tempContext.recordID,
                '@ID': tempContext.recordID,
                newRecord: true,
                imageMissing: true,
                contextHeaderMessage: {}
            });
            var currentPosition = this.context[tempContext.context].position;
            this.setContextPosition(tempContext.context, currentPosition + 1);
        }
        else {
            this.setContextPosition("PERSON", currentContext.position);
        }
    }

    removeContextRecord(contextList: any) {
        for (var cont in this.context) {
            var contextIterator = this.context[cont];
            if (contextIterator && contextIterator.data && contextIterator.data.length > 0) {
                for (var i = 0; i < contextIterator.data.length; i++) {
                    var obj = contextIterator.data[i];

                    if (contextList.indexOf(obj.ID) !== -1) {
                        contextIterator.data.splice(i, 1);
                    }
                }
            }
        }
        var currentPosition = this.context["PERSON"].position;
        this.setContextPosition("PERSON", currentPosition - 1);
    }


    addContextData(contextId: any, data: any, refreshOnly: any = null) {
        _.each(data, (d: any) => {
            var br = false;
            if (d == null || d['PRIVACY.FLAG'] == null || d['PRIVACY.FLAG'].length == 0) {
                d.contextHeaderMessage = {
                    message: "",
                    error: false,
                    warning: false
                }
                br = true;
            }

            if (br == false) {
                var showError = true,
                    foundPrivacyCode = "";
                _.each(this.preferencesService.savedPreferences.PrivacyCodeAccess, (prefPrivacyCode: any) => {
                    if (Array.isArray(d['PRIVACY.FLAG'])) {
                        _.each(d['PRIVACY.FLAG'], (pFlag: any) => {
                            if (pFlag === prefPrivacyCode) {
                                foundPrivacyCode = pFlag;
                                showError = true;
                            }
                        });
                    } else {
                        if (d['PRIVACY.FLAG'] === prefPrivacyCode) {
                            foundPrivacyCode = d['PRIVACY.FLAG'];
                            showError = true;
                        }
                    }
                });
                if (showError) {
                    var found = false;
                    for (var i = 0; i < this.configurationService.privacyCodes.length; i++) {
                        if (this.configurationService.privacyCodes[i].Key == foundPrivacyCode) {
                            d.contextHeaderMessage = {
                                message: this.configurationService.privacyCodes[i].Value,
                                error: false,
                                warning: true
                            }
                            found = true;
                        }
                    }
                    if (found == false) {
                        d.contextHeaderMessage = {
                            message: this.configurationService.getPersonRecordDenialMessage(),
                            error: true,
                            warning: false
                        }
                    }
                } else {
                    d.contextHeaderMessage = {
                        message: "",
                        error: false,
                        warning: false
                    }
                }
            }
        });


        var position = 0;
        var newContextId = contextId;
        contextId = "PERSON";

        if (_.has(this.context, contextId)) {
            if (this.context[contextId].position < 0) {
                this.context[contextId].position = 0;
            }
            // if (this.context[contextId].data.length > 0) {
            //     this.CheckContextExist = true;
            // }
            var currentContext = this.getContext(contextId);
            var xposition = currentContext.position;
            var recordAdded = false;
            var currentRecordCount = currentContext.data.length;
            // Prevent duplicate entries
            _.each(data, (contextRecord: any) => {
                var existingRecord = _.find(currentContext.data, (val: any) => {
                    return val['@ID'] === contextRecord['@ID'];
                });
                if (!existingRecord && refreshOnly != true) {
                    contextRecord.contextId = newContextId;
                    recordAdded = true;

                    var plusOne = 1;
                    if (position == 0) position = xposition + plusOne;
                    else position++;
                    this.contextExistPosition = true;
                    this.duplicateContext = false;
                    this.imageService.getImage(contextRecord);
                    // adding data to the context area
                    if (this.checkContext) {
                        this.context[contextId].data.splice(position, 0, contextRecord);
                    } else {
                        this.context[contextId].data.push(contextRecord);
                    }
                } else if (existingRecord) {

                    existingRecord.contextId = newContextId;
                    this.contextExistPosition = false;
                    this.duplicateContext = true;

                    // Only update the flag if not a new record.
                    if (Object.keys(contextRecord).length > 3)
                        existingRecord.newRecord = false;
                    // Copy New Data to update things if data have changed.
                    for (var property in contextRecord) {
                        existingRecord[property] = contextRecord[property];
                    }
                }
            });

            //Move to next position if there are existing records in the context
            if (recordAdded && currentRecordCount != 0) {
                var newRecord = _.find(currentContext.data, (contextCard: any) => {
                    return contextCard.newRecord === true;
                });
                if (!newRecord) {
                    this.setContextPosition("PERSON", currentContext.position + 1);
                }
                return true;
            }

        }
        return false;
    };

    //refresh context after save
    refreshContextData(contextId: any, data: any) {
        _.each(data, (contextRecord: any) => {
            for (var i = 0; i < this.context[contextId].data.length; i++) {
                if (this.context[contextId].data[i]['@ID'] === contextRecord['@ID']) {
                    this.imageService.getImage(contextRecord);
                    this.context[contextId].data[i] = contextRecord;
                }
            }
        });
    };

    fetchContext(contextId: any, callback: any) {
        if (_.has(this.context, contextId)) {
            callback[0](callback[1], callback[2], callback[3]);
        } else {
            this.fetchContextFromServer([contextId], callback);
        }
    };

    // Return context data that should be already cached locally
    getContext(contextId: any = null) {
        let contextToRetrieve = contextId || "PERSON";
        return this.context[contextToRetrieve];
    };

    viewAllAddRecordToFavorite(position: any, data: any) {
        var selectedFavoritesList: any = []
        _.each(data, (it: any) => {
            selectedFavoritesList.push(it);
        });
        //selected records are set to an array
        selectedFavoritesList = data;
        //checking if length of the records >0 or not
        if (data.length > 0) {
            this.favoritesService.showAddToFavorites("PERSON", selectedFavoritesList);
        }
    }

    // context and favorites screen-Add record to favorites
    addRecordToFavorites(position: number) {
        var dataToAdd;
        if (position < 0) {
            // Add all to favorites
            dataToAdd = this.getContext("").data;
        } else {
            // Add current to favorites
            dataToAdd = [this.getContext("").data[position]];
        }
        if (dataToAdd.length > 0) {
            this.favoritesService.showAddToFavorites("PERSON", dataToAdd);
        }
    };


    help() {
        this.helpService.display(this.configurationService.getConfiguration().helpProcesses.ContextArea);
    };

    position(contextId: any, position: any) {
        if ((position < 0) || (this.context[contextId].data.length < 2)) {
            this.context[contextId].position = -1;
            this.context[contextId].data = [];
        } else {
            this.context[contextId].position = 0;
            this.context[contextId].data.splice(position, 1);
            if (position >= this.context[contextId].data.length) {
                this.context[contextId].position = this.context[contextId].data.length - 1;
            } else {
                this.context[contextId].position = position;
            }
        }
    };

    /**
     * Removes person records from context card area.
     */
    removeRecordsViaViewAllContext() {
        // Get current context
        let currentContext = this.getContext();

        // Parse through all records in current context and remove all records that are tagged as toBeRemoved
        _.remove(currentContext.data, (context: any) => {
            return context.toBeRemoved === true;
        });

        // Adjust active position
        currentContext.position = currentContext.data.map((context: any) => { return context['@ID']; }).indexOf(this.activeIdAfterContextRemove);
    }

    clearAllRecords() {
        var currentContext = this.getContext();
        _.remove(currentContext.data, (contextCard: any) => {
            return true;
        });
    }

    setPositionCloseAll(val: any) {
        this.positionCloseAll = val;
    }

    //removing person record from both form and context area
    closeRecord(contextId: any, position: any, selectedData: any, autoCloseContext: any = null) {
        let contextOpenForm = this.rootScopeService.isContextFormOpen;
        let viewallContext = this.rootScopeService.viewallContext;
        if (autoCloseContext == true) {
            this.position(contextId, position);

        } else if (contextOpenForm) {
            let fieldData = "";
            if (!viewallContext) {
                //context close and close all position
                let currentContext = this.getContext();
                let currentContextposition = this.getContext().position;

                if (currentContextposition !== -1 && this.positionCloseAll == false) {
                    fieldData = currentContext.data[currentContextposition].ID;
                } else {
                    this.positionCloseAll = false;
                    fieldData = "ALL";
                    _.each(currentContext.data, (contextCard: any) => {
                        contextCard.toBeRemoved = true;
                    });
                }

                this.serverCommandService.sendFieldNavigationMessage("CloseSelected", "", fieldData, "", "", "");
            } else {
                //context close and close all position view all context
                this.rootScopeService.viewallContext = false;
                let currentContext = selectedData;
                //context close and close all position
                let contextCardArray: any = [];
                _.each(currentContext, (contextCard: any) => {
                    contextCardArray.push(contextCard['@ID']);
                });
                let listOfCardId = contextCardArray.join('|');
                if (position !== -1) {
                    fieldData = listOfCardId;
                } else {
                    fieldData = "ALL";
                }

                this.serverCommandService.sendProcessCommandWithDataMessage("CloseSelected", "", fieldData);
            }
        } else {
            this.position(contextId, position);
        }
    };

    /**
     * Context list refresh and add new record
     * @param personContextId
     * @param data
     */
    listRefreshRecord(personContextId: any, data: any) {
        let existingRecordPosition: number = -1;
        if (this.rootScopeService.favoritePanelOpen) {
            this.rootScopeService.favoritePanelOpen = false;
        }
        // Get current context
        let context: any = this.getContext();
        // Get all data from current context
        let contextAllData: any = context.data;
        // Get current position in context header
        let currentContextPosition: number = context.position;

        /* contextPosition value in rootScope is used when users try to open some person record in context card, but, upon being asked for the Update, Cancel, Return prompt, they decide to click Return.
        If they tried to open a new person record that did not exist in the context card area, set the contextPosition so that upon clicking Return, the handler for Return can perform a minus 1 to return back to the previous position.
        If they tried to open an existing person record, do not set contextPosition. Since no new record was added, the handler for Return should not have to do minus 1 and just stay on the currently active context card area. */

        // Check if the list of current context IDs is the same as the data the user is trying to load
        let listOfCurrentContextIds = contextAllData.map((record: any) => { return record["@ID"]; }).sort();
        let listOfDataIds = data.map((record: any) => { return record["@ID"] }).sort();
        let newRecordFound: boolean = false;
        for (let i = 0; i < listOfDataIds.length; i++) {
            // New record in listOfDataIds that does not exist in listOfCurrentContextIds
            if (listOfCurrentContextIds.indexOf(listOfDataIds[i]) === -1) {
                newRecordFound = true;
                break;
            }
        }

        // If the user is trying to open a new record, set the current context position to rootScope so that the Return Handler can use the position to reset the context position
        if (newRecordFound)
            this.rootScopeService.contextPosition = currentContextPosition;

        let contextPositionData: number = currentContextPosition + 1;
        // Flag to check if form is open
        let checkFormIsOpen: boolean = this.rootScopeService.isContextFormOpen;

        if (checkFormIsOpen == true) {
            this.previousPosition = currentContextPosition;
            if (contextPositionData > 0 && contextAllData.length > currentContextPosition) {
                this.previousId = contextAllData[currentContextPosition].ID;
            }
        }

        let checkPersonFormIsOpen: boolean = this.rootScopeService.CheckPersonRelatedFormIsOpen;
        let checkContextFromFavorite: boolean = this.rootScopeService.loadContextFromFavorite;

        let isContextChecked: boolean = false;
        let contextNewData: any;
        let contextData: any;
        // Check whether you are adding context at end of record or middle of record
        if (contextAllData.length === contextPositionData && !checkFormIsOpen && !checkPersonFormIsOpen && !checkContextFromFavorite) { //end of record Add new context
            isContextChecked = true;
            contextNewData = data;
        } else { //middle of record Add new context
            contextData = contextAllData;
        }
        this.checkContext = contextAllData.length > 0;

        let totalContextCards: number = contextAllData.length;
        //add context to the header
        let contextAdded: boolean = this.addContextData(personContextId, data);
        // If a new context has been added to the context data, reset the context position in currentContextPosition
        if (contextAdded) {
            currentContextPosition = context.position;
        }

        let contextPersonData: any = [];
        let contextNewPersonData: any = [];

        if (contextAdded == false && this.rootScopeService.doNotRemoveDuplicate == false) {
            // let contextPersonData: any = [];
            // let contextNewPersonData: any = [];
            if (isContextChecked) { //end of record Add new context and loop it
                _.each(contextNewData, (it: any) => {
                    contextNewPersonData.push(it.ID);
                });
            } else { //middle of record Add new context and loop it
                if (this.rootScopeService.checkContextExistInFavorite) {
                    //add context from favorites
                    _.each(contextAllData, (it: any) => {
                        contextPersonData.push(it.ID);
                    });
                } else if (this.rootScopeService.viewallContext == true) {
                    _.each(data, (it: any) => {
                        contextNewPersonData.push(it.ID);
                    });
                } else {
                    _.each(contextData, (it: any) => {
                        contextPersonData.push(it.ID);
                    });
                }
            }
        } else {
            _.each(context.data, (it: any) => {
                contextPersonData.push(it['@ID']);
            });
            _.each(data, (it: any) => {
                contextNewPersonData.push(it['@ID']);
            });
        }

        //get current context position
        let _duplicateContextPosition: number = -1;
        _.each(data, (contextRecord: any) => {
            for (var i = 0; i < this.getContext().data.length; i++) {
                if (this.getContext().data[i]['@ID'] === contextRecord['@ID']) {
                    if (existingRecordPosition == -1)
                        existingRecordPosition = i + 1;
                    _duplicateContextPosition = i;
                }
            }
        });

        let _duplicateContext: boolean = _duplicateContextPosition != -1;

        let contextPosition: number;
        //calculating context position : adding 1 or removing 1
        if (this.rootScopeService.viewallContext) {
            contextPosition = currentContextPosition;
        } else if (!this.contextExistPosition) {
            if (currentContextPosition === 0 && !_duplicateContext) {
                contextPosition = currentContextPosition + 1;
            } else if (currentContextPosition === 0 && _duplicateContext) {
                contextPosition = _duplicateContextPosition;
            } else if (_duplicateContext) {
                contextPosition = _duplicateContextPosition;
            } else {
                contextPosition = currentContextPosition - 1;
            }
        } else {
            contextPosition = currentContextPosition + 1;
        }

        if (contextAdded == false && existingRecordPosition > -1) {
            _.each(context.data, (it: any) => {
                contextPersonData.push(it['@ID']);
            });
        }
        let listOfPersonId = _.uniq(contextPersonData).join('|'); //end of record Add new context
        let listOfNewPersonId = _.uniq(contextNewPersonData).join('|'); //middle of record Add new context

        let clientInputMessage: any = {
            "_i:type": 'ClientInputMessage',
            "FieldData": '',
            "Command": '',
            "FieldJumpName": '',
            "WindowJumpIndex": '',
            "CurrentFieldName": '',
            "CurrentWindowRow": ''
        };

        let formPosition = currentContextPosition + 1;

        if (contextPersonData.length >= 1 || contextNewPersonData.length >= 1) {
            if (this.checkContext) {
                if (isContextChecked) { //end of record Add new context and loop it and send command(empty string)
                    if (this.rootScopeService.loadContextFromFavorite) {
                        this.rootScopeService.loadContextFromFavorite = false;
                        if (contextAdded == false && this.rootScopeService.doNotRemoveDuplicate == false) {
                            if (existingRecordPosition > -1)
                                this.setContextPosition("PERSON", existingRecordPosition - 1);
                            else
                                this.setContextPosition("PERSON", contextPosition);
                        }

                        clientInputMessage.Command = "ListRefresh";
                        clientInputMessage.FieldData = existingRecordPosition > -1 ? listOfPersonId + ';' + existingRecordPosition : listOfPersonId + ';' + formPosition;
                    } else {
                        this.rootScopeService.checkPrivacyRecord = true;
                        if (contextAdded == false && this.rootScopeService.doNotRemoveDuplicate == false) {
                            if (existingRecordPosition > -1)
                                this.setContextPosition("PERSON", existingRecordPosition - 1);
                            else
                                this.setContextPosition("PERSON", contextPosition);
                        }
                        clientInputMessage.Command = "EmptyString";
                        clientInputMessage.FieldData = listOfNewPersonId;
                    }
                } else { //middle of record Add new context and loop it and send command(list refresh and form position)
                    if (this.rootScopeService.isContextFormOpen) { //check if form is open or not
                        if (this.rootScopeService.viewallContext == true) {
                            //record jump from view all
                            clientInputMessage.Command = "RecordJump";
                            clientInputMessage.FieldData = listOfNewPersonId;
                        } else if (context.data.length == (totalContextCards + contextNewPersonData.length) && (this.previousPosition + 1) == totalContextCards && (this.previousPosition > 0 || totalContextCards == 1)) {
                            // Remove the condition trying to figure out whether to send ListRefresh or not - just send it
                            // if ((!this.validationService.isNullOrEmpty(this.rootScopeService.cancelPrompted) && this.rootScopeService.cancelPrompted != true) || (this.rootScopeService.searchResultMessage != true)) {
                            clientInputMessage.Command = "ListRefresh";
                            clientInputMessage.FieldData = existingRecordPosition > -1 ? listOfPersonId + ';' + existingRecordPosition : listOfPersonId + ';' + formPosition;
                            //} else {
                            //    if (this.validationService.isNullOrEmpty(listOfNewPersonId) == false) {
                            //        this.serverCommandService.sendProcessCommandWithDataMessage("EmptyString", null, listOfNewPersonId);
                            //    }
                            //}

                            this.rootScopeService.loadContextFromFavorite = false;
                        } else {
                            //list refresh from search results
                            if (contextAdded == false && this.rootScopeService.doNotRemoveDuplicate == false) {
                                if (this.previousPosition != contextPosition) {
                                    clientInputMessage.Command = "ListRefresh";
                                    clientInputMessage.FieldData = existingRecordPosition > -1 ? listOfPersonId + ';' + existingRecordPosition : listOfPersonId + ';' + (contextPosition + 1);
                                }
                            } else if (this.rootScopeService.doNotRemoveDuplicate == true) {
                                this.serverCommandService.sendProcessCommandWithDataMessage("EmptyString", null, listOfPersonId);
                            }
                            else {
                                clientInputMessage.Command = "ListRefresh";
                                if (this.previousId == data[0]['@ID']) {
                                    clientInputMessage.FieldData = existingRecordPosition > -1 ? listOfPersonId + ';' + existingRecordPosition : listOfPersonId + ';' + this.previousPosition;
                                } else {
                                    clientInputMessage.FieldData = existingRecordPosition > -1 ? listOfPersonId + ';' + existingRecordPosition : listOfPersonId + ';' + contextPosition;
                                }
                            }
                        }
                    } else {
                        if (this.rootScopeService.loadContextFromFavorite) {
                            //list refresh from favorites open context
                            this.rootScopeService.loadContextFromFavorite = false;
                            if (contextAdded == false && this.rootScopeService.doNotRemoveDuplicate == false) {
                                if (existingRecordPosition > -1)
                                    this.setContextPosition("PERSON", existingRecordPosition - 1);
                                else
                                    this.setContextPosition("PERSON", contextPosition);
                            }
                            clientInputMessage.Command = "ListRefresh";
                            clientInputMessage.FieldData = existingRecordPosition > -1 ? listOfPersonId + ';' + existingRecordPosition : listOfPersonId + ';' + formPosition;
                        } else {
                            //empty string
                            if (contextAdded == false && this.rootScopeService.doNotRemoveDuplicate == false) {
                                if (existingRecordPosition > -1)
                                    this.setContextPosition("PERSON", existingRecordPosition - 1);
                                else
                                    this.setContextPosition("PERSON", contextPosition);
                            }
                            clientInputMessage.Command = "EmptyString";
                            clientInputMessage.FieldData = listOfPersonId;
                        }
                    }
                }
            } else { //add context at first time (when there is no context)
                clientInputMessage.Command = "EmptyString";
                clientInputMessage.FieldData = listOfPersonId;
            }
        }
        //No need to send server message if field data is empty
        if (clientInputMessage.FieldData != null && clientInputMessage.FieldData != "") {
            this.eventsService.broadcast("sendServerMessage", clientInputMessage);
        }

        if (!this.validationService.isNullOrEmpty(this.rootScopeService.cancelPrompted)) {
            // CUI-5712: cancelPrompted needs to be null, not false
            this.rootScopeService.cancelPrompted = null;
        }
    };

    private contextResponse(data: any, callback: any = null) {
        if (data['Errors'] && data.Errors.length > 0) {
            var errorMessages = '';
            _.each(data.Errors, (it: any) => {
                errorMessages += it.ErrorMessageText;
            });

            console.error(errorMessages);

            if (callback != null) {
                callback[0](callback[1], callback[4], true);
            }
        } else {
            _.extend(this.context, this.organizeContext(data));
            this.eventsService.broadcast("contextDefinitionsLoaded");
            if (callback != null) callback[0](callback[1], callback[2], callback[3]);
        }
    }

}