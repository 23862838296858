/**
* Copyright 2019 - 2021 Ellucian Company L.P. and its affiliates.
*/

import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Models from '../../models';
import { AttachService, FocusService, ConfigurationService, PromptService, FormService, SessionService, HelpService, DateFormatService, EventsService } from "../../services";
import IAttachment from "../../models/colleague-attach/attachment";
import IAttachmentCollection from "../../models/colleague-attach/attachmentCollection";
import { AttachmentStatus } from "../../models/colleague-attach/attachmentStatus";
import IAttachSpecs from "../../models/colleague-attach/attachmentSpecs";
import { AttachmentOwnerAction } from "../../models/colleague-attach/attachmentOwnerAction";
import { AttachmentCollectionStatus } from "../../models/colleague-attach/attachmentCollectionStatus";

declare let $: any;

@Component({
    selector: 'attach',
    template: require('./attach.component.html')
})
export class AttachComponent implements OnInit {
    // Attachments
    attachSpecs: IAttachSpecs;
    attachments: IAttachment[];
    loadingAttachmentData: boolean = false;

    // Attachment Collection
    attachmentCollection: IAttachmentCollection;
    loadingCollectionData: boolean = false;
    acceptedFileTypes: string = "";
    maximumFileSizeText: string = "N/A";

    // Alerts
    displaySuccessAlert: boolean = false;
    displayErrorAlert: boolean = false;
    displayInfoAlert: boolean = false;
    alertMessage: string = "";

    // Permissions
    permissions: any = {
        Create: false,
        Delete: false,
        Update: false,
        View: false
    }

    // File Uploader
    uploadedFiles: any = {};

    // File Extensions -- Matches the file extension validator extensions. UI Web API will need to validate file extensions before uploading.
    allowedFileTypeMappings: any = {
        "application/pdf": [".pdf"],
        "image/jpeg": [".jpg"],
        "image/png": [".png"],
        "image/gif": [".gif"],
        "image/bmp": [".bmp", ".bm"],
        "image/tiff": [".tif", ".tiff"],
    }

    colleagueAttachServiceUrl: string;
    loggedInUserPersonId: string;
    colleagueApiJwt: string;
    editAttachmentId: string;
    editOriginalAttachmentData: any;

    // Date Time Format for Uploaded On field. Default format will show this: 04-28-2020 08:50:14 AM
    dateTimeFormat = 'MM-dd-yyyy hh:mm:ss a';

    /**
     * Constructor for AttachComponent
     */
    constructor(private attachService: AttachService,
        private configurationService: ConfigurationService,
        private focusService: FocusService,
        private http: HttpClient,
        private promptService: PromptService,
        private formService: FormService,
        private sessionService: SessionService,
        private helpService: HelpService,
        private dateFormatService: DateFormatService,
        private eventsService: EventsService) {
            this.eventsService.on("CloseAttachModal", [this.closeAttachModalListener, this]);
    }

    ngOnInit() {
        this.loadingAttachmentData = true;
        this.loggedInUserPersonId = this.sessionService.getCurrentPersonId();
        this.dateTimeFormat = `${this.dateFormatService.getDateFormat()} hh:mm:ss a`;
        this.focusService.focusOn("btnAttachHelp", true);

        // Attachments initialization steps. Can be passed to the getColleagueApiToken HTTP call to be invoked as a callback.
        let initAttach = (error: any = null) => {
            if (error) {
                this.displayAlert(AttachDialogAlertType.Error, "Unexpected error while loading attachments. Please try again.", false);
                this.loadingAttachmentData = false;
            }
            else {
                this.colleagueApiJwt = this.sessionService.getColleagueToken();

                this.editAttachmentId = "";
                this.editOriginalAttachmentData = {};

                // If the user doesn't have an org entity ID or Colleague API doesn't contain a JWT token, do not proceed.
                if (this.loggedInUserPersonId == "" || this.colleagueApiJwt == "") {
                    this.displayAlert(AttachDialogAlertType.Error, "Your account is missing from the resources database. Please contact system administrator.", false);
                    this.loadingAttachmentData = false;
                }
                else {
                    let latestForm: any = this.formService.getLatestForm();
                    let latestFormId: string;

                    if(latestForm){
                        latestFormId = latestForm.formId;
                    
                        if(latestFormId){
                            this.attachSpecs = this.attachService.attachSpecs.get(latestFormId);
                            this.colleagueAttachServiceUrl = [this.configurationService.getConfiguration().serviceUrl, "colleagueAttach"].join('/');
        
                            this.getAttachments();
                            this.getAttachmentCollection();
                            this.uploadedFiles.length = 0;
                        }
                        else{
                            this.displayAlert(AttachDialogAlertType.Error, "Unexpected error initializing attachments.", false);
                            this.loadingAttachmentData = false;
                        }
                    }
                    else{
                        this.displayAlert(AttachDialogAlertType.Error, "Unexpected error initializing attachments.", false);
                        this.loadingAttachmentData = false;
                    }
                }
            }
        }

        // Get ColleagueAPI JWT from Session Service
        this.colleagueApiJwt = this.sessionService.getColleagueToken();

        if (!this.colleagueApiJwt) {// If session service doesn't have JWT, try an additional HTTP call to Colleague Web API and fetch the token.
            this.sessionService.getColleagueApiToken(initAttach);
        }
        else {// JWT was found, proceed with Attachments initialization
            initAttach();
        }
    }

    ngOnDestroy() {
        this.editAttachmentId = "";
        this.editOriginalAttachmentData = {};
        this.attachService.showAttach = false;
    }

    /**
     * Get list of attachments for the current owner and collection
     */
    getAttachments() {
        this.focusService.focusOn("btnAttachHelp", true);

        // CSSP - 8397 -> If AttachSpecsIncludeNonOwner is Y, get attachments for all owners in the collection and tagOne combination
        let getAttachmentsUrl: string = [this.colleagueAttachServiceUrl, `attachments?owner=${this.attachSpecs.AttachSpecsIncludeNonOwner === 'Y' ? '' : this.attachSpecs.AttachSpecsOwner}&collectionId=${this.attachSpecs.AttachSpecsCollection}&tagOne=${this.attachSpecs.AttachSpecsTagOne != '' ? this.attachSpecs.AttachSpecsTagOne : ''}`].join('/');

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-CustomCredentials': this.colleagueApiJwt
            })
        };

        this.http.get(getAttachmentsUrl, httpOptions).subscribe((response: any) => {
            this.attachments = response;
            this.loadingAttachmentData = false;
        }, (error: any) => {
            // an error occurred during the http request. log it to the console
            console.error(error);
            let attachText = document.getElementById("attachText");
            if (attachText)
                attachText.innerHTML = "";
            this.displayAlert(AttachDialogAlertType.Error, "Error retrieving attachments. Please try again.", false);
            this.loadingAttachmentData = false;
        });
    }

    /**
     * Get Attachment Collection data
     */
    getAttachmentCollection() {
        this.loadingCollectionData = true;

        let getAttachmentCollectionUrl: string = [this.colleagueAttachServiceUrl, `attachmentCollection/${this.attachSpecs.AttachSpecsCollection}`].join('/');

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-CustomCredentials': this.colleagueApiJwt
            })
        };

        this.http.get(getAttachmentCollectionUrl, httpOptions).subscribe((response: any) => {
            this.attachmentCollection = response;
            if (this.attachmentCollection.Status && this.attachmentCollection.Status.toString().toLowerCase() === "inactive")
                this.displayAlert(AttachDialogAlertType.Error, "The Attachment Collection is Inactive", false);
            this.loadingCollectionData = false;
            this.postAttachmentCollectionProcessing();
            this.getEffectivePermissions();
        }, (err: any) => {
            // an error occurred during the http request. log it to the console
            console.error(err);
            let attachText = document.getElementById("attachText");
            if (attachText)
                attachText.innerHTML = "";
            this.displayAlert(AttachDialogAlertType.Error, `${err.error.ExceptionMessage}`, false);

            // If there is an error in fetching the attachment collection data, set all permissions to false.
            this.attachSpecs.EffectivePermissions = {
                CanViewAttachments: false,
                CanCreateAttachments: false,
                CanUpdateAttachments: false,
                CanDeleteAttachments: false,
            };

            this.permissions = {
                Create: false,
                Delete: false,
                Update: false,
                View: false
            };
        });
    }

    /**
     * Get current user's effective permissions
     */
    getEffectivePermissions() {
        let activeFormId: string = this.formService.getLatestForm().formId;
        let securityToken = this.sessionService.getToken();
        let controlId: string = this.sessionService.getControlId();

        let getEffectivePermissionsUrl: string = [this.colleagueAttachServiceUrl, `effectivePermissions/${this.attachSpecs.AttachSpecsCollection}`].join('/');

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-CustomCredentials': this.colleagueApiJwt
            })
        };

        this.http.get(getEffectivePermissionsUrl, httpOptions).subscribe((response: any) => {
            this.attachSpecs.EffectivePermissions = {
                CanViewAttachments: response.CanViewAttachments,
                CanCreateAttachments: response.CanCreateAttachments,
                CanUpdateAttachments: response.CanUpdateAttachments,
                CanDeleteAttachments: response.CanDeleteAttachments,
            };

            this.setPermissions();
        }, (error: any) => {
            // an error occurred during the http request. log it to the console
            console.error(error);
            this.attachSpecs.EffectivePermissions = {
                CanViewAttachments: false,
                CanCreateAttachments: false,
                CanUpdateAttachments: false,
                CanDeleteAttachments: false,
            };

            this.setPermissions();
        });
    }

    /**
     * Upload an attachment
     */
    uploadAttachment() {
        let securityToken = this.sessionService.getToken();
        let operatorId = this.sessionService.getOperatorId();
        this.loadingAttachmentData = true;

        const attachmentMetadata: any = {
            Name: this.uploadedFiles[0].name,
            Status: AttachmentStatus.Active,
            Owner: this.attachSpecs.AttachSpecsOwner,
            CreatedBy: this.loggedInUserPersonId,
            CollectionId: this.attachSpecs.AttachSpecsCollection,
            TagOne: this.attachSpecs.AttachSpecsTagOne
        }

        // Multi-part data
        const multiPartAttachData: FormData = new FormData();
        multiPartAttachData.append("attachment", JSON.stringify(attachmentMetadata));
        multiPartAttachData.append("attachmentContent", this.uploadedFiles[0], this.uploadedFiles[0].name);
        let urlFilename = encodeURIComponent(this.uploadedFiles[0].name);
        let postAttachmentAndContentUrl: string = [this.colleagueAttachServiceUrl, `attachments/${securityToken}/${operatorId}?attachmentName=${urlFilename}`].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CustomCredentials': this.colleagueApiJwt
            })
        };

        this.http.post(postAttachmentAndContentUrl, multiPartAttachData, httpOptions).subscribe((response: any) => {
            // Default sort order of attachments CreatedAt DESCENDING. Therefore, add this attachment to the beginning of the attachments array
            this.attachments.splice(0,0,response);
            this.displayAlert(AttachDialogAlertType.Success, `${attachmentMetadata.Name} has been uploaded.`);
            this.loadingAttachmentData = false;
            this.clearAttachment();
        }, (err: any) => {
            console.error(err);
            if (err.error && err.error.ExceptionMessage)
                this.displayAlert(AttachDialogAlertType.Error, err.error.ExceptionMessage, false);
            else
                this.displayAlert(AttachDialogAlertType.Error, `Failed to upload ${this.uploadedFiles[0].name}. Please try again.`, false);
            this.loadingAttachmentData = false;
            this.clearAttachment();
        })
    }

    /**
     * Downlaod an attachment
     */
    downloadAttachment(attachment: IAttachment, event: any) {
        let attachmentId: string = attachment.Id;
        let attachmentName: string = attachment.Name;
        let urlAttachmentName: string = encodeURIComponent(attachmentName);
        let securityToken = this.sessionService.getToken();
        let operatorId = this.sessionService.getOperatorId();

        let downloadAttachmentUrl: string = [this.colleagueAttachServiceUrl, `attachments/content/${attachmentId}/${securityToken}/${operatorId}?urlAttachmentName=${urlAttachmentName}`].join('/');
        this.http.get(downloadAttachmentUrl, {
            headers: new HttpHeaders({
                'X-CustomCredentials': this.colleagueApiJwt
            }),
            responseType: 'blob'
        }).subscribe((response: any) => {
            const newBlob = new Blob([response], { type: response.type });

            // 09/14/2021 No longer support IE, so no longer need depricated msSaveOrOpenBlob
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //    window.navigator.msSaveOrOpenBlob(newBlob);
            //    return;
            // }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement('a');
            link.href = data;
            link.download = attachmentName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        }, (error: any) => {
            console.error(error);
            this.displayAlert(AttachDialogAlertType.Error, `Failed to download ${attachmentName}. Please try again.`, false);
        });
    }

    /**
     * Delete an attachment
     * @param attachment - cref=Attachment
     * @param event - $event
     */
    deleteAttachment(attachment: IAttachment, event: any) {
        let popupMessage: any = {};
        let message: any = `Are you sure you want to delete ${attachment.Name}?`;
        popupMessage.title = "Delete attachment";
        popupMessage.text = [message];
        popupMessage.buttons = [
            {
                label: "Yes",
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        let url: string = [this.configurationService.getConfiguration().serviceUrl, `/colleagueAttach/delete/${this.attachSpecs.AttachSpecsOwner}/${this.attachSpecs.AttachSpecsCollection}/${attachment.Id}/${this.attachSpecs.AttachSpecsTagOne}/${this.attachSpecs.AttachSpecsIncludeNonOwner && this.attachSpecs.AttachSpecsIncludeNonOwner === 'Y'}`].join('/');

                        this.loadingAttachmentData = true;

                        const httpOptions = {
                            headers: new HttpHeaders({
                                'Content-Type': 'application/json',
                                'X-CustomCredentials': this.colleagueApiJwt
                            })
                        };

                        this.http.delete(url, httpOptions).subscribe((response: any) => {
                            this.displayAlert(AttachDialogAlertType.Success, `${attachment.Name} has been deleted.`);
                            this.attachments = response;
                            this.loadingAttachmentData = false;
                        }, (error: any) => {
                            console.error(error);

                            this.displayAlert(AttachDialogAlertType.Error, `Failed to delete ${attachment.Name}.`);

                            let target: any = event.target || event.srcElement || event.currentTarget;
                            this.focusService.focusOn(target.attributes.id, true, false);

                            this.loadingAttachmentData = false;
                        });
                    }, false);
                },
                SearchFocus: false
            },
            {
                label: "No",
                callback: () => {
                    this.promptService.popupMessageDismiss(() => {
                        // If user clicks no, take the user back to the clicked element.
                        let target: any = event.target || event.srcElement || event.currentTarget;
                        this.focusService.focusOn(target.attributes.id, true, false);
                    }, false);
                },
                SearchFocus: false
            }
        ];
        popupMessage.defaultCallbackNumber = "0";
        this.promptService.popupMessageShow(popupMessage);
    }

    /**
     * Update the attachment
     * @param attachment
     * @param event 
     */
    updateAttachment(attachment: IAttachment, event: any) {
        let attachmentId: string = attachment.Id;
        let updateAttachmentUrl: string = [this.colleagueAttachServiceUrl, `attachments/${attachmentId}`].join('/');
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CustomCredentials': this.colleagueApiJwt
            })
        };

        this.http.put(updateAttachmentUrl, attachment, httpOptions).subscribe((response: any) => {
            this.displayAlert(AttachDialogAlertType.Success, `'${this.editOriginalAttachmentData.Name}' has been updated to '${attachment.Name}'.`);

            attachment = response;

            // Clear editable control flags
            this.editAttachmentId = "";
            this.editOriginalAttachmentData = {};
        }, (err: any) => {
            console.error(err);
            this.displayAlert(AttachDialogAlertType.Error, `Failed to update ${attachment.Name}. Please try again.`, false);
        });
    }

    /**
     * Display editable controls to let the user rename attachment
     * @param attachment
     * @param event 
     */
    handleEditAttachment(attachment: IAttachment, event: any) {
        event.preventDefault();
        event.stopPropagation();

        // Set the attachment Id that is being edited to display the editable controls
        this.editAttachmentId = attachment.Id;

        // Focus input box
        setTimeout(() => {
            document.getElementById(`txt_${attachment.Id}`).focus();
        }, 100);

        // Set original attachment name in case the user decides to cancel edits
        this.editOriginalAttachmentData = JSON.parse(JSON.stringify(attachment));
    }

    /**
     * Display the attachment name in a label, hide editable components
     * @param attachment
     * @param event 
     */
    handleCancelEdit(attachment: IAttachment, event: any) {
        event.stopPropagation();

        // Find index of attachment in the list
        const idMatcher = (att: IAttachment) => att.Id === attachment.Id;
        let index: number = this.attachments.findIndex(idMatcher);

        // Set attachment name back to its original name
        this.attachments[index] = JSON.parse(JSON.stringify(this.editOriginalAttachmentData));

        // Clear editable control flags
        this.editAttachmentId = "";
        this.editOriginalAttachmentData = {};
    }

    /**
     * File uploader input change event
     */
    inputAttachUploadChange() {
        let target = <HTMLInputElement>document.getElementById("inputAttachUpload");
        if (target != null && target.files && target.files.length == 1) {
            this.uploadedFiles = target.files;
        }
    }

    /**
     * Clear any files from file uploader
     */
    clearAttachment() {
        let target = <HTMLInputElement>document.getElementById("inputAttachUpload");
        if (target != null && target.files && target.files.length == 1) {
            target.value = "";
            this.uploadedFiles = {};
            this.uploadedFiles.length = 0;
        }
    }

    /**
     * Redirect to Attach Help
     * @param event 
     */

    openHelp(event: any = null) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            this.helpService.display(this.configurationService.getConfiguration().helpProcesses.Attachments);
            if (event != null) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    /**
     * Favorites keyboard shortcut for Help
     * @param event
     */
    attachModalKeydown(event: any) {
        let keyCtrl = event.ctrlKey;
        let keyAlt = event.altKey;
        let keyCode = event.keyCode;
        let keyCharCode = event.charCode;
        let keyChar = String.fromCharCode(keyCode || keyCharCode);

        if (keyCtrl && keyAlt && keyChar == "H") { // CTRL + ALT + H  - Help
            event.stopPropagation();
            event.preventDefault();
            this.openHelp();
        }
    }

    /**
     * Handle CloseAttachModal event - Attachment dialog is being closed using Escape or Click-away
     * Added for CSSP-8734
     * @param component - Scope reference to AttachComponent
     * @param data - data passed by the broadcast event
     */
    closeAttachModalListener(component: any, data: any) {
        let self = <AttachComponent>component;
        self.closeAttachDialog(null, true);
    }

    /**
     * Close Attach Dialog
     * @param event - event object
     * @param modalClose - true if attachment dialog is being closed using Escape or Click-away, false otherwise
     */
    closeAttachDialog(event: any = null, modalClose: boolean = false) {
        if (event == null || (event && (event.keyCode == Models.KeyCodes.space || event.keyCode == Models.KeyCodes.enter))) {
            // Clear editable control flags
            this.editAttachmentId = "";
            this.editOriginalAttachmentData = {};

            this.attachService.showAttach = false;

            // CSSP-8734
            // Only invoke modal hide if the Close button was explicitly clicked.
            // If the modal is closed due to Escape key or clicking away from the dialog, the hide event has already been triggered
            if(!modalClose){
                if ($("#attach .modal").modal().length > 0 && $("#attach .modal").modal()[0].hidden == false) {
                    $("#attach .modal").modal('hide');
                }
            }

            setTimeout(() => {
                this.focusService.selectPreviousField();
            }, 100);
        }
    }

    /**
     * Tab on Close button in footer should focus on Attach Help
     * @param event 
     */
    footerCloseButtonKeydown(event) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;

        if (!shift && tab) {
            event.preventDefault();
            event.stopPropagation();

            document.getElementById("btnHeaderClose").focus();
        }
    }

    /**
     * Tab on Close button in header should focus on Uploader. Shift+Tab should focus on Help.
     * @param event 
     */
    headerCloseButtonKeydown(event) {
        let tab: boolean = event.keyCode == Models.KeyCodes.tab;
        let shift: boolean = event.shiftKey;
        if (shift && tab) {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById("btnFooterClose").focus();
        }
    }

    /**
     * Enables/Disables Create, Update, Delete or Download actions based on currently logged in user's Colleague permissions, Attachment Owner Actions and Effective Permissions
     */
    setPermissions() {
        let activeFormId: string = this.formService.getLatestForm().formId;
        if (activeFormId) {
            let currentAttachSpecs = this.attachSpecs;

            if (currentAttachSpecs) {
                // CREATE
                if (currentAttachSpecs.hasOwnProperty("AttachSpecsUploadFlag") && currentAttachSpecs.AttachSpecsUploadFlag === 'N') {
                    this.permissions.Create = false;
                }
                else {
                    if (currentAttachSpecs.hasOwnProperty("EffectivePermissions") && currentAttachSpecs.EffectivePermissions
                        && currentAttachSpecs.EffectivePermissions.hasOwnProperty("CanCreateAttachments")) {
                        this.permissions.Create = currentAttachSpecs.EffectivePermissions.CanCreateAttachments === true;
                    }
                }

                // Get currently logged in user's Person ID
                let currentUser: string = this.sessionService.getCurrentPersonId();

                // Get Owner of Attachments
                let owner: string = this.attachSpecs.AttachSpecsOwner;

                let attachOwnerCanUpdate: boolean = false;
                let attachOwnerCanDelete: boolean = false;

                // Currently logged in user is the owner of attachments, set Attachment Owner Actions
                if (currentUser != "" && owner != "" && currentUser === owner) { // Do not compare empty IDs
                    attachOwnerCanUpdate = this.attachmentCollection.AttachmentOwnerActions && this.attachmentCollection.AttachmentOwnerActions.length > 0 && this.attachmentCollection.AttachmentOwnerActions.includes("Update");
                    attachOwnerCanDelete = this.attachmentCollection.AttachmentOwnerActions && this.attachmentCollection.AttachmentOwnerActions.length > 0 && this.attachmentCollection.AttachmentOwnerActions.includes("Delete");
                }

                // UPDATE
                // If the currently logged in user is the owner himself, check if Attachment Owner Actions allow Update
                if (currentUser === owner && attachOwnerCanUpdate === true) {
                    this.permissions.Update = attachOwnerCanUpdate;
                }
                /* If the currently logged in user:
                    1. Is not the owner 
                        OR
                    2. Is the owner but Attachment Owner Actions do not allow Update
                   Fallback to the EffectivePermissions
                */
                else {
                    this.permissions.Update = currentAttachSpecs.hasOwnProperty("EffectivePermissions") && currentAttachSpecs.EffectivePermissions.hasOwnProperty("CanUpdateAttachments") && currentAttachSpecs.EffectivePermissions.CanUpdateAttachments === true;
                }

                // DELETE
                // If parent of Attach Component does not allow Delete, deny DELETE permission
                if (currentAttachSpecs.hasOwnProperty("AttachSpecsDeleteFlag") && currentAttachSpecs.AttachSpecsDeleteFlag === 'N') {
                    this.permissions.Delete = false;
                }
                else {
                    // If the currently logged in user is the owner himself, check if Attachment Owner Actions allow Delete
                    if (currentUser === owner && attachOwnerCanDelete) {
                        this.permissions.Delete = attachOwnerCanDelete;
                    }
                    /* If the currently logged in user:
                        1. Is not the owner 
                            OR
                        2. Is the owner but Attachment Owner Actions do not allow Delete
                       Fallback to the EffectivePermissions
                    */
                    else {
                        this.permissions.Delete = currentAttachSpecs.hasOwnProperty("EffectivePermissions") && currentAttachSpecs.EffectivePermissions
                            && currentAttachSpecs.EffectivePermissions.hasOwnProperty("CanDeleteAttachments") && currentAttachSpecs.EffectivePermissions.CanDeleteAttachments === true;
                    }
                }

                // VIEW
                if (currentUser === owner) {
                    this.permissions.View = true;
                }
                else {
                    this.permissions.View = currentAttachSpecs.hasOwnProperty("EffectivePermissions") && currentAttachSpecs.EffectivePermissions.hasOwnProperty("CanViewAttachments") && currentAttachSpecs.EffectivePermissions.CanViewAttachments === true;
                }
            }
        }
    }

    /**
     * Set Allowed File Types and Maximum File Size for File Uploader Options
     */
    postAttachmentCollectionProcessing() {
        if (this.attachmentCollection.AllowedContentTypes && this.attachmentCollection.AllowedContentTypes.length > 0) {
            for (let i = 0; i < this.attachmentCollection.AllowedContentTypes.length; i++) {
                // Get mime type
                let key = this.attachmentCollection.AllowedContentTypes[i];
                // Find matching extensions in allowedFileExtensions
                if (this.allowedFileTypeMappings.hasOwnProperty(key)) {
                    this.acceptedFileTypes += this.allowedFileTypeMappings[key].join(",");
                    // Add a trailing comma at the end of each allowed file type
                    if (i !== this.attachmentCollection.AllowedContentTypes.length - 1)
                        this.acceptedFileTypes += ",";
                }
            }
        }

        if (this.attachmentCollection.MaxAttachmentSize) {
            this.maximumFileSizeText = this.attachmentCollection.MaxAttachmentSize === 0 ? "0 Bytes" : this.readableBytes(this.attachmentCollection.MaxAttachmentSize);
        }
    }

    /**
     * Display an Alert box on Attachments dialog
     * @param alertType 
     * @param alertText 
     * @param hideAlertAfterDelay 
     */
    displayAlert(alertType: AttachDialogAlertType, alertText: string, hideAlertAfterDelay: boolean = true) {
        if (alertType === AttachDialogAlertType.Error) {
            this.displayErrorAlert = true;
            this.displaySuccessAlert = false;
            this.displayInfoAlert = false;
            this.alertMessage = alertText;
        }
        else if (alertType === AttachDialogAlertType.Success) {
            this.displayErrorAlert = false;
            this.displaySuccessAlert = true;
            this.displayInfoAlert = false;
            this.alertMessage = alertText;
        }
        else if (alertType === AttachDialogAlertType.Info) {
            this.displayErrorAlert = false;
            this.displaySuccessAlert = false;
            this.displayInfoAlert = true;
            this.alertMessage = alertText;
        }
        else {
            this.displayErrorAlert = false;
            this.displaySuccessAlert = false;
            this.alertMessage = "";
        }

        // Remove any alerts after 5 seconds
        if (hideAlertAfterDelay === true) {
            setTimeout(() => {
                this.displayErrorAlert = false;
                this.displaySuccessAlert = false;
                this.alertMessage = "";
            }, 5000);
        }
    }

    /**
     * Converts bytes to a human readable value
     * @param bytes
     */
    readableBytes(bytes) {
        let i = Math.floor(Math.log(bytes) / Math.log(1024));
        let sizes = ['Bytes', 'KB', 'MB', 'GB'];
        let bytesData: any = (bytes / Math.pow(1024, i));
        return bytesData.toFixed(2) * 1 + ' ' + sizes[i];
    }
}

enum AttachDialogAlertType {
    Success = 0,
    Error = 1,
    Info = 2
}