/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { EventsService, InstrumentationService, PromptService, RootScopeService, FocusService } from "./";

@Injectable()
export class ProcessingService {
    message: any = "";
    title: any = "";
    event: any = "";
    showStopProcessing: boolean = false;
    alertTimeout: any = null;
    isOpen: boolean = false;
    heartBeatTimeout: any = null;
    isCancelable: boolean = false;

    constructor(private promptService: PromptService, private eventsService: EventsService, private instrumentationService: InstrumentationService, private rootScopeService: RootScopeService, private focusService: FocusService) {
        this.eventsService.on("openAlert", [this.showProcessingListener, this]);
        this.eventsService.on("closeAlert", [this.hideProcessingListener, this]);
    }

    cancelSearch() {
        this.eventsService.broadcast("cancelPersonSearch");
        this.hideProcessing(true);
        this.focusService.focusSearch();
    }

    showProcessing(event: any, title: string = "Processing ...", message: string = "Please wait ...", isCancelable: boolean = false) {
        this.isCancelable = isCancelable;
        if (this.alertTimeout != null) {
            clearTimeout(this.alertTimeout);
            clearInterval(this.heartBeatTimeout);
        }
        this.message = message;
        this.title = title;
        this.event = event;
        $('#waitDiv').show();
        setTimeout(() => {
            document.getElementById('waitDivInput').focus();
        }, 100);
        this.isOpen = true;
        this.showStopProcessing = false;

        this.alertTimeout = setTimeout(() => {
            this.logEvent();
        }, 60 * 1000);

        this.heartBeatTimeout = setInterval(() => {
            this.eventsService.broadcast("sendKeepAliveCommand");
        }, 10 * 1000);
    }

    logEvent() {
        if (this.isOpen) {
            if (this.event != null) {
                console.error("Processing triggered by: " + this.event);
                this.instrumentationService.event("Error", "Processing triggered by: " + this.event + ". Last Form launched: " + (this.rootScopeService.analyticsLastFormOpen != "" ? this.rootScopeService.analyticsLastFormOpen : "N/A"),
                    "UI Version: " + this.rootScopeService.colleagueUiBuildVersion);
            }
            clearTimeout(this.alertTimeout);
            clearInterval(this.heartBeatTimeout);
            this.showStopProcessing = true;
            setTimeout(() => {
                document.getElementById('stopWaitingButton').focus();
            }, 500);
        }
    }


    hideProcessing(force: boolean = false) {
        if (this.promptService.isOpen == false || force) {
            this.closeProcessing();
        }
    }

    showProcessingListener(service: any, settings: any) {
        let self = <ProcessingService>service;
        if (self.alertTimeout != null) {
            clearTimeout(self.alertTimeout);
            clearInterval(self.heartBeatTimeout);
        }
        self.message = settings.message;
        self.title = settings.alertTitle;
        self.event = settings.event;

        $('#waitDiv').show();
        setTimeout(() => {
            document.getElementById('waitDivInput').focus();
        }, 100);
        self.isOpen = true;
        self.alertTimeout = setTimeout(() => {
            self.logEvent();
        }, 60 * 1000);
        self.heartBeatTimeout = setInterval(() => {
            self.eventsService.broadcast("sendKeepAliveCommand");
        }, 10 * 1000);
    }


    hideProcessingListener(service: any, force: boolean = false) {
        let self = <ProcessingService>service;
        if (self.promptService.isOpen == false || force) {
            self.closeProcessing();
        }
    }

    closeProcessing() {
        $('#waitDiv').hide();
        clearTimeout(this.alertTimeout);
        clearInterval(this.heartBeatTimeout);
        this.event = null;
        this.showStopProcessing = false;
        this.isOpen = false;
    }
}