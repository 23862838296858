/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventsService } from "./";
import IAttachSpecsMessage from "../models/colleague-attach/attachSpecsMessage";
import { FormService } from "./form.service";
import { ConfigurationService } from "../services";
import { SessionService } from "./session.service";
import * as Models from "../models/";

declare var $: any;

@Injectable()
export class AttachService {
    attachSpecs: any = {};
    enableAttachLink: boolean = false;

    constructor(private eventsService: EventsService,
        private formService: FormService,
        private configurationService: ConfigurationService,
        private http: HttpClient,
        private sessionService: SessionService) {
        this.attachSpecs = new Map();
        this.eventsService.on("AttachSpecsMessage", [this.attachSpecsMessageListener, this]);
        this.eventsService.on("AttachButtonStateMessage", [this.attachButtonStateMessageListener,this]);
        this.eventsService.on(Models.EventConstants.activeFormUpdated, [this.activeFormUpdatedListener, this]);
    }

    showAttach: boolean = false;

    displayAttachDialog() {
        this.showAttach = true;

        setTimeout(() => {
            $("#attach .modal").modal({
                show: true,
                keyboard: true
            }).drags({
                handle: ".esg-modal-dialog__header"
            });

            // Bootstrap modal event (https://getbootstrap.com/docs/3.4/javascript/#modals-events)
            // This event is fired immediately when the hide instance method has been called
            // CSSP-8734 : This event will be fired when the user uses the Escape key or clicks away from the modal to close the attachment dialog
            $("#attach .modal").on("hide.bs.modal", (event) => {
                // Broadcast CloseAttachModal only if the attachment dialog is being closed using Escape or Click-away
                if(this.showAttach === true){
                    // Stop default event propagation, otherwise this event gets called multiple times
                    event.stopPropagation();
                    this.eventsService.broadcast("CloseAttachModal");
                }
            });
        }, 100);
    }

    /**
     * Process AttachSpecsMessage, maintain the specs per form, and get current user's effective permissions for the given attachment collection.
     * @param service Reference to AttachService
     * @param message AttachSpecsMessage
     */
    attachSpecsMessageListener(service: any, message: any) {
        let self = <AttachService>service;

        // First call to get ColleagueApi JWT
        let jwtToken = self.sessionService.getColleagueToken();
        if (!jwtToken)
            self.sessionService.getColleagueApiToken();

        // Check if there is a valid activeForm in formService. 
        // Some forms send AttachSpecsMessage on closing out of the form.
        // In those cases, formService does not contain any activeForm. Trying to access formId in that case results in JS errors.
        if(self.formService && self.formService.activeForm && self.formService.activeForm.formId){
            let activeFormId: string = self.formService.activeForm.formId;
            self.attachSpecs.set(activeFormId,{
                AttachSpecsCollection: message.AttachSpecsCollection ? message.AttachSpecsCollection : null,
                AttachSpecsOwner: message.AttachSpecsOwner ? message.AttachSpecsOwner : null,
                AttachSpecsProcess: message.AttachSpecsProcess ? message.AttachSpecsProcess : null,
                AttachSpecsTagOne: message.AttachSpecsTagOne ? message.AttachSpecsTagOne : null,
                AttachSpecsUploadFlag: message.AttachSpecsUploadFlag ? message.AttachSpecsUploadFlag : null,
                AttachSpecsDeleteFlag: message.AttachSpecsDeleteFlag ? message.AttachSpecsDeleteFlag : null,
                AttachSpecsIncludeNonOwner: message.AttachSpecsIncludeNonOwner ? message.AttachSpecsIncludeNonOwner : null,// CSSP - 8397
            });
    
            self.setAttachLinkState(true);
        }

        console.log(self.attachSpecs);
    }

    removeAttachSpecs(formId: string) {
        if (this.attachSpecs.has(formId)) {
            this.attachSpecs.delete(formId);
        }

        // If attachSpecs doesn't contain any data OR the currently active form
        if (this.attachSpecs.size < 1 
            || (this.formService.getLatestForm() != null && !this.attachSpecs.has(this.formService.getLatestForm().formId))) {
                this.setAttachLinkState(false);
        }
    }

    attachButtonStateMessageListener(service: any, message: any){
        let self = <AttachService>service;

        if(message && message.hasOwnProperty("ButtonStateEnabled") && message.ButtonStateEnabled != null){
            if(message.ButtonStateEnabled.toUpperCase() === 'N')
                self.setAttachLinkState(false);    
            else if(message.ButtonStateEnabled.toUpperCase() === 'Y')
                self.setAttachLinkState(true);    
        }

        // CSSP-8734: Allow an application developer to remove attach specs for a form
        if(message && message.hasOwnProperty("ResetAttachSpecs") && message.ResetAttachSpecs != null){
            let formSpecToReset: string = message.ResetAttachSpecs;
            if(self.attachSpecs.has(formSpecToReset)) {
                self.attachSpecs.delete(formSpecToReset);
            }
        }
    }

    setAttachLinkState(valToSet: boolean = false){
        this.enableAttachLink = valToSet;
    }

    /**
     * Any time an active form changes, enable/disable Attach Link in sidebar based on the currently active form
     * @param service - AttachService scope reference
     * @param activeFormId - active form ID
     */
    activeFormUpdatedListener(service: any, activeFormId: string){
        let self = <AttachService>service;

        if(activeFormId){
            if(self.attachSpecs.has(activeFormId)){
                self.setAttachLinkState(true);
            }
            else{
                self.setAttachLinkState(false);
            }
        }
        else{
            self.setAttachLinkState(false);
        }
    }
}