/**
* Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
*/

import { Field, Window } from "./"
import { JsonProperty } from "@dhkatz/json-ts";


export class Form {
    @JsonProperty('baseFormId')
    baseFormId: string;

    @JsonProperty('bottomHeaderRow')
    bottomHeaderRow: number;

    @JsonProperty('fields')
    fields: { [key: string]: Field };

    @JsonProperty({type: Field, name: 'fieldsArray' })
    fieldsArray: Field[];

    @JsonProperty('firstFocusElement')
    firstFocusElement: string;

    @JsonProperty('formId')
    formId: string;

    @JsonProperty('formMnemonic')
    formMnemonic: string;

    @JsonProperty('formTitle')
    formTitle: string;

    @JsonProperty('lastColumn')
    lastColumn: number;

    @JsonProperty('lastRow')
    lastRow: number;

    @JsonProperty('windowList')
    windowList: { [key: string]: Window };


    constructor() {
        this.baseFormId = undefined;
        this.bottomHeaderRow = undefined;
        this.fields = undefined;
        this.fieldsArray = undefined;
        this.firstFocusElement = undefined;
        this.formId = undefined;
        this.formMnemonic = undefined;
        this.formTitle = undefined;
        this.lastColumn = undefined;
        this.lastRow = undefined;
        this.windowList = undefined;
    }
}

