/**
 * Copyright 2019 - 2020 Ellucian Company L.P. and its affiliates.
 */

import { Injectable, Inject } from '@angular/core';
import {
    RootScopeService,
    HelpService,
    PromptService,
    EventsService,
    ConfigurationService,
    ValidationService,
    ServerCommandService,
    FocusService
} from "./";
import * as Models from "../models"

declare var $: any;


@Injectable()
export class CommentDialogService {

    title: string;
    header: string;
    timeStampDate: string;
    timeStampName: string;
    inquiryOnly: boolean;
    maxLength: number;
    content: string;
    mnemonic: string;
    keepAliveInterval: any = null;

    constructor(private configurationService: ConfigurationService,
        private validationService: ValidationService,
        private serverCommandService: ServerCommandService,
        private focusService: FocusService,
        private notificationsService: PromptService,
        private helpService: HelpService,
        private eventsService: EventsService,
        private rootScopeService: RootScopeService, ) {
        this.eventsService.on("CommentDialogMessage", [this.openCommentDialog, this]);
    }

    /**
     * Open Multiline line editor event listener
     * @param commentDialogService
     * @param msg
     */
    openCommentDialog(commentDialogService: any, msg: any): void {
        let self = <CommentDialogService>commentDialogService;
        $(document).off("keydown"); // enable form key down
        self.rootScopeService.multiLineEditorOpen = true;
        self.eventsService.broadcast('closeAlert');
        self.resetParams();
        self.rootScopeService.commentDialogOpen = true;

        self.title = msg.Title;
        self.header = msg.HeaderBlock.join("\n").replace(/ /g, '&nbsp;'); // space in html is trimmed by default. Inject space with html version of space '&nbsp;'
        self.maxLength = msg.LineMaxLength;
        self.content = msg.Text.join("\n");
        self.mnemonic = msg.Mnemonic;
        self.inquiryOnly = msg.InquiryOnly;
        self.timeStampDate = msg.TimeStampDate;
        self.timeStampName = msg.TimeStampName;


        setTimeout(() => {
            $('#multi-line-editor .modal').modal({
                backdrop: 'static',
                show: true,
                keyboard: false
            }).draggable();
        }, 250);
        
        /** Don't send a KeepAlive every 60 seconds
        self.sendKeepAlive(); */
    }

    /**
     * Close multi-line editor
     */
    closeCommentDialog(): void {
        this.resetParams();
        this.serverCommandService.sendProcessCommandMessage(Models.CommandConstants.screenCancel);
        this.rootScopeService.multiLineEditorOpen = false;
    }

    /**
     * Save multi-line editor
     */
    save(): any {
        //todo
        let content = $('textarea#multiLine').val();
        if (this.maxLength > 0) {
            let lines = content.split("\n");
            for (var i = 0; i < lines.length; i++) {
                // process line only if number of characters in line are more than allowed
                if (lines[i].length > this.maxLength) {
                    //split all the words from the line
                    let words = lines[i].split(" ");
                    let newLine = "";
                    for (var j = 0; j < words.length; j++) {
                        // if existing line content plus new word is less or equal to allowed max length
                        if ((newLine.length + words[j].length) < this.maxLength) {
                            if (j == 0) {
                                newLine = words[j];
                            } else {
                                newLine = newLine + " " + words[j];
                            }
                        }
                        // if the word itself is greater than the allowed max length
                        else if (j == 0 && words[j].length >= this.maxLength) {
                            let tempContent = words[j].substring(0, this.maxLength);
                            lines.splice(i, 0, tempContent);
                            if (words[j].length == this.maxLength) {
                                lines[i + 1] = lines[i + 1].replace(tempContent + " ", "");
                            }
                            else {
                                lines[i + 1] = lines[i + 1].replace(tempContent, "");
                            }
                            break;
                        }
                        else {
                            lines.splice(i, 0, newLine);
                            lines[i + 1] = lines[i + 1].replace(newLine, "");
                            lines[i + 1] = lines[i + 1].trimLeft();
                            break;
                        }
                    }
                }
            }


            content = lines.join("\n").trimRight();


        }

        // Remove blank lines at the bottom
        // CUI-5057
        // CR-000155084
        let lines = content.split("\n");
        for (var i = lines.length - 1; i >= 0; i--) {
            if (lines[i].length == 0) {
                lines.splice(i, 1);
            }
            else {
                break;
            }
        }
        content = lines.join("\n");

        // content = content.replace(/\r\n/g, "\r");
        // content = content.replace(/\n/g, "\r");
        // content = content.replace(/\r/g, "\r");
        // if(content.indexOf("\r") >= 0){
        //     let x = content.indexOf("\r");
        // }
        this.serverCommandService.sendProcessCommandWithDataMessage(Models.CommandConstants.processUpdate, "", content, true);
        this.resetParams();
        this.rootScopeService.multiLineEditorOpen = false;
    }

    /**
     * Send keep Alive message
     */
    sendKeepAlive(): void {
        this.keepAliveInterval = setInterval(() => {
            if (this.rootScopeService.commentDialogOpen == true) {
                this.serverCommandService.sendProcessCommandMessage("KeepAlive");
            }
        }, 60000);
    }

    /**
     * Reset params
     */
    private resetParams(): void {
        this.title = "";
        this.header = "";
        this.timeStampDate = "";
        this.timeStampName = "";
        this.inquiryOnly = false;
        this.maxLength = -1;
        this.content = "";
        this.mnemonic = "";
        $('#multi-line-editor .modal').modal('hide');
        this.rootScopeService.commentDialogOpen = false;
        clearInterval(this.keepAliveInterval);
    }


}